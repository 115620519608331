<template>
    <div class="flex items-center rounded-lg shadow-md hover:bg-gray-200 bg-white px-2 py-3 mx-3 mt-2 relative" @click="turnToDetail">
        <img :src="LandInfor.unitpicture" class="land-picture"/>
        <div class="flex-grow">
            <!-- 商品名称 -->
            <div class="font-bold text-base">{{ LandInfor.landname }}</div>
            <!-- 单位土地个数 -->
            <div class="text-gray-700 mb-2 text-xs">{{$t('ShopCartInfor.num')}}: {{ LandInfor.blocks }}</div>
            <!-- 经纬度 -->
            <div class="flex items-center rounded-full bg-gray-100 px-1 mt-2" style="width:120px;">
                <img src="../images/locate.png" class="w-3 h-3" />
                <span  class="ml-1 text-xs">{{  LandInfor.originalLand.geographicalcoordinates }}</span>
            </div>
            <!-- 原始土地名称 -->
            <div class="mt-2">
                <van-tag  round color="#7232dd" class="text-xs px-1" plain>{{ LandInfor.originalLand.originallandname }}</van-tag>
            </div>
            <!-- 价格 -->
            <div class="absolute" style="bottom:8px;right:10px;">
                <div class="flex items-center">
                    <span class="text-red-500 text-sm">{{$t('MobileMyLandWindow.closingPrice')}}:</span>
                    <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                    <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        LandInfor: {
            // 包含图片链接image、landName、landPrice、landAddress
            type: Object,
            required: true,
            default:{
                originalLand: {
                    geographicalcoordinates: " ",
                },
            }
        },
    },
    data() {
        return {};
    },
    methods: {
        turnToDetail() {
            this.$router.push('/mobile/my-land/detail?id=' + this.LandInfor.unitlandid + '&order=' + this.LandInfor.orderid);
        },
    },
};
</script>

<style scoped>
.land-picture {
    height: 100px;
    width: 100px;
    border-radius: 12px;
    @apply shadow-lg mr-4 ml-2
}
</style>
