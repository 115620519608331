<template>
    <div class="mobile-pay-full mobile-base-background2">
        <div class="mobile-pay-flex justify-center">
            <div class="flex flex-col items-center">
                <img src="../images/pay-fail.png" class="mobile-pay-img">
                <span class="mt-5">{{$t('MobilePayFail.title')}}</span>
                <span class="text-sm text-gray-500 mt-3" v-if="$i18n.locale === 'zh'">本页面将于{{ time }}秒后返回首页</span>
                <span class="text-sm text-gray-500 mt-3" v-else>This page will return to the home page after {{time}}s</span>
                <button class="mobile-pay-button mt-10" @click="goBack">{{$t('MobilePayFail.back')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        // 不要忘了获取传输过来的土地信息
        this.id = this.$route.query.token;
    },
    mounted() {
        this.$http.get("/v1/paypal/callback/success?orderid=" + this.id).then((res) => {}).catch((err) => {});
        this.timer = setInterval(() => {
            if (this.time > 0) {
                this.time --;
            } else {
                clearInterval(this.timer);
                this.timer = null;
                this.$router.go(-1);
            }
        }, 1000); // 每秒钟进行一次
    },
    data() {
        return {
            messages: 0,
            id: null, // 获取该商品详细信息
            timer: null, // 定时器
            time: 60, // 剩余时间
        };
    },
    methods: {
        goBack() {
            clearInterval(this.timer);
            this.timer = null;
            this.$router.push('/user/home-page');
        },
    },
    /* 生命周期结束时清除计时器 */
    destroyed() {
        clearInterval(this.timer);
        this.timer = null;
    },
}
</script>

<style scoped>
.mobile-pay-button {
    height: 40px;
    width: 120px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #3662EC;
    color: #3662EC;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>