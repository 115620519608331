<template>
    <div class="developer-detail-fill">
        <div class="flex flex-row">
            <div class="o-income-box1">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">{{$t('OrderView.l1')}}</div>
                </div>
                <div class="o-income-box1-echart">
                    <div class="echar" ref="juti" style="height: 300px;width: 300px;"></div>
                    <div class="w-full flex flex-col items-center">
                        <span class="o-income-box2-income-text1" style="font-size: 40px;">xxx</span>
                        <span class="o-income-box2-income-text2">{{$t('OrderView.originalland')}}</span>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="o-income-box1-landnum">
                        <div class="o-income-box1-landnum-circle"></div>
                        <div class="o-income-box2-income-text">
                            <span class="o-income-box2-income-text1">{{ jutiIncome.分块土地块数 }}</span>
                            <span class="o-income-box2-income-text2">{{$t('OrderView.subdividedland')}}</span>
                        </div>
                    </div>
                    <div class="o-income-box1-landnum">
                        <div class="o-income-box1-landnum-circle" style="background-color:green;"></div>
                        <div class="o-income-box2-income-text">
                            <span class="o-income-box2-income-text1">{{ jutiIncome.曾挂牌土地块数 }}</span>
                            <span class="o-income-box2-income-text2">{{$t('OrderView.listednumber')}}</span>
                        </div>
                    </div>
                    <div class="o-income-box1-landnum">
                        <div class="o-income-box1-landnum-circle" style="background-color:#F99D38;"></div>
                        <div class="o-income-box2-income-text">
                            <span class="o-income-box2-income-text1">{{ jutiIncome.已出售土地块数 }}</span>
                            <span class="o-income-box2-income-text2">{{$t('OrderView.soldnumber')}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-income-box2">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">
                        <span>{{$t('OrderView.originincome')}}</span>
                        <div class="o-income-box2-shaixuan">
                            <div class="o-income-box2-shaixuan-label">{{$t('OrderView.felterday')}}:</div>
                            <span class="text-xl mr-3">{{ startDateDay }} -</span>
                            <el-date-picker
                                v-model="endDate"
                                type="date"
                                placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="o-income-box2-income-parent">
                        <img src="../developer/income-b2-i1.png" class="o-income-box2-income-icon">
                        <div class="o-income-box2-income-text">
                            <span class="o-income-box2-income-text1">{{ tradeIncome.wholeincome }}</span>
                            <span class="o-income-box2-income-text2">{{$t('OrderView.l5')}}</span>
                        </div>
                    </div>
                    <div class="o-income-box2-income-parent" style="margin-left:80px;">
                        <img src="../developer/income-b2-i2.png" class="o-income-box2-income-icon">
                        <div class="o-income-box2-income-text">
                            <span class="o-income-box2-income-text1">{{ tradeIncome.todayIncome }}</span>
                            <span class="o-income-box2-income-text2">{{$t('OrderView.l6')}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow flex items-end">
                    <div class="echar" ref="charts" style="height: 300px;width: 100%;"></div>
                </div>
            </div>
        </div>
        <div class="developer-common-card-box" style="margin-top:30px;">
            <div class="developer-common-card-title-parent">
                <div class="developer-common-card-title">{{$t('OrderView.l7')}}</div>
            </div>
            <div class="flex flex-row w-full" style="margin-top:20px;">
                <div class="form-item-cells">
                    <div style="height:40px;width:100%;color: #788AA1;">
                        <el-col :span="4"><span>{{$t('OrderView.l8')}}</span></el-col>
                        <el-col :span="5"><span>{{$t('OrderView.l9')}}</span></el-col>
                        <el-col :span="2"><span>{{$t('OrderView.l10')}}</span></el-col>
                        <el-col :span="4"><span>{{$t('OrderView.l11')}}</span></el-col>
                        <el-col :span="4"><span>{{$t('OrderView.l12')}}</span></el-col>
                        <el-col :span="2"><span>{{$t('OrderView.l13')}}</span></el-col>
                        <el-col :span="3"><span>{{$t('OrderView.l14')}}</span></el-col>
                    </div>
                    <el-skeleton :rows="4" :loading="users_trade.length===0" animated></el-skeleton>
                    <div class="home-account-data">
                        <el-row v-for="(user, index) in users_trade" :key="index" style="width:100%;">
                            <div class="users-information-col" :style="tradeIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="tradeIndex = index" @mouseleave="tradeIndex = -1">
                                <el-col :span="4"><span>{{ user.separateOrderId }}</span></el-col>
                                <el-col :span="5"><span>{{ user.landname }}</span></el-col>
                                <el-col :span="2"><span>{{ user.ordertime }}</span></el-col>
                                <el-col :span="4"><span>{{ user.paymeans }}</span></el-col>
                                <el-col :span="4"><span>{{ user.totalprice }}</span></el-col>
                                <el-col :span="2"><span>{{ user.landid }}</span></el-col>
                                <el-col :span="3"><span>{{ user.totalprice }}</span></el-col>
                            </div>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        if (this.$route.query.land) { this.originallandid = this.$route.query.land; }
        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setTime(n - 7 * 3600 * 1000 * 24);
        this.changeDateFormat();
    },
    mounted() {
        this.$http.get('/v1/business/select-income?originallandid=' + this.originallandid + '&starttime=' + this.startDateDay + '&endtime=' + this.endDateDay)
        .then(res => {
            this.tradeIncome = res.data.data;
            this.initChart();
        }).catch(error => {
            this.$toast.fail(this.$t('OrderView.l15'))
        });
        this.$http.get('/v1/land-income/area-analysis?originallandid=' + this.originallandid)
        .then(res => {
            this.jutiIncome = res.data.data;
            this.initUnitChart();
        }).catch(error => {
            this.$toast.fail(this.$t('OrderView.l15'))
        });
        this.$http.post('/v1/business/select-transaction-list-more', {
            pagesize: this.pageSize,
            pagenum: this.currentPage,
            landid: this.originallandid
        }).then(res => {
            // todo接口不大对，需要显示最近十条订单，处理的时候记得把上面的显示的数据也给改了
            this.users_trade = res.data.data.rows;
            this.loading = false;
        }).catch(error => {
            this.$toast.fail(this.$t('OrderView.l16'))
        });
    },
    watch: {
        endDate(n) {
            this.startDate = new Date();
            this.startDate.setTime(n - 7 * 3600 * 1000 * 24);
        },
    },
    data () {
        return {
            originallandid: null, // 原始土地编号，url获取
            jutiIncome: {
                分块土地: "0.857",
                分块土地块数: 857,
                曾挂牌土地: "0.550",
                曾挂牌土地块数: "1",
                已出售土地: "0.198",
                已出售土地块数: "1"
            },
            startDate: null, // 查看原始土地收益的起始日期，需要根据结束日期计算前一周，这个参数存Date类型
            endDate: null, // 结束日期
            startDateDay: '', // 这个是显示在屏幕以及给后端传参的日期，改为字符串‘2024-01-02’这种
            endDateDay: '', // 同理
            tradeIncome: [{
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                wholeincome: 0.0,
                todayIncome: 0.0,
            }], // 收益数据
            unitchart: null, // 第一栏折线图-具体收益
            chartInstance: null, // 第二栏折线图-原始土地收益
            users_trade: [],
            users_trade_example: [{
                ids: 328,
                separateOrderId: "1001-1696990-0840905",
                combinedOrderId: "1000-1696990-0841501",
                ordertime: "2023-10-11T10:08:04",
                unitlandid: "6001",
                landid: "S538",
                landname: "陆地",
                landpicture: "/image/20230731145802.png",
                salemeans: "立即购买",
                paymeans: "PayPal",
                totalprice: 1301.0,
                completetime: null,
                buyerid: "43a89960781b41de9e16c2575772dc54",
                sellerid: "ad73795b81d74dacb72f280109143cae",
                addressid: "171",
                needpaper: true,
                orderstatus: "已付款",
                processstatus: "已付款",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: null,
                unitLand: null,
                address: null,
                onSale: null
            }],
            loading: true, // 第三栏交易列表加载项
            pageSize: 10, // 第三栏交易列表的每页条数，固定
            currentPage: 1,// 第三栏交易列表的页码
            tradeIndex: -1, // 鼠标悬浮的列表条数
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1)
        },
        /* 改换日期格式类型从Date类型转换成String类型 */
        changeDateFormat() {
            let startMonth = this.startDate.getMonth() + 1;
            if (startMonth < 10) { startMonth = '0' + startMonth; }
            let startDay = this.startDate.getDate();
            if (startDay < 10) { startDay = '0' + startDay }
            let endMonth = this.endDate.getMonth() + 1;
            if (endMonth < 10) { endMonth = '0' + endMonth; }
            let endDay = this.endDate.getDate();
            if (endDay < 10) { endDay = '0' + endDay; }

            this.startDateDay = this.startDate.getFullYear() + '-' + startMonth + '-' + startDay;
            this.endDateDay = this.endDate.getFullYear() + '-' + endMonth + '-' + endDay;
        },
        initUnitChart() {
            this.unitchart = this.$echarts.init(this.$refs.juti);
            let num1 = [];
            let num2 = [];
            let num3 = [];
            num1.push(parseFloat(this.jutiIncome.分块土地));
            num2.push(parseFloat(this.jutiIncome.曾挂牌土地));
            num3.push(parseFloat(this.jutiIncome.已出售土地));
            //配置echarts
            const option={
                polar: {
                    radius: [0, '80%']
                },
                angleAxis: {
                    max: 100,
                    startAngle: 90
                },
                radiusAxis: {
                    type: 'category',
                },
                tooltip: {},
                series: [{
                    type: 'bar',
                    data: num1,
                    coordinateSystem: 'polar',
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: '{c}%'
                    }
                },
                {
                    type: 'bar',
                    data: num2,
                    coordinateSystem: 'polar',
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: '{c}%'
                    }
                },
                {
                    type: 'bar',
                    data: num3,
                    coordinateSystem: 'polar',
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: '{c}%'
                    }
                },]
            }
            this.unitchart.setOption(option);
        },
        initChart(){
            this.chartInstance = this.$echarts.init(this.$refs.charts);
            let dates = []; // x轴
            let incomes = []; // y轴
            for (let i=6;i>=0;i--) {
                dates.push(this.tradeIncome[i].date);
                incomes.push(this.tradeIncome[i].income);
            }
            //配置echarts
            const option={
                tooltip: { //echarts提示框配置
                    trigger: 'item', // 触发类型，可选值有 'item'（数据项图形触发）和 'axis'（坐标轴触发）。默认为 'item'。
                    position: 'inside', // 提示框位置，可选值有 'auto'（自动计算位置）、'inside'（数据项内部）、'insideLeft'（数据项左侧）、'insideRight'（数据项右侧）、'insideTop'（数据项顶部）、'insideBottom'（数据项底部）。
                },
                xAxis: {
				    data: dates
			    },
                yAxis:{         //yAxis（Y轴）是配置图表的垂直轴。它用于定义数据在垂直方向上的刻度和标签。
                    type: 'value', // 设置y轴的类型为数值型
                    show: true // 设置y轴是否显示
                },
                series:{        //配置项
                    name: '收益(元)',
				    type: 'line',
                    smooth: true,
				    data: incomes
                },
            }
            this.chartInstance.setOption(option);
        },
        loadmore() { // 交易列表滚动到底时继续加载
            this.currentPage += 1;
            let data = {
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            this.$http.post('/v1/business/select-transaction-list-more', data)
            .then(res => {
                this.view = res.data.data.rows;
                this.loading = false;
            }).catch(error => {});
        },
    },
}
</script>

<style scoped>
.developer-detail-fill {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    background-color: rgb(242,248,255);
    overflow-y: auto;
    padding: 30px;
}
.o-income-box1 {
    width: 35%;
    height: 560px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #FFF;
    border-radius: 20px;
}
.o-income-box1-echart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.o-income-box1-landnum {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.o-income-box1-landnum-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #6881FC;
}
.o-income-box2 {
    margin-left: 30px;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #FFF;
    border-radius: 20px;
}
.o-income-box2-shaixuan {
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.o-income-box2-shaixuan-label {
    margin-right: 50px;
    font-size: 14px;
    color: #1D2129;
}
.o-income-box2-income-parent {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.o-income-box2-income-icon {
    height: 30px;
    width: 30px;
}
.o-income-box2-income-text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}
.o-income-box2-income-text2 {
    font-size: 14px;
    color: #ABA8BB;
}
.o-income-box2-income-text1 {
    font-size: 20px;
    color: #4E5969;
}
.order-income-box {
    min-height: 300px;
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.order-income-data-box {
    background-color: #dcdcdc;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}
</style>