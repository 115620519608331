<template>
    <div class="admin-apply-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>订单管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/refund-manage');">订单信息查看</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">查看详情</span>
        </div>
        <div class="admin-detail-form-parent">
            <div class="admin-detail-form">
                <div class="admin-detail-form-topic"><span>售后信息</span></div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">退款申请时间:</span>
                            <span>{{ refundInfor.refundtime }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row flex flex-row">
                            <span class="admin-detail-form-item-label">退款原因:</span>
                            <el-tooltip class="item" :content="refundInfor.refundreason" placement="top">
                                <span class="admin-manage-tabs-content-col-text">{{ refundInfor.refundreason }}</span>
                            </el-tooltip>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">退款金额:</span>
                            <span>{{ refundInfor.totalprice }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">订单状态:</span>
                            <span>{{ refundInfor.processstatus }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half" style="flex-direction:row;">
                        <el-button type="info" plain @click="showModal(true)" class="admin-apply-button-1">驳回申请</el-button>
                        <el-button type="primary" @click="showModal(false)" class="admin-apply-button-1" style="margin-left: 20px;">通过申请</el-button>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form">
                <div class="admin-detail-form-topic">买家信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">买家ID:</span>
                            <span>{{ refundInfor.buyerid }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">购买方式:</span>
                            <span>{{ refundInfor.salemeans }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">收件人:</span>
                            <span>{{ refundInfor.address.consigneename }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">收货地址:</span>
                            <span>{{ refundInfor.address.province + refundInfor.address.city + refundInfor.address.area + refundInfor.address.detailedaddress }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">买家昵称:</span>
                            <span>{{ refundInfor.buyer }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">是否开具纸质证明:</span>
                            <span>{{ refundInfor.needpaper?'是':'否' }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">联系方式:</span>
                            <span>{{ refundInfor.buyercommunicate }}</span>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;">
                <div class="admin-detail-form-topic">卖家信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家ID:</span>
                            <span>{{ refundInfor.sellerid }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家手机号:</span>
                            <span>{{ refundInfor.sellercommunicate }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家昵称:</span>
                            <span>{{ refundInfor.seller }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家电子邮箱:</span>
                            <span>{{ refundInfor.sellercommunicate }}</span>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;">
                <div class="admin-detail-form-topic">订单信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half" style="width:33%">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">独立订单编号:</span>
                            <span>{{ refundInfor.separateOrderId }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">购买方式:</span>
                            <span>{{ refundInfor.salemeans }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">实付金额:</span>
                            <span>{{ refundInfor.totalprice }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half" style="width:33%">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">交易时间:</span>
                            <span>{{ refundInfor.ordertime? refundInfor.ordertime : '' }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">支付方式:</span>
                            <span>{{ refundInfor.paymeans? refundInfor.paymeans : '' }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">是否开具纸质证明:</span>
                            <span>{{ refundInfor.needpaper?'是':'否' }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half" style="width:33%">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">订单状态:</span>
                            <span>{{ refundInfor.orderstatus }}</span>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;overflow-y:auto;">
                <div class="admin-detail-form-topic">原始土地信息</div>
                <div class="admin-detail-form-full" style="padding:30px 0;">
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">原始土地编号</span>
                        <span>{{ refundInfor.originalLand.originallandid }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">土地名称</span>
                        <span>{{ refundInfor.originalLand.originallandname? refundInfor.originalLand.originallandname : '' }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">地址</span>
                        <span>{{ refundInfor.originalLand.detailedaddress }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">开发商编号</span>
                        <span>{{ refundInfor.originalLand.ownerid }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">大门朝向</span>
                        <span>{{ refundInfor.originalLand.entrance? refundInfor.originalLand.entrance : '' }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">创建时间</span>
                        <span>{{ refundInfor.originalLand.createdate }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">原始土地长度</span>
                        <span>{{ refundInfor.originalLand.landlength }}英尺</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">原始土地宽度</span>
                        <span>{{ refundInfor.originalLand.landwidth }}英尺</span>
                    </el-row>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="admin-apply-modal" v-if="modal.vmodal">
            <div class="admin-apply-modal-box">
                <span class="mb-5">{{ modal.text }}</span>
                <el-input
                    type="textarea"
                    v-model="modal.refundText"
                    :autosize="{ minRows: 3, maxRows: 4}"
                    placeholder="请输入驳回原因"
                    maxlength="30"
                    show-word-limit
                    v-show="modal.isRefund"></el-input>
                <div class="text-red-500" v-show="modal.refundFailed">驳回原因不可为空</div>
                <div style="margin-top: 30px;">
                    <el-button type="info" plain class="admin-apply-button-1" style="margin-right: 30px;" @click="closeModal">取消</el-button>
                    <el-button type="primary" class="admin-apply-button-1" @click="apply" v-if="!modal.isRefund">确定</el-button>
                    <el-button type="primary" class="admin-apply-button-1" @click="refuse" v-else>确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.orderid = this.$route.query.id;
    },
    mounted() {
        this.$http.get('/v1/administrator/select-ordermanage-byid?id=' + this.orderid)
            .then(res => {
                this.refundInfor = res.data.data;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
            });
    },
    data() {
        return {
            modal: {
                vmodal: false, // 弹窗开关
                text: '', // 弹窗内容
                isRefund: false, // true是驳回，false是通过
                refundText: '', // 驳回原因
                refundFailed: false, // 没有写驳回原因时设置为true提醒
            },
            refundInfor: { // 范例数据
                ids: 317,
                separateOrderId: "",
                combinedOrderId: null,
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: null,
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                completetime: null,
                buyerid: "",
                sellerid: "",
                addressid: null,
                needpaper: true,
                orderstatus: "",
                processstatus: "",
                refundreason: "",
                refundtime: "",
                rejectionreason: null,
                isdelete: null,
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: {
                    id: 6006,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 6006,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 171,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                },
                onSale: {
                    id: 171,
                    originallandid: "",
                    unitlandid: "",
                    landname: "",
                    sellerid: "",
                    seller: "",
                    salesmethods: "",
                    expirydate: "",
                    unitlandprice: null,
                    pricenum: 0,
                    unitlandminprice: null,
                    minimumprice: null,
                    blocks: 0,
                    totalprice: 0,
                    unitpicture: "",
                    createtime: "",
                    reasondelisting: null,
                    status: "",
                    remark: null,
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: null,
                    unitLand: null
                }
            },
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        closeModal() {
            this.modal.refundFailed = false
            this.modal.vmodal = false
        },
        showModal(boolean) {
            if (boolean) {
                this.modal.text = '驳回原因'
                this.modal.isRefund = true
                this.modal.vmodal = true
            } else {
                this.modal.text = '确定审核通过？'
                this.modal.isRefund = false
                this.modal.vmodal = true
            }
        },
        apply() {
            const number = this.refundInfor.separateOrderId
            const data = {
                number
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/administrator/application-approved', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核通过，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核通过录入系统失败！请重试或重新登录')
            });
        },
        refuse() {
            if (this.modal.refundText === '') {
                this.modal.refundFailed = true
                return
            }
            const data = {
                separateOrderId: this.refundInfor.separateOrderId,
                rejectionreason: this.modal.refundText
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/administrator/reject-application', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核驳回，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核驳回录入系统失败！请重试或重新登录')
            });
        },
    },
}
</script>

<style scoped>
.admin-apply-form-inn {
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
</style>