<template>
    <div class="mobile-base-background2 h-full base-p-16px">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileMyWallet.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="animate__animated animate__fast animate__fadeInUp rounded-lg items-center shadow-md bg-white px-8 pt-7 pb-6 mt-5 flex flex-col">
            <span>{{$t('MobileMyWallet.coin')}}</span>
            <div class="flex flex-row mt-5">
                <img src="../images/coin.png" class="w-14 h-14 place-self-center" />
                <span class="flex-1 mx-5 text-left text-3xl font-bold text-gray-500 self-center">{{ balance[0] }}.<span class="text-xl">{{ balance[1] }}</span></span>
            </div>
            <div class="bg-blue-500 hover:bg-blue-600 flex justify-center items-center text-white text-lg rounded-3xl w-4/5 mt-5 py-2" @click="goRecharge">{{$t('MobileMyWallet.Recharge')}}</div>
        </div>
        <div class="grid-cols-8 gap-2 px-8 text-lg"></div>
    </div>
</template>

<script>
export default {
    name: "MobileMyWallet",
    data() {
        return {
            messages: 0,
            balance: ['0','00'],
        };
    },
    created() {
        // /Recharge/checkBalance
        this.$http
            .get("/v1/recharge/check-balance")
            .then((res) => {
                const value = Number(res.data.data.balance).toFixed(2).toString();
                this.balance = value.split(".");
            })
            .catch((err) => {
                console.log(err);
            });
        /* if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.onpopstate=() => {
                this.onBack();
            }
        } */
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    methods: {
        goRecharge() {
            this.$router.push("/mobile/recharge");
        },
    },
};
</script>

<style scoped>
</style>
