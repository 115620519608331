<template>
  <div class="user-container">

    <!-- 头部 -->
    <div class="user-header">
      <div class="user-header-tabs" :class="index===1?'user-header-tabs-select':''" @click="changeIndex(1)">{{$t('all.homepage')}}</div>
      <div class="user-header-tabs" :class="index===2?'user-header-tabs-select':''" @click="changeIndex(2)">{{$t('all.landmarket')}}</div>
      <div class="user-header-tabs" :class="index===3?'user-header-tabs-select':''" @click="changeIndex(3)">{{$t('all.goodStore')}}</div>
      <div class="user-header-tabs" :class="index===7?'user-header-tabs-select':''" @click="changeIndex(7)">{{$t('MobileHome.page3')}}</div>
      <div class="user-header-tabs" :class="index===4?'user-header-tabs-select':''" @click="changeIndex(4)">{{$t('PcFinanceMenu.finance')}}</div>
      <div class="user-header-tabs" :class="index===6?'user-header-tabs-select':''" @click="showChatModal=true">{{$t('all.message')}}</div>
      <div class="user-header-user" @click="changeIndex(5)">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('userhome.changeAvatar')"
          placement="bottom-start"
          v-if="hasLogin"
        >
          <img :src="avatarUrl" class="user-header-avatar" @click="gotoMyinfor" />
        </el-tooltip>
        <span v-else>{{$t('all.login')}}</span>
      </div>
    </div>
    
    <!-- 主体 -->
    <div class="user-main">
      <router-view :hasLogin="hasLogin"></router-view><!-- 路由占位符 -->
    </div>

    <!-- 登录组件 -->
    <UserLogin v-if="showLoginModal"></UserLogin>

    <!-- 聊天组件 -->
    <UserChat v-if="showChatModal"></UserChat>
  </div>
</template>

<script>
import UserLogin from './UserLoginModal.vue'
import UserChat from './ChatMain.vue'
export default {
  components: {
    UserLogin,
    UserChat
  },
  computed: {
    users() {
      return this.$store.state.user.userInfo;
    },
    avatarUrl() {
      return this.$store.state.user.avatarUrl;
    },
  },
  created() {
    if (localStorage.getItem('user-menu')) {
      this.index = parseInt(localStorage.getItem('user-menu'));
    }
    this.$http.get("/v1/index/detect-user-status")
      .then(response => {
        if (response.data.status === 200) {
          this.hasLogin = true;
        } else { // 接口201，未登录，不允许跳转
          this.hasLogin = false;
        }
      }).catch(error => {
        if (this.$store.state.user.token) {
          this.hasLogin = true;
        } else {
          this.hasLogin = false;
        }
      });
    if (this.$route.params.login) {
      this.showLoginModal = this.$route.params.login;
    }
  },
  data() {
    return {
      index: 1,
      showLoginModal: false, // 是否显示登录窗口
      showChatModal: false, // 是否显示聊天窗口
      hasLogin: false, // 是否已登录
    }
  },
  methods: {
    // 更换页面
    changeIndex(n) {
      if (this.hasLogin) {
        this.index = n;
        localStorage.setItem('user-menu',n);
        if (n === 1) {
          this.$router.push('/user/home-page').catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.log(err);
            }
          })
        } else if (n === 2) {
          this.$router.push('/user/land-market').catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.log(err);
            }
          })
        } else if (n === 3) {
          alert('todo暂无该功能')
        } else if (n === 4) {
          this.$router.push('/user/property').catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.log(err);
            }
          })
        } else if (n === 5) {
          this.$router.push('/user/account').catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.log(err);
            }
          })
        } else if (n === 7) {
          this.$router.push('/user/shop-cart').catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.log(err);
            }
          })
        }
      } else {
        this.showLoginModal = true;
      }
    },
    // 给登录组件一个可以开关登录界面的父方法
    closeLoginModal() {
      this.showLoginModal = false;
    },
    // 登录组件登录成功时需要更改登录状态
    loginSuccess() {
      this.hasLogin = true;
      this.closeLoginModal();
    },
    // 给聊天组件一个可以开关聊天界面的父方法
    closeChatModal() {
      this.showChatModal = false;
    },
    // 子组件触发事件时需要根据当前登录状态触发登录组件
    openLoginModal() {
      this.showLoginModal = true;
    },
    // 打开我的资料页面
    gotoMyinfor() {
      this.$router.push('/user/account')
    },
  },
}
</script>

<style scoped>
.user-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.user-header {
  height: 80px;
  width: 100%;
  background-color: #FBF5F5;
  box-shadow: 0px 4px 10px 0px rgba(219, 223, 235, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  white-space: nowrap;
}
.user-header-tabs {
  margin-right: 16px;
  font-size: 16px;
  color: #242129;
  padding: 12px 34px;
  cursor: pointer;
}
.user-header-tabs-select {
  color: #b4b4b4;
}
.user-header-user {
  margin-right: 200px;
  height: 56px;
  width: 56px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  box-shadow: 0 0 1px 0 #696969;
  cursor: pointer;
}
.user-header-avatar {
  object-fit: fill;
  height: 56px;
  width: 56px;
  border-radius: 9999px;
}
.user-main {
  flex-grow: 1;
  max-height: calc(100% - 80px);
  overflow: hidden;
  background: linear-gradient(243deg, #F5EAE6 8%, #ECF0F9 90%);
}
@media (max-width: 1024px) or (max-height: 980px) {
    .user-header > div {
        transform: scale(0.8);
        transform-origin: center center; /* 确保从中心缩放 */
        padding: 0;
    }
    .user-header-user {
        margin-right: 100px;
    }
}
</style>
