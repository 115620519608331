<template>
    <div class="h-full mobile-base-background2">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileUserEditInfo.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="users_profix_parent relative animate__animated animate__faster animate__slideInRight">
            <div @mouseover="changeCursor" class="circle-image">
                <img :src="users.avatarUrl" alt="头像" style="padding: 0; margin: 0" @click="handleClick" />
                <input type="file" ref="fileInput" hidden @change="handleFileSelect" />
            </div>
            <img src="../mImage/camera.png" style="position: absolute; bottom: 0; right: 50px" @change="handleFileSelect" />
        </div>
        <div class="mx-6">
            <div class="my-4">
                <hr class="border-t-2 border-gray-200" />
            </div>
            <div class="animate__animated animate__faster animate__slideInRight">
                <div class="grid grid-cols-8 gap-2 text-lg" @click="changeName = true">
                    <span class="col-start-1 col-span-2">{{$t('MobileUserEditInfo.username')}}:</span>
                    <span class="col-span-3 col-end-8 text-right text-gray-500">{{ users.username }}</span>
                    <img src="../images/right.png" class="col-span-1 w-5 h-5 place-self-center col-end-9" />
                </div>
                <div class="my-4 px-8">
                    <hr class="border-t-2 border-gray-100" />
                </div>
                <div class="grid grid-cols-8 gap-2 text-lg" @click="onChangeGender">
                    <span class="col-start-1 col-span-2">{{$t('MobileUserEditInfo.usersex')}}:</span>
                    <span class="col-span-3 col-end-8 text-right text-gray-500">{{ users.usersex }}</span>
                    <img src="../images/right.png" class="col-span-1 w-5 h-5 place-self-center col-end-9" />
                </div>
                <div class="my-4 px-8">
                    <hr class="border-t-2 border-gray-100" />
                </div>
                <div class="grid grid-cols-8 gap-2 text-lg" @click="onChangeBirthdate">
                    <span class="col-start-1 col-span-3">{{$t('MobileUserEditInfo.birthdate')}}:</span>
                    <span class="col-span-4 col-end-8 text-right text-gray-500">{{ new Date(users.birthdate).toLocaleDateString("en-CA") }}</span>
                    <img src="../images/right.png" class="col-span-1 w-5 h-5 place-self-center col-end-9" />
                </div>
                <div class="my-4">
                    <hr class="border-t-2 border-gray-100" />
                </div>
            </div>
        </div>

        <!-- 修改用户名弹窗 -->

        <van-dialog v-model="changeName" :title="$t('MobileUserEditInfo.changeName')" :show-confirm-button="showConfirmButton" show-cancel-button @confirm="updateUsername" @cancel="closeNameModal">
            <div class="flex justify-center items-center text-gray-400 text-xs" v-if="showConfirmButton && changeNameStep">
                <span class="text-center" style="max-width:80%;">{{$t('MobileUserEditInfo.changeNameTip')}}</span>
            </div>
            <div class="flex justify-center m-3" v-if="showConfirmButton && changeNameStep">
                <input type="text" maxlength="10" show-word-limit class="text-center py-1 px-2 border-b border-b-4 border-black" id="nickname" v-model="newuser.username" :placeholder="$t('MobileUserEditInfo.placeholder')" />
            </div>
            <div class="flex justify-center items-center text-gray-400 text-xs" v-if="showConfirmButton && !changeNameStep">
                <span class="text-center" style="max-width:80%;">{{$t('MobileUserEditInfo.changeNameTip2')}}</span>
            </div>
            <div class="flex justify-center items-center text-gray-400 text-xs" v-if="!showConfirmButton">
                <span class="text-center" style="max-width:80%;">{{$t('MobileUserEditInfo.cannotChange')}}:{{lastTime}}</span>
            </div>
        </van-dialog>

        <!-- 修改性别弹窗 -->

        <van-dialog v-model="changeGender" :title="$t('MobileUserEditInfo.changeSex')" show-cancel-button @confirm="updateUsersex">
            <div class="grid grid-cols-8 my-6">
                <label class="col-start-2 col-span-2">
                    <input class="mr-2" type="radio" v-model="newuser.usersex" value="男" />
                    <span>{{$t('MobileUserEditInfo.male')}}</span>
                </label>
                <label class="col-end-6 col-span-2">
                    <input class="mr-2" type="radio" v-model="newuser.usersex" value="女" />
                    <span>{{$t('MobileUserEditInfo.female')}}</span>
                </label>
                <label class="col-end-8 col-span-2">
                    <input class="mr-2" type="radio" v-model="newuser.usersex" value="保密" />
                    <span>{{$t('MobileUserEditInfo.secret')}}</span>
                </label>
            </div>
        </van-dialog>

        <!-- 修改出生日期弹窗 -->

        <van-dialog v-model="changeBirthdate" :show-confirm-button="false">
            <van-datetime-picker v-if="changeBirthdate" v-model="newuser.birthdate" type="date" :title="$t('MobileUserEditInfo.select')" :max-date="maxDate" :min-date="minDate" @confirm="updateBirthdate" @cancel="cancelBirthdate" />
        </van-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "MobileUserEditInfo",
    data() {
        return {
            messages: 0,
            /* 判断是否折叠变量，初始不折叠，false */
            isCollapse: false,
            lastTime: null,
            showConfirmButton: false,
            newuser:{},
            changeName: false,
            changeNameStep: true, // 修改昵称的步骤
            changeGender: false,
            changeBirthdate: false,
            maxDate: new Date(),
            minDate: new Date(1900, 1, 1),
        };
    },
    computed: {
        ...mapState({
            users: (state) => state.user.userInfo,
        }),
    },
    created() {
        this.$store.dispatch("user/fetchUserInfo");
        if(this.users===null) {
            // 如果没有用户信息则跳转到登录页面
            this.$router.push("/mobile/login");
        }
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
        // 获取用户名上次修改时间
        this.uploadLastChangeNameTime();
    },
    methods: {
        /* 初始化加载上次用户名更新时间 */
        uploadLastChangeNameTime() {
            this.newuser= JSON.parse(JSON.stringify(this.users));
            ///user/getUpdateTime 获取上次修改时间
            this.$http
                .get("/v1/user/get-updatetime")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        //获取时间计算是否已经过去了一年？
                        this.lastTime = data.data;
                        if (this.lastTime) {
                            const currentTime = new Date();
                            const savedTime = new Date(this.lastTime);
                            const timeDifference = currentTime - savedTime;
                            const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
                            const hasPassedOneYear = timeDifference >= oneYearInMilliseconds;
                            if (hasPassedOneYear) {
                                this.showConfirmButton = true;
                            } else {
                                this.showConfirmButton = false;
                            }
                        } else {
                            this.showConfirmButton = true;
                        }
                    } else {
                        console.error(data.msg);
                    }
                });
        },
        /* 此处为点击用户头像上传图片的点击事件 */
        handleFileSelect(event) {
            // 获取用户选择的文件对象
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("userphoto", file);
            const loadingToast = this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t('MobileUserEditInfo.alertLoading'),
            });
            this.$http
                .put("/v1/user/update-userphoto", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        loadingToast.clear();
                        this.$message({
                            message: this.$t('MobileUserEditInfo.alertLoadSuccess'),
                            type: "success",
                        });
                        this.$store.dispatch("user/loadAvatar");
                    } else {
                        this.$message.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        handleClick() {
            // 点击时执行相应逻辑
            // 触发文件选择器
            this.$refs.fileInput.click();
        },
        /* 点击修改性别 */
        onChangeGender() {
            this.newuser= JSON.parse(JSON.stringify(this.users));
            this.changeGender = true;
        },
        /* 点击修改生日 */
        onChangeBirthdate() {
            this.newuser= JSON.parse(JSON.stringify(this.users));
            this.newuser.birthdate = new Date(this.newuser.birthdate);
            this.changeBirthdate = true;
        },
        /*确认修改昵称*/
        updateUsername() {
            if (this.changeNameStep) {
                this.changeNameStep = false;
                return;
            }
            // 修改用户信息的请求统一在vuex中进行
            if (this.newuser.username) {
                this.$http
                    .put("/v1/user/update-user", {
                        username: this.newuser.username
                    })
                    .then((response) => {
                        const data = response.data;
                        if (data.status === 200) {
                            this.$store.commit("user/setUserInfo", this.newuser);
                            this.changeName = false;
                            this.$message({
                                message: this.$t('MobileUserEditInfo.alertChangeNameSuccess'),
                                type: "success",
                            });
                        } else {
                            this.$message({
                                message: this.$t('MobileUserEditInfo.alertChangeNameFail'),
                                type: "danger",
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        /* 关闭修改昵称弹窗 */
        closeNameModal() {
            this.changeName = false;
            this.changeNameStep = true;
        },
        /*确认修改性别*/
        updateUsersex() {
            if (this.newuser.usersex) {
                this.$http
                    .put("/v1/user/update-user", {
                        usersex: this.newuser.usersex
                    })
                    .then((response) => {
                        const data = response.data;
                        if (data.status === 200) {
                            this.$store.commit("user/setUserInfo", this.newuser);
                            this.changeGender = false;
                            this.$message({
                                message: this.$t('MobileUserEditInfo.alertChangeSexSuccess'),
                                type: "success",
                            });
                        } else {
                            this.$message({
                                message: this.$t('MobileUserEditInfo.alertChangeSexFail'),
                                type: "danger",
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        /*确认修改生日*/
        updateBirthdate() {
            if (this.newuser.birthdate) {
                this.$http
                    .put("/v1/user/update-user", {
                        birthdate: this.newuser.birthdate.toLocaleDateString("en-CA")
                    })
                    .then((response) => {
                        const data = response.data;
                        if (data.status === 200) {
                            this.$store.commit("user/setUserInfo", this.newuser);
                            this.changeBirthdate = false;
                            this.$message({
                                message: this.$t('MobileUserEditInfo.alertChangeBirthSuccess'),
                                type: "success",
                            });
                        } else {
                            this.$message({
                                message: this.$t('MobileUserEditInfo.alertChangeBirthFail'),
                                type: "danger",
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        /*取消修改生日*/
        cancelBirthdate() {
            this.changeBirthdate = false;
        },
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100%;
    width: 100%;
    overflow: auto;
}

/* 用户头像的父块布局，里面包含了头像图片框和文字 */
.users_profix_parent {
    width: 200px;
    margin: 0 auto; /* 左右自动居中 */
    /* 使用了 flex 布局，并通过 `justify-content: center` 和 `align-items: center` 属性将上下两个块垂直对齐。 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 设置一点边角距离 */
    margin-top: 30px;
}

/* 用户头像布局 */
.users_profix {
    width: auto; /* 设置块的宽度 */
    height: auto; /* 设置块的高度 */
}

/* 用户头像图片设为圆形显示 */
.circle-image {
    width: 100px;
    height: 100px;
    /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
    border-radius: 50%;
    overflow: hidden;
}

/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.circle-image > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}

.popup {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
    background-color: #fefefe;
    margin: 30% auto;
    padding: 20px;
    width: 300px;
    border-radius: 15px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
