<template>
    <div class="finance-report-container" ref="financeReport" style="padding-right:20px;">
        <!-- 基础信息 -->
        <div class="w-full flex flex-row" ref="box">
            <div class="user-card">
                <img :src="users.avatarUrl" class="user-avatar">
                <div class="flex flex-col ml-3">
                    <span class="user-infor-name">{{ users.username }}</span>
                    <span class="user-infor-sex">{{ $t('MyInfor.letter11') }}:{{ setUserSex(users.usersex) }}</span>
                </div>
                <div class="flex-grow flex flex-row justify-end">
                    <div class="land-box">
                        <div class="land-data">{{ user_data.购买土地的单位土地数量 }}</div>
                        <div class="land-name">
                            <img src="../pImage/buy.png" class="land-name-icon">
                            <span class="land-name-text">{{$t('all.purchasedLand')}}</span>
                        </div>
                    </div>
                    <div class="land-box" style="margin-left:22px;">
                        <div class="land-data">{{ user_data.卖出土地的单位土地数量 }}</div>
                        <div class="land-name">
                            <img src="../pImage/sale.png" class="land-name-icon">
                            <span class="land-name-text">{{$t('all.soldLand')}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 虚拟币 -->
            <div class="balance-box">
                <div class="flex flex-row justify-center">
                    <img src="../pImage/balance-vc.png" class="balance-icon">
                    <span class="balance-title">{{$t('all.balanceNum')}}</span>
                </div>
                <div class="balance-num">{{ balance[0] }}.<span style="font-size:20px;">{{ balance[1] }}</span></div>
                <button class="infor1-button infor1-button-whiteground" @click="$router.push('/user/top-up')" style="margin-top:27px;">{{ $t('MobileMyWallet.Recharge') }}</button>
            </div>
        </div>
        <!-- 收益折线图 -->
        <div class="w-full flex flex-row" ref="canvas" style="margin-top:30px;">
            <div class="developer-common-card-box">
                <div class="developer-common-card-title-parent" style="margin-left:38px">
                    <div class="developer-common-card-title">{{$t('all.linechart')}}</div>
                </div>
                <div class="flex flex-row mt-5">
                    <div class="h-full flex flex-col items-center justify-center">
                        <div class="data-card data-card1">
                            <img src="../pImage/income1.png" class="data-card-img">
                            <span class="data-card-title">{{ $t('TotalIncome.l4') }}</span>
                            <span class="data-card-value">{{ user_data.总收益 }}</span>
                        </div>
                        <div class="data-card data-card2" style="margin-top:42px">
                            <img src="../pImage/income2.png" class="data-card-img">
                            <span class="data-card-title">{{ $t('TotalIncome.l5') }}</span>
                            <span class="data-card-value">{{ user_data.昨日新增收益 }}</span>
                        </div>
                    </div>

                    <div class="echar" ref="charts" style="height:240px;width:0;"></div>
                </div>
            </div>
        </div>
        <!-- 平均交易额趋势图 -->
        <div class="w-full flex flex-row" ref="canvas2" style="margin-top:30px;">
            <div class="developer-common-card-box">
                <div class="developer-common-card-title-parent" style="margin-left:38px">
                    <div class="developer-common-card-title">{{$t('all.unitPriceChart')}}</div>
                </div>
                <div class="flex flex-row mt-5">
                    <div class="h-full flex flex-col items-center justify-center">
                        <div class="data-card data-card3">
                            <img src="../pImage/income3.png" class="data-card-img">
                            <span class="data-card-title">{{ $t('TotalIncome.l9') }}</span>
                            <span class="data-card-value">{{ user_data.单位土地均价 }}</span>
                        </div>
                        <div class="data-card data-card4" style="margin-top:42px">
                            <img src="../pImage/income4.png" class="data-card-img">
                            <span class="data-card-title">{{ $t('TotalIncome.l8') }}</span>
                            <span class="data-card-value">{{ user_data.交易土地数据 }}</span>
                        </div>
                    </div>

                    <div class="echar" ref="charts2" style="height:240px;width:0;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    created() {
        this.$http.get('/v1/order/income-data') // 获取收益数据
            .then(res => {
                this.user_data = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        this.$http.get('/v1/business/select-totalincome') // 获取十天内收益趋势折线图数据
            .then(res => {
                this.tradeIncome = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        this.$http.get("/v1/recharge/check-balance") //查询余额
            .then((res) => {
                const value = Number(res.data.data.balance).toFixed(2).toString();
                this.balance = value.split(".");
            })
            .catch((err) => {
                console.log(err);
            });
    },
    mounted() {
        this.$parent.adjustBoxHeight('975px');
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
        const timer = setTimeout(() => {
            if (this.$refs.financeReport.clientHeight) {
                this.$parent.adjustBoxHeight(this.$refs.financeReport.clientHeight + 'px');
            }
        }, 1000);
        this.initChart();
        this.initChart2();
    },
    beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.updateModalScale);
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener('resize', this.updateModalScale);
        next();
    },
    computed: {
        ...mapState({
            users: (state) => state.user.userInfo,
        }),
    },
    data() {
        return {
            balance: ['0','00'], // 虚拟币数量
            user_data: {
                买家数量: 0,
                总收益: 0,
                昨日新增收益: 0,
                平均交易额: 0,
                昨日平均交易额: 0,
                交易土地数据: 0,
                单位土地均价: 0,
                当月单位土地均价: 0
            },
            chartInstance1: null, // 折线图
            tradeIncome: [{
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                wholeincome: 0.0,
                segmentation: 10,
                Listing: 2,
                Sold: 1
            }], // 收益数据
            chartInstance2: null, // 趋势图
        }
    },
    methods: {
        // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal1 = this.$refs.financeReport;
            if (window.innerWidth >= 1180) {
                modal1.style.transform = `scale(1)`;
                modal1.style.height = 'auto';
                modal1.style.width = 'auto';
                this.$parent.controlRightBox(960);
            } else {
                const scaleX = (window.innerWidth - 200) / 980;
                modal1.style.transform = `scale(${scaleX})`;
                modal1.style.height = (window.innerHeight - 150) / scaleX + 'px';
                modal1.style.width = '980px';
                this.$parent.controlRightBox(window.innerWidth - 200);
            }
        },
        setUserSex(sex) {
            // 英文版需要套一层皮显示性别
            if (this.$i18n.locale === 'en') {
                if (sex === '男') {
                    return 'male'
                } else if (sex === '女') {
                    return 'female'
                } else {
                    return 'hidden'
                }
            } else {
                if (sex !== '') {
                    return sex;
                } else {
                    return '未设置';
                }
            }
        },
        initChart(){
            // 获取卡片宽度并设置echarts的画布宽度
            const myDiv = this.$refs.canvas;
            // const width = myDiv.clientWidth;
            const width = 640;
            this.$refs.charts.style.width = width.toString() + 'px';
            // 绘制echarts
            this.chartInstance1 = this.$echarts.init(this.$refs.charts);
            let dates = []; // x轴
            let incomes = []; // y轴
            for (let i=6;i>=0;i--) {
                dates.push(this.tradeIncome[i].date);
                incomes.push(this.tradeIncome[i].income);
            }
            //配置echarts
            const option={
                tooltip: { //echarts提示框配置
                    trigger: 'item', // 触发类型，可选值有 'item'（数据项图形触发）和 'axis'（坐标轴触发）。默认为 'item'。
                    position: 'inside', // 提示框位置，可选值有 'auto'（自动计算位置）、'inside'（数据项内部）、'insideLeft'（数据项左侧）、'insideRight'（数据项右侧）、'insideTop'（数据项顶部）、'insideBottom'（数据项底部）。
                },
                xAxis: {
				    data: dates
			    },
                yAxis:{         //yAxis（Y轴）是配置图表的垂直轴。它用于定义数据在垂直方向上的刻度和标签。
                    type: 'value', // 设置y轴的类型为数值型
                    show: true // 设置y轴是否显示
                },
                series:{        //配置项
                    name: this.$t('TotalIncome.l15'),
				    type: 'line',
                    smooth: true,
				    data: incomes
                },
            }
            this.chartInstance1.setOption(option);
        },
        initChart2(){
            // 获取卡片宽度并设置echarts的画布宽度
            const myDiv = this.$refs.canvas2;
            // const width = myDiv.clientWidth;
            const width = 640;
            this.$refs.charts2.style.width = width.toString() + 'px';
            // 绘制echarts
            this.chartInstance2 = this.$echarts.init(this.$refs.charts2);
            let dates = []; // x轴
            let incomes = []; // y轴
            for (let i=6;i>=0;i--) {
                dates.push(this.tradeIncome[i].date);
                incomes.push(this.tradeIncome[i].income);
            }
            //配置echarts
            const option={
                tooltip: { //echarts提示框配置
                    trigger: 'item', // 触发类型，可选值有 'item'（数据项图形触发）和 'axis'（坐标轴触发）。默认为 'item'。
                    position: 'inside', // 提示框位置，可选值有 'auto'（自动计算位置）、'inside'（数据项内部）、'insideLeft'（数据项左侧）、'insideRight'（数据项右侧）、'insideTop'（数据项顶部）、'insideBottom'（数据项底部）。
                },
                xAxis: {
				    data: dates
			    },
                yAxis:{         //yAxis（Y轴）是配置图表的垂直轴。它用于定义数据在垂直方向上的刻度和标签。
                    type: 'value', // 设置y轴的类型为数值型
                    show: true // 设置y轴是否显示
                },
                series:{        //配置项
                    name: this.$t('TotalIncome.l16'),
				    type: 'line',
                    smooth: true,
				    data: incomes
                },
            }
            this.chartInstance2.setOption(option);
        },
    },
}
</script>

<style scoped>
.finance-report-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    transform-origin: top left;
}
@media (max-width: 1180px) or (max-height: 980px) {
    .finance-report-container {
        overflow-y: auto;
        max-height: none;
    }
}
.user-card {
    width: 700px;
    height: 250px;
    border-radius: 8px;
    background: linear-gradient(180deg, #221A84 0%, #4032EA 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 35px;
}
.user-avatar {
    object-fit: fill;
    width: 70px;
    height: 70px;
    border-radius: 9999px;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
}
.user-infor-name {
    font-size: 22px;
    font-weight: 500;
    color: #FFB300;
}
.user-infor-sex {
    margin-top: 10px;
    font-size: 14px;
    color: #FFFFFF;
}
/* 第一栏左边里面的两个包-购买的土地和卖出的土地 */
.land-box {
    width: 134px;
    height: 134px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
}
.land-data {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    white-space: nowrap;
    max-width: 125px;
    overflow: hidden;
}
.land-name {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-60%, 0);
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
}
.land-name-icon {
    object-fit: contain;
    width: 16px;
}
.land-name-text {
    white-space: nowrap;
    margin-left: 4px;
}
/* 第一栏右边虚拟币的块 */
.balance-box {
    margin-left: 20px;
    width: 260px;
    height: 250px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(25, 66, 182, 0.99) 0%, #396CF8 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.balance-icon {
    object-fit: contain;
    width: 23px;
}
.balance-title {
    margin-left: 6px;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
}
.balance-num {
    margin-top: 33px;
    font-size: 30px;
    font-weight: 500;
    color: #FFFFFF;
}
/* ------------------------------------------第二、三栏-交易列表------------------------------------------- */
.data-card {
    width: 250px;
    height: 99px;
    border-radius: 8px;
    position: relative;
}
.data-card-img {
    position: absolute;
    top: 29px;
    left: 24px;
    object-fit: contain;
    width: 43px;
}
.data-card-title {
    position: absolute;
    top: 26px;
    left: 86px;
    font-size: 14px;
}
.data-card-value {
    position: absolute;
    top: 51px;
    left: 86px;
    font-size: 20px;
    font-weight: 500;
    color: #3D3D3D;
}
.data-card1 {
    background: rgba(255, 179, 0, 0.1);
    color: #FF8E01;
}
.data-card2 {
    background: rgba(0, 198, 255, 0.1);
    color: #00C6FF;
}
.data-card3 {
    background: rgba(64, 50, 234, 0.1);
    color: #4032EA;
}
.data-card4 {
    background: rgba(32, 221, 76, 0.1);
    color: #20DD4C;
}
</style>