<template>
    <div class="h-full w-full flex flex-row justify-center mt-44">
        <div class="flex flex-col pr-12 ml-5 relative">
            <div class="flex flex-row w-full">
                <div class="flex flex-row items-center">
                    <img src="../pImage/market-land.png" class="icon">
                    <span class="title">{{$t('all.recommend')}}</span>
                </div>
            </div>
            <div class="right-box" ref="scrollContainer" @scroll="handleScroll">
                <!-- 骨架屏 -->
                <div class="w-full flex flex-wrap" v-if="loading">
                    <el-skeleton class="private-skeleton" :loading="loading" animated :count="12" :throttle="500">
                        <template slot="template">
                            <div class="flex flex-col" style="width: 235px;margin-top: 16px;margin-left: 15px;">
                                <el-skeleton-item variant="image" style="width: 235px; height: 150px;" />
                                <div style="width: 235px;height: 165px;padding: 16px;">
                                    <el-skeleton-item variant="p" style="width: 60%" />
                                    <el-skeleton-item variant="p" style="width: 50%;margin-top: 40px;" />
                                    <div style="display: flex; align-items: center; justify-items: space-between;margin-top: 40px;">
                                        <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                        <el-skeleton-item variant="text" style="width: 30%;" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </div>
                <LandMarketWindows v-for="(card, index) in apply" :key="index" class="card" :LandInfor="card"></LandMarketWindows>
            </div>
            <div class="back-top" v-show="showBackTop" @click="backTop">
                <i class="el-backtop">
                    <img src="../pImage/backtop-icon.png" style="width:24px;height:20px;">
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import LandMarketWindows from "./LandShopList";
export default {
    components: {
        LandMarketWindows, // 土地市场组件
    },
    props: {
        hasLogin: {
            type: Boolean,
            required: false, // 定义接收的 props 必须
            default: false,
        }
    },
    mounted() { // 初始化
        // 初始化土地市场列表
        this.$http.post("/v1/sale/get-sale-selectlist-recommend",{
            pageNum: 1,
            pageSize: this.pageSize
        })
        .then((res) => {
            const response = res.data.data.rows;
            this.currentPage = 2;
            this.apply = response;
            this.loading = false;
        })
        .catch((error) => {
            this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
        });
    },
    data() {
      return {
        loading: true, // 骨架屏
        showLoginModal: false, // 登录组件
        flag: false, // 防抖，滚动条加载数据需要有一段时间不触发，true代表处于防抖状态
        currentPage: 1, // 土地市场页码
        pageSize: 20, // 每页有多少条数据
        productNum: 0, // 商品总量，初始化时根据后端获取
        apply: [],
      };
    },
    methods: {
        initProduct() { // 初始化显示的数据
            this.$http
                .post("/v1/sale/get-sale-selectlist-recommend",{
                    pageNum: 1,
                    pageSize: this.pageSize
                })
                .then((res) => {
                    this.currentPage = 2;
                    this.apply = res.data.data.rows;
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        loadProduct() { // 未登录时加载推荐商品
            this.$http
                .post("/v1/sale/get-sale-selectlist-recommend",{
                    pageNum: this.currentPage,
                    pageSize: this.pageSize
                })
                .then((response) => {
                    this.currentPage += 1;
                    this.apply = this.apply.concat(res.data.data);
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        // 关闭登录组件
        closeLoginModal() {
            this.showLoginModal = false;
        },
        // 当滚动条达到一定距离时加载新的商品数据
        handleScroll() {
            if (this.flag) { // 防抖
                return;
            }
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;

            // 判断滚动条距离底部的距离是否达到一定范围
            if (scrollHeight - (scrollTop + clientHeight) < 100) {
                this.flag = true;
                this.currentPage++;
                this.loadProduct();
                setTimeout(()=>{
                    // 延时一秒
                    this.flag = false;
                } ,1000)
            }
        },
        showBackTop() {
            const scrollContainer = this.$refs.scrollContainer;
            if (scrollContainer) {} else { return; }
            const scrollTop = scrollContainer.scrollTop;
            if (scrollTop >= 100) {
                return true;
            } else {
                return false;
            }
        },
        backTop() {
            let n = 0;
            const LENGTH = this.$refs.scrollContainer.scrollTop * 0.1;
            let timer = setInterval(() => {
                n++;
                this.$refs.scrollContainer.scrollTop -= LENGTH;
                if (n >= 10) {
                    clearInterval(timer);
                }
            }, 10);
        },
        turnToDetail(LandInfor) {
            if (!this.hasLogin) { // 未登录时打开登录框
                this.$parent.openLoginModal();
                return;
            }
            this.$router.push({
                path: '/user/land-market/detail',
                query: {
                    land: LandInfor.originallandid,
                    merch: LandInfor.id
                }
            });
        },
    },
}
</script>

<style scoped>
.mt-44 {
    margin-top: 44px;
}
.icon {
    height: 25px;
    object-fit: contain;
}
.title {
    margin-left: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #3D3D3D;
}
.left-box {
    margin-top: 22px;
    width: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 100px 16px;
    background-color: #FFFFFF;
}
.quick-filter {
    width: 100%;
    border-bottom: 1px solid rgba(216, 216, 216, 0.3);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
}
.quick-filter-title {
    font-size: 14px;
    color: #999999;
    margin-bottom: 16px;
}
.quick-filter-subhead {
    margin-bottom: 8px;
    font-size: 12px;
    color: #E5E5E5;
}
.quick-filter-input {
    height: 32px;
    width: 80px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #0256FF;
    background-color: #FFFFFF;
}
.right-box {
    width: 1020px; /* 为了适应滚动条，加了20px */
    max-height: 800px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.card {
  width: 235px;
  height: 315px;
  margin: 0 0 0 20px;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
}
.card:nth-child(4n+1) {
  margin-left: 0;
}
.card:nth-child(n+5) {
  margin-top: 16px;
}
.back-top {
  position: absolute;
  bottom: 30px;
  right: 0;
  border-radius: 9999px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, .12);;
  cursor: pointer;
}

.private-skeleton {
    width: 100%;
}
.private-skeleton >>> .el-skeleton {
    display: flex;
    flex-wrap: wrap;
}
</style>