<template>
    <div class="developer-common-container">
        <div class="developer-goback-parent" @click="$router.go(-1)">
            <div class="developer-goback-icon">
                <img src="../developer/developer-goback.png">
            </div>
            <span>{{$t('OrderList.title')}}</span>
        </div>
        <div class="w-full flex-grow" style="margin-top:30px;">
            <div class="developer-search-container">
                <div class="developer-search-box">
                    <div class="developer-search-input">
                        <span class="developer-search-input-label">{{$t('LandSellList.blocks')}}</span>
                        <el-input v-model="search.landid" :placeholder="$t('OrderList.l1')" style="width:250px;"></el-input>
                    </div>
                    <div class="developer-search-input" style="margin-top: 20px;">
                        <span class="developer-search-input-label">{{$t('LandSellList.goin')}}</span>
                        <el-input v-model="search.areaid" :placeholder="canInputAreaid?$t('OrderList.l2'):$t('OrderList.l2.1')" :disabled="canInputAreaid" style="width:250px;"></el-input>
                    </div>
                </div>
                <div class="developer-search-box" style="margin-left:15px;">
                    <div class="developer-search-input">
                        <span class="developer-search-input-label">{{$t('OrderList.l3')}}</span>
                        <el-input v-model="search.buyerid" :placeholder="$t('OrderList.l4')" style="width:250px;"></el-input>
                    </div>
                    <div class="developer-search-input" style="margin-top: 20px;">
                        <span class="developer-search-input-label">{{$t('OrderList.l5')}}</span>
                        <el-date-picker
                            v-model="search.ordertime"
                            type="daterange"
                            align="right"
                            unlink-panels
                            :range-separator="$t('LandSellList.mergename')"
                            :start-placeholder="$t('LandSellList.price')"
                            :end-placeholder="$t('LandSellList.saletime')"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="developer-search-box items-end" style="flex:1;">
                    <div class="developer-search-buttonbox developer-search-input" style="height:100%;width:auto;">
                        <el-button type="primary" @click="updatePage">
                            <img src="../developer/developer-search.png" class="admin-manage-search-icon">
                            <span style="vertical-align: middle;">{{$t('LandSellList.unitdetail')}}</span>
                        </el-button>
                        <el-button type="info" plain style="margin-left: 20px;" @click="resetUser">
                            <img src="../developer/developer-refresh.png" class="admin-manage-search-icon">
                            <span style="vertical-align: middle;">{{$t('LandSellList.area')}}</span>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="developer-tabs-content">
                    <el-row :gutter="15" class="developer-tabs-content-row">
                        <el-col :span="4"><span>{{$t('OrderList.l6')}}</span></el-col>
                        <el-col :span="2"><span>{{$t('OrderList.l7')}}</span></el-col>
                        <el-col :span="2"><span>{{$t('OrderList.l8')}}</span></el-col>
                        <el-col :span="3"><span>{{$t('OrderList.l9')}}</span></el-col>
                        <el-col :span="5"><span>{{$t('OrderList.l10')}}</span></el-col>
                        <el-col :span="3"><span>{{$t('OrderList.l11')}}</span></el-col>
                        <el-col :span="2"><span>{{$t('OrderList.l12')}}</span></el-col>
                        <el-col :span="3"><span>{{$t('OrderList.l13')}}</span></el-col>
                    </el-row>
                    <div class="flex-grow w-full">
                        <el-skeleton :rows="10" :loading="loading" animated></el-skeleton>
                        <div class="developer-tabs-content-list" v-if="!loading">
                            <el-row v-for="(apply, index) in view" :key="index" class="developer-tabs-content-row" :style="hoveredIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="hoveredIndex = index" @mouseleave="hoveredIndex = -1">
                                <div class="developer-tabs-content-col">
                                    <el-col :span="4"><span style="word-wrap: break-word;">{{ apply.separateOrderId }}</span></el-col>
                                    <el-col :span="2"><span style="word-wrap: break-word;">{{ apply.unitLand.originallandid }}</span></el-col>
                                    <el-col :span="2"><span style="word-wrap: break-word;">{{ apply.unitlandid }}</span></el-col>
                                    <el-col :span="3"><span style="word-wrap: break-word;">{{ apply.landname }}</span></el-col>
                                    <el-col :span="5">
                                        <span style="word-wrap: break-word;" v-if="$setPayment === 'am'">${{ apply.totalprice }}</span>
                                        <span style="word-wrap: break-word;" v-else-if="$setPayment === 'zh'">￥{{ apply.totalprice * $exchangeRate }}</span>
                                    </el-col>
                                    <el-col :span="3"><span style="word-wrap: break-word;">{{ apply.ordertime }}</span></el-col>
                                    <el-col :span="2"><span style="word-wrap: break-word;">{{ apply.unitLand.landblocks }}</span></el-col>
                                    <el-col :span="3" style="word-wrap: break-word;">
                                        <span @mouseover="changeCursor" style="text-decoration: underline;" @click="gotoChat(apply.onSale.id,apply.buyerid)">{{$t('OrderList.l14')}}</span>
                                        <!-- &nbsp;
                                        <span @mouseover="changeCursor" style="text-decoration: underline;" @click="alert('暂无此功能')">反馈/投诉该订单</span> -->
                                    </el-col>
                                </div>
                            </el-row>
                        </div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$http.post('/v1/business/select-transaction-list-more', {
            pagesize: this.pageSize,
            pagenum: this.currentPage
        }).then(res => {
            this.view = res.data.data.rows;
            this.showLength = res.data.data.total;
            this.loading = false;
        }).catch(error => {
            this.$toast.fail(this.$t('LandSellList.tip6'))
        });
    },
    watch: {
        'search.landid': {
            handler(newVal, oldVal) {
                if (newVal !== '') {
                    this.canInputAreaid = false;
                } else {
                    this.canInputAreaid = true;
                }
            },
            deep: true
        }
    },
    data() {
      return {
        loading: true, // 加载中
        apply: [{
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },], // 测试数据,后端获取的数据

        view: null, // 测试数据,显示的数据



        pageSize: 50, // 每页条数
        currentPage: 1,// 页码
        showLength: 0, // 总条数
        hoveredIndex: -1, // 鼠标悬浮到的条目

        search: {
            landid: '',
            areaid: '',
            buyerid: '',
            ordertime: '',
        },
        canInputAreaid: true, // 是否可输入所属小区,true是封禁，false是解封
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: this.$t('LandSellList.tip2'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('LandSellList.tip3'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('LandSellList.tip4'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        updatePage() { // 更新显示的数据
            let data = {
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.landid !== '') {
                data["landid"] = this.search.landid;
            }
            if(this.search.areaid !== '') {
                data["communitynumber"] = this.search.areaid;
            }
            if(this.search.buyerid !== '') {
                data["buyerid"] = this.search.buyerid;
            }
            if(this.search.ordertime !== '') {
                data["starttime"] = this.search.ordertime[0];
                data["endtime"] = this.search.ordertime[1];
            }
            
            this.$http.post('/v1/business/select-transaction-list-more', data)
            .then(res => {
                this.view = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail(this.$t('LandSellList.tip6'))
            });
        },
        resetUser() {
            this.search.landid = '';
            this.search.areaid = '';
            this.search.buyerid = '';
            this.search.ordertime = '';
            this.updatePage();
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loading = true;
            this.updatePage();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val
            this.loading = true;
            this.updatePage();
        },
        showModal(id) {
            this.$router.push('/developer/my-sale/detail?land=' + id);
        },
        gotoChat(id1,id2) { // 联系买家
            localStorage.setItem("developer-message", '来自用户');
            this.$router.push('/developer/message/send?mid=' + id1 + '&id=' + id2);
        }
    },
}
</script>

<style>
/* --------------------------------------------选项卡样式-------------------------------------------- */
.developer-tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.developer-tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #207aac;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.developer-tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 18px;
    font-weight: bold;
}
.developer-tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.developer-tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.developer-tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.developer-tabs-content {
    margin-top: 30px;
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;
}
/* 信息栏样式 */
.developer-tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #788AA1;
    position: relative;
}
.developer-tabs-content-list {
    max-height: 100%;
    overflow-y: auto;
}
.developer-tabs-content-col {
    height: 40px;
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
}
</style>