<template>
    <div class="flex h-screen flex-col mobile-background-base base-p-16px">
        <div class="flex flex-row items-center">
            <img src="../mImage/login-back.png" class="justify-start" style="height:25px;width:25px;" @click="goBack">
            <div class="mobile-goback-title">{{$t('computerlogin.register')}}</div>
        </div>
        <div style="margin-top:28px;">
            <img src="../icons/logo.png" style="object-fit: contain;height: 25px;cursor: pointer;" @click="backToHomePage" />
        </div>
        <!-- 两种模式注册的父层，需要relative来确定内部的相对位置 -->
        <div class="w-full relative flex-grow overflow-auto">
            <!-- 手机注册标签和邮箱注册标签 -->
            <div class="absolute" style="top:40px;left:16px;">
                <span :class="mobileRegister?'register-tab-select':'register-tabs'" @click="changeMode(true)">{{$t('mobileLogin.phoneRegistrationLabel')}}</span>
            </div>
            <div class="absolute" style="top:40px;left:112px;">
                <span :class="mobileRegister?'register-tabs':'register-tab-select'" @click="changeMode(false)">{{$t('mobileLogin.emailRegistrationLabel')}}</span>
            </div>
            <!-- 为了正常显示ui搞的这个b图只能加这么个东西 -->
            <div class="absolute" style="top:68px;" :style="mobileRegister?'left:21px;':'left:116px;'">
                <img src="../mImage/login-icon.png" style="height:30px;width:60px;">
            </div>


            <!-- 邮箱注册 -->
            <div class="my-tab" v-if="!mobileRegister" ref="contentBox">
                <!-- 邮箱输入区域 -->
                <div class="mobile-base-input-parent" style="width: 100%;">
                    <img src="../developer/account-email.png" class="mobile-base-input-icon">
                    <input class="mobile-base-input" v-model="mobile.email" :placeholder="$t('mobileLogin.emailRegistrationEmailInput')">
                </div>
                <!-- 验证码输入区域 -->
                <div class="mobile-base-input-parent w-full" style="margin-top:16px;">
                    <img src="../developer/account-password.png" class="mobile-base-input-icon">
                    <input type="text" v-model="mobile.code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                    <div class="mobile-base-input-code-parent">
                        <div class="mobile-base-input-code-button" @click="openModal" v-if="mobile.timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                        <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ mobile.timeCount }}s)</div>
                    </div>
                </div>
                <!-- 密码输入区域 -->
                <div class="mobile-base-input-parent relative" style="width: 100%;margin-top:20px;">
                    <img src="../developer/account-password.png" class="mobile-base-input-icon">
                    <input :type="showPassword ? '' : 'password'" class="mobile-base-input" v-model="mobile.password" :placeholder="$t('mobileLogin.passwordInput')" @input="mobileCheckPassword" @focus="loadCodeImg">
                    <button class="absolute right-0 top-0 bottom-0 mr-5 focus:outline-none" @click="togglePassword">
                        <img src="../images/not-visible.png" class="h-5" v-if="!showPassword" alt="Show Password" />
                        <img src="../images/visible.png" class="h-5" v-if="showPassword" alt="Hide Password" />
                    </button>
                </div>
                <!-- 密码错误提示 -->
                <div class="flex flex-col">
                    <!-- 第一行 -->
                    <div class="flex flex-row items-center mt-3">
                        <img src="../pImage/check-error.png" class="check-icon" v-if="showRules1" />
                        <img src="../pImage/check-right.png" class="check-icon" v-else />
                        <span class="password-tips" :style="showRules1 ? '' : 'color: rgba(0, 0, 0, 0.4);'" >{{ $t('AccountPassword.tip1') }}</span>
                    </div>
                    <!-- 第二行 -->
                    <div class="flex flex-row items-center mt-3">
                        <img src="../pImage/check-error.png" class="check-icon" v-if="showRules2" />
                        <img src="../pImage/check-right.png" class="check-icon" v-else />
                        <span class="password-tips" :style="showRules2 ? '' : 'color: rgba(0, 0, 0, 0.4);'" >{{ $t('AccountPassword.tip2') }}</span>
                    </div>
                    <!-- 第三行 -->
                    <div class="flex flex-row items-center mt-3">
                        <img src="../pImage/check-error.png" class="check-icon" v-if="showRules3" />
                        <img src="../pImage/check-right.png" class="check-icon" v-else />
                        <span class="password-tips" :style="showRules3 ? '' : 'color: rgba(0, 0, 0, 0.4);'" >{{ $t('AccountPassword.tip3') }}</span>
                    </div>
                </div>
                <!-- 同意协议 -->
                <div class="bottom-1 text-sm mt-5 mb-2">
                    <van-checkbox icon-size="14px" v-model="accept">
                        {{$t('mobileLogin.licenseNumber')}}
                        <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.businessLicense')}}</a>
                        {{$t('mobileLogin.and')}}
                        <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.copyright')}}</a>
                    </van-checkbox>
                </div>
            </div>


            <!-- 手机号注册 -->
            <div class="my-tab" v-else-if="mobileRegister" ref="contentBox">
                <!-- 手机号输入区域 -->
                <div class="mobile-base-input-parent w-full">
                    <img src="../developer/account-phone.png" class="mobile-base-input-icon">
                    <!-- 国际号选择区域 -->
                    <div class="flex flex-row items-center text-sm font-bold mx-1" @click="$parent.openIntlPhoneNumberModal">
                        {{ mobile.phoneCountry }}
                        <div class="flex-grow flex justify-end"><img src="../developer/developer-land-dropdown.png" style="height:16px;width:16px;"></div>
                    </div>
                    <input type="tel" class="mobile-base-input" v-model="mobile.phone" :placeholder="$t('mobileLogin.codeLoginPhoneInput')">
                </div>
                <!-- 验证码输入区域 -->
                <div class="mobile-base-input-parent w-full" style="margin-top:16px;">
                    <img src="../developer/account-password.png" class="mobile-base-input-icon">
                    <input type="text" v-model="mobile.code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                    <div class="mobile-base-input-code-parent">
                        <div class="mobile-base-input-code-button" @click="openModal" v-if="mobile.timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                        <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ mobile.timeCount }}s)</div>
                    </div>
                </div>
                <div class="bottom-1 text-sm mt-5 mb-2">
                    <van-checkbox icon-size="14px" v-model="accept">
                        {{$t('mobileLogin.licenseNumber')}}
                        <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.businessLicense')}}</a>
                        {{$t('mobileLogin.and')}}
                        <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.copyright')}}</a>
                    </van-checkbox>
                </div>
            </div>

            <div class="absolute w-full" :style="{ top: overlayTop }">
                <!-- 手机号注册按钮 -->
                <button class="mobile-button-full-blue" @click="mobilePhoneRegistration" v-if="mobileRegister">{{$t('mobileLogin.register')}}</button>
                <!-- 邮箱注册获取验证码及登录按钮 -->
                <button class="mobile-button-full-blue" @click="mobileEmailRegistration" v-if="!mobileRegister">{{$t('mobileLogin.emailRegister')}}</button>
            </div>
        </div>

        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <!-- 该弹窗需要用户输入图像验证码来获取手机验证码或邮箱验证码 -->
            <div class="developer-common-modal-box">
                <!-- 标题 -->
                <div class="w-full text-center mb-3">{{$t('mobileLogin.safeVerify')}}</div>
                <!-- 图像验证码输入区，上为图片，下为输入框 -->
                <div class="flex flex-col">
                    <div class="w-full flex justify-center">
                        <img @click="loadCodeImg" style="width:150px;" :src="imgCodeUrl" alt="验证码"/>
                    </div>
                    <div class="account-modal-input-parent" style="margin-top:20px;">
                        <!--            请输入图像验证码-->
                        <img src="../developer/account-password.png" class="account-modal-input-icon">
                        <input type="text" v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="account-modal-input" />
                    </div>
                </div>
                <!-- 按钮，关闭弹窗和获取验证码 -->
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" @click="closeModal">{{$t('forgetPassword.no')}}</button>
                    <button class="developer-common-button2" style="margin-left:20px;" @click="getCode">{{$t('mobileLogin.getCode')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.lastLoginMethod = localStorage.getItem("loginMethod");
        if (this.lastLoginMethod === "phone") {
            this.mobileRegister = true;
        } else {
            this.mobileRegister = false;
        }
        this.updateOverlayPosition();
    },
    data() {
        return {
            showPassword: false, // 改变密码显示/隐藏状态的参数

            showRules1: true, // 检查密码格式是否正确-仅在注册和忘记密码中显示
            showRules2: true,
            showRules3: true,

            mobileRegister: false, // 手机号注册模式,true为手机号,false为邮箱

            isCode: true, // 是否可以获取验证码,初始为true,表示可以获取验证码,false时变为重新发送倒计时
            imgCode: "", // 手机端图像验证码
            imgCodeUrl: null, // 手机端图像验证码的url
            vmodal: false, // 显示安全验证的弹窗
            step: 1, // 安全验证的步骤
            mobile: {
                account: "", // 三合一版账号
                email: "", // 手机端邮箱登录/注册时输入的邮箱统一为这个
                phone: "", // 手机端手机号登录/注册时输入的手机号统一为这个
                phoneCountry: '+1', // 手机号国际号
                username: "", // 用户名，同上
                password: "", // 手机端邮箱以及手机号登录/注册时输入的密码统一为这个
                code: "", // 手机端注册输入的验证码统一为这个
                timeCount: 0, // 手机端获取验证码后的倒计时
                timer: null, // 手机端计时器
            },

            accept: false, // 选择是否同意协议

            lastLoginMethod: "", // 根据用户上一次登录方式自动选择登录方式

            overlayTop: '355px', // 下方按钮位置属性
        };
    },
    methods: {
        /* --------------------------------------------自适应样式-------------------------------------------------- */
        updateOverlayPosition() {
            // 获取当前显示的卡片高度
            this.$nextTick(() => {
                const cardElement = this.$refs.contentBox ? this.$refs.contentBox : null;
                if (cardElement) {
                    const cardHeight = cardElement.clientHeight; // 获取卡片高度
                    this.overlayTop = `${cardHeight + 100}px`;   // 更新按钮位置
                } else {
                    this.overlayTop = '355px'; // 如果没有卡片，保持初始位置
                }
            });
        },
        /* --------------------------------------------通用------------------------------------------------ */
        /* 返回 */
        goBack() {
            this.$parent.fatherMethod();
        },

        /* 点击眼睛图标显示/隐藏密码 */
        togglePassword() {
            this.showPassword = !this.showPassword;
        },

        /* 手机端登录/注册点击密码输入框后检查密码格式是否错误 */
        mobileCheckPassword() {
            const re1 = /^.{8,16}$/
            const re21 = /^(?=.*[A-Za-z])(?=.*\d).{2,}$/ // 包含字母和数字
            const re22 = /^(?=.*[A-Za-z])(?=.*[-+_!@#$%^&*.,?]).{2,}$/ // 包含字母和符号
            const re23 = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/ // 包含数字和符号
            const re24 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/ // 包含数字、字母和符号
            const re3 = /\s/ // 包含空格
            this.showRules1 = !re1.test(this.mobile.password)
            if (re21.test(this.mobile.password) || re22.test(this.mobile.password) || re23.test(this.mobile.password) || re24.test(this.mobile.password)) {
                this.showRules2 = false
            } else {
                this.showRules2 = true
            }
            this.showRules3 = re3.test(this.mobile.password)
        },

        /*加载图像验证码*/
        loadCodeImg(){
            ///register/captcha
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                }).catch(err => {})
        },

        backToHomePage() {
            this.$router.push('/')
        },

        /* --------------------------------------------事件------------------------------------------------ */
        // 切换邮箱注册和手机号注册
        changeMode(boolean) {
            this.mobileRegister = boolean;
            this.updateOverlayPosition();
        },


        /* 点击获取验证码 */
        openModal() {
            this.loadCodeImg();
            this.vmodal = true;
        },


        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.mobile.timeCount = 60;
            this.isCode = false;
            // 计时器
            this.mobile.timer = setInterval(() => {
                if (this.mobile.timeCount > 0) {
                    this.mobile.timeCount--;
                } else {
                    this.isCode = true;
                    clearInterval(this.mobile.timer);
                    this.mobile.timer = null;
                }
            }, 1000);
        },

        closeModal() {
            this.vmodal = false;
        },

        // 根据模式判断将验证码发送到手机邮件还是邮箱
        getCode() {
            if (this.mobileRegister) { // 手机号
                this.getMobilePhoneCode();
            } else { // 邮箱
                this.getEmailCode();
            }
        },

        /* 手机端的手机号注册页面点击获取验证码 */
        getMobilePhoneCode() {
            // 手机端点击获取验证码
            if (this.mobile.phone.length === 0) {
                this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'));
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            this.$http.post('/v1/index/get-verification-code?useremail=' + this.mobile.phoneCountry + '-' + this.mobile.phone + '&code=' + this.imgCode)
                .then(res => {
                    loadingToast.clear();
                    this.startCountdown();
                    if (res.data.status === 200) {
                        this.vmodal = false;
                        this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
                })
        },

        /* 手机号注册按钮事件 */
        mobilePhoneRegistration() {
            if (this.mobile.phone.length === 0) {
                this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'));
                return;
            }
            if (this.mobile.code === "") {
                this.$toast(this.$t('mobileLogin.alertNoCodeInput'));
                return;
            }
            if (!this.accept) {
                this.$toast(this.$t('mobileLogin.alertNoAgreement'));
                return;
            }
            const data = {
                userphone: this.mobile.phoneCountry + '-' + this.mobile.phone,
                remark: this.mobile.code,
            };
            this.$http
                .post("/v1/register/userphone-register", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    // 处理返回值
                    if (res.data.status === 200) {
                        this.$router.push("/mobile?index=1");
                    } else {
                        if (res.data.data === '图形验证码错误') {
                            this.$toast.fail(this.$t('mobileLogin.alertCodeError'));
                        } else {
                            this.$toast.fail(this.$t('mobileLogin.alertAccountHaveRegister'));
                        }
                    }
                    this.loadCodeImg();
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },

        /* 手机端的邮箱注册页面点击获取验证码 */
        getEmailCode() {
            // 手机端点击获取验证码
            if (this.mobile.email.length === 0) {
                this.$toast(this.$t('mobileLogin.emailRegistrationEmailInput'));
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            this.$http.post('/v1/index/get-verification-code?useremail=' + this.mobile.email + '&code=' + this.imgCode)
                .then(res => {
                    loadingToast.clear();
                    this.startCountdown();
                    if (res.data.status === 200) {
                        this.vmodal = false;
                        this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
                })
        },

        /* 手机端的邮箱注册 */
        mobileEmailRegistration() {
            if (!this.mobile.email.includes("@")) {
                this.$toast(this.$t('mobileLogin.alertEmailError'));
                return;
            }
            if (this.mobile.password === "" || this.showRules1 || this.showRules2 || this.showRules3) {
                this.$toast(this.$t('mobileLogin.alertPasswordError'));
                return;
            }
            if(this.imgCode===""){
                this.$toast(this.$t('mobileLogin.alertNoCodeInput'));
                return;
            }
            if (!this.accept) {
                this.$toast(this.$t('mobileLogin.alertNoAgreement'));
                return;
            }

            const data = {
                useremail: this.mobile.email,
                userpassword: this.mobile.password,
                remark: this.imgCode
            };
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            this.$http
                .post("/v1/register/email-register", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    loadingToast.clear();
                    if(res.data.status===200){
                        this.$toast(this.$t('mobileLogin.alertSendSuccessfully'));
                    }
                    else {
                        if (res.data.data === '图形验证码错误') {
                            this.$toast.fail(this.$t('mobileLogin.alertCodeError'));
                        } else {
                            this.$toast.fail(this.$t('mobileLogin.alertAccountHaveRegister'));
                        }
                    }
                    this.loadCodeImg();
                })
                .catch((error) => {
                    this.vmodal = false;
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },
    },
};
</script>

<style>
/* ------------------------------------------------以下是手机端---------------------------------------------------- */
/* 模式标签样式 */
.register-tabs {
    font-size: 14px;
    font-weight: normal;
    color: rgba(61, 61, 61, 0.6);
}
.register-tab-select {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    box-shadow: inset 0 -4px 0 0 rgba(54, 98, 236, 0.6);
}
.my-tab {
    width: 100%;
    min-height: 40%;
    padding: 32px 16px;
    top: 85px;
    left: 0;
    @apply absolute rounded-lg shadow-md bg-white flex flex-col items-center justify-center;
}
.password-tips {
  margin-left: 3px;
  font-size: 14px;
  color: red;
  white-space: normal;
}
</style>
