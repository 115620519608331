<template>
  <div class="main-page-container">
    <div class="developer-common-card-box">
        <div class="developer-common-card-title-parent">
            <div class="developer-common-card-title">{{$t('useraccount.accountinfor')}}</div>
            <div class="developer-common-card-button-parent">
                <button class="developer-common-card-button" @click="$router.push('/developer/total-income')">{{$t('useraccount.report')}}</button>
            </div>
        </div>
        <div class="home-account-item items-center" style="margin-top:20px;">
            <div class="flex items-center" style="width:25%;">
                <div class="home-account-bottom-box1">
                    <div class="home-account-bottom-box1-item">
                        <img class="home-account-bottom-box1-totalIncome-img" src="../developer/developer-home-income.png">
                        <span class="home-account-bottom-box1-totalIncome">{{$t('useraccount.totalIncome')}}</span>
                    </div>
                    <div class="home-account-bottom-box1-item">
                        <span style="font-size: 45px;">{{$t('useraccount.unitprice')}} {{ user_data.总收益 }}</span>
                    </div>
                    <div class="home-account-bottom-box1-item">
                        <img style="height:40px;width:40px;" src="../developer/developer-home-up.png" v-if="user_data.昨日新增收益 >= 0">
                        <img src="../developer/developer-home-down.png" style="height:40px;width:40px;" v-else>
                        <div class="flex flex-col ml-2">
                            <span>{{$t('useraccount.unitprice')}} {{ user_data.昨日新增收益 }}</span>
                            <span style="font-size: 10px;color: #788AA1;">{{$t('useraccount.dayincome')}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center" style="width:25%;">
                <div class="home-account-bottom-box2">
                    <img src="../developer/developer-home-buyer-icon.png" class="home-account-bottom-icon1">
                    <span class="home-account-bottom-text1">{{$t('useraccount.buyer')}}</span>
                    <img src="../developer/developer-home-up.png" class="home-account-bottom-icon2">
                    <img src="../developer/developer-home-down.png" class="home-account-bottom-icon2">
                    <span class="home-account-bottom-text2">{{ user_data.买家数量 }}{{$t('useraccount.person')}}</span>
                    <div class="home-account-bottom-icon3">{{$t('useraccount.yesterday')}}+3</div>
                </div>
            </div>
            <div class="flex justify-center items-center" style="width:25%;">
                <div class="home-account-bottom-box3">
                    <img src="../developer/developer-home-trade-icon.png" class="home-account-bottom-icon1">
                    <span class="home-account-bottom-text1">{{$t('all.monthlyIncome')}}</span>
                    <img src="../developer/developer-home-up.png" class="home-account-bottom-icon2" v-if="(user_data.本月收益额 - user_data.上月收益额) >= 0">
                    <img src="../developer/developer-home-down.png" class="home-account-bottom-icon2" v-else>
                    <span class="home-account-bottom-text2">{{$t('useraccount.unitprice')}} {{ user_data.本月收益额 }}</span>
                    <div class="home-account-bottom-icon3">{{$t('useraccount.lastMonth')}}<span v-if="(user_data.本月收益额 - user_data.上月收益额) >= 0">{{ '+' }}</span>{{ user_data.本月收益额 - user_data.上月收益额 }}</div>
                </div>
            </div>
            <div class="flex justify-center items-center" style="width:25%;">
                <div class="home-account-bottom-box4">
                    <img src="../developer/developer-home-land-icon.png" class="home-account-bottom-icon1">
                    <span class="home-account-bottom-text1">{{$t('all.monthlyExpend')}}</span>
                    <img src="../developer/developer-home-up.png" class="home-account-bottom-icon2" v-if="user_data.本月支出额 - user_data.上月支出额">
                    <img src="../developer/developer-home-down.png" class="home-account-bottom-icon2" v-else>
                    <span class="home-account-bottom-text2">{{ user_data.本月支出额 }}</span>
                    <div class="home-account-bottom-icon3">{{$t('useraccount.lastMonth')}}<span v-if="(user_data.本月收益额 - user_data.上月收益额) >= 0">{{ '+' }}</span>{{ user_data.本月支出额 - user_data.上月支出额 }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="developer-common-card-box" style="margin-top:30px;">
        <div class="developer-common-card-title-parent">
            <div class="developer-common-card-title">{{$t('useraccount.myRealEstate')}}</div>
            <div class="developer-common-card-button-parent">
                <button class="developer-common-card-button" @click="uploadland">{{$t('useraccount.landUpload')}}</button>
            </div>
        </div>
        <div class="home-account-item" style="margin-top:20px;">
            <div class="form-item-cells">
                <div style="height:40px;width:100%;color: #788AA1;">
                    <el-col :span="4"><span>{{$t('useraccount.originalid')}}</span></el-col>
                    <el-col :span="6"><span>{{$t('useraccount.landName')}}</span></el-col>
                    <el-col :span="6"><span>{{$t('useraccount.developersName')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('useraccount.statu')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('useraccount.control')}}</span></el-col>
                </div>
                <div class="home-account-data">
                    <el-row v-for="(land, index) in users_land" :key="index" style="width:100%;">
                        <div class="users-information-col" :style="hoveredIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="hoveredIndex = index" @mouseleave="hoveredIndex = -1">
                            <el-col :span="4"><span>{{ land.originallandid }}</span></el-col>
                            <el-col :span="6"><span>{{ land.originallandname }}</span></el-col>
                            <el-col :span="6"><span>{{ land.detailedaddress }}</span></el-col>
                            <el-col :span="4"><span :style="setStatuColor(land.landstatus)">{{ land.landstatus }}</span></el-col>
                            <el-col :span="4">
                                <span @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.landstatus === '已上线'" @click="gotoland(land.originallandid)">{{$t('useraccount.goToLand')}}</span>
                                &nbsp;
                                <span @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.landstatus === '已上线'" @click="showModal(land.originallandid)">{{$t('useraccount.downline')}}</span>
                                <span @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.landstatus === '待审核' || land.landstatus === '已驳回'" @click="gotolandregister(land.originallandid,land.landstatus)">{{$t('useraccount.progress')}}</span>
                                <span @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.landstatus === '已下线'" @click="reloadland(land.originallandid)">{{$t('useraccount.online')}}</span>
                                &nbsp;
                                <span @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.landstatus === '已上线'" @click="checkLand(land.originallandid)">{{$t('useraccount.certificate')}}</span>
                            </el-col>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
    <div class="developer-common-card-box" style="margin-top:30px;">
        <div class="developer-common-card-title-parent">
            <div class="developer-common-card-title">{{$t('useraccount.transaction')}}</div>
            <div class="developer-common-card-button-parent">
                <button class="developer-common-card-button" @click="gotoTrade">{{$t('useraccount.detailedTransaction')}}</button>
            </div>
        </div>
        <div class="home-account-item" style="margin-top:20px;">
            <div class="form-item-cells">
                <div style="height:40px;width:100%;color: #788AA1;">
                    <el-col :span="4"><span>{{$t('useraccount.order')}}</span></el-col>
                    <el-col :span="5"><span>{{$t('useraccount.merch')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('useraccount.time')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('useraccount.buyername')}}</span></el-col>
                    <el-col :span="3"><span>{{$t('useraccount.paymeans')}}</span></el-col>
                    <el-col :span="2"><span>{{$t('useraccount.price')}}</span></el-col>
                    <el-col :span="2"><span>{{$t('useraccount.landid')}}</span></el-col>
                </div>
                <div class="home-account-data">
                    <el-row v-for="(user, index) in users_trade" :key="index" style="width:100%;">
                        <div class="users-information-col" :style="tradeIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="tradeIndex = index" @mouseleave="tradeIndex = -1">
                            <el-col :span="4"><span>{{ user.separateOrderId }}</span></el-col>
                            <el-col :span="5"><span>{{ user.landname }}</span></el-col>
                            <el-col :span="4"><span>{{ user.ordertime }}</span></el-col>
                            <el-col :span="4"><span>{{$t('useraccount.buyername')}}</span></el-col>
                            <el-col :span="3"><span>{{ user.paymeans }}</span></el-col>
                            <el-col :span="2">
                                <span style="color:red;word-wrap: break-word;" v-if="$setPayment === 'am'">${{ user.totalprice }}</span>
                                <span style="color:red;word-wrap: break-word;" v-else-if="$setPayment === 'zh'">￥{{ user.totalprice * $exchangeRate }}</span>
                            </el-col>
                            <el-col :span="2"><span>{{ user.landid }}</span></el-col>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
    
        <!-- 下线弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal" @click="closeModal">
            <div class="developer-common-modal-box" v-if="showError">
                <span style="padding:20px;">{{ errorText }}</span>
                <div class="developer-common-buttons" style="margin-top:40px;">
                    <el-button type="primary" class="developer-common-button ml-10" @click="closeModal">{{$t('useraccount.yes')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span style="padding:20px;">{{$t('useraccount.makeSureDownload')}}</span>
                <div class="developer-common-buttons" style="margin-top:40px;">
                    <el-button type="primary" class="developer-common-button" @click.prevent="downloadland">{{$t('useraccount.yes')}}</el-button>
                    <el-button type="info" plain class="developer-common-button" style="margin-left:15px;" @click="closeModal">{{$t('useraccount.no')}}</el-button>
                </div>
            </div>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 systemModal 变量决定 -->
        <div class="modal" v-if="systemModal" @click="systemModal = false">
            <div class="system-message-background">
                <span class="system-message-title">{{$t('ChatMain.systemTitle')}}</span>
                <div class="system-message-announcement">
                    <div class="system-message-announcement-element" v-for="(apply, index) in system" :key="index" v-show="!apply.read">
                        <span class="system-message-date">{{ apply.createdAt }}</span>
                        <div class="system-message-content">{{ apply.content }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$http.get('/v1/business/select-transaction-list') // 获取近期交易
            .then(res => {
                this.users_trade = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        this.$http.get('/v1/original-land/select-originalland-list') // 获取原始土地列表
            .then(res => {
                this.users_land = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        this.$http.get('/v1/order/income-data') // 获取主页收益数据
            .then(res => {
                this.user_data = res.data.data;
            }).catch(error => {
                // 处理错误
            });
    },
    data() {
        return {
            users: {
                lvURL: '../assets/logo.png', // 用户等级应用的等级图标
                lv: 1, // 用户等级
                totalIntegral: 2000, // 用户总积分
                increaseIntegral: 1000, // 用户当月新增积分
                updateTime: 'xxxx-x-xx', // 更新时间？
                totalIncome: 5000, // 当前总收入
                lastTotalIncome: 4000, // 昨日总收入
                customer: 90, // 买家
                lastCustomer: 87, // 昨日买家
                average: 1100, // 平均交易额
                lastAverage: 1000, // 上月平均交易额
                unitPrice: 1000, // 最小单位土地平均地价
                lastUnitPrice: 1100, // 上月最小单位土地平均地价
            },

            user_data: {
                买家数量: 0,
                总收益: 0,
                昨日新增收益: 0,
                平均交易额: 0,
                昨日平均交易额: 0,
                交易土地数据: 0,
                单位土地均价: 0,
                当月单位土地均价: 0
            },

            system: null, // 系统消息
            systemModal: false, // 系统消息弹窗显示

            users_land1: [ // ‘我的土地’测试用例
                {
                    originallandname: 'mylandisbig12345',
                    detailedaddress: '大图智能科技有限公司',
                    count: '100',
                    landstatus: '已上线',
                    originallandid: 'S136'
                },
                {
                    id: 'mylandisnotbig12345',
                    name: '大图智能科技有限公司',
                    count: '50',
                    state: '待审核',
                    originallandid: 'S137'
                },
                {
                    id: 'mylandissmall12345',
                    name: '大图智能科技有限公司',
                    count: '10',
                    state: '已下线',
                    originallandid: 'S138'
                },
            ],
            users_land: [], // axios后端所用的data参数
            hoveredIndex: -1, // 鼠标悬浮时更改其颜色
            downloadlandid: null, // 选择下线的原始土地编号
            vmodal: false, // 显示弹窗
            showError: false, // 如果不能下线，则这个为true，这个为true时是只有关闭弹窗的按钮的
            errorText: '',

            users_trade1: [ // ‘近期交易’测试用例
                {
                    "ids": 328,
                    "separateOrderId": "",
                    "combinedOrderId": "",
                    "ordertime": "",
                    "unitlandid": "",
                    "landid": "",
                    "landname": "",
                    "landpicture": "",
                    "salemeans": "",
                    "paymeans": "",
                    "totalprice": 0,
                    "completetime": null,
                    "buyerid": "",
                    "sellerid": "",
                    "addressid": "",
                    "needpaper": true,
                    "orderstatus": "",
                    "processstatus": "",
                    "refundreason": null,
                    "refundtime": null,
                    "rejectionreason": null,
                    "isdelete": null,
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "originalLand": null,
                    "unitLand": null,
                    "address": null,
                    "onSale": null
                },
            ],
            users_trade: [{
                    "ids": 328,
                    "separateOrderId": "",
                    "combinedOrderId": "",
                    "ordertime": "",
                    "unitlandid": "",
                    "landid": "",
                    "landname": "",
                    "landpicture": "",
                    "salemeans": "",
                    "paymeans": "",
                    "totalprice": 0,
                    "completetime": null,
                    "buyerid": "",
                    "sellerid": "",
                    "addressid": "",
                    "needpaper": true,
                    "orderstatus": "",
                    "processstatus": "",
                    "refundreason": null,
                    "refundtime": null,
                    "rejectionreason": null,
                    "isdelete": null,
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "originalLand": null,
                    "unitLand": null,
                    "address": null,
                    "onSale": null
                },], // axios后端所用的data参数
            tradeIndex: -1, // 鼠标悬浮时变色
        }
    },


    methods: {
        /* 鼠标悬停到等级图标上显示的文本 */
        levelText() {
            if(this.users.lv < 4) {
                return '初级开发商:持有土地1~5块,等级小于4级'
            }
        },
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 计算新增数据 */
        updateData(now,last) {
            const data = now - last
            if (data >= 0) {
                return '+' + data.toString()
            } else {
                return data.toString()
            }
        },
        /* 给原始土地状态设置颜色 */
        setStatuColor(statu) {
            if (statu === '待审核') {
                return 'color: #FFC300;';
            } else if (statu === '已驳回') {
                return 'color: #FA5151;';
            } else if (statu === '已上线') {
                return 'color: #00B578;';
            } else if (statu === '已下线') {
                return 'color: #476DFF;';
            }
        },
        /* 我的土地-查看-进入土地 */
        landControl(id,name) {
            this.$router.push({
                path: '/land-operate/big-map',
                name: 'Originland',
                query: {
                    land: id,
                    landName: name,
                }
            })
        },
        gotoland(id) { // 进入土地合并分割页面
            this.$router.push({
                path: '/land-operate/big-map?land=' + id})
        },
        uploadland() { // 上传土地
            this.$router.push({
                path: '/developer/land-detail/real-estate',
                name: 'developerLandView',
                params: {
                    index: 1
                }
            })
        },
        reloadland(id) { // 重新上传土地
            this.$router.push({
                path: '/developer/land-detail/real-estate',
                name: 'developerLandView',
                query: {
                    land: id
                },
                params: {
                    index: 1
                }
            })
        },
        gotolandregister(id,statu) { // 对于待审核的土地，用户点击查看进度
            if (statu === '待审核') {
                this.$router.push({
                    path: '/developer/land-detail/real-estate',
                    name: 'developerLandView',
                    query: {
                        land: id
                    },
                    params: {
                        index: 2
                    }
                })
            } else if (statu === '已驳回') {
                this.$router.push({
                    path: '/developer/land-detail/real-estate',
                    name: 'developerLandView',
                    query: {
                        land: id
                    },
                    params: {
                        index: 3
                    }
                })
            }
        },
        checkLand(id) { // 查看土地详情
            this.$router.push({
                path: '/developer/land-detail?id=' + id,
            })
        },
        gotoTrade() { // 查看交易列表
            this.$router.push('/developer/order-list')
        },
        gotoSale() { // 挂牌土地管理-列表页
            this.$router.push('/developer/my-sale')
        },
        /* 以下是下线原始土地的功能 */
        downloadland() {
            this.errorText = this.$t('OriginalLandDetail.l19');
            this.showError = true;
            this.$http.get("/v1/original-land/offline?originallandid=" + this.downloadlandid)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('useraccount.alertDownloadSuccessfully'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.errorText = res.data.data;
                        this.vmodal = true;
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('OrderView.l16'));
                    this.vmodal = false;
                });
        },
        showModal(id) { // 弹窗提示是否下线，给用户一个二次选择的机会
            this.downloadlandid = id;
            this.showError = false;
            this.vmodal = true;
        },
        closeModal() {
            this.vmodal = false;
        },
    }

}
</script>

<style scoped>
/* body之下第一层 */
.main-page-container {
    width: 100%;
    background-color: rgb(242,248,255);
    height: 100%;
    overflow-y: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
}
/* --------------------------------------第一个模块-账号收入信息-------------------------------------- */
.home-account-item {
    display: flex;
    flex-direction: row;
    width: 100%;
}
/* 第一个模块的下半部分，分为左右共四栏，最左边是总收入、今日收入，第二栏是买家，第三栏平均交易额，第四栏土地交易数量 */
.home-account-bottom-box1 {
    width: 320px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.home-account-bottom-box1-item {
    display: flex;
    flex-direction: row;
    padding: 5px 20px;
}
.home-account-bottom-box1-totalIncome-img {
    height: 20px;
    width: 20px;
}
.home-account-bottom-box1-totalIncome {
    margin-left: 10px;
    color: #788AA1;
    font-size: 16px;
}
/* 三个卡片一样的模块，显示买家人数、平均交易额和土地交易数量 */
.home-account-bottom-box2 {
    width: 300px;
    height: 170px;
    background-image: url('../developer/developer-home-buyer.png');
    background-size: cover;
    position: relative;
}
.home-account-bottom-box3 {
    margin-left: 60px;
    width: 300px;
    height: 170px;
    background-image: url('../developer/developer-home-trade.png');
    background-size: cover;
    position: relative;
}
.home-account-bottom-box4 {
    margin-left: 60px;
    width: 300px;
    height: 170px;
    background-image: url('../developer/developer-home-land.png');
    background-size: cover;
    position: relative;
}
.home-account-bottom-icon1 {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 24px;
    left: 30px;
}
.home-account-bottom-text1 {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 600;
    position: absolute;
    top: 20px;
    left: 64px;
}
.home-account-bottom-icon2 {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 75px;
    left: 30px;
}
.home-account-bottom-text2 {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 600;
    position: absolute;
    top: 77px;
    left: 83px;
}
.home-account-bottom-icon3 {
    background-color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    position: absolute;
    top: 120px;
    left: 30px;
}
/* 给用户各部分信息区域设置一个父块，来控制行内元素 */
.form-item-cells {
    max-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}
.home-account-data {
    max-height: 160px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


/* -------------------------------------个人信息——总收入、相比昨日的新增收入等------------------------------------- */
/* 昨日/上月新增 */
.update-data {
    background-color: #646262;
    border: 2px solid #646262;
    border-radius: 12px;
    font-size: 14px;
    padding: 10px;
}

/* 系统消息页面 */
.system-message-background {
    height: 800px;
    width: 600px;
    background-image: url('../images/systemMessage.jpg');
    position: relative;
}
.system-message-title {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 24px;
    font-weight: bold;
}
.system-message-announcement {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 50px;
    overflow: auto;
    position: absolute;
    top: 100px;
}
.system-message-announcement-element {
    display: flex;
    flex-direction: column;
}
.system-message-date {
    margin-bottom: 20px;
}
.system-message-content {
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
</style>