<template>
    <div class="mobile-refund-home">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileRefundDetail.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="refund-steps-box" v-show="showSteps">
            <van-steps :active="active">
                <van-step>{{$t('MobileRefundDetail.step1')}}</van-step>
                <van-step>{{$t('MobileRefundDetail.step2')}}</van-step>
                <van-step>{{$t('MobileRefundDetail.step3')}}</van-step>
            </van-steps>
        </div>

        <div class="refund-view-infor" v-if="active === 0">
            <ShopCartInfor class="animate__animated animate__fadeInUp" :LandInfor="LandInfor"/>
            <van-divider />
            <div class="refund-reason-box animate__animated animate__fadeInLeft">
                <span class="refund-reason-topic">{{$t('MobileRefundDetail.reason')}}:</span>
                <textarea cols="6" rows="20" maxlength="100" show-word-limit :placeholder="$t('MobileRefundDetail.input')" class="refund-reason-input"></textarea>
            </div>
            <span class="refund-price animate__animated animate__fadeInRight">{{$t('MobileRefundDetail.price')}}: 
                <span v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                <span v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
            </span>
            <div class="flex  items-center justify-center">
                <button class="px-4 mt-5 py-2 shadow-md text-white hover:bg-green-600 bg-green-500 cursor-pointer text-sm rounded-3xl w-20" @click="RefundConfirm">{{$t('MobileRefundDetail.button')}}</button>
            </div>
        </div>
        
        <div class="refund-view-infor" v-if="active === 1">
            <div class="market-background animate__animated animate__fadeInUp"  @click="turnToDetail">
                <img :src="LandInfor.originalLand.landpicture" class="market-image" />
                <div class="market-landname">{{ LandInfor.landname }}</div>
                <div class="market-landprice">
                    <span v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                    <span v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
                </div>
            </div>
            <van-divider />
            <div class="refund-reason-box animate__animated animate__fadeInLeft">
                <span class="refund-reason-topic">{{$t('MobileRefundDetail.reason')}}:</span>
                <div class="refund-reason-text">{{LandInfor.refundreason}}</div>
            </div>
            <span class="refund-price animate__animated animate__fadeInRight">{{$t('MobileRefundDetail.price')}}:</span>
        </div>

        <div class="refund-result" v-if="active === 2">
            <span>{{$t('MobileRefundDetail.success')}}</span>
            <button class="refund-result-button" @click="viewDetails">{{$t('MobileRefundDetail.successButton')}}</button>
        </div>
        <div class="refund-result" v-if="active === 2">
            <span>{{$t('MobileRefundDetail.fail')}}</span>
            <div class="refund-reason-box">
                <span class="refund-reason-topic">{{$t('MobileRefundDetail.failReason')}}:</span>
                <div class="refund-reason-text">{{LandInfor.refundreason}}</div>
            </div>
            <button class="refund-result-button">{{$t('MobileRefundDetail.refundTwice')}}</button>
        </div>

    </div>
</template>

<script>
import ShopCartInfor from "@/mobile/MobileRefundWindow";
export default {
    components: {
        ShopCartInfor, // 土地市场组件
    },
    created() {
        this.orderid = this.$route.query.order; // 根据地址获取申请退款的订单号
    },
    mounted() {
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
        // 初始化该订单土地商品窗口信息列表
        this.$http
            .get("/v1/order/select-separateorder-one?separateOrderId=" + this.orderid)
            .then((res) => {
                this.loading = false
                this.LandInfor = res.data.data;
                if (this.LandInfor.orderstatus === '已付款') {
                    this.active = 0;
                } else if (this.LandInfor.orderstatus === '退款中') {
                    this.active = 1;
                } else if (this.LandInfor.orderstatus === '已退款') {
                    this.active = 2;
                    this.refundResult = true;
                } else if (this.LandInfor.processstatus === '退款申请被驳回') {
                    this.active = 2;
                    this.refundResult = false;
                } else {}
            })
            .catch((error) => {
                console.log(error);
            });
    },
    data() {
        return {
            messages: 0,
            orderid: null, // 订单号
            LandInfor: {
                ids: 0,
                separateOrderId: "",
                combinedOrderId: "",
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: "",
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                combinationpay: null,
                buyerid: "",
                sellerid: "",
                addressid: "",
                needpaper: true,
                orderstatus: "",
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                originalLand: {
                    id: 0,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 0,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 0,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                }
            }, // 后端获取的订单信息
            active: 0, // 退款的当前进度,0为退款申请,1为审核中,2为退款结果
            refundResult: true, // 退款结果，true是成功，false是驳回，仅在active=2时有效
            flag: true, // 防抖
            showSteps: true, // 是否隐藏进度条
        };
    },
    methods: {
        turnToDetail() { // 跳转到土地详情页
            const newpath = "/mobile/my-order/detail?order=" + this.LandInfor.separateOrderId
            this.$router.push({
                path: newpath,
            });
        },
        RefundConfirm(){
            if (!this.flag) {
                return;
            }
            this.flag = false;
            // 退款确认
            this.$http
                .post("/v1/order/request-refund", {
                    separateOrderId: this.orderid,
                    refundreason: this.refundReason,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MobilePurchaseDetails.alertSeccess'));
                        this.showRefund = false;
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(res.data.message);
                    }
                    this.flag = true;
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('MobileRefundDetail.alertAxiosError'));
                    this.flag = true;
                });
        },
        viewDetails() {
            this.showSteps = false;
            this.active = 1;
        },
        refundTwice() {
            this.active = 0;
        }
    },
}
</script>

<style scoped>
.mobile-refund-home {
    min-height: 100vh;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
/* 进度条样式 */
.refund-steps-box {
    padding: 20px 20px 0;
}
/* 退款信息 */
.refund-view-infor {
    padding: 10px;
}
.refund-reason-box {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.refund-reason-topic {
    font-size: 18px;
}
.refund-reason-text {
    min-width: 100%;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194); /* 边框 */
    padding: 20px;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    font-size: 18px;
}
/* 退款输入框 */
.refund-reason-input {
    min-height: 100px;
    width: 100%;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194); /* 边框 */
    padding: 20px;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    font-size: 18px;
}
.refund-price {
    padding: 20px;
    font-size: 18px;
}
/* 退款结果通知 */
.refund-result {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}
.refund-result-button {
    margin-top: 20px;
    height: 40px;
    width: 120px;
    border-radius: 20px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}

/* 商品图鉴 */
.market-background {
    height: 170px;
    width: calc(100% - 20px);
    background-color: #eeeeee;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 6px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 30px;
    left: 170px;
    font-size: 20px;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 20px;
    left: 170px;
    font-size: 20px;
}
</style>