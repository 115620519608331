<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>聊天管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">聊天记录列表</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-topic">聊天记录列表信息</div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">发送者编号</span>
                            <el-input style="width:256px;" v-model="search.sendid" placeholder="请输入发送者编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">发送者昵称</span>
                            <el-input style="width:256px;" v-model="search.sendname" placeholder="请输入发送者昵称"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">接收者编号</span>
                            <el-input style="width:256px;" v-model="search.receiveid" placeholder="请输入接收者编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">接收者昵称</span>
                            <el-input style="width:256px;" v-model="search.recipientname" placeholder="请输入接收者昵称"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">消息内容</span>
                            <el-input style="width:256px;" v-model="search.text" placeholder="请输入消息内容" :disabled="true"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">时间范围</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadChat">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetKey">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                        <div class="admin-manage-search-input-tips">*请尽可能输入短的关键词，宁可缺字少字也不要错字漏字！</div>
                    </div>
                </div>
                <!-- <div class="admin-manage-tabs-content select-button-box">
                    <button>批量选中</button>
                </div> -->
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="5"><span>发送者编号</span></el-col>
                        <el-col :span="2"><span>发送者昵称</span></el-col>
                        <el-col :span="3"><span>发送时间</span></el-col>
                        <el-col :span="5"><span>接收者编号</span></el-col>
                        <el-col :span="2"><span>接收者昵称</span></el-col>
                        <el-col :span="5"><span>消息内容</span></el-col>
                        <el-col :span="2"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in showChat" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <!-- <el-checkbox v-model="checked"></el-checkbox> -->
                                <el-col :span="5">
                                    <el-tooltip class="item" :content="apply.fromid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.fromid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.fromname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.fromname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.createdate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.createdate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="5">
                                    <el-tooltip class="item" :content="apply.recipientid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.recipientid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.recipientname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.recipientname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="5">
                                    <el-tooltip class="item" :content="apply.isUrl==='url'?'[图片]':apply.emailscontent" placement="top">
                                        <span class="admin-manage-tabs-content-col-text" style="text-decoration: underline;" @mouseover="changeCursor" @click="showLight(apply.emailscontent,apply.isUrl)">{{ apply.isUrl==='url'?'[图片]':apply.emailscontent }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="showConfirmModal(apply.id)">删除该条</span>
                                    &nbsp;
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="showThread(apply.serviceid,apply.threadid)">查看记录</span>
                                </el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 删除聊天记录的弹窗 vmodal 变量决定 -->
        <div class="admin-manage-modal" v-if="vmodal">
            <div class="admin-manage-modal-box">
                <span class="admin-manage-modal-text" style="padding:30px;">确定删除该聊天信息?</span>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="deleteChat" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
        <!-- 显示聊天文本/图片的弹窗 isText 变量决定 -->
        <div class="admin-manage-image-modal" v-if="isText" @click="hide">
            <div class="admin-manage-modal-box">
                <img class="full-image" :src="imgUrl" v-if="haveImg"/>
                <span class="admin-manage-modal-text" v-else>{{ showText }}</span>
            </div>
        </div>
        <!-- 显示整个主题的弹窗 showthread 变量决定 -->
        <div class="admin-manage-image-modal" v-if="showthread">
            <div class="admin-manage-modal-box" style="width:800px;max-height:1000px;">
                <div class="admin-chat-manage-thread-topic">
                    <span class="text-2xl">聊天记录管理</span>
                </div>
                <div class="admin-chat-manage-thread-content">
                    <div v-for="(message, index) in showMessages" :key="index" :class="message.isme?'admin-chat-manage-thread-content-left':'admin-chat-manage-thread-content-right'">
                            <div class="text-sm flex flex-row items-center mb-3">
                                <span class="text-lg ml-1">{{ message.fromname }}</span>
                                <span class="ml-2 text-right">{{ message.createdate }}</span>
                            </div>
                            <el-image style="max-width: 300px; max-height: 300px" :src="message.emailscontent" v-if="message.isUrl==='url'"></el-image>
                            <div class="msg-text" v-else>{{ message.emailscontent }}</div>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="showthread=false" class="admin-apply-button-1">关闭</el-button>
                    <el-button type="primary" @click="refuse" class="admin-apply-button-1" style="margin-left: 20px;">导出</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        const data = {
            pageSize: this.pageSize,
            pagenum: this.currentPage
        }
        this.$http.post('/v1/chatmanage/select-chatrecord', data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            .then(res => {
                this.showChat = res.data.data.rows;
                this.showLength = res.data.data.total;
                let array = [];
                for(let i=0;i<this.showLength;i++) {
                    array.push({
                        id: this.showChat[i].id,
                        check: false
                    })
                }
                this.chatArray = array;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
                this.loading = false;
            });
    },
    data() {
      return {
        example: { // 纯测试数据，不显示
            "id": 6193,
            "serviceid": "166870055533475523",
            "fromid": "00e3f6867e364662bb02475f6fb7b84f",
            "fromname": "root1234",
            "recipientid": "10002",
            "recipientname": "高级管理员",
            "emailscontent": "哈哈哈，测试1下",
            "createdate": "2023-09-27T16:16:03",
            "receivedate": null,
            "fromdelete": false,
            "recipientdelete": false,
            "isme": null,
            "starttime": null,
            "endtime": null,
            "pagesize": 0,
            "pagenum": 0,
            "start": 0
        },
        loading: true, // 控制骨架屏
        showChat: null, // 显示的当页订单信息，根据页码页距控制
        chatArray: [{
            id: '', // 该多选框对应的聊天消息id
            check: false, // 是否选中该多选框
        }], // 存储的多选框，每一个框都是一个含两个参数的对象
        selectChat: [], // 选中需要删除的消息
        showLength: 0, // 条数
        currentPage: 1, // 页码
        pageSize: 50, // 每页条数

        search: {
            sendid: '', // 发送者编号
            sendname: '', // 发送者昵称
            receiveid: '', // 接收者编号
            recipientname: '', // 接收者昵称
            text: '', // 消息
            date: '',
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        vmodal: false, // 显示弹窗
        selectid: null, // 选中要删除的聊天信息id
        isText: false, // 显示消息
        imgUrl: null, // 显示消息-图片url
        haveImg: false, // 消息中是否有图片
        showText: '', // 显示的文本

        showthread: false, // 第三个弹窗-显示该主题消息记录
        showMessages: [], // 主题消息记录数组，后端获取
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        loadChat() {
            this.loading = true;
            let data = { // 初始化必须的筛选条件
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            // 添加用户输入的筛选条件
            if(this.search.sendid !== '') {
                data["fromid"] = this.search.sendid;
            }
            if(this.search.sendname !== '') {
                data["fromname"] = this.search.sendname;
            }
            if(this.search.receiveid !== '') {
                data["recipientid"] = this.search.receiveid;
            }
            if(this.search.recipientname !== '') {
                data["recipientname"] = this.search.recipientname;
            }
            if(this.search.text !== '') {
                data["emailscontent"] = this.search.text;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }
            this.$http.post('/v1/chatmanage/select-chatrecord',data)
            .then(res => {
                this.showChat = res.data.data.rows;
                this.showLength = res.data.data.total;
                let array = [];
                for(let i=0;i<this.showLength;i++) {
                    array.push({
                        id: this.showChat[i].id,
                        check: false
                    })
                }
                this.chatArray = array;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("加载失败，请刷新页面或重新登录");
                this.loading = false;
            });
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            this.loadChat();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loadChat();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            this.loadChat();
        },
        resetKey() {
            this.search.sendid = '';
            this.search.sendname = '';
            this.search.receiveid = '';
            this.search.recipientname = '';
            this.search.text = '';
            this.search.date = '';
            this.loadChat();
        },
        deleteChat() { // 删除消息
            this.$http.delete('/v1/chatmanage/delete-chatrecord?id=' + this.selectid)
                .then(res => {
                    this.$toast.success('删除聊天信息成功！');
                    this.vmodal = false;
                    this.loadChat();
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail('操作失败！请刷新页面或重新登录');
                    console.error(error.data)
                });
        },
        /* 显示确定弹窗（带按钮） */
        showConfirmModal(id) {
            this.selectid = id;
            this.vmodal = true;
        },
        closeModal() {
            this.vmodal = false;
        },
        /* 显示消息弹窗（无按钮） */
        showLight(text,isurl) {
            if (isurl === 'url') {
                this.imgUrl = text;
                this.haveImg = true;
            } else {
                this.showText = text;
                this.haveImg = false;
            }
            this.isText = true;
        },
        hide() {
            this.isText = false;
        },
        /* 显示主题消息记录列表 */
        showThread(serveid,threadid) { // 消息记录有两种模式：客服消息和用户间消息
            if (serveid) {
                this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + serveid)
                .then(res => {
                    this.showMessages = res.data.data;
                    this.showthread = true;
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail('加载失败，请刷新页面或重新登录');
                });
            } else {
                this.$http.get('/v1/mail/select-message?threadid=' + threadid)
                .then(res => {
                    this.showMessages = res.data.data;
                    this.showthread = true;
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail('加载失败，请刷新页面或重新登录');
                });
            }
        },
    },
}
</script>

<style scoped>
.admin-chat-manage-thread-topic {
    font-size: 16px;
    font-weight: bold;
}
.admin-chat-manage-thread-content {
    width: 100%;
    padding: 20px;
    overflow-y: auto;
}

/* 以下是聊天记录弹窗的css样式 */
.admin-chat-manage-thread-content-left {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 15px;
}
.admin-chat-manage-thread-content-right {
    display: flex;
    align-items: end;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 15px;
}
</style>