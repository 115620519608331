<template>
    <div class="msger">
        <!-- 聊天页面的头部，包含菜单和清除聊天记录按钮 -->
        <!-- <div class="flex items-center justify-between p-2 bg-white shadow-md">
            <div class="flex items-center space-x-2 flex-1">
                <h1 class="text-lg font-bold text-gray-700 flex-1 text-center">
                    {{$t('ChatCustomer.title')}}
                </h1>
            </div>
        </div> -->

        <!-- 商品信息 -->
        <div class="landinfor" v-if="sale">
            <img :src="sale.saleslandpicture" class="landinfor-image" />
            <div class="landinfor-name">{{$t('ChatCustomer.merge')}}:{{ sale.landname }}</div>
            <div class="landinfor-price">{{$t('ChatCustomer.price')}}:
                <span v-if="$setPayment === 'am'">${{ sale.totalprice }}</span>
                <span v-else-if="$setPayment === 'zh'">￥{{ sale.totalprice * $exchangeRate }}</span>
            </div>
            <div class="landinfor-address">
                <img src="../developer/chat-address.png"/>
                <span>{{ sale.originallandid }}</span>
            </div>
            <div class="landinfor-unitland">{{ $t('MobileLandDetail.landNum') }}:{{ sale.blocks }}</div>
        </div>

        <!-- 聊天记录区域 -->
        <div class="flex-1 relative flex flex-col" style="max-height:calc(100% - 50px)" ref="messageList">

            <div class="flex-1 p-4 relative overflow-y-auto" ref="messageList">
            <!-- 对话框 -->
            <AppendMessage v-for="(message, index) in messages" :key="index" :name="message.isme? message.fromname : message.recipientname" :img="message.isme? myavatarUrl : message.userphoto"
                :side="message.isme? 'right' : 'left'" :text="message.emailscontent" :id="message.id" :time="message.createdate" :progress="message.progress" :isUrl="message.isUrl==='url'?true:false"
                :file="message.file" @forward="onForward(message.id)" />
            </div>
        
        </div>

        <div class="input-parent">
            <input type="text" v-model="text" @keyup.enter="sendMessage" :placeholder="$t('ChatCustomer.enter')" class="input">
            <div class="flex-none ml-2 mr-2 relative"><!-- 这里也是，必须是客服才有发送图片 -->
                <el-tooltip class="item" effect="dark" :content="tooltipText" placement="top-start">
                    <img src="../developer/chat-sendImage.png" @click="handleClick" style="height:20px;width:20px;" @mouseover="changeCursor" v-show="type==='来自客服'">
                </el-tooltip>
                <input type="file" multiple accept="image/*" ref="image" @change="handleFileInputChange" hidden>
                <!-- 显示的图片 -->
                <div class="image-parent" v-if="images.length > 0">
                    <!-- 清空按钮 -->
                    <div class="flex justify-end w-full mb-3">
                        <button class="image-clear-button" @click="clearImage">{{ $t('all.clear2') }}</button>
                    </div>
                    <!-- 3x3网格 -->
                    <div class="image-grid">
                        <div class="image-item" v-for="(image, index) in images" :key="index" >
                            <img :src="image" alt="Uploaded Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-send"><span @click="sendMessage">{{$t('ChatCustomer.send')}}</span></div>
        </div>

        <!-- 输入框和发送按钮 -->
        <!-- <form class="flex md:flex-row items-center p-2 bg-white shadow-md relative">
            <div class="flex-1 flex items-center justify-center">
                <textarea ref="inputBox"
                    class="flex-1 h-12 px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:shadow-outline"
                    :placeholder="$t('ChatCustomer.enter')" rows="2" v-model="text" @keyup.enter="sendMessage" v-if="uploadMode"></textarea>
                <div class="flex flex-col p-3" v-if="!sale && !uploadMode">
                    <div class="h-40 w-60 flex justify-center items-center shadow" @mouseover="changeCursor" @click="handleClick">
                        <img :src="imageUrl" v-if="image" alt="图片已上传" class="max-h-full max-w-full">
                        <span class="text-6xl" v-else>+</span>
                        <input type="file" ref="image" @change="handleFileInputChange" hidden>
                    </div>
                    <span v-if="!uploadMode" class="mt-3" style="color: red;font-size: 14px;">*{{$t('ChatCustomer.tip')}}</span>
                </div>
            </div>
            <div class="flex-none ml-2" v-if="!sale">
                <el-tooltip class="item" effect="dark" :content="tooltipText" placement="top-start">
                    <img src="../images/plus.jpg" @click="changeMode" style="height:40px;padding:5px;" @mouseover="changeCursor">
                </el-tooltip>
            </div>
            <div class="flex-none ml-2">
                <button @click.prevent="sendMessage"
                    class="px-4 py-2 text-white bg-blue-500 rounded-md focus:outline-none focus:shadow-outline hover:bg-blue-600">
                    {{$t('ChatCustomer.send')}}
                </button>
            </div>
        </form> -->

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>
</template>
<script>
import {mapState} from "vuex";
import AppendMessage from "../companyUsers/AppendMessage.vue";
export default {
    computed: {
        ...mapState({
            myavatarUrl: (state) => state.developer.avatarUrl,
        }),
    },
    props: {
        ThreadId: String,
        messages: Array,
        youravatarUrl: String,
        sale: Object,
        noSaleAlert: { // 未获取到商品数据，有可能是网络问题，有可能是用户改了id，总之改为true，此时不可发送信息
            type: Boolean,
            require: false,
            default: false,
        },
        type: {
            type: String,
            default: '来自用户'
        },
    },
    components: {
        AppendMessage,
    },
    data() {
        return {
            merch: null, // 商品id
            receiveid: null, // 接收的用户id
            example: [{
                id: 6175,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "你好，客服",
                createdate: "2023-08-23T16:00:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
            },{
                id: 6176,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "我有问题想要问",
                createdate: "2023-08-23T16:01:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
            }],
            text: '', // 文本，发送的消息
            images: [], // 图片，显示
            imagesSend: [], // 图片，发送
            tooltipText: this.$t('ChatCustomer.uploadimg'), // 下方切换上传图片/文本提示语
            uploadMode: true, // true是上传文本，false是上传图片
            dialogImageUrl: '', // 预览图
            dialogVisible: false,
            saleExample: {
                "id":140,
                "originallandid":"S751",
                "unitlandid":"6006",
                "landname":"黄土地",
                "sellerid":"b4bcab5f1afa44e8bbba5449afa13d6d",
                "seller":"Mrs.wang",
                "saleslandpicture":"",
                "salesmethods":"立即购买",
                "expirydate":"",
                "unitlandprice":null,
                "pricenum":1200,
                "unitlandminprice":null,
                "minimumprice":null,
                "blocks":2,
                "totalprice":1200,
                "saleslandpicture":null,
                "createtime":"2023-06-20 13:41:41.131",
                "status":"挂牌中",
                "remark":null,
                "originalLand":null,
                "unitLand":null
            },

            sendImageCount: 9999, // 发送的图片数量
            sendImageResultCount: 0, // 获得处理结果的图片数量
            showError: false, // 是否显示异常
        }
    },
    // 监听发送图片获得后端回复的计数，进行提示
    watch: {
        sendImageResultCount(n) {
            if (this.sendImageResultCount >= this.sendImageCount) {
                this.sendImageCount = 9999;
                this.sendImageResultCount = 0;
                this.clearImage();
                this.toastLoading.clear();
                if (this.showError) {
                    this.$toast.fail(this.$toast.fail('AccountEmail.alertSendFail'));
                } else {
                    this.$toast.success(this.$t('all.sendSuccess') + '!');
                    this.$parent.refreshMessage();
                }
            }
        }
    },
    beforeDestroy() {
        if (this.toastLoading) {
            this.toastLoading.clear();
            this.toastLoading = null;
        }
    },
    methods: {
        changeCursor(event) {
            event.target.style.cursor = "pointer";
        },
        changeMode() {
            if (this.uploadMode) {
                this.uploadMode = false;
                this.tooltipText = this.$t('ChatCustomer.uploadtext')
            } else {
                this.uploadMode = true;
                this.tooltipText = this.$t('ChatCustomer.uploadimg')
            }
        },
        handlePictureCardPreview(file) { // 图片预览
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        /* 上传图片功能样式 */
        handleClick() {
            // 触发文件选择器
            this.$refs.image.click();
        },
        handleFileInputChange(event) { // 获取用户选择的文件
            const size = 5 * 1024 * 1024; // 5MB
            const files = Array.from(event.target.files); // 获取文件列表
            const newImages = [];

            // 限制最多上传 9 张图片
            files.forEach((file) => {
                // 检查文件大小限制
                if (file.size > size) {
                    this.$toast.fail(this.$t('ChatCustomer.alertImageSize'));
                    return; // 如果文件大小超过限制，跳过该文件
                }

                const reader = new FileReader();
                this.imagesSend.push(file);

                // 读取文件并转换为 Data URL 格式
                reader.onload = (e) => {
                    if (newImages.length < 9 - this.images.length) { // 确保总数不超过 9
                        newImages.push(e.target.result);
                    }
                    // 更新 images 数组。只在所有文件读完后更新。
                    if (newImages.length + this.images.length >= 9) {
                        this.images = [...this.images, ...newImages].slice(0, 9); // 确保只有 9 张图片
                    }
                };
                reader.readAsDataURL(file); // 读取文件
            });

            // 在所有文件读取完成后更新 this.images
            Promise.all(files.map(file => new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            })))
            .then(results => {
                results.forEach((result) => {
                    if (this.images.length < 9) {
                        this.images.push(result);
                    }
                });
            });
        },
        // 清空图片
        clearImage() {
            this.images = [];
            this.imagesSend = [];
        },
        // 发送文字函数
        async sendMessage() {
            if (this.ThreadId) { // 没有选中主题时无法发送
                if (this.type === '来自用户' && this.text !== '') { // 消息来自用户,输入框有内容时发送给用户
                    let recipientid = ''; // 需要获取接收人id
                    if (this.messages[0].isme) {
                        recipientid = this.messages[0].recipientid;
                    } else {
                        recipientid = this.messages[0].fromid;
                    }
                    this.$http.post('/v1/mail/write-message', {
                        saleid: this.sale.id,
                        emailscontent: this.text,
                        threadid: this.ThreadId,
                        landname: this.sale.landname,
                        recipientid: recipientid
                    }).then(res => {
                        this.$parent.refreshMessage();
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                    });
                } else if (this.type === '来自客服') { // 来自客服
                    if (this.imagesSend.length === 0 && this.text !== '') { // 只发送文本
                        this.$http.post('/v1/csmail/cswrite-message', {
                            emailscontent: this.text,
                            serviceid: this.messages[0].serviceid
                        }).then(res => {
                            this.$parent.refreshMessage();
                        }).catch(error => {
                            this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                        });
                    } else if (this.imagesSend.length > 0 && this.text === '') { // 只发送图片
                        this.sendImageCount = this.imagesSend.length;
                        this.toastLoading= this.$toast.loading({
                            duration: 0, // 持续展示 toast
                            message: this.$t('userhome.alertSending'),
                            forbidClick: true,
                        });
                        for (let i = 0;i < this.imagesSend.length;i++) {
                            const formData = new FormData();
                            formData.append('picture', this.imagesSend[i]);
                            formData.append('serviceid', this.ThreadId);
                            this.$http.post("/v1/csmail/cswrite-picture", formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                }).then((res) => {
                                    this.sendImageResultCount += 1;
                                }).catch((error) => {
                                    this.sendImageResultCount += 1;
                                    this.showError = true;
                                });
                        }
                    } else {
                        this.$http.post('/v1/csmail/cswrite-message', {
                            emailscontent: this.text,
                            serviceid: this.messages[0].serviceid
                        }).then(res => {
                            this.$parent.refreshMessage();
                        }).catch(error => {
                            this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                        });
                        this.sendImageCount = this.imagesSend.length;
                        this.toastLoading= this.$toast.loading({
                            duration: 0, // 持续展示 toast
                            message: this.$t('userhome.alertSending'),
                            forbidClick: true,
                        });
                        for (let i = 0;i < this.imagesSend.length;i++) {
                            const formData = new FormData();
                            formData.append('picture', this.imagesSend[i]);
                            formData.append('serviceid', this.ThreadId);
                            this.$http.post("/v1/csmail/cswrite-picture", formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                }).then((res) => {
                                    this.sendImageResultCount += 1;
                                }).catch((error) => {
                                    this.sendImageResultCount += 1;
                                    this.showError = true;
                                });
                        }
                    }
                }
            } else { // 没有主题id，可能是从联系买家过来的，也有可能没有选择主题
                if (this.receiveid) { // 有接收人id，说明是从联系买家过来的
                    if (this.noSaleAlert) { this.$toast.fail(this.$t('ChatMain.alertMergeFail')); }
                    if (this.text === '' ) { return; }
                    this.$http.post('/v1/mail/write-message', {
                        saleid: this.sale.id,
                        emailscontent: this.text,
                        landname: this.sale.landname,
                        recipientid: this.receiveid
                    }).then(res => {
                        this.ThreadId = res.data.data; // 获取后端返回的主题id
                        this.refreshMessage(); // 刷新聊天记录
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                    });
                } else {
                    this.$toast.fail(this.$t('ChatCustomer.alertCanotSend'));
                }
            }
        },
        refreshMessage() {
            this.$http.get('/v1/mail/select-message?threadid=' + this.ThreadId)
                .then(res => {
                    this.messages = res.data.data;
                    this.avatarUrl = URL.createObjectURL(res.data.msg); // todo这个没有对方头像
                    this.goBottom();
                    this.clearInput();
                    this.$store.dispatch("developer/refreshMessages");
                }).catch(error => {
                    this.$toast.fail(this.$t('ChatMain.alertMessageFail'));
                });
        },
        goBottom() {
            // 滚动到最新消息
            this.$nextTick(() => {
                const messageList = this.$refs.messageList;
                messageList.scrollTop = messageList.scrollHeight;
            });
        },
        clearInput() { // 清空输入框
            this.text = '';
            this.image = null;
            this.imageUrl = null;
        },
    },

}
</script>
<style scoped>
.msger {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    padding: 30px;
}
/* 来自用户的消息会携带商品信息 */
.landinfor {
    height: 150px;
    border-radius: 12px;
    background: linear-gradient(270deg, rgba(0, 58, 210, 0.8) 0%, rgba(0, 151, 236, 0.8) 100%);
    position: relative;
}
.landinfor-image {
    height: 120px;
    width: 120px;
    border-radius: 12px;
    position: absolute;
    top: 15px;
    left: 15px;
}
.landinfor-name {
    max-width: 440px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 600;
    color: #FFF;
    position: absolute;
    top: 30px;
    left: 160px;
}
.landinfor-price {
    max-width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 500;
    color: #FFF;
    position: absolute;
    top: 105px;
    left: 160px;
}
.landinfor-address {
    height: 44px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 12px;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 45px;
    right: 40px;
}
.landinfor-address > img {
    height: 20px;
    width: 20px;
}
.landinfor-address > span {
    margin-left: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
}
/* 单位土地个数 */
.landinfor-unitland {
    position: absolute;
    top: 70px;
    left: 160px;
    font-size: 16px;
    font-weight: 500;
}


/* 输入框 */
.input-parent {
    height: 50px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 2px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.input {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0);
}
.input-send {
    border-left: 2px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
}
.input-send > span {
    margin-left: 30px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

/* 图片上传 */
.image-parent {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -110%);
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* 图片间隔 */
}

.image-item {
    width: 100px; /* 宽度可以根据需要设置 */
    height: 100px; /* 高度可以根据需要设置 */
    overflow: hidden; /* 防止超出边界 */
}

.image-item > img {
    width: 100%; /* 让图片填充整个容器 */
    height: 100%;
    object-fit: cover; /* 保持图片比例并填充 */
}

.image-clear-button {
    padding: 3px 14px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0256FF;
    background: #165DFF;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 350;
    white-space: nowrap;
    cursor: pointer;
}
</style>