<template>
    <div class="h-full w-full flex justify-center items-center p-20">
        <div class="normal-card-box">
            <h1 class="text-2xl py-5 text-center">{{$t('MobileSafe.set')}}</h1>
            <div class="shadow-lg rounded-lg hover:bg-gray-200 bg-white pl-3 pr-4 pt-2 pb-2 mx-3 mt-3 flex items-center flex-row" @click="onChangePayment">
                <img src="../mImage/infor-myinfor.png" style="margin-right:20px;">
                <div class="flex-grow text-lg">
                    {{$t('PcPay.title')}}:
                    <span v-if="$setPayment === 'am'">{{$t('all.海外支付')}}</span>
                    <span v-if="$setPayment === 'zh'">{{$t('all.国内支付')}}</span>
                </div>
                <van-switch v-model="payment" size="20px" active-color="#ee0a24" inactive-color="#00C6FF" @click="noEvent" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        payment() {
            if (this.$setPayment === 'am') {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        onChangePayment() {
            if (this.payment) {
                this.$setPayment = 'zh';
            } else {
                this.$setPayment = 'am';
            }
        },
        noEvent() {
            return;
        },
    },
}
</script>

<style>

</style>