<template>
  <div class="developer-common-safe">
    <div class="address-list-container">
      <!-- 第一行，包括标题、有几个地址、新增地址按钮 -->
      <div class="title-parent">
        <div class="title">{{ $t('PcAddress.title') }}</div>
        <div class="subhead">
          {{ $t('PcAddress.tip1') }}
          <span class="subhead-data mx-2">{{ addressList.length }}</span>
          {{ $t('PcAddress.tip2') }}
          <span class="subhead-data mx-2">{{ '10' }}</span>
          {{ $t('PcAddress.tip3') }}
        </div>
        <div class="flex-grow flex justify-end items-center">
          <button class="add" @click="addAddress">{{ $t('PcAddress.add') }}</button>
        </div>
      </div>
      
      <!-- 列表 -->
      <div class="list-container">
        <myAddress ref="addressChild" :addressList="addressList" :chosenAddressId="chosenAddressId" :mode="mode"></myAddress>
      </div>
    </div>

  </div>
</template>

<script>
import myAddress from './AddressWindow.vue'
export default {
  components: {
    myAddress,
  },
  props: { // 父子组件传值
    mode: {
      type: Boolean,
      default: false, // false为编辑模式，点击进入编辑，true为切换模式，点击切换该地址
    }
  },
  data() {
    return {
      chosenAddressId: -1,
      addressList: [],
    }
  },
  created() {
  /* this.addressList.push({
                id: 1,
                name: 'userData[i].consigneename',
                tel: 'userData[i].contactinformation',
                province: 'userData[i].province',
                city: 'userData[i].city',
                county: 'userData[i].area',
                addressDetail: 'userData[i].detailedaddress',
                address:
                  'userData[i].province' +
                  'userData[i].city' +
                  'userData[i].area' +
                  'userData[i].detailedaddress',
                isDefault: true,
                areaCode: 'userData[i].provincecode',
                areaCode: 'userData[i].citycode',
                areaCode: 'userData[i].areacode',
              }) */
    this.onLoad()
  },
  methods: {
    // 添加新地址
    addAddress() {
      this.$refs.addressChild.onAdd();
    },
    // 如果是确认订单页，点击地址图鉴会切换地址
    onChange(address) {
      this.$parent.onChange(address);
    },
    onLoad() {
      this.$http
        .get('/v1/address/select-address')
        .then((response) => {
          const data = response.data
          if (data.status === 200) {
            let userData = data.data
            let addressList = []
            for (let i = 0; i < userData.length; i++) {
              addressList.push({
                id: userData[i].id,
                name: userData[i].consigneename,
                tel: userData[i].contactinformation,
                province: userData[i].province,
                city: userData[i].city,
                county: userData[i].area,
                addressDetail: userData[i].detailedaddress,
                address:
                  userData[i].province +
                  userData[i].city +
                  userData[i].area +
                  userData[i].detailedaddress,
                isDefault: userData[i].isdefault,
                areaCode: userData[i].provincecode,
                areaCode: userData[i].citycode,
                areaCode: userData[i].areacode,
                // 这里好离谱 连续设置三遍???
              })
            }
            this.addressList = addressList
            if (this.addressList.length === 0) {
              // this.addAddress = true;
              this.$toast(this.$t('MobileAddressManagement.alertAddAddress'))
            } else {
              this.chosenAddressId = this.addressList[0].id
            }
          } else {
            // 处理错误情况
            console.error(data.msg)
          }
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error(error)
        })
    },
  },
}
</script>

<style scoped>
.address-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title-parent {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .title-parent > div {
        transform: scale(0.8);
        transform-origin: top left; /* 确保从中心缩放 */
    }
}
@media (max-width: 980px) or (max-height: 720px) {
    .title-parent > div {
        transform: scale(0.6);
        transform-origin: top left; /* 确保从中心缩放 */
    }
}
.title {
  font-size: 24px;
  font-weight: 500;
  color: #3D3D3D;
  white-space: nowrap;
}
.subhead {
  margin-left: 16px;
  font-size: 14px;
  font-weight: 350;
  color: #B4B4B4;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.subhead-data {
  color: rgba(2, 86, 255, 0.6)
}
.add {
  width: 146px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #165DFF;
  font-size: 14px;
  font-weight: 350;
  color: #FFFFFF;
  cursor: pointer;
}

/* 收货地址列表样式 */
.list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 765px;
  overflow-y: auto;
}

</style>
