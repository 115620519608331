<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>订单管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">订单信息查看</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('')" @click="tabsControl('')">
                        <span @mouseover="changeCursor">全部</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('售后中')" @click="tabsControl('售后中')">
                        <span @mouseover="changeCursor">已完成</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('待付款')" @click="tabsControl('待付款')">
                        <span @mouseover="changeCursor">待付款</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已付款')" @click="tabsControl('已付款')">
                        <span @mouseover="changeCursor">已付款</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已退款')" @click="tabsControl('已退款')">
                        <span @mouseover="changeCursor">已退款</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已取消')" @click="tabsControl('已取消')">
                        <span @mouseover="changeCursor">已取消</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('订单异常')" @click="tabsControl('订单异常')">
                        <span @mouseover="changeCursor">订单异常</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">组合订单编号</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入组合订单编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">独立订单编号</span>
                            <el-input style="width:256px;" v-model="search.separateOrderId" placeholder="请输入独立订单编号"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">买家昵称</span>
                            <el-input style="width:256px;" v-model="search.buyerid" placeholder="请输入买家昵称"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">卖家昵称</span>
                            <el-input style="width:256px;" v-model="search.sellerid" placeholder="请输入卖家昵称"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">时间范围</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadOrder">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetOrder">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="2"><span>最后修改时间</span></el-col>
                        <el-col :span="3"><span>组合订单编号</span></el-col>
                        <el-col :span="3"><span>独立订单编号</span></el-col>
                        <el-col :span="3"><span>买家昵称</span></el-col>
                        <el-col :span="3"><span>买家联系方式</span></el-col>
                        <el-col :span="3"><span>卖家昵称</span></el-col>
                        <el-col :span="3"><span>卖家联系方式</span></el-col>
                        <el-col :span="2" v-if="whichtab===''"><span>订单状态</span></el-col>
                        <el-col :span="2" v-else><span>实际付款</span></el-col>
                        <el-col :span="2"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in showOrder" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.ordertime" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.ordertime }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.combinedOrderId" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.combinedOrderId }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.separateOrderId" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.separateOrderId }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.buyer" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.buyer }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.buyercommunicate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.buyercommunicate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.seller" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.seller }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.sellercommunicate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.sellercommunicate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2" v-if="whichtab===''">
                                    <el-tooltip class="item" :content="apply.processstatus" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.processstatus }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2" v-else>
                                    <el-tooltip class="item" :content="apply.totalprice" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.totalprice }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <!-- <span @mouseover="changeCursor" style="text-decoration: underline;" @click="deleteOrder(apply.id)" v-show="whichtab !== '已取消'">取消订单</span>
                                    &nbsp; -->
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.ids)">查看详情</span>
                                </el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        if (localStorage.getItem("admin-order")) {
            this.whichtab = localStorage.getItem("admin-order")
        }
    },
    mounted() {
        const data = ({
                processstatus: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            })
        this.$http.post('/v1/administrator/select-ordermanage-list', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                this.showOrder = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
            });
    },
    data() {
      return {
        example: { // 纯测试数据，不显示
            "id": 193,
            "separateOrderId": "169174718447578536",
            "ordertime": "2023-08-11T17:46:24.476324",
            "unitlandid": "6003",
            "landid": "S725",
            "landname": null,
            "salemeans": "立即购买",
            "totalprice": 1301.0,
            "buyerid": "43a89960781b41de9e16c2575772dc54",
            "sellerid": null,
            "addressid": "171",
            "needpaper": false,
            "orderstatus": 1,
            "processstatus": "退款中",
            "refundreason": "不想要了",
            "isdelete": null,
            "refundtime": "2023-08-21T15:49:03",
            "originalLand": null
        },
        loading: true, // 控制骨架屏
        whichtab: '', // 筛选的状态
        showOrder: null, // 显示的当页订单信息，根据页码页距控制
        showLength: 0, // 条数
        currentPage: 1, // 页码
        pageSize: 50, // 每页条数

        search: {
            id: '', // 组合订单编号
            separateOrderId: '', // 独立订单编号
            buyerid: '',
            sellerid: '',
            date: '',
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        loadOrder() {
            let data = { // 初始化必须的筛选条件
                processstatus: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            // 添加用户输入的筛选条件
            if(this.search.id !== '') {
                data["combinedOrderId"] = this.search.id;
            }
            if(this.search.separateOrderId !== '') {
                data["separateOrderId"] = this.search.separateOrderId;
            }
            if(this.search.buyerid !== '') {
                data["buyer"] = this.search.buyerid;
            }
            if(this.search.sellerid !== '') {
                data["seller"] = this.search.sellerid;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }
            this.$http.post('/v1/administrator/select-ordermanage-list',data,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            .then(res => {
                this.showOrder = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("加载失败，请重试或重新登录");
                this.loading = false;
            });
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            this.loadOrder();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loading = true;
            this.loadOrder();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val
            this.loading = true;
            this.loadOrder();
        },
        resetOrder() {
            this.search.id = '';
            this.search.separateOrderId = '';
            this.search.sellerid = '';
            this.search.buyerid = '';
            this.search.date = '';
            this.loading = true;
            this.loadOrder();
        },
        landReview(id) { // 查看详情
            localStorage.setItem("admin-order", this.whichtab);
            this.$router.push('/admin/order-manage/detail?id=' + id)
        },
        deleteOrder(id) { // TODO取消订单,不知道传什么参数
            this.$toast("暂无此功能")
            /* this.$http.get('/v1/order/cancel-order?status=' + this.order.whichtab)
                .then(res => {
                    this.orderRefund = res.data.data
                    this.initializePage()
                }).catch(error => {
                    // 处理错误
                    console.error(error.data)
                }); */
        },
    },
}
</script>

<style scoped>
</style>