<template>
  <div class="confirm-container">
    <div class="confirm-box">
      <div class="normal-card-title">{{$t('MobileOrderList.continueTitle')}}</div>
      <AddressWindow :addressList="chosenAddress"></AddressWindow>
      <span class="confirm-orderinfor">{{$t('PcConfirmOrder.orderinfor')}}</span>
      <MerchWindow :order="LandInfor" :totalPrice="totalPrice"></MerchWindow>
      <!-- 是否开具纸质土地证明-->
      <van-cell center class="mt-5" v-show="false">
        <template #icon>
          {{$t('MobileConfirmOrder.paper')}}
          <van-switch v-model="needpaper" @click="()=>{if(needpaper){totalPrice-=25} else {totalPrice+=25};}" size="15px" class="ml-2" />
        </template>
      </van-cell>
      <!-- 充值方式 -->
      <span class="topup-card-subhead">{{$t('PcConfirmOrder.payment')}}</span>
        <!-- 支付宝支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'alipay' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:14px"
          v-if="$setPayment === 'zh'"
          @click="payWay='alipay'"
        >
          <img src="../pImage/alipay.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.aliPay')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.rmb')}}</div>
        </div>
        <!-- paypal支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'paypal' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:14px"
          v-if="$setPayment === 'am'"
          @click="payWay='paypal'"
        >
          <img src="../pImage/paypal.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.paypal')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.dollar')}}</div>
        </div>
        <!-- 余额支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'balance' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:16px"
          @click="payWay='balance'"
        >
          <img src="../pImage/balance.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.balance')}}{{ '(' + balance + ')' }}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.balance')}}</div>
        </div>
    </div>

    <!-- 底部总金额 -->
    <div class="price-box">
      <span class="price-merchnum">{{$t('PcConfirmOrder.total1')}}{{ LandInfor.length }}{{$t('PcConfirmOrder.total2')}}</span>
      <span class="price-total">{{$t('ShopCart.total')}}:</span>
      <span class="price-total-number">{{ $t('ShopCartInfor.price') + totalPrice }}</span>
      <div class="flex-grow flex justify-end">
        <button class="price-button" @click="onSubmit" v-if="haveData">{{$t('MobileConfirmOrder.submitOrder')}}</button>
      </div>
    </div>

    <!-- 选择/编辑地址弹窗 -->
    <div class="developer-common-modal" v-if="showAddressList">
      <AddressList :mode="true" style="width:980px;height:900px;"></AddressList>
    </div>

    <!-- 余额支付的弹窗 -->
    <div class="common-modal" v-if="showPopup" @click="handleClickOutside">
      <!-- 余额充足/选择完混合支付方式，输入支付密码 -->
      <div class="common-modal-box justify-center items-center" v-if="showPasswordInput" @click="handleClickInside">
        <div class="text-xl text-center">{{ $t('PcConfirmOrder.pay') }}</div>
        <!-- 密码输入框 -->
        <div class="pay-password-input mt-5" @click="handleClick()">
          <div v-for="(item, index) in 6" :key="index" class="pay-password-item" :class="{ active: index === payPassword }">
            <span class="input-cursor" v-show="inputFocus && index === payPassword.length"></span>
            <span v-if="payPassword.length > index" class="pay-password-item-hidden"></span>
          </div>
        </div>
        <!-- 隐藏的input标签 -->
        <input type="text" ref="payPasswordInut" v-model="payPassword" style="opacity:0;height:1px;width:1px;" @focus="inputFocus=true" @blur="inputFocus=false">
      </div>
      
      
      <!-- 余额不足，选择混合支付方式 -->
      <div class="common-modal-box justify-center items-center" v-else @click="handleClickInside">
        <span class="text-xl">{{ $t('PcConfirmOrder.needpay') }}:<span class="text-green-500 ml-2">{{ totalPrice }}</span></span>
        <span class="text-xl mt-2">{{ $t('PcConfirmOrder.yourbalance') }}:<span class="text-red-500 ml-2">{{ balance[0] }}.<span class="text-sm">{{ balance[1] }}</span></span></span>
        <div class="w-full text-right text-blue-500 text-sm"><span class="cursor-pointer" @click="$router.push('/user/top-up')">{{ $t('MobileConfirmOrder.topup') }}</span></div>
        <!-- 充值方式 -->
        <span class="topup-card-subhead">{{ $t('PcConfirmOrder.choosepayment') }}</span>
        <!-- 支付宝混合支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'mixAliPay' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:14px"
          v-if="$setPayment = 'zh'"
          @click="payWay='mixAliPay'"
        >
          <img src="../pImage/alipay.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.aliPay')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.rmb')}}</div>
        </div>
        <!-- 微信混合支付 -->
        <div
          class="topup-card-rechargemode cannot-select-this-payment"
          :class="payWay === 'mixWechat' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:16px"
          v-if="$setPayment = 'zh'"
        >
          <img src="../pImage/wechat.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.wechat')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.rmb')}}</div>
        </div>
        <!-- paypal混合支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'mixPaypal' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:14px"
          v-if="$setPayment = 'am'"
          @click="payWay='mixPaypal'"
        >
          <img src="../pImage/paypal.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.paypal')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.dollar')}}</div>
        </div>
        <!-- 下一步按钮 -->
        <div @click="balancePayNextStep" class="mt-8 rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center cursor-pointer">
          <span class="text-lg self-center text-white">{{$t('MobileAssociatedSet.nextStep')}}</span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import AddressWindow from './AddressWindow.vue'
import AddressList from './AccountAddress.vue'
import MerchWindow from './ContinueWindow.vue'
export default {
  components: {
    AddressWindow,
    AddressList,
    MerchWindow
  },
  created() {
        if (this.$route.params.orders) {
            this.LandInfor = JSON.parse(this.$route.params.orders);
        }
        if (this.$route.params.totalPrice) {
            this.totalPrice = JSON.parse(this.$route.params.totalPrice);
            this.haveData = true;
        } else {
            this.$toast.fail(this.$t('MobileAssociatedReplaceloadFail'));
        }
        //查询默认地址
        this.$http.get("/v1/address/select-address?isdefault=true",)
            .then((res) => {
                //todo 没有默认地址
                if(res.data.data === '没有收货地址'){
                    return;
                }
                const userData = res.data.data;
                this.chosenAddressId = userData.id;
                this.chosenAddress.push({
                  id: userData.id,
                  name: userData.consigneename,
                  tel: userData.contactinformation,
                  province: userData.province,
                  city: userData.city,
                  county: userData.area,
                  addressDetail: userData.detailedaddress,
                  address:
                    userData.province +
                    userData.city +
                    userData.area +
                    userData.detailedaddress,
                  isDefault: userData.isdefault,
                  areaCode: userData.provincecode,
                  areaCode: userData.citycode,
                  areaCode: userData.areacode,
                })
            })
            .catch((err) => {
                console.log(err);
            });

        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                const value = Number(res.data.data.balance).toFixed(2).toString();
                this.balance = value.split(".");
            })
            .catch((err) => {
                console.log(err);
            });
  },
  mounted() {
    this.controlWindow();
    window.addEventListener('resize', this.controlWindow);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.controlWindow);
  },
  // 监听支付密码，必须输入数字且输入六位后直接支付
  watch: {
    payPassword(payPassword,o) {
      const re = /^[0-9]+$/;
      if (!re.test(payPassword)) {
        this.payPassword = o;
        return;
      }
      if (payPassword.length >= 6) {
        this.payPassword = this.payPassword.substring(0, 6) //  截取前六位
        this.onPay();
      }
    },
  },
  data() {
    return {
      haveData: false, // 检查一下url格式，防止有人恶意输url发起订单

      chosenAddressId: null, // 提交订单时用来确定地址的参数
      chosenAddress: [], // 当前选中的收货地址，只显示一条，但因为组件原因需要设置为数组
      showAddressList: false, // 是否显示收货地址列表弹窗

      balance: ['0','00'], // 虚拟币余额，后端获取
      
      totalPrice: 0, // 页面url传过来的总金额
      LandInfor: [], // 页面url传过来的商品列表
      example: {
                ids: 0,
                separateOrderId: "",
                combinedOrderId: "",
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: "",
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                combinationpay: null,
                buyerid: "",
                sellerid: "",
                addressid: "",
                needpaper: true,
                orderstatus: "",
                canceltime: null, // 待支付剩余时间
                terminationtime: null, // 退款截止日期
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                originalLand: {
                    id: 0,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 0,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 0,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                },
                onSale: {
                    id: 0,
                    originallandid: "",
                    unitlandid: "",
                    landname: "",
                    communitynumber: null,
                    sellerid: "",
                    seller: "",
                    salesmethods: "",
                    expirydate: "",
                    unitlandprice: null,
                    pricenum: 0,
                    unitlandminprice: null,
                    minimumprice: null,
                    blocks: 0,
                    totalprice: 0,
                    unitpicture: "",
                    createtime: "",
                    reasondelisting: null,
                    status: "未挂牌",
                    remark: "",
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: null,
                    unitLand: null
                },
      },
      
      payWay: "paypal", // 支付方式
      needpaper: false, // 是否需要纸质契约
      showPopup: false, // 余额支付的弹窗，根据showPasswordInput确定是否显示输入支付密码
      showPasswordInput: false,
      payPassword: "", // 支付密码
      inputFocus: false, // 支付密码输入框获取焦点
    };
  },
  methods: {
    // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1300 || window.innerHeight <= 880) {
                const scaleX = (window.innerWidth - 100) / 1200;
                const scaleY = (window.innerHeight - 38) / 850;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
                this.$refs.mainContainer.style.height = 842 / scale + 'px';
                this.$refs.mainContainer.style.width = 1200 / scale + 'px';
                this.$refs.mainContainer.style.marginLeft = (window.innerWidth - 1200 * scale) / 2 + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = `100%`;
                this.$refs.mainContainer.style.width = `100%`;
                this.$refs.mainContainer.style.marginLeft = `0`;
            }
        },
    // 点击收货地址弹窗中的某个地址时切换当前地址
    onChange(address) {
      this.chosenAddress = [];
      this.chosenAddress.push(address);
      this.chosenAddressId = address.id;
      this.showAddressList = false;
    },

    /** 提交订单&&正常支付*/
        onSubmit() {
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
                ischeck: true,
            };
            if(this.chosenAddressId===null){
                this.$toast.fail(this.$t('MobileConfirmOrder.alertNoAddress'));
                return ;
            }
            const toastLoading = this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('AccountSafe.loading'),
                forbidClick: true,
            });
            if (this.payWay === "aliPay") {
                this.$http.post("/v1/alipay/pay", data).then((res) => {
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            } else if (this.payWay === "wechat") {
                toastLoading.clear();
                this.$toast(this.$t('MobileConfirmOrder.alertWechat'));
            } else if (this.payWay==='paypal'){
                this.$http.post("/v1/paypal/pay", data).then((res) => {
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            } else if (this.payWay==='balance'){
                this.showPopup = true;
                if(this.balance>=this.totalPrice){
                    this.showPasswordInput = true;
                }
            }
        },


    // 点击弹窗外围背景关闭弹窗
    handleClickOutside(event) {
      if (!event.target.closest('.common-modal-box')) {
        // 点击弹窗外部，关闭弹窗
        this.showPopup = false;
      }
    },
    handleClickInside() {
      // 点击弹窗内部，不做任何操作
    },
    // 余额支付余额不足时需选择混合支付方式然后下一步
    balancePayNextStep() {
      if (this.payWay !== 'mixAlipay' && this.payWay !== 'mixWechat' && this.payWay !== 'mixPaypal') {
        this.$toast.fail('请选择一样混合支付方式');
      } else {
        this.showPasswordInput = true;
      }
    },
    // 点击输入框触发输入监听事件
    handleClick() {
      this.$refs.payPasswordInput.focus();
    },
    // 余额支付点击支付
    onPay() {
      const unitlandids = this.LandInfor.map((item) => item.unitlandid);
      const toastLoading= this.$toast.loading({
        duration: 0, // 持续展示 toast
        message: this.$t('MobileConfirmOrder.alertLoading'),
        forbidClick: true,
      });
      const data = {
        needpaper: this.needpaper,
        unitlandid: unitlandids,
        addressid: this.chosenAddressId,
        paymentpassword: this.payPassword,
        ischeck: true,
      };
      if(this.payWay==='balance'){
                this.$http.post("/v1/balance-payment/pay", data).then((res) => {
                    toastLoading.clear();
                    if (res.data.data==='密码错误') {
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertPasswordError'));
                        this.payPassword='';
                        this.showPopup = true;
                        this.showPasswordInput = true;
                        return ;
                    }
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.go(-1);
                });
      } else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if (res.data==='密码错误') {
                            toastLoading.clear();
                            this.$toast.fail(this.$t('MobileConfirmOrder.alertPasswordError'));
                            this.payPassword='';
                            this.showPopup = true;
                            this.showPasswordInput = true;
                            return ;
                        }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
      } else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
      } else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if (res.data.data==='密码错误') {
                            toastLoading.clear();
                            this.$toast.fail(this.$t('MobileConfirmOrder.alertPasswordError'));
                            this.payPassword='';
                            this.showPopup = true;
                            this.showPasswordInput = true;
                            return;
                        }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
      }
    },
  },
}
</script>

<style scoped>
</style>