import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/index.js";
import './assets/global.css';


// 导入axios
import axios from "axios";
import "tailwindcss/tailwind.css";
import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';
import 'animate.css';
// 安装element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 安装vue-i18n
import VueI18n from 'vue-i18n'
// 导入vant
import {
    AddressEdit,
    AddressList,
    Button,
    Card,
    Cell,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    Col,
    Collapse,
    CollapseItem,
    DatetimePicker,
    Dialog,
    Divider,
    DropdownItem,
    DropdownMenu,
    Empty,
    Field,
    Icon,
    Lazyload,
    NavBar,
    PullRefresh,
    Radio,
    RadioGroup,
    Row,
    Skeleton,
    Step,
    Steps,
    SubmitBar,
    Swipe,
    SwipeCell,
    SwipeItem,
    Switch,
    Tab,
    Tabs,
    Tag,
    Toast,
    Uploader,
    Popup,
    PasswordInput,
    NumberKeyboard,
    Notify,
    Image as VanImage,
    Locale,
} from "vant"; // 图片懒加载，同样在手机端首页 // 轮播，用在手机端首页
import 'vant/lib/index.css';
import lang from 'vant/es/locale/lang/zh-CN'; // 默认语言为中文
import enUS from 'vant/es/locale/lang/en-US'; // 英文语言包
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
// 导入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts //可以全局使用 不用每次使用需要在页面导入


Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Popup);
Vue.use(ElementUI);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(PullRefresh);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Skeleton);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(SwipeCell);
Vue.use(SubmitBar);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(DatetimePicker);
Vue.use(NavBar);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Divider);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Uploader);
Vue.use(VanImage);

if (localStorage.getItem('language') === 'zh') { // 设置vant的语言
  Locale.use('zh', lang);
} else { // 默认语言为英语
  Locale.use('en', enUS);
}

Vue.use(VueI18n); // vue-i18n

// 在Vue.js原型上添加axios属性
const http = axios.create({
    baseURL: "/",
    timeout: 20000, // 超时设置
});
// 重试请求的函数
const apiRequest = async (config, retries = 10) => {
  try {
    const response = await http(config);
    return response;
  } catch (error) {
    if (retries > 0) {
      console.warn(`Axios Error: ${retries}. `);
      return await apiRequest(config, retries - 1);
    } else {
      throw error; // 超过重试次数，抛出错误
    }
  }
};
Vue.prototype.$http = http;

/* 全局变量 */
Vue.mixin({
  beforeCreate() {
    this.$exchangeRate = 7.2; // 汇率
    this.$setPayment = localStorage.getItem('payment') ? localStorage.getItem('payment') : 'am'; // 支付方式设置-am是美元版，zh是国内版
    // 手机国际号
    this.$intlNumber = [
      { value: "+93", text: "+93 Afghanistan (افغانستان)" },
      { value: "+355", text: "+355 Albania (Shqipëri)" },
      { value: "+213", text: "+213 Algeria (الجزائر)" },
      { value: "+1", text: "+1 Andorra (Andorra)" },
      { value: "+376", text: "+376 Andorra (Andorra)" },
      { value: "+244", text: "+244 Angola (Angola)" },
      { value: "+1", text: "+1 Antigua and Barbuda (Antigua and Barbuda)" },
      { value: "+672", text: "+672 Australian External Territories" },
      { value: "+54", text: "+54 Argentina (Argentina)" },
      { value: "+374", text: "+374 Armenia (Հայաստան)" },
      { value: "+297", text: "+297 Aruba (Aruba)" },
      { value: "+61", text: "+61 Australia (Australia)" },
      { value: "+43", text: "+43 Austria (Österreich)" },
      { value: "+994", text: "+994 Azerbaijan (Azərbaycan)" },
      { value: "+1", text: "+1 Bahamas (The Bahamas)" },
      { value: "+973", text: "+973 Bahrain (البحرين)" },
      { value: "+880", text: "+880 Bangladesh (বাংলাদেশ)" },
      { value: "+1", text: "+1 Barbados (Barbados)" },
      { value: "+32", text: "+32 Belgium (België)" },
      { value: "+501", text: "+501 Belize (Belize)" },
      { value: "+229", text: "+229 Benin (Bénin)" },
      { value: "+1", text: "+1 Bermuda (Bermuda)" },
      { value: "+975", text: "+975 Bhutan (འབྱར་ཡུལ་)" },
      { value: "+591", text: "+591 Bolivia (Bolivia)" },
      { value: "+387", text: "+387 Bosnia and Herzegovina (Bosna i Hercegovina)" },
      { value: "+267", text: "+267 Botswana (Botswana)" },
      { value: "+55", text: "+55 Brazil (Brasil)" },
      { value: "+1", text: "+1 British Virgin Islands (British Virgin Islands)" },
      { value: "+673", text: "+673 Brunei (Brunei Darussalam)" },
      { value: "+359", text: "+359 Bulgaria (България)" },
      { value: "+226", text: "+226 Burkina Faso" },
      { value: "+257", text: "+257 Burundi (Uburundi)" },
      { value: "+855", text: "+855 Cambodia (កម្ពុជា)" },
      { value: "+237", text: "+237 Cameroon (Cameroun)" },
      { value: "+1", text: "+1 Canada (Canada)" },
      { value: "+238", text: "+238 Cape Verde (Kabu Verdi)" },
      { value: "+345", text: "+345 Cayman Islands (Cayman Islands)" },
      { value: "+236", text: "+236 Central African Republic" },
      { value: "+235", text: "+235 Chad (Tchad)" },
      { value: "+56", text: "+56 Chile (Chile)" },
      { value: "+86", text: "+86 China (中国)" },
      { value: "+61", text: "+61 Christmas Island" },
      { value: "+61", text: "+61 Cocos (Keeling) Islands" },
      { value: "+57", text: "+57 Colombia (Colombia)" },
      { value: "+269", text: "+269 Comoros (مؤسسات جزيرة القمر)" },
      { value: "+682", text: "+682 Cook Islands" },
      { value: "+506", text: "+506 Costa Rica (Costa Rica)" },
      { value: "+225", text: "+225 Côte d'Ivoire (Ivory Coast)" },
      { value: "+385", text: "+385 Croatia (Hrvatska)" },
      { value: "+53", text: "+53 Cuba (Cuba)" },
      { value: "+599", text: "+599 Curacao (Curaçao)" },
      { value: "+357", text: "+357 Cyprus (Κύπρος)" },
      { value: "+420", text: "+420 Czech Republic (Česká republika)" },
      { value: "+45", text: "+45 Denmark (Danmark)" },
      { value: "+253", text: "+253 Djibouti (Djibouti)" },
      { value: "+1", text: "+1 Dominica (Dominica)" },
      { value: "+1809", text: "+1809 Dominican Republic (República Dominicana)" },
      { value: "+593", text: "+593 Ecuador (Ecuador)" },
      { value: "+20", text: "+20 Egypt (مصر)" },
      { value: "+503", text: "+503 El Salvador (El Salvador)" },
      { value: "+240", text: "+240 Equatorial Guinea (Guinea Ecuatorial)" },
      { value: "+291", text: "+291 Eritrea (ኤርትራ)" },
      { value: "+372", text: "+372 Estonia (Eesti)" },
      { value: "+251", text: "+251 Ethiopia (ኢትዮጵያ)" },
      { value: "+500", text: "+500 Falkland Islands (Malvinas)" },
      { value: "+298", text: "+298 Faroe Islands (Føroyar)" },
      { value: "+679", text: "+679 Fiji (Fiji)" },
      { value: "+358", text: "+358 Finland (Suomi)" },
      { value: "+33", text: "+33 France (France)" },
      { value: "+241", text: "+241 Gabon (Gabon)" },
      { value: "+220", text: "+220 Gambia (The Gambia)" },
      { value: "+995", text: "+995 Georgia (საქართველო)" },
      { value: "+49", text: "+49 Germany (Deutschland)" },
      { value: "+233", text: "+233 Ghana (Ghana)" },
      { value: "+350", text: "+350 Gibraltar" },
      { value: "+30", text: "+30 Greece (Ελλάδα)" },
      { value: "+299", text: "+299 Greenland (Gre land)" },
      { value: "+1", text: "+1 Grenada (Grenada)" },
      { value: "+590", text: "+590 Guadeloupe (Guadeloupe)" },
      { value: "+502", text: "+502 Guatemala (Guatemala)" },
      { value: "+44", text: "+44 Guernsey (Guernsey)" },
      { value: "+224", text: "+224 Guinea (Guinée)" },
      { value: "+245", text: "+245 Guinea-Bissau (Guiné-Bissau)" },
      { value: "+595", text: "+595 Paraguay (Paraguay)" },
      { value: "+241", text: "+241 Republic of the Congo" },
      { value: "+262", text: "+262 Réunion" },
      { value: "+40", text: "+40 Romania (România)" },
      { value: "+7", text: "+7 Russia (Россия)" },
      { value: "+250", text: "+250 Rwanda (U Rwanda)" },
      { value: "+966", text: "+966 Saudi Arabia (المملكة العربية السعودية)" },
      { value: "+221", text: "+221 Senegal (Sénégal)" },
      { value: "+381", text: "+381 Serbia (Србија)" },
      { value: "+248", text: "+248 Seychelles (Seychelles)" },
      { value: "+65", text: "+65 Singapore (Singapore)" },
      { value: "+421", text: "+421 Slovakia (Slovensko)" },
      { value: "+386", text: "+386 Slovenia (Slovenija)" },
      { value: "+677", text: "+677 Solomon Islands" },
      { value: "+252", text: "+252 Somalia (Soomaaliya)" },
      { value: "+27", text: "+27 South Africa" },
      { value: "+500", text: "+500 South Georgia and the South Sandwich Islands" },
      { value: "+34", text: "+34 Spain (España)" },
      { value: "+94", text: "+94 Sri Lanka (ශ්‍රී ලංකාව)" },
      { value: "+211", text: "+211 South Sudan (جنوب السودان)" },
      { value: "+1", text: "+1 Saint Kitts and Nevis (Saint Kitts and Nevis)" },
      { value: "+1", text: "+1 Saint Lucia (Saint Lucia)" },
      { value: "+1", text: "+1 Saint Vincent and the Grenadines (Saint Vincent and the Grenadines)" },
      { value: "+249", text: "+249 Sudan (السودان)" },
      { value: "+597", text: "+597 Suriname (Suriname)" },
      { value: "+47", text: "+47 Svalbard and Jan Mayen" },
      { value: "+41", text: "+41 Switzerland (Schweiz)" },
      { value: "+963", text: "+963 Syria (سوريا)" },
      { value: "+886", text: "+886 Taiwan (台灣)" },
      { value: "+992", text: "+992 Tajikistan (Тоҷикистон)" },
      { value: "+255", text: "+255 Tanzania (Tanzania)" },
      { value: "+66", text: "+66 Thailand (ไทย)" },
      { value: "+670", text: "+670 Timor-Leste (Timor-Leste)" },
      { value: "+228", text: "+228 Togo (Togo)" },
      { value: "+676", text: "+676 Tonga (Tonga)" },
      { value: "+1", text: "+1 Trinidad and Tobago (Trinidad and Tobago)" },
      { value: "+216", text: "+216 Tunisia (تونس)" },
      { value: "+90", text: "+90 Turkey (Türkiye)" },
      { value: "+993", text: "+993 Turkmenistan (Türkmenistan)" },
      { value: "+1", text: "+1 Turks and Caicos Islands (Turks and Caicos Islands)" },
      { value: "+688", text: "+688 Tuvalu (Tuvalu)" },
      { value: "+256", text: "+256 Uganda (Uganda)" },
      { value: "+380", text: "+380 Ukraine (Україна)" },
      { value: "+971", text: "+971 United Arab Emirates (الإمارات العربية المتحدة)" },
      { value: "+44", text: "+44 United Kingdom (United Kingdom)" },
      { value: "+1", text: "+1 United States (United States)" },
      { value: "+598", text: "+598 Uruguay (Uruguay)" },
      { value: "+998", text: "+998 Uzbekistan (O'zbekistan)" },
      { value: "+678", text: "+678 Vanuatu (Vanuatu)" },
      { value: "+379", text: "+379 Vatican City (Città del Vaticano)" },
      { value: "+58", text: "+58 Venezuela (Venezuela)" },
      { value: "+84", text: "+84 Vietnam (Việt Nam)" },
      { value: "+1", text: "+1 Wallis and Futuna" },
      { value: "+681", text: "+681 Western Sahara" },
      { value: "+967", text: "+967 Yemen (اليمن)" },
      { value: "+260", text: "+260 Zambia (Zambia)" },
      { value: "+263", text: "+263 Zimbabwe (Zimbabwe)" },
    ];
  }
});

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'en', // 默认语言为英文
    fallbackLocale: 'en', // 如果找不到对应语言的文本，则使用英文
    messages: {
      en: require('./locales/en.json'), // 英文翻译文件的路径
      zh: require('./locales/zh.json') // 中文翻译文件的路径
    }
});

  // 在main.js中配置全局导航守卫
  /* router.beforeEach((to, from, next) => {
    if (to.meta.role === 'user') { // 普通用户
      // 调用接口询问该用户是否在线
      http.get("/v1/index/detect-user-status")
      .then(response => {
        if (response.data.status === 200) {
          next();
        } else { // 接口201，未登录，不允许跳转
          next(false);
          Toast("User not logged in.");
        }
      }).catch(error => {
        // 处理接口调用失败的情况,例如，将其重定向到错误页面
        alert("Network error!")
        next(false)
      });
    } else if (to.meta.role === 'developer') {
      // 调用接口询问该用户是否在线
      http.get("/v1/index/detect-business-status")
      .then(response => {
        if (response.data.status === 200) {
          next();
        } else { // 接口200，已登录，重定向到登录后主页
          next(false);
          alert("User not logged in.");
        }
      }).catch(error => {
        // 处理接口调用失败的情况,例如，将其重定向到错误页面
        next()
      });
    } else {
      next()
    }
  }) */


new Vue({
    router,
    store,
    el: '#app',
    render: (h) => h(App),
    i18n,
}).$mount("#app");

export default Vue;



// mita识别标识需要在index.js中配置,下面是范例
    /* {
      path: '/users',
      component: UserComponent,
      meta: {
        role: 'user' // 根据需要验证的页面角色设置为'user'
      }
    } */


// // 解决移动端APP延迟
// document.addEventListener('deviceready', function() {
//     new Vue({
//         router,
//         store,
//         render: (h) => h(App),
//     }).$mount("#app");
// }, false);

// Vue.prototype.$http.interceptors.request.use(config => {
//     // 从本地存储中获取cookie值
//     if (config.url !== "/index/login" && config.url!=="/index/loginYZM") { // 非登录请求时才执行添加token的逻辑
//         console.log("config", config)
//         const token = this.$store.state.user.token;
//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }
//     }
//     return config;
// }, error => {
//     // 处理请求错误
//     return Promise.reject(error);
// });

// 响应拦截器
// Vue.prototype.$http.interceptors.response.use(response => {
//     // 在收到响应后，可以对响应进行处理
//     return response;
// }, error => {
//     // 处理响应错误
//     return Promise.reject(error);
// });