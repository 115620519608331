<template>
    <div class="upload-form">
        <div class="upload-in-the-form">
            <div class="land-review-title-parent">
                <div class="land-review-title">{{$t('LandReview.title')}}</div>
                <div class="flex flex-row ml-5 items-center">
                    <span class="mr-5">{{$t('LandReview.statu')}}:</span>
                    <div class="land-review-title-statu-yishangxian" v-if="LandInfor.landstatus === '已上线'">{{$t('LandReview.yishangxian')}}</div>
                    <div class="land-review-title-statu-yixiaxian" v-if="LandInfor.landstatus === '已下线'">{{$t('LandReview.yixiaxian')}}</div>
                    <div class="land-review-title-statu-daishenhe" v-if="LandInfor.landstatus === '待审核'">{{$t('LandReview.daishenhe')}}</div>
                    <div class="land-review-title-statu-yibohui" v-if="LandInfor.landstatus === '已驳回'">{{$t('LandReview.yibohui')}}</div>
                </div>
            </div>
            <div class="flex flex-col p-10 w-full">
                <!-- 土地产权编号 -->
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.ownershipnumber')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.ownershipnumber }}</span>
                </div>
                <!-- 原始土地名称 -->
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.originallandname')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.originallandname }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.createdate')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.createdate }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.detailedaddress')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.detailedaddress }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.geo')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.geographicalcoordinates }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.landlength')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.landlength }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.landwidth')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.landwidth }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.height')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.height }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.landarea')}}:</div>
                    <span class="land-review-item-data">{{ LandInfor.landarea }}{{$t('LandReview.areaunit2')}}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.landcertificate')}}:</div>
                    <a class="company-register-input-link" :href="LandInfor.landcertificate" target="_blank">{{$t('CompanyInfor.download')}}</a>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.warrantydeed')}}:</div>
                    <a class="company-register-input-link" :href="LandInfor.warrantydeed" target="_blank">{{$t('CompanyInfor.download')}}</a>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.height')}}:</div>
                    <span>{{ LandInfor.height }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('LandReview.landpicture')}}:</div>
                    <div class="upload-form-item-img" @mouseover="changeCursor">
                        <el-tooltip class="item" effect="dark" :content="$t('myCompany.seeImage')" placement="top-end">
                            <img :src="LandInfor.landpicture" alt="土地照片" class="upload-form-item-img-i" @click="show">
                        </el-tooltip>
                        <!-- 弹框的显示和隐藏状态，根据 islandimg 变量决定 -->
                        <div class="lightbox" v-if="islandimg" @click="hide">
                            <img class="full-image" :src="LandInfor.landpicture"/>
                        </div>
                    </div>
                </div>
                <!-- 驳回原因-->
                <div class="land-review-item" v-if="LandInfor.landstatus==='已驳回'">
                    <div class="land-review-item-label" style="color:red;">{{$t('LandReview.reason')}}:</div>
                    <span class="land-review-reject">{{ LandInfor.reasonrejection }}</span>
                </div>
                <!-- 重新上传按钮-->
                <div class="land-review-item" v-if="LandInfor.landstatus==='已驳回'">
                    <el-button type="primary" class="developer-common-button" @click.prevent="reupload">{{$t('LandReview.reupload')}}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        LandInfor: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            islandimg: false, // 土地照片原图显示
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 点击图片查看原图事件 */
        show() {
            this.islandimg = true;
        },
        hide() { // 点击弹框外部时，隐藏弹框
            this.islandimg = false;
        },

        /* 重新上传事件 */
        reupload() {
            this.$parent.gotolandupload();
        }
    },
}
</script>

<style scoped>
/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
/* 表单基础层-白色背景色 */
.upload-form {
    padding: 50px;
    border-radius: 20px;
    background-color: #fff;
    height: calc(100% - 40px);
    overflow-y: hidden;
}
/* 第一行-标题、状态展示 */
.land-review-title-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.land-review-title {
    font-size: 28px;
    font-weight: 600;
    border-left: 4px solid #476DFF;
    height: 31px;
    padding-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* 已上线标签 */
.land-review-title-statu-yishangxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 10px 30px;
    background-color: rgba(0, 181, 120, 0.1);
    color: #00B578;
}
/* 待审核标签 */
.land-review-title-statu-daishenhe {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 10px 30px;
    background-color: rgba(255, 195, 0, 0.1);
    color: #FFC300;
}
/* 已驳回标签 */
.land-review-title-statu-yibohui {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 10px 30px;
    background-color: rgba(250, 81, 81, 0.1);
    color: #FA5151;
}
/* 已下线标签 */
.land-review-title-statu-yixiaxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 10px 30px;
    background-color: rgba(54, 98, 236, 0.1);
    color: #3662EC;
}


/* ------------------------------各行凭证信息样式------------------------------- */
.land-review-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.land-review-item-label {
    font-size: 16px;
    color: #4E5969;
    width: 200px;
}
.land-review-item-data {
    max-width: 500px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* 驳回原因 */
.land-review-reject {
    color: red;
    max-width: 300px;
    word-wrap: break-word;
}


/* ------------------------------土地照片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    margin-right: 30px;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* ------------------------------图片弹窗------------------------------- */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  z-index: 99; /* 将弹框置于其他所有元素之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-image {
  max-width: 80%;
  max-height: 80%;
}
</style>