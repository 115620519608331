<template>
    <div class="developer-common-safe">
        <div class="developer-common-safe-title">{{$t('AccountPassword.title')}}</div>
                    <div class="developer-account-input-parent">
                        <span style="padding:10px 0;">{{$t('AccountPassword.oldpassword')}}:</span>
                        <el-input :type="showPassword ? '' : 'password'" v-model="oldPassword" style="width:400px" :placeholder="$t('AccountPassword.enteroldpassword')">
                            <el-tooltip class="item" effect="dark" :content="$t('forgetPassword.showOrHidePassword')" placement="top-end"  slot="append">
                                <span :class="showPassword ? 'el-icon-view' : 'el-icon-lock'" @click="togglePassword()" @mouseover="changeCursor"></span>
                            </el-tooltip>
                        </el-input>
                    </div>
                    <div class="developer-account-input-parent" style="margin-top:20px;">
                        <span style="padding:10px 0;">{{$t('forgetPassword.newPassword')}}:</span>
                        <el-input :type="showPassword ? '' : 'password'" v-model="changePassword" style="width:400px" :placeholder="$t('forgetPassword.setNewPassword')" @input="checkPassword">
                            <el-tooltip class="item" effect="dark" :content="$t('forgetPassword.showOrHidePassword')" placement="top-end"  slot="append">
                                <span :class="showPassword ? 'el-icon-view' : 'el-icon-lock'" @click="togglePassword()" @mouseover="changeCursor"></span>
                            </el-tooltip>
                        </el-input>
                        <div class="flex flex-row items-center">
                            <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules1">
                            <img src="../developer/safe-access.png" class="infor-icon" v-else>
                            <span class="developer-account-input-tips" :style="showRules1?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip1')}}</span>
                        </div>
                        <div class="flex flex-row items-center">
                            <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules2">
                            <img src="../developer/safe-access.png" class="infor-icon" v-else>
                            <span class="developer-account-input-tips" :style="showRules2?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip2')}}</span>
                        </div>
                        <div class="flex flex-row items-center">
                            <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules3">
                            <img src="../developer/safe-access.png" class="infor-icon" v-else>
                            <span class="developer-account-input-tips" :style="showRules3?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip3')}}</span>
                        </div>
                    </div>
                    <button class="developer-account-button" @click="openModal">{{$t('forgetPassword.resetPassword')}}</button>
        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal" @click="vmodal=false">
            <div class="developer-common-modal-box" v-if="modalMode">
                <span>{{$t('AccountPassword.tip4')}}</span>
                <div style="margin-top: 50px;">
                    <el-button type="infor" class="developer-common-button" @click="vmodal=false">{{$t('CompanyInfor.no')}}</el-button>
                    <el-button type="primary" class="developer-common-button" @click.prevent="add">{{$t('CompanyInfor.yes')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{ errorText }}</span>
                <el-button type="primary" class="developer-common-button" style="margin-top:50px;" @click.prevent="vmodal=false">{{$t('CompanyInfor.yes')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            oldPassword: '',
            changePassword: '',
            showPassword: false,
            showRules1: true,
            showRules2: true,
            showRules3: true,

            vmodal: false, // 二次确认弹窗
            modalMode: true, // 弹窗模式，true为二次确认弹窗，false为无按钮提示窗
            errorText: '', // 无按钮提示窗的文本
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            // 原正则表达式：/^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/
            const re1 = /^.{8,16}$/;
            const re21 = /^(?=.*[A-Za-z])(?=.*\d).{2,}$/; // 包含字母和数字
            const re22 = /^(?=.*[A-Za-z])(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含字母和符号
            const re23 = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字和符号
            const re24 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字、字母和符号
            const re3 = /\s/; // 包含空格
            this.showRules1 = !re1.test(this.changePassword);
            if (re21.test(this.changePassword) || re22.test(this.changePassword) || re23.test(this.changePassword) || re24.test(this.changePassword)) {
                this.showRules2 = false;
            } else {
                this.showRules2 = true;
            }
            this.showRules3 = re3.test(this.changePassword);
        },
        openModal() {
            if (this.changePassword === '' || this.oldPassword === '') {
                this.errorText = this.$t('AccountPassword.alertNoPassword');
                this.modalMode = false;
            } else if (this.showRules1 || this.showRules2 || this.showRules3) {
                this.errorText = this.$t('AccountPassword.alertErrorPassword');
                this.modalMode = false;
            } else {
                this.modalMode = true;
            }
            this.vmodal = true;
        },
        add() {
            const data = {
                oldpassword: this.oldPassword,
                newpassword: this.changePassword
            }
            this.$http.put('/v1/business-center/update-businesspassword-byid', data)
                .then(response => {
                    // 处理返回值
                    this.$toast.success(this.$t('forgetPassword.alertResetSuccessfully'));
                    this.exit
                }).catch(error => {
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                });
        },
        exit() { //退出登录 /index/exit
            this.$http
                .get("/v1/index/exit")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        localStorage.removeItem("vuex");
                        this.$router.push("/pc/login");
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
}
</script>

<style>
.developer-account-input-parent {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.developer-account-input {
    height: 34px;
    margin-left: 10px;
    vertical-align: top;
}
.developer-account-button {
    width: 96px;
    height: 36px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #165DFF;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
}
.developer-account-input-tips {
    font-size: 22px;
    color: red;
    white-space: normal;
}
.infor-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
</style>