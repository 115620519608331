<template>
    <!-- 页面布局容器-总区域 -->
    <div class="main-container">
        <!-- 页面头部区域 -->
        <div class="header-container">
            <img src="../icons/logo.png" class="header_img"/>
            <!-- <div class="header_dropdown">
                <p class="header_dropdown_welcome">{{$t('userhome.welcome')}}</p>
                <el-dropdown class="header_dropdown_menu" @command="handleCommand">
                    <span class="el-dropdown-link">{{ users.businessname?users.businessname:'开心网友' }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="changeToEn" v-if="$i18n.locale === 'zh'">切换英文</el-dropdown-item>
                        <el-dropdown-item command="changeToZh" v-else>Switch Chinese</el-dropdown-item>
                        <el-dropdown-item command="setting">设置</el-dropdown-item>
                        <el-dropdown-item command="unload">切换账号/退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div> -->
            <div class="flex-grow flex flex-row items-center justify-end">
              <el-tooltip class="item" effect="dark" :content="$t('userhome.changeLanguage')" placement="bottom">
                <img src="../developer/developer-header-language.png" class="header-icon" style="margin-right:16px;" @click="changeLanguage">
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="$t('userhome.customerService')" placement="bottom">
                <img src="../developer/home-service.png" class="header-icon" style="margin-right:32px;" @click="serviceModal=true">
              </el-tooltip>
            </div>
        </div>
        <!-- 页面主体区域-包括侧边栏和主页 -->
        <div class="body-container" ref="mainContainer">
            <!-- 页面侧边框 -->
            <el-aside :width="'300px'">
                <el-row class="tac">
                    <el-col :span="12">
                        <div class="users_profix_parent">
                          <div class="users_profix">
                            <el-tooltip class="item" effect="dark" :content="$t('userhome.changeAvatar')" placement="top">
                              <div @mouseover="changeCursor" class="circle-image">
                                <img :src="avatarUrl" @click="setIndex('infor')">
                              </div>
                            </el-tooltip>
                            <span class="developer-home-name">{{ users.businessname?users.businessname:'开心网友' }}</span>
                          </div>
                        </div>


            <!-- 侧边栏菜单 -->
            <div class="left_aside_menu">

              <!-- 一级菜单-我的主页 -->
              <div class="my-menu-item" :style="index==='home'?'background-color:rgb(22, 93, 255);color:#fff;':''" @click="setIndex('home')">
                <img src="../developer/developer-home-white.png" v-if="index==='home'">
                <img src="../developer/developer-home-gray.png" v-else>
                <span style="font-size:large;" slot="title">{{$t('userhome.myRealEstate')}}</span>
              </div>
              
              <!-- 一级菜单-土地市场 -->
              <!-- <el-menu-item index="/pc/land-market">
                <i class="el-icon-menu"></i>
                <span slot="title">{{$t('userhome.landMarket')}}</span>
              </el-menu-item> -->
              
              <!-- 一级菜单-挂牌土地 -->
              <div class="my-menu-item" :style="index==='sale'?'background-color:rgb(22, 93, 255);color:#fff;':''" @click="setIndex('sale')">
                <img src="../developer/developer-sale-white.png" v-if="index==='sale'">
                <img src="../developer/developer-sale-gray.png" v-else>
                <span style="font-size:large;" slot="title">{{$t('userhome.landOnSale')}}</span>
              </div>

              <!-- 一级菜单-我的公司 -->
              <div class="my-menu-item" :style="index==='company'?'background-color:rgb(22, 93, 255);color:#fff;':''" @click="setIndex('company')">
                <img src="../developer/developer-company-white.png" v-if="index==='company'">
                <img src="../developer/developer-company-gray.png" v-else>
                <span style="font-size:large;" slot="title">{{$t('userhome.myCompany')}}</span>
              </div>

              <!-- 一级菜单-聊天消息 -->
              <div class="my-menu-item" :style="index==='message'?'background-color:rgb(22, 93, 255);color:#fff;':''" @click="setIndex('message')">
                <img src="../developer/developer-message-white.png" v-if="index==='message'">
                <img src="../developer/developer-message-gray.png" v-else>
                <span style="font-size:large;" slot="title">{{$t('userhome.message')}}</span>
                <div class="flex-grow flex justify-end items-center">
                  <div class="bg-red-600 rounded-full h-6 w-6 items-center justify-center text-white inline-flex text-sm" v-if="messages !== '0' && messages" v-show="index!=='message'">{{ messages }}</div>
                </div>
              </div>
              
            </div>


            <div class="left-aside-system-parent">
              <div class="left-aside-system">
                <!-- 一级菜单-系统消息 -->
                <div class="my-menu-item" style="margin-top:30px;border: 1px solid #165DFF;" @click="showSystem">
                  <img src="../developer/home-system.png">
                  <span style="font-size:large;color: #165DFF;" slot="title">{{$t('userhome.system')}}</span>
                  <div class="flex-grow flex justify-end items-center">
                    <div class="bg-red-600 rounded-full h-6 w-6 items-center justify-center text-white inline-flex text-sm" v-if="systemUnread !== 0">{{ systemUnread }}</div>
                  </div>
                </div>
              </div>
            </div>


          </el-col>
        </el-row>
      </el-aside>
      <el-main><!-- 页面主体 -->
        <router-view></router-view><!-- 路由占位符 -->
      </el-main>
    </div>


    <!-- 系统消息弹窗 -->
        <div class="developer-common-modal" v-if="systemModal">
          <div class="developer-common-modal-box" style="width:400px;padding:30px;">
            <div class="flex flex-row w-full">
              <img src="../developer/home-system-icon.png" style="height:48px;width:48px;">
              <div class="flex-grow flex justify-end">
                <img src="../developer/home-system-close.png" style="height:24px;width:24px;" @mouseover="changeCursor" @click="systemModal=false">
              </div>
            </div>
            <el-skeleton :loading="system===null" :rows="5" animated/>
            <div class="flex flex-col w-full" style="margin-top: 24px;" v-for="(item,index) in system" :key="index">
              <div class="system-title-parent">
                <div class="system-title">{{ item.title }}</div>
                <div class="system-time">{{ item.createdAt }}</div>
              </div>
              <div class="system-text">{{ item.content }}</div>
            </div>
            <div class="flex flex-row" style="margin-top:32px;">
              <button class="system-button1" @click="serviceModal=true">{{$t('userhome.customerService')}}</button>
              <button class="system-button2" @click="systemModal=false">{{$t('forgetPassword.yes')}}</button>
            </div>
          </div>
        </div>

        <!-- 联系客服弹窗 -->
        <div class="developer-common-modal" v-if="serviceModal">
          <div class="developer-common-modal-box" style="width:400px;padding:30px;" v-if="textSend && imgSend">
            <span>{{$t('userhome.send')}}</span>
          </div>
          <div class="developer-common-modal-box" style="width:400px;padding:30px;" v-else>
            <div class="flex flex-row w-full">
              <img src="../developer/home-service-modal.png" style="height:28px;width:28px;">
              <span class="service-title">{{$t('userhome.service')}}</span>
              <div class="flex-grow flex justify-end">
                <img src="../developer/home-system-close.png" style="height:24px;width:24px;" @mouseover="changeCursor" @click="closeServiceModal">
              </div>
            </div>
            <div class="flex flex-col w-full relative" style="margin-top: 24px;">
              <textarea ref="inputBox"
                    class="flex-1 h-12 px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:shadow-outline"
                    :placeholder="$t('userhome.enter')" rows="5" v-model="text" @keyup.enter="sendMessage"></textarea>
              <input type="file" ref="image" @change="serviceHandleFileInputChange" hidden>
              <el-tooltip class="item" effect="dark" :content="$t('ChatCustomer.uploadimg')" placement="top-start">
                <img src="../developer/chat-sendImage.png" @click="serviceHandleClick" style="height:20px;width:20px;" class="service-imgUpload-icon">
              </el-tooltip>
            </div>
            <img :src="imageUrl" v-if="image" style="margin-top: 24px;max-height:100px;max-width:100px;">
            <div class="flex flex-row" style="margin-top:32px;">
              <button class="system-button1" @click="closeServiceModal">{{$t('forgetPassword.no')}}</button>
              <button class="system-button2" @click="serviceModal=false">{{$t('forgetPassword.yes')}}</button>
            </div>
          </div>
        </div>


  </div>
</template>

<script>
import {mapState,mapMutations} from "vuex";
export default {
  computed: {
    ...mapState({
      users: (state) => state.developer.userInfo,
      avatarUrl: (state) => state.developer.avatarUrl,
    }),
  },
  created() {
    if (localStorage.getItem('developer-index')) { this.index = localStorage.getItem('developer-index') }
  },
  mounted() {
    this.$http.get("/v1/mail/unread")
      .then((response) => {
        this.messages = response.data.data;
    }).catch((error) => {});
    this.$http.get('/v1/message/select-unread-messages') // 获取公告
      .then(res => {
        this.systemUnread = res.data.data;
      }).catch(error => {});
    /* this.timer = setInterval(() => { // 更新待查看消息
      this.$http.get("/v1/mail/unread")
        .then((response) => {
          this.messages = response.data.data;
      }).catch((error) => {});
    }, 60000); // 每分钟进行一次 */
    this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
  },
  beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.updateModalScale);
    },
  data () {
    return {
      index: 'home', // 菜单栏选中的模块，需要计入缓存，并在销毁页面时销毁，缓存名称：developer-index
      messages: '0', // 待查看的消息数量，需要用定时器每分钟更新一次
      timer: null, // 定时器
      system: null, // 系统公告消息
      systemUnread: 0, // 未读的系统消息
      systemModal: false, // 显示系统消息
      serviceModal: false, // 联系客服弹窗
      
      text: '', // 文本，发送的消息
      image: null, // 图片，发送的消息
      imageUrl: null, // 图片url
      textSend: false, // 双重验证，均为true说明发送完成
      imgSend: false, // 双重验证
    }
  },
  methods: {
    // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal = this.$refs.mainContainer;
            if (window.innerWidth >= 1920 && window.innerHeight >= 1140) {
                modal.style.transform = `scale(1)`;
            } else {
                const scaleX = window.innerWidth / 1920;
                const scaleY = (window.innerHeight - 60) / 1080;
                let scale = 0;
                if (scaleX < scaleY) {
                  scale = scaleX;
                  this.$refs.mainContainer.style.height = 1080 / scale + 'px';
                  this.$refs.mainContainer.style.width = '1920px';
                } else {
                  scale = scaleY;
                  this.$refs.mainContainer.style.width = window.innerWidth / scale + 'px';
                  this.$refs.mainContainer.style.height = '1080px';
                }
                modal.style.transform = `scale(${scale})`;
            }
        },
    /* 此处为点击用户头像上传图片的点击事件 */
    handleFileSelect(event) {
      // 获取用户选择的文件对象
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("userphoto", file);
      this.$http
        .put("/v1/user/update-userphoto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          const data = response.data;
                    if (data.status === 200) {
                        this.$store.dispatch("developer/loadAvatar"); // 更新开发商头像
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
      // 将文件对象转换为 URL
      const url = URL.createObjectURL(file);
      // 更新当前头像 URL
      this.users.avatarUrl = url;
      // 清空 input 内容
      event.target.value = '';
    },
    changeCursor(event) {
      // 鼠标移上去时改变样式
      event.target.style.cursor = "pointer";
    },
    handleClick() {
      // 点击时执行相应逻辑
      // 触发文件选择器
      this.$refs.fileInput.click();
    },
    /* 此处为页面右上角下拉菜单的点击事件 */
    handleCommand(command) {
      if (command === 'changeToZh') {
        this.$i18n.locale = 'zh';
        localStorage.setItem('language', 'zh'); // 存储语言选择到localStorage
      } else if (command === 'changeToEn') {
        this.$i18n.locale = 'en';
        localStorage.setItem('language', 'en'); // 存储语言选择到localStorage
      }
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },

    /* 切换语言 */
    changeLanguage() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'zh';
        localStorage.setItem('language', 'zh');
      } else if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en';
        localStorage.setItem('language', 'en');
      }
    },
    /* 左侧菜单选中的目录 */
    setIndex(t) {
      // if (this.index === t) { return; } // todo 当前页面与菜单栏一致时，不跳转页面，还是跳吧
      localStorage.setItem('developer-index',t);
      this.index = t;
      if (t === 'home') {
        this.$router.push('/developer/home-page').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      } else if (t === 'sale') {
        this.$router.push('/developer/my-sale').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      } else if (t === 'company') {
        this.$router.push('/developer/my-company').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      } else if (t === 'message') {
        this.$router.push('/developer/message').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      } else if (t === 'safe') {
        this.$router.push('/developer/account-safe').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      } else if (t === 'service') {
        this.$router.push('/developer/message/service').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      } else if (t === 'infor') {
        this.$router.push('/developer/account-safe').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },

    showSystem() {
      this.systemModal = true;
      this.$http.get('/v1/message/select-system-messages') // 获取公告
      .then(res => {
        this.system = res.data.data;
        this.systemUnread = 0;
      }).catch(error => {});
    },


    /* 关闭联系客服弹窗 */
    closeServiceModal() {
      this.text = '';
      this.image = null;
      this.imageUrl = null;
      this.serviceModal = false;
    },
    /* 上传图片功能样式 */
        serviceHandleClick() {
            // 触发文件选择器
            this.$refs.image.click();
        },
        serviceHandleFileInputChange() { // 获取用户选择的文件
            const size = 5 * 1024 * 1024; // 5MB
            const file = this.$refs.image.files[0];
            if (file.size < size) {
                this.image = this.$refs.image.files[0];
                // 将文件对象转换为 URL
                this.imageUrl = URL.createObjectURL(this.image);
            } else {
                this.$toast.fail(this.$t('ChatCustomer.alertImageSize'))
            }
        },
    async sendMessage() {
      const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('userhome.alertSending'),
                forbidClick: true,
            });
                if (this.image === null && this.text !== '') { // 只发送文本
                    this.$http.post('/v1/csmail/cswrite-message', {
                        emailscontent: this.text,
                        serviceid: ''
                    }).then(res => {
                        this.text = '';
                        this.textSend = true;
                        this.imgSend = true;
                        toastLoading.clear();
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                        toastLoading.clear();
                    });
                } else if (this.text === '' && this.image) { // 只发送图片
                    const formData = new FormData();
                    formData.append('picture', this.image);
                    this.$http.post("/v1/csmail/cswrite-picture", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((res) => {
                            this.image = null;
                            this.imageUrl = null;
                            this.textSend = true;
                            this.imgSend = true;
                            toastLoading.clear();
                        })
                        .catch((error) => {
                            this.$toast.fail('error')
                            toastLoading.clear();
                        });
                } else if (this.text !== '' && this.image) { // 发送图片和文本
                    const formData = new FormData();
                    formData.append('picture', this.image);
                    this.$http.post("/v1/csmail/cswrite-picture", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((res) => {
                            this.image = null;
                            this.imageUrl = null;
                            this.imgSend = true;
                            toastLoading.clear();
                        })
                        .catch((error) => {
                            this.$toast.fail('error')
                            toastLoading.clear();
                        });
                    this.$http.post('/v1/csmail/cswrite-message', {
                        emailscontent: this.text,
                        serviceid: ''
                    }).then(res => {
                        this.text = '';
                        this.textSend = true;
                        toastLoading.clear();
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                        toastLoading.clear();
                    });
                }
        },
  },
  /* 生命周期结束时清除计时器 */
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
}
</script>

<style scoped>
/* ---------------------------主页布局容器---------------------------- */
.main-container {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  position: relative;
}
.body-container {
  position: absolute;
  top: 60px;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 60px);
  transform-origin: top left;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1980px) or (max-height: 1140px) {
    .body-container {
        max-height: none;
    }
}



/* ---------------------------顶栏布局容器---------------------------- */
/* 布局容器顶栏容器样式 */
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;/* 左右贴边对齐 */
  padding: 0;
  align-items: center;
  color: #000;
  font-size: 20px;
  box-shadow: inset 0px -2px 2px 0px rgba(22, 93, 255, 0.05);
  height: 60px;
  width: 100%;
}
/* 头部区域logo图片样式 */
.header_img {
  margin-left: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
}
/* 头部右侧小图标 */
.header-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
}
/* 头部右边下拉菜单样式（包括欢迎你和带下拉框的文本） */
.header_dropdown {
  width: 100%;
  text-align: right;
  white-space: nowrap;
}
/* 头部右边欢迎您文本 */
.header_dropdown_welcome {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
/* 头部右边下拉菜单 */
.header_dropdown_menu {
  display: inline-block;
  vertical-align: top;
}
/* 下拉菜单el组件需要的style */
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}


/* ---------------------------左侧边栏布局容器---------------------------- */
/* 用户名样式 */
.developer-home-name {
  font-size:20px;
  margin-left:10px;
  color:#fff;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 布局容器侧边栏容器样式（主要是背景色） */
.el-aside {
  background-color: #165DFF;
  background-image: url('../developer/developer-aside-back.png');
}
/* 左侧折叠/展开文本设置 */
.toggle_button_container {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
/* 左侧菜单位置设置 */
.left_aside_menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aside-mainmenu-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color:#fff;
  margin-left: 10px;
  font-size: 20px;
}
/* 用户头像的父块布局，里面包含了头像图片框和文字 */
.users_profix_parent {
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
.users_profix {
  height: 90px;
  width: 100%;
  background-image: url('../developer/developer-aside-user.png');
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* 用户头像图片设为圆形显示 */
.circle-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 30px;
  border: 1px solid #FFFFFF;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.circle-image > img {
  display: block;
  width: 100%;
  height: 100%;
  /* 它会按比例缩放图片并填满整个圆形区域 */
  object-fit: cover; /* 调整图片表现方式 */
}
/* 左侧每个菜单的样式 */
.my-menu-item {
  width: 240px;
  height: 50px;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 20px;
  cursor: pointer;
}
.my-menu-item > img {
  height: 22px;
  width: 22px;
  margin-right: 20px;
}

/* 左下角系统消息的样式 */
.left-aside-system-parent {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding: 30px;
}
.left-aside-system {
  border-top: 2px solid rgba(216, 216, 216, 0.6);
  margin-bottom: 120px;
  width: 100%;
}



/* ---------------------------其他部分（我都忘了这些各自控制啥了）---------------------------- */
.tac {
  height: 100%;
  width: 100%;
}
/* 主页容器样式-主要是将它的padding属性设为0 */
.el-main {
  background-color: #fff;
  padding: 0;
}
/* 侧边栏宽度(与侧边框保持一致) */
.el-col-12 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* 去除白线(侧边栏与侧边框右侧连接处出现白线，染色去除) */
.el-menu {
  border-right: #333744;
}
/* 折叠展开组件设置 */
.toggle-button {
  background-color: #333744;
  color:#fff;
  font-weight:200;
  font-size: 20px;
  /* 鼠标放上去会变成小手 */
  cursor: pointer;
}


/* ---------------------------系统消息---------------------------- */
.system-title-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.system-title {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
}
.system-time {
  flex-grow: 1;
  display: flex;
  justify-content: end;
  font-size: 14px;
  color: #727272;
}
.system-text {
  margin-top: 5px;
  font-size: 14px;
  color: #475467;
  line-height: 20px;
}
.system-button1 {
  width: 170px;
  height: 44px;
  border-radius: 8px;
  background-color: #F2F3F5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #344054;
}
.system-button2 {
  margin-left: 12px;
  width: 170px;
  height: 44px;
  border-radius: 8px;
  background-color: #165DFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
}

/* ---------------------------联系客服---------------------------- */
.service-title {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
  margin-left: 10px;
}
.service-imgUpload-icon {
  position: absolute;
  bottom: 14px;
  right: 14px;
  cursor: pointer;
}
</style>
