<template>
    <div class="home-container">
        <header class="header-container">
            <div class="header-image-div">
                <img src="../icons/logo.png" class="header-image-img">
            </div>
            <div class="header-language-div">
                <div class="header-language-text-box" @click="changeLanguage">
                    <span :style="$i18n.locale === 'en'? 'color: blue' : ''">EN</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span :style="$i18n.locale === 'zh'? 'color: blue' : ''">中文</span>
                </div>
                <button class="header-login-button" @click="goLogin">{{$t('MobileHomePage.button')}}</button>
            </div>
        </header>
        <main class="main-container">
            <section class="slider">
                <div class="Image-impression-box">
                    <span class="Image-impression-text font-family-normal animate__animated animate__fadeInUp">{{$t('MobileHomePage.introduce1')}}</span>
                    <span class="Image-impression-text font-family-normal animate__animated animate__fadeInUp">{{$t('MobileHomePage.introduce2')}}</span>
                </div>
            </section>
            <div class="Image-propagate-box" v-for="(item,index) in images" :key="index" :class="index%2===0?'Image-propagate-box-blue':''">
                <div class="Image-propagate-text-box animate__animated" :class="index%2===0?'animate__fadeInLeft':'animate__fadeInRight'" v-if="$i18n.locale === 'zh'"><span class="Image-propagate-text-blue">{{$t('MobileHomePage.view' + index + '1')}}</span>{{$t('MobileHomePage.view' + index + '2')}}</div>
                <div class="Image-propagate-text-box animate__animated" :class="index%2===0?'animate__fadeInLeft':'animate__fadeInRight'" v-if="$i18n.locale === 'en'">{{$t('MobileHomePage.view' + index)}}</div>
                <img :src="item" class="Image-propagate-img">
            </div>
            <div class="Image-login-box">
                <div class="Image-login-text">{{$t('MobileHomePage.usenow')}}</div>
                <button class="Image-login-button" @click="goLogin">{{$t('MobileHomePage.button')}}</button>
            </div>
            <div class="footer-container" v-show="footer">
                <a href="mailto:info@datuland.com?subject=Hello" class="footer-link font-family-normal">{{$t('MobileHomePage.contact')}}</a>
                <div class="font-family-normal footer-copyright">{{$t('MobileHomePage.copyright')}}</div>
            </div>
        </main>
    </div>
</template>

<script>
import { Locale } from "vant"; // 切换vant语言
import lang from 'vant/es/locale/lang/zh-CN'; // 默认语言为中文
import enUS from 'vant/es/locale/lang/en-US'; // 英文语言包
export default {
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            images: [ // 首页轮播图
                require('@/images/propagandizeImage1.jpg'),
                require('@/images/propagandizeImage2.jpg'),
                require('@/images/propagandizeImage3.jpg'),
                require('@/images/propagandizeImage4.jpg'),
            ],
            footer: false, // 控制底部显示
        }
    },
    methods: {
        changeLanguage() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'zh';
                Locale.use('zh', lang);
                localStorage.setItem('language', 'zh'); // 存储语言选择到localStorage
            } else {
                this.$i18n.locale = 'en';
                Locale.use('en', enUS);
                localStorage.setItem('language', 'en'); // 存储语言选择到localStorage
            }
            document.title = this.$t('pageTitle')
        },
        goLogin() {
            this.$router.push('/mobile/login')
        },
        handleScroll() {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.offsetHeight;
      
            if (window.pageYOffset + windowHeight >= documentHeight) {
                // 页面滚动到底部时显示底部div层
                this.footer = true;
            } else {
                // 页面未滚动到底部时隐藏底部div层
                this.footer = false;
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style scoped>
.home-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
/* --------------------------------------------头部------------------------------------------------- */
.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    z-index: 1;
}
.header-image-div {
    padding: 0px;
}
.header-image-img {
    object-fit: cover;
    height: 40px;
    margin-top: 10px;
    height: 40px;
    margin-left: 5px;
}
.header-language-div {
    margin-left: 20px;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-language-text-box {
    font-size: 14px;
}
.header-login-button {
    height: 33px;
    width: 80px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    border-radius: 3px;
    text-align: center;
    text-overflow: ellipsis; /* 对文本进行截断和省略 */
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden;
    background-color: #248aff;
    color: #fff;
    font-size: 13px;
}
/* --------------------------------------------主页------------------------------------------------- */
.main-container {
    margin-top: 60px;
    flex: 1;
    display: flex;
    background-image: linear-gradient(to bottom, #0083e0 5%, #ffffff);
    background-repeat: no-repeat;
    background-color: #fff;
    overflow-y: auto;
    flex-direction: column;
}
/* 印象图 */
.Image-impression-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background: #1d192c;
    opacity: 0.8;
}
.Image-impression-text {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 2;
}
.slider {
  background: url("../images/slider-bg.jpg") no-repeat;
  background-size: auto 400px;
  background-attachment: fixed;
  background-position: 50% 60px;
  position: relative;
  height: 400px;
  width: 100%;
}


/* 宣传图 */
.Image-propagate-box {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
/* 白色灰色交替展示 */
.Image-propagate-box-blue {
    background-color: #f5f8fc;
}
.Image-propagate-img {
    max-width: 100%; /* 控制图片大小不能超出屏幕宽度 */
    padding: 20px;
}
.Image-propagate-text-box {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-weight: bold;
    word-wrap: break-word;
    text-align: center;
}
.Image-propagate-text-blue {
    color: #248aff;
}


/* 立即使用 */
.Image-login-box {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Image-login-text {
    font-size: 30px;
    margin-bottom: 25px;
}
.Image-login-button {
    background-color: #248aff;
    font-size: 20px;
    height: 50px;
    width: 200px;
    text-align: center;
    border-radius: 6px;
    color: #fff;
}


/* --------------------------------------------底部------------------------------------------------- */
.footer-container {
    height: 100px;
    width: 100%;
    background-color: #515666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* 联系我们-样式 */
.footer-link {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
}
/* 版权所有-样式 */
.footer-copyright {
    font-size: 14px;
    color: #a4a7ad; /* 原919191 */
}
.font-family-normal {
    font-family: "Roboto", "Droid Serif", Georgia, "Times New Roman", "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "WenQuanYi Micro Hei","Microsoft Yahei", serif
}
</style>