<template>
    <div class="window">
        <div class="left-window"></div>
        <div class="middle-window">
            <div class="middle-window-background" @mousemove="handleMouseMove">
                <background ref="background" :style="canvasStyle()" :building="buildings" :canvasheight="canvasheight" :canvaswidth="canvaswidth" :gridSize="gridSize"></background>
                <movetest ref="character" :style="canvasStyle()" :canvasheight="canvasheight" :canvaswidth="canvaswidth" :gridSize="gridSize"></movetest>
                <loadbar :style="canvasStyle()" style="z-index:100;" :step="step" :stepcount="stepcount" v-if="loading"></loadbar>
            </div>
        </div>
        <div class="right-window"></div>
    </div>
</template>

<script>
import loadbar from './loadbar.vue'
import background from './background.vue'
import movetest from './characterMoveTest.vue'
export default {
    components: {
        loadbar, // 加载进度条页面
        background, // 背景层
        movetest, // 人物行动测试页面
    },
    mounted() {
        for(let i = 0;i < this.canvasheight / this.gridSize;i++) {
            let array = [];
            for(let j = 0;j < this.canvaswidth / this.gridSize;j++) {
                array.push({
                    col: i, // 行
                    row: j, // 列
                    imgsrc: './v2Image/grass.jpg', // 图片src
                    through: true, // 是否可通行
                })
            }
        }
        this.$refs.background.drawBackgroundGrids();
        this.buildings = [ // 背景层上的建筑
                { x: 100, y: 100, width: 100, height: 100, text: 'Building 1', src: './v2Image/building1.jpg' },
                { x: 300, y: 200, width: 120, height: 80, text: 'Building 2', src: './v2Image/building2.jpg' },
                // 可以添加更多建筑图片信息
            ]
        this.timer = setInterval(() => {
            this.step += 1;
            if (this.step >= 10) {
                clearInterval(this.timer);
                setTimeout(() => {
                    this.loading = false; // 关闭加载进度条页面
                    window.addEventListener('keydown',this.handleKeyDown)
                }, 2000);
            }
        }, Math.random() * 1000);
    },
    data() {
        return {
            timer: null, // 定时器
            canvasheight: 800,
            canvaswidth: 1200,
            gridSize: 40, // 网格尺寸
            loading: true, // 是否处于加载页面
            step: 0, // 加载节点
            stepcount: 10, // 加载节点总数
            buildings: [],
            grids: [], // 网格属性
        };
    },
    methods: {
        canvasStyle() {
            return 'height:' + this.canvasheight + 'px;width:' + this.canvaswidth + 'px'
        },
        /* 控制勇士的行动 */
        handleKeyDown(event) {
            switch(event.key) {
                case 'ArrowUp':
                    // 处理上键逻辑
                    this.$refs.character.moveUp();
                    break;
                case 'ArrowDown':
                    // 处理下键逻辑
                    this.$refs.character.moveDown();
                    break;
                case 'ArrowLeft':
                    // 处理左键逻辑
                    this.$refs.character.moveLeft();
                    break;
                case 'ArrowRight':
                    // 处理右键逻辑
                    this.$refs.character.moveRight();
                    break;
            }
        },
        /* 鼠标悬浮事件触发背景层事件 */
        handleMouseMove(event) {
            const bottomElement = this.$refs.background;
            // bottomElement.handleMouseMove(event);
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown',this.handleKeyDown);
    },
}
</script>

<style scoped>
.window {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.left-window {
    width: 300px;
    height: 100%;
}
.middle-window {
    flex-grow: 1;
    height: 100%;
    min-width: 1000px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.middle-window-background {
    position: relative;
}
.canvas-window {
    height: 500px;
    width: 800px;
    position: relative;
}
.right-window {
    width: 300px;
    height: 100%;
}
</style>