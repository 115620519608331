<template>
  <div class="topup-box" ref="mainContainer">
    <div class="topup-balance">{{$t('all.currentBalance')}}</div>
    <div class="topup-balance2">{{ balance[0] }}.<span style="font-size:20px;">{{ balance[1] }}</span></div>
    <div class="topup-card">
      <div class="flex flex-col">
        <span class="topup-card-title">{{$t('all.rechargeWallet')}}</span>
        <input type="number" class="topup-card-input" :placeholder="$t('all.enterRecharge')" v-model="rechargeAmount">
        <span class="topup-card-subhead">{{$t('all.rechargeForm')}}</span>
        <!-- 支付宝支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'alipay' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:14px"
          v-if="$setPayment === 'zh'"
          @click="payWay='alipay'"
        >
          <img src="../pImage/alipay.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.aliPay')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.rmb')}}</div>
        </div>
        <!-- 微信支付 -->
        <div
          class="topup-card-rechargemode cannot-select-this-payment"
          :class="payWay === 'wechat' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:16px"
          v-if="$setPayment === 'zh'"
        >
          <img src="../pImage/paypal.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.wechat')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.rmb')}}</div>
        </div>
        <!-- paypal支付 -->
        <div
          class="topup-card-rechargemode"
          :class="payWay === 'paypal' ? 'topup-card-rechargemode-select' : ''"
          style="margin-top:16px"
          v-if="$setPayment === 'am'"
          @click="payWay='paypal'"
        >
          <img src="../pImage/paypal.png" class="topup-card-rechargemode-icon">
          <span class="topup-card-rechargemode-paymean">{{$t('MobileConfirmOrder.paypal')}}</span>
          <div class="topup-card-rechargemode-country">{{$t('PcConfirmOrder.dollar')}}</div>
        </div>
      </div>

      <div class="flex flex-col" style="margin-left:40px;">
        <span class="topup-card-title">{{$t('all.scaling')}}</span>
        <div class="topup-card-calculator">
          <div class="calculator-row">
            <span class="calculator-text1">{{ rechargeAmount }}<span class="ml-2 text-sm">{{$t('PcConfirmOrder.balance')}}</span></span>
            <img src="../pImage/calculator.png" class="calculator-icon">
            <span class="calculator-text2">
              <span v-if="$setPayment === 'am'">$</span>
              <span v-if="$setPayment === 'zh'">￥</span>
              {{ getPrice(rechargeAmount)[0] }}.<span style="font-size:18px;">{{ getPrice(rechargeAmount)[1] }}</span></span>
          </div>
          <button class="calculator-button" @click="recharge">{{$t('all.payNow')}}</button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$http.get("/v1/recharge/check-balance")
      .then((res) => {
        const value = Number(res.data.data.balance).toFixed(2).toString();
        this.balance = value.split(".");
      })
      .catch((err) => {});
    },
  mounted() {
    this.controlWindow();
    window.addEventListener('resize', this.controlWindow);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('resize', this.controlWindow);
    next();
  },
  data() {
    return {
      balance: [
        '0',
        '00'
      ], // 虚拟币余额
      rechargeAmount: '', // 充值的虚拟币数量
      payWay: "paypal", // 支付方式
    }
  },
  methods: {
    // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 980 || window.innerHeight <= 980) {
                const scaleX = (window.innerWidth - 80) / 900;
                const scaleY = (window.innerHeight - 220) / 760;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
            }
        },
    /* 获取支付金额 */
    getPrice(n) {
      if (isNaN(n)) {
        return;
      }
      if (this.$i18n.locale === 'zh') {
        return (Number(n) * this.$exchangeRate).toFixed(2).toString().split(".");
      } else {
        return Number(n).toFixed(2).toString().split(".");
      }
    },
    /* 支付 */
    recharge() {
      const data = {
        "balance": parseInt(this.rechargeAmount),
      };
      if(data.balance <= 0){
        this.$toast(this.$t('MobileRecharge.alertNoRecharge'));
        return;
      }
      const toastLoading= this.$toast.loading({
        duration: 0, // 持续展示 toast
        message: this.$t('MobileRecharge.alertGoPay'),
        forbidClick: true,
      });
      if (this.payWay === "aliPay") {
        this.$http.post("/v1/recharge/alipay-add", data)
          .then((res) => {
            setTimeout(() => {
              this.submitLoading = false;
              document.querySelector('form[name="punchout_form"]').submit();
              toastLoading.clear();
              window.location.href = res.data.html;
            }, 500);
          });
      } else if (this.payWay === "wechat") {
        // this.$toast("微信支付");
      } else if (this.payWay==='paypal'){ // paypal充值
        this.$toast(this.$t('MobileConfirmOrder.alertPaypal'));
        const toastLoading= this.$toast.loading({
          duration: 0, // 持续展示 toast
          message: this.$t('MobileRecharge.alertGoPay'),
          forbidClick: true,
        });
        this.$http.post("/v1/recharge/paypal-add", data).then((res) => {
          toastLoading.clear();
          //访问paylink
          window.location.href = res.data.html;
          // 保存订单号后提示用户支付成功并做后续操作
          this.orderid = res.data.orderid;
          // window.open(res.data.html,'_blank'); // 打开一个新的PayPal标签页
          // fetchDataWithTimeout("/v1/paypal/callback/success?orderid=" + this.orderid);
        }).catch((error) => {
          toastLoading.clear();
          this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
        });
      }
    },
  },
}
</script>

<style>
.topup-box {
  width: 900px;
  height: 759px;
  min-height: 600px;
  border-radius: 8px;
  background: #FFFFFF;
  position: relative;
}
@media (max-width: 980px) or (max-height: 980px) {
    .topup-box {
      transform-origin: top center;
    }
}
.topup-balance {
  position: absolute;
  top: 30px;
  left: 44px;
  font-weight: 500;
  font-size: 16px;
  color: #9DA1AD;
}
.topup-balance2 {
  position: absolute;
  top: 55px;
  left: 44px;
  font-weight: 700;
  font-size: 30px;
  color: #3D3D3D;
}
/* 充值虚拟币的卡片 */
.topup-card {
  position: absolute;
  top: 123px;
  left: 44px;
  width: 808px;
  height: 370px;
  border-radius: 8px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  padding: 19px 27px;
  display: flex;
  flex-direction: row;
}
.topup-card-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  color: #3D3D3D;
}
/* 充值虚拟币数量填写输入框 */
.topup-card-input {
  margin-top: 13px;
  width: 310px;
  height: 91px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #86909C;
  padding: 9px 20px;
}
.topup-card-subhead {
  margin-top: 29px;
  font-size: 14px;
  line-height: 25px;
  color: #3D3D3D;
}
/* 支付方式选项 */
.topup-card-rechargemode {
  width: 310px;
  height: 50px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.topup-card-rechargemode-select {
  border: 1px solid #3662EC;
}
.topup-card-rechargemode-icon {
  object-fit: contain;
  width: 20px;
}
.topup-card-rechargemode-paymean {
  margin-left: 8px;
  font-size: 16px;
  color: #3D3D3D;
}
.topup-card-rechargemode-country {
  flex-grow: 1;
  text-align: end;
  font-size: 12px;
  color: #3D3D3D;
}
/* 注释了支付宝支付和微信支付 */
.cannot-select-this-payment {
  background-color: #707070;
  cursor: not-allowed;
}
/* 换算器 */
.topup-card-calculator {
  margin-top: 19px;
  width: 400px;
  height: 276px;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.calculator-row {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
}
.calculator-text1 {
  font-size: 24px;
  font-weight: 500;
  color: #3D3D3D;
}
.calculator-icon {
  object-fit: contain;
  width: 30px;
  margin: 0 40px;
}
.calculator-text2 {
  font-size: 24px;
  font-weight: 500;
  color: #FA5151;
}
.calculator-button {
  position: absolute;
  bottom: 23px;
  left: 67px;
  width: 266px;
  height: 37px;
  background: #165DFF;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
}
</style>