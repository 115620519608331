<template>
    <div class="developer-common-safe">
        <div class="developer-common-safe-title">{{$t('MyInfor.letter1')}}</div>
        <div class="developer-infor-box">
            <div class="developer-infor-col">
                <span class="developer-infor-row-label">{{$t('MyInfor.letter3')}}:</span>
                <span class="developer-infor-row-font">{{ users.businessname?users.businessname:$t('MyInfor.letter4') }}</span>
                <!-- <el-input v-model="name" :placeholder="users.businessname?users.businessname:'开心网友'"></el-input> -->
            </div>
            <div class="developer-infor-col">
                <span class="developer-infor-row-label">{{$t('MyInfor.letter5')}}:</span>
                <span class="developer-infor-row-font">{{ users.businessemail?users.businessemail:$t('MyInfor.letter6') }}</span>
            </div>
            <div class="developer-infor-col">
                <span class="developer-infor-row-label">{{$t('MyInfor.letter8')}}:</span>
                <span class="developer-infor-row-font">{{ users.businessphone?users.businessphone:$t('MyInfor.letter6') }}</span>
            </div>
            <div class="developer-infor-col">
                <span class="developer-infor-row-label">{{$t('MyInfor.letter11')}}:</span>
                <span class="developer-infor-row-font" v-if="mode">{{ users.usersex?setUserSex(users.usersex):$t('MyInfor.letter6') }}</span>
                <el-radio-group style="display:flex;align-items:center" v-model="sex" @input="changeSex" v-else>
                    <el-radio :label="'男'">{{$t('MyInfor.letter12')}}</el-radio>
                    <el-radio :label="'女'">{{$t('MyInfor.letter13')}}</el-radio>
                    <el-radio :label="'隐藏'">{{$t('MyInfor.letter14')}}</el-radio>
                </el-radio-group>
            </div>
            <div class="developer-infor-col">
                <span class="developer-infor-row-label">{{$t('MyInfor.date')}}:</span>
                <span class="developer-infor-row-font" v-if="mode">{{ users.birthdate?users.birthdate:$t('MyInfor.letter6') }}</span>
                <el-date-picker v-model="birthdate" type="date" :placeholder="$t('MyInfor.choosedate')" @change="changeBirth" v-else></el-date-picker>
            </div>
            <el-divider></el-divider>
            <div class="developer-infor-col">
                <button class="developer-infor-button" @click="mode = !mode" v-if="mode">{{$t('MyInfor.letter15')}}</button>
                <button class="developer-infor-button" @click="mode = !mode" v-else>{{$t('MyInfor.letter16')}}</button>
                <button class="developer-infor-button ml-10" @click="exit" v-if="mode">{{$t('MyInfor.letter17')}}</button>
                <button class="developer-infor-button ml-10" @click="changeInfor" v-else>{{$t('MyInfor.letter18')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            users: (state) => state.developer.userInfo,
            avatarUrl: (state) => state.developer.avatarUrl,
        }),
    },
    mounted() {
        this.$store.dispatch("developer/fetchUserInfo");
        if (this.users.birthdate) { this.birthdate = this.users.birthdate; }
    },
    data () {
        return {
            mode: true, // 模式切换，查看个人资料和修改个人资料
            sex: '隐藏', // 性别
            birthdate: '',
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        changeSex() {
            const data = {
                usersex: this.sex
            }
            this.$http.put("/v1/business-center/update-userbusiness", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success(this.$t('MyInfor.letter19'));
                        this.$store.dispatch("developer/fetchUserInfo");
                    } else {
                        this.$toast.fail(data.data)
                    }
                })
                .catch((error) => {
                    this.$toast.fail('error');
                });
        },
        changeBirth() {
            const data = {
                birthdate: this.birthdate
            }
            this.$http.put("/v1/business-center/update-userbusiness", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success(this.$t('MyInfor.letter19'));
                        this.$store.dispatch("developer/fetchUserInfo");
                    } else {
                        this.$toast.fail(data.data)
                    }
                })
                .catch((error) => {
                    this.$toast.fail('error');
                });
        },
        exit() { //退出登录 /index/exit
            this.$http
                .get("/v1/index/exit")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        localStorage.removeItem("vuex");
                        this.$router.push("/pc/login");
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        setUserSex(sex) { // 英文版需要套一层皮显示性别
            if (this.$i18n.locale === 'en') {
                if (sex === '男') { return 'male' } else if (sex === '女') { return 'female' } else if (sex === '隐藏') { return 'hidden' } else { return 'Not Set' }
            } else {
                if (sex !== '') {
                    return sex;
                } else {
                    return '未设置';
                }
            }
        },
    },
}
</script>

<style>
/* 最外层 */
.developer-infor-cover {
    min-height: 100%;
    width: 100%;
    flex-direction: column;
}
.developer-infor-box {
    min-height: 700px;
    min-width: 600px;
    padding: 10px 30px;
    border-radius: 12px;
}
/* 标题 */
.developer-infor-title {
    font-size: 24px;
    font-weight: bold;
}
/* 行块 */
.developer-infor-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
}
.developer-infor-row-label {
    width: 150px;
    font-size: 20px;
}
.developer-infor-row-font {
    font-size: 20px;
    color: #8185a3;
}
.developer-infor-button {
    height: 40px;
    min-width: 80px;
    box-shadow: 0 0 0 1px #000;
    background-color: #eeeeee;
    color: #000;
}
/* 头像 */
.circle-image {
    border-radius: 50%;
    overflow: hidden;
}
.circle-image > img {
    width: 150px;
    height: 150px;
}
</style>