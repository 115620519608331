import { render, staticRenderFns } from "./ChatManage.vue?vue&type=template&id=3c856f27&scoped=true&"
import script from "./ChatManage.vue?vue&type=script&lang=js&"
export * from "./ChatManage.vue?vue&type=script&lang=js&"
import style0 from "./ChatManage.vue?vue&type=style&index=0&id=3c856f27&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c856f27",
  null
  
)

export default component.exports