<template>
    <div class="admin-infor-cover">
        <div class="admin-infor-box">
            <span class="admin-infor-title">个人资料</span>
            <el-divider></el-divider>
            <div class="admin-infor-col">
                <div @mouseover="changeCursor" class="circle-image shadow-md border-gray">
                    <img :src="avatarUrl" alt="头像" @click="handleClick" />
                    <input type="file" ref="fileInput" hidden @change="handleFileSelect" />
                </div>
                <div @mouseover="changeCursor" class="circle-image shadow-md border-gray admin-infor-head-icon">
                    <img src="../adminImage/admin-myinfor-photo.png" @click="handleClick" style="height:60px;width:60px;" />
                </div>
            </div>
            <div class="admin-infor-col">
                <span class="admin-infor-row-label">管理员编号:</span>
                <span class="admin-infor-row-font">{{ users.administratorid }}</span>
            </div>
            <div class="admin-infor-col">
                <span class="admin-infor-row-label">管理员昵称:</span>
                <span class="admin-infor-row-font">{{ users.administratorname }}</span>
            </div>
            <div class="admin-infor-col">
                <span class="admin-infor-row-label">管理员职称:</span>
                <span class="admin-infor-row-font">{{ users.role }}</span>
            </div>
            <div class="admin-infor-col" style="margin-bottom: 50px;">
                <span class="admin-infor-row-label">管理员等级:</span>
                <span class="admin-infor-row-font">{{ users.administratorlevel }}</span>
            </div>
            <span class="admin-infor-title">账号与安全</span>
            <el-divider></el-divider>
            <div class="admin-infor-col">
                <span class="admin-infor-row-label" style="width:200px;">联系方式(手机号码):</span>
                <span class="admin-infor-row-font">{{ users.administratorphone?users.administratorphone:'未设置' }}</span>
                <span class="admin-infor-row-change" @click="orenModal" @mouseover="changeCursor">修改</span>
            </div>
            <div class="admin-infor-col">
                <button class="admin-infor-button" @click="mode = true">修改密码</button>
                <button class="admin-infor-button ml-10" @click="exit">退出登录</button>
            </div>
        </div>
        <!-- 这个是用来修改联系电话的弹窗，由vmodal控制 -->
        <div class="admin-manage-modal" v-if="vmodal">
            <div class="admin-manage-modal-box">
                <span style="padding:10px;font-weight:bold;">修改联系方式（电话号码）</span>
                <div class="p-5">
                    <div class="flex flex-col justify-end">
                        <input type="phone" class="admin-infor-input" v-model="newphone" placeholder="请输入新的手机号码">
                        <span class="admin-infor-input-error" v-show="showError">*只能输入数字</span>
                    </div>
                </div>
                <div style="margin-top: 10px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="changePhoneNumber" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
        <!-- 这个是用来修改密码的弹窗，由mode控制 -->
        <div class="admin-manage-modal" v-if="mode">
            <div class="admin-manage-modal-box">
                <span style="padding:10px;font-weight:bold;">修改密码</span>
                <div class="p-5 flex flex-col">
                    <div class="flex flex-col justify-end">
                        <input type="phone" class="admin-infor-input" v-model="originalPassword" placeholder="请输入原密码">
                    </div>
                    <div class="flex flex-col justify-end mt-5">
                        <input type="phone" class="admin-infor-input" v-model="newPassword" placeholder="请输入新密码">
                        <span class="admin-infor-input-error" v-show="error">*密码为8-16位，必须包含字母、数字、字符其中两种，不能包含空格</span>
                    </div>
                </div>
                <div>
                    <el-button type="info" plain @click="mode=false" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="changePassword" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            users: (state) => state.admin.adminInfo,
            avatarUrl: (state) => state.admin.avatarUrl,
        }),
    },
    watch: {
        newphone(n) {
            const re = /^[0-9]+$/;
            if (!re.test(n)) {this.showError = true} else {this.showError = false;}
        },
        newPassword(n) {
            this.examine();
        },
    },
    data () {
        return {
            mode: false, // 修改密码弹窗
            originalPassword: '', // 原密码
            newPassword: '', // 新密码
            error: false, // 新密码与原密码不同


            vmodal: false, // 控制弹窗显示
            newphone: '', // 新的联系方式
            showError: false, // 是否显示新联系方式输入格式错误
        }
    },
    methods: {
        /* 此处为点击用户头像上传图片的点击事件 */
        handleFileSelect(event) {
            // 获取用户选择的文件对象
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("userphoto", file);
            this.$http
                .put("/v1/user/update-userphoto", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$store.dispatch("admin/loadAvatar"); // 更新管理员头像
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    this.$toast.fail('头像上传失败，请重试或重新登录');
                });
            // 将文件对象转换为 URL
            const url = URL.createObjectURL(file);
            // 更新当前头像 URL
            this.users.avatarUrl = url;
            // 清空 input 内容
            event.target.value = "";
        },
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        handleClick() {
            // 点击时执行相应逻辑
            // 触发文件选择器
            this.$refs.fileInput.click();
        },
        onChangeInfo() {
            // 点击时执行相应逻辑
            // 触发文件选择器
            this.$router.push("/mobile/my-infor/detail");
        },
        orenModal() {
            this.vmodal = true;
        },
        closeModal() {
            this.vmodal = false;
        },
        changePhoneNumber() {
            if (this.showError || this.newphone === '') { return; }
            const data = {
                administratorphone: this.newphone
            }
            this.$http.put("/v1/administrator/update-administratorphone-byid", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success("修改成功！");
                        this.$store.dispatch("admin/fetchUserInfo");
                        this.vmodal = false;
                    } else {
                        this.$toast.fail(data.data)
                    }
                })
                .catch((error) => {
                    this.$toast.fail('关联手机号修改失败，请重试或重新登录');
                });
        },
        examine() {
            const re = /^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/;
            if (re.test(this.newPassword)) {
                this.error = false;
            } else {
                this.error = true;
            }
        },
        changePassword() {
            if(this.originalPassword === '') {
                this.$toast.fail("原密码不可为空")
                return;
            } else if (this.newPassword === '') {
                this.$toast.fail("新密码不可为空")
                return;
            }
            if (this.error) { return; }
            const data = {
                oldpassword: this.originalPassword,
                newpassword: this.newPassword
            }
            this.$http.put("/v1/administrator/update-administratorpassword-byid", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success("修改成功！")
                        this.mode = !this.mode
                    } else {
                        this.$toast.fail(data.data)
                    }
                })
                .catch((error) => {
                    this.$toast.fail('密码修改失败，请重试或重新登录');
                });
        },
        exit() { //退出登录 /index/exit
            localStorage.removeItem("admin-order");
            localStorage.removeItem("admin-sell");
            localStorage.removeItem("admin-unitland");
            localStorage.removeItem("admin-user");
            this.$http
                .get("/v1/index/exit")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        localStorage.removeItem("vuex");
                        this.$router.push("/admin/login");
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
}
</script>

<style>
/* 最外层 */
.admin-infor-cover {
    min-height: 100%;
    width: 100%;
    flex-direction: column;
}
.admin-infor-box {
    min-height: 700px;
    min-width: 600px;
    padding: 50px;
    border-radius: 12px;
}
/* 标题 */
.admin-infor-title {
    font-size: 24px;
    font-weight: bold;
}
.admin-infor-head-icon {
    position: absolute;
    bottom: 0;
    left: 130px;
}
/* 行块 */
.admin-infor-col {
    display: flex;
    flex-direction: row;
    padding: 20px;
    position: relative;
}
.admin-infor-row-label {
    width: 150px;
    font-size: 20px;
}
.admin-infor-row-font {
    font-size: 20px;
    color: #8185a3;
}
.admin-infor-row-change {
    margin-left: 10px;
    font-size: 16px;
    text-decoration: underline;
    color: blue;
    display: flex;
    align-items: center;
}
.admin-infor-button {
    height: 40px;
    width: 80px;
    box-shadow: 0 0 0 1px #000;
    background-color: #eeeeee;
    color: #000;
}
/* 头像 */
.circle-image {
    border-radius: 50%;
    overflow: hidden;
}
.circle-image > img {
    width: 150px;
    height: 150px;
}

.admin-infor-input {
    width: 200px;
    height: 32px;
    border-radius: 2px;
    background-color: #F2F3F5;
    font-size: 14px;
    box-shadow: 0 0 0 1px black;
}
.admin-infor-input-error {
    margin-top: 10px;
    font-size: 12px;
    color: red;
    max-width: 200px;
}
</style>