<template>
    <div class="admin-login-container">
        <!-- 三合一密码登录 -->
        <div class="admin-login-box">
            <div class="flex flex-col justify-center">
                <div class="admin-login-title">
                    <span>管理员登录</span>
                    <div class="admin-login-title-divider"></div>
                </div>
                <div class="admin-login-input-parent" style="margin-top:40px;">
                    <img class="admin-login-input-icon" src="../adminImage/admin-login-loginname.png">
                    <input type="text" class="admin-login-input" placeholder="请输入管理员登录账号" v-model="loginname">
                </div>
                <div class="admin-login-input-parent" style="margin-top:20px;">
                    <img class="admin-login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                    <input :type="showPassword ? '' : 'password'" class="admin-login-input" placeholder="请输入管理员登录密码" v-model="loginpassword">
                    <img src="../adminImage/admin-login-show.png" class="admin-login-input-icon-show" v-if="showPassword" @click="showPassword=false">
                    <img src="../adminImage/admin-login-hide.png" class="admin-login-input-icon-show" v-else @click="showPassword=true">
                </div>
                <button class="admin-login-button" @click="adminLogin">登 录</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loginname: '', // 登录账号
            loginpassword: '', // 登录密码
            showPassword: false, // 是否显示密码，true为显示，false为隐藏
        };
    },
    methods: {
        /* 点击眼睛图标显示/隐藏密码 */
        togglePassword() {
            this.showPassword = !this.showPassword;
        },

        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        adminLogin() { // 登录
            if (this.loginname === '') {
                this.$toast.fail('请输入登录账号');
                return;
            } else if (this.loginpassword === '') {
                this.$toast.fail('登录密码不可为空');
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            const data = {
                loginname: this.loginname,
                loginpassword: this.loginpassword,
            };
            this.$http.post('/v1/index/administrator-login', data)
                .then(response => {
                    loadingToast.clear();
                    if (response.data.data === '登陆成功') {
                        localStorage.setItem('admin-index','infor');
                        this.$store.dispatch("admin/fetchAdminInfo"); // 加载管理员个人信息
                        this.$router.push('/admin/my-infor')
                    } else {
                        alert(response.data.data);
                    }
                }).catch(error => {
                    loadingToast.clear();
                    this.$toast.fail('网络异常，请稍后重试');
                });
        },
    },
};
</script>

<style scoped>
/* ------------------------------------------------以下是手机端---------------------------------------------------- */
.admin-login-container {
    background-image: url('../adminImage/admin-login-background.png');
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.admin-login-box {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(0, -50%);
    width: 400px;
    height: 500px;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    padding: 50px 30px;
}
.admin-login-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
.admin-login-title-divider {
    width: 30px;
    height: 5px;
    background-color: #165DFF;
    margin-top: 7px;
}
.admin-login-input-parent {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #F1F4F8;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    position: relative;
}
.admin-login-input-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}
.admin-login-input {
    height: 100%;
    width: 100%;
    background-color: #F1F4F8;
}
.admin-login-input-icon-show {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.admin-login-button {
    height: 50px;
    width: 100%;
    margin-top: 80px;
    background-color: #165DFF;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
</style>
