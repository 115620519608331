<template>
    <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-2 py-3 mx-3 mt-2 mb-1 relative flex" @click="goToDetail">
        <div class="flex-shrink-0 relative">
            <div class="message-unread" v-if="!Message.readstatus"></div>
            <div class="shadow rounded-full">
                <img :src="Message.isme? myavatarUrl : Message.userphoto" class="w-12 h-12" />
            </div>
        </div>
        <div class="flex flex-col ml-4">
            <div class="flex items-center mb-1">
                <span class="font-semibold text-sm w-40">{{ Message.fromname }}</span>
                <span class="text-xs text-gray-500 absolute top-3 right-3">{{ Message.createdate }}</span>
                <div class="bg-red-600 rounded-full h-5 w-5 flex absolute top-10 right-5" v-if="!Message.readstatus"></div>
            </div>
            <div class="mb-6 text-gray-600 text-sm">
                <span class="mobile-message-text" v-if="Message.isurl === 'text'">{{ Message.emailscontent }}</span>
                <span class="mobile-message-text" v-else>{{$t('MobileMessageForm.isImage')}}</span>
            </div>
            <div class="inline-block text-blue-500 text-xs rounded absolute bottom-3 right-3" v-if="Message.landname">
                <span>{{$t('MobileMessageForm.topic1')}}:{{ Message.landname }}{{$t('MobileMessageForm.topic2')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            myavatarUrl: (state) => state.developer.avatarUrl,
        }),
    },
    props: {
        // 父子组件传值
        Message: {
            // 包含信息在data查看
            type: Object,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            userhead: require("../images/logo.png"),
        };
    },
    methods: {
        goToDetail() {
            if (this.value === '来自用户') { // 和卖家聊天
                this.$router.push({
                    path: "/mobile/message/thread?thread=" + this.Message.threadid + "&sale=" + this.Message.saleid,
                });
            } else if (this.value === '来自客服') {
                this.$router.push({ // 和客服聊天
                    path: "/mobile/service/reply?thread=" + this.Message.serviceid,
                });
            }
        },
    },
};
</script>

<style>
.mobile-message-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.message-unread {
    height: 12px;
    width: 12px;
    background-color: red;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
}
</style>
