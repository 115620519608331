<template>
    <div class="mobile-shop-home mobile-base-background2">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('SellerHome.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="flex flex-row animate__animated animate__fadeInUp" style="padding:0 16px;">
            <div class="mobile-seller-img">
                <img :src="avatarUrl" alt="用户头像" class="p-0 m-0" />
            </div>
            <div class="flex flex-col ml-2">
                <div class="flex flex-row">
                    <span style="font-size: 16px;font-weight: 500;">{{ sellername }}</span>
                    <div class="detail-merch-icon-developer" v-if="identifier === '企业'">{{$t('SellerHome.developer')}}</div>
                    <div class="detail-merch-icon-platform" v-else-if="identifier === '平台'">{{$t('SellerHome.platform')}}</div>
                </div>
                <div class="text-gray-600 text-sm tm-2">{{$t('SellerHome.contacttype')}}:{{ getSellerInfor() }}</div>
            </div>
            <div class="flex-grow flex justify-end items-center">
                <div class="mobile-land-seller-button" @click="TalkSeller">{{$t('SellerHome.contact')}}</div>
            </div>
        </div>
        <div class="border-t border-gray-300 mb-2 mt-2"></div>
        <div class="flex-grow overflow-y-auto flex flex-col" ref="sellerlands">
            <span class="mx-6 text-gray-400 text-sm">{{$t('SellerHome.all')}}:({{ totalNum }}){{$t('SellerHome.num')}}</span>
            <div v-for="(land, index) in LandInfor" :key="index">
                <ShopCartInfor
                    class="animate__animated animate__fast animate__fadeInUp"
                    :LandInfor="land"
                    :showSelect="false"
                    :show-delete="false"
                    :index="index"
                    @turnToDetail = "turnToDetail"
                    ref="MobileLand"
                />
            </div>
            <van-skeleton v-for="i in 5" :key="i" class="mt-3" title avatar :row="3" :loading="loading" />
        </div>
    </div>
</template>

<script>
import ShopCartInfor from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        ShopCartInfor,
    },
    created() {
        // this.sellerId = this.$route.query.seller;
        this.unitlandid = this.$route.query.merch;
    },
    mounted() {
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })


        // 初始化卖家信息
        this.$http.get("/v1/sale/get-seller-information?saleid=" + this.unitlandid)
            .then((response) => {
                const seller = response.data.data;
                if (seller.identifier === '企业' || seller.identifier === '平台') {
                    this.sellerId = seller.businessid;
                    this.sellername = seller.businessname;
                    this.sellerEmail = seller.businessemail;
                    this.sellerphone = seller.businessphone;
                    this.avatarUrl = seller.userphoto;
                    this.identifier = seller.identifier;
                    // 初始化卖家商品列表
                    this.$http.get("/v1/sale/get-saleselect-byseller?pageNum=1&pageSize=5&sellerid=" + this.sellerId)
                        .then((response) => {
                            this.loading = false;
                            const newData = response.data.data.rows;
                            this.LandInfor = newData;
                            this.pageNum = 2;
                            this.totalNum = response.data.data.total;
                            if (newData.length === 0) {
                                this.hasMoreData = false;
                            }
                        }).catch((error) => {});
                } else {
                    this.sellerId = seller.userid;
                    this.sellername = seller.username;
                    this.sellerEmail = seller.useremail;
                    this.sellerphone = seller.userphone;
                    this.avatarUrl = seller.avatarUrl;
                    this.identifier = '';
                    // 初始化卖家商品列表
                    this.$http.get("/v1/sale/get-saleselect-byseller?pageNum=1&pageSize=5&sellerid=" + this.sellerId)
                        .then((response) => {
                            this.loading = false;
                            const newData = response.data.data.rows;
                            this.LandInfor = newData;
                            this.pageNum = 2;
                            this.totalNum = response.data.data.total;
                            if (newData.length === 0) {
                                this.hasMoreData = false;
                            }
                        }).catch((error) => {});
                }
            })
            .catch((error) => {
                console.log(error);
            });
        

        this.$refs.sellerlands.addEventListener('scroll', this.handleScroll)
    },
    data() {
        return {
            messages: 0,
            // 测试数据
            loading: true, // 骨架屏，获取到卖家商品列表后变false
            flag: true, // 控制滚动条监听器触发频率的参数
            hasMoreData: true, // 当没有更多数据时变为false，停止调用接口
            sellerId: null, // 顾名思义，卖家id
            sellername: '', // 用户姓名
            sellerEmail: null, // 卖家邮箱
            sellerphone: null, // 卖家手机号
            identifier: '', // 卖家身份，有企业、平台
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            LandInfor: [], // 后端获取该卖家名下所有挂牌商品信息
            pageNum: 0,
            firstload: true,
            totalNum: 0, // 该卖家全部商品数量
            avatarUrl: require("../assets/no-image.png"),
            timer: null, // 定时器
        };
    },
    methods: {
        // 卖家联系方式
        getSellerInfor() {
            if (this.sellerEmail) {
                return this.sellerEmail;
            } else if (this.sellerPhone) {
                return this.sellerPhone;
            } else {
                return this.$t('MobileLandDetail.none');
            }
        },
        loadAvatar() {
            // 加载卖家头像
            this.$http
                .get("/v1/user/see-userphoto", {
                    params: {
                        userid: this.sellerId, // 将用户ID作为请求参数发送给服务器
                    },
                    responseType: "blob", // 设置响应类型为blob
                })
                .then((response) => {
                    const imageBlob = response.data;
                    const imageUrl = URL.createObjectURL(imageBlob);
                    this.$set(this.users, "avatarUrl", imageUrl);
                })
                .catch((error) => {
                    console.error(error);
                });
            // 加载卖家邮箱
            this.$http
                .get("/v1/user/personal-centerlist", {
                    params: {
                        userid: this.sellerId, // 将用户ID作为请求参数发送给服务器
                    },
                })
                .then((response) => {
                    const data = response.data.data;
                    console.log(data)
                    this.sellerEmail = data.useremail;
                    this.sellername = data.username;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        handleScroll() {
            let scrollHeight = this.$refs.sellerlands.scrollHeight;
            let scrollTop = this.$refs.sellerlands.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight <= 0 && this.hasMoreData && this.flag) {
                this.flag = false;
                this.timer = setTimeout(() => {
                    // 等待 300 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(this.timer);
                    this.timer = null;
                }, 300);
                // 当滚动到底部并且还有更多数据可加载时
                this.loadMoreData();
            }
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http
                .get("/v1/sale/get-saleselect-byseller?pageNum=" + this.pageNum + "&pageSize=5&sellerid=" + this.sellerId)
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?&merch=" + this.LandInfor[index].id;
            this.$router.push({
                path: newpath,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?merch=" + this.unitlandid + "&receive=" + this.sellerId,
            });
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    beforeDestroy() {
        // 在组件销毁时移除监听事件
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
/* 用户头像图片 */
.mobile-seller-img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: 1px solid #FFFFFF;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.mobile-seller-img > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}
/* 联系卖家按钮 */
.mobile-land-seller-button {
    display: flex;
    align-items: center;
    padding: 2px 16px;
    border: 1px solid #ED763D;
    font-size: 14px;
    color: #ED763D;
    border-radius: 303px;
    height: 27px;
}
</style>
