<template>
  <div class="main-container" ref="mainContainer">
    <!-- 返回文本链接 -->
    <div class="goback-parent" style="width:900px;" @click="$router.go(-1)">
      <span class="text-2xl">{{ '<' }}</span>
      <span class="ml-2">{{$t('PcLandDetail.landdetail')}}</span>
    </div>

    <!-- 卖家box -->
    <div class="seller-box relative" style="margin-top:20px">
      <!-- 加载界面 -->
      <div class="absolute h-full w-full" style="top: 0;left: 0;z-index: 999;" v-if="loading">
        <el-skeleton :loading="loading" animated>
          <template slot="template">
            <div class="seller-box">
              <el-skeleton-item variant="image" class="seller-avatar" />
              <div class="seller-infor">
                <el-skeleton-item variant="p" style="width: 150px;" />
                <el-skeleton-item variant="p" style="width: 150px;margin-top: 8px;" />
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
      <!-- 卖家头像 -->
      <div class="seller-avatar">
        <img :src="LandInfor.userBusiness.identifier ? LandInfor.userBusiness.userphoto : LandInfor.userBusiness.avatarUrl" class="seller-avatar-img">
      </div>
      <!-- 昵称和联系方式 -->
      <div class="seller-infor">
        <span>{{  $t('MobileServiceAWindow.name') }}:{{ LandInfor.userBusiness.identifier ? LandInfor.userBusiness.businessname : LandInfor.userBusiness.username }}</span>
        <span>{{ $t('SellerHome.contacttype') }}:{{ getSellerInfor() }}</span>
      </div>
      <!-- 右侧按钮 -->
      <div class="seller-contact">
        <button class="infor1-button infor1-button-whiteground" @click="OpenSeller">{{$t('PcLandDetail.viewhome')}}</button>
        <button class="infor1-button infor1-button-whiteground" style="margin-left:17px" @click="TalkSeller">{{$t('MobilePurchaseDetails.private')}}</button>
      </div>
    </div>

    <!-- 商品信息框 -->
    <div class="merch-detail-box relative" ref="merchContainer" style="margin-top:16px">
      <!-- 加载界面 -->
      <div class="absolute h-full w-full" style="top: 0;left: 0;z-index: 999;" v-if="loading">
        <el-skeleton :loading="loading" animated>
          <template slot="template">
            <div class="merch-detail-box">
              <div class="w-full flex flex-row">
                <el-skeleton-item variant="image" style="width: 352px;height: 352px;" />
                <div class="order-detail-infor1-box">
                  <el-skeleton-item variant="p" class="infor1-landname" />
                  <el-skeleton-item variant="p" class="infor1-landblock" />
                  <el-skeleton-item variant="p" class="infor1-price" />
                </div>
              </div>
              <div class="flex flex-row" style="margin-top:32px">
                <div class="infor2-box">
                  <el-skeleton-item variant="p" style="width: 80%;" />
                  <el-skeleton-item variant="p" style="width: 80%;margin-top: 4px;" />
                  <el-skeleton-item variant="p" style="width: 80%;margin-top: 4px;" />
                  <el-skeleton-item variant="p" style="width: 80%;margin-top: 4px;" />
                  <el-skeleton-item variant="p" style="width: 40%;margin-top: 4px;" />
                </div>
                <div class="infor3-box">
                  <el-skeleton-item variant="p" style="width: 80px;" />
                  <el-skeleton-item variant="p" style="width: 300px;margin-top: 16px;" />
                  <el-skeleton-item variant="p" style="width: 80px;margin-top: 4px;" />
                  <el-skeleton-item variant="p" style="width: 300px;margin-top: 8px;" />
                  <el-skeleton-item variant="p" style="width: 80px;margin-top: 4px;" />
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
      <div class="w-full flex flex-row">
        <canvas :height="canvasSize" :width="canvasSize" ref="bigmap" style="border-radius:8px"></canvas>
        <div class="order-detail-infor1-box">
          <span class="infor1-landname">{{ LandInfor.landname }}</span>
          <span class="infor1-landblock">{{ $t('MobileHomeLand.landNum') }}:{{ LandInfor.unitLand.landblocks }}</span>
          <span class="infor1-price" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
          <span class="infor1-price" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
          <div class="flex-grow flex flex-col justify-end">
            <div class="flex flex-row">
              <button class="sale-left-button" @click="onBuy">{{$t('MobileLandDetail.buy')}}</button>
              <button class="sale-right-button" @click="deleteLandCart" style="background-color: LightGray;color: black;" v-if="haveAddIn">{{$t('MobileLandDetail.havaAdd')}}</button>
              <button class="sale-right-button" @click="addLandCart" v-else>{{$t('MobileLandDetail.add')}}</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 下部分三个框 -->
      <div class="flex flex-row" style="margin-top:32px">
          <div class="infor2-box">
                <van-tabs v-model="tabControl">
                    <van-tab :title="$t('MobileLandDetail.detail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.area')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.number')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitlandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coor')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('MobileLandDetail.origindetail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.userBusiness.identifier ? LandInfor.userBusiness.businessname : LandInfor.userBusiness.username }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="infor3-box">
                <span class="text-md font-semibold">{{$t('MobileLandDetail.landRights')}}</span>
                <div class="flex flex-col mb-3 mt-3">
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  created() {
    // 不要忘了获取传输过来的土地信息
    this.unitlandid = this.$route.query.merch;
  },
  mounted() {
    this.isInMyCart();
    this.$http.get("/v1/mail/select-onsale-by-saleid?saleid=" + this.unitlandid)
      .then((res) => {
        this.LandInfor = res.data.data;
        let time1 = setTimeout(() => {
          const canvas = this.$refs.bigmap; // 获取 canvas 元素
          canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
          this.drawGrid();
          this.drawLand();
          this.loading = false;
          clearInterval(time1);
        }, 1000);
      }).catch((error) => {});
      this.controlWindow();
      window.addEventListener('resize', this.controlWindow);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.controlWindow);
  },
  data() {
    return {
      unitlandid: null, // url传来的订单编号，用来查询订单信息
      loading: true, // 是否处于加载中

      canvasSize: 352, // 画布尺寸
      gridSize: 0, // 网格尺寸

      haveAddIn: false, // 该商品是否已在购物车中

      tabControl: 0, // 第三格土地详情栏tab控制参数，0是土地详情，1是原始土地详情，2是卖家信息
      mode: true, // 图片模式，true为显示小区地图，false为显示原始土地图片

            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: "土地名称",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
                onSale: {
                    seller: this.$t('MobileLandDetail.none'),
                },
                userBusiness: {
                    identifier: '企业',
                    businessname: '',
                    businessemail: '',
                    businessphone: '',
                    userphoto: '',
                }
            },
    }
  },
  methods: {
    // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1080 || window.innerHeight <= 945) {
                const scaleX = (window.innerWidth - 180) / 900;
                const scaleY = window.innerHeight / 945;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
                this.$refs.mainContainer.style.height = 945 / scale + 'px';
                this.$refs.merchContainer.style.height = (window.innerHeight - 200) / scale + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = `100%`;
                this.$refs.merchContainer.style.height = `781px`;
            }
        },
    // 绘制网格
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;
            // 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#000000"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);

            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col + 1;
            const height = end.row - start.row + 1;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "#FF9500";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },

    // 判断是否已在购物车中
        isInMyCart() {
            // 判断该商品是否已经加入购物车
            this.$http
                .get("/v1/shoppingcart/get-landcart")
                .then((res) => {
                    let LandInfor = res.data.data;
                    for (let i = 0; i < LandInfor.length; i++) {
                        if (LandInfor[i].unitlandid === this.LandInfor.unitlandid) {
                            this.haveAddIn = true;
                            break;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

    // 卖家联系方式
        getSellerInfor() {
            if (this.LandInfor.userBusiness.businessemail) {
                return this.LandInfor.userBusiness.businessemail;
            } else if (this.LandInfor.userBusiness.businessphone) {
                return this.LandInfor.userBusiness.businessphone;
            } else {
                return this.$t('MobileLandDetail.none');
            }
        },

        OpenSeller() {
            // 查看卖家
            this.$router.push({
                path: "/user/seller-home?merch=" + this.LandInfor.id + "&seller=" + this.LandInfor.sellerid,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/user/seller-chat?merch=" + this.LandInfor.id,
            });
        },

    // 加入购物车
        addLandCart() {
            // 加入购物车
            const data = {
                unitlandid: this.LandInfor.unitlandid,
            };
            this.$http
                .post("/v1/shoppingcart/add-landcart", data, {
                    withCredentials: true,
                })
                .then((res) => {
                    this.haveAddIn = true;
                    this.$toast.success(this.$t('MobileLandDetail.alertaddIn'));
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('all.加入购物车失败'));
                });
        },

    // 将商品从购物车中删除
        deleteLandCart() {
            this.$http
                .delete("/v1/shoppingcart/delete-landcart?id=" + this.LandInfor.id)
                .then((res) => {
                    this.haveAddIn = false;
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },

    // 立即支付-进入确认订单页面
        onBuy() {
            this.$router.push({
                path: "/user/confirm-order",
                name: "UserPcConfirmOrder",
                query: {
                    LandInfor: JSON.stringify([this.LandInfor]),
                    totalPrice: JSON.stringify(this.LandInfor.totalprice),
                },
            });
        },
  },
}
</script>

<style>
.main-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* 卖家数据 */
.seller-box {
  height: 96px;
  width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 25px;
  border-radius: 8px;
  background-color: #FFFFFF;
}
.seller-avatar {
  width: 50px;
  height: 50px;
}
.seller-avatar-img {
  object-fit: fill;
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  box-shadow: 0 0 1px 0 #000000;
}
.seller-infor {
  margin-left: 21px;
  font-size: 14px;
  font-weight: 500;
  color: #2F3544;
  line-height: 22px;
  display: flex;
  flex-direction: column;
}
.seller-contact {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.infor1-button {
  padding: 7px 20px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #0256FF;
  font-size: 14px;
  font-weight: 350;
  cursor: pointer;
}
.infor1-button-blueground {
  background: #165DFF;
  color: #FFFFFF;
}
.infor1-button-whiteground {
  background: #FFFFFF;
  color: #0256FF;
}

/* 商品数据 */
.map-container {
    border-radius: 8px;
    border: 1px solid #b9b9b9;
}
.merch-detail-box {
  height: 781px;
  max-height: calc(100% - 275px);
  width: 900px;
  padding: 39px 32px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #FFFFFF;
}
.seller-list-container {
  max-height: calc(100% - 40px);
  overflow: auto;
  padding: 22px 40px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1080px) or (max-height: 945px) {
    .main-container {
      transform-origin: top center;
      justify-content: start;
    }
    .merch-detail-box {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .goback-parent {
      margin-top: 4px;
    }
}
.order-detail-infor1-box {
  flex-grow: 1;
  padding-left: 84px;
  display: flex;
  flex-direction: column;
}
.infor1-landname {
  font-size: 32px;
  font-weight: bold;
  color: #0256FF;
}
.infor1-landblock {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 350;
  color: #3D3D3D;
}
.infor1-price {
  margin-top: 15px;
  font-size: 24px;
  font-weight: bold;
  color: #FA5151;
}
.sale-left-button {
  width: 150px;
  height: 36px;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #165DFF 0%, #7423CF 100%);
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}
.sale-right-button {
  width: 150px;
  height: 36px;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #3873FF;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  color: #0256FF;
}

.infor2-box {
  width: 323px;
  padding: 16px;
  max-height: 309px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  background: #FFFFFF;
}
.infor3-box {
  margin-left: 95px;
  width: 350px;
  padding: 22px;
  max-height: 343px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>