<template>
    <div ref="container3" class="admin-unitland-container">
        <div @click="goBack" class="goback-parent">
            <img src="../icons/左箭头.png" alt="返回图标" class="goback-img">
            <span @mouseover="changeCursor">返回</span>
        </div>
        <canvas
            ref="backgroundcanvas"
            :width="containerWidth"
            :height="containerHeight"
            class="admin-unitland-canvas-background"></canvas>
        <canvas
            ref="canvas"
            :width="containerWidth"
            :height="containerHeight"
            class="admin-unitland-canvas"></canvas>
        <canvas
            ref="tooltipcanvas"
            :width="containerWidth"
            :height="containerHeight"
            class="admin-unitland-canvas-tooltip"
            @mousedown="onMouseDown"
            @mouseup="onMouseUp"
            @mousemove="onMouseMove"
            @mouseover="changeCursor"></canvas>


        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="modal" v-if="vmodal" @click="closeModal">
            <div class="modal-box">
                <span>{{ vmodalText }}</span>
            </div>
        </div>


        <!-- 左侧土地信息栏 -->
        <div class="flex flex-row absolute admin-unitland-information-position">
            <div class="flex flex-col admin-unitland-information-box" v-if="showAreaInfor">
                <span class="admin-unitland-information-box-topic">小区信息</span>
                <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">所属原始土地编号:</span>{{ area.originallandid?area.originallandid:'' }}</div>
                <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">所属原始土地名称:</span>{{ area.originalLand.originallandname?area.originalLand.originallandname:'' }}</div>
                <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">所在地址:</span>{{ area.originalLand.detailedaddress?area.originalLand.detailedaddress:'' }}</div>
                <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">小区区号:</span>{{ area.communitynumber?area.communitynumber:'' }}</div>
                <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">创建时间:</span>{{ area.originalLand.createdate?area.originalLand.createdate:'' }}</div>
                <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">土地状态:</span>{{ area.originalLand.landstatus?area.originalLand.landstatus:'' }}</div>
                <el-divider></el-divider>
                <div v-if="selectUnitland">
                    <span class="admin-unitland-information-box-topic">分块土地信息</span>
                    <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">选中的单位土地坐标:</span>{{ '(' + col + ',' + row + ')' }}</div>
                    <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">分块土地范围:</span>{{ selectUnitland.landcoordinates }}</div>
                    <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">分块土地名称:</span>{{ selectUnitland.mergelandname?selectUnitland.mergelandname:'未命名' }}</div>
                    <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">单位土地块数:</span>{{ selectUnitland.landblocks }}块</div>
                    <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">当前状态:</span>{{ selectUnitland.status }}</div>
                    <el-divider></el-divider>
                    <div v-if="selectUnitland.status==='已挂牌'">
                        <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">卖家编号:</span>{{ selectUnitland.onSale.sellerid }}</div>
                        <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">卖家名称:</span>{{ selectUnitland.onSale.seller }}</div>
                        <div class="admin-unitland-information-box-text"><span class="admin-unitland-information-box-text-label">售价:</span>{{ selectUnitland.onSale.totalprice }}</div>
                        <el-divider></el-divider>
                    </div>
                </div>
            </div>
            <!-- 缩放图标 -->
            <div class="admin-unitland-information-hide" @click="showAreaInfor = !showAreaInfor">
                <img src="../images/left.png" class="admin-unitland-information-hide-img" v-if="showAreaInfor">
                <img src="../images/right.png" class="admin-unitland-information-hide-img" v-else>
            </div>
        </div>


        <!-- 右侧合并侧边栏 -->
        <div class="flex flex-row absolute admin-unitland-merge-position" v-if="whichtab==='1'">
            <!-- 缩放图标 -->
            <div class="admin-unitland-right-hide" @click="showMergeBox = !showMergeBox">
                <img src="../images/right.png" class="admin-unitland-information-hide-img" v-if="showMergeBox">
                <img src="../images/left.png" class="admin-unitland-information-hide-img" v-else>
            </div>
            <div class="flex flex-col admin-unitland-information-box" v-if="showMergeBox">
                <span class="p-5">土地名称：<input type="text" placeholder="请输入1~20个字符" v-model="mergeLandName" style="color: #000"></span>
                <span class="p-5">土地块数：{{ selectLand.length }}块</span>
                <div class="p-5 text-center">
                    <button class="admin-unitland-right-button mr-5" @click="removeMergeUnitland" @mouseover="changeCursor">取消</button>
                    <button class="admin-unitland-right-button" @click="LandMerge" @mouseover="changeCursor">我选好了</button>
                </div>
            </div>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 partitionvmodal 变量决定 -->
        <div class="modal" v-if="mergevmodal" @click="closeModal">
            <div class="modal-box">
                <span>选中的分块土地与其他土地块所有权不同，</span>
                <span>是否重新确定合并土地并以此分块土地为基础？</span>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="removeMergeLand" style="margin-right: 30px;" @mouseover="changeCursor">确定</button>
                    <button class="modal-button" @click="closeModal" @mouseover="changeCursor">取消</button>
                </div>
            </div>
        </div>


        <!-- 右侧分割侧边栏 -->
        <div class="flex flex-row absolute admin-unitland-merge-position" v-if="whichtab==='2'">
            <!-- 缩放图标 -->
            <div class="admin-unitland-right-hide" @click="showMergeBox = !showMergeBox">
                <img src="../images/right.png" class="admin-unitland-information-hide-img" v-if="showMergeBox">
                <img src="../images/left.png" class="admin-unitland-information-hide-img" v-else>
            </div>
            <div class="land-partition-menu" v-if="isLandPartition">
                <div class="land-partition-tabs">
                    <div class="tabs-partition-select-item" :class="isPartitionTab('0')" @click="PartitionTabsControl('0')">
                        <span @mouseover="changeCursor" class="tabs-select-item-text">自动分割</span>
                    </div>
                    <div class="tabs-partition-select-item" :class="isPartitionTab('1')" @click="PartitionTabsControl('1')">
                        <span @mouseover="changeCursor" class="tabs-select-item-text">手动分割</span>
                    </div>
                </div>
                <div class="land-partition-text" v-if="whichpartitiontab==='0'">
                    <span class="p-5">土地名称：{{ selectUnitland?selectUnitland.mergelandname:'' }}</span>
                    <span class="p-5">土地块数：{{ LandPartition?LandPartition.length - 1:0 }}块</span>
                    <el-radio-group v-model="landPartitionAutoRadio" style="display: flex;flex-direction: column;">
                        <el-radio label="1x1" style="padding: 20px;color: #fff;">1x1</el-radio>
                        <el-radio label="2x2" style="padding: 20px;color: #fff;">2x2</el-radio>
                        <el-radio label="3x3" style="padding: 20px;color: #fff;">3x3</el-radio>
                    </el-radio-group>
                    <button class="admin-unitland-right-button" @click="LandPartitionAuto" @mouseover="changeCursor">我选好了</button>
                </div>
                <div class="land-partition-text" v-if="whichpartitiontab==='1'">
                    <span class="p-5" style="text-align:center">手动分割的土地名称：</span>
                    <el-input type="text" placeholder="请输入1~20个字符" v-model="partitionLandName" maxlength="10" show-word-limit style="width:80%"></el-input>
                    <span class="p-5">土地块数：{{ LandPartition?LandPartition.length:0 }}块</span>
                    <span class="p-5">分割数量：{{ selectLand?selectLand.length:'' }}</span>
                    <button class="admin-unitland-right-button" @click="LandPartitionHand" @mouseover="changeCursor">我选好了</button>
                </div>
            </div>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 partitionvmodal 变量决定 -->
        <div class="modal" v-if="partitionvmodal" @click="closeModal">
            <div class="modal-box">
                <span>确定更改选中的土地块？</span>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="removePartitionLand" style="margin-right: 30px;" @mouseover="changeCursor">确定</button>
                    <button class="modal-button" @click="closeModal" @mouseover="changeCursor">取消</button>
                </div>
            </div>
        </div>


        <!-- 合并/分割按钮 -->
        <div class="tabs-select">
            <div class="tabs-select-item" :class="isTab('0')" @click="tabsControl('0')">
                <span class="tabs-select-item-text" @mouseover="changeCursor">查看</span>
            </div>
            <div class="tabs-select-item" :class="isTab('1')" @click="tabsControl('1')">
                <span class="tabs-select-item-text" @mouseover="changeCursor">合并</span>
            </div>
            <div class="tabs-select-item" :class="isTab('2')" @click="tabsControl('2')">
                <span class="tabs-select-item-text" @mouseover="changeCursor">分割</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.id = this.$route.query.id;
        this.originallandid = this.$route.query.oid;
        this.communitynumber = this.$route.query.area;
    },
    mounted() {
        // 小区数据
        this.$http.get('/v1/administrator/select-blocklandmanage-byid?id='+this.id)
            .then(res => {
                this.area = res.data.data;
            }).catch(err => {
                this.$toast.fail('初始化失败，请刷新页面或重新登录');
            });
        // 分块土地数据
        this.$http.get('/v1/terrain-map/select-blockland-by-cordinates?originallandid=' + this.originallandid + '&communitynumber=' + this.communitynumber)
            .then(res => {
                this.unitland = res.data.data;
            }).catch(err => {
                this.$toast.fail('初始化失败，请刷新页面或重新登录');
            });
        this.timer = setInterval(() => {
            // 获取到了小区数据和分块土地数据后才能绘制
            if (this.area && this.unitland) {
                this.addCoordinate(); // 给每个分块土地添加便于识别的范围坐标
                this.addUnitlandOwner(); // 添加各分块土地所有人id
                this.addOriginUserLand(); // 把分块土地包含的单位土地坐标存入数组，便于选中
                this.drawOriginUserLand(); // 绘制背景图中的各分块土地
                clearInterval(this.timer);
                this.timer = null;
            } else {
                this.timernum += 1;
                if(this.timernum === 5) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }
        }, 1000);
    },
    data() {
        return {
            id: '', // 根据该id进入的分块土地所在小区，用来查询原始土地信息
            originallandid: '', // 原始土地编号
            communitynumber: '', // 小区坐标
            timer: "", // 计时器
            timernum: 0, // 定时器总是爆炸，计数10次不行直接停
            areaexample: {
                "id": 0,
                "originallandid": "",
                "mergelandname": "",
                "landnumber": "",
                "communitynumber": "",
                "landblocks": 0,
                "landcoordinates": "",
                "virtualownership": "",
                "remark": null,
                "status": "",
                "createdate": "",
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0,
                "originalLand": {
                    "id": 0,
                    "originallandid": "",
                    "ownershipnumber": "",
                    "originallandname": "",
                    "detailedaddress": "",
                    "landcertificate": "",
                    "warrantydeed": null,
                    "ownerid": "",
                    "entrance": "",
                    "geographicalcoordinates": "",
                    "landlength": "",
                    "landwidth": "",
                    "height": "",
                    "digitalright": "",
                    "surfaceright": "",
                    "landpicture": "",
                    "createdate": "",
                    "landstatus": "",
                    "applicationtype": "",
                    "reasonrejection": null,
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "userBusiness": null
                },
                "onSale": {
                    "id": 6250,
                    "originallandid": "S538",
                    "unitlandid": null,
                    "landname": null,
                    "sellerid": null,
                    "seller": null,
                    "salesmethods": null,
                    "expirydate": null,
                    "unitlandprice": null,
                    "pricenum": null,
                    "unitlandminprice": null,
                    "minimumprice": null,
                    "blocks": null,
                    "totalprice": null,
                    "unitpicture": null,
                    "createtime": null,
                    "reasondelisting": null,
                    "status": "",
                    "remark": null,
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "originalLand": null,
                    "unitLand": null
                }
            }, // 该小区数据
            unitlandexample: [{
                id: 0,
                originallandid: "",
                mergelandname: "",
                landnumber: "",
                communitynumber: "",
                landblocks: 0,
                landcoordinates: "",
                virtualownership: "",
                remark: null,
                status: "",
                createdate: "",
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: null,
                onSale: null
            },], // 从后端获取的小区数据
            area: null,
            unitland: null,
            userLand: [], // 通过上一条计算得出具体土地坐标


            selectUnitland: null, // 选中的分块土地信息，选中后会存储unitland内单个对象的信息
            showAreaInfor: true, // 控制左侧信息栏的显示与隐藏
            unitOwner: [], // 每个相同的所有人对应一种颜色
            colors: [
                "rgb(255,250,250,0.5)",
                "rgb(255,250,205,0.5)",
                "rgb(255,218,185,0.5)",
                "rgb(240,255,240,0.5)",
                "rgb(230,230,250,0.5)",
                "rgb(211,211,211,0.5)",
                "rgb(100,149,237,0.5)",
                "rgb(106,90,205,0.5)",
                "rgb(132,112,255,0.5)",
                "rgb(0,191,255,0.5)",
                "rgb(173,216,230,0.5)",
                "rgb(0,206,209,0.5)",
                "rgb(127,255,212,0.5)",
                "rgb(60,179,113,0.5)",
                "rgb(0,255,0,0.5)",
                "rgb(255,255,0,0.5)"], // 颜色对照表
            ispartition: true, // 是否是第一次进行分割，如果未分割过，不能进行挂牌
            vmodalText: '',
            containerHeight: 1100,
            containerWidth: 1100,
            gridSize: 50,  // 单位土地边长
            colLength: 22, // 计算横坐标方向有多少小方块
            rowLength: 22,
            originX: 0, // 鼠标当前的位置
            originY: 0,
            col: 0, // 鼠标选中的单位土地坐标
            row: 0,

            sellThisLandId: null, // 查看-选中的土地可以进行拍卖
            changenamevmodal: false, // 查看-修改该土地块名称-弹框显示
            changeLandName: null, // 查看-修改该土地块名称-修改后名称
            showError: false, // 查看-修改该土地块名称-弹框-名称校验错误

            selectLand: [], // 合并-已经选中的单位土地坐标集合
            isSelectLand: [], // 合并-可以选中的单位土地坐标集合
            mergeLandName: '', // 合并-选中合并土地后取名
            mergeLandNum: [], // 合并-被选中的土地块id
            mergeUnitland: [], // 合并-所有选中的待合并分块土地数据，用来比对所有权
            mergevmodal: false, // 比对所有权有异时询问是否重新设定

            LandPartition: [], // 分割-选中的用户土地块的单位土地数组集
            partitionLandId: 0, // 分割-选中的待分割土地块在用户土地块二维数组集中的一维下标
            partitionLandName: '', // 分割-手动分割确定的土地名称

            isLandInforMenu: true, // 左侧信息栏是否显示
            showMergeBox: true, // 右侧合并栏是否显示
            isLandPartition: true, // 右侧分割栏是否显示
            whichtab: '0', // 当前模式，1为合并，2为分割
            isMouseDown: false, // 鼠标是否按下
            whichpartitiontab: '0', // 分割的模式:0为自动分割，1为手动分割
            landPartitionAutoRadio: '1x1', // 自动分割选中的分割块
            vmodal: false, // 确定/取消弹窗
            partitionvmodal: false, // 分割土地的弹窗显示
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1);
        },


/* -------------------------------------------------------------------初始化功能区------------------------------------------------------------------------------ */



        addCoordinate() { // 为每一个分块土地添加坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/; // 提取坐标数据规则
            for (let i = 0;i < this.unitland.length;i++) {
                const matches = this.unitland[i].landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
                // 存储坐标数据
                this.unitland[i]["minX"] = parseInt(matches[1]);
                this.unitland[i]["minY"] = parseInt(matches[2]);
                this.unitland[i]["maxX"] = parseInt(matches[3]);
                this.unitland[i]["maxY"] = parseInt(matches[4]);
            }
            // 给进来的这块分块土地独立加一个四坐标到area参数中
            const matches = this.area.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            this.area["minX"] = parseInt(matches[1]);
            this.area["minY"] = parseInt(matches[2]);
            this.area["maxX"] = parseInt(matches[3]);
            this.area["maxY"] = parseInt(matches[4]);
        },
        addUnitlandOwner() { // 给每一个所有人都添加一个指定颜色
            // 使用 reduce 方法处理原始数组
            const newArray = this.unitland.reduce((result, obj) => {
                // 检查新数组中是否已存在 virtualownership 相同的对象
                const existingObj = result.find(item => item.virtualownership === obj.virtualownership);

                if (existingObj) {
                } else {
                    // 若不存在，则创建新对象，并存入 virtualownership 和 color
                    result.push({
                        virtualownership: obj.virtualownership,
                        color: this.colors[result.length],
                    });
                }

                return result;
            }, []);
            this.unitOwner = newArray;
        },
        addOriginUserLand() { // 根据后端传值传来的用户已经合并分割过的大块土地存入土地坐标
            for(let i = 0;i < this.unitland.length;i++) {
                const land = []
                for(let a = this.unitland[i].minX;a <= this.unitland[i].maxX;a++) {
                    for(let b = this.unitland[i].minY;b <= this.unitland[i].maxY;b++) {
                        land.push({col:a,row:b})
                    }
                }
                land.push({id:i})
                this.userLand.push(land)
            }
        },
        drawOriginUserLand() { // 绘制用户土地块
            const canvas = this.$refs.backgroundcanvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            canvas.style.backgroundImage = "url(" + this.area.originalLand.landpicture + ")"; // 修改背景图片
            for(let i = 0;i < this.unitland.length;i++) { // 加载所有用户土地
                const minX = this.unitland[i].minX
                const minY = this.unitland[i].minY
                const maxX = this.unitland[i].maxX
                const maxY = this.unitland[i].maxY
                const n = this.gridSize
                const width = maxX - minX + 1
                const height = maxY - minY + 1
                // 画线
                ctx.fillStyle = "rgb(0,0,0)";
                ctx.strokeRect(minX * n,minY * n,width * n,height * n);
                // 画土地块
                const array = this.unitOwner.find(item => item.virtualownership === this.unitland[i].virtualownership);
                ctx.fillStyle = array.color;
                ctx.fillRect(minX * n + 1,minY * n + 1,width * n - 2,height * n - 2);
            }
            // 进来的这块分块土地给一个土豪金
            const n = this.gridSize
            const width = this.area.maxX - this.area.minX + 1;
            const height = this.area.maxY - this.area.minY + 1;
            ctx.fillStyle = '#faff00';
            ctx.fillRect(this.area.minX * n + 1,this.area.minY * n + 1,width * n - 2,height * n - 2);
        },
        initializeMap() {
            const canvas = this.$refs.canvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            // 清除原有的所有大地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        },
        


/* -------------------------------------------------------------------屏幕页面功能区,包括监听鼠标事件、获取点击位置等------------------------------------------------------------------------------ */
        showLandInfor() {
            // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.tooltipcanvas;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            // 将位置坐标转化为土地块的坐标
            this.col = Math.floor(canvasX / this.gridSize)
            this.row = Math.floor(canvasY / this.gridSize)
        },
        // 鼠标按下土地地图事件
        onMouseDown(event) {
            this.isMouseDown = true
            this.originX = event.clientX
            this.originY = event.clientY
            this.showLandInfor() // 获取土地坐标
            if (this.whichtab==='1') { // 将选中的土地方块渲染为橙色，以此表示选中此土地块
                this.initializeMap() // 清空之前的绘图
                this.bigMapSelect() // 在合并模式，选中的格子为橙色，相邻格子显示为绿色
            } else if(this.whichtab==='2') {
                this.selectLandPartition() // 在分割模式，选中格子为橙色，但是有单位土地边框
            } else {
                this.initializeMap() // 清空之前的绘图
                this.drawLandSelect() // 在仅查看模式，选中格子不做处理，只显示土地块信息
            }
        },
        onMouseMove(event) {
            if (!this.isMouseDown) {
                this.floatingframe(event.clientX,event.clientY);
                return;
            } else {
                this.originX = event.clientX
                this.originY = event.clientY
                if (this.whichtab==='1') { // 合并模式
                    this.initializeMap(); // 拖动显示新区域
                    this.bigMapSelect();
                } else if (this.whichtab==='2') {
                    this.selectLandPartition();
                }
            }
        },
        onMouseUp() {
            this.isMouseDown = false;
        },
        floatingframe(x,y) { // 绘制悬浮框时获取坐标
            // 根据鼠标悬浮的坐标确定土地块坐标
            const canvas = this.$refs.tooltipcanvas;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = x - rect.left;
            const canvasY = y - rect.top;
            // 将位置坐标转化为土地块的坐标
            const landcol = Math.floor(canvasX / this.gridSize)
            const landrow = Math.floor(canvasY / this.gridSize)
            // 确定绘制的悬浮框坐标
            const drawX = landcol * this.gridSize + this.gridSize / 2
            const drawY = landrow * this.gridSize

            // 清除Canvas
            const ctx = canvas.getContext('2d');

            this.drawHoverBox(ctx,drawX,drawY,landcol,landrow);
        },
        drawHoverBox(ctx,drawX,drawY,landcol,landrow) { // 绘制悬浮框

            // 清除Canvas
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // 确定悬浮框的宽为80px,高为40px,最上层悬浮框设置为下方,右侧设置为左侧,其他情况设置为上方
            if (landrow === 0 && landcol !== this.colLength - 1) { // 上方
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX + 5, y + 10);
                ctx.lineTo(drawX - 40, y + 10);
                ctx.lineTo(drawX - 40, y + 50);
                ctx.lineTo(drawX + 40, y + 50);
                ctx.lineTo(drawX + 40, y + 10);
                ctx.lineTo(drawX + 10, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 30, y + 35);
            } else if (landcol === this.colLength - 1 && landrow !== 0) { // 右侧
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX - 10, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 50);
                ctx.lineTo(drawX + 10, drawY - 50);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.lineTo(drawX - 5, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 65, drawY - 22);
            } else if (landcol === this.colLength - 1 && landrow === 0) { // 右上
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX - 10, y + 10);
                ctx.lineTo(drawX - 60, y + 10);
                ctx.lineTo(drawX - 60, y + 50);
                ctx.lineTo(drawX + 20, y + 50);
                ctx.lineTo(drawX + 20, y + 10);
                ctx.lineTo(drawX - 5, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 55, y + 35);
            } else {
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX + 5, drawY - 10);
                ctx.lineTo(drawX - 25, drawY - 10);
                ctx.lineTo(drawX - 25, drawY - 50);
                ctx.lineTo(drawX + 55, drawY - 50);
                ctx.lineTo(drawX + 55, drawY - 10);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 20, drawY - 22);
            }
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
            this.selectLand = []
            this.mergeLandNum = []
            this.mergeUnitland = []
            this.LandPartition = null
            this.partitionLandId = undefined
            this.showTooltip = false
            this.userLandName = null
            this.mergeLandName = ''
            this.sellThisLandId = null
            this.selectUnitland = null
            this.initializeMap()
            if(this.whichtab==='1') { // 合并模式初始化在合并土地中加入进入的分块土地
                this.initializeMergeUnitland();
            } else if(this.whichtab==='2') { // 切换到分割模式时直接填入进入的分块土地
                this.initializePartitionUnitland();
            }
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        closeModal() { // 关闭弹窗
            this.vmodal = false;
            this.partitionvmodal = false;
            this.changenamevmodal = false;
            this.showError = false;
            this.isMouseDown = false;
        },



/* -------------------------------------------------------------------查看模式功能区------------------------------------------------------------------------------ */
        drawLandSelect() { // 绘制所有被选中的土地格，用橙色标注
            this.sellThisLandId = null
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            const result = this.unitland.filter(item => {return (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY);});
            if(result.length > 0) { // 属于分割后的分块土地
                this.selectUnitland = result[0];
                this.sellThisLandId = this.myLand.findIndex(item => item.some(({ col, row }) => col === this.col && row === this.row)); // 获取选中的土地块在我的土地集中的一维下标
                // 绘制土地块
                const array = this.selectUnitland;
                const width = array.maxX - array.minX + 1;
                const height = array.maxY - array.minY + 1;
                ctx.fillRect(array.minX * this.gridSize + 1,array.minY * this.gridSize + 1,this.gridSize * width - 2,this.gridSize * height - 2);
            } else {
                ctx.fillRect(this.col * this.gridSize + 1,this.row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },



/* -------------------------------------------------------------------合并模式功能区------------------------------------------------------------------------------ */



        removeMergeUnitland() { // 撤销选择的合并土地
            this.mergeLandName = '';
            this.mergeLandNum = [];
            this.selectLand = [];
            this.isSelectLand = [];
            this.mergeUnitland = [];
            this.initializeMap();
            this.initializeMergeUnitland();
        },
        initializeMergeUnitland() {
            this.mergeUnitland.push(this.area);
            this.selectUnitland = this.area;
            this.mergeLandNum.push(this.area.id);
            for(let i = this.area.minX;i <= this.area.maxX;i++) { // 计数，选中的单位土地坐标集，同时用于后续合并接口的格式判定
                for(let j = this.area.minY;j <= this.area.maxY;j++) {
                    this.selectLand.push({col:i,row:j});
                }
            }
            this.confirmSlectLand();
            this.drawBigMapSelect();
        },
        bigMapSelect() { // 在合并模式，大地图鼠标按下时选中某个区域，此区域高亮显示
            const inCanSelectedLand = this.isSelectLand.find(item => {return this.col >= item.minX && this.col <= item.maxX && this.row >= item.minY && this.row <= item.maxY}); // 查看选中的单位土地是否在可选坐标集中
            const result = this.unitland.find(item => {return (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)}); // 查看选中的单位土地是否在该区已分割的分块土地集中
            if(inCanSelectedLand !== undefined){ // 在可选坐标集中
                if(result !== undefined && this.mergeUnitland.find(item => { return item.virtualownership === result.virtualownership }) === undefined) { // 比对所有权
                    this.$toast("不同的所有权");
                    return;
                }
            } else { // 不符合相邻规则，清空原数据重新录入
                this.$toast.fail('不可选择该分块土地');
                this.initializeMergeUnitland();
                return;
            }
            if(result !== undefined && !this.mergeLandNum.includes(result.id)) { // 点到新的相邻分块土地
                this.selectUnitland = result; // 更新左侧信息栏数据
                this.mergeUnitland.push(result); // 添加到待合并分块土地数组，用来比对所有权
                this.mergeLandNum.push(result.id); // 计数，选中的土地块数，同时用于后续合并接口的参数
                for(let i = result.minX;i <= result.maxX;i++) { // 计数，选中的单位土地坐标集，同时用于后续合并接口的格式判定
                    for(let j = result.minY;j <= result.maxY;j++) {
                        this.selectLand.push({col:i,row:j});
                    }
                }
            } else if (result !== undefined && this.mergeLandNum.includes(result.id)) {
                // 选中的单位土地已经在合并选中的分块土地集中，不操作
            } else { // 不在已分割的分块土地集中，正常情况不会遇到，除非地址栏数据被改
                this.vmodalText = '不可选中该土地';
                this.vmodal = true;
                this.selectUnitland = null;
                this.selectLand = [];
                this.mergeUnitland = [];
                this.isSelectLand = [];
                this.mergeLandNum = [];
            }
            this.confirmSlectLand(); // 计算并添加新的可选的分块土地集
            this.drawBigMapSelect(); // 绘制选中的以及可选的分块土地
        },
        confirmSlectLand() { // 根据已选中的坐标集计算出可选的坐标集
            let minX = this.selectUnitland.minX;
            let minY = this.selectUnitland.minY;
            let maxX = this.selectUnitland.maxX;
            let maxY = this.selectUnitland.maxY;
            for(let i = 0;i < (maxX - minX + 1);i++) {
                const array = {col:minX+i,row:minY-1} // 上边单位土地
                const result = this.unitland.find(item => {return array.col >= item.minX && array.row >= item.minY && array.col <= item.maxX && array.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result !== undefined && this.isSelectLand.find(item => {return item.id === result.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result)
                }
                const array1 = {col:minX+i,row:maxY+1} // 下边单位土地
                const result1 = this.unitland.find(item => {return array1.col >= item.minX && array1.row >= item.minY && array1.col <= item.maxX && array1.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result1 !== undefined && this.isSelectLand.find(item => {return item.id === result1.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result1)
                }
            }
            for(let i = 0;i < (maxY - minY + 1);i++) {
                const array = {col:minX-1,row:minY+i} // 左边单位土地
                const result = this.unitland.find(item => {return array.col >= item.minX && array.row >= item.minY && array.col <= item.maxX && array.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result !== undefined && this.isSelectLand.find(item => {return item.id === result.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result)
                }
                const array1 = {col:maxX+1,row:minY+i} // 右边单位土地
                const result1 = this.unitland.find(item => {return array1.col >= item.minX && array1.row >= item.minY && array1.col <= item.maxX && array1.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result1 !== undefined && this.isSelectLand.find(item => {return item.id === result1.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result1)
                }
            }
        },
        drawBigMapSelect() { // 合并模式根据选中的单位土地集和可选的单位土地集改变相应土地格颜色进行标注
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "green"
            for(let i = 0;i < this.isSelectLand.length;i++) { // 绘制所有可选择的土地格，用绿色标注
                const x = this.isSelectLand[i].minX;
                const y = this.isSelectLand[i].minY;
                const height = this.isSelectLand[i].maxY - this.isSelectLand[i].minY + 1;
                const width = this.isSelectLand[i].maxX - this.isSelectLand[i].minX + 1;
                ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,width * this.gridSize - 2,height * this.gridSize - 2);
            }
            ctx.fillStyle = "orange"
            for(let i = 0;i < this.mergeUnitland.length;i++) { // 绘制所有被选中的土地格，用橙色标注
                const x = this.mergeUnitland[i].minX;
                const y = this.mergeUnitland[i].minY;
                const height = this.mergeUnitland[i].maxY - this.mergeUnitland[i].minY + 1;
                const width = this.mergeUnitland[i].maxX - this.mergeUnitland[i].minX + 1;
                ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,width * this.gridSize - 2,height * this.gridSize - 2);
            }
        },
        removeMergeLand() {
            this.selectUnitland = null;
            this.selectLand = [];
            this.isSelectLand = [];
            this.mergeLandNum = [];
            this.mergeUnitland = [];
            this.bigMapSelect();
        },
        LandMerge() { // 合并按钮
            if (this.mergeUnitland.length === 0 || this.mergeLandNum.length < 2) {
                this.vmodalText = '请至少选择两块土地.'
                this.vmodal = true
                return;
            }
            if (this.mergeLandName === '') {
                this.vmodalText = '请为您的新土地命名.'
                this.vmodal = true
                return;
            } else if(this.mergeLandName.length > 20) {
                this.vmodalText = '名称过长.'
                this.vmodal = true
                return;
            }
            const minX = Math.min(...this.mergeUnitland.map(coord => coord.minX));
            const minY = Math.min(...this.mergeUnitland.map(coord => coord.minY));
            const maxX = Math.max(...this.mergeUnitland.map(coord => coord.maxX));
            const maxY = Math.max(...this.mergeUnitland.map(coord => coord.maxY));
            const width = maxX - minX + 1;
            const height = maxY - minY + 1;
            const aspectRatio = width / height;
            if (aspectRatio >= 4 || aspectRatio <= 0.25 || this.selectLand.length < width * height) {
                this.vmodalText = '已选中的坐标集合组成的新网格必须为正方形或长宽比小于4的长方形.'
                this.vmodal = true
                return;
            }
            const landcoordinates = '(' + minX + ',' + minY + ') ~ (' + maxX + ',' + maxY + ')';
            let string = 'communitynumber=' + this.area.communitynumber + '&mergelandname=' + this.mergeLandName + '&landcoordinates=' + landcoordinates;
            for(let i=0;i<this.mergeLandNum.length;i++) {
                string += '&ids[]=' + this.mergeLandNum[i];
            }
            // 符合要求，进行后续操作
            this.$http.post('/v1/terrain-map/blockland-merge-byid?' + string)
            .then(res => {
                // 处理返回值
                this.$toast.success(res.data.msg);
                // 获取当前页面的完整 URL
                const currentUrl = window.location.href;
                // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                const url = new URL(currentUrl);
                url.searchParams.set('id', res.data.data);
                // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                history.replaceState({}, null, url.toString());
                location.reload();
            }).catch(err => {
                // 处理错误
                this.$toast.fail('合并失败，请重试或重新登录')
            });
        },



/* -------------------------------------------------------------------分割模式功能区------------------------------------------------------------------------------ */



        initializePartitionUnitland() { // 初始化分割的分块土地
            let array = [];
            for(let i = this.area.minX;i <= this.area.maxX;i++) { // 将该分块土地包含的单位土地放入数组集中
                for(let j = this.area.minY;j <= this.area.maxY;j++) {
                    array.push({col:i,row:j});
                }
            }
            this.selectUnitland = this.area;
            this.LandPartition = array;
            this.drawLandPartition();
        },
        selectLandPartition() { // 在分割模式，初始选中进入的分块土地，后续点击进行此操作
            if(this.whichpartitiontab==='0') {
                const flag = this.LandPartition.find(item => item.col === this.col && item.row === this.row);
                if (flag !== undefined) { // 选中了当前选中的分块土地
                    this.vmodalText = '当前处于自动分割模式\n如需再次点选请转为手动分割模式';
                    this.vmodal = true;
                } else { // 选中了别的分块土地
                    this.$toast.fail('不可更改分块土地');
                }
            } else { // 处于手动分割模式
                if(this.selectLand.find(item => {return item.col === this.col && item.row === this.row}) === undefined) {
                    this.selectLand.push({col:this.col,row:this.row});
                } else {
                    return;
                }
                this.drawLandPartition2();
            }
        },
        drawLandPartition() { // 选中分割土地块后，将其颜色填充为橙色
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            for(let i = 0;i < this.LandPartition.length;i++) {
                ctx.fillRect(this.LandPartition[i].col * this.gridSize + 1,this.LandPartition[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        drawLandPartition2() { // 在手动分割模式中，用户自己点选该土地块的单位土地
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "yellow"
            for(let i = 0;i < this.selectLand.length;i++) {
                ctx.fillRect(this.selectLand[i].col * this.gridSize + 1,this.selectLand[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        PartitionTabsControl(n) {
            this.whichpartitiontab = n
        },
        isPartitionTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichpartitiontab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        /* ------------------------------------------------自动分割------------------------------------------- */
        LandPartitionAuto() { // 自动分割土地
            if(!this.LandPartition) { // 未选中土地块，不可操作
                this.vmodalText = '请选中一块土地.'
                this.vmodal = true
                return
            }
            // 符合要求，进行后续操作
            this.$http.get('/v1/terrain-map/segmentation-blockland?id=' + this.area.id + '&size=' + this.landPartitionAutoRadio)
            .then(res => {
                // 处理返回值
                if (res.data.status===200) {
                    this.$toast.success(res.data.msg);
                    // 获取当前页面的完整 URL
                    const currentUrl = window.location.href;
                    // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                    const url = new URL(currentUrl);
                    url.searchParams.set('id', res.data.data[0]);
                    // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                    history.replaceState({}, null, url.toString());
                    location.reload();
                } else {
                    this.$toast.fail(res.data.data);
                }
            }).catch(err => {
                // 处理错误
                this.$toast.fail('分割失败，请重试或重新登录')
            });
        },
        /* ------------------------------------------------手动分割------------------------------------------- */
        LandPartitionHand() { // 手动分割土地
            if (this.selectLand.length === 0) {
                this.$toast("请至少选中一块土地。");
                return;
            } else if (this.partitionLandName === '') {
                this.$toast("请输入土地名称");
            }
            const minX = Math.min(...this.selectLand.map(coord => coord.col));
            const minY = Math.min(...this.selectLand.map(coord => coord.row));
            const maxX = Math.max(...this.selectLand.map(coord => coord.col));
            const maxY = Math.max(...this.selectLand.map(coord => coord.row));
            const width = maxX - minX + 1;
            const height = maxY - minY + 1;
            const aspectRatio = width / height;
            if (aspectRatio >= 4 || aspectRatio <= 0.25 || this.selectLand.length < width * height) {
                this.$toast.fail('已选中的坐标集合组成的新网格必须为正方形或长宽比小于4的长方形。');
                return;
            }
            // 符合要求，进行后续操作
            const landcoordinates = '(' + minX + ',' + minY + ') ~ (' + maxX + ',' + maxY + ')';

            this.$http.get('/v1/terrain-map/handmovement-segmentation-blockland?id=' + this.area.id + '&mergelandname=' + this.partitionLandName + '&coordinate=' + landcoordinates)
            .then(res => {
                // 处理返回值
                this.$toast.success(res.data.msg);
                // 获取当前页面的完整 URL
                const currentUrl = window.location.href;
                // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                const url = new URL(currentUrl);
                url.searchParams.set('id', res.data.data);
                // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                history.replaceState({}, null, url.toString());
                location.reload();
            }).catch(err => {
                // 处理错误
                this.$toast.fail('分割失败，请重试或重新登录')
            });
        },
    }
}
</script>

<style scoped>
.admin-unitland-container {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: relative;
    background-color: #fff;
}

/* ----------------------------------------禁忌的三重canvas---------------------------------------- */
/* 背景canvas */
.admin-unitland-canvas-background {
    background-image: url('../assets/no-image.png');
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* 可操作性canvas */
.admin-unitland-canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
/* 悬浮坐标绘制的canvas图 */
.admin-unitland-canvas-tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}


/* ----------------------------------------屏幕左侧土地信息栏---------------------------------------- */
/* 该小区土地信息框 */
.admin-unitland-information-position {
    /* 确定位置和主题色、字体 */
    top: 100px;
    left: 0;
    color: #fff;
    font-size: 16px;
    z-index: 4;
}
.admin-unitland-information-box {
    /* 文字显示区 */
    width: 350px;
    background-color: #767676;
}
.admin-unitland-information-hide {
    /* 显示隐藏的按钮样式 */
    height: 30px;
    width: 20px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #767676;
}
.admin-unitland-information-hide-img {
    height: 30px;
    width: 20px;
    object-fit: cover;
}
.admin-unitland-information-box-topic {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 20px;
}
.admin-unitland-information-box-text {
    padding: 10px;
}
.admin-unitland-information-box-text-label {
    min-width: 150px;
    color: #c9c9c9;
}


/* ----------------------------------------屏幕右侧合并信息栏---------------------------------------- */
.admin-unitland-merge-position {
    /* 确定位置和主题色、字体 */
    top: 100px;
    right: 0;
    color: #fff;
    font-size: 16px;
    z-index: 4;
}
.admin-unitland-right-hide {
    /* 显示隐藏的按钮样式 */
    height: 30px;
    width: 20px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #767676;
}
/* 合并分割按钮 */
.admin-unitland-right-button {
    height: 50px;
    width: 120px;
    margin-top: 20px;
    font-size: 24px;
    background-color: #fff;
    color: #000;
}


/* ----------------------------------------屏幕右侧分割信息栏---------------------------------------- */
.land-partition-menu {
    height: 600px;
    width: 350px;
    position: absolute;
    top: 20%;
    right: 0;
    background-color: #767676;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    z-index: 4;
}
.land-partition-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
}
.land-partition-tabs {
    height: 100px;
    width: 100%;
}
.tabs-partition-select-item {
    display: inline-block;
    height: 100%;
    width: 50%;
    padding: 0;
    margin: 0;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 20px;
    color: #fff;
    position: relative;
}


/* ----------------------------------------合并/分割选项卡---------------------------------------- */
.tabs-select {
    position: absolute;
    bottom: 50px;
    right: 100px;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    z-index: 5;
}
.tabs-select-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 20px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 20px;
}
.tabs-select-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}



/* ----------------------------------------确定/取消的弹窗---------------------------------------- */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 3; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>