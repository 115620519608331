<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>平台管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">管理员列表</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-topic">管理员列表信息</div>
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('可用')" @click="tabsControl('可用')">
                        <span @mouseover="changeCursor">可用</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('不可用')" @click="tabsControl('不可用')">
                        <span @mouseover="changeCursor">不可用</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">管理员编号</span>
                            <el-input style="width:256px;" v-model="search.administratorid" placeholder="请输入管理员编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">管理员职称</span>
                            <el-select style="width:256px;" v-model="search.role" placeholder="请选择">
                                <el-option v-for="item in restaurants" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">注册时间</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadAdmin">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetUser">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content" v-if="isReview">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic" style="background-color:#F7F8FA">
                        <el-col :span="3"><span>注册日期</span></el-col>
                        <el-col :span="3"><span>管理员编号</span></el-col>
                        <el-col :span="3"><span>管理员昵称(登录账号)</span></el-col>
                        <el-col :span="2"><span>真实姓名</span></el-col>
                        <el-col :span="2"><span>管理员职称</span></el-col>
                        <el-col :span="3"><span>创建人</span></el-col>
                        <el-col :span="3"><span>联系电话</span></el-col>
                        <el-col :span="2"><span>状态</span></el-col>
                        <el-col :span="3"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in userArray" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.foundertime" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.foundertime }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.administratorid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.administratorid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.loginname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.loginname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.administratorname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.administratorname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.role" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.role }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.founder" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.founder }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.administratorphone" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.administratorphone }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <div class="admin-manage-status-normal" v-if="apply.status==='可用'">正常</div>
                                    <div class="admin-manage-status-stop" v-else>停用</div>
                                </el-col>
                                <el-col :span="3" style="word-wrap: break-word;">
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="goDetail(apply)">查看详情</span>
                                    &nbsp;
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="showModal(apply.id,'不可用')" v-if="apply.status==='可用'">停用</span>
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="showModal(apply.id,'可用')" v-else>启用</span>
                                </el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
                <AdminApply v-else :adminChild='adminChild'></AdminApply>
            </div>
        </div>
        <!-- 启用停用管理员账户二次确认弹窗，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="admin-apply-modal" v-if="vmodal">
            <div class="admin-apply-modal-box">
                <span style="padding:30px;">{{ modalText }}</span>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="vmodal=false" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="changeStatus" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminApply from "./AdminDetail.vue";
export default {
    components: { // 管理员组件
        AdminApply,  // 修改管理员信息组件
    },
    mounted() {
        this.$http.post('/v1/administrator/select-platformmanage-list', {
                status: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            })
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                // 处理错误
                this.$toast.fail('初始化失败，请刷新重试或重新登录')
            });
    },
    data() {
      return {
        loading: true, // 缓冲条
        userArray: [], // 用于显示的数组，根据需要显示
        showLength: 0, // 所有数据的长度
        
        whichtab: '可用',
        pageSize: 50, // 每页条数
        currentPage: 1,// 页码
        isReview: true, // 控制详细信息组件显示，true是列表页，false是详情页
        adminChild: { // 父子组件传值
            id: 3,
            administratorid: "10003",
            administratorname: "普通管理员",
            loginname: "admin666",
            loginpassword: "394bde512604e9f1de9f5aedb31e8186",
            administratorphone: null,
            role: "普通管理员",
            founder: null,
            foundertime: null,
            status: "可用",
            administratorlevel: "7级",
        },

        search: {
            administratorid: '',
            role: '',
            date: '',
        },
        restaurants: [{
          value: '普通管理员',
          label: '普通管理员'
        }, {
          value: '高级管理员',
          label: '高级管理员'
        },{
          value: '超级管理员',
          label: '超级管理员'
        }],
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        selectId: '', // 启用停用管理员账户时选中的id
        selectStatus: '',
        vmodal: false,
        modalText: '',
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            this.currentPage = 1;
            this.search.id = '';
            this.search.role = '';
            this.search.date = '';
            this.loadAdmin();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        loadAdmin() { // 更新显示的数据
            this.loading = true;
            this.isReview = true;
            let data = {
                status: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.administratorid !== '') {
                data["administratorid"] = this.search.administratorid;
            }
            if(this.search.role !== '') {
                data["role"] = this.search.role;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }

            this.$http.post('/v1/administrator/select-platformmanage-list', data)
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                // 处理错误
                this.$toast.fail('加载失败，请刷新重试或重新登录');
            });
        },
        resetUser() {
            this.search.administratorid = '';
            this.search.role = '';
            this.search.date = '';
            this.loadAdmin();
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loadAdmin();
        },
        pageCurrentChange(val) {
            // 更新页距
            this.currentPage = val;
            this.loadAdmin();
        },
        goDetail(admin) { // 打开详情页
            this.adminChild = admin;
            this.isReview = false;
        },
        closeDetail() {
            this.isReview = true;
        },
        refresh() { // 用来让子组件调用返回的函数
            this.vmodal = false;
            this.loadAdmin(); // 更新数据
        },
        showModal(id,status) {
            this.selectId = id;
            this.selectStatus = status;
            if (status === '可用') {
                this.modalText = '确定启用该账号？';
            } else {
                this.modalText = '确定停用该账号？';
            }
            this.vmodal = true;
        },
        changeStatus() {
            let data = {
                id: this.selectId,
                status: this.selectStatus
            }
            this.$http.put("/v1/administrator/update-platformmanage-byid", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success("修改成功！");
                        this.vmodal = false;
                        this.loadAdmin(); // 更新数据
                    } else {
                        this.$toast.fail(data.data);
                    }
                })
                .catch((error) => {
                    this.$toast.fail('修改失败，请刷新重试或重新登录');
                });
        },
    },
}
</script>

<style>
/* 最外层布局 */
.admin-manage-container {
    height: 100%;
    padding: 10px 50px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.admin-manage-main {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    flex: 1;
}
.admin-manage-tabs {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}


/* --------------------------------------------页面路径样式-------------------------------------------- */
.admin-breadcrumb {
    font-size: 14px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    padding-left: 0;
}
.admin-breadcrumb-icon {
    height: 15px;
    width: 15px;
}
.admin-breadcrumb-interval {
    margin-left: 10px;
    margin-right: 10px;
}
.admin-breadcrumb-link {
    font-weight: bold;
}


/* --------------------------------------------列表上方标题样式-------------------------------------------- */
.admin-manage-topic {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
}


/* --------------------------------------------选项卡样式-------------------------------------------- */
.admin-manage-tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    display: flex;
    flex-direction: row;
}
.admin-manage-tabs-head-item {
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
    border: 1px solid #F2F3F8;
    font-size: 14px;
    margin-right: 15px;
}
.admin-manage-tabs-head-item-select {
    background: #F2F3F8;
    box-sizing: border-box;
    border: 1px solid #165DFF;
    font-size: 16px;
    color: #165DFF;
}
/* 新增企业按钮 */
.admin-manage-tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.admin-manage-tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}

/* --------------------------------------------搜索栏样式-------------------------------------------- */
.admin-manage-search-container {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
}
.admin-manage-search-box {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.admin-manage-search-buttonbox {
    justify-content: end;
    border-left: 1px solid rgb(223 223 223);
    padding-left: 50px;
}
.admin-manage-search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 356px;
    height: 32px;
}
.admin-manage-search-input-tips {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: red;
}
.admin-manage-search-input-label {
    font-size: 14px;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
}
.admin-manage-search-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.el-date-editor > .el-range-separator {
    width: 30px !important;
}


/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.admin-manage-tabs-content {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
/* 信息栏样式 */
.admin-manage-tabs-content-row {
    padding: 10px;
    text-align: center;
}
.admin-manage-tabs-content-row-topic {
    background-color: rgba(242, 243, 245);
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
}
.admin-manage-tabs-content-col {
    height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    border-bottom: 1px solid #a7a5a5;
}
.admin-manage-tabs-content-col-text {
    /* 文本不可换行，超出宽度的变为省略号 */
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.admin-manage-tabs-conten-row-flow {
    flex-grow: 1;
    max-height: 700px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}
.admin-manage-no-search {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
}
.el-pagination {
    margin-top: 20px;
    text-align: right;
}
/* -------------------------------表示管理员状态的图标（正常、停用）------------------------------- */
.admin-manage-status-normal {
    width: 81px;
    height: 26px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 181, 120, 0.1);
    color: #00B578;
}
.admin-manage-status-stop {
    width: 81px;
    height: 26px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 81, 81, 0.1);
    color: #FA5151;
}


/* 消息弹窗 */
.admin-manage-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  z-index: 1; /* 将弹框置于其他所有元素之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  max-width: 80%;
  max-height: 80%;
}

/* 批量选中按钮 */
.select-button-box {
    width: 100%;
    text-align: right;
}
.select-button-box > .button {
    height: 40px;
    width: 100px;
    background-color: #a7a7a7;
    font-size: 18px;
    border-radius: 12px;
}
</style>