<template>
    <div class="flex flex-col bg-gray-100 msger relative">
        <!-- 聊天页面的头部，包含菜单和清除聊天记录按钮 -->
        <div class="flex items-center justify-between p-2 bg-white shadow-md">
            <div class="flex items-center space-x-2 flex-1">
                <h1 class="text-lg font-bold text-gray-700 flex-1 text-center">
                    发送公告
                </h1>
            </div>
        </div>

        <!-- 输入框和发送按钮 -->
        <form class="input">
            <textarea class="px-4 py-2 text-gray-700 bg-gray-100 rounded-md w-full h-full" placeholder="请输入要发送的公告消息" v-model="text"></textarea>
        </form>

        <div class="send">
            <button @click.prevent="open"
                class="text-4xl px-8 py-4 text-white bg-blue-500 rounded-md focus:outline-none focus:shadow-outline hover:bg-blue-600">
                发 送
            </button>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            text: '', // 文本，发送的消息
            image: null, // 图片，发送的消息
            imageUrl: null, // 图片url
            tooltipText: '上传图片', // 下方切换上传图片/文本提示语
            uploadMode: true, // true是上传文本，false是上传图片
        }
    },
    methods: {
        changeCursor(event) {
            event.target.style.cursor = "pointer";
        },
        changeMode() {
            if (this.uploadMode) {
                this.uploadMode = false;
                this.tooltipText = '上传文本'
            } else {
                this.uploadMode = true;
                this.tooltipText = '上传图片'
            }
        },
        /* 上传图片功能样式 */
        handleClick() {
            // 触发文件选择器
            this.$refs.image.click();
        },
        handleFileInputChange() { // 获取用户选择的文件
            const size = 5 * 1024 * 1024; // 5MB
            const file = this.$refs.image.files[0];
            if (file.size < size) {
                this.image = this.$refs.image.files[0];
                // 将文件对象转换为 URL
                this.imageUrl = URL.createObjectURL(this.image);
            } else {
                this.$toast.fail('图片过大')
            }
        },

        open() {
            this.$confirm('是否确定发送该系统消息?', '再次确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendMessage();
            }).catch(() => {
                this.$toast('已取消发送')
            });
        },

        // 发送公告
        async sendMessage() {
            if (this.text !== '') { // 公告不可为空
                this.$http.post('/v1/message/send?message=' + this.text)
                .then(res => {
                    this.$toast.success('发送成功！');
                    this.text = '';
                }).catch(error => {
                    this.$toast.fail('公告发送失败，请刷新重试或重新登录');
                });
            } else {
                this.$toast.fail('公告不可为空');
            }
        },
    },

}
</script>
<style scoped>
.msger {
    height: 95%;
    min-width: 768px;
    max-width: 867px;
    margin: auto;
    background-color: #eeeeee;
    background-image: url('../images/systemMessage.jpg');
    background-size: cover;
    border-radius: 12px;
    box-shadow: 0 0 0 1px rgb(0, 0, 0);
}

@media (min-width: 768px) {

    /* 对于宽度大于等于768px的设备 */
    .input-container {
        flex-direction: row;
    }

    .input-container textarea {
        flex: 1;
        height: auto;
        max-height: 80px;
    }

    .input-container button {
        width: 120px;
    }
}

.input {
    position: absolute;
    top: 10%;
    left: 51%;
    transform: translate(-50%, 0);
    padding: 60px;
    width: 100%;
    height: 60%;
}
.send {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>