<template>
    <div class="chat-container">
        <div class="left-chatmenu-parent">
            <div class="left-chatmenu">
                <div class="left-chatmenu-select-parent">
                    <div class="left-chatmenu-select" :class="value === '来自客服' ? 'left-chatmenu-select-on' : ''" @click="handleCommand('来自客服')">{{$t('ChatMain.service')}}</div>
                    <div class="left-chatmenu-select" :class="value === '来自用户' ? 'left-chatmenu-select-on' : ''" style="margin-left:30px;" @click="handleCommand('来自用户')">{{$t('ChatMain.user')}}</div>
                </div>
                <el-skeleton :rows="10" :loading="loading" animated></el-skeleton>
                <div class="left-menu-list">
                    <div
                        v-for="(apply, index) in chatThread"
                        :key="index" class="left-chatmenu-chatthread"
                        :style="(selectServeId === apply.serviceid || selectServeId === apply.threadid)? 'background-color: #F3F7FF' : ''"
                        @mouseover="changeCursor"
                        @click="changeServeId(apply)">
                        <div class="left-chatmenu-chatthread-head">
                            <img :src="apply.avatarUrl" style="height: 40px;width: 40px;"/>
                        </div>
                        <div class="left-chatmenu-chatthread-name">{{ apply.fromname }}</div>
                        <div class="left-chatmenu-chatthread-message"><span style="color:#165DFF;">{{ isUnRead(apply.fromid,apply.readstatus) }}</span>{{ apply.isUrl==='url'?$t('ChatMain.img'):apply.emailscontent }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-parent">
            <chatMessage :ThreadId="selectServeId" :messages="messages" :youravatarUrl="avatarUrl" :sale="sale" :type="value" ref="mychild"></chatMessage>
        </div>
    </div>
</template>

<script>
import chatMessage from "../companyUsers/ChatCustomer.vue";
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            userid: (state) => state.developer.userInfo.businessid,
        }),
    },
    components: {
        chatMessage,
    },
    created() {
        if (localStorage.getItem("developer-message")) {
            this.value = localStorage.getItem("developer-message")
        }
    },
    mounted() {
        this.refreshMenu();
        this.timer = setInterval(() => {  // 等待 100 毫秒后再绘制
            this.refreshMenu();
        }, 60000); // 每分钟进行一次
    },
    data() {
        return {
            loading: true, // 骨架屏
            timer: null, // 定时器
            selectServeId: null, // 选中的服务id
            example: [{
                id: 6175,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "你好，客服",
                createdate: "2023-08-23T16:00:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            }],
            chatThread: [], // 聊天主题，后端获取，客服需要获取多个客户发来的主题并进行回复，这里截取最新一条消息
            messages: null, // 确定主题id后向后端查询获取
            avatarUrl: null, // 当前主题id下对方用户的头像
            sale: null, // 当前主题id下的商品信息
            value: '来自用户', // 消息类型，下拉菜单选中
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        refreshMenu() {
            if (this.value === '来自用户') {
                this.$http.get('/v1/mail/select-threadid', { // 获取普通消息列表
                    withCredentials: true,
                }).then(res => {
                    this.chatThread = res.data.data;
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                })
            } else if (this.value === '来自客服') {
                this.$http.get('/v1/csmail/csselect-message', { // 获取客服消息列表
                    withCredentials: true,
                }).then(res => {
                    this.chatThread = res.data.data;
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        changeServeId(apply) { // 选中该位置
            if (this.value === '来自用户') {
                if (this.selectServeId !== apply.threadid) {
                    this.selectServeId = apply.threadid;
                } else {
                    return;
                }
            } else if (this.value === '来自客服') {
                if (this.selectServeId !== apply.serviceid) {
                    this.selectServeId = apply.serviceid;
                } else {
                    return;
                }
            }
            this.refreshMessage();
        },
        refreshMessage() {
            if (this.selectServeId) {
                if (this.value === '来自用户') {
                    this.$http.get('/v1/mail/select-message?threadid=' + this.selectServeId)
                    .then(res => {
                        const data = res.data.data;
                        this.messages = res.data.data;
                        // this.avatarUrl = URL.createObjectURL(res.data.msg); // todo这个没有对方头像
                        if (data[0].saleid && data[0].saleid !== '') { this.getSale(res.data.data[0].saleid); } // 来自用户的一般有商品信息
                        this.$refs.mychild.goBottom();
                        this.$refs.mychild.clearInput();
                        this.$store.dispatch("developer/refreshMessages");
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatMain.alertMessageFail'));
                    });
                } else if (this.value === '来自客服') {
                    this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + this.selectServeId)
                    .then(res => {
                        this.messages = res.data.data;
                        this.avatarUrl = res.data.msg;
                        this.$refs.mychild.goBottom();
                        this.$refs.mychild.clearInput();
                        this.$store.dispatch("developer/refreshMessages");
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatMain.alertMessageFail'));
                    });
                }
            }
        },
        getSale(saleid) {
            this.$http.get('/v1/mail/select-onsale-by-saleid?saleid=' + saleid)
                .then(res => {
                    this.sale = res.data.data;
                }).catch(error => {
                    console.error(this.$t('ChatMain.alertMergeFail'));
                });
        },
        handleCommand(command) {
            if (this.value === command) {
                return;
            }
            this.sale = null;
            this.chatThread = [];
            this.messages = null;
            this.selectServeId = null;
            this.system = null;
            this.value = command;
            localStorage.setItem("developer-message", this.value);
            this.loading = true;
            this.refreshMenu();
        },
        isUnRead(fromid,unread) {
            // unread参数，true是已读，false是未读，换算到未读提醒，未读要提示'[未读]'，已读设置空''
            if (this.userid !== fromid && !unread) { // 我是发送方，该条消息一定是已读，我是接收方，需要根据unread参数判断
                return this.$t('ChatMain.unread');
            } else {
                return '';
            }
        },
    },
    /* 生命周期结束时清除计时器 */
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
}
</script>

<style scoped>
.chat-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(242,248,255);
}
.left-chatmenu-parent {
    height: 100%;
    width: 360px;
    padding: 30px;
}
/* 这是上面那两个选客服消息和用户消息的样式 */
.left-chatmenu-select-parent {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.left-chatmenu-select {
    width: 105px;
    height: 34px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.0392);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.left-chatmenu-select-on {
    background: linear-gradient(286deg, #165DFF 6%, #56C2FF 90%);
    color: #FFF;
}
/* 这是下面选择主题的样式 */
.left-chatmenu {
    height: 100%;
    width: 300px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 12px;
}
.left-menu-list {
    margin-top: 20px;
    flex-grow: 1;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.left-chatmenu-chatthread {
    height: 70px;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
}
.left-chatmenu-chatthread-head {
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 50%;
}
.left-chatmenu-chatthread-name {
    position: absolute;
    left: 66px;
    top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.left-chatmenu-chatthread-message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 66px;
    top: 36px;
    max-width: 150px;
    font-size: 12px;
    color: #86909C;
    word-wrap: break-word;
}
.right-parent {
    flex-grow: 1;
    padding: 30px;
    padding-left: 0;
}
/* 更新后这部分没用了 */
.left-chatmenu-chatthread-time {
    position: absolute;
    right: 10px;
    top: 40px;
    font-size: 16px;
}
.left-chatmenu-chatthread-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
.left-chatmenu-chatthread-icon-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}



.el-dropdown-menu {
    text-align: center;
    width: 400px;
}
</style>