<template>
    <div class="main-container" style="justify-content: center;" ref="mainContainer">
        <div class="confirm-box">
            <div class="normal-card-title">{{$t('all.myCart')}}</div>
            <div class="order-window" style="margin-top: 16px;" v-if="LandInfor.length > 0">
                <div class="window-combin">
                    <!-- 商品信息 -->
                    <div class="window-combin-merch">{{ $t('PcContinueWindow.merchinfor') }}</div>
                    <!-- 单位土地个数 -->
                    <div class="window-combin-id">{{$t('all.unitNum')}}</div>
                    <!-- 价格 -->
                    <div class="window-combin-salemethod">{{$t('all.goodprice')}}</div>
                    <!-- 操作 -->
                    <div class="window-combin-price">{{ $t('all.control') }}</div>
                </div>

                <div class="window-infor" v-for="(separateOrders, index) in LandInfor" :key="index" @click="turnToDetail(separateOrders)">
                    <!-- 多选框 -->
                    <div class="h-full flex items-center" style="margin-left:10px;">
                        <van-checkbox icon-size="20px" class="order-select" v-model="selectMerchCheck[index]" @change="checkBoxChange(index,separateOrders.id)"></van-checkbox>
                    </div>
                    <!-- 土地图片 -->
                    <div class="window-infor-picture">
                        <img :src="separateOrders.originalLand.landpicture">
                    </div>
                    <!-- 土地信息 -->
                    <div class="window-infor-box">
                        <span class="window-infor-name">{{ separateOrders.landname }}</span>
                        <span class="window-infor-address">{{ separateOrders.originalLand.originallandname }}</span>
                        <span class="window-infor-tag">{{ separateOrders.onSale.communitynumber }}</span>
                    </div>
                    <!-- 分割线 -->
                    <div class="window-devider"></div>
                    <!-- 单位土地个数 -->
                    <div class="window-infor-landnum">{{ separateOrders.blocks }}</div>
                    <!-- 分割线 -->
                    <div class="window-devider"></div>
                    <!-- 状态 -->
                    <div class="window-infor-salemethod">{{ separateOrders.totalprice }}</div>
                    <!-- 分割线 -->
                    <div class="window-devider"></div>
                    <!-- 商品价格 -->
                    <div class="window-infor-price">
                        <span style="color:#FA5151;" @click="deleteLandCart(separateOrders.id)">{{ $t('ShopCart.delete') }}</span>
                    </div>
                </div>
            </div>

            <div class="flex-grow flex flex-col justify-center items-center" v-else>
                <img src="../mImage/cart-empty.png">
                <span class="shop-cart-empty">{{ $t('ShopCart.description') }}</span>
            </div>
        </div>

        <!-- 底部总金额 -->
        <div class="price-box">
            <div class="price-merchnum">
                {{$t('PcConfirmOrder.total1')}}
                <span class="mx-2">{{ LandInfor.length }}</span>
                {{$t('PcConfirmOrder.total2')}}{{','}}{{$t('all.selected')}}
                <span class="mx-2">{{ selectMerch.length }}</span>
                {{$t('PcConfirmOrder.total2')}}
            </div>
            <span class="price-total">{{$t('ShopCart.total')}}:</span>
            <span class="price-total-number">{{ $t('ShopCartInfor.price') + totalPrice }}</span>
            <div class="flex-grow flex justify-end" v-if="LandInfor.length > 0">
                <button class="infor1-button infor1-button-whiteground mr-5" @click="showDeleteModal(true)">{{$t('all.clearCart')}}</button>
                <button class="infor1-button infor1-button-redground mr-5" @click="showDeleteModal(false)">{{$t('all.batchDelete')}}</button>
                <button class="infor1-button infor1-button-blueground" @click="onSubmit">{{$t('MobileConfirmOrder.submitOrder')}}</button>
            </div>
        </div>

        <!-- 弹窗1 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box">
                <img src="../adminImage/admin-manage-modal.png" style="height:38px;width:38px;">
                <span class="shop-cart-modal-text">{{ $t('all.确定删除该商品') }}</span>
                <div class="flex flex-row">
                    <button class="infor1-button infor1-button-blueground" @click="vmodal=false;">{{ $t('mobileForgetPassword.no') }}</button>
                    <button class="infor1-button infor1-button-grayground" style="margin-left: 16px;" @click="deleteShopCart">{{ $t('mobileForgetPassword.yes') }}</button>
                </div>
            </div>
        </div>

        <!-- 弹窗2 -->
        <div class="developer-common-modal" v-if="bmodal">
            <div class="developer-common-modal-box" v-if="showError">
                <img src="../adminImage/admin-manage-modal.png" style="height:38px;width:38px;">
                <span class="shop-cart-modal-text">{{ $t('all.networkError') }}</span>
                <div class="flex flex-row">
                    <button class="infor1-button infor1-button-blueground" @click="bmodal=false;">{{ $t('mobileForgetPassword.yes') }}</button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <img src="../developer/step-accept.png" style="height:38px;width:38px;">
                <span class="shop-cart-modal-text">{{ $t('all.成功删除该商品') }}</span>
                <div class="flex flex-row">
                    <button class="infor1-button infor1-button-grayground" @click="bmodal=false;">{{ $t('all.关闭') }}</button>
                    <button class="infor1-button infor1-button-blueground" style="margin-left: 16px;" @click="$router.push('/user/land-market')">{{ $t('all.前往土地市场') }}</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    mounted() {
        this.controlWindow();
        window.addEventListener('resize', this.controlWindow);
        this.$http
            .get("/v1/shoppingcart/get-landcart")
            .then((res) => {
                this.LandInfor = res.data.data;
                this.loading = false;
                this.resetSelect();
            })
            .catch((error) => {
                console.log(error);
            });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.controlWindow);
        if (this.toastLoading) {
            this.toastLoading.clear();
        }
    },
    data() {
        return {
            totalPrice: 0, // 页面url传过来的总金额
            LandInfor: [], // 页面url传过来的商品列表
            selectMerch: [], // 选中的要删除的商品
            selectMerchCheck: [], // 多选框
            vmodal: false, // 询问用户弹窗
            example: [{
            "id": 257,
            "userid": "00e3f6867e364662bb02475f6fb7b84f",
            "unitlandid": "7161",
            "originallandid": "W355",
            "landname": "大图小镇 (47)",
            "unitprice": 1080.000,
            "blocks": 9,
            "totalprice": 1080.000,
            "unitpicture": "/img/unitland/map9.jpg",
            "createtime": "2024-04-09 10:29:16",
            "originalLand": {
                "id": 110,
                "originallandid": "W355",
                "ownershipnumber": "10002000",
                "originallandname": "大图小镇",
                "detailedaddress": "青岛市",
                "landcertificate": "/img/land/business/e0f96d7e595f48a7a27fd191255d1508.pdf",
                "warrantydeed": null,
                "ownerid": "ad73795b81d74dacb72f280109143cae",
                "entrance": "西",
                "geographicalcoordinates": "193.20,138.231",
                "landlength": "100",
                "landwidth": "80",
                "landarea": "20",
                "height": "8000",
                "digitalright": "出售数字权",
                "surfaceright": "出售地表权",
                "landpicture": "/img/land/business/cbc69a3fd1f9411383653d11bfbb39e2.png",
                "createdate": "2024-03-06 11:15:55",
                "landstatus": "已上线",
                "applicationtype": "上传原始土地",
                "reasonrejection": null,
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0,
                "userBusiness": null
            },
            "onSale": {
                "id": 188,
                "originallandid": "W355",
                "unitlandid": "7161",
                "landname": "大图小镇 (47)",
                "commodityname": null,
                "communitynumber": "区(37,21)",
                "sellerid": "ad73795b81d74dacb72f280109143cae",
                "seller": "开发商",
                "salesmethods": "立即购买",
                "expirydate": null,
                "unitlandprice": null,
                "pricenum": 1080.00,
                "unitlandminprice": null,
                "minimumprice": null,
                "blocks": 9,
                "totalprice": 1080.000,
                "unitpicture": "/img/unitland/map9.jpg",
                "updatetime": null,
                "createtime": "2024-04-09T10:29:16",
                "reasondelisting": null,
                "status": "已挂牌",
                "remark": null,
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0,
                "unitlandids": null,
                "code": null,
                "originalLand": null,
                "unitLand": null,
                "userBusiness": null
            }}],

            deleteShopCartCount: 9999,
            deleteShopCartResultCount: 0,
            showError: false, // 是否显示异常
            toastLoading: null,
            bmodal: false, // 显示结果弹窗
        };
    },
    // 监听删除商品获得后端回复的计数，进行提示
    watch: {
        deleteShopCartResultCount(n) {
            if (n >= this.deleteShopCartCount) {
                this.deleteShopCartCount = 9999;
                this.deleteShopCartResultCount = 0;
                this.resetSelect();
                this.toastLoading.clear();
                this.bmodal = true;
            }
        }
    },
    methods: {
        // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1300 || window.innerHeight <= 850) {
                const scaleX = (window.innerWidth - 100) / 1200;
                const scaleY = (window.innerHeight - 8) / 842;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
                this.$refs.mainContainer.style.height = 842 + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = `100%`;
            }
        },
        // 多选框切换事件
        checkBoxChange(index,id) {
            if (this.selectMerchCheck[index]) {
                this.totalPrice += this.LandInfor[index].totalprice;
                this.selectMerch.push(id);
            } else {
                this.totalPrice -= this.LandInfor[index].totalprice;
                this.selectMerch = this.selectMerch.filter(item => item !== this.LandInfor[index].id);
            }
        },
        // 批量删除或清空购物车
        showDeleteModal(v) {
            if (v) {
                for (let i = 0;i < this.selectMerchCheck.length; i++) {
                    this.selectMerchCheck[i] = true;
                }
            }
            this.vmodal = true;
        },
        // 删除商品弹窗确认按钮事件
        deleteShopCart() {
            this.showError = false;
            this.deleteShopCartCount = this.selectMerch.length;
            this.deleteShopCartResultCount = 0;
            this.toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('userhome.alertSending'),
                forbidClick: true,
            });
            for (let i = 0;i < this.selectMerchCheck.length; i++) {
                if (this.selectMerchCheck[i]) {
                    this.deleteLandCart(this.selectMerch[i]);
                }
            }
        },
        // 将商品从购物车中删除
        deleteLandCart(id) {
            this.$http
                .delete("/v1/shoppingcart/delete-landcart?id=" + id)
                .then((res) => {
                    this.deleteShopCartResultCount += 1;
                    const index = this.LandInfor.findIndex(item => item.id === id);
                    this.totalPrice -= this.LandInfor[index].totalprice;
                    this.LandInfor.splice(index,1);
                })
                .catch((error) => {
                    this.deleteShopCartResultCount += 1;
                    this.showError = true;
                });
        },
        // 重置或全选商品
        resetSelect() {
            this.selectMerch = [];
            this.selectMerchCheck = [];
            this.totalPrice = 0;
            for (let i = 0; i < this.LandInfor.length; i++) {
                this.selectMerchCheck.push(true);
                this.selectMerch.push(this.LandInfor[i].id);
                this.totalPrice += Number(this.LandInfor[i].totalprice);
            }
        },

        // 结算购物车
        onSubmit() {
            const landinfor = [];
            for (let i =0; i < this.LandInfor.length; i++) {
                if (this.selectMerchCheck[i]) {
                    landinfor.push(this.LandInfor[i]);
                }
            }
            this.$router.push({
                path: "/user/confirm-order",
                name: "UserPcConfirmOrder",
                query: {
                    LandInfor: JSON.stringify(landinfor),
                    totalPrice: JSON.stringify(this.totalPrice),
                },
            });
        },
        // 查看详情
        turnToDetail(LandInfor) {
            this.$router.push({
                path: '/user/land-market/detail',
                query: {
                    land: LandInfor.originallandid,
                    merch: LandInfor.id
                }
            });
        },
    },
}
</script>

<style scoped>
.confirm-box {
    height: 737px;
    max-height: calc(100% - 340px);
    width: 1200px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #FFFFFF;
    overflow-y: auto;
    overflow-x: hidden;
}

/* 商品图鉴区 */
.window-infor {
    box-sizing: border-box;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    margin-top: 16px;
    max-height: calc(100% - 32px);
    overflow-y: auto;
    overflow-x: hidden;
}
.window-infor-box {
    width: 262px;
}
.shop-cart-empty {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #22292E;
}

/* 弹窗 */
.shop-cart-modal-text {
    font-size: 18px;
    color: #3D3D3D;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (max-width: 1300px) or (max-height: 952px) {
    .main-container {
        transform-origin: top center;
    }
    .confirm-box {
        width: 100%;
        max-height: 100%;
    }
    .price-box {
        width: 100%;
    }
}
</style>