<template>
  <div id="app" class="bg-gray-50">
    <!-- 路由占位符 -->
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        gap_time: 0,
        beforeUnload_time: 0,
      };
    },
    methods: {
      // 关闭窗口之前执行
      beforeunloadHandler() {
        this.beforeUnload_time = new Date().getTime();
      },
      unloadHandler() {
        this.gap_time = new Date().getTime() - this.beforeUnload_time;
        //判断是窗口关闭还是刷新 毫秒数判断
        if (this.gap_time <= 5) {
          localStorage.removeItem("vuex");
          localStorage.removeItem("admin-order");
          localStorage.removeItem("admin-sell");
          localStorage.removeItem("admin-unitland");
          localStorage.removeItem("admin-user");
          localStorage.removeItem("admin-index");
          localStorage.removeItem("developer-index");
          localStorage.removeItem("user-menu");
          localStorage.removeItem("user-fmenu");
          this.$http.get("/v1/index/exit").then((response) => {}).catch((error) => {}); //退出登录接口
        } else {}
      },
    },
    destroyed() {
      // 移除监听
      window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
      window.removeEventListener("unload", () => this.unloadHandler());
    },
    mounted() {
      // 监听浏览器关闭
      window.addEventListener("beforeunload", () => this.beforeunloadHandler());
      window.addEventListener("unload", () => this.unloadHandler());
    },
  }
</script>


<style lang="scss">
#app,html,body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #fff;
}
</style>

<style lang="css">
   /* 在style标签中引入global.css */
   @import '@/assets/global.css';
</style>