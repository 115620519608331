import Vue from "vue";
import VueRouter from "vue-router";

// 主页
import HomePage from "../views/Login.vue";
// 手机端有了登陆前的首页，需要从首页进入注册/登陆页
import UserMobileLogin from "../views/MobileLogin.vue";
// pc端登录页面
import ComputerLogin from "../views/ComputerLogin";
// 邮箱验证
import EmailVerification from "../components/EmailVerification.vue";
// 手机端修改密码页面
import MobileForgetPassword from "../views/MobileForgetPassword.vue";

// 管理员登录界面
import AdminLogin from "../admin/AdminLogin.vue";
// 管理员界面
import Admin from "../admin/Administrator.vue";
// 管理员处理用户上传土地申请页面
import AdminApply from "../admin/LandManage.vue";
// 管理员处理用户上传土地申请详情页面
import AdminApplyDetail from "../admin/LandReview.vue";
// 管理员处理用户上传企业认证页面
import AdminCompany from "../admin/CompanyManage.vue";
// 管理员处理用户上传企业认证详情页面
import AdminCompanyApply from "../admin/CompanyApply.vue";
// 管理员查看曾经驳回的企业认证信息详情页面
import AdminCompanyRefund from "../admin/CompanyRefund.vue";
// 超级管理员注册新企业用户
import AdminCompanyAdd from "../admin/CompanyAdd.vue";
// 管理员用户管理-封禁账户
import AdminUserManage from "../admin/UserManage.vue";
// 管理员用户管理-查看详情-企业用户
import AdminUserDeveloperDetail from "../admin/UserDeveloperDetail.vue";
// 管理员用户管理-查看详情-普通用户
import AdminUserNormalDetail from "../admin/UserNormalDetail.vue";
// 管理员挂牌管理
import AdminSellManage from "../admin/SellManage.vue";
// 管理员挂牌管理详情页面
import AdminSellDetail from "../admin/SellDetail.vue";
// 管理员订单列表页面
import AdminOrderManage from "../admin/OrderManage.vue";
// 管理员退款审核列表页面
import AdminRefund from "../admin/RefundCheck.vue";
// 管理员退款审核列表详情页面
import AdminRefundDetail from "../admin/RefundCheckDetail.vue";
// 管理员订单详情页面
import AdminOrderDetail from "../admin/OrderDetail.vue";
// 管理员客服页面
import AdminChat from "../admin/ChatMain.vue";
// 管理员个人信息页面
import AdminInfor from "../admin/MyInfor.vue";
// 管理员订单列表页面
import AdminChatManage from "../admin/ChatManage.vue";
// 管理员操作日志页面
import AdminOperationLog from "../admin/OperationLog.vue";
// 管理员分块土地列表页面
import AdminUnitlandManage from "../admin/UnitLandManage.vue";
// 管理员分块土地详情页面
import AdminUnitlandDetail from "../admin/UnitLandDetail.vue";
// 管理员分块土地合并分割页面
import AdminUnitlandOperate from "../admin/UnitLandOperate.vue";
// 管理员平台管理页面
import AdminManage from "../admin/AdminManage.vue";
// 管理员平台管理页面
import AdminAdd from "../admin/AdminAdd.vue";
// 超级管理员查看所有管理员操作日志页面
import AdminSuperOperationLog from "../admin/SuperOperationLog.vue";
// 管理员发送系统消息页面
import AdminSystemMessage from "../admin/Announcement.vue";

// 开发商用户界面
import Home from "../companyUsers/UserHome.vue";
// 用户账户信息界面
import Account from "../companyUsers/UserAccount.vue";
// 企业用户第一次登录进行注册
import CompanyUserRegister from "../companyUsers/CompanyRegister.vue";
// 企业用户查看原始土地详情
import CompanyLandDetail from "../companyUsers/OriginalLandDetail.vue";
// 企业用户查看上传的原始土地凭证
import RealEstate from "../companyUsers/LandRegister.vue";
// 企业用户查看原始土地收益分析
import CompanyIncome from "../companyUsers/OrderView.vue";
// 企业用户查看公司信息
import CompanyInfor from "../companyUsers/MyCompany.vue";
// 企业用户查看挂牌土地信息
import SelleLandList from "../companyUsers/LandSellList.vue";
// 企业用户查看挂牌土地详情信息
import SelleLandDetail from "../companyUsers/LandSellDetail.vue";
// 企业用户查看聊天消息
import CompanyChatMessage from "../companyUsers/ChatMain.vue";
// 企业用户联系购买用户发送消息
import CompanyChatBuyer from "../companyUsers/ChatCustomer.vue";
// 企业用户联系客服
import CompanyChatService from "../companyUsers/ChatService.vue";
// 企业用户账号与安全
import CompanyAccountSafe from "../companyUsers/AccountSafe.vue";
// 企业用户查看总收益（主页-详细月报）
import CompanyTotalIncome from "../companyUsers/TotalIncome.vue";
// 企业用户查看过往交易历史
import CompanyOrderList from "../companyUsers/OrderList.vue";

// 普通用户手机端页面
import UserMobile from "../mobile/MobileHome.vue";
// 普通用户手机端页面主页
import UserMobileHome from "../mobile/HomePage.vue";
// 普通用户手机端页面土地市场
import UserMobileShop from "../mobile/MobileShop.vue";
// 普通用户手机端页面土地详情
import UserMobileDetail from "../mobile/MobileLandDetail.vue";
// 普通用户手机端页面卖家信息
import UserMobileSeller from "../mobile/SellerHome.vue";
// 普通用户手机端页面联系卖家发邮件
import MobileSendEmail from "../mobile/MobileEmailSend.vue";
// 普通用户手机端页面购物车
import UserMobileCart from "../mobile/ShopCart.vue";
// 普通用户手机端页面购物车结算MobileConfirmOrder
import MobileConfirmOrder from "../mobile/MobileConfirmOrder.vue";
// 普通用户手机端页面我的信息
import UserMobileInfor from "../mobile/Infor.vue";
// 普通用户手机端页面买家卖家联系信息列表
import MobileMessage from "../mobile/MobileMessage.vue";
// 普通用户手机端页面买家卖家联系信息详情
import MobileMessageWindow from "../mobile/MobileMessageWindow.vue";
// 普通用户手机端页面联系客服
import MobileService from "../mobile/MobileService.vue";
// 普通用户手机端页面联系客服反馈意见
import MobileServiceSend from "../mobile/MobileServiceQ.vue";
// 普通用户手机端页面联系客服查看消息
import MobileServiceSearch from "../mobile/MobileServiceA.vue";
// 普通用户手机端页面查看我的土地
import MobileMyLand from "../mobile/MobileMyLand.vue";
// 普通用户手机端页面查看我的土地详情
import MobileMyLandDetail from "../mobile/MobileMyLandDetail.vue";
// 普通用户手机端页面查看购买的土地详情
import MobilePurchaseDetails from "@/mobile/MobilePurchaseDetails";
// 普通用户手机端页面修改信息
import MobileUserEditInfo from "../mobile/MobileUserEditInfo.vue";
// 普通用户手机端页面我的钱包
import MobileMyWallet from "@/mobile/MobileMyWallet";
// 普通用户手机端页面地址管理
import MobileAddressManagement from "@/mobile/MobileAddressWindow";
// 普通用户手机端充值页面
import MobileRecharge from "@/mobile/MobileRecharge";
// 普通用户手机端账号与安全页面
import MobileAccountAndSafe from "@/mobile/MobileSafe";
// 普通用户手机端修改密码页面
import MobileChangePassword from "@/mobile/MobileChangePassword";
// 普通用户手机端修改支付密码页面
import MobileChangePayPassword from "@/mobile/MobileChangePayPassword";
// 普通用户手机端账号绑定页面
import MobileAssociatedSet from "@/mobile/MobileAssociatedSet";
// 普通用户手机端账号换绑页面
import MobileAssociatedReplace from "@/mobile/MobileAssociatedReplace";
// 普通用户手机端我的订单页面
import MobileOrderList from "@/mobile/MobileOrderList";
// 普通用户手机端我的订单详情页面
import MobileOrderDetail from "@/mobile/MobileOrderDetail";
// 普通用户手机端退款进度查看页面
import MobileRefundView from "@/mobile/MobileRefundDetail";
// 普通用户手机端使用帮助页面
import MobileHelp from "@/mobile/MobileHelp";
// 普通用户手机端支付成功页和支付失败页
import MobilePaySuccessful from "@/pay/paySuccess";
import MobilePayFail from "@/pay/payFail";
// 地契页
import MobileTitleDeed from "@/mobile/MobileTitleDeed";
// 继续支付页
import MobileOrderContinue from "@/mobile/MobileOrderContinue";
// 设置页
import MobileSetting from "@/mobile/MobileSetting";


// 操作土地合并分割的基础页面
import Operation from "../landmap/LandOperate.vue";
// 原始土地界面
import LandMap from "../landmap/LandMap1.vue";
// 三号土地(22英尺x22英尺)界面
import LandMap3 from "../landmap/LandMap3.vue";

// 普通用户页面
import UserPcHead from '../normalUsers/UserMenu.vue'
// 普通用户信息页面
import UserPcHomePage from '../normalUsers/HomePage.vue'
// 普通用户土地市场页面
import UserPcLandMarket from '../normalUsers/LandShop.vue'
// 土地详情页面
import UserPcLandDetail from '../normalUsers/LandDetail.vue'
// 个人资料页面
import UserPcAccount from '../normalUsers/AccountSafe.vue'
// 个人资产菜单页面
import UserPcFinance from '../normalUsers/FinanceMenu.vue'
// 个人资产首页页面
import UserPcFinanceHome from '../normalUsers/FinanceHome.vue'
// 个人资产-我的土地列表页面
import UserPcFinanceLandList from '../normalUsers/MyLandList.vue'
// 个人资产-我的土地详情页面
import UserPcFinanceLandDetail from '../normalUsers/MyLandDetail.vue'
// 个人资产-我的订单页面
import UserPcFinanceOrderList from '../normalUsers/OrderList.vue'
// 个人资产-收益页面
import UserPcFinanceReport from '../normalUsers/FinanceReport.vue'
// 充值页面
import UserPcTopUp from '../normalUsers/TopUpContainer.vue'
// 确认订单页面
import UserPcConfirmOrder from '../normalUsers/ConfirmOrder.vue'
// 继续支付页面
import UserPcContinue from '../normalUsers/ContinueOrder.vue'
// 我的订单详情页面
import UserPcOrderDetail from '../normalUsers/OrderDetail.vue'
// 卖家主页页面
import UserPcSellerHome from '../normalUsers/SellerHome.vue'
// 联系卖家页面
import UserPcTalkSeller from '../normalUsers/ChatSeller.vue'
// 退款详情页面
import UserPcRefundDetail from '../normalUsers/RefundDetail.vue'
// 普通用户操作土地合并分割的基础页面
import UserPcOperation from "../normalUsers/LandOperate.vue";
// 普通用户原始土地界面
import UserPcLandMap from "../normalUsers/LandMap1.vue";
// 普通用户三号土地(22英尺x22英尺)界面
import UserPcLandMap3 from "../normalUsers/LandMap3.vue";
// 普通用户设置页面
import UserPcSetting from '../normalUsers/Setting.vue'
// 普通用户购物车页面
import UserPcShopCart from '../normalUsers/ShopCart.vue'

// 二期页面测试
import v2Test from "../v2/gamemap.vue";
// 第一个活动页面
import activity1 from '../activity/userActivity1.vue'
// 第一个活动页面
import mobileactivity1 from '../activity/userMobileActivity1.vue'

Vue.use(VueRouter);

const routes = [
    /* 活动1 */
    {
        path: '/activity/1',
        name: 'activity1',
        component: activity1,
    },
    {
        path: '/mobile/activity/1',
        name: 'mobileactivity1',
        component: mobileactivity1,
    },
    {
        // 输入网站时首先重定向到登录页面
        path: "/",
        name: "HomePage",
        component: HomePage
    },
    {
        // 普通用户页面
        path: '/user',
        name: 'UserPcHead',
        component: UserPcHead,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: '/user/home-page',
        /* 主页子页面 */
        children: [
            /* 普通用户首页 */
            {
                path: '/user/home-page',
                name: 'UserPcHomePage',
                component: UserPcHomePage,
            },
            /* 普通用户土地市场页面 */
            {
                path: '/user/land-market',
                component: UserPcLandMarket,
                meta: {
                    role: 'user',
                },
            },
            /* 土地详情 */
            {
                path: '/user/land-market/detail',
                name: 'LandDetail',
                component: UserPcLandDetail,
                props: (route) => ({
                    originallandid: route.params.originallandid,
                    unitlandid: route.params.unitlandid,
                }),
                meta: {
                    role: 'user',
                },
            },
            // 购物车页面
            {
                path: '/user/shop-cart',
                name: 'UserPcShopCart',
                component: UserPcShopCart,
                meta: {
                    role: 'user',
                },
            },
            // 卖家页面
            {
                path: '/user/seller-home',
                name: 'UserPcSellerHome',
                component: UserPcSellerHome,
                meta: {
                    role: 'user',
                },
            },
            // 联系卖家页面
            {
                path: '/user/seller-chat',
                name: 'UserPcTalkSeller',
                component: UserPcTalkSeller,
                meta: {
                    role: 'user',
                },
            },
            // 个人资料
            {
                path: '/user/account',
                name: 'UserPcAccount',
                component: UserPcAccount,
                meta: {
                    role: 'user',
                },
            },
            // 设置
            {
                path: '/user/settings',
                name: 'UserPcSetting',
                component: UserPcSetting,
                meta: {
                    role: 'user',
                },
            },
            // 充值页面
            {
                path: '/user/top-up',
                name: 'UserPcTopUp',
                component: UserPcTopUp,
                meta: {
                    role: 'user',
                },
            },
            // 确认订单页面
            {
                path: '/user/confirm-order',
                name: 'UserPcConfirmOrder',
                component: UserPcConfirmOrder,
                meta: {
                    role: 'user',
                },
            },
            // 继续支付页面
            {
                path: '/user/continue-payment',
                name: 'UserPcContinue',
                component: UserPcContinue,
                meta: {
                    role: 'user',
                },
            },
            // 订单详情页面
            {
                path: '/user/my-order/detail',
                name: 'UserPcOrderDetail',
                component: UserPcOrderDetail,
                meta: {
                    role: 'user',
                },
            },
            // 退款详情页面
            {
                path: '/user/refund/detail',
                name: 'UserPcRefundDetail',
                component: UserPcRefundDetail,
                meta: {
                    role: 'user',
                },
            },
            // 个人财产
            {
                path: '/user/property',
                name: 'UserPcFinance',
                component: UserPcFinance,
                redirect: '/user/property/home',
                // 子页面的子页面:(
                children: [
                    // 财产首页
                    {
                        path: '/user/property/home',
                        name: 'UserPcFinanceHome',
                        component: UserPcFinanceHome,
                        meta: {
                            role: 'user',
                        },
                    },
                    // 我拥有的土地-列表
                    {
                        path: '/user/property/my-land',
                        name: 'UserPcFinanceLandList',
                        component: UserPcFinanceLandList,
                        meta: {
                            role: 'user',
                        },
                    },
                    // 我拥有的土地-详情
                    {
                        path: '/user/property/my-land/detail',
                        name: 'UserPcFinanceLandDetail',
                        component: UserPcFinanceLandDetail,
                        meta: {
                            role: 'user',
                        },
                    },
                    // 我的订单-列表
                    {
                        path: '/user/property/my-order',
                        name: 'UserPcFinanceOrderList',
                        component: UserPcFinanceOrderList,
                        meta: {
                            role: 'user',
                        },
                    },
                    // 我的收益
                    {
                        path: '/user/property/income',
                        name: 'UserPcFinanceReport',
                        component: UserPcFinanceReport,
                        meta: {
                            role: 'user',
                        },
                    },
                ],
            },
            // 普通用户合并分割大地图
            {
                path: '/user/land-operate',
                name: 'UserPcOperation',
                component: UserPcOperation,
                redirect: '/user/land-operate/big-map',
                // 子页面的子页面:(
                children: [
                    // 财产首页
                    {
                        path: '/user/land-operate/big-map',
                        name: 'UserPcLandMap',
                        component: UserPcLandMap,
                        meta: {
                            role: 'user',
                        },
                    },
                    // 我拥有的土地-列表
                    {
                        path: '/user/land-operate/area-map"',
                        name: 'UserPcLandMap3',
                        component: UserPcLandMap3,
                        meta: {
                            role: 'user',
                        },
                    },
                ],
            },
        ],
    },


    /* -----------------------------------------------------管理员-------------------------------------------------------- */
    {
        path: "/admin/login",
        name: "adminLogin",
        component: AdminLogin,
    },
    {
        // 管理员页面
        path: "/admin",
        name: "admin",
        component: Admin,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/admin/login",
        /* 主页子页面 */
        children: [
            { path: "/admin/my-infor", component: AdminInfor } /* 管理员个人信息页面 */,
            { path: "/admin/land-manage", component: AdminApply } /* 管理员处理用户上传土地申请页面 */,
            { path: "/admin/land-manage/detail", component: AdminApplyDetail } /* 管理员处理用户上传土地申请详情页面 */,
            { path: "/admin/company", component: AdminCompany } /* 管理员处理用户上传企业认证页面 */,
            { path: "/admin/company/detail", component: AdminCompanyApply } /* 管理员处理用户上传企业认证详情页面 */,
            { path: "/admin/company/detail-r", component: AdminCompanyRefund } /* 管理员查看曾经驳回的企业认证信息详情页面 */,
            { path: "/admin/company-register", component: AdminCompanyAdd } /* 超级管理员注册新企业用户 */,
            { path: "/admin/refund-manage", component: AdminRefund } /* 管理员退款审核 */,
            { path: "/admin/refund-manage/detail", component: AdminRefundDetail } /* 管理员退款审核详情页 */,
            { path: "/admin/chat-manage", component: AdminChatManage } /* 管理员聊天记录管理 */,
            { path: "/admin/chat", component: AdminChat } /* 客服聊天 */,
            { path: "/admin/operation", component: AdminOperationLog } /* 操作日志 */,
            { path: "/admin/super-operation", component: AdminSuperOperationLog } /* 超级管理员查看所有操作日志 */,
            { path: "/admin/unitland-manage/unitland", component: AdminUnitlandOperate } /* 分块土地合并分割 */,
            { path: "/admin/admin-manage", component: AdminManage } /* 管理员列表 */,
            { path: "/admin/admin-add", component: AdminAdd } /* 管理员新增 */,
            { path: "/admin/system-message", component: AdminSystemMessage } /* 管理员新增 */,
            
            { path: "/admin/user-manage", component: AdminUserManage, } /* 管理员处理用户账户封禁 */,
            { path: "/admin/user-manage/detail-d", component: AdminUserDeveloperDetail } /* 管理员用户管理详情页面-企业用户 */,
            { path: "/admin/user-manage/detail-n", component: AdminUserNormalDetail } /* 管理员用户管理详情页面-普通用户 */,
            { path: "/admin/sell-manage", component: AdminSellManage, } /* 管理员挂牌管理 */,
            { path: "/admin/sell-manage/detail", component: AdminSellDetail } /* 管理员挂牌管理查看挂牌详情页面 */,
            { path: "/admin/order-manage", component: AdminOrderManage, } /* 管理员订单查询 */,
            { path: "/admin/order-manage/detail", component: AdminOrderDetail } /* 管理员订单详情 */,
            { path: "/admin/unitland-manage", component: AdminUnitlandManage, } /* 分块土地列表 */,
            { path: "/admin/unitland-manage/detail", component: AdminUnitlandDetail } /* 分块土地详情 */,
        ],
    },


    /* -----------------------------------------------------企业用户-------------------------------------------------------- */
    {
        // 企业用户登录页面
        path: "/pc/login",
        name: "computerLogin",
        component: ComputerLogin,
    },
    {
        // 邮箱注册验证码链接处理
        // 当用户访问 /email/login/?token=your-token 这样的 URL 时，Vue Router 会渲染 EmailVerification 组件并处理激活链接。
        path: "/email/login/",
        name: "EmailVerification",
        component: EmailVerification,
    },
    {
        // 企业用户第一次登录注册信息页面
        path: "/login/register",
        name: "CompanyUserRegister",
        component: CompanyUserRegister,
    },
    {
        // 开发商用户页面
        path: "/developer",
        name: "UserHome",
        component: Home,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/developer/home-page",
        /* 主页子页面 */
        children: [
            /* 用户账户信息界面 */
            {
                path: "/developer/home-page",
                component: Account,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看原始土地详情，由此进入土地凭证页、收益分析页 */
            {
                path: "/developer/land-detail",
                component: CompanyLandDetail,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看上传的原始土地凭证 */
            {
                path: "/developer/land-detail/real-estate",
                name: 'developerLandView',
                component: RealEstate,
                props: true,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看收益 */
            {
                path: "/developer/land-detail/income",
                component: CompanyIncome,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看总收益（主页-详细月报） */
            {
                path: "/developer/total-income",
                component: CompanyTotalIncome,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看公司注册信息 */
            {
                path: "/developer/my-company",
                component: CompanyInfor,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看挂牌土地 */
            {
                path: "/developer/my-sale",
                component: SelleLandList,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看挂牌土地详情 */
            {
                path: "/developer/my-sale/detail",
                component: SelleLandDetail,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看消息 */
            {
                path: "/developer/message",
                component: CompanyChatMessage,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户联系购买用户发送消息 */
            {
                path: "/developer/message/send",
                component: CompanyChatBuyer,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户联系客服 */
            {
                path: "/developer/message/service",
                component: CompanyChatService,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户账号与安全 */
            {
                path: "/developer/account-safe",
                component: CompanyAccountSafe,
                meta: {
                    role: 'developer'
                },
            },
            /* 企业用户查看过往交易历史 */
            {
                path: "/developer/order-list",
                component: CompanyOrderList,
                meta: {
                    role: 'developer'
                },
            },
        ],
    },
    {
        /* 用户合并分割土地页面 */
        path: "/land-operate",
        name: "LandOperate",
        component: Operation,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/land-operate/big-map",
        /* 主页子页面 */
        children: [
            /* 土地地图 */
            {
                path: "/land-operate/big-map",
                name: "Originland",
                component: LandMap,
                props: (route) => ({ landid: route.params.landid, landName: route.params.landName }),
                meta: {
                    role: 'developer'
                },
            }, // 原始土地
            {
                path: "/land-operate/area-map",
                name: "Thirdland",
                component: LandMap3,
                props: (route) => ({
                    landid: route.params.landid,
                    landName: route.params.landName,
                    SecondLandCoordinates: route.params.SecondLandCoordinates,
                    ThirdLandCoordinates: route.params.ThirdLandCoordinates,
                }),
                meta: {
                    role: 'developer'
                },
            }, // 三号土地
        ],
    },


    /* -----------------------------------------------------普通用户手机端-------------------------------------------------------- */
    {
        // 普通用户手机端登录页面
        path: "/mobile/login",
        name: "mobileLogin",
        component: UserMobileLogin,
    },
    {
        // 普通用户手机端页面
        path: "/mobile",
        name: "usermobile",
        component: UserMobile,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/mobile/home-page",
        /* 主页子页面 */
        children: [
            /* ------------------------------------------第一部分：首页--------------------------------------------- */
            /* 普通用户手机端主页 */
            {
                path: "/mobile/home-page",
                name: 'UserMobileHome',
                component: UserMobileHome,
                meta: {
                    role: 'user' // 目前验证:该用户是否处于登录状态中
                },
            },
            /* ------------------------------------------第二部分：土地市场--------------------------------------------- */
            /* 普通用户手机端土地市场页面 */
            {
                path: "/mobile/land-market",
                name: 'LandMarket',
                component: UserMobileShop,
                props: (route) => ({ LandInfor: route.params.LandInfor }),
                meta: {
                    role: 'user' // 目前验证:该用户是否处于登录状态中
                },
            },
            
            /* ------------------------------------------第三部分：购物车--------------------------------------------- */
            /* 普通用户手机端购物车页面 */
            {
                path: "/mobile/shopping-cart",
                component: UserMobileCart,
                meta: {
                    role: 'user' // 目前验证:该用户是否处于登录状态中
                },
            },
            /* ------------------------------------------第四部分：我的--------------------------------------------- */
            /* 普通用户手机端我的信息页面 */
            {
                path: "/mobile/my-infor",
                component: UserMobileInfor,
                meta: {
                    role: 'user' // 目前验证:该用户是否处于登录状态中
                },
            },
        ],
    },
    /* 普通用户手机端查看消息列表页面 */
    {
        path: "/mobile/message",
        name: "mobilemessage",
        component: MobileMessage,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端查看消息主题消息页面 */
    {
        path: "/mobile/message/thread",
        name: "mobilemessagewindow",
        component: MobileMessageWindow,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端联系客服主页面 */
    {
        path: "/mobile/service",
        name: "mobileservice",
        component: MobileService,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端联系客服发送意见反馈页面 */
    {
        path: "/mobile/service/send",
        name: "mobileservicesend",
        component: MobileServiceSend,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端联系客服查看客服回复页面 */
    {
        path: "/mobile/service/reply",
        name: "mobileservicereply",
        component: MobileServiceSearch,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端查看我的土地页面 */
    {
        path: "/mobile/my-land",
        name: "mobilemyland",
        component: MobileMyLand,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端查看我的土地详情页面 */
    {
        path: "/mobile/my-land/detail",
        name: "mobilemylanddetail",
        component: MobileMyLandDetail,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        path: "/mobile/purchase-detail",
        name: "purchasedetail",
        component: MobilePurchaseDetails,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端土地详情页面 */
    {
        path: "/mobile/land/detail",
        name: "mobilelanddetail",
        component: UserMobileDetail,
        props: (route) => ({ sellerId: route.params.sellerId, LandInfor: route.params.LandInfor }),
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端查看卖家账户页面 */
    {
        path: "/mobile/seller-home",
        name: "mobilesellerhome",
        component: UserMobileSeller,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    /* 普通用户手机端联系卖家发送邮件页面 */
    {
        path: "/mobile/private-chat",
        name: "mobilesendemail",
        component: MobileSendEmail,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端用户个人信息页 */
        path: "/mobile/my-infor/detail",
        name: "mobileuserinfor",
        component: MobileUserEditInfo,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端用户钱包 */
        path: "/mobile/my-wallet",
        name: "mobilemywallet",
        component: MobileMyWallet,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端用户地址管理 */
        path: "/mobile/address-manage",
        name: "MobileAddressManagement",
        component: MobileAddressManagement,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端用户充值 */
        path: "/mobile/recharge",
        name: "MobileRecharge",
        component: MobileRecharge,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端用户确认订单 */
        path: "/mobile/confirm-order",
        name: "MobileConfirmOrder",
        component: MobileConfirmOrder,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端账号与安全页面 */
        path: "/mobile/account-safe",
        name: "MobileAccountAndSafe",
        component: MobileAccountAndSafe,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端修改密码页面 */
        path: "/mobile/change-password",
        name: "MobileChangePassword",
        component: MobileChangePassword,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
      /* 普通用户手机端修改支付密码页面 */
        path: "/mobile/change-password/pay",
        name: "MobileChangePayPassword",
        component: MobileChangePayPassword,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端账号绑定页面 */
        path: "/mobile/associate/set",
        name: "MobileAssociatedSet",
        component: MobileAssociatedSet,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端账号换绑页面 */
        path: "/mobile/associate/replace",
        name: "MobileAssociatedReplace",
        component: MobileAssociatedReplace,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端设置页面 */
        path: "/mobile/settings",
        name: "MobileSetting",
        component: MobileSetting,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端我的订单页面 */
        path: "/mobile/my-order",
        name: "MobileOrderList",
        component: MobileOrderList,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端我的订单详情页面 */
        path: "/mobile/my-order/detail",
        name: "MobileOrderDetail",
        component: MobileOrderDetail,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端退款进度查看页面 */
        path: "/mobile/my-order/refund",
        name: "MobileRefundView",
        component: MobileRefundView,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端使用帮助页面 */
        path: "/mobile/help",
        name: "MobileHelp",
        component: MobileHelp,
        meta: {
            role: 'user' // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端使用帮助页面 */
        path: "/mobile/pay-success",
        name: "MobilePaySuccessful",
        component: MobilePaySuccessful,
        meta: {
            role: '', // 目前验证:该用户是否处于登录状态中
        },
        beforeEnter: (to,from,next) => {
            if (to.name === 'LandMarket' || to.name === 'mobilemyland' || to.name === 'mobilelanddetail' || to.name === 'MobilePaySuccessful') {
                next();
            } else {
                next(false);
            }
        },
    },
    {
        /* 普通用户手机端使用帮助页面 */
        path: "/mobile/pay-fail",
        name: "MobilePayFail",
        component: MobilePayFail,
        meta: {
            role: 'user', // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端查看地契页面 */
        path: "/mobile/title-deed",
        name: "MobileTitleDeed",
        component: MobileTitleDeed,
        meta: {
            role: 'user', // 目前验证:该用户是否处于登录状态中
        },
    },
    {
        /* 普通用户手机端继续支付页面 */
        path: "/mobile/continue-payment",
        name: "MobileOrderContinue",
        component: MobileOrderContinue,
        meta: {
            role: 'user', // 目前验证:该用户是否处于登录状态中
        },
    },
];

const router = new VueRouter({
    mode: "history",
    // mode: "hash",
    base: process.env.BASE_URL,
    // history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
