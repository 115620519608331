<template>
    <div class="admin-detail-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>订单管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/order-manage');">订单信息查看</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">查看详情</span>
        </div>
        <div class="admin-detail-form-parent">
            <div class="admin-detail-form">
                <div class="admin-detail-form-topic">买家信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">买家ID:</span>
                            <span>{{ orderInfor.buyerid }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">购买方式:</span>
                            <span>{{ orderInfor.salemeans }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">收件人:</span>
                            <span>{{ orderInfor.address.consigneename }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">收货地址:</span>
                            <span>{{ orderInfor.address.province + orderInfor.address.city + orderInfor.address.area + orderInfor.address.detailedaddress }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">买家昵称:</span>
                            <span>{{ orderInfor.buyer }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">是否开具纸质证明:</span>
                            <span>{{ orderInfor.needpaper?'是':'否' }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">联系方式:</span>
                            <span>{{ orderInfor.buyercommunicate }}</span>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;">
                <div class="admin-detail-form-topic">卖家信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家ID:</span>
                            <span>{{ orderInfor.sellerid }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家手机号:</span>
                            <span>{{ orderInfor.sellercommunicate }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家昵称:</span>
                            <span>{{ orderInfor.seller }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家电子邮箱:</span>
                            <span>{{ orderInfor.sellercommunicate }}</span>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="flex flex-row" style="margin-top: 30px;" v-if="orderInfor.refundtime">
                <div class="admin-detail-form">
                    <div class="admin-detail-form-topic">订单信息</div>
                    <div class="admin-detail-form-full">
                        <div class="admin-detail-form-half">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">独立订单编号:</span>
                                <span>{{ orderInfor.separateOrderId }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">购买方式:</span>
                                <span>{{ orderInfor.salemeans }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">实付金额:</span>
                                <span>{{ orderInfor.totalprice }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">订单状态:</span>
                                <span>{{ orderInfor.orderstatus }}</span>
                            </el-row>
                        </div>
                        <div class="admin-detail-form-half">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">交易时间:</span>
                                <span>{{ orderInfor.ordertime? orderInfor.ordertime : '' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">支付方式:</span>
                                <span>{{ orderInfor.paymeans? orderInfor.paymeans : '' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">是否开具纸质证明:</span>
                                <span>{{ orderInfor.needpaper?'是':'否' }}</span>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="admin-detail-form" style="margin-left:20px;flex:1;">
                    <div class="admin-detail-form-topic">退款信息</div>
                    <div class="admin-detail-form-full" style="width:100%;">
                        <div class="admin-detail-form-half" style="width:33%">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">处理时间:</span>
                                <span>{{ orderInfor.refundtime }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">退款金额:</span>
                                <span>{{ orderInfor.totalprice }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">退款原因:</span>
                                <span>{{ orderInfor.refundreason }}</span>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;" v-else>
                <div class="admin-detail-form-topic">订单信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half" style="width:33%">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">独立订单编号:</span>
                            <span>{{ orderInfor.separateOrderId }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">购买方式:</span>
                            <span>{{ orderInfor.salemeans }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">实付金额:</span>
                            <span>{{ orderInfor.totalprice }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half" style="width:33%">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">交易时间:</span>
                            <span>{{ orderInfor.ordertime? orderInfor.ordertime : '' }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">支付方式:</span>
                            <span>{{ orderInfor.paymeans? orderInfor.paymeans : '' }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">是否开具纸质证明:</span>
                            <span>{{ orderInfor.needpaper?'是':'否' }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half" style="width:33%">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">订单状态:</span>
                            <span>{{ orderInfor.orderstatus }}</span>
                        </el-row>
                    </div>
                </div>
            </div>
            <!-- <div class="admin-detail-form" style="margin-top: 20px;">
                <div class="admin-detail-form-topic">原始土地信息</div>
                <div class="admin-detail-form-full" style="padding:30px;">
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">原始土地编号</span>
                        <span>{{ orderInfor.originalLand.originallandid }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">土地名称</span>
                        <span>{{ orderInfor.originalLand.originallandname? orderInfor.originalLand.originallandname : '' }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">地址</span>
                        <span>{{ orderInfor.originalLand.detailedaddress }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">开发商编号</span>
                        <span>{{ orderInfor.originalLand.ownerid }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">大门朝向</span>
                        <span>{{ orderInfor.originalLand.entrance? orderInfor.originalLand.entrance : '' }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">创建时间</span>
                        <span>{{ orderInfor.originalLand.createdate }}</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">原始土地长度</span>
                        <span>{{ orderInfor.originalLand.landlength }}英尺</span>
                    </el-row>
                    <el-row class="text-center">
                        <span class="admin-detail-form-item-label" style="margin-bottom:10px;">原始土地宽度</span>
                        <span>{{ orderInfor.originalLand.landwidth }}英尺</span>
                    </el-row>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.orderid = this.$route.query.id;
    },
    mounted() {
        this.$http.get('/v1/administrator/select-ordermanage-byid?id=' + this.orderid)
            .then(res => {
                this.orderInfor = res.data.data;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail('初始化失败，请刷新页面或重新登录');
            });
    },
    data() {
        return {
            orderid: null, // 订单编号，从网址获取
            orderInfor: { // 范例数据
                ids: 317,
                separateOrderId: "",
                combinedOrderId: null,
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: null,
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                completetime: null,
                buyerid: "",
                sellerid: "",
                addressid: null,
                needpaper: true,
                orderstatus: "",
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: {
                    id: 6006,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 6006,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 171,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                },
                onSale: {
                    id: 171,
                    originallandid: "",
                    unitlandid: "",
                    landname: "",
                    sellerid: "",
                    seller: "",
                    salesmethods: "",
                    expirydate: "",
                    unitlandprice: null,
                    pricenum: 0,
                    unitlandminprice: null,
                    minimumprice: null,
                    blocks: 0,
                    totalprice: 0,
                    unitpicture: "",
                    createtime: "",
                    reasondelisting: null,
                    status: "",
                    remark: null,
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: null,
                    unitLand: null
                }
            }, // 订单数据，从后端获取
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 返回按钮事件 */
        goBack() {
            this.$router.go(-1)
        },
    },
}
</script>

<style>
/* body之下第一层，设置黑色背景色 */
.admin-detail-container {
    height: 100%;
    overflow: auto;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.admin-detail-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.admin-detail-form {
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
}
.admin-detail-form-topic {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #1D2129;
}
.admin-detail-form-full {
    padding: 10px 50px;
    display: flex;
    flex-direction: row;
}
.admin-detail-form-half {
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: normal;
    color: #4E5969;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.admin-detail-form-item-label {
    width: 200px;
    display: inline-block;
    vertical-align: top;
}
.admin-detail-form-row {
    line-height: 2.5;
}
</style>