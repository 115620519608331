<template>
    <div class="developer-common-safe">
        <div class="developer-common-safe-title">{{$t('AccountSafe.account')}}</div>
        <div class="flex flex-row">
            <div class="h-full w-1/2">
                <MyEmail></MyEmail>
            </div>
            <div class="h-full w-1/2">
                <MyPhone></MyPhone>
            </div>
        </div>
    </div>
</template>

<script>
import MyEmail from "./AccountEmail.vue";
import MyPhone from "./AccountPhone.vue";
export default {
    components: {
        MyEmail,
        MyPhone,
    },
    data() {
        return {
        }
    },
    methods: {
        
    },
}
</script>

<style scoped>
</style>