<template>
  <div class="order-window">
    <div class="window-combin">
      <!-- 商品信息 -->
      <div class="window-combin-merch">{{ $t('PcContinueWindow.merchinfor') }}</div>
      <!-- 订单编号 -->
      <div class="window-combin-id">{{$t('PcContinueWindow.ordertime')}}</div>
      <!-- 购买方式 -->
      <div class="window-combin-salemethod">{{$t('PcContinueWindow.payment')}}</div>
      <!-- 总金额 -->
      <div class="window-combin-price">{{$t('PcContinueWindow.total')}}:
        <span v-if="$setPayment === 'am'">${{ totalPrice }}</span>
        <span v-else-if="$setPayment === 'zh'">￥{{ totalPrice * $exchangeRate }}</span>
      </div>
    </div>

    <div class="window-infor" v-for="(separateOrders, index) in order" :key="index" @click="turnToDetail(separateOrders.unitlandid)">
      <!-- 土地图片 -->
      <div class="window-infor-picture">
        <img :src="separateOrders.landpicture">
      </div>
      <!-- 土地信息 -->
      <div class="window-infor-box">
        <span class="window-infor-name">{{ separateOrders.landname }}</span>
        <span class="window-infor-address">{{ getEnglish(separateOrders.orderstatus) }}</span>
        <span class="window-infor-tag">{{$t('MobileLandDetail.landId')}}:{{ separateOrders.landid }}</span>
      </div>
      <!-- 分割线 -->
      <div class="window-devider"></div>
      <!-- 单位土地个数 -->
      <div class="window-infor-landnum">{{ separateOrders.ordertime }}</div>
      <!-- 分割线 -->
      <div class="window-devider"></div>
      <!-- 状态 -->
      <div class="window-infor-salemethod">{{ separateOrders.paymeans }}</div>
      <!-- 分割线 -->
      <div class="window-devider"></div>
      <!-- 商品价格 -->
      <div class="window-infor-price">
        <span style="color:#FA5151;" v-if="$setPayment === 'am'">${{ separateOrders.totalprice }}</span>
        <span style="color:#FA5151;" v-else-if="$setPayment === 'zh'">￥{{ separateOrders.totalprice * $exchangeRate }}</span>
      </div>
    </div>




  </div>
</template>

<script>
export default {
  props: { // 父子组件传值
    order: {
      type: Array,
      default: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黑土地",
                        totalAmount: "1000.0",
                        paymentStatus: "交易成功",
                        landpicture: null,
                        canceltime: '111',
      }],
    },
    totalPrice: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      example: [{
        id:1,
        userid:"43a89960781b41de9e16c2575772dc54",
        unitlandid:"6003",
        originallandid:"S751",
        landblock:32,
        landname:"红土地",
        totalprice:1301.0,
        createtime:"2023-07-21T12:54:32",
        originalLand: {
          originallandname: '黄土高坡',
          geographicalcoordinates: '128.6,41.73'
        }
      }],
    }
  },
  methods: {
    /* 订单状态在后端是中文，需要有双语 */
        getEnglish(statu) {
            if (statu === '待付款') {
                return this.$t('MobileOrderList.time');
            } else if (statu === '已付款') {
                return this.$t('MobileOrderList.statu2');
            } else if (statu === '已退款') {
                return this.$t('MobileOrderList.statu3');
            } else if (statu === '退款中') {
                return this.$t('MobileOrderList.statu4');
            } else if (statu === '已驳回') {
                return this.$t('MobileOrderList.statu5');
            } else if (statu === '已取消') {
                return this.$t('MobileOrderList.statu6');
            }
        },
    // 打开商品信息页
    turnToDetail(unitlandid) {
      return;
      this.$router.push('/user/commodity/detail?merch=' + unitlandid)
    },
  },
}
</script>

<style>
.order-window {
  width: 1120px;
  border-radius: 8px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  display: flex;
  flex-direction: column;
}
.window-combin {
  width: 100%;
  height: 36px;
  background: #F4F4F4;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #9DA1AD;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.window-combin-merch {
  width: 423px;
  text-align: center;
}
.window-combin-id {
  width: 168px;
  text-align: center;
}
.window-combin-salemethod {
  width: 270px;
  text-align: center;
}
.window-combin-price {
  width: 255px;
  text-align: center;
}

.window-infor {
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.window-infor-picture {
  margin-left: 16px;
  width: 89px;
  height: 89px;
  border-radius: 8px;
}
.window-infor-picture > .img {
  object-fit: contain;
  width: 89px;
  border-radius: 8px;
}
.window-infor-box {
  height: 100%;
  margin-left: 18px;
  width: 302px;
  max-width: 300px;
  max-height: 110px;
  padding-top: 9px;
}
/* 商品名称 */
.window-infor-name {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #3D3D3D;
}
/* 经纬度 */
.window-infor-address {
  display: block;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 350;
  color: #6f6f6f;
}
/* 原始土地名称标签 */
.window-infor-tag {
  margin-top: 16px;
  background-color: #582d7e;
  border-radius: 12px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 3px 8px;
}


/* 竖直的分割线 */
.window-devider {
  width: 1px;
  height: 100%;
  background: #D8D8D8;
}
/* 单位土地个数 */
.window-infor-landnum {
  width: 167px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  color: #3D3D3D;
}
/* 购买方式 */
.window-infor-salemethod {
  width: 269px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  color: #0256FF;
}
/* 价格 */
.window-infor-price {
  width: 254px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #3D3D3D;
  max-width: 254px;
  padding: 16px;
}
</style>