<template>
    <div style="max-height: 600px;" class="relative rounded-lg overflow-hidden bg-white flex flex-col" @click="turnToDetail">
        <div class="px-1 py-3 pb-1">
            <img :src="LandInfor.unitpicture" class="w-full object-cover" />
        </div>
        <div class="px-1 pb-2 flex-grow flex flex-col">
            <div class="font-bold text-lg">{{ LandInfor.landname }}</div>
            <div class="text-gray-500 mt-1" style="font-size:10px;">{{$t('MobileHomeLand.landNum')}}: {{ LandInfor.blocks }}</div>
            <div class="flex items-center mt-1">
                <img src="../images/locate.png" class="w-3 h-3"/>
                <span class="ml-1 text-xs">{{ LandInfor.originalLand.geographicalcoordinates }}</span>
            </div>
            <van-tag round color="#7232dd" class="text-xs px-1 mt-1" plain>{{ LandInfor.originalLand.originallandname }}</van-tag>

            <div class="mt-1 flex flex-grow flex flex-row items-end">
                <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        LandInfor: {
            // 包含图片链接image、landName、landPrice、landAddress
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            // 测试数据
            id: 144,
            originallandid: "TD012023042057120",
            landname: "黑土地",
            seller: "Mrs.wang",
            pricenum: "1301.0",
            salesmethods: "立即购买",
            originalLand: null,
        };
    },
    methods: {
        turnToDetail() {
            const newpath = "/mobile/land/detail?land=" + this.LandInfor.originallandid + "&merch=" + this.LandInfor.id;
            this.$router.push({
                path: newpath,
            });
        },
    },
};
</script>

<style scoped>
.market-background {
    height: 300px;
    width: 170px;
    background-color: #fff;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 6px;
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, 0); /* 将子元素向左、向上移动自身宽高的一半 */
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 170px;
    left: 10px;
    font-size: 20px;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 20px;
}
.market-landaddress {
    /* 地址（后续可能会改为购买按钮） */
    height: 30px;
    width: 80px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
.market-landtag {
    /* 小标签 */
    height: 20px;
    width: 80px;
    position: absolute;
    top: 210px;
    left: 10px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
</style>
