<template>
  <div class="order-detail-container">
    <div class="goback-parent" style="width:1200px;" @click="$router.go(-1)">
      <span class="text-2xl">{{ '<' }}</span>
      <span class="ml-2">{{$t('PcOrderDetail.title')}}</span>
    </div>

    <div class="order-detail-box" ref="mainContainer">
      <!-- 加载界面 -->
      <div class="absolute h-full w-full" style="top: 0;left: 0;z-index: 999;" v-if="loading">
        <el-skeleton :loading="loading" animated>
          <template slot="template">
            <div class="skeleton-box">
              <div class="w-full flex flex-row">
                <el-skeleton-item variant="image" style="width: 352px;height: 352px;" />
                <div class="order-detail-infor1-box">
                  <el-skeleton-item variant="p" class="infor1-landname" />
                  <el-skeleton-item variant="p" class="infor1-landblock" />
                  <el-skeleton-item variant="p" class="infor1-price" />
                </div>
              </div>
              <div class="flex flex-row" style="margin-top:32px">
                <div class="infor2-box">
                  <el-skeleton-item variant="p" style="width: 80px;" />
                  <el-skeleton-item variant="p" style="width: 80%;margin-top: 16px;" />
                  <el-skeleton-item variant="p" style="width: 80%;margin-top: 16px;" />
                  <el-skeleton-item variant="p" style="width: 80%;margin-top: 16px;" />
                  <el-skeleton-item variant="p" style="width: 40%;margin-top: 16px;" />
                </div>
                <div class="infor3-box" style="margin-left:55px">
                  <el-skeleton-item variant="p" style="width: 80px;" />
                  <el-skeleton-item variant="p" style="margin-top: 16px;" />
                  <el-skeleton-item variant="p" style="width: 80px;margin-top: 4px;" />
                  <el-skeleton-item variant="p" style="margin-top: 8px;" />
                  <el-skeleton-item variant="p" style="width: 80px;margin-top: 4px;" />
                </div>
                <div class="infor3-box" style="margin-left:55px">
                  <el-skeleton-item variant="p" style="width: 80px;" />
                  <el-skeleton-item variant="p" style="margin-top: 16px;" />
                  <el-skeleton-item variant="p" style="width: 80px;margin-top: 4px;" />
                  <el-skeleton-item variant="p" style="margin-top: 8px;" />
                  <el-skeleton-item variant="p" style="width: 80px;margin-top: 4px;" />
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
      <div class="w-full flex flex-row">
        <canvas class="shadow-sm" :height="canvasSize" :width="canvasSize" ref="bigmap" style="border-radius:8px"></canvas>
        <div class="order-detail-infor1-box">
          <span class="infor1-landname">{{ LandInfor.landname }}</span>
          <span class="infor1-landblock">{{ LandInfor.orderstatus }}<span class="ml-2 text-red-500" v-if="LandInfor.orderstatus === '待付款'">{{ countdown.hour + 'h-' + countdown.minute + 'm-' + countdown.second + 's' }}</span></span>
          <span class="infor1-price" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
          <span class="infor1-price" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
          <div class="flex-grow flex flex-row justify-end">
            <button class="infor1-button infor1-button-blueground" v-if="LandInfor.orderstatus === '已付款' || LandInfor.orderstatus === '已驳回'">{{$t('PcOrderDetail.refund')}}</button>
            <button class="infor1-button infor1-button-blueground" v-else-if="LandInfor.orderstatus === '待付款'" @click="continuePayment(LandInfor)">{{$t('MobileOrderList.continueTitle')}}</button>
            <button class="infor1-button infor1-button-whiteground" style="margin-left:17px" v-if="LandInfor.orderstatus === '已付款' && SevenDaysCanTalkToSeller()">{{$t('MobilePurchaseDetails.private')}}</button>
          </div>
        </div>
      </div>

      <!-- 下部分三个框 -->
      <div class="flex flex-row" style="margin-top:32px">
        <div class="infor2-box">
          <span class="box-title">{{$t('PcConfirmOrder.orderinfor')}}</span>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobilePurchaseDetails.ordertime') }}:</div>
            <div class="box-infor-data">{{ LandInfor.ordertime }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobilePurchaseDetails.orderno') }}:</div>
            <div class="box-infor-data">{{ LandInfor.separateOrderId }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobilePurchaseDetails.totalprice') }}:</div>
            <div class="box-infor-data">
              <span v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
              <span v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
            </div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobilePurchaseDetails.landname') }}:</div>
            <div class="box-infor-data">{{ LandInfor.landname }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobilePurchaseDetails.sellerid') }}:</div>
            <div class="box-infor-data">{{ LandInfor.onSale.seller }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobileRecharge.method') }}:</div>
            <div class="box-infor-data">{{ LandInfor.paymeans }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('MobilePurchaseDetails.address') }}:</div>
            <div class="box-infor-data">{{ LandInfor.address.province ? LandInfor.address.province + LandInfor.address.city + LandInfor.address.area : LandInfor.address.detailedaddress }}</div>
          </div>
        </div>

        <div class="infor3-box" style="margin-left:55px">
          <span class="box-title">{{ $t('PcOrderDetail.detail') }}</span>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.area') }}:</div>
            <div class="box-infor-data">{{ LandInfor.unitLand.communitynumber }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellList.unit') }}:</div>
            <div class="box-infor-data">{{ LandInfor.unitLand.landcoordinates }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.landpicture') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.height }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.blocks') }}:</div>
            <div class="box-infor-data">{{ LandInfor.unitLand.landblocks }}</div>
          </div>
        </div>

        <div class="infor3-box" style="margin-left:55px">
          <span class="box-title">{{ $t('LandSellDetail.origindetail') }}</span>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.originallandid') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.originallandid }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.originallandname') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.originallandname }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.landlength') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.landlength }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.landwidth') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.landwidth }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.detailedaddress') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.detailedaddress }}</div>
          </div>
          <div class="box-infor-col">
            <div class="box-infor-label">{{ $t('LandSellDetail.geo') }}:</div>
            <div class="box-infor-data">{{ LandInfor.originalLand.geographicalcoordinates }}</div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  created() {
    // 不要忘了获取传输过来的土地信息
    this.orderid = this.$route.query.order;
  },
  mounted() {
    this.controlWindow();
    window.addEventListener('resize', this.controlWindow);
    this.$http.get("/v1/order/select-separateorder-one?separateOrderId=" + this.orderid)
      .then((res) => {
        this.LandInfor = res.data.data;
        this.orderStatus = this.LandInfor.orderstatus;
        this.initializeSelect(); // 初始化计时器
        let time1 = setTimeout(() => {
          const canvas = this.$refs.bigmap; // 获取 canvas 元素
          canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
          this.drawGrid();
          this.drawLand();
          this.loading = false
          clearInterval(time1);
        }, 1000);
      }).catch((error) => {});
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.controlWindow);
  },
  data() {
    return {
      orderid: null, // url传来的订单编号，用来查询订单信息
      orderStatus: '', // 订单状态，单独拿出来便于使用
      loading: true, // 加载

      countdown: {}, // 待付款订单倒计时剩余时间
      timer: null, // 待付款订单倒计时定时器，为了在页面销毁时清空设置为全局参数

      canvasSize: 352, // 画布尺寸
      gridSize: 0, // 网格尺寸
            LandInfor: {
                ids: 0,
                separateOrderId: "",
                combinedOrderId: "",
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "土地名称",
                landpicture: "",
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                combinationpay: null,
                buyerid: "",
                sellerid: "",
                addressid: "",
                needpaper: true,
                orderstatus: "订单状态",
                canceltime: null, // 待支付剩余时间
                terminationtime: null, // 退款截止日期
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                originalLand: {
                    id: 0,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 0,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 0,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                },
                onSale: {
                    id: 0,
                    originallandid: "",
                    unitlandid: "",
                    landname: "",
                    communitynumber: null,
                    sellerid: "",
                    seller: "",
                    salesmethods: "",
                    expirydate: "",
                    unitlandprice: null,
                    pricenum: 0,
                    unitlandminprice: null,
                    minimumprice: null,
                    blocks: 0,
                    totalprice: 0,
                    unitpicture: "",
                    createtime: "",
                    reasondelisting: null,
                    status: "未挂牌",
                    remark: "",
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: null,
                    unitLand: null
                },
            },
    }
  },
  methods: {
    // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1250) {
                const scaleX = (window.innerWidth - 50) / 1200;
                this.$refs.mainContainer.style.transform = `scale(${scaleX})`;
                this.$refs.mainContainer.style.height = (window.innerHeight - 115) / scaleX + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = `842px`;
            }
        },
    // 待付款剩余时间定时器
        initializeSelect() { // 初始化定时器
            if (this.orderStatus !== '待付款') { return; }
            let totalSeconds = Math.floor(parseInt(this.LandInfor.canceltime) / 1000);
            let hour = Math.floor(totalSeconds / 3600);
            let minute = Math.floor((totalSeconds % 3600) / 60);
            let second = totalSeconds % 60;
            this.countdown = {
                hour: hour,
                minute: minute,
                second: second,
                totalSeconds: totalSeconds
            }
            this.timer = setInterval(() => {
                this.countdown.totalSeconds--;
                if (this.countdown.totalSeconds <= 0) {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.$http.put("/v1/order/cancel-order?separateOrderId=" + this.orderid).then((res) => {
                        this.$toast.fail(this.$t('OrderList.tip4'));
                        this.$router.go(-1);
                    }).catch((err) => {});
                }
                this.countdown.hour = Math.floor(this.countdown.totalSeconds / 3600);
                this.countdown.minute = Math.floor((this.countdown.totalSeconds % 3600) / 60);
                this.countdown.second = this.countdown.totalSeconds % 60;
            }, 1000);
        },

    // 绘制网格
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;
            // 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#000000"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);

            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col + 1;
            const height = end.row - start.row + 1;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "#FF9500";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },

    // 7天可联系卖家
    SevenDaysCanTalkToSeller() {
      if (this.LandInfor.ordertime instanceof Date) {} else { return false; }
      const Today = new Date().getTime();
      const orderDate = this.LandInfor.ordertime.getTime();
      const SevenDays = 3600 * 24 * 7;
      if (Today - orderDate < SevenDays) {
        return true;
      } else {
        return false;
      }
    },

    // 单个独立订单继续支付-进入继续支付页面
        continuePayment(order) {
          this.orders = [];
          this.orders.push(order);
          this.$router.push({
            path: "/user/continue-payment",
            name: 'UserPcContinue',
            params: {
              totalPrice: JSON.stringify(this.LandInfor.totalprice),
              orders: JSON.stringify(this.orders)
            },
          });
        },
  },
}
</script>

<style scoped>
.order-detail-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-detail-box {
  margin-top: 24px;
  height: 842px;
  max-height: calc(100vh - 238px);
  width: 1200px;
  padding: 50px 106px 64px 106px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #FFFFFF;
  position: relative;
}
.skeleton-box {
  height: 842px;
  width: 1200px;
  padding: 50px 106px 64px 106px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1250px) or (max-height: 980px) {
    .goback-parent {
        margin-top: 0;
    }
    .order-detail-container {
      justify-content: normal;
    }
    .order-detail-box {
      margin-top: 0;
      max-height: none;
      transform-origin: top center;
    }
}
.order-detail-infor1-box {
  flex-grow: 1;
  padding-left: 93px;
  display: flex;
  flex-direction: column;
}

.infor2-box {
  width: 354px;
  height: 343px;
  padding: 20px;
  max-height: 343px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}
.infor3-box {
  width: 262px;
  height: 343px;
  padding: 30px;
  max-height: 343px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}
.box-title {
  font-size: 20px;
  font-weight: 500;
  color: #21205A;
}
.box-infor-col {
  margin-top: 6px;
}
.box-infor-label {
  display: inline-block;
  width: 50%;
  font-size: 14px;
  font-weight: normal;
  color: #4E5969;
}
.box-infor-data {
  display: inline-block;
  width: 50%;
  font-size: 14px;
  font-weight: normal;
  color: #1D2129;
}
</style>