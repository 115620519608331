<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>用户管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">用户信息列表</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('企业用户')" @click="tabsControl('企业用户')">
                        <span @mouseover="changeCursor">企业用户</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('普通用户')" @click="tabsControl('普通用户')">
                        <span @mouseover="changeCursor">普通用户</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">用户id:</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入用户id"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">用户名:</span>
                            <el-input style="width:256px;" v-model="search.name" placeholder="请输入用户名"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">用户状态:</span>
                            <el-select style="width:256px;" v-model="search.status" placeholder="请选择">
                                <el-option v-for="item in restaurants" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">注册时间:</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadUser">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetUser">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="3"><span>注册日期</span></el-col>
                        <el-col :span="4"><span>用户id</span></el-col>
                        <el-col :span="3"><span>用户名</span></el-col>
                        <el-col :span="3"><span>用户邮箱</span></el-col>
                        <el-col :span="3"><span>用户手机号</span></el-col>
                        <el-col :span="2"><span>性别</span></el-col>
                        <el-col :span="3"><span>用户状态</span></el-col>
                        <el-col :span="3"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated @mouseover="changeCursor" @click="loadUser" />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in userArray" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.createdate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.createdate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="returnData(apply,'id')" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ returnData(apply,'id') }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="returnData(apply,'name')" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ returnData(apply,'name') }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="returnData(apply,'email')" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ returnData(apply,'email') }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="returnData(apply,'phone')" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ returnData(apply,'phone') }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="returnData(apply,'sex')" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ returnData(apply,'sex') }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.status" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.status }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3" style="word-wrap: break-word;">
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="banUserModal(apply)" v-if="apply.status === '正常'">封禁用户</span>
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="unlockUserModal(apply,apply.bantime,apply.bandays)" v-else-if="apply.status === '封禁中'">解封用户</span>
                                    &nbsp;
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="goDetail(apply)">查看详情</span>
                                </el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="!loading && showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 这个是用来封禁用户的弹窗，由modal.bmodal控制 -->
        <div class="admin-manage-modal" v-show="modal.bmodal">
            <div class="admin-manage-modal-box">
                <div class="flex flex-row">
                    <img src="../adminImage/admin-company-modal.png" class="admin-apply-modal-icon">
                    <span>确定封禁此账号？</span>
                </div>
                <div class="flex flex-row mt-5">
                    <span class="flex items-center mr-3">封禁时长:</span>
                    <el-select v-model="selectValue" placeholder="请选择" class="admin-manage-modal-select">
                        <el-option
                            v-for="item in selectArray"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="flex flex-row mt-5">
                    <span class="flex mr-3" style="width:130px;">封禁原因:</span>
                    <el-input type="textarea" :rows="4" placeholder="请输入封禁原因" v-model="banReason"></el-input>
                </div>
                <div style="margin-top: 50px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="banUser" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
        <!-- 这个是用来解禁用户的弹窗，由modal.vmodal控制 -->
        <div class="admin-manage-modal" v-show="modal.vmodal">
            <div class="admin-manage-modal-box" v-if="!modal.showError">
                <span>此账号于</span>
                <span style="padding:10px;">{{ modal.ban1 }}</span>
                <span>被封禁 {{ modal.ban2 }} 天</span>
                <span style="margin-top: 10px;">是否立即解封？</span>
                <div style="margin-top: 30px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="unlockUser" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
            <div class="admin-manage-modal-box" v-if="modal.showError">
                <div>{{ modal.errorText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        if (localStorage.getItem("admin-user")) {
            this.whichtab = localStorage.getItem("admin-user")
        }
    },
    mounted() {
        this.loadUser();
        /* if (this.whichtab === '企业用户') {
            this.$http.post('/v1/administrator/select-usermanage-businesslist', {
                pagesize: this.pageSize,
                pagenum: this.currentPage
            })
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
            }).catch(error => {
                // 处理错误
                this.$toast.fail("加载失败-错误代码401");
            });
        } else {
            this.$http.post('/v1/administrator/select-usermanage-list', {
                pagesize: this.pageSize,
                pagenum: this.currentPage
            })
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
            }).catch(error => {
                // 处理错误
                this.$toast.fail("加载失败-错误代码401");
            });
        } */
    },
    data() {
      return {
        loading: true, // 加载
        userNormal: [{
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            paymentpassword: null,
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            updatedate: null,
            status: "正常",
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3,
            starttime: null,
            endtime: null,
            pagesize: 0,
            pagenum: 0,
            start: 0,
            userBalance: null,
            addresss: null,
        }], // 测试数据，正常用户数据

        userArray: [], // 用于显示的数组，根据需要显示
        showLength: 0, // 所有数据的长度
        
        whichtab: '企业用户',
        pageSize: 50, // 每页条数
        currentPage: 1,// 页码

        search: {
            id: '',
            name: '',
            status: '正常',
            date: '',
        },
        restaurants: [{
          value: '正常',
          label: '正常'
        }, {
          value: '封禁中',
          label: '封禁中'
        }],
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        
        modal: {
            bmodal: false, // 封禁用户的弹窗
            vmodal: false, // 弹窗开关
            ban1: '', // 带按钮的弹窗内容
            ban2: '',
            showError: false, // true是带按钮的，false是仅提示
            errorText: '', // 仅提示时的文本
        },

        selectArray: [{
          value: '3',
          label: '3天'
        }, {
          value: '7',
          label: '7天'
        }, {
          value: '30',
          label: '1个月'
        }, {
          value: '10y',
          label: '10年'
        }],
        selectValue: '3', // 封禁时间对应的参数
        selectUserId: '', // 封禁的用户id
        banReason: '', // 封禁用户原因
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            localStorage.setItem("admin-user", this.whichtab);
            this.currentPage = 1;
            if(this.whichtab==='企业用户') {
                this.loadDevelopeUser();
            } else {
                this.loadNormalUser();
            }
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        returnData(data,type) {
            if (this.whichtab === '企业用户') {
                if (type === 'id') {
                    return data.businessid;
                } else if (type === 'name') {
                    return data.businessname;
                } else if (type === 'email') {
                    if (data.businessemail) {
                        return data.businessemail;
                    } else {
                        return '未设置'
                    }
                } else if (type === 'phone') {
                    if (data.businessphone) {
                        return data.businessphone;
                    } else {
                        return '未设置'
                    }
                } else if (type === 'sex') {
                    if (data.usersex) {
                        return data.usersex;
                    } else {
                        return '未设置'
                    }
                }
            } else {
                if (type === 'id') {
                    return data.userid;
                } else if (type === 'name') {
                    return data.username;
                } else if (type === 'email') {
                    if (data.useremail) {
                        return data.useremail;
                    } else {
                        return '未设置'
                    }
                } else if (type === 'phone') {
                    if (data.userphone) {
                        return data.userphone;
                    } else {
                        return '未设置'
                    }
                } else if (type === 'sex') {
                    if (data.usersex) {
                        return data.usersex;
                    } else {
                        return '未设置'
                    }
                }
            }
        },
        loadNormalUser() { // 更新显示的数据
            this.loading = true;
            let data = {
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.id !== '') {
                data["userid"] = this.search.id;
            }
            if(this.search.name !== '') {
                data["username"] = this.search.name;
            }
            if(this.search.status !== '') {
                data["status"] = this.search.status;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }

            this.$http.post('/v1/administrator/select-usermanage-list', data)
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                // 处理错误
                this.$toast.fail("加载失败,请重试或重新登录");
            });
        },
        loadDevelopeUser() { // 更新显示的数据
            this.loading = true;
            let data = {
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.id !== '') {
                data["businessid"] = this.search.id;
            }
            if(this.search.name !== '') {
                data["businessname"] = this.search.name;
            }
            if(this.search.status !== '') {
                data["status"] = this.search.status;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }

            this.$http.post('/v1/administrator/select-usermanage-businesslist', data)
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                // 处理错误
                this.$toast.fail("加载失败,请重试或重新登录");
            });
        },
        loadUser() {
            if(this.whichtab==='企业用户') {
                this.loadDevelopeUser();
            } else {
                this.loadNormalUser();
            }
        },
        resetUser() {
            this.search.id = '';
            this.search.name = '';
            this.search.status = '正常';
            this.search.date = '';
            if(this.whichtab==='企业用户') {
                this.loadDevelopeUser();
            } else {
                this.loadNormalUser();
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            if(this.whichtab==='企业用户') {
                this.loadDevelopeUser();
            } else {
                this.loadNormalUser();
            }
        },
        pageCurrentChange(val) {
            // 更新页距
            this.currentPage = val;
            if(this.whichtab==='企业用户') {
                this.loadDevelopeUser();
            } else {
                this.loadNormalUser();
            }
        },
        closeModal() {
            this.modal.vmodal = false;
            this.modal.bmodal = false;
            this.selectUserId = '';
            this.banReason = '';
            this.selectValue = '3';
        },
        banUserModal(id) {
            if (this.whichtab === '企业用户') {
                this.selectUserId = id.businessid;
            } else {
                this.selectUserId = id.userid;
            }
            this.modal.bmodal = true;
        },
        banUser() {
            if(this.whichtab === '普通用户') {
                this.$http.patch('/v1/administrator/prohibited-users-byid?userid=' + this.selectUserId + '&remark=' + this.banReason + '&days=' + this.selectValue)
                .then(res => {
                    this.$toast.success("成功封禁该用户,封禁时间: " + this.selectValue + "天");
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("封禁失败,请重试或重新登录");
                });
            } else if (this.whichtab === '企业用户') {
                this.$http.patch('/v1/administrator/prohibited-userbusiness-byid?businessid=' + this.selectUserId + '&remark=' + this.banReason + '&days=' + this.selectValue)
                .then(res => {
                    this.$toast.success("成功封禁该用户,封禁时间: " + this.selectValue + "天");
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("封禁失败,请重试或重新登录");
                });
            } else {
                this.$toast.fail("封禁失败-异常的用户类型:" + this.whichtab);
            }
        },
        unlockUserModal(id,time1,time2) {
            if (this.whichtab === '企业用户') {
                this.selectUserId = id.businessid;
            } else {
                this.selectUserId = id.userid;
            }
            this.modal.ban1 = time1;
            this.modal.ban2 = time2;
            this.modal.vmodal = true;
        },
        unlockUser() {
            if(this.whichtab === '普通用户') {
                this.$http.put('/v1/administrator/manual-unblocking-byid?userid=' + this.selectUserId)
                .then(res => {
                    this.$toast.success("成功解封该用户");
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("解封失败,请重试或重新登录");
                });
            } else if (this.whichtab === '企业用户') {
                this.$http.put('/v1/administrator/manual-unblocking-bybusinessid?businessid=' + this.selectUserId)
                .then(res => {
                    this.$toast.success("成功解封该用户");
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("解封失败,请重试或重新登录");
                });
            } else {
                this.$toast.fail("解封失败-异常的用户类型:" + this.whichtab);
            }
        },
        goDetail(id) {
            if(this.whichtab === '企业用户') {
                this.$router.push('/admin/user-manage/detail-d?id=' + id.businessid);
            } else if(this.whichtab === '普通用户') {
                this.$router.push('/admin/user-manage/detail-n?id=' + id.userid);
            } else {
                this.$toast.fail("异常的用户类型:" + this.whichtab);
                return;
            }
        }
    },
}
</script>

<style scoped>
</style>