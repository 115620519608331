<template>
  <div class="main-container" ref="mainContainer">
    <!-- 返回文本链接 -->
    <div class="goback-parent" style="width:900px;" @click="$router.go(-1)">
      <span class="text-2xl">{{ '<' }}</span>
      <span class="ml-2 font-semibold">{{ sellername }}</span>
      <span class="ml-1">{{$t('all.shopinfor')}}</span>
    </div>

    <!-- 卖家box -->
    <div class="seller-box" style="margin-top:20px">
      <!-- 卖家头像 -->
      <div class="seller-avatar">
        <img :src="avatarUrl" class="seller-avatar-img">
      </div>
      <!-- 昵称和联系方式 -->
      <div class="seller-infor">
        <span>{{ $t('MobileServiceAWindow.name') + ':' + sellername }}</span>
        <span>{{ $t('SellerHome.contacttype') + ':' + getSellerInfor() }}</span>
      </div>
      <div class="detail-merch-icon-developer" v-if="identifier === '企业'">{{$t('SellerHome.developer')}}</div>
        <div class="detail-merch-icon-platform" v-else-if="identifier === '平台'">{{$t('SellerHome.platform')}}</div>
      <!-- 右侧按钮 -->
      <div class="seller-contact">
        <button class="infor1-button infor1-button-whiteground">{{ $t('all.follow') }}</button>
        <button class="infor1-button infor1-button-whiteground" style="margin-left:17px" @click="TalkSeller">{{$t('MobilePurchaseDetails.private')}}</button>
      </div>
    </div>

    <!-- 商品信息列表 -->
    <div class="merch-detail-box" ref="merchContainer" style="margin-top:16px">
        <div class="normal-card-title ml-5 mt-3">
            {{ $t('all.allgoods') }}
            <span class="text-sm text-gray-500 ml-3">{{$t('PcConfirmOrder.total1')}}</span>
            <span class="mx-1 text-gray-600 text-lg">{{ totalNum }}</span>
            <span class="text-sm text-gray-500">{{$t('PcConfirmOrder.total2')}}</span>
        </div>
        <div class="seller-list-container" ref="scrollContainer" @scroll="handleScroll">
            <!-- 骨架屏 -->
                <div class="w-full flex flex-wrap" v-if="loading">
                    <el-skeleton class="private-skeleton" :loading="loading" animated :count="12" :throttle="500">
                        <template slot="template">
                            <div class="flex flex-col" style="margin-top: 16px;margin-left: 15px;">
                                <el-skeleton-item variant="image" style="width: 235px; height: 150px;" />
                                <div style="width: 235px;height: 165px;padding: 16px;">
                                    <el-skeleton-item variant="p" style="width: 60%" />
                                    <el-skeleton-item variant="p" style="width: 50%;margin-top: 40px;" />
                                    <div style="display: flex; align-items: center; justify-items: space-between;margin-top: 40px;">
                                        <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                        <el-skeleton-item variant="text" style="width: 30%;" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </div>
            <LandMarketWindows v-for="(card, index) in LandInfor" :key="index" class="card" :LandInfor="card"></LandMarketWindows>
        </div>
    </div>


  </div>
</template>

<script>
import LandMarketWindows from "./LandShopList";
export default {
  components: {
      LandMarketWindows, // 土地市场组件
  },
  created() {
      this.unitlandid = this.$route.query.merch;
  },
  mounted() {
    this.controlWindow();
      window.addEventListener('resize', this.controlWindow);
    this.$http.get("/v1/sale/get-seller-information?saleid=" + this.unitlandid)
      .then((res) => {
        const seller = res.data.data;
        if (seller.identifier === '企业' || seller.identifier === '平台') {
            this.sellerId = seller.businessid;
            this.sellername = seller.businessname;
            this.sellerEmail = seller.businessemail;
            this.sellerPhone = seller.businessphone
            this.avatarUrl = seller.userphoto;
            this.identifier = seller.identifier;
            this.$http.get("/v1/sale/get-saleselect-byseller?pageNum=1&pageSize=5&sellerid=" + this.sellerId)
            .then((res) => {
                this.loading = false;
                        const newData = res.data.data.rows;
                        this.LandInfor = newData;
                        this.pageNum = 2;
                        this.totalNum = res.data.data.total;
                        if (newData.length === 0) {
                            this.hasMoreData = false;
                        }
            }).catch((error) => {});
        } else {
            this.sellerId = seller.userid;
            this.sellername = seller.username;
            this.sellerEmail = seller.useremail;
            this.sellerphone = seller.userphone;
            this.avatarUrl = seller.avatarUrl;
            this.identifier = '';
            this.$http.get("/v1/sale/get-saleselect-byseller?pageNum=1&pageSize=5&sellerid=" + this.sellerId)
            .then((res) => {
                this.loading = false;
                        const newData = res.data.data.rows;
                        this.LandInfor = newData;
                        this.pageNum = 2;
                        this.totalNum = res.data.data.total;
                        if (newData.length === 0) {
                            this.hasMoreData = false;
                        }
            }).catch((error) => {});
        }
      }).catch((error) => {});
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.controlWindow);
  },
  data() {
    return {
            unitlandid: null, // 商品id，从url中获取
            sellerId: null, // 顾名思义，卖家id，从url中获取
            loading: true, // 骨架屏，获取到卖家商品列表后变false
            flag: true, // 控制滚动条监听器触发频率的参数
            hasMoreData: true, // 当没有更多数据时变为false，停止调用接口
            
            sellername: '', // 用户姓名，从后端获取
            sellerEmail: null, // 卖家邮箱
            sellerPhone: null, // 卖家手机号
            avatarUrl: require('../assets/no-image.png'), // 卖家头像
            identifier: '', // 卖家身份，有企业、平台

            LandInfor: [],
            LandInforExample: [{
            originallandid: 'TD012023042096969',
            landname: "合并测试土地1号",
            unitlandid: '60', // 用于确定该土地块的关键词
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },], // 后端获取该卖家名下所有挂牌商品信息
            pageNum: 0, // 页码
            totalNum: 0, // 该卖家全部商品数量
    }
  },
  methods: {
    // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1080 || window.innerHeight <= 945) {
                const scaleX = (window.innerWidth - 180) / 900;
                const scaleY = window.innerHeight / 945;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
                this.$refs.mainContainer.style.height = 945 / scale + 'px';
                this.$refs.merchContainer.style.height = (window.innerHeight - 200) / scale + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = `auto`;
                this.$refs.merchContainer.style.height = `781px`;
            }
        },
    // 卖家联系方式
        getSellerInfor() {
            if (this.sellerEmail) {
                return this.sellerEmail;
            } else if (this.sellerPhone) {
                return this.sellerPhone;
            } else {
                return this.$t('MobileLandDetail.none');
            }
        },

    // 当滚动条达到一定距离时加载新的商品数据
        handleScroll() {
            if (this.flag) { // 防抖
                return;
            }
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;

            // 判断滚动条距离底部的距离是否达到一定范围
            if (scrollHeight - (scrollTop + clientHeight) < 100) {
                this.flag = true;
                this.currentPage++;
                this.updatePage();
                setTimeout(()=>{
                    // 延时一秒
                    this.flag = false;
                } ,1000)
            }
        },

    // 加载一段商品信息添加到LandInfor
        updatePage() {
            this.$http
                .get('/v1/sale/get-saleselect-byseller?sellerid=' + this.sellerId + '&pageNum=' + this.pageNum + '&pageSize=' + this.pageSize)
                .then((response) => {
                    this.LandInfor.push(response.data.data.rows);
                })
                .catch((error) => {});
        },

    // 判断是否应该显示返回顶部按钮
        showBackTop() {
            const scrollContainer = this.$refs.scrollContainer;
            const scrollTop = scrollContainer.scrollTop;
            if (scrollTop >= 100) {
                return true;
            } else {
                return false;
            }
        },

    // 返回顶部按钮触发事件
        backTop() {
            let n = 0;
            const LENGTH = this.$refs.scrollContainer.scrollTop * 0.1;
            let timer = setInterval(() => {
                n++;
                this.$refs.scrollContainer.scrollTop -= LENGTH;
                if (n >= 10) {
                    clearInterval(timer);
                }
            }, 10);
        },

    // 私聊卖家
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/user/seller-chat?merch=" + this.LandInfor.id + "&receive=" + this.LandInfor.sellerid,
            });
        },

    // 点击商品图鉴触发查看详情事件
        turnToDetail(LandInfor) {
            this.$router.push({
                path: '/user/land-market/detail',
                query: {
                    land: LandInfor.originallandid,
                    merch: LandInfor.unitlandid
                }
            });
        },
  },
}
</script>

<style scoped>
.merch-detail-box {
    padding: 0;
}
@media (max-width: 1080px) or (max-height: 945px) {
    .main-container {
      transform-origin: top center;
      justify-content: start;
    }
    .merch-detail-box {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .goback-parent {
      margin-top: 4px;
    }
}

/* 卖家商品列表 */
.card {
  width: 235px;
  height: 315px;
  margin: 16px 0 0 20px;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
}
.card:nth-child(3n+1) {
  margin-left: 0;
}
.card:nth-child(n+4) {
  margin-top: 16px;
}

.private-skeleton {
    width: 100%;
}
.private-skeleton >>> .el-skeleton {
    display: flex;
    flex-wrap: wrap;
}
</style>