<template>
    <div class="mobile-help-home">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileHelp.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>

        <div class="flex justify-center items-center bg-gray-100 mt-3">
            <div class="mx-auto px-4 py-4 bg-white">
                <div class="flex items-center">
                    <div class="flex-none mr-4">
                        <img src="../images/service.png" class="w-16 h-16" alt="Customer Service Icon" />
                    </div>
                    <div>
                        <h2 class="text-lg font-bold mb-2">{{$t('MobileHelp.title')}}</h2>
                        <p class="text-gray-600 text-sm">{{$t('MobileService.introduce')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-for="(i,index) in item" :key="index">
            <h1 class="mobile-help-collapse-topic">{{$t('MobileHelp.t'+(index+1))}}</h1>
            <van-collapse v-model="activeNames">
                <van-collapse-item :title="$t('MobileHelp.q'+(index+1)+'-'+j)" :name="index*10+j" v-for="j in i" :key="j">
                    <p v-html="$t('MobileHelp.a'+(index+1)+'-'+j)"></p>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</template>

<script>
export default {
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            activeNames: [],
            item: [6,2,2,4,6,1,3,4,1,2,3,5], // 循环次数，12个大标题，每个标题n个问题
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.mobile-help-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: auto;
}
.mobile-help-collapse-topic {
    padding: 10px;
    font-size: 14px;
    color: #bebebe;
}
</style>
