<template>
    <div class="mobile-pay-full mobile-base-background2 justify-center items-center">
        <div class="pc-pay-flex">
            <img src="../mImage/pay-success.png" class="mobile-pay-img">
            <span class="mobile-pay-topic">{{$t('MobilePaySuccessful.title')}}</span>
            <span class="mobile-pay-view" @click="gotoLand">查看土地</span>
            <div class="flex flex-col w-full rounded-t-xl mt-7" style="height:calc(100vh - 240px)">
                <div class="flex flex-row items-center w-full mt-3 px-3">
                    <div class="flex flex-row items-center">
                        <img src="../mImage/hot.png" style="height:16px;width:16px;">
                        <span style="font-size: 16px;font-weight: 600;margin-left: 6px;">{{$t('homePage.title')}}</span>
                    </div>
                </div>
                <van-skeleton  v-for="i in 10" :key="i" class="mt-10" title avatar :row="3" :loading="!loaded" />
                <div class="flex-grow overflow-y-auto mt-10" style="max-height:50%;" v-if="loaded">
                    <div class="flex justify-between flex-wrap px-3 pb-5"><MobileHomeLand1 class="w-1/2 mt-3" style="width:48%;" v-for="(landinfor,index) in LandInfor" :key="index" :LandInfor="landinfor" ref="MobileLand"></MobileHomeLand1></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MobileHomeLand1 from "./LandWindow.vue";
export default {
    components: {
        // 土地市场组件
        MobileHomeLand1, // 挂牌土地信息组件
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.id = this.$route.query.token;
    },
    mounted() {
        this.$http.get("/v1/paypal/callback/success?orderid=" + this.id).then((res) => {}).catch((err) => {});
        // 初始化热门推荐列表
        this.$http.post('/v1/sale/get-sale-selectlist-recommend',{
            pageSize: 20,
            pageNum: 1
        }).then(response => {
            if(response.data.status === 200) {
                this.LandInfor = response.data.data.rows;
                this.loaded = true;
            }
        }).catch(error => {
            this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
        })
    },
    data() {
        return {
            id: null, // 获取该商品详细信息
            loaded: false, // 骨架屏
            LandInfor: null, // 推荐商品列表
            mode: true, // 推荐商品的显示格式，true为半格，false为满格
        };
    },
    methods: {
        goBack() {
            this.$router.push('/user/land-market');
        },
        gotoLand() {
            this.$router.push("/user/property/my-land"); // 进入我的土地页面
        },
    },
}
</script>

<style scoed>
.pc-pay-flex {
    width: 980px;
    padding-top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>