<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>订单管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">订单退款审核列表</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('退款中')" @click="tabsControl('退款中')">
                        <span @mouseover="changeCursor">待审核</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已驳回')" @click="tabsControl('已驳回')">
                        <span @mouseover="changeCursor">已驳回</span>
                    </div>
                </div>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="4"><span>申请时间</span></el-col>
                        <el-col :span="4"><span>订单编号</span></el-col>
                        <el-col :span="4"><span>卖家id</span></el-col>
                        <el-col :span="4"><span>买家id</span></el-col>
                        <el-col :span="4"><span>退款金额</span></el-col>
                        <el-col :span="4"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in showCompany" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.refundtime" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.refundtime }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.separateOrderId" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.separateOrderId }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.sellerid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.sellerid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.buyerid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.buyerid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.totalprice" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.totalprice }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4"><span @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.ids)">审核</span></el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果，退款成功的订单请到订单管理-订单查看中查看</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$http.get('/v1/administrator/select-ordermanage-refund?status=' + this.companyChild.whichtab)
            .then(res => {
                this.refundChecking = res.data.data
                this.initializePage()
            }).catch(error => {
                // 处理错误
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
            });
    },
    data() {
      return {
        loading: true, // 控制骨架屏
        refundChecking: null, // 从后端接收的待审核退款申请
        refundReject: null, // 从后端接收的已驳回退款申请
        showCompany: null, // 显示的当页公司信息，根据页码页距控制
        showLength: 0, // 条数
        currentPage: 1, // 页码
        pageSize: 50, // 每页条数


        companyChild: { // 父子组件传值
            whichtab: '退款中', // 两种状态
            isReview: false // 土地上传资格审查详情页是否点入
        },
        refundInfor: {
            ids: 317,
                separateOrderId: "",
                combinedOrderId: null,
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: null,
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                completetime: null,
                buyerid: "",
                sellerid: "",
                addressid: null,
                needpaper: true,
                orderstatus: "",
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: {
                    id: 6006,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 6006,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 171,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                },
                onSale: {
                    id: 171,
                    originallandid: "",
                    unitlandid: "",
                    landname: "",
                    sellerid: "",
                    seller: "",
                    salesmethods: "",
                    expirydate: "",
                    unitlandprice: null,
                    pricenum: 0,
                    unitlandminprice: null,
                    minimumprice: null,
                    blocks: 0,
                    totalprice: 0,
                    unitpicture: "",
                    createtime: "",
                    reasondelisting: null,
                    status: "",
                    remark: null,
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: null,
                    unitLand: null
                }
        }, // 子组件详情信息
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.companyChild.whichtab = n
            this.companyChild.isReview = false
            if (this.companyChild.whichtab === '已驳回' && !this.refundReject) { // 已驳回未向后端申请时先申请
                this.loading = true
                this.$http.get('/v1/administrator/select-ordermanage-refund?status=' + this.companyChild.whichtab)
                .then(res => {
                    this.refundReject = res.data.data
                    this.initializePage()
                }).catch(error => {
                    // 处理错误
                    console.error(error.data)
                });
            }
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.companyChild.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        initializePage() { // 初始化
            this.currentPage = 1
            if (this.companyChild.whichtab === '退款中') {
                this.showLength = this.refundChecking.length
                this.showCompany = this.refundChecking.slice(0,this.pageSize - 1)
            } else if (this.companyChild.whichtab === '已驳回') {
                this.showLength = this.refundReject.length
                this.showCompany = this.refundReject.slice(0,this.pageSize - 1)
            }
            this.loading = false
        },
        pageSizeChange(val) {
            // 计算索引值
            const start = (this.currentPage - 1) * val
            const end = this.currentPage * val - 1
            // 更新显示的数据
            if (this.companyChild.whichtab === '退款中') {
                this.showCompany = this.refundChecking.slice(start,end)
            } else if (this.companyChild.whichtab === '已驳回') {
                this.showCompany = this.refundReject.slice(start,end)
            }
            // 更新页距
            this.pageSize = val
        },
        pageCurrentChange(val) {
            // 计算索引值
            const start = (val - 1) * this.pageSize
            const end = this.pageSize * val - 1
            // 更新显示的数据
            if (this.companyChild.whichtab === '退款中') {
                this.showCompany = this.refundChecking.slice(start,end)
            } else if (this.companyChild.whichtab === '已驳回') {
                this.showCompany = this.refundReject.slice(start,end)
            }
            // 更新页码
            this.currentPage = val
        },
        landReview(id) {
            this.$router.push('/admin/refund-manage/detail?id=' + id);
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    min-height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 14px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 16px;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>