import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

/* 管理员个人信息 */
const admin = {
  namespaced: true,
  state: {
    adminInfo: {
      id: 0,
      administratorid: "",
      administratorname: "111",
      loginname: "",
      loginpassword: "",
      administratorphone: null,
      role: "",
      founder: null,
      foundertime: null,
      status: null,
      administratorlevel: "",
    },
    avatarUrl: require("../images/logo.png"),
    messages: '',
  },
  mutations: {
    setAdminInfo(state, userInfo) {
      state.adminInfo = userInfo;
    },
    setUserAvatar(state, avatarUrl) {
      state.avatarUrl = avatarUrl;
    },
    setMessages(state, avatarUrl) {
      state.messages = avatarUrl;
    },
    setIndex(state, index) {
      state.index = index;
    },
    saveDataToLocalStorage(state) {
      localStorage.setItem('adminvuex', JSON.stringify(state));
    },
    loadDataFromLocalStorage(state) {
      const data = localStorage.getItem('adminvuex');
      if (data) {
        Object.assign(state, JSON.parse(data));
      }
    }
  },
  actions: {
    fetchAdminInfo({ commit,dispatch }) {
      // 异步获取管理员信息
      this._vm.$http
          .get("/v1/administrator/select-by-administratorid")
          .then((response) => {
            const data = response.data;
            if (data.status === 200) {
              let userInfo = data.data;
              commit("setAdminInfo", userInfo);
              dispatch("loadAvatar"); // 获取头像信息
            } else {
              // 处理错误情况
              console.error(data.msg);
            }
          })
          .catch((error) => {
            // 处理请求失败的情况
            console.error(error);
          });
    },
    loadAvatar({commit}) {
      this._vm.$http
          .get("/v1/user/see-userphoto")
          .then((response) => {
            if(response.data){
              commit("setUserAvatar", response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
    refreshMessages({commit}) {
      this._vm.$http
          .get("/v1/mail/unread")
          .then((response) => {
            if(response.data){
              commit("setMessages", response.data.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        }
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    }
  }
};

/* pc端开发商用户个人信息 */
const developer = {
  namespaced: true,
  state: {
    userInfo: {
        id: 0,
        businessid: "",
        businessname: "",
        businessemail: "",
        businesspassword: "",
        businessphone: "",
        corporationname: null,
        housingownership: null,
        businesscard1: "",
        businesscard2: null,
        companyregistration: null,
        platformcontract: null,
        createdate: null,
        status: "",
        remark: null,
        verificationstatus: "",
        businesspart: null,
        usersex: "",
        birthdate: null,
        userphoto: null,
        isbanned: false,
        bantime: null,
        bandays: null,
        starttime: null,
        endtime: null,
        pagesize: 0,
        pagenum: 0,
        start: 0,
        avatarUrl: require("../images/logo.png"),
    },
    avatarUrl: require("../images/logo.png"),
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setUserAvatar(state, avatarUrl) {
      state.avatarUrl = avatarUrl;
    },
    saveDataToLocalStorage(state) {
      localStorage.setItem('developervuex', JSON.stringify(state));
    },
    loadDataFromLocalStorage(state) {
      const data = localStorage.getItem('developervuex');
      if (data) {
        Object.assign(state, JSON.parse(data));
      }
    }
  },
  actions: {
    fetchUserInfo({ commit,dispatch }) {
      // 异步获取用户信息
      this._vm.$http
          .get("/v1/business-center/select-information-businessid")
          .then((response) => {
            const data = response.data;
            if (data.status === 200) {
              let userInfo = data.data;
              commit("setUserInfo", userInfo);
              dispatch("loadAvatar"); // 获取头像信息
            } else {
              // 处理错误情况
              console.error(data.msg);
            }
          })
          .catch((error) => {
            // 处理请求失败的情况
            console.error(error);
          });
    },
    loadAvatar({commit}) {
      this._vm.$http
          .get("/v1/user/see-userphoto")
          .then((response) => {
            if(response.data){
              commit("setUserAvatar", response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    }
  }
};

/* 手机端用户个人信息 */
const user = {
  namespaced: true,
  state: {
    userInfo: {
      id: -1,
      userid: "",
      username: "开心网友",
      useremail: "",
      userphone: "",
      userpart: "",
      userrealname: "",
      usercard: "",
      usersex: "",
      birthdate: new Date(),
      createdate: "",
      updatedate: "",
      status: true,
      remark: null,
      verificationstatus: "",
      avatarUrl: require("../images/logo.png"),
      isbanned: true,
      bantime: "",
      bandays: 0,
    },
    token: null,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setUserAvatar(state, avatarUrl) {
      state.userInfo.avatarUrl = avatarUrl;
    },
    setToken(state, token) {
      state.token = token;
    },
    saveDataToLocalStorage(state) {
      localStorage.setItem('uservuex', JSON.stringify(state));
    },
    loadDataFromLocalStorage(state) {
      const data = localStorage.getItem('uservuex');
      if (data) {
        Object.assign(state, JSON.parse(data));
      }
    }
  },
  actions: {
    fetchUserInfo({ commit,dispatch }) {
      /* user.state.session = this._vm.$cookies.get("SESSION"); // 没用，前端读取不到cookie
      console.log(this._vm.$cookies.get("SESSION")); */
      // 异步获取用户信息
      this._vm.$http
          .get("/v1/user/personal-centerlist")
          .then((response) => {
            const data = response.data;
            if (data.status === 200) {
              let userInfo = data.data;
              userInfo.birthdate = new Date(userInfo.birthdate);
              commit("setUserInfo", userInfo);
              commit("setToken", userInfo.id);
              dispatch("loadAvatar");
            } else {
              // 处理错误情况
              console.error(data.msg);
            }
          })
          .catch((error) => {
            // 处理请求失败的情况
            console.error(error);
          });
    },
    loadAvatar({commit}) {
      this._vm.$http
          .get("/v1/user/see-userphoto")
          .then((response) => {
            if(response.data){
              commit("setUserAvatar", response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    }
  }
};

export default new Vuex.Store({
  modules:{
    user: user,
    admin: admin,
    developer: developer,
  },
  plugins: [
    createPersistedState({
      paths: ['user','admin','developer'],
    }),
  ]
})