<template>
    <div class="mobile-shop-home mobile-base-background2 h-full">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileSafe.set')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>

        <div class="rounded-lg hover:bg-gray-200 bg-white pl-3 pr-4 pt-2 pb-2 mx-3 mt-3 flex items-center flex-row" @click="onChangePayment">
            <img src="../mImage/infor-myinfor.png" style="margin-right:20px;">
            <div class="flex-grow text-lg">
                {{$t('PcPay.title')}}:
                <span v-if="$setPayment === 'am'">{{$t('all.海外支付')}}</span>
                <span v-if="$setPayment === 'zh'">{{$t('all.国内支付')}}</span>
            </div>
            <van-switch v-model="payment" size="20px" active-color="#ee0a24" inactive-color="#00C6FF" @click="noEvent" />
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        // 消息提示
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    computed: {
        payment() {
            if (this.$setPayment === 'am') {
                return true;
            } else {
                return false;
            }
        },
    },
    data() {
        return {
            messages: 0,
        };
    },
    methods: {
        onChangePayment() {
            if (this.payment) {
                this.$setPayment = 'zh';
            } else {
                this.$setPayment = 'am';
            }
        },
        noEvent() {
            return;
        },
    },
}
</script>

<style scoped>
</style>