<template>
    <div class="flex h-screen flex-col mobile-background-base" style="padding:16px;">
        <!-- 左上角返回，返回登录页面 -->
        <div class="flex flex-row items-center">
            <img src="../mImage/login-back.png" class="justify-start" style="height:25px;width:25px;" @click="goBack">
            <div class="mobile-goback-title">{{$t('forgetPassword.title')}}</div>
            <img src="../mImage/login-ding.png" class="justify-end" style="height:25px;width:25px;">
        </div>
        <div style="margin-top:28px;">
            <img src="../icons/logo.png" style="object-fit: contain;height: 25px;cursor: pointer;" @click="backToHomePage" />
        </div>


        <!-- 忘记密码 -->
        <div class="my-box-tab" v-if="mobilechange">
            <div class="mobile-form-topic">{{$t('mobileForgetPassword.informationAuthentication')}}</div>
            <!-- 账号输入区域 -->
            <div class="mobile-base-input-parent w-full" style="margin-top:20px;">
                <img src="../developer/login-loginname.png" class="mobile-base-input-icon">
                <input type="text" class="mobile-base-input" v-model="account" :placeholder="$t('mobileForgetPassword.passwordLoginAccountInput')">
            </div>
            <!-- 提示用户手机号格式 -->
            <div class="w-full text-sm text-red-600">*{{ $t('all.请注意手机号格式') }}</div>
            <!-- 验证码输入区域及获取验证码 -->
            <div class="mobile-base-input-parent w-full" style="margin-top:16px;">
                <img src="../developer/account-password.png" class="mobile-base-input-icon">
                <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                <div class="mobile-base-input-code-parent">
                    <div class="mobile-base-input-code-button" @click="openModal" v-if="time===0">{{$t('mobileLogin.getCode')}}</div>
                    <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ time }})</div>
                </div>
            </div>
        </div>

        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <!-- 该弹窗仅用来获取手机验证码 -->
            <div class="developer-common-modal-box">
                <!-- 标题 -->
                <div class="w-full text-center mb-3">{{ $t('mobileLogin.safeVerify') }}</div>
                <!-- 图像验证码输入区，上为图片，下为输入框 -->
                <div class="flex flex-col">
                    <div class="w-full flex justify-center">
                        <img @click="loadCodeImg" style="width:150px;" :src="imgCodeUrl" alt="验证码"/>
                    </div>
                    <div class="account-modal-input-parent" style="margin-top:20px;">
                        <!--            请输入图像验证码-->
                        <img src="../developer/account-password.png" class="account-modal-input-icon">
                        <input type="text" v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="account-modal-input" />
                    </div>
                </div>
                <!-- 按钮，关闭弹窗和获取验证码 -->
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" @click="closeModal">{{$t('forgetPassword.no')}}</button>
                    <button class="developer-common-button2" style="margin-left:20px;" @click="getMobileCode">{{$t('mobileLogin.getCode')}}</button>
                </div>
            </div>
        </div>


        <div class="my-box-tab" v-if="!mobilechange">
            <div class="mobile-form-topic">{{$t('mobileForgetPassword.resetPassword')}}</div>
            <!-- 密码输入区域 -->
            <div class="mobile-base-input-parent relative w-full" style="margin-top:20px;">
                <img src="../developer/account-password.png" class="mobile-base-input-icon">
                <input :type="showPassword ? '' : 'password'" class="mobile-base-input" v-model="changePassword" :placeholder="$t('mobileForgetPassword.setNewPassword')" @input="checkPassword" @focus="loadCodeImg">
                <button class="absolute right-0 top-0 bottom-0 mr-5 focus:outline-none" @click="togglePassword">
                    <img src="../images/not-visible.png" class="h-5" v-if="!showPassword" alt="Show Password" />
                    <img src="../images/visible.png" class="h-5" v-if="showPassword" alt="Hide Password" />
                </button>
            </div>
            <div class="w-full flex flex-col">
                <div class="flex flex-row items-center mt-5">
                    <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules1">
                    <img src="../developer/safe-access.png" class="infor-icon" v-else>
                    <span class="developer-account-input-tips" :style="showRules1?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip1')}}</span>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules2">
                    <img src="../developer/safe-access.png" class="infor-icon" v-else>
                    <span class="developer-account-input-tips" :style="showRules2?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip2')}}</span>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules3">
                    <img src="../developer/safe-access.png" class="infor-icon" v-else>
                    <span class="developer-account-input-tips" :style="showRules3?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip3')}}</span>
                </div>
            </div>
        </div>

        <div class="mt-10 w-full">
            <!-- 第一步信息认证按钮 -->
            <button class="mobile-button-full-blue" @click="InforNext" v-if="mobilechange">{{$t('mobileForgetPassword.informationAuthentication')}}</button>
            <!-- 第二步修改密码按钮 -->
            <button class="mobile-button-full-blue" @click="mobileChangePassword" v-if="!mobilechange">{{$t('mobileForgetPassword.resetPassword')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mobilechange: true, // 由于只有两页，方便起见用了true和false
            account: '', // 三合一了
            changePassword: '',
            showPassword: false,
            code: '', // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            timer: null, // 计时器
            imgCode: '', // 图像验证码
            imgCodeUrl: "", // 手机端图像验证码的url
            showRules1: true,
            showRules2: true,
            showRules3: true,


            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            errorText: '',
        }
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        goBack() {
            this.$parent.fatherMethod();
        },
        backToHomePage() {
            this.$router.push('/')
        },
        openModal() {
            this.loadCodeImg();
            this.imgCode = '';
            this.vmodal = true;
        },
        closeModal() {
            this.vmodal = false
        },
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            // 原正则表达式：/^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/
            const re1 = /^.{8,16}$/;
            const re21 = /^(?=.*[A-Za-z])(?=.*\d).{2,}$/; // 包含字母和数字
            const re22 = /^(?=.*[A-Za-z])(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含字母和符号
            const re23 = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字和符号
            const re24 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字、字母和符号
            const re3 = /\s/; // 包含空格
            this.showRules1 = !re1.test(this.changePassword);
            if (re21.test(this.changePassword) || re22.test(this.changePassword) || re23.test(this.changePassword) || re24.test(this.changePassword)) {
                this.showRules2 = false;
            } else {
                this.showRules2 = true;
            }
            this.showRules3 = re3.test(this.changePassword);
        },
        /*加载图像验证码*/
        loadCodeImg(){
            ///register/captcha
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },
        setMyInterval() { // 设置计时器
            this.time = 60
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false
        },
        getMobileCode() { // 三合一版身份验证-获取验证码
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileForgetPassword.alertLoading'),
            });
            if (this.account.length > 0) {
                this.setMyInterval()
                this.$http.post('/v1/register/verificationcode?loginname=' + this.account + '&code=' + this.imgCode)
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
                        this.vmodal = false;
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    }
                }).catch(err => {
                    this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
                })
            } else {
                this.$toast.fail(this.$t('mobileForgetPassword.alertNoAccountInput'));
            }
        },
        InforNext() {
            if (this.account.length === 0) {
                this.$toast(this.$t('mobileForgetPassword.alertNoAccountInput'));
                return
            } else if (this.code.length === 0) {
                this.$toast(this.$t('mobileForgetPassword.alertNoCodeInput'));
                return
            }
            const data = {
                loginname: this.account,
                code: this.code
            }
            this.$http.post('/v1/register/user-update-password-authentication', data)
                .then(res => {
                    if (res.data.data === '用户验证成功') {
                        this.mobilechange = false
                    } else {
                        this.$toast.fail(this.$t('AccountEmail.alertBindFail'))
                    }
                }).catch(err => {
                    this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
                })
        },
        mobileChangePassword() {
            if (this.changePassword.length === 0 || this.showRules) {
                this.$toast(this.$t('mobileForgetPassword.alertNewPasswordError'));
                return
            }
            const data = {
                loginname: this.account,
                loginpassword: this.changePassword
            }
            this.$http.post('/v1/register/update-password', data)
                .then(response => {
                    // 处理返回值
                    this.$toast.success(this.$t('mobileForgetPassword.alertResetSuccessfully'));
                    this.$router.go(-1)
                }).catch(error => {
                    this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
                });
        },




        /* getMobileCodeEmail() { // 手机端邮箱改密获取验证码
            this.showError = true;
            this.errorText = this.$t('mobileForgetPassword.alertLoading')
            this.vmodal = true
            this.setMyInterval()
            this.$http.post('/v1/index/get-verificationcode-topassword?useremail=' + this.email)
            .then(res => {
                this.showError = true;
                this.errorText = '获取验证码成功,请登录邮箱查询,5分钟后失效'
                this.vmodal = true
            }).catch(err => {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                this.vmodal = true
            })
        },
        getMobileCodePhone() { // 手机端手机号改密获取验证码
            this.setMyInterval()
            this.$http.post('/v1/register/phone-verificationcode?userphone=' + this.phone)
                .then(response => {
                    // 处理返回值
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertPhoneCodeSuccessfully')
                    this.vmodal = true
                }).catch(error => {
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                    this.vmodal = true
                });
        },
        phoneChange() {
            // 检查格式
            if (!this.phone.test(/^[0-9]*$/)) {
                this.showError = true;
                this.errorText = '手机号格式错误'
                this.vmodal = true
                return
            }
            if (this.changePassword === '' || this.showRules) {
                this.showError = true;
                this.errorText = '密码格式错误'
                this.vmodal = true
                return
            }
        }, */
        /* emailChange() {
            if (!this.email.includes('@')) {
                this.$toast(this.$t('mobileForgetPassword.alertEmailError'));
                return
            }
            if (this.changePassword === '' || this.showRules) {
                this.$toast(this.$t('mobileForgetPassword.alertPasswordError'));
                return
            }
            this.$http.get('/v1/index/all-reset-password?newpassword=' + this.changePassword + '&useremail=' + this.email + '&code=' + this.code)
            .then(res => {
                // 成功时的处理
                this.$toast.success(‘);
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
            });
        }, */
    },
}
</script>

<style scoped>
.my-box-tab {
    width: 100%;
    margin-top: 36px;
    padding: 32px 16px;
    @apply rounded-lg shadow-md bg-white flex flex-col items-center justify-center;
}
.developer-account-input-tips {
    font-size: 12px;
    color: red;
    white-space: normal;
}
.my-tab {
    @apply relative mx-3 p-6 rounded-lg shadow-md bg-white flex flex-col items-center justify-center;
}
.width-100 {
    width: 100%;
}
.my-input {
    width: calc(100% - 70px);
    @apply text-sm py-2 px-1 border-b border-gray-300 focus:outline-none;
}
</style>