<template>
    <div>
        <!-- 对方发的消息，放在左侧 -->
        <div v-if="!Message.isme" style="overflow: hidden" class="animate__animated animate__slideInLeft mx-3 px-2 py-3 mb-1 relative flex">
            <div class="flex-shrink-0">
                <img :src="Message.isme? myavatarUrl : Message.userphoto" class="w-12 h-12 rounded-full" />
            </div>
            <div class="flex flex-col ml-4">
                <div class="flex items-center mb-1">
                    <span class="font-semibold text-sm w-40">{{ Message.fromname }}</span>
                </div>
                <div class="mb-6 p-2 text-sm rounded-lg shadow-md bg-white">
                    <span v-if="Message.isurl==='text'">{{ Message.emailscontent }}</span>
                    <img :src="Message.emailscontent" @click="viewImage" v-else>
                </div>
                <span class="text-xs text-gray-500">{{ getDay(Message.createdate) }}</span>
            </div>
        </div>
        <!-- 我发的消息，放在右侧 -->
        <div v-else style="overflow: hidden" class="animate__animated animate__slideInRight mx-3 px-2 py-3 mb-1 relative flex justify-end">
            <div class="flex flex-col mr-4">
                <!-- <div class="text-right items-center mb-1">
                    <span class="font-semibold text-sm w-40">{{ Message.fromname }}</span>
                    <span class="text-xs text-gray-500 absolute top-3 left-3">{{ Message.createdate }}</span>
                </div> -->
                <div class="p-2 text-sm rounded-lg shadow-md bg-blue-600">
                    <span class="text-white" v-if="Message.isurl==='text'">{{ Message.emailscontent }}</span>
                    <img :src="Message.emailscontent" @click="viewImage" v-else>
                </div>
                <span class="text-xs text-gray-500">{{ getDay(Message.createdate) }}</span>
            </div>
            <div class="flex-shrink-0">
                <img :src="Message.isme? myavatarUrl : Message.userphoto" class="w-12 h-12 rounded-full" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import { ImagePreview } from 'vant';
export default {
    computed: {
        ...mapState({
            myavatarUrl: (state) => state.developer.avatarUrl,
        }),
    },
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component,
    },
    props: {
        // 父子组件传值
        Message: {
            // 包含信息在data查看
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userhead: require("../images/logo.png"),
        };
    },
    methods: {
        getDay(date) {
            return date.replace(/T/g, ' ');
        },
        viewImage() {
            ImagePreview([
                this.Message.emailscontent,
            ]);
        },
    },
};
</script>

<style scoped>
.mobile-message-box {
    height: 150px;
    width: 100%;
    position: relative;
    overflow: auto;
    border: none;
    border-bottom: 1px solid #dcdcdc;
}
/* -------------------------------我是接收方，头像以及用户名放在左边---------------------------------- */
/* 用户头像图片设为圆形显示 */
.mobile-message-box-head {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
    border-radius: 50%;
    overflow: hidden;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.mobile-message-box-head > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}
.mobile-message-box-user {
    /* 包括该条信息的发送人和时间 */
    position: absolute;
    top: 20px;
    left: 70px;
    display: flex;
    flex-direction: column;
}
.mobile-message-box-message {
    height: calc(100% - 70px);
    width: calc(100% - 70px);
    position: absolute;
    top: 60px;
    left: 70px;
    white-space: normal; /* 控制是否换行 */
    overflow-wrap: break-word; /* 控制是否在单词内换行 */
    overflow-y: auto;
}
/* -------------------------------我是发送方，头像以及用户名放在右边---------------------------------- */
/* 用户头像图片设为圆形显示 */
.mobile-message-box-head-right {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    right: 0;
    /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
    border-radius: 50%;
    overflow: hidden;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.mobile-message-box-head-right > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}
.mobile-message-box-user-right {
    /* 包括该条信息的发送人和时间 */
    position: absolute;
    top: 20px;
    right: 70px;
    display: flex;
    flex-direction: column;
}
.mobile-message-box-message-right {
    height: calc(100% - 70px);
    width: calc(100% - 70px);
    position: absolute;
    top: 60px;
    right: 70px;
    white-space: normal; /* 控制是否换行 */
    overflow-wrap: break-word; /* 控制是否在单词内换行 */
    overflow-y: auto;
    text-align: right;
}
</style>
