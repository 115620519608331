<template>
    <div class="mobile-pay-full mobile-base-background2">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobilePaySuccessful.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>

        <div class="mobile-pay-flex">
            <img src="../mImage/pay-success.png" class="mobile-pay-img">
            <span class="mobile-pay-topic">{{$t('MobilePaySuccessful.title')}}</span>
            <span class="mobile-pay-view" @click="gotoLand">查看土地</span>
            <div class="flex flex-col w-full rounded-t-xl mt-7" style="height:calc(100vh - 240px)">
                <div class="flex flex-row items-center w-full mt-3 px-3">
                    <div class="flex flex-row items-center">
                        <img src="../mImage/hot.png" style="height:16px;width:16px;">
                        <span style="font-size: 16px;font-weight: 600;margin-left: 6px;">{{$t('homePage.title')}}</span>
                    </div>
                    <div class="flex-grow flex justify-end">
                        <img src="../mImage/home-mode.png" style="height:16px;width:16px;" @click="mode = !mode">
                    </div>
                </div>
                <van-skeleton  v-for="i in 10" :key="i" class="mt-3" title avatar :row="3" :loading="!loaded" />
                <div class="flex-grow overflow-y-auto" v-if="loaded">
                    <div v-if="mode" class="flex justify-between flex-wrap px-3 pb-5"><MobileHomeLand1 class="w-1/2 mt-3" style="width:48%;" v-for="(landinfor,index) in LandInfor" :key="index" :LandInfor="landinfor" ref="MobileLand"></MobileHomeLand1></div>
                    <div v-else><MobileHomeLand2 class="mt-3" v-for="(landinfor,index) in LandInfor" :key="index" :LandInfor="landinfor" @turnToDetail="turnToDetail" ref="MobileLand"></MobileHomeLand2></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MobileHomeLand1 from "./MobileHomeLand.vue";
import MobileHomeLand2 from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        MobileHomeLand1, // 挂牌土地信息组件
        MobileHomeLand2, // 挂牌土地信息组件
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.id = this.$route.query.token;
    },
    mounted() {
        this.$http.get("/v1/paypal/callback/success?orderid=" + this.id).then((res) => {}).catch((err) => {});
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
        // 初始化热门推荐列表
        this.$http.post('/v1/sale/get-sale-selectlist-recommend',{
            pageSize: 20,
            pageNum: 1
        }).then(response => {
            if(response.data.status === 200) {
                this.LandInfor = response.data.data.rows;
                this.loaded = true;
            }
        }).catch(error => {
            this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
        })
    },
    data() {
        return {
            messages: 0,
            id: null, // 获取该商品详细信息
            loaded: false, // 骨架屏
            LandInfor: null, // 推荐商品列表
            mode: true, // 推荐商品的显示格式，true为半格，false为满格
        };
    },
    methods: {
        goBack() {
            this.$router.push('/mobile/land-market');
        },
        gotoLand() {
            this.$router.push("/mobile/my-land"); // 进入我的土地页面
        },
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?land=" + this.LandInfor[index].originallandid + "&merch=" + this.LandInfor[index].id;
            this.$router.push({
                path: newpath,
            });
        },
    },
}
</script>

<style>
.mobile-pay-full {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile-pay-flex {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile-pay-topic {
    font-size: 22px;
    font-weight: bold;
    padding: 20px;
}
.mobile-pay-view {
    font-size: 14px;
    color: #3662EC;
    cursor: pointer;
}
.mobile-pay-img {
    height: 50px;
    width: 50px;
}
</style>