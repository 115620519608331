<template>
    <div class="h-full">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileAddressManagement.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <shop-cart-infor></shop-cart-infor>
    </div>
</template>

<script>
import ShopCartInfor from "@/mobile/MobileAddressManagement";
export default {
    components: {
        ShopCartInfor, // 土地市场组件
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
        };
    },
}
</script>

<style>

</style>