<template>
  <div class="developer-common-safe">
    <div class="account-associate-container">
      <div class="developer-common-safe-title">{{ $t('AccountHelp.title') }}</div>
      <div class="qa-lists" v-for="(i, index) in item" :key="index">
        <h1 class="mobile-help-collapse-topic">
          {{ $t('AccountHelp.t' + (index + 1)) }}
        </h1>
        <div class="qa-list" v-for="j in i" :key="j">
          <img
            src="../developer/service-icon.png"
            style="height: 28px; width: 28px"
          />
          <div class="qa-list-box">
            <h4 class="font-bold">
              · {{ $t('AccountHelp.q' + (index + 1) + '-' + j) }}
            </h4>
            <pre style="white-space: break-spaces">{{
              $t('AccountHelp.a' + (index + 1) + '-' + j)
            }}</pre>
          </div>
        </div>
      </div>
    </div>

    <!-- 联系客服弹窗 -->
        <div class="developer-common-modal" v-if="serviceModal">
          <div class="developer-common-modal-box" style="width:400px;padding:30px;" v-if="textSend && imgSend">
            <span>{{$t('userhome.send')}}</span>
          </div>
          <div class="developer-common-modal-box" style="width:400px;padding:30px;" v-else>
            <div class="flex flex-row w-full">
              <img src="../developer/home-service-modal.png" style="height:28px;width:28px;">
              <span class="service-title">{{$t('userhome.service')}}</span>
              <div class="flex-grow flex justify-end">
                <img src="../developer/home-system-close.png" style="height:24px;width:24px;" @mouseover="changeCursor" @click="closeServiceModal">
              </div>
            </div>
            <div class="flex flex-col w-full relative" style="margin-top: 24px;">
              <textarea ref="inputBox"
                    class="flex-1 h-12 px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:shadow-outline"
                    :placeholder="$t('userhome.enter')" rows="5" v-model="text" @keyup.enter="sendMessage"></textarea>
              <input type="file" ref="image" @change="serviceHandleFileInputChange" hidden>
              <el-tooltip class="item" effect="dark" :content="$t('ChatCustomer.uploadimg')" placement="top-start">
                <img src="../developer/chat-sendImage.png" @click="serviceHandleClick" style="height:20px;width:20px;" class="service-imgUpload-icon">
              </el-tooltip>
            </div>
            <img :src="imageUrl" v-if="image" style="margin-top: 24px;max-height:100px;max-width:100px;">
            <div class="flex flex-row" style="margin-top:32px;">
              <button class="system-button1" @click="closeServiceModal">{{$t('forgetPassword.no')}}</button>
              <button class="system-button2" @click="serviceModal=false">{{$t('forgetPassword.yes')}}</button>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: [6, 2, 2, 4, 6, 1, 3, 4, 1, 2, 3, 5], // 循环次数，12个大标题，每个标题n个问题

      serviceModal: false, // 联系客服弹窗
      text: '', // 文本，发送的消息
      image: null, // 图片，发送的消息
      imageUrl: null, // 图片url
      textSend: false, // 双重验证，均为true说明发送完成
      imgSend: false, // 双重验证
    }
  },
  methods: {
    /* 关闭联系客服弹窗 */
    closeServiceModal() {
      this.text = '';
      this.image = null;
      this.imageUrl = null;
      this.serviceModal = false;
    },
    /* 上传图片功能样式 */
        serviceHandleClick() {
            // 触发文件选择器
            this.$refs.image.click();
        },
        serviceHandleFileInputChange() { // 获取用户选择的文件
            const size = 5 * 1024 * 1024; // 5MB
            const file = this.$refs.image.files[0];
            if (file.size < size) {
                this.image = this.$refs.image.files[0];
                // 将文件对象转换为 URL
                this.imageUrl = URL.createObjectURL(this.image);
            } else {
                this.$toast.fail(this.$t('ChatCustomer.alertImageSize'))
            }
        },
    async sendMessage() {
      const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('userhome.alertSending'),
                forbidClick: true,
            });
                if (this.image === null && this.text !== '') { // 只发送文本
                    this.$http.post('/v1/csmail/cswrite-message', {
                        emailscontent: this.text,
                        serviceid: ''
                    }).then(res => {
                        this.text = '';
                        this.textSend = true;
                        this.imgSend = true;
                        toastLoading.clear();
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                        toastLoading.clear();
                    });
                } else if (this.text === '' && this.image) { // 只发送图片
                    const formData = new FormData();
                    formData.append('picture', this.image);
                    this.$http.post("/v1/csmail/cswrite-picture", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((res) => {
                            this.image = null;
                            this.imageUrl = null;
                            this.textSend = true;
                            this.imgSend = true;
                            toastLoading.clear();
                        })
                        .catch((error) => {
                            this.$toast.fail('error')
                            toastLoading.clear();
                        });
                } else if (this.text !== '' && this.image) { // 发送图片和文本
                    const formData = new FormData();
                    formData.append('picture', this.image);
                    this.$http.post("/v1/csmail/cswrite-picture", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((res) => {
                            this.image = null;
                            this.imageUrl = null;
                            this.imgSend = true;
                            toastLoading.clear();
                        })
                        .catch((error) => {
                            this.$toast.fail('error')
                            toastLoading.clear();
                        });
                    this.$http.post('/v1/csmail/cswrite-message', {
                        emailscontent: this.text,
                        serviceid: ''
                    }).then(res => {
                        this.text = '';
                        this.textSend = true;
                        toastLoading.clear();
                    }).catch(error => {
                        this.$toast.fail(this.$t('ChatCustomer.alertSendFail'));
                        toastLoading.clear();
                    });
                }
        },
  },
}
</script>

<style scoped>
.qa-lists {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.qa-list {
  margin-top: 28px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.qa-list-box {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #e5e6e8;
  margin-left: 16px;
  padding-bottom: 28px;
}

.service-title {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
  margin-left: 10px;
}
.service-imgUpload-icon {
  position: absolute;
  bottom: 14px;
  right: 14px;
  cursor: pointer;
}
.system-button1 {
  width: 170px;
  height: 44px;
  border-radius: 8px;
  background-color: #F2F3F5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #344054;
}
.system-button2 {
  margin-left: 12px;
  width: 170px;
  height: 44px;
  border-radius: 8px;
  background-color: #165DFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
}
</style>
