<template>
    <div class="mobile-detail-home mobile-base-background2 p-0 bg-gray-50">
        <div class="fixed top-0 w-full flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileLandDetail.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="flex flex-col animate__animated animate__fadeInUp detail-main">
            <div class="relative mb-3" ref="background">
                <img :src="LandInfor.originalLand.landpicture" v-show="!mode">
                <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="mobile-detail-map rounded-lg  border-3 border-gray" v-show="mode"></canvas>
                <img src="../mImage/land-detail-left.png" class="absolute" style="left:5px;top:50%;transform: translate(0,-50%);" @click="mode=!mode;">
                <img src="../mImage/land-detail-right.png" class="absolute" style="right:5px;top:50%;transform: translate(0,-50%);" @click="mode=!mode;">
                <span class="absolute text-blue-500" style="bottom:5px;left:50%;transform: translate(-50%,0);">{{ mode?'1':'2' }}/2</span>
            </div>
            <!-- 价格 -->
            <div class="flex flex-row mt-4 mb-4">
                <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
            </div>
            <!-- 商品名称及开发商/平台标签 -->
            <div class="flex flex-row items-center">
                <span class="text-lg font-semibold">{{ LandInfor.landname }}</span>
                <div class="detail-merch-icon-developer" v-if="LandInfor.userBusiness.identifier === '企业'">{{$t('SellerHome.developer')}}</div>
                <div class="detail-merch-icon-platform" v-else-if="LandInfor.userBusiness.identifier === '平台'">{{$t('SellerHome.platform')}}</div>
            </div>
            <!-- 单位土地个数 -->
            <span class="text-gray-600 mt-1" style="font-size:12px;">{{$t('MobileLandDetail.landNum')}}: {{ LandInfor.blocks }}</span>
            <!-- 上架时间 -->
            <span class="text-gray-500" style="font-size:12px;">{{$t('MobileLandDetail.listTime')}}: {{ LandInfor.createtime }}</span>
            <!-- 详细信息及原始土地信息 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-3 mb-3 pb-5">
                <van-tabs v-model="tabControl">
                    <van-tab :title="$t('MobileLandDetail.detail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.area')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.number')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitlandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coor')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('MobileLandDetail.origindetail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.userBusiness.identifier?LandInfor.userBusiness.businessname:LandInfor.userBusiness.username }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('MobileLandDetail.sellerInfor')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.sellername')}}:</span>
                                <span class="text-gray-700 text-sm">{{ getSellerInfor('name') }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.selleremail')}}:</span>
                                <span class="text-gray-700 text-sm">{{ getSellerInfor('email') }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.sellerphone')}}:</span>
                                <span class="text-gray-700 text-sm">{{ getSellerInfor('phone') }}</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="mt-1 mb-3 pt-1">
                <span class="text-md font-semibold">{{$t('MobileLandDetail.landRights')}}</span>
                <div class="flex flex-col mb-3 mt-3">
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 mobile-detail-bottom border-t border-gray-300 py-3 flex items-center justify-between pr-3">
            <div class="px-2 py-1 mr-1 ml-3 text-xs rounded w-auto flex items-center flex-col" @click="OpenSeller">
                <img src="../mImage/merch-detail-seller.png" style="height:16px;width:16px;">
                <span class="mt-1">{{$t('MobileLandDetail.seller')}}</span>
            </div>
            <div class="px-2 py-1 mr-1 text-xs rounded w-auto flex items-center flex-col" @click="TalkSeller">
                <img src="../mImage/merch-detail-talk.png" style="height:16px;width:16px;">
                <span class="mt-1">{{$t('MobileLandDetail.private')}}</span>
            </div>
            <div>
                <button class="mobile-detail-bottom-button button-back1" @click="addLandCart" v-if="haveAddIn">{{$t('MobileLandDetail.add')}}</button>
                <button class="mobile-detail-bottom-button button-back2" v-if="!haveAddIn" @click="deleteLandCart">
                    <span>{{$t('MobileLandDetail.havaAdd')}}</span>
                </button>
            </div>
            <button class="mobile-detail-bottom-button button-back3" @click="onBuy">{{$t('MobileLandDetail.buy')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileLandDetail",
    created() {
        // 不要忘了获取传输过来的土地信息
        this.unitlandid = this.$route.query.merch;
    },
    mounted() {
        this.canvasSize=this.$refs.background.clientWidth;

        // 获取土地详情信息
        if (this.flag) {
            this.flag = false;
            this.$http
                .get("/v1/mail/select-onsale-by-saleid?saleid=" + this.unitlandid)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.LandInfor.createtime = this.LandInfor.createtime.split("")[0];
                    // this.$refs.background.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")"
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(" + this.LandInfor.unitpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 100);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        this.isInMyCart();

        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            selectPage: null, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            flag: true, // 防抖
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            mode: true, // 图片模式，true为显示小区地图，false为显示原始土地图片
            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
                onSale: {
                    seller: this.$t('MobileLandDetail.none'),
                },
                userBusiness: {
                    identifier: '企业',
                    businessname: '',
                    businessemail: '',
                    businessphone: '',
                }
            },
            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: 0, // 第三格土地详情栏tab控制参数，0是土地详情，1是原始土地详情，2是卖家信息
        };
    },
    methods: {
        getSellerInfor(tab) {
            if (this.LandInfor.userBusiness.identifier) {
                if (tab === 'name') {
                    if (this.LandInfor.userBusiness.businessname) {
                        return this.LandInfor.userBusiness.businessname;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'email') {
                    if (this.LandInfor.userBusiness.businessemail) {
                        return this.LandInfor.userBusiness.businessemail;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'phone') {
                    if (this.LandInfor.userBusiness.businessphone) {
                        return this.LandInfor.userBusiness.businessphone;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                }
            } else {
                if (tab === 'name') {
                    if (this.LandInfor.userBusiness.username) {
                        return this.LandInfor.userBusiness.username;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'email') {
                    if (this.LandInfor.userBusiness.useremail) {
                        return this.LandInfor.userBusiness.useremail;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'phone') {
                    if (this.LandInfor.userBusiness.userphone) {
                        return this.LandInfor.userBusiness.userphone;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                }
            }
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            canvas.style.backgroundImage = "url(" + this.LandInfor.unitpicture + ")";
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            canvas.width=this.canvasSize;
            canvas.height=this.canvasSize;
            this.gridSize = this.canvasSize / 22;
            ctx.strokeStyle = "#000000"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col + 1;
            const height = end.row - start.row + 1;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            ctx.fillStyle = "#FF9500";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        OpenSeller() {
            // 查看卖家
            this.$router.push({
                path: "/mobile/seller-home?merch=" + this.LandInfor.id + "&seller=" + this.LandInfor.sellerid,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?merch=" + this.LandInfor.id + "&receive=" + this.LandInfor.sellerid,
            });
        },
        addLandCart() {
            // 加入购物车
            const data = {
                unitlandid: this.LandInfor.unitlandid,
                // landnumber: this.LandInfor.originallandid,
                // landname: this.LandInfor.landname,
                // unitprice: "1000",
                // blocks: this.LandInfor.unitLand.landblocks,
                // totalprice: this.LandInfor.pricenum,
            };
            this.$http
                .post("/v1/shoppingcart/add-landcart", data, {
                    withCredentials: true,
                })
                .then((res) => {
                    this.haveAddIn = false;
                    this.$message({
                        message: this.$t('MobileLandDetail.alertaddIn'),
                        duration: 100,
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 将商品从购物车中删除
        deleteLandCart() {
            this.$http
                .delete("/v1/shoppingcart/delete-landcart?id=" + this.LandInfor.id)
                .then((res) => {
                    this.haveAddIn = true;
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        isInMyCart() {
            // 判断该商品是否已经加入购物车
            this.$http
                .get("/v1/shoppingcart/get-landcart")
                .then((res) => {
                    let LandInfor = res.data.data;
                    for (let i = 0; i < LandInfor.length; i++) {
                        if (LandInfor[i].unitlandid === this.LandInfor.unitlandid) {
                            this.haveAddIn = false;
                            break;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onBuy() {
            this.$router.push({
                path: "/mobile/confirm-order",
                name: "MobileConfirmOrder",
                query: {
                    LandInfor: JSON.stringify([this.LandInfor]),
                    totalPrice: JSON.stringify(this.LandInfor.totalprice),
                },
            });
        },
    },
};
</script>

<style>
.mobile-detail-home {
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.detail-main {
    padding: 20px;
    padding-top: 0;
    margin-top: 57px;
    padding-bottom: 50px;
}
/* ---------------------------------底部：超链接------------------------------- */
.mobile-detail-bottom {
    height: 50px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: fixed;
    bottom: 0;
}
.mobile-detail-bottom-button {
    height: 40px;
    width: 100px;
    margin-left: 10px;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
}
.button-back1 {
    background-color: #FFC300;
}
.button-back2 {
    background-color: #808080;
}
.button-back3 {
    background-color: #ED763D;
}
/* ---------------------------------第一格：土地地图------------------------------- */
.mobile-detail-map {
    background-size: cover;
}
</style>
