<template>
    <div class="developer-common-container">
        <div class="w-full flex flex-row">
            <div class="sell-list-card-parent">
                <img src="../developer/sell-list-land.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ LandInfor.originalLandNum }}</div>
                    <div class="sell-list-card-text2">{{$t('LandSellList.originnumber')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="margin-left:4%;">
                <img src="../developer/sell-list-unit.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ LandInfor.unitLandNum }}</div>
                    <div class="sell-list-card-text2">{{$t('LandSellList.subdividnumber')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="margin-left:4%;">
                <img src="../developer/sell-list-selling.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ LandInfor.saleUnitLandNum }}</div>
                    <div class="sell-list-card-text2">{{$t('LandSellList.sellingnumber')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="margin-left:4%;">
                <img src="../developer/sell-list-sale.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ LandInfor.soldUnitLandNum }}</div>
                    <div class="sell-list-card-text2">{{$t('LandSellList.sellednumber')}}</div>
                </div>
            </div>
        </div>
        <div class="sell-list-tabs">
            <div class="sell-list-tab" :class="isTab('未挂牌')" @click="tabsControl('未挂牌')">{{$t('LandSellList.title')}}</div>
            <div class="sell-list-tab" style="margin-left:120px;" :class="isTab('已挂牌')" @click="tabsControl('已挂牌')">{{$t('LandSellList.name')}}</div>
        </div>
        <div class="developer-search-container" style="margin-top:30px;">
            <div class="developer-search-box">
                <div class="developer-search-input">
                    <span class="developer-search-input-label">{{$t('LandSellList.blocks')}}</span>
                    <el-select v-model="originallandid" :placeholder="$t('LandSellList.createdate')" style="width:250px;">
                        <el-option v-for="item in restaurants" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="developer-search-input" style="margin-top: 20px;">
                    <span class="developer-search-input-label">{{$t('LandSellList.goin')}}</span>
                    <el-input v-model="search.smalllandid" :placeholder="$t('LandSellList.unsale')" style="width:250px;"></el-input>
                </div>
            </div>
            <div class="developer-search-box" style="margin-left:15px;">
                <div class="developer-search-input">
                    <span class="developer-search-input-label">{{$t('LandSellList.saledetail')}}</span>
                    <el-date-picker
                        v-model="search.date"
                        type="daterange"
                        align="right"
                        unlink-panels
                        :range-separator="$t('LandSellList.mergename')"
                        :start-placeholder="$t('LandSellList.price')"
                        :end-placeholder="$t('LandSellList.saletime')"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
                <!-- 批量选择按钮 -->
                <div class="developer-search-input" style="margin-top: 20px;" v-if="mode">
                    <button class="developer-common-card-button infor1-button-blueground" @click="mode = false">{{$t('all.batchMode')}}</button>
                </div>
                <!-- 批量模式按钮 -->
                <div class="developer-search-input" style="margin-top: 20px;" v-else>
                    <button class="developer-common-card-button infor1-button-blueground" v-if="whichtab === '未挂牌'" @click="openSellModalSeg(1)">{{$t('all.sale')}}</button>
                    <button class="developer-common-card-button infor1-button-redground" v-if="whichtab === '已挂牌'" @click="openSellModalSeg(2)">{{$t('LandSellDetail.unsale')}}</button>
                    <button class="developer-common-card-button infor1-button-whiteground" style="margin-left: 12px;" v-if="whichtab === '已挂牌'" @click="openSellModalSeg(3)">{{$t('LandSellDetail.changeprice')}}</button>
                    <button class="developer-common-card-button infor1-button-grayground" style="margin-left: 12px;" @click="cancelSeg">{{$t('MobileOrderList.cancelSeg')}}</button>
                    <el-checkbox style="margin-left: 12px;" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('all.selectPage')}}</el-checkbox>
                </div>
            </div>
            <div class="developer-search-box items-end" style="flex:1;">
                <div class="developer-search-buttonbox developer-search-input" style="height:100%;width:auto;">
                    <el-button type="primary" @click="updatePage">
                        <img src="../developer/developer-search.png" class="admin-manage-search-icon">
                        <span style="vertical-align: middle;">{{$t('LandSellList.unitdetail')}}</span>
                    </el-button>
                    <el-button type="info" plain style="margin-left: 20px;" @click="resetUser">
                        <img src="../developer/developer-refresh.png" class="admin-manage-search-icon">
                        <span style="vertical-align: middle;">{{$t('LandSellList.area')}}</span>
                    </el-button>
                </div>
            </div>
        </div>
        <div class="w-full flex-grow">
            <div class="developer-tabs-content">
                <el-row :gutter="15" class="developer-tabs-content-row">
                    <el-col :span="3"><i>{{$t('LandSellList.landpicture')}}</i></el-col>
                    <el-col :span="3"><i>{{$t('LandSellList.landlength')}}</i></el-col>
                    <el-col :span="4"><i>{{$t('LandSellList.unit')}}</i></el-col>
                    <el-col :span="4"><i>{{$t('LandSellList.unitlandname')}}</i></el-col>
                    <el-col :span="3">
                        <i v-if="whichtab === '未挂牌'">{{$t('LandSellList.origindetail')}}</i>
                        <i v-else>{{$t('LandSellList.totalprice')}}</i>
                    </el-col>
                    <el-col :span="2"><i>{{$t('LandSellList.originallandid')}}</i></el-col>
                    <el-col :span="5"><i>{{$t('LandSellList.originallandname')}}</i></el-col>
                </el-row>
                <el-skeleton :loading="loading" :rows="10" animated />
                <div v-if="!loading && showLength > 0" ref="overflowList" style="max-height: 50vh;overflow-y:auto;overflow-x:hidden;">
                    <el-row :gutter="15" v-for="(apply, index) in view" :key="index" class="developer-tabs-content-row">
                        <el-checkbox class="land-sell-checkbox" style="position: absolute;" v-model="segChecked[index]" @change="changeCheckBoxValue(segChecked[index],apply.id)" v-show="!mode"></el-checkbox>
                        <div class="developer-tabs-content-col">
                            <el-col :span="3"><i style="word-wrap: nowrap;">{{ apply.originallandid }}</i></el-col>
                            <el-col :span="3"><i style="word-wrap: nowrap;">{{ apply.communitynumber }}</i></el-col>
                            <el-col :span="4"><i style="word-wrap: nowrap;">{{ apply.landcoordinates }}</i></el-col>
                            <el-col :span="4"><i style="word-wrap: nowrap;">{{ apply.mergelandname }}</i></el-col>
                            <el-col :span="3" v-if="whichtab === '未挂牌'"><i style="word-wrap: nowrap;">{{ apply.status }}</i></el-col>
                            <el-col :span="3" v-else>
                                <i style="word-wrap: nowrap;" v-if="$setPayment === 'am'">${{ apply.onSale.totalprice }}</i>
                                <i style="word-wrap: nowrap;" v-else-if="$setPayment === 'zh'">￥{{ apply.onSale.totalprice * $exchangeRate }}</i>
                            </el-col>
                            <el-col :span="2"><i style="word-wrap: nowrap;">{{ apply.landblocks }}</i></el-col>
                            <el-col :span="5" style="word-wrap: nowrap;">
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="gotoDetail(apply.id)">{{$t('LandSellList.ownerid')}}</i>
                                &nbsp;
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="gotoLand(apply.originallandid,apply.communitynumber,apply.id)">{{$t('LandSellList.detailedaddress')}}</i>
                                &nbsp;
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="openSellModal(1,apply)" v-if="apply.status === '未挂牌'">{{$t('all.sale')}}</i>
                                &nbsp;
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="openSellModal(3,apply)" v-if="apply.status === '已挂牌'">{{$t('LandSellDetail.changeprice')}}</i>
                                &nbsp;
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="openSellModal(2,apply)" v-if="apply.status === '已挂牌'">{{$t('LandSellList.geo')}}</i>
                            </el-col>
                        </div>
                    </el-row>
                </div>
                <div class="flex flex-grow justify-center items-center" style="font-size: 30px;font-weight: bold;" v-else-if="!loading && showLength === 0">
                    {{ nosearch }}
                </div>
                <el-pagination
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    background
                    :current-page.sync="currentPage"
                    :page-sizes="[50, 70, 90, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="showLength">
                </el-pagination>
            </div>
        </div>


        <!-- 挂牌弹框-显示和隐藏状态根据 smodal 变量决定 -->
        <div class="land-sell-modal" v-if="smodal">
            <div class="land-sell-modal-box">
                <!-- 标题 -->
                <div class="land-sell-modal-box-title">
                    <span>{{$t('LandMap3.listtitle')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" @click="closeSaleModal" class="cursor-pointer">
                    </div>
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('useraccount.merch')}}</div>
                    <input type="text" v-model="salename" maxlength="20" show-word-limit class="land-sell-modal-box-items-input" :placeholder="$t('all.default') + ':' + $t('LandSellDetail.name') + '+ id'">
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.buytype')}}</div>
                    <el-select v-model="type" :placeholder="$t('LandMap3.pleasechoose')" style="width: 200px;margin-left:10px;">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- 单位土地价格 -->
                <div class="land-sell-modal-box-items" v-if="!isSeg">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.unitprice')}}</div>
                    <input type="number" v-model="saleUnitPrice" maxlength="10" show-word-limit class="land-sell-modal-box-items-input" @input="changeSellPrice">
                </div>
                <!-- 单位土地块数 -->
                <div class="land-sell-modal-box-items" v-if="!isSeg">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.unitnumber')}}</div>
                    <div class="land-sell-modal-box-items-span">
                        <span class="text-red-500">{{ selectunitland.landblocks }}</span>
                        <span class="ml-2">{{$t('LandMap3.unitunit')}}</span>
                    </div>
                </div>
                <!-- 售价 -->
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.totalpeice')}}</div>
                    <input type="number" v-model="sellprice" maxlength="15" show-word-limit class="land-sell-modal-box-items-input" @input="changeSaleUnitPrice">
                </div>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <span style="font-size: 10px;color: red;margin-top: 8px;">*{{$t('LandMap3.listtip1')}}</span>
                <span style="font-size: 10px;color: red;">*{{$t('LandMap3.listtip2')}}</span>
                <span style="font-size: 10px;color: red;">*{{$t('LandMap3.listtip3')}}</span>
                <button class="land-sell-button" @click="sellFunction">{{$t('LandMap3.ok')}}</button>
            </div>
        </div>
        <!-- 修改价格的弹窗，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box" v-if="needCode">
                <span>{{$t('all.verifyId')}}</span>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-input v-model="newprice" class="mt-5" :placeholder="$t('LandSellDetail.pricenum')"></el-input>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('LandSellDetail.changeprice')}}</span>
                <el-input v-model="newprice" class="mt-5" :placeholder="$t('LandSellDetail.pricenum')"></el-input>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
        </div>
        <!-- 二次确认下架商品的弹窗 -->
        <div class="developer-common-modal" v-if="bmodal">
            <div class="developer-common-modal-box" v-if="needCode">
                <span>{{$t('all.verifyId')}}</span>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('all.removeNow')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('LandSellDetail.alertunsale')}}</span>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    mounted() {
        this.$http.get('/v1/business/select-business-land-num') // 获取土地信息
            .then(res => {
                this.LandInfor = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        this.$http.get('/v1/original-land/select-originallandid-list') // 获取所有该开发商用户的原始土地编号
            .then(res => {
                const data = res.data.data;
                for(let i=0;i<data.length;i++) {
                    this.restaurants.push({value: data[i],label: data[i]});
                }
                this.originallandid = this.restaurants[0].value;
                this.updatePage();
            }).catch(error => {
                this.$toast.fail(this.$t('LandSellList.tip'));
            });
    },
    watch: {
        originallandid(n) {
            this.pageSize = 50;
            this.currentPage = 1;
            this.search.smalllandid = '';
            this.search.date = '';
            this.updatePage();
        },
    },
    data() {
      return {
        loading: true, // 加载中
        LandInfor: {
            originalLandNum: 6,
            unitLandNum: 541,
            saleUnitLandNum: 49,
            soldUnitLandNum: 0
        },
        apply: [{
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },], // 测试数据,后端获取的数据
        view: null, // 显示的数据
        nosearch: this.$t('LandSellList.tip1'), // 查询不到时显示的文本



        whichtab: '未挂牌', // 标签
        mode: true, // 查看模式和批量选择模式，true为查看，false为批量
        pageSize: 50, // 每页条数
        currentPage: 1,// 页码
        showLength: 0, // 总条数
        unitlandids: [], // 批量选择模式选中的分块土地编号集
        segChecked: [], // 批量选择模式选择坐标集
        checkAll: false, // 全选属性
        isIndeterminate: false,
        allUnitlandids: [], // 当前页全部分块土地编号集

        vmodal: false, // 修改售价弹窗
        newprice: '', // 价格
        bmodal: false, // 下架弹窗

        smodal: false, // 挂牌弹窗
        type: '立即购买', // 挂牌-购买形式
        options: [{value: '立即购买',label: this.$t('LandMap3.buytype1')},], // 挂牌-购买形式的选择集
        saleUnitPrice: 0, // 单位土地售价
        sellprice: 0, // 售价
        salename: '', // 设置的商品名称

        isSeg: false, // 是否为批量触发事件
        selectunitland: null, // 单个商品触发事件存储的id

        needCode: true, // 是否需要验证码
        code: '', // 挂牌验证码
        timeCount: 0, // 倒计时
        timer: null, // 定时器

        originallandid: '', // 原始土地编号，便于监听移到外面
        search: {
            smalllandid: '',
            date: '',
        },
        restaurants: [], // 原始土地编号的选择域
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: this.$t('LandSellList.tip2'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('LandSellList.tip3'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('LandSellList.tip4'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.mode = true;
            this.checkAll = false;
            this.unitlandids = [];
            this.segChecked = [];
            this.isIndeterminate = false;
            this.whichtab = n;
            this.currentPage = 1;
            this.pageSize = 50;
            this.search.smalllandid = '';
            this.search.date = '';
            this.updatePage();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'sell-list-tab-select'
            } else {
                return
            }
        },
        updatePage() {
            this.loading = true;
            let data = {
                status: this.whichtab,
                originallandid: this.originallandid,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.smalllandid !== '') {
                data["id"] = this.search.smalllandid
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0]
                data["endtime"] = this.search.date[1]
            }
            this.$http.post('/v1/original-land/select-unitlandlist-byolid',data) // 查询该原始土地下所有分块土地
                .then(res => {
                    this.loading = false;
                    this.view = res.data.data.rows;
                    this.showLength = res.data.data.total;
                    this.allUnitlandids = res.data.data.rows.map((item) => {
                        this.segChecked.push(false);
                        return item.id;
                    });
                }).catch(error => {
                    this.$toast(this.$t('LandSellList.tip5'));
                });
        },
        resetUser() {
            this.search.smalllandid = '';
            this.search.date = '';
            this.currentPage = 1;
            this.updatePage();
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            // 更新显示的数据
            this.updatePage();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            // 更新显示的数据
            this.updatePage();
        },
        gotoDetail(id) { // 查看详情
            this.$router.push('/developer/my-sale/detail?land=' + id);
        },
        gotoLand(originallandid,area,unitlandid) { // 进入土地块
            this.$router.push({
                path:'/land-operate/area-map?land=' + originallandid + '&area=' + area + '&id=' + unitlandid})
        },

        // 批量选择
        changeCheckBoxValue(boolean,value) {
            if (boolean) {
                this.unitlandids.push(value);
            } else {
                this.unitlandids = this.unitlandids.filter(item => item !== value);
            }
            let checkedCount = 0; // 初始化计数器
            this.segChecked.forEach(item => {
                if (item === true) {
                    checkedCount++;
                }
            });
            this.checkAll = (checkedCount === this.pageSize);
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.pageSize;
        },
        handleCheckAllChange(val) {
            this.segChecked = [];
            if (val) {
                this.unitlandids = this.allUnitlandids;
                for (let i = 0;i < this.pageSize;i++) {
                    this.segChecked.push(true);
                }
            } else {
                this.unitlandids = [];
                for (let i = 0;i < this.pageSize;i++) {
                    this.segChecked.push(false);
                }
            }
            this.isIndeterminate = false;
        },
        // 取消批量
        cancelSeg() {
            this.mode = false;
            this.checkAll = false;
            this.unitlandids = [];
            this.segChecked = [];
            for (let i =0;i < this.pageSize;i++) {
                this.segChecked.push(false);
            }
            this.isIndeterminate = false;
        },
        // 打开批量模式弹窗
        openSellModalSeg(tab) {
            this.isSeg = true;
            if (this.unitlandids.length < 1) {
                this.$toast.fail(this.$t('LandSellList.tip9'));
                return;
            }
            if (tab === 1) { // 挂牌模式
                this.codeNeedOrNot('挂牌');
            } else if (tab === 2) { // 下架模式
                this.codeNeedOrNot('下架');
            } else if (tab === 3) { // 修改价格模式
                this.codeNeedOrNot('修改价格');
            }
        },
        // 打开单土地模式弹窗
        openSellModal(tab,value) {
            this.isSeg = false;
            this.selectunitland = value;
            if (tab === 1) { // 挂牌模式
                this.codeNeedOrNot('挂牌');
            } else if (tab === 2) { // 下架模式
                this.codeNeedOrNot('下架');
            } else if (tab === 3) { // 修改价格模式
                this.codeNeedOrNot('修改价格');
            }
        },

        // 挂牌弹窗修改单位土地价格时总价格也会变
        changeSellPrice() {
            this.sellprice = this.saleUnitPrice * this.selectunitland.landblocks;
        },
        changeSaleUnitPrice() {
            if (this.selectunitland) {
                let quotient = this.sellprice / this.selectunitland.landblocks;
                this.saleUnitPrice = quotient.toFixed(2);
            }
        },

        // 获取验证码前打开弹窗需要检测是否需要验证码
        codeNeedOrNot(tab) {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/if-operation')
                .then(res => {
                    loadingToast.clear();
                    this.needCode = res.data.data;
                    if (tab === '挂牌') {
                        this.smodal = true;
                    } else if (tab === '下架') {
                        this.bmodal = true;
                    } else if (tab === '修改价格') {
                        this.vmodal = true;
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        // 获取验证码
        getVerificationCode() {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/verification-code')
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.startCountdown();
                        if (res.data.data === '邮箱验证码发送成功') {
                            this.$toast.success(this.$t('LandSellList.sendemail'));
                        } else {
                            this.$toast.success(this.$t('LandSellList.sendphone'));
                        }
                    } else {
                        this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.timeCount = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                    this.timeCount--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        // 三种事件，两种模式，六种触发
        sellFunction() {
            if (this.isSeg) { // 批量
                if (this.vmodal) { // 修改价格弹窗开启中
                    this.changePrice(this.unitlandids);
                } else if (this.bmodal) { // 下架弹窗开启中
                    this.unsale(this.unitlandids);
                } else if (this.smodal) { // 挂牌弹窗开启中
                    this.sellLand(this.unitlandids);
                }
            } else {
                if (this.vmodal) { // 修改价格弹窗开启中
                    this.changePrice([this.selectunitland.id]);
                } else if (this.bmodal) { // 下架弹窗开启中
                    this.unsale([this.selectunitland.id]);
                } else if (this.smodal) { // 挂牌弹窗开启中
                    this.sellLand([this.selectunitland.id]);
                }
            }
        },
        // 挂牌事件
        sellLand(unitlandids) {
            let data = {
                unitlandids: unitlandids,
                salesmethods: this.type,
                pricenum: this.sellprice,
            }
            if (needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            if (this.salename !== '') {
                data["commodityname"] = this.salename;
            }
            this.$http.post('/v1/sale/add-sale-list',data)
            .then(res => {
                if (res.data.status === 200) {
                    this.$toast.success(this.$t('LandMap3.saleSuccess'));
                    window.location.reload(); // 刷新页面
                } else {
                    this.$toast.fail(this.$t('LandMap3.saleFail'));
                }
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.neterror'));
            });
        },
        // 关闭挂牌弹窗
        closeSaleModal() {
            this.saleUnitPrice = '';
            this.sellprice = '';
            this.salename = '';
            this.newprice = '';
            this.code = '';
            clearInterval(this.timer);
            this.timeCount = 0;
            this.smodal = false;
            this.vmodal = false;
            this.bmodal = false;
        },
        // 下架
        unsale(unitlandids) {
            let data = {
                unitlandids: unitlandids,
            }
            if (needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            this.$http.DELETE('/v1/sale/delete-sale-list',data)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('all.removeSuccessful'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(this.$t('all.removeFail'));
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        // 修改价格
        changePrice(unitlandids) {
            const re = /^(?:(?:0|[1-9]\d*)(?:\.\d+)?|\d+(?:\.\d*)?)$/;
            if (this.newprice !== '' && re.test(this.newprice)) {
                let data = {
                    unitlandids: unitlandids,
                    salesmethods: this.type,
                    pricenum: this.newprice
                }
                if (this.needCode && this.code === '') {
                    this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                    return;
                } else if (this.needCode) {
                    data["code"] = this.code;
                }
                this.$http.put('/v1/sale/update-sale-list',data)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MyInfor.letter19'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                }).catch(error => {
                    this.$toast(this.$t('computerlogin.alertAxiosError'));
                });
            } else {
                this.$toast.fail(this.$t('LandSellDetail.tip4'));
            }
        },
    },

    destroyed() {
        clearInterval(this.timer);
    },
}
</script>

<style>
/* --------------------------------------------最上面的四张卡片-------------------------------------------- */
.sell-list-card-parent {
    height: 120px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(71, 109, 255, 0.05);
    width: 22%;
    padding: 20px;
    display: flex;
    flex-direction: row;
}
.sell-list-card-text {
    margin-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sell-list-card-text1 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    text-align: center;
}
.sell-list-card-text2 {
    margin-top: 5px;
    font-size: 16px;
    color: #AFAEB3;
}

/* --------------------------------------------选项卡,选择是查询未挂牌还是已挂牌-------------------------------------------- */
.sell-list-tabs {
    margin-top: 30px;
    height: 60px;
    width: 100%;
    border-bottom: 2px solid rgba(83, 83, 83, 0.1);
}
.sell-list-tab {
    display: inline-block;
    height: 100%;
    min-width: 110px;
    font-size: 20px;
    color: #3D3D3D;
    cursor: pointer;
}
.sell-list-tab-select {
    font-size: 24px;
    font-weight: 600;
    color: #3D3D3D;
    border-bottom: 2px solid #165DFF;
}



/* --------------------------------------------搜索栏样式-------------------------------------------- */
.developer-search-container {
    display: flex;
    flex-direction: row;
}
.developer-search-box {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.developer-search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 356px;
    height: 32px;
}
.developer-search-input-label {
    width: 150px;
}
.developer-search-buttonbox {
    justify-content: end;
    border-left: 1px solid rgb(223 223 223);
    padding-left: 50px;
}
.developer-list-flow {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 50vh;
}
@media (max-width: 1980px) or (max-height: 1140px) {
    .developer-list-flow {
        max-height: 100vh;
    }
}

.land-sell-checkbox {
    position: absolute;
    left: 12px;
}
</style>