<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>企业管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">企业列表查看</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-topic">企业资质信息</div>
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('已认证')" @click="tabsControl('已认证')">
                        <span @mouseover="changeCursor">已认证</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('未认证')" @click="tabsControl('未认证')">
                        <span @mouseover="changeCursor">未认证</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已驳回')" @click="tabsControl('已驳回')">
                        <span @mouseover="changeCursor">已驳回</span>
                    </div>
                    <!-- <div class="admin-manage-tabs-head-add">
                        <button class="admin-manage-tabs-head-add-button" @click="addCompany" @mouseover="changeCursor">
                            <i class="el-icon-plus"></i>
                            <span>新增企业</span>
                        </button>
                    </div> -->
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">企业编号</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入企业编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">企业名称</span>
                            <el-input style="width:256px;" v-model="search.name" placeholder="请输入企业名称"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input" style="margin-bottom: 10px;">
                            <span class="admin-manage-search-input-label">时间范围</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadCompany">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetKey">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="5"><span>上传时间</span></el-col>
                        <el-col :span="6"><span>企业编号</span></el-col>
                        <el-col :span="6"><span>公司名称</span></el-col>
                        <el-col :span="4"><span>企业联络人手机号码</span></el-col>
                        <el-col :span="3"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in showCompany" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="5">
                                    <el-tooltip class="item" :content="apply.createdate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.createdate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="6">
                                    <el-tooltip class="item" :content="apply.businessid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.businessid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="6">
                                    <el-tooltip class="item" :content="apply.corporationname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.corporationname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.businessphone" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.businessphone }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3"><span @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.id)">查看详情</span></el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        let data = {
            verificationstatus: this.companyChild.whichtab,
            pagesize: this.pageSize,
            pagenum: this.currentPage
        }
        this.$http.post('/v1/administrator/business-listview',data)
            .then(res => {
                this.showCompany = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
                this.loading = false;
            });
    },
    data() {
        return {
            showCompany: [], // 显示的当页公司信息，根据页码页距控制
            showLength: 0, // 长度
            currentPage: 1, // 页码
            pageSize: 50, // 每页条数
            loading: true, // 控制加载项
            url: 'http://192.168.1.145:8092/v1/business/see-images?', // 所有企业图片的baseUrl
            pdfurl: 'http://192.168.1.145:8092/v1/business/see-pdffile?',


            companyChild: { // 父子组件传值
                whichtab: '已认证', // 三种状态,已认证、认证中、已驳回
                businessid: '', // 向子组件传入土地编号，以获取土地上传信息
                isReview: false, // 土地上传资格审查详情页是否点入
                companyName: '',
                ownershipCertificate: '',
                companyContact: '',
                companyContactPhone: '',
                frontIdImg: require('../assets/no-image.png'), // 身份证正面照
                isfrontIdImg: false,
                reverseIdImg: require('../assets/no-image.png'), // 身份证反面照
                isreverseIdImg: false,
                landcertificateImg: '', // 土地证明
                CPRTImg: '', // 公司注册信息与注册证明
                PFCTImg: '', // 平台合同扫描件
            },
            search: {
                id: '', // 企业编号
                name: '', // 企业名称
                date: '',
            },
            pickerOptions: { // 选择日期的组件代码
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },

            example: {
                "id": 10007,
                "businessid": "c55468def68d414ea9cc4b74b573e52e",
                "businessname": "陈波",
                "businessemail": null,
                "businesspassword": null,
                "businessphone": "18403828061",
                "corporationname": "大图智能科技有限公司",
                "housingownership": "123456789",
                "businesscard1": "/usr/local/land/landpicture/20230404/73663ebd471246a38fac4aef32623037.png",
                "businesscard2": "/usr/local/land/landpicture/20230404/c2b0f690ac9b4019ac07af1ce51dcae1.png",
                "companyregistration": "/usr/local/land/landpicture/20230404/51909d17112b4ac68861a5266c449653.png",
                "platformcontract": "/usr/local/land/landpicture/20230404/c9c7c735ea79493f8fe32e1812f3bb8e.png",
                "createdate": "2023-04-04 14:17:37",
                "status": "正常",
                "remark": "公司材料不充分",
                "verificationstatus": "已驳回",
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0
            },
        };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        loadCompany() { // 加载企业列表
            let data = {
                verificationstatus: this.companyChild.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.id !== '') {
                data["businessid"] = this.search.id
            }
            if(this.search.name !== '') {
                data["corporationname"] = this.search.name
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0]
                data["endtime"] = this.search.date[1]
            }
            this.$http.post('/v1/administrator/business-listview',data,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                this.showCompany = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("加载失败，请刷新页面或重新登录");
                this.loading = false;
            });
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.companyChild.whichtab = n;
            this.companyChild.isReview = false;
            this.currentPage = 1;
            this.loading = true;
            this.loadCompany();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.companyChild.whichtab) {
                return 'admin-manage-tabs-head-item-select';
            } else {
                return;
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loading = true;
            this.loadCompany();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            this.loading = true;
            this.loadCompany();
        },
        landReview(id) { // 打开展示详情
            if (this.companyChild.whichtab === '已驳回') {
                this.$router.push('/admin/company/detail-r?id=' + id);
            } else {
                this.$router.push('/admin/company/detail?id=' + id + '&mode=' + this.companyChild.whichtab);
            }
        },
        addCompany() {
            this.$router.push('/admin/company-register')
        },
        resetKey() {
            this.search.id = '';
            this.search.name = '';
            this.search.date = '';
            this.currentPage = 1;
            this.loading = true;
            this.loadCompany();
        },
    },
}
</script>

<style>
</style>