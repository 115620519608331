<template>
    <div class="background">
        <canvas ref="canvas" :width="canvaswidth" :height="canvasheight"></canvas>
    </div>
</template>

<script>
export default {
    props: { // 背景图片
        building: { // 加载到背景图片上的建筑
            type: Array,
            required: true,
            default: [],
        },
        canvasheight: { // 画布高度
            type: Number,
            required: true,
            default: 0,
        },
        canvaswidth: { // 画布宽度
            type: Number,
            required: true,
            default: 0,
        },
        gridSize: { // 网格尺寸
            type: Number,
            required: true,
            default: 0,
        },
    },
    watch: {
        building(n) {
            this.drawBuildings();
        },
    },
    methods: {
        drawBackgroundGrids() { // 根据网格绘制背景
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            for (let i = 0;i < this.canvaswidth / this.gridSize;i++) {  // 绘制背景网格
                for (let j = 0;j < this.canvasheight / this.gridSize;j++) {
                    ctx.strokeRect(i*this.gridSize,j*this.gridSize,this.gridSize,this.gridSize)
                }
            }
        },
        drawBuildings() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            // 建筑图片位置信息
            const buildings = this.building;

            // 绘制建筑图片
            buildings.forEach(building => {
                const buildingImage = new Image();
                buildingImage.src = require(`${building.src}`);
                buildingImage.onload = () => {
                    ctx.drawImage(buildingImage, building.x, building.y, building.width, building.height);
                };
            });

            // 监听鼠标移动事件
            const handleMouseMove = (e) => {
                const rect = canvas.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                let isHovering = false;

                buildings.forEach(building => {
                    if (x > building.x && x < (building.x + building.width) && y > building.y && y < (building.y + building.height)) {
                        canvas.style.cursor = 'pointer';
                        ctx.fillText(building.text, x, y);
                        isHovering = true;
                    }
                });

                if (!isHovering) {
                    canvas.style.cursor = 'default';
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    buildings.forEach(building => {
                        const buildingImage = new Image();
                        buildingImage.src = require(buildings.src);
                        buildingImage.onload = () => {
                            ctx.drawImage(buildingImage, building.x, building.y, building.width, building.height);
                        };
                    });
                }
            };

            canvas.addEventListener('mousemove', handleMouseMove);

            canvas.addEventListener('mouseleave', () => {
                canvas.style.cursor = 'default';
                canvas.removeEventListener('mousemove', handleMouseMove);
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                buildings.forEach(building => {
                    const buildingImage = new Image();
                    buildingImage.src = require(buildings.src);
                    buildingImage.onload = () => {
                        ctx.drawImage(buildingImage, building.x, building.y, building.width, building.height);
                    };
                });
            });
        },
    },
}
</script>

<style scoped>
.background {
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-image: url('./v2Image/grass.jpg');
    position: relative;
}
</style>