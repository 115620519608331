<template>
    <div class="pb-20 h-screen flex flex-col mobile-base-background2">
        <van-nav-bar :title="$t('MobileMessageWindow.topic1') + (LandInfor.seller===undefined?$t('MobileMessageWindow.name'):LandInfor.seller) + $t('MobileMessageWindow.topic2')" :left-text="$t('MobileMessageWindow.back')" left-arrow @click-left="goBack" />
        <div class="flex flex-row items-center mobile-base-back">
            <img src="../mImage/login-back.png" class="justify-start" style="height:25px;width:25px;" @click="goBack">
            <div class="mobile-goback-title">{{ getTitle() }}</div>
        </div>
        <ShopCartInfor class="animate__animated animate__fadeInDown" :LandInfor="LandInfor" :showSelect="false" :show-delete="false" :index="0" ref="MobileLand" />
        <div class="mt-2 pt-2 mx-3 overflow-y-auto flex-grow" ref="messageList">
            <MobileMessageInfor v-for="(message, index) in Message" :key="index" :Message="message" />
        </div>
        <div class="fixed bottom-2 left-0 right-0 flex justify-center">
            <div class="message-user-input-parent">
                <input type="text" class="w-full" v-model="messageReply" :placeholder="$t('MobileServiceA.placefolter')">
                <img src="../mImage/message-send.png" style="height:19px;width:23px;" @click="reply">
            </div>
            <!-- <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="showModel=true">回复</button> -->
        </div>

        <!-- 回复消息弹窗 -->
        <van-popup v-model="showModel" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md ">{{$t('MobileServiceA.topic')}}</span>
                        <van-field
                            v-model="messageReply"
                            :placeholder="$t('MobileServiceA.placefolter')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                            class="rounded-lg shadow"
                        />
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showModel = false;messageReply = '';">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="reply">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import MobileMessageInfor from "./MobileMessageInfor.vue";
import ShopCartInfor from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        MobileMessageInfor, // 对话信息
        ShopCartInfor,
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.selectPage = this.$route.query.index;
        this.threadId = this.$route.query.thread;
        this.saleid = this.$route.query.sale;
    },
    mounted() {
        // 获取主题消息
        this.$http.get('/v1/mail/select-message?threadid=' + this.threadId, {
            withCredentials: true,
        }).then(res => {
            this.Message = res.data.data;
            // 滚动到最新消息
            this.$nextTick(() => {
                const messageList = this.$refs.messageList;
                messageList.scrollTop = messageList.scrollHeight;
            });
        }).catch(error => {
            console.log(error)
        })
        // todo
        this.$http.get("/v1/mail/select-onsale-by-saleid?saleid=" + this.saleid)
            .then((res) => {
                this.LandInfor = res.data.data;

                this.$nextTick(() => {
                    const messageList = this.$refs.messageList;
                    messageList.scrollTop = messageList.scrollHeight;
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    data() {
        return {
            selectPage: null, // 从地址获取
            threadId: null, // 同上
            saleid: null, // 挂牌土地信息查询id
            LandInfor: {
                originalLand: {
                    geographicalcoordinates: " ",
                },
            }, // 挂牌土地信息
            Message: [],
            inputText: "", // 用户输入的内容
            showModel: false, // 回复弹窗
            messageReply: '', // 回复的消息
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getTitle() {
            return this.$t('MobileMessageWindow.topic1') + (this.LandInfor.seller === undefined ? this.$t('MobileMessageWindow.name') : this.LandInfor.seller) + this.$t('MobileMessageWindow.topic2');
        },
        reply() {
            if (this.messageReply === '') {
                this.$toast(this.$t('MobileServiceA.alertNoMessgae'));
                return;
            }
            const data = {
                emailscontent: this.messageReply,
                threadid: this.threadId
            }
            this.$http.post("/v1/csmail/cswrite-message", data)
                .then((res) => {
                    this.showModel = false;
                    const now = new Date();
                    this.Message.push({
                        fromname: this.Message[0].fromname,
                        createdate: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}T${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
                        emailscontent: this.messageReply,
                        isme: true,
                    })
                    // 滚动到最新消息
                    this.$nextTick(() => {
                        const messageList = this.$refs.messageList;
                        messageList.scrollTop = messageList.scrollHeight;
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.message-user-input-parent {
    width: 90%;
    height: 60px;
    border-radius: 22px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px 0px rgba(90, 108, 234, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 24px;
}
</style>
