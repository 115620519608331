<template>
    <div class="safe-full-container">
        <div class="safe-left-container">
            <div class="safe-left-head">
                <div class="safe-left-head-a shadow-md border-gray">
                    <img :src="avatarUrl" alt="avatar" @click="handleClick" style="height:100px;width:100px;"/>
                    <input type="file" ref="fileInput" hidden @change="handleFileSelect" />
                </div>
                <div class="safe-left-head-a shadow-md border-gray safe-left-head-icon">
                    <img src="../adminImage/admin-myinfor-photo.png" @click="handleClick" style="height:30px;width:30px;" />
                </div>
            </div>
            <div class="safe-left-name-p">
                <span class="safe-left-name">{{ users.businessname }}</span>
                <img src="../developer/safe-changename.png" class="safe-left-name-icon" @click="openModal">
            </div>
            <div class="safe-left-options">
                <span class="safe-left-option" :style="index === 'infor' ? 'color: #3662EC;' : ''" @click="index='infor'">{{$t('AccountSafe.user')}}</span>
                <span class="safe-left-option" :style="index === 'password' ? 'color: #3662EC;' : ''" @click="index='password'">{{$t('AccountSafe.password')}}</span>
                <span class="safe-left-option" :style="index === 'account' ? 'color: #3662EC;' : ''" @click="index='account'">{{$t('AccountSafe.account')}}</span>
                <span class="safe-left-option" :style="index === 'service' ? 'color: #3662EC;' : ''" @click="index='service'">{{$t('AccountSafe.service')}}</span>
            </div>
        </div>
        <div class="safe-right-main">
            <MyInfor v-if="index==='infor'"></MyInfor>
            <MyPassword v-else-if="index==='password'"></MyPassword>
            <AssociatedAccount v-else-if="index==='account'"></AssociatedAccount>
            <CustomerService v-else-if="index==='service'"></CustomerService>
        </div>
        <!-- 修改用户名弹窗 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box" style="width: 550px;" v-if="canChangeName===true">
                <div class="flex flex-row w-full">
                    <div class="safe-modal-title">{{$t('AccountSafe.input')}}</div>
                    <img src="../developer/home-system-close.png" style="height:24px;width:24px;" @mouseover="changeCursor" @click="systemModal=false">
                </div>
                <div class="safe-modal-tip1">{{$t('AccountSafe.tip1')}}</div>
                <input type="text" maxlength="10" show-word-limit class="safe-modal-input" v-model="inputName">
                <div class="safe-modal-tip2" v-show="alert">
                    <img src="../developer/safe-modal-tip.png" style="height:12px;width:12px;">
                    {{$t('AccountSafe.tip1')}}
                </div>
                <div class="flex flex-row" style="margin-top:32px;">
                    <button class="safe-modal-button" @click="changeName">{{$t('forgetPassword.yes')}}</button>
                </div>
            </div>
            <div class="developer-common-modal-box" style="width: 550px;" v-else-if="canChangeName===false">
                <div class="flex flex-row w-full">
                    <div class="safe-modal-title">{{$t('AccountSafe.canotchange')}}</div>
                    <img src="../developer/home-system-close.png" style="height:24px;width:24px;" @mouseover="changeCursor" @click="systemModal=false">
                </div>
                <div class="mt-8" style="font-size:16px;">{{$t('AccountSafe.tip2')}}</div>
                <div class="flex flex-row" style="margin-top:32px;">
                    <button class="safe-modal-button" @click="vmodal=false">{{$t('forgetPassword.yes')}}</button>
                </div>
            </div>
            <div class="developer-common-modal-box" style="width: 550px;" v-else>
                <span>{{$t('AccountSafe.loading')}}...</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import MyInfor from "./MyInfor.vue";
import MyPassword from "./AccountPassword.vue";
import AssociatedAccount from "./AccountAsociated.vue";
import CustomerService from "./ChatService.vue";
export default {
    components: {
        MyInfor,
        MyPassword,
        AssociatedAccount,
        CustomerService,
    },
    computed: {
        ...mapState({
            users: (state) => state.developer.userInfo,
            avatarUrl: (state) => state.developer.avatarUrl,
        }),
    },
    data () {
        return {
            index: 'infor', // 菜单栏选中的模块，
            vmodal: false, // 修改用户名的弹窗
            canChangeName: null, // 是否可以修改用户名
            inputName: '', // 修改用户名时输入的用户名
            alert: false, // 提示用户该用户名已存在
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 此处为点击用户头像上传图片的点击事件 */
        handleClick() {
            this.$refs.fileInput.click();
        },
        handleFileSelect(event) {
            // 获取用户选择的文件对象
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("userphoto", file);
            this.$http
                .put("/v1/user/update-userphoto", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$store.dispatch("developer/loadAvatar"); // 更新开发商头像
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            // 将文件对象转换为 URL
            const url = URL.createObjectURL(file);
            // 更新当前头像 URL
            this.avatarUrl = url;
            // 清空 input 内容
            event.target.value = "";
        },

        /* 修改用户名弹窗，需要先检测前置条件 */
        openModal() {
            this.$http.get("/v1/business-center/inspect")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.canChangeName = true;
                    } else {
                        this.canChangeName = false;
                    }
                    this.vmodal = true;
                })
                .catch((error) => {
                    this.$toast.fail('error');
                    this.vmodal = false;
                });
        },
        changeName() {
            if (this.inputName === '') { return; }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('AccountSafe.loading'),
                forbidClick: true,
            });
            const data = {
                businessname: this.inputName
            }
            this.$http.put("/v1/business-center/update-userbusiness", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success(this.$t('MyInfor.letter19'));
                        this.$store.dispatch("developer/fetchUserInfo"); // 加载开发商用户个人信息
                        this.inputName = '';
                        this.vmodal = false;
                    } else {
                        this.alert = true;
                    }
                    toastLoading.clear();
                })
                .catch((error) => {
                    this.$toast.fail('error');
                    toastLoading.clear();
                });
        },
    },
}
</script>

<style scoped>
.safe-full-container {
    height: 100%;
    width: 100%;
    background-color: rgb(242,248,255);
    padding: 30px 100px 100px 30px;
    display: flex;
    flex-direction: row;
}
.safe-left-container {
    height: 100%;
    border-radius: 30px;
    background-color: #FFFFFF;
    width: 300px;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
}
/* 头像区域 */
.safe-left-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    position: relative;
}
.safe-left-head-a {
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
.safe-left-head-icon {
    position: absolute;
    bottom: 0;
    right: 60px;
}
/* 用户名区域 */
.safe-left-name-p {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}
.safe-left-name {
    font-size: 24px;
    font-weight: 600;
    color: #3D3D3D;
    max-width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
.safe-left-name-icon {
    margin-left: 4px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}
/* 菜单栏区域 */
.safe-left-options {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
}
.safe-left-option {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #3D3D3D;
    cursor: pointer;
    text-align: center;
}
.safe-right-main {
    margin-left: 30px;
    flex-grow: 1;
    overflow: hidden;
    max-width: calc(100% - 330px);
}
/* 修改用户名弹窗 */
.safe-modal-title {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
}
.safe-modal-tip1 {
    font-size: 10px;
    font-weight: 300;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.4);
    width: 100%;
}
.safe-modal-input {
    width: 310px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #165DFF;
    background-color: #F1F4F8;
}
.safe-modal-tip2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: 300;
    color: #FA5151;
}
.safe-modal-button {
    width: 250px;
    height: 40px;
    border-radius: 10px;
    background-color: #165DFF;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
</style>