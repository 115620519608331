<template>
    <div ref="mainWindow" class="mobile-detail-home mobile-base-background2 p-0 bg-gray-50">
        <div class="fixed top-0 w-full flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileLandDetail.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="flex flex-grow flex-col overflow-y-auto animate__animated animate__fadeInUp detail-main">
            <div class="mb-3 relative mobile-detail-background" style="height:100%;width:100%;" ref="background">
                <img :src="LandInfor.originalLand.landpicture" style="width: 286px;height: 286x;" v-show="!mode">
                <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="mobile-detail-map shadow-lg rounded-lg  border-3 border-gray" v-show="mode"></canvas>
                <img src="../mImage/land-detail-left.png" class="absolute" style="left:5px;top:50%;transform: translate(0,-50%);" @click="mode=!mode;">
                <img src="../mImage/land-detail-right.png" class="absolute" style="right:5px;top:50%;transform: translate(0,-50%);" @click="mode=!mode;">
                <span class="absolute text-blue-500" style="bottom:5px;left:50%;transform: translate(-50%,0);">{{ mode?'1':'2' }}/2</span>
            </div>
            <!-- 价格 -->
            <div class="flex flex-row mt-4 mb-4" v-if="LandInfor.unitLand.status === '已挂牌'">
                <span class="text-lg mr-3">{{$t('useraccount.price')}}:</span>
                <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.onSale.totalprice }}</span>
                <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.onSale.totalprice * $exchangeRate }}</span>
            </div>
            <!-- 商品名称及开发商/平台标签 -->
            <div class="flex flex-row items-center">
                <span class="text-lg font-semibold">{{ LandInfor.unitLand.mergelandname }}</span>
            </div>
            <!-- 单位土地个数 -->
            <span class="text-gray-600 mt-1" style="font-size:12px;">{{$t('MobileLandDetail.landNum')}}: {{ LandInfor.unitLand.landblocks }}</span>
            <!-- 上架时间 -->
            <span class="text-gray-500" style="font-size:12px;" v-if="LandInfor.unitLand.status === '已挂牌'">{{$t('MobileLandDetail.listTime')}}: {{ LandInfor.onSale.createtime }}</span>
            <!-- 详细信息及原始土地信息 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-3 mb-3 pb-5">
                <van-tabs v-model="tabControl">
                    <van-tab :title="$t('MobileLandDetail.detail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('OrderView.l10')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.unitlandid')}}:</span>
                                <span class="text-gray-700 text-sm">{{ unitlandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('LandSellList.unit')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.landcoordinates }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('LandMap3.statu')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.status }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('LandSellDetail.origindetail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landId')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <!-- todo开发商名称未添加 -->
                            <!-- <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.ownerid }}</span>
                            </div> -->
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="mt-1 mb-3 pt-1">
                <span class="text-md font-semibold">{{$t('MobileLandDetail.landRights')}}</span>
                <div class="flex flex-col mb-3 mt-3">
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full py-3 flex items-center justify-end bg-white">
            <button class="px-4 mr-2 py-1 bg-blue-500 text-white text-sm rounded-3xl w-auto mr-5" style="border: 1px solid #3662EC;" @click="openSellModal" v-if="LandInfor.unitLand.status === '未挂牌'">{{$t('all.sale')}}</button>
            <button class="px-4 mr-2 py-1 bg-red-500 text-white text-sm rounded-3xl w-auto mr-5" style="border: 1px solid #3662EC;" @click="openSellModal" v-if="LandInfor.unitLand.status === '已挂牌'">{{$t('all.sale')}}</button>
            <button class="px-4 mr-2 py-1 bg-whitr text-blue-500 text-sm rounded-3xl w-auto mr-5" style="border: 1px solid #3662EC;" @click="openSellModal" v-if="LandInfor.unitLand.status === '已挂牌'">{{$t('all.sale')}}</button>
            <button class="px-4 mr-2 py-1 bg-white text-blue-500 text-sm rounded-3xl w-auto" style="border: 1px solid #3662EC;" @click="gototitledeed">{{$t('MobileMyLandDetail.goTitleDeed')}}</button>
        </div>

        <!-- 挂牌/修改价格/摘牌弹窗 -->
        <LandSale ref="landsale" :LandInfor="saleLand"></LandSale>

    </div>
</template>

<script>
import LandSale from '../LandSaleModal.vue'
export default {
    components: {
        LandSale
    },
    name: "MobileLandDetail",
    created() {
        // 不要忘了获取传输过来的土地信息
        this.unitlandid = this.$route.query.id;
        this.orderid = this.$route.query.order;
    },
    mounted() {
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })

        // 获取土地详情信息
            this.$http
                .post("/v1/myland/show-myland-one?unitlandid=" + this.unitlandid)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.saleLand.unitlandid = this.unitlandid;
                    this.saleLand.landblocks = this.LandInfor.unitLand.landblocks;
                    this.saleLand.mergelandname = this.LandInfor.unitLand.mergelandname;
                    /* this.$refs.originMap.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                    this.$refs.originMap.style.backgroundColor = "unset"; */
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 100);
                })
                .catch((error) => {
                    console.log(error);
                });
    },
    data() {
        return {
            messages: 0,
            saleLand: {
                unitlandid: '',
                landblocks: 0,
                mergelandname: '',
            },
            orderid: null, // 订单号
            selectPage: null, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            flag: true, // 防抖
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            mode: true, // 图片模式，true为显示小区地图，false为显示原始土地图片
            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },
            canvasSize: 286,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: 0, // 第三格土地详情栏tab控制参数，0是土地详情，1是原始土地详情，2是卖家信息
            smodal: false, // 挂牌弹窗
            saleUnitPrice: '', // 单位土地售价
            sellprice: 0, // 售价
            salename: '', // 设置的商品名称
            type: '立即购买', // 购买形式
            
            code: '', // 挂牌验证码
            timeCount: 0, // 倒计时
            timer: null, // 定时器
        };
    },
    watch: {
        saleUnitPrice(n) {
            this.sellprice = Number(n) * this.LandInfor.unitLand.landblocks
        },
    },
    methods: {
        getSellerInfor(tab) {
            if (tab === 'name') {
                // todo
            }
            return '暂无'
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            // canvas.style.backgroundImage = "url(" + this.LandInfor.unitpicture + ")";
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col + 1;
            const height = end.row - start.row + 1;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        gototitledeed() {
            this.$router.push({
                path: '/title-deed',
                name: 'MobileTitleDeed',
                params: {
                    landid: this.unitlandid,
                }
            })
        },
    },
};
</script>

<style scoped>
.mobile-detail-background {
    background-image: url('../mImage/my-land-background.png');
    background-size: cover;
    padding: 20px;
    border-radius: 12px;
}
.mobile-detail-home {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
/* ---------------------------------第一格：土地地图------------------------------- */
.mobile-detail-map {
    background-size: cover;
    background-color: #fff;
}

/* 挂牌 */
.land-sell-modal-box-items-input {
    width: 150px;
}
.land-sell-modal-box-items-span {
    width: 150px;
}
</style>
