<template>
    <!-- 页面布局容器 -->
    <el-container class="admin-full">
        <!-- 页面头部区域 -->
        <el-header class="admin-header" style="height:100px;">
            <div class="admin-header-logo">
                <div class="admin-header-logo-image">
                    <img src="../adminImage/logo-white.png"/>
                </div>
                <span class="ml-2">Datu 后台管理系统</span>
            </div>
            <div class="admin-header-right">
                <span class="header_dropdown_welcome">欢迎您</span>
                <el-dropdown class="header_dropdown_menu">
                    <span class="el-dropdown-link">{{ admin.name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- 点击下拉框触发的事件到methods里找 -->
                        <el-dropdown-item command="myEstate">我的地产</el-dropdown-item>
                        <el-dropdown-item command="myTrade">我的交易</el-dropdown-item>
                        <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
                        <el-dropdown-item command="myWallet">我的钱包</el-dropdown-item>
                        <el-dropdown-item command="myAuction">我的拍卖</el-dropdown-item>
                        <el-dropdown-item command="mySub">我的订阅</el-dropdown-item>
                        <el-dropdown-item command="setting">设置</el-dropdown-item>
                        <el-dropdown-item command="unload">切换账号/退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        
        <!-- 侧边栏和主页的布局容器 -->
        <el-container class="admin-main-container">
            <!-- 页面侧边框 -->
            <el-aside :width="'200px'">
                <el-row class="tac">
                    <el-col :span="12">
                        <!-- 侧边栏菜单 -->
                        <el-menu default-active="2" class="left_aside_menu" router>
                        

                            <!-- 一级菜单-个人信息 -->
                            <el-menu-item index="/admin/my-infor" @click="setIndex('infor')" :class="index === 'infor'?'el-menu-item-select':''">
                                <i class="el-icon-user"></i>
                                <span slot="title">个人主页</span>
                            </el-menu-item>
                        
                            <!-- 一级菜单-用户管理 -->
                            <el-menu-item index="/admin/user-manage" @click="setIndex('user')" :class="index === 'user'?'el-menu-item-select':''">
                                <i class="el-icon-s-custom"></i>
                                <span slot="title">用户管理</span>
                            </el-menu-item>
                        
                        
                            <!-- 企业管理二级菜单 -->
                            <el-submenu index="Company">
                                <template slot="title">
                                    <i class="el-icon-more" :class="index === 'company'||index === 'companyadd'?'el-menu-item-select':''"></i>
                                    <span :class="index === 'company'||index === 'companyadd'?'el-menu-item-select':''">企业管理</span>
                                </template>
                                
                                <el-menu-item-group>
                                    <el-menu-item index="/admin/company" @click="setIndex('company')" :class="index === 'company'?'el-menu-item-select':''">
                                        <i class="el-icon-office-building"></i>
                                        <span>企业认证</span>
                                        <div class="flex-grow flex justify-end items-center">
                                          <div class="bg-red-600 rounded-full h-6 w-6 items-center justify-center text-white inline-flex text-sm" v-if="companyCheckingCount !== 0">{{ companyCheckingCount }}</div>
                                        </div>
                                    </el-menu-item>
                                    <el-menu-item index="/admin/company-register" @click="setIndex('companyadd')" :class="index === 'companyadd'?'el-menu-item-select':''">
                                        <i class="el-icon-plus"></i>
                                        <span>注册新企业用户</span>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>

                            
                            <!-- 一级菜单-挂牌管理 -->
                            <el-menu-item index="/admin/sell-manage" @click="setIndex('sell')" :class="index === 'sell'?'el-menu-item-select':''">
                                <i class="el-icon-sell"></i>
                                <span slot="title">挂牌管理</span>
                            </el-menu-item>
              
              
                            <!-- 订单管理二级菜单 -->
                            <el-submenu index="Order">
                                <template slot="title">
                                    <i class="el-icon-more" :class="index === 'orderview'||index === 'orderrefund'?'el-menu-item-select':''"></i>
                                    <span :class="index === 'orderview'||index === 'orderrefund'?'el-menu-item-select':''">订单管理</span>
                                </template>
                                <!-- 二级菜单选项部分 -->
                                <el-menu-item-group>
                                    <!-- 二级菜单模板区域 -->
                                    <el-menu-item index="/admin/order-manage" @click="setIndex('orderview')" :class="index === 'orderview'?'el-menu-item-select':''">
                                        <!-- 小图标 -->
                                        <i class="el-icon-sold-out"></i>
                                        <!-- 文本 -->
                                        <span>订单查看</span>
                                    </el-menu-item>
                                    <!-- 二级菜单模板区域 -->
                                    <el-menu-item index="/admin/refund-manage" @click="setIndex('orderrefund')" :class="index === 'orderrefund'?'el-menu-item-select':''">
                                        <div class="menu-check-title">
                                          <i class="el-icon-coin"></i>
                                          <span>退款审核 x</span>
                                          <div class="menu-check-count">{{ refundCheckingCount }}</div>
                                        </div>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>

                            <!-- 一级菜单-聊天管理 -->
                            <el-menu-item index="/admin/chat-manage" @click="setIndex('chat')" :class="index === 'chat'?'el-menu-item-select':''">
                                <i class="el-icon-chat-dot-round"></i>
                                <span slot="title">聊天管理</span>
                            </el-menu-item>


                            <!-- 一级菜单-分块土地管理 -->
                            <el-menu-item index="/admin/unitland-manage" v-if="users.role!=='普通管理员'" @click="setIndex('unit')" :class="index === 'unit'?'el-menu-item-select':''">
                                <i class="el-icon-map-location"></i>
                                <span slot="title">分块土地管理</span>
                            </el-menu-item>


                            <!-- 平台管理二级菜单 -->
                            <el-submenu index="Admin" v-if="users.role==='超级管理员'">
                                <template slot="title">
                                    <i class="el-icon-more" :class="index === 'adminlist'||index === 'adminadd'||index === 'adminlog'?'el-menu-item-select':''"></i>
                                    <span :class="index === 'adminlist'||index === 'adminadd'||index === 'adminlog'?'el-menu-item-select':''">平台管理</span>
                                </template>
                                <!-- 二级菜单选项部分 -->
                                <el-menu-item-group>
                                    <el-menu-item index="/admin/admin-manage" @click="setIndex('adminlist')" :class="index === 'adminlist'?'el-menu-item-select':''">
                                        <i class="el-icon-user-solid"></i>
                                        <span>管理员列表</span>
                                    </el-menu-item>
                                    <el-menu-item index="/admin/admin-add" @click="setIndex('adminadd')" :class="index === 'adminadd'?'el-menu-item-select':''">
                                        <i class="el-icon-plus"></i>
                                        <span>管理员新增</span>
                                    </el-menu-item>
                                    <el-menu-item index="/admin/super-operation" @click="setIndex('adminlog')" :class="index === 'adminlog'?'el-menu-item-select':''">
                                        <i class="el-icon-view"></i>
                                        <span>监察管理员操作日志</span>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
              
              
                            <!-- 一级菜单-原始土地管理 -->
                            <el-menu-item index="/admin/land-manage" v-if="users.role==='超级管理员'" @click="setIndex('original')" :class="index === 'original'?'el-menu-item-select':''">
                                <div class="menu-check-title">
                                    <i class="el-icon-location-information"></i>
                                    <span>原始土地管理 x</span>
                                    <div class="menu-check-count">{{ landUploadCheckingCount }}</div>
                                </div>
                            </el-menu-item>

                            
                            <!-- 一级菜单-操作日志 -->
                            <el-menu-item index="/admin/operation" @click="setIndex('log')" :class="index === 'log'?'el-menu-item-select':''">
                                <i class="el-icon-document"></i>
                                <span slot="title">操作日志</span>
                            </el-menu-item>

                            
                            <el-menu-item index="/admin/chat" @click="setIndex('service')" :class="index === 'service'?'el-menu-item-select':''">
                                <i class="el-icon-service"></i>
                                <span slot="title">客服</span>
                                <div class="bg-red-600 rounded-full h-8 w-8 items-center justify-center text-white inline-flex ml-20" v-if="messages !== '0' && messages">{{ messages }}</div>
                            </el-menu-item>

                            <el-menu-item index="/admin/system-message" v-if="users.role==='超级管理员'" @click="setIndex('announce')" :class="index === 'announce'?'el-menu-item-select':''">
                                <i class="el-icon-bell"></i>
                                <span slot="title">发送公告</span>
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
            </el-aside>
      
      
            <!-- 页面主体 -->
            <el-main>
                <!-- 路由占位符 -->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import {mapState} from "vuex";
export default {
  computed: {
        ...mapState({
            users: (state) => state.admin.adminInfo,
            messages: (state) => state.admin.messages,
        }),
    },
  mounted() {
    this.$http.post('/v1/administrator/select-originallandmanage-list',{
      "landstatus": "待审核",
      "pagesize": "100",
      "pagenum": "1"
    }).then(res => {
        if(res.data.data.total>99) {
          this.landUploadCheckingCount = '99+'
        } else {
          this.landUploadCheckingCount = res.data.data.total;
        }
      }).catch(error => {
        this.$toast.fail('初始化失败，请刷新重试或重新登录');
      });
    this.$http.post('/v1/administrator/select-ordermanage-list',{
      "processstatus": "退款中",
      "pagesize": "100",
      "pagenum": "1"
    }).then(res => {
        if(res.data.data.total>99) {
          this.refundCheckingCount = '99+'
        } else {
          this.refundCheckingCount = res.data.data.total;
        }
      }).catch(error => {
        this.$toast.fail('初始化失败，请刷新重试或重新登录');
      });
    this.$http.post('/v1/administrator/business-listview',{
      verificationstatus: "待审核",
      pagesize: "100",
      pagenum: "1"
    }).then(res => {
        if(res.data.data.total > 99) {
          this.landUploadCheckingCount = '99+'
        } else {
          this.companyCheckingCount = res.data.data.total;
        }
      }).catch(error => {
        this.$toast.fail('初始化失败，请刷新重试或重新登录');
      });
    this.$store.dispatch("admin/refreshMessages");
  },


  data () {
    return {
      /* 判断是否折叠变量，初始不折叠，false */
      isCollapse: false,
      /* 土地上传信息待处理数量 */
      landUploadCheckingCount: 0,
      /* 企业认证信息待处理数量 */
      companyCheckingCount: 0,
      /* 退款待处理数量 */
      refundCheckingCount: 0,
      /* 管理员数据 */
      admin: {
        name: '管理员', // 右上角名称，应该会写等级
        num: '',
        prof: '',
        isCollapse: true,
      },
      index: localStorage.getItem("admin-index"), // 菜单
    }
  },
  methods: {
    changeCursor(event) {
      // 鼠标移上去时改变样式
      event.target.style.cursor = "pointer";
    },
    /* 此处为页面右上角下拉菜单的点击事件 */
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    /* 侧边栏折叠事件 */
    toggleButton () {
      this.isCollapse = !this.isCollapse
    },
    /* 左侧菜单选中的目录 */
    setIndex(t) {
      localStorage.setItem('admin-index',t);
      this.index = t;
    },
  },
}
</script>

<style scoped>
/* ---------------------------主页布局容器---------------------------- */
/* body之下第一层，element-ui组件布局 */
.admin-full {
  height: 100%;
  overflow: hidden;
}

/* ---------------------------顶栏布局容器---------------------------- */
/* 布局容器顶栏容器样式 */
.admin-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
}
/* 顶栏logo区域 */
.admin-header-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.admin-header-logo-image {
  border-radius: 50%;
  overflow: hidden;
  background: rgba(22, 93, 255, 0.2);
}
.admin-header-logo-image > img {
  height: 80px;
  width: 80px;
}
/* 顶栏右上角图标区域 */
.admin-header-right {
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* 主页+侧边栏区域 */
.admin-main-container {
  box-shadow: 0px 1px 10px 2px #E5E6EB;
  height: calc(100% - 100px);
}

/* 侧边栏区域 */
.el-aside {
  background-color: #fff;
}

/* 主页区域 */
.el-main {
  background-color: #f7f8fa !important;
}
/* 头部右边下拉菜单样式（包括欢迎你和带下拉框的文本） */
.header_dropdown {
  width: 100%;
  text-align: right;
  /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
  white-space: nowrap;
}
/* 头部右边欢迎您文本 */
.header_dropdown_welcome {
  display: inline-block;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
  margin-right: 20px;
}
/* 头部右边下拉菜单 */
.header_dropdown_menu {
  display: inline-block;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
}
/* 下拉菜单el组件需要的style */
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
.el-submenu {
  background-color: #fff !important;
}


/* ---------------------------左侧边栏布局容器---------------------------- */
/* 左侧菜单位置设置 */
.left_aside_menu {
  margin-top: 30px;
  background-color: #fff;
  color: #000;
}
/* 待处理的文本父块 */
.menu-check-title {
  display: flex;
  align-items: center; /* 垂直居中 */
}
/* 待处理的信息数量 */
.menu-check-count {
  font-size: 20px;
  color: #ff0000;
  margin-left: 10px;
  margin-top: 2px;
}



/* ---------------------------其他部分（我都忘了这些各自控制啥了）---------------------------- */
.tac {
  width: 100%;
}
/* 侧边框背景色 */
.el-submenu {
  background-color: #333744;
}
/* 侧边栏宽度(与侧边框保持一致) */
.el-col-12 {
  width: 100%;
}
/* 侧边栏背景色 */
.el-aside {
  background-color: #fff;
}
/* 去除白线(侧边栏与侧边框右侧连接处出现白线，染色去除) */
.el-menu {
  border-right: #333744;
}
.el-submenu__title {
  background-color: #fff;
}
/* 折叠展开组件设置 */
.toggle-button {
  background-color: #333744;
  color:#fff;
  font-weight:200;
  font-size: 20px;
  /* 鼠标放上去会变成小手 */
  cursor: pointer;
}


/* ---------------------------当侧边栏被选中时，更改为该style---------------------------- */
.el-menu-item-select {
  color: #409EFF;
}
.el-menu-item-select >>> i {
  color: inherit;
}
</style>
