<template>
    <div class="characterTest">
        <canvas ref="move" :height="canvasheight" :width="canvaswidth"></canvas>
        <button @click="drawDialogInterval" v-if="!dialogInterval">开启对话框</button>
        <button @click="closeDialogInterval" v-else>关闭对话框</button>
    </div>
</template>

<script>
export default {
    props: { // 画布尺寸
        canvasheight: { // 画布高度
            type: Number,
            required: true,
            default: 0,
        },
        canvaswidth: { // 画布宽度
            type: Number,
            required: true,
            default: 0,
        },
        gridSize: { // 网格尺寸
            type: Number,
            required: true,
            default: 0,
        },
    },
    mounted() {
        this.characterHeight = this.gridSize;
        this.characterWidth = this.gridSize;
        this.img = new Image();
        this.img.src = this.character;
        this.img.onload = () => {
            this.imgWidth = Math.floor(this.img.width / 4);
            this.imgHeight = Math.floor(this.img.height / 4);
            this.initializeCharacter();
        };
    },
    data() {
        return {
            character: require('./v2Image/character.png'),
            img: null, // 用于canvas绘图的图片链接
            imgWidth: 0,
            imgHeight: 0,
            currentX: 200, // 勇士所在位置的x轴的值
            currentY: 200, // 勇士所在位置的y轴的值
            characterWidth: 0, // 勇士的图片显示缩放宽度
            characterHeight: 0, // 勇士的图片显示缩放高度
            flag: true, // 行动中不可接收命令
            dialogInterval: null, // 绘制对话框的无限循环定时器
            dialogImg: require('./v2Image/对话框.png'),
        };
    },
    methods: {
        initializeCharacter() {
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            //            图片 裁剪坐标x 裁剪坐标y 裁剪宽度 裁剪高度 显示坐标x 显示坐标y 显示宽度 显示高度
            ctx.drawImage(this.img, 0, 0, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
        },
        /* 上行 */
        moveUp() {
            if (!this.flag || this.dialogInterval) { return; }
            this.flag = false;
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            const tailorY = this.imgHeight * 3;
            // 清空原图片
            ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
            // 立即绘制上行第一幅图,需要修改的是裁剪坐标，上行是第四行，裁剪坐标y均为imgHeight * 3，裁剪坐标x需要改变
            ctx.drawImage(this.img, 0, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            // 100毫秒后绘制第二幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 100);
            // 200毫秒后移动位置并绘制第三幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                this.currentY -= this.characterHeight; // 上行是当前坐标y减去勇士图片高度
                ctx.drawImage(this.img, this.imgWidth * 3, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 200);
            // 300毫秒后绘制第四幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth * 2, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
                this.flag = true;
            }, 300);
        },
        /* 下行 */
        moveDown() {
            if (!this.flag || this.dialogInterval) { return; }
            this.flag = false;
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            const tailorY = 0; // 下行是第一行
            // 清空原图片
            ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
            // 立即绘制下行第一幅图
            ctx.drawImage(this.img, 0, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            // 100毫秒后绘制第二幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 100);
            // 200毫秒后移动位置并绘制第三幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                this.currentY += this.characterHeight; // 下行是当前坐标y加上勇士图片高度
                ctx.drawImage(this.img, this.imgWidth * 3, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 200);
            // 300毫秒后绘制第四幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth * 2, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
                this.flag = true;
            }, 300);
        },
        /* 左行 */
        moveLeft() {
            if (!this.flag || this.dialogInterval) { return; }
            this.flag = false;
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            const tailorY = this.imgHeight; // 下行是第二行
            // 清空原图片
            ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
            // 立即绘制左行第一幅图
            ctx.drawImage(this.img, 0, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            // 100毫秒后绘制第二幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 100);
            // 200毫秒后移动位置并绘制第三幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                this.currentX -= this.characterWidth; // 左行是当前坐标y减去勇士图片高度
                ctx.drawImage(this.img, this.imgWidth * 3, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 200);
            // 300毫秒后绘制第四幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth * 2, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
                this.flag = true;
            }, 300);
        },
        /* 右行 */
        moveRight() {
            if (!this.flag || this.dialogInterval) { return; }
            this.flag = false;
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            const tailorY = this.imgHeight * 2; // 右行是第三行
            // 清空原图片
            ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
            // 立即绘制右行第一幅图
            ctx.drawImage(this.img, 0, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            // 100毫秒后绘制第二幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 100);
            // 200毫秒后移动位置并绘制第三幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                this.currentX += this.characterWidth; // 右行是当前坐标y减去勇士图片高度
                ctx.drawImage(this.img, this.imgWidth * 3, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
            }, 200);
            // 300毫秒后绘制第四幅图
            setTimeout(() => {
                ctx.clearRect(this.currentX,this.currentY,this.characterWidth,this.characterHeight);
                ctx.drawImage(this.img, this.imgWidth * 2, tailorY, this.imgWidth, this.imgHeight, this.currentX, this.currentY, this.characterWidth, this.characterHeight);
                this.flag = true;
            }, 300);
        },

        drawDialogInterval() {
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            let img = new Image();
            img.src = this.dialogImg;
            img.onload = () => {
                const imgWidth = img.width;
                const imgHeight = img.height;
                //            图片 裁剪坐标x 裁剪坐标y 裁剪宽度 裁剪高度 显示坐标x 显示坐标y 显示宽度 显示高度
                ctx.drawImage(img, 0, 0, imgWidth, imgHeight, this.currentX, this.currentY - 20, 30, 20);
            };
            let dialogcount = 1;
            this.dialogInterval = setInterval(() => {
                if(dialogcount > 3) {
                    dialogcount = 1;
                    ctx.clearRect(this.currentX + 13,this.currentY - 13,10,5);
                }
                ctx.beginPath();
                ctx.arc(this.currentX + 5 * dialogcount + 5,this.currentY - 11,1,0,2*Math.PI);
                ctx.fill();
                dialogcount += 1;
            }, 300);
        },
        closeDialogInterval() {
            clearInterval(this.dialogInterval);
            this.dialogInterval = null;
            const canvas = this.$refs.move;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(this.currentX,this.currentY - 20,30,20);
        },
    },
}
</script>

<style scoped>
.characterTest {
    position: absolute;
    top: 0;
    left: 0;
}
</style>