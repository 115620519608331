<template>
  <div class="finance-home-container" style="width: auto;" ref="financeHomeBox">
    <div class="box1">
        <div class="user-header-user">
            <img :src="avatarUrl" class="user-header-avatar" />
        </div>
        <div class="name">{{ users.username }}</div>
        <div class="total-points">
            <span>{{$t('useraccount.totalIntegral')}}:{{ users_data.总收益 }}</span>
            <span style="margin-left:33px;">{{$t('useraccount.increaseThisMonth')}}:{{ users_data.本月收益额 }}</span>
        </div>
        <div class="level-background">
            <img src="../pImage/level-yellow.png" class="level-icon">
        </div>
        <span class="refresh-date">{{$t('useraccount.updateTime')}}:{{ getToday() }}</span>
    </div>

    <!-- 第二栏--展示数据 -->
    <div class="box2">
        <div class="developer-common-card-title-parent">
            <div class="flex-grow flex items-center justify-end">
                <span class="goto-all" @click="gotoReport">{{$t('useraccount.monthlyReport')}}</span>
                <span class="goto-all-icon">{{ '>' }}</span>
            </div>
        </div>
        <div class="flex flex-row w-full" style="margin-top:20px;">
            <div class="data-card">
                <div class="data-card-icon">
                    <img src="../pImage/property-buyernum.png">
                </div>
                <div class="data-card-title">{{$t('TotalIncome.l3')}}</div>
                <div class="data-card-data">
                    <span>{{ users_data.买家数量 }}</span>
                </div>
            </div>
            <div class="data-card" style="margin-left:18px;">
                <div class="data-card-icon">
                    <img src="../pImage/property-tradenum.png">
                </div>
                <div class="data-card-title">{{$t('all.monthlyIncome')}}</div>
                <div class="data-card-data">
                    <span>{{ users_data.本月收益额 }}</span>
                    <span class="data-card-data-variation variation-reduce" v-if="(users_data.本月收益额 - users_data.上月收益额) < 0">{{ '(' + $t('useraccount.yesterday') + (users_data.本月收益额 - users_data.上月收益额) + ')' }}</span>
                    <span class="data-card-data-variation variation-increase" v-else>{{ '(' + $t('useraccount.lastMonth') }}<span v-if="(users_data.本月收益额 - users_data.上月收益额) > 0">{{ '+' }}</span>{{ (users_data.本月收益额 - users_data.上月收益额) + ')' }}</span>
                </div>
            </div>
            <div class="data-card" style="margin-left:18px;">
                <div class="data-card-icon">
                    <img src="../pImage/property-landprice.png">
                </div>
                <div class="data-card-title">{{$t('all.monthlyExpend')}}</div>
                <div class="data-card-data">
                    <span>{{ users_data.本月支出额 }}</span>
                    <span class="data-card-data-variation variation-reduce" v-if="(users_data.本月支出额 - users_data.上月支出额) < 0">{{ '(' + $t('useraccount.lastMonth') + (users_data.本月支出额 - users_data.上月支出额) + ')' }}</span>
                    <span class="data-card-data-variation variation-increase" v-else>{{ '(' + $t('useraccount.lastMonth') }}<span v-if="(users_data.本月支出额 - users_data.上月支出额) > 0">{{ '+' }}</span>{{ (users_data.本月支出额 - users_data.上月支出额) + ')' }}</span>
                </div>
            </div>
            <div class="data-card" style="margin-left:18px;">
                <div class="data-card-icon">
                    <img src="../pImage/property-selledland.png">
                </div>
                <div class="data-card-title">{{$t('PcFinanceHome.selledland')}}</div>
                <div class="data-card-data">
                    <span>{{ users_data.交易土地数据 }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- 第三栏--我的土地 -->
    <div class="box3">
        <div class="developer-common-card-title-parent">
            <div class="box3-title">{{$t('MobileMyLand.title')}}</div>
            <div class="flex-grow flex items-center justify-end">
                <span class="goto-all" @click="gotoLand">{{$t('useraccount.detailedTransaction')}}</span>
                <span class="goto-all-icon">{{ '>' }}</span>
            </div>
        </div>
        <div class="flex flex-row w-full" style="margin-top:20px;">
            <div class="form-item-cells">
                <div style="height:40px;width:100%;color: #788AA1;">
                    <el-col :span="3"><span>{{$t('useraccount.originalid')}}</span></el-col>
                    <el-col :span="3"><span>{{$t('LandSellDetail.area')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('LandSellList.unit')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('LandSellDetail.name')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('LandSellDetail.blocks')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('LandList.getTime')}}</span></el-col>
                    <el-col :span="2"><span>{{$t('useraccount.control')}}</span></el-col>
                </div>
                <el-skeleton :loading="landloading" :rows="3" animated />
                <div class="home-account-data">
                    <el-row v-for="(land, index) in users_land" :key="index" style="width:100%;">
                        <div class="users-information-col" :style="hoveredIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="hoveredIndex = index" @mouseleave="hoveredIndex = -1">
                            <el-col :span="3"><span>{{ land.originallandid }}</span></el-col>
                            <el-col :span="3"><span>{{ land.unitLand.communitynumber }}</span></el-col>
                            <el-col :span="4"><span>{{ land.unitLand.landcoordinates }}</span></el-col>
                            <el-col :span="4"><span>{{ land.landname }}</span></el-col>
                            <el-col :span="4"><span>{{ land.unitLand.landblocks }}</span></el-col>
                            <el-col :span="4"><span>{{ returnDate(land.createtime) }}</span></el-col>
                            <el-col :span="2"><span class="goto-detail" @click="gotoLandDetail(land.unitlandid)">{{$t('useraccount.certificate')}}</span></el-col>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>

    <!-- 第四栏--我的订单 -->
    <div class="box3" ref="box4">
        <div class="developer-common-card-title-parent">
            <div class="box3-title">{{$t('useraccount.transaction')}}</div>
            <div class="flex-grow flex items-center justify-end">
                <span class="goto-all" @click="gotoTrade">{{$t('useraccount.detailedTransaction')}}</span>
                <span class="goto-all-icon">{{ '>' }}</span>
            </div>
        </div>
        <div class="flex flex-row w-full" style="margin-top:20px;">
            <div class="form-item-cells">
                <div style="height:40px;width:100%;color: #788AA1;">
                    <el-col :span="5"><span>{{$t('useraccount.merch')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('useraccount.time')}}</span></el-col>
                    <el-col :span="4"><span>{{$t('LandMap3.sellername')}}</span></el-col>
                    <el-col :span="3"><span>{{$t('useraccount.paymeans')}}</span></el-col>
                    <el-col :span="3"><span>{{$t('useraccount.price')}}</span></el-col>
                    <el-col :span="2"><span>{{$t('useraccount.statu')}}</span></el-col>
                    <el-col :span="3"><span>{{$t('useraccount.control')}}</span></el-col>
                </div>
                <el-skeleton :loading="tradeloading" :rows="3" animated />
                <div class="home-account-data">
                    <el-row v-for="(user, index) in users_trade" :key="index" style="width:100%;">
                        <div class="users-information-col" :style="tradeIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="tradeIndex = index" @mouseleave="tradeIndex = -1">
                            <el-col :span="5"><span>{{ user.landname }}</span></el-col>
                            <el-col :span="4"><span>{{ returnDate(user.ordertime) }}</span></el-col>
                            <el-col :span="4"><span>{{ user.seller }}</span></el-col>
                            <el-col :span="3"><span>{{ user.paymeans }}</span></el-col>
                            <el-col :span="3">
                                <span style="color:red;" v-if="$setPayment === 'am'">${{ user.totalprice }}</span>
                                <span style="color:red;" v-else-if="$setPayment === 'zh'">￥{{ user.totalprice * $exchangeRate }}</span>
                            </el-col>
                            <el-col :span="2">
                                <div>
                                    {{ getEnglish(user.orderstatus) }}
                                    <span v-if="countdown[index]">
                                        {{ countdown[index].hour?countdown[index].hour:0 }}h-
                                        {{ countdown[index].minute?countdown[index].minute:0 }}m-
                                        {{ countdown[index].second?countdown[index].second:0 }}s
                                    </span>
                                </div>
                            </el-col>
                            <el-col :span="3"><span class="goto-detail" @click="gotoOrderDetail(user.orderstatus,user.separateOrderId)">{{$t('useraccount.certificate')}}</span></el-col>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        users() {
            return this.$store.state.user.userInfo;
        },
        avatarUrl() {
            return this.$store.state.user.avatarUrl;
        },
    },
    mounted() {
        this.$parent.adjustBoxHeight('975px');
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
        const timer = setTimeout(() => {
            if (this.$refs.financeHomeBox.clientHeight) {
                this.$parent.adjustBoxHeight(this.$refs.financeHomeBox.clientHeight + 'px');
            }
        }, 1000);
        // 初始化我的订单列表
        this.$http.get('/v1/order/select-separateorder-list?pageSize=' + 10 + '&pageNum=' + 1)
        .then(response => {
            const data = response.data.data.rows;
            this.initializeOrderList(data); // 初始化订单列表系统
            this.initializeInterval(); // 初始化倒计时系统
            this.tradeloading = false;
        }).catch(error => {
            this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
        });
        // 初始化我的土地列表
        this.$http.post('/v1/myland/show-myland',{
            pagenum: 1,
            pagesize: 10
        }).then(response => {
            this.users_land = response.data.data.rows;
            this.landloading = false;
        }).catch(error => {
            this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
        });
        // 初始化财务数据
        this.$http.get('/v1/order/income-data') // 获取收益数据
            .then(res => {
                this.user_data = res.data.data;
            }).catch(error => {
                // 处理错误
            });
    },
    beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.updateModalScale);
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener('resize', this.updateModalScale);
        next();
    },
    data() {
        return {
            users_data: {
                买家数量: 0,
                总收益: 0,
                昨日新增收益: 0,
                平均交易额: 0,
                昨日平均交易额: 0,
                交易土地数据: 0,
                单位土地均价: 0,
                当月单位土地均价: 0,
                本月收益额: 0,
                上月收益额: 0,
                本月支出额: 0,
                上月支出额: 0,
                购买土地的单位土地数量: 0,
                卖出土地的单位土地数量: 0,
            },
            users_land_example: {
                originallandid: '111',
                landname: '111',
                createtime: '111',
                unitLandid: '111',
                unitLand: {
                    communitynumber: '111',
                    landcoordinates: '111',
                    landblocks: '111',
                },
            },
            users_land: [], // 我的土地列表
            landloading: true, // 正在加载我的土地列表
            hoveredIndex: -1, // 鼠标悬浮在哪一条分块土地

            users_trade: [], // 订单列表
            tradeloading: true, // 正在加载订单列表
            tradeIndex: -1, // 鼠标悬浮在哪一条订单
            countdown: [], // 待付款订单的剩余时间列表
            timer: null, // 对待付款订单剩余时间进行倒计时的定时器
        }
    },
    methods: {
        // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal = this.$refs.financeHomeBox;
            if (window.innerWidth >= 1180) {
                modal.style.transform = `scale(1)`;
                modal.style.height = 'auto';
                modal.style.width = 'auto';
                this.$parent.controlRightBox(960);
            } else {
                const scaleX = (window.innerWidth - 200) / 980;
                modal.style.transform = `scale(${scaleX})`;
                modal.style.height = (window.innerHeight - 150) / scaleX + 'px';
                modal.style.width = 980 / scaleX + 'px';
                this.$parent.controlRightBox(window.innerWidth - 200);
            }
        },
        // 最近更新时间，直接获取现在时间
        getToday() {
            const NOW = new Date();
            return NOW.getFullYear() + '-' + NOW.getMonth() + '-' + NOW.getDay();
        },
        // 这个是查看详细月报的
        gotoReport() {
            this.$router.push('/user/property/income');
        },
        // 这个是去我的土地列表页的
        gotoLand() {
            this.$router.push('/user/property/my-land');
        },
        // 前往小区地图
        gotoLandDetail(unitlandid) {
            this.$router.push('/user/property/my-land/detail?land=' + unitlandid);
        },
        // 初始化十条左右的独立订单列表
        initializeOrderList(data) {
            const total = data.length;
            let n = 0;
            while(this.users_trade.length < 11) {
                if (n < total) {
                    this.users_trade = this.users_trade.concat(data[n].separateOrders);
                    n++;
                } else {
                    return;
                }
            }
        },
        /* 订单状态在后端是中文，需要有双语 */
        getEnglish(statu) {
            if (statu === '待付款') {
                return this.$t('MobileOrderList.time');
            } else if (statu === '已付款') {
                return this.$t('MobileOrderList.statu2');
            } else if (statu === '已退款') {
                return this.$t('MobileOrderList.statu3');
            } else if (statu === '退款中') {
                return this.$t('MobileOrderList.statu4');
            } else if (statu === '已驳回') {
                return this.$t('MobileOrderList.statu5');
            } else if (statu === '已取消') {
                return this.$t('MobileOrderList.statu6');
            }
        },
        // 获取日期
        returnDate(date) {
            if (date instanceof Date) {
                const YEAR = date.getFullYear();
                const MONTH = date.getMonth();
                const DAY = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
                return YEAR + '-' + MONTH + '-' + DAY;
            } else {
                return date;
            }
        },
        // 初始化待付款商品的剩余时间
        initializeInterval() {
            for (let i = 0;i < this.users_trade.length; i++) {
                if (this.users_trade[i].orderstatus === '待付款') {
                    let totalSeconds = Math.floor(this.users_trade[i].canceltime / 1000);
                    let hour = Math.floor(totalSeconds / 3600);
                    let minute = Math.floor((totalSeconds % 3600) / 60);
                    let second = totalSeconds % 60;
                    this.countdown[i] = {
                        hour: hour,
                        minute: minute,
                        second: second,
                        totalSeconds: totalSeconds
                    };
                }
            }
            this.timer = setInterval(() => {
                this.countdown = this.countdown.map(item => {
                    if (item) {
                        if (--item.totalSeconds <= 0) {
                            item.totalSeconds = 0;
                        }
                        item.hour = Math.floor(item.totalSeconds / 3600);
                        item.minute = Math.floor((item.totalSeconds % 3600) / 60);
                        item.second = item.totalSeconds % 60;
                        return item;
                    }
                });
            }, 1000);
        },
        // 这个是去全部订单的
        gotoTrade() {
            this.$router.push('/user/property/my-order');
        },
        // 前往订单详情页
        gotoOrderDetail(status,id) {
            if (status === '已付款' || status === '待付款' || status === '已驳回' || status === '已取消') {
                const newpath = "/user/my-order/detail?order=" + id
                this.$router.push({
                    path: newpath,
                });
            } else if (status === '已退款' || status === '退款中') {
                const newpath = "/user/my-order/refund?order=" + id
                this.$router.push({
                    path: newpath,
                });
            }
        },
    },
    /* 生命周期结束时清除计时器 */
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
}
</script>

<style scoped>
.finance-home-container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.box1 {
    min-height: 133px;
    height: 133px;
    width: 980px;
    background: linear-gradient(180deg, #221A84 0%, #4542F7 100%);
    border-radius: 8px;
    position: relative;
}
.user-header-user {
    position: absolute;
    top: 32px;
    left: 33px;
    height: 69px;
    width: 69px;
    border-radius: 9999px;
    box-shadow: 0 0 1px 0 #696969;
}
.user-header-avatar {
    object-fit: fill;
    height: 69px;
    width: 69px;
    border-radius: 9999px;
}
.name {
    position: absolute;
    top: 36px;
    left: 120px;
    font-size: 22px;
    font-weight: 500;
    color: #FFB300;
}
.total-points {
    position: absolute;
    top: 78px;
    left: 120px;
    font-size: 14px;
    color: #FFFFFF;
}
.level-background {
    position: absolute;
    top: 31px;
    right: 34px;
    width: 71px;
    height: 71px;
    border-radius: 16px;
    background: #4032EA;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.level-icon {
    object-fit: contain;
    width: 38px;
}
.refresh-date {
    position: absolute;
    bottom: 7px;
    right: 15px;
    font-size: 12px;
    color: #FFFFFF;
}

/* -------------------------------------第二栏-各种数据------------------------------------- */
.box2 {
    margin-top: 19px;
    width: 980px;
    padding: 20px 30px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(22, 93, 255, 0.05);
    border-radius: 30px;
    height: 185px;
}
.data-card {
    width: 216px;
    height: 95px;
    box-sizing: border-box;
    border: 1px solid #4542F7;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #FFFFFF;
    position: relative;
}
.data-card-icon {
    position: absolute;
    top: 26px;
    left: 20px;
    height: 43px;
    width: 43px;
}
.data-card-icon > .img {
    object-fit: contain;
    width: 43px;
    border-radius: 9999px;
}
.data-card-title {
    position: absolute;
    top: 21px;
    left: 75px;
    font-size: 14px;
    color: #B4B4B4;
    max-width: 138px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.data-card-data {
    position: absolute;
    top: 46px;
    left: 75px;
    font-size: 20px;
    font-weight: 500;
    color: #3D3D3D;
}
.data-card-data-variation {
    margin-left: 3px;
    font-weight: 350;
    font-size: 14px;
}
.variation-reduce {
    color: #FA5151;
}
.variation-increase {
    color: #33BF65;
}

/* -------------------------------------个人信息——我的地产与近期交易------------------------------------- */
.form-item-cells {
    max-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}
.home-account-data {
    max-height: 160px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.goto-all {
    color: #0256FF;
    font-size: 14px;
    cursor: pointer;
}
.goto-all-icon {
    margin-left: 8px;
    font-size: 20px;
    color: #0256FF;
    cursor: pointer;
}
.box3 {
    margin-top: 19px;
    width: 980px;
    padding: 20px 30px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(22, 93, 255, 0.05);
    border-radius: 30px;
    min-height: 300px;
}
.box3-title {
    font-size: 20px;
    color: #3D3D3D;
}
/* 查看详情 */
.goto-detail {
    cursor: pointer;
    text-decoration: underline;
    color: #476DFF;
    font-size: 14px;
}
/* 已付款、标签 */
.land-review-title-statu-yishangxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 12px;
    background-color: rgba(0, 181, 120, 0.1);
    color: #00B578;
}
/* 售后中标签 */
.land-review-title-statu-yixiaxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 12px;
    background-color: rgba(54, 98, 236, 0.1);
    color: #3662EC;
}
/* 已退款、已取消标签 */
.land-review-title-statu-yixiaxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 12px;
    background-color: rgba(54, 98, 236, 0.1);
    color: #3662EC;
}
@media (max-width: 1180px) or (max-height: 980px) {
    .finance-home-container {
        transform-origin: top left;
        max-height: none;
    }
    
}
</style>