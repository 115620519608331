<template>
    <div refs="container" class="canvas-container">
        <!-- 左侧土地信息栏 -->
        <div class="land-information-parent" ref="leftContainer">
            <div class="land-information-menu" v-if="isLandInforMenu">
                <div class="land-information-menu-row">
                    <span>{{$t('LandMap1.l1')}}:</span>
                    <span>{{$t('LandMap1.l2')}}:</span>
                    <span>{{$t('LandMap1.l3')}}:</span>
                </div>
                <div class="land-information-menu-row" style="padding: 10px;">
                    <span class="cursor-pointer" @mouseover="onMouseOverText(originLand.originallandname)" @mouseout="onMouseOutText" @mousemove="onMouseMoveText">{{ originLand.originallandname }}</span>
                    <span class="cursor-pointer" @mouseover="onMouseOverText(originLand.detailedaddress)" @mouseout="onMouseOutText" @mousemove="onMouseMoveText">{{ originLand.detailedaddress }}</span>
                    <span class="cursor-pointer" @mouseover="onMouseOverText(originLand.createdate)" @mouseout="onMouseOutText" @mousemove="onMouseMoveText">{{ originLand.createdate }}</span>
                </div>
            </div>
            <div class="land-information-menu" v-if="isLandInforMenu">
                <div class="land-information-menu-row">
                    <span v-if="landcol">{{$t('LandMap1.l4')}}:</span>
                    <span v-if="SecondLandCoordinates">{{$t('LandMap1.l5')}}:</span>
                    <span>{{$t('LandMap1.l6')}}:</span>
                </div>
                <div class="land-information-menu-row" style="padding: 10px;">
                    <span v-if="landcol">{{ '(' + landcol + ',' + landrow + ')'}}</span>
                    <span v-if="SecondLandCoordinates">{{ SecondLandCoordinates }}</span>
                    <span>(0,0)~{{ '(' + (colLength - 1) + ',' + (rowLength - 1) + ')'}}</span>
                </div>
            </div>
            <div class="land-information-menu" v-if="isLandInforMenu">
                <div class="land-information-menu-row">
                    <div class="flex items-center p-3 cursor-pointer" @click="drawFilterBackground('')">
                        <img src="../adminImage/admin-company-select-on.png" style="width: 20px;height: 20px;" v-if="filterArea === ''">
                        <img src="../adminImage/admin-company-select-off.png" style="width: 20px;height: 20px;" v-else>
                        <div class="mx-4" style="height:20px;width:20px;background-color:OrangeRed"></div>
                        <div class="flex-grow" style="max-width:200px;">{{$t('LandMap1.l7')}}</div>
                    </div>
                    <div class="flex items-center p-3 cursor-pointer" @click="drawFilterBackground('selled')">
                        <img src="../adminImage/admin-company-select-on.png" style="width: 20px;height: 20px;" v-if="filterArea === 'selled'">
                        <img src="../adminImage/admin-company-select-off.png" style="width: 20px;height: 20px;" v-else>
                        <div class="mx-4" style="height:20px;width:20px;background-color:yellow"></div>
                        <div class="flex-grow" style="max-width:200px;">{{$t('LandMap1.l8')}}</div>
                    </div>
                    <div class="flex items-center p-3 cursor-pointer" @click="drawFilterBackground('selling')">
                        <img src="../adminImage/admin-company-select-on.png" style="width: 20px;height: 20px;" v-if="filterArea === 'selling'">
                        <img src="../adminImage/admin-company-select-off.png" style="width: 20px;height: 20px;" v-else>
                        <div class="mx-4" style="height:20px;width:20px;background-color:Cyan"></div>
                        <div class="flex-grow" style="max-width:200px;">{{$t('LandMap1.l9')}}</div>
                    </div>
                    <div class="flex items-center p-3 cursor-pointer" @click="drawFilterBackground('selledandselling')">
                        <img src="../adminImage/admin-company-select-on.png" style="width: 20px;height: 20px;" v-if="filterArea === 'selledandselling'">
                        <img src="../adminImage/admin-company-select-off.png" style="width: 20px;height: 20px;" v-else>
                        <div class="mx-4" style="height:20px;width:20px;background-color:SpringGreen"></div>
                        <div class="flex-grow" style="max-width:160px;">{{$t('LandMap1.l10')}}</div>
                    </div>
                </div>
            </div>
            <button class="left-information-button button-movein" @click="moveIn" v-show="canMoveIn && mode === 'view'">{{$t('LandMap1.l11')}}</button>
            <button class="left-information-button button-batchsegmentation" @click="changeMode('partition')" v-if="mode === 'view' && haveOwnerShip">{{$t('LandMap1.l14')}}</button>
            <button class="left-information-button button-batchSell" @click="changeMode('sell')" v-if="mode === 'view' && haveOwnerShip">{{$t('all.batchSell')}}</button>
            <button class="left-information-button button-cancelbatch" @click="changeMode('view')" v-else-if="mode !== 'view' && haveOwnerShip">{{$t('LandMap1.l15')}}</button>
            <!-- 全部文本悬浮窗 -->
            <div class="tooltip" ref="tooltip2" style="margin-top: -100px;margin-left: 0;top: 0;left: 0;" v-if="showToolTipText">{{ toolTipText }}</div>
        </div>

        <!-- 大地图 -->
        <div class="flex-grow h-full absolute big-map-transform" style="top: 0;left: 360px;width: 0;" ref="middleContainer">
            <div class="big-map-container" ref="bigmapcontainer">
                <canvas
                ref="bigmapbackground"
                :width="canvasWidth"
                :height="canvasHeight"
                class="big-map-background"
                style="left: 0;top: 0;"></canvas>
                <canvas
                ref="bigmap"
                :width="canvasWidth"
                :height="canvasHeight"
                class="big-map"
                style="left: 0;top: 0;"
                @mousedown="onMouseDown"
                @mouseup="onMouseUp"
                @mousemove="onMouseMove"
                @click="onMouseClick"
                @mouseover="onMouseOver"
                @mouseout="onMouseOut"
                @wheel.prevent="zoom"
                ></canvas>
                <div class="tooltip" ref="tooltip" style="margin-top: -100px;margin-left: 0;top: 0;left: 0;" v-if="showToolTips">{{ toolcol + ',' + toolrow }}</div>
            </div>
        </div>

        <!-- 小地图 -->
        <div class="small-map-parent" style="width:0;" ref="rightContainer">
            <canvas v-show="showSmallMap"
            ref="smallmapbackground"
            :width="smallMapCanvasWidth"
            :height="smallMapCanvasHeight"
            class="small-map-background"></canvas>
            <canvas v-show="showSmallMap"
            ref="smallmap"
            :width="smallMapCanvasWidth"
            :height="smallMapCanvasHeight"
            class="small-map"
            @mousedown="smallMapMouseDown"
            @mouseup="smallMapMouseUp"
            @mousemove="smallMapMouseMove"
            @mouseover="changeCursor"></canvas>

            <!-- 批量分割模式选中的小区显示屏 -->
            <div class="batch-seg-box" style="width: 360px;" ref="bachSegBox" v-show="mode === 'partition'">
                <div class="batch-seg-box-title">
                    <div class="batch-seg-box-title-icon"></div>
                    <span class="batch-seg-box-title-span">{{$t('LandMap1.l14')}}</span>
                </div>
                <div class="batch-seg-box-items">
                    <span>{{$t('LandMap1.l16')}}</span>
                </div>
                <div class="mt-2 w-full h-full overflow-y-auto" style="max-height:200px;">
                    <div class="batch-seg-box-items" style="color:blue;width: 50%;" v-for="(block, index) in selectedBlocks" :key="index">
                        <span>{{ block }}</span>
                        <div class="flex-grow flex justify-end">
                            <img class="batch-seg-box-items-icon" src="../developer/map-sell-cancel.png" @click="deleteBlock(index)">
                        </div>
                    </div>
                </div>
                <div class="batch-seg-box-items" style="margin-top:20px;">
                    <span class="batch-seg-plotname-label">{{$t('LandMap1.l21')}}</span>
                    <input type="text" maxlength="20" show-word-limit class="batch-seg-plotname-input" :placeholder="$t('LandMap1.l22')" v-model="segPlotName">
                </div>
                <div class="batch-seg-box-items">
                    <el-radio v-model="segSize" label="1x1">1x1</el-radio>
                    <el-radio v-model="segSize" label="2x2">2x2</el-radio>
                    <el-radio v-model="segSize" label="3x3">3x3</el-radio>
                </div>
                <button class="left-information-button button-movein" @click="openSegModal" v-show="selectedBlocks.length !== 0">{{$t('LandMap1.l14')}}</button>
            </div>

            <!-- 批量小区挂牌模式选中的小区显示屏 -->
            <div class="batch-seg-box" style="width: 360px;" ref="bachSellBox" v-show="mode === 'sell'">
                <div class="batch-seg-box-title">
                    <div class="batch-seg-box-title-icon"></div>
                    <span class="batch-seg-box-title-span">{{$t('all.batchSell')}}</span>
                </div>
                <div class="batch-seg-box-items">
                    <span>{{$t('all.optionalBlocks') + '(' + $t('all.uptoTen') + ')'}}</span>
                </div>
                <div class="mt-2 w-full h-full overflow-y-auto" style="max-height:200px;">
                    <div class="batch-seg-box-items" style="color:blue;" v-for="(block, index) in canBeSoldBlocks" :key="index">
                        <el-checkbox style="position: unset;margin-right: 10px;" v-model="segChecked[index]" @change="selectSoldBlock(index)"></el-checkbox>
                        <span>{{ block }}</span>
                    </div>
                </div>
                <!-- 单位土地价格 -->
                <div class="batch-seg-box-items" style="margin-top:20px;">
                    <div class="batch-seg-plotname-label">{{$t('LandMap3.unitprice')}}</div>
                    <input type="number" v-model="saleUnitPrice" maxlength="10" show-word-limit class="batch-seg-plotname-input">
                </div>
                <div class="batch-seg-box-items" style="margin-top:20px;">
                    <span class="batch-seg-plotname-label">{{$t('LandMap1.l21')}}</span>
                    <input type="text" maxlength="20" show-word-limit class="batch-seg-plotname-input" :placeholder="$t('LandMap3.listtip1')" v-model="segPlotName">
                </div>
                <button class="left-information-button button-movein" @click="openSellModal" v-show="selectedBlocks.length !== 0">{{$t('all.batchSell')}}</button>
            </div>


            <!-- 活动1：拼拼乐模式选中的小区显示屏 -->
            <div class="batch-seg-box" style="width: 360px;" ref="bachActivity1Box" v-show="mode === 'activity1'">
                <div class="batch-seg-box-title">
                    <div class="batch-seg-box-title-icon"></div>
                    <span class="batch-seg-box-title-span">{{$t('LandMap1.l14')}}</span>
                </div>
                <div class="batch-seg-box-items">
                    <span>{{$t('LandMap1.l16')}}</span>
                </div>
                <div class="mt-2 w-full h-full overflow-y-auto" style="max-height:200px;">
                    <div class="batch-seg-box-items" style="color:blue;width: 50%" v-for="(block, index) in selectedBlocks" :key="index">
                        <span>{{ block }}</span>
                        <div class="flex-grow flex justify-end">
                            <img class="batch-seg-box-items-icon" src="../developer/map-sell-cancel.png" @click="deleteBlock(index)">
                        </div>
                    </div>
                </div>
                <button class="left-information-button button-movein" @click="openActivity1Modal" v-show="selectedBlocks.length !== 0">{{$t('all.partyActivity')}}</button>
            </div>

        </div>


        <!-- 批量分割的提示弹窗，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box">
                <!-- 标题 -->
                <div class="developer-common-modal-box-title">
                    <span>{{$t('LandMap1.l17')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" @click="vmodal=false">
                    </div>
                </div>
                <span class="text-center mt-3">{{$t('LandMap1.l18')}}</span>
                <div class="developer-common-buttons">
                    <button class="developer-common-button" @click="segAll">{{$t('LandMap1.l19')}}</button>
                </div>
            </div>
        </div>

        <!-- 批量挂牌的提示弹窗，弹框的显示和隐藏状态，根据 bmodal 变量决定 -->
        <div class="developer-common-modal" v-if="bmodal">
            <!-- 第一步输入验证码 -->
            <div class="developer-common-modal-box" v-if="sellStep">
                <!-- 标题 -->
                <div class="developer-common-modal-box-title">
                    <span>{{$t('MobileAssociatedReplace.title1')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" @click="closeSellModal">
                    </div>
                </div>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="developer-common-buttons">
                    <button class="developer-common-button" @click="sellStep=false">{{$t('CompanyRegister.next')}}</button>
                </div>
            </div>
            <!-- 第二步提示 -->
            <div class="developer-common-modal-box" v-else>
                <!-- 标题 -->
                <div class="developer-common-modal-box-title">
                    <span>{{$t('LandMap1.l17')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" @click="closeSellModal">
                    </div>
                </div>
                <span class="text-center mt-3">{{$t('LandMap1.l28')}}</span>
                <div class="developer-common-buttons">
                    <button class="developer-common-button" @click="sellAll">{{$t('LandMap1.l19')}}</button>
                </div>
            </div>
        </div>


        <ActivityModal :step="activityStep" :activity1Selected="selectedBlocks" ref="activity" v-show="activityModal"></ActivityModal>
    </div>
</template>

<script>
import ActivityModal from '../activity/developerActivity.vue';
export default {
    components: {
        ActivityModal,
    },
    created() {
        this.originallandid = this.$route.query.land;
    },
    mounted() {
        // 获取权限
        this.$http.get("/v1/business/select-business-status?originallandid=" + this.originallandid)
            .then((res) => {
                this.haveOwnerShip = res.data
            })
            .catch((error) => {});
        this.$http // 查询原始土地内所有已分割、挂牌中、已售出小区的坐标
            .get("/v1/terrain-map/select-community-number?originallandid=" + this.originallandid)
            .then((res) => {
                this.originLand = res.data.data.map2.originalLand; // 获取原始土地数据
                this.segmentation = res.data.data.map1.segmentation;
                this.Listing = res.data.data.map1.Listing;
                this.Sold = res.data.data.map1.Sold;
                this.ListingandSold = this.Listing.filter((item) => this.Sold.some((item2) => item2 === item));
                this.canBeSoldBlocks = this.segmentation.filter(item => !this.Listing.includes(item) && !this.Sold.includes(item));
                for(let i = 0;i < this.canBeSoldBlocks.length;i++) {
                    this.segChecked.push(false);
                }
                this.flag = false;
            })
            .catch((error) => {
                console.log(error);
            });
        /* ctx.fillStyle = "rgb(200,0,0)"; // 设置填充颜色
        ctx.fillRect(25, 25, 100, 100); // fillRect(x, y, width, height)绘制一个填充的矩形
        ctx.clearRect(45, 45, 60, 60); // strokeRect(x, y, width, height)绘制一个矩形的边框
        ctx.strokeStyle = "rgb(200,0,0)"; // 设置边框颜色
        ctx.strokeRect(50, 50, 50, 50); // clearRect(x, y, width, height)清除指定矩形区域，让清除部分完全透明。 */
        // 根据后端获取的土地长宽赋予画布长宽以及小地图
        this.originalCanvasHeight = parseInt(this.originLand.landlength) * 2;
        this.originalCanvasWidth = parseInt(this.originLand.landwidth) * 2;
        this.canvasWidth = this.originalCanvasHeight;
        this.canvasHeight = this.originalCanvasWidth;
        this.smallMapCanvasWidth = this.canvasWidth / 10;
        this.smallMapCanvasHeight = this.canvasHeight / 10;
        this.$refs.rightContainer.style.width = this.smallMapCanvasWidth + 60 + 'px';
        if (this.smallMapCanvasWidth >= 360) {
            this.$refs.bachSegBox.style.width = this.smallMapCanvasWidth + 'px';
            this.$refs.bachSellBox.style.width = this.smallMapCanvasWidth + 'px';
        }
        // 获取大地图容器的宽高
        this.containerWidth = this.$refs.bigmapcontainer.clientWidth;
        this.containerHeight = this.$refs.bigmapcontainer.clientHeight;
        // 获取小地图画布长宽和容器长宽
        this.smallMapContainerHeight = this.containerHeight / 10;
        this.smallMapContainerWidth = this.containerWidth / 10;
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
        // 获取横纵坐标各有多少个小方块
        this.colLength = this.canvasWidth / this.gridSize;
        this.rowLength = this.canvasHeight / this.gridSize;
        // 计算小区土地坐标范围
        this.gridNum = this.colLength * this.rowLength;

        const loadingToast = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t('LandMap1.l12'),
        });
        let num = 0;
        this.timer = setInterval(() => {  // 等待 100 毫秒后再绘制
            if (this.flag) { // 判断 canvas 元素是否存在
                if (num < 5) {
                    num++;
                } else {
                    this.$toast.fail(this.$t('LandMap1.l13'))
                    clearInterval(this.timer);
                }
            } else {
                loadingToast.clear();
                clearInterval(this.timer);
                this.drawBigMapGrid(); // 初始化大地图网格
                this.drawSmallMapGrid(); // 初始化小地图网格
                this.drawSmallMapBoxShadow(); // 初始化小地图容器边框
                this.drawProcessedArea(); // 初始化大地图+小地图已分割、已挂牌、已售出的小区
            }
        }, 1000);
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener('resize', this.updateModalScale);
        clearInterval(this.timer);
        next();
    },
    data() {
        return {
            haveOwnerShip: false, // 通过后端获取所有权，限制初次分割
            originalCanvasHeight: 0,
            originalCanvasWidth: 0,
            containerHeight: 0,
            containerWidth: 0,
            canvasHeight: 0,
            canvasWidth: 0,
            smallMapContainerHeight: 0,
            smallMapContainerWidth: 0,
            smallMapCanvasHeight: 0,
            smallMapCanvasWidth: 0,
            gridSize: 44,  // 原始土地分割成22x22的区块
            colLength: 0, // 计算获得横坐标方向有多少小方块
            rowLength: 0,
            data: [],
            isMouseDown: false,
            originX: 0, // 大地图鼠标按下时的坐标
            originY: 0,
            col: 0, // 地图容器左上角坐标
            row: 0,
            landcol: null, // 土地网格坐标
            landrow: null,
            toolcol: null, // 悬浮时鼠标处于的网格坐标
            toolrow: null,
            gridNum: 0,

            zoomFactor: 1, // 缩放指数

            originallandid: '', // 原始土地id，查询信息需要用到
            originLand: {
                id: 1,
                originallandid: "S538",
                ownershipnumber: "889123654",
                originallandname: "黑土地",
                detailedaddress: "青岛市",
                landcertificate: "/usr/local/land/landpicture/USER_10022/8c1d34694ed242f397dabc8a8ad34867.pdf",
                warrantydeed: null,
                ownerid: "b4bcab5f1afa44e8bbba5449afa13d6d",
                entrance: "东",
                geographicalcoordinates: "12,23",
                landlength: "2640",
                landwidth: "2640",
                height: "2",
                digitalright: "出售数字权",
                surfaceright: "出售地表权",
                landpicture: "/usr/local/land/landpicture/USER_10023/d0a14175baaa46a9abb343df5af93764.png",
                createdate: "2023-05-31 09:52:24",
                landstatus: "未审核",
            },
            segmentation: [], // 已分割过的小区
            Listing: [], // 有已挂牌的分块土地的小区
            Sold: [], // 有已售出的分块土地的小区
            ListingandSold: [], // 同时含有已挂牌和已售出的分块土地的小区
            canBeSoldBlocks: [], // 可批量售卖的小区
            filterArea: '', // 筛选含特定状态分块土地的小区的筛选方式，有selled、selling、selledandselling
            flag: true, // 未获取到后端数据前该条为true，此时不可初始化绘图，flag为false是才可
            timer: null, // 配套的定时器

            canMoveIn: false, // 进入该小区，初始化时不显示按钮，第一次选中后显示按钮

            isSmallMapMouseDown: false, // 小地图被按下

            isLandInforMenu: true, // 左侧信息栏显示控制

            SecondLandCoordinates: null, // 二号土地坐标

            showSmallMap: true, // 是否显示小地图

            showToolTips: false, // 是否显示悬浮窗

            mode: 'view', // 三种模式：查看模式view，批量分割模式partition，批量挂牌模式sell
            segChecked: [], // 批量挂牌的多选框组
            selectedBlocks: [], // 批量分割及批量挂牌模式选中的小区,传给后端的数组
            selectedBlocksIndex: [], // 批量分割模式选中的小区,前端处理的数组
            vmodal: false, // 批量分割提示弹窗
            segSize: '1x1', // 自动分割的格式
            segPlotName: '', // 批量分割的分块土地默认名称

            bmodal: false, // 批量挂牌提示弹窗
            needCode: true, // 批量挂牌是否需要验证码
            type: '立即购买', // 挂牌-购买形式
            options: [{value: '立即购买',label: this.$t('LandMap3.buytype1')},], // 挂牌-购买形式的选择集
            saleUnitPrice: 0, // 单位土地售价
            code: '', // 挂牌验证码
            timeCount: 0, // 倒计时
            timer: null, // 定时器
            sellStep: true, // true是第一步，false是第二步

            showToolTipText: false, // 是否显示全部文本悬浮窗
            toolTipText: '', // 显示的文本

            activityModal: false, // 控制活动弹窗的开启关闭
            activityStep: true, // 活动选择页面和活动处理页面
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        // 活动-开启弹窗和关闭弹窗
        openActivityModal() {
            if (this.activityStep) { // 不处于活动处理页面，直接打开
                this.activityModal = true;
            }
        },
        closeActivityModal() {
            this.activityModal = false;
        },
        acceptActivity(index) {
            this.changeMode('view');
            if (index === 0) {
                // 1号活动--挂牌小区，按照单位土地价格进行售卖
                this.mode = 'activity1';
                this.activityModal = false;
                this.activityStep = false;
            }
        },
        // 重置活动弹窗
        resetActivityModal() {
            this.activityModal = false;
            this.activityStep = true;
            this.changeMode('view');
        },
        // 打开一号活动的活动处理页面
        openActivity1Modal() {
            this.$refs.activity.codeNeedOrNot();
        },



        /* --------------------------------------------------初始化功能区-------------------------------------------------- */
        // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal1 = this.$refs.leftContainer;
            const modal2 = this.$refs.rightContainer;
            const modal3 = this.$refs.middleContainer;
            if (window.innerWidth >= 1870 && window.innerHeight >= 980) {
                modal1.style.transform = `scale(1)`;
                modal2.style.transform = `scale(1)`;
                this.$refs.middleContainer.style.left = '360px';
                this.$refs.middleContainer.style.width = window.innerWidth - 420 - this.smallMapCanvasWidth + 'px';
                modal3.style.transform = `scale(1)`;
            } else {
                const scaleX = window.innerWidth / 1870;
                const scaleY = window.innerHeight / 980;
                const scale = Math.min(scaleX, scaleY);
                modal1.style.transform = `scale(${scale})`;
                modal2.style.transform = `scale(${scale})`;
                this.$refs.middleContainer.style.left = scale * 360 + 'px';
                this.$refs.middleContainer.style.width = ((window.innerWidth - scale * 360 - scale * (this.smallMapCanvasWidth + 60)) / scale).toFixed(2) + 'px';
                modal3.style.transform = `scale(${scale})`;
            }
            // 获取大地图容器的宽高
            this.containerWidth = this.$refs.bigmapcontainer.clientWidth;
            this.containerHeight = this.$refs.bigmapcontainer.clientHeight;
            // 获取小地图画布长宽和容器长宽
            this.smallMapContainerHeight = this.containerHeight / 10;
            this.smallMapContainerWidth = this.containerWidth / 10;
        },
        drawBigMapGrid() { // 绘制大地图网格
            const canvas = document.querySelector('.big-map-background');  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            canvas.style.backgroundImage = "url(" + this.originLand.landpicture + ")"; // 修改背景图片
            // 清除原有的所有大地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0;i < this.colLength;i++) {  // 绘制大地图网格
                for (let j = 0;j < this.rowLength;j++) {
                    ctx.strokeRect(i*this.gridSize,j*this.gridSize,this.gridSize,this.gridSize)
                }
            }
        },
        drawSmallMapGrid() { // 绘制小地图网格
            const canvas = this.$refs.smallmapbackground
            const ctx = canvas.getContext("2d")
            // 清除原有的所有小地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#eeeeee" // 设置网格颜色
            for (let i = 0;i < this.colLength;i++) {  // 绘制大地图网格
                for (let j = 0;j < this.rowLength;j++) {
                    ctx.strokeRect(i * this.gridSize / 10,j * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10)
                }
            }
        },
        drawSmallMapBoxShadow() { // 绘制小地图容器边框
            const canvas = this.$refs.smallmap
            const ctx = canvas.getContext("2d")
            // 清除原有的所有小地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45" // 设置小地图边框颜色
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight)
        },
        drawProcessedArea() { // 绘制大地图+小地图已分割、挂牌中、已售出的小区块
            const canvas = document.querySelector('.big-map-background');  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            const canvas2 = this.$refs.smallmap; // 小地图
            const ctx2 = canvas2.getContext("2d");
            if (this.filterArea === 'selled') {
                // 已售出的小区
                ctx.fillStyle = "yellow" // 设置网格颜色
                ctx2.fillStyle = "yellow"
                for (let i = 0;i < this.Sold.length;i++) {  // 绘制大地图网格
                    const matches = this.Sold[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            } else if (this.filterArea === 'selling') {
                // 已挂牌的小区
                ctx.fillStyle = "Cyan" // 设置网格颜色
                ctx2.fillStyle = "Cyan"
                for (let i = 0;i < this.Listing.length;i++) {  // 绘制大地图网格
                    const matches = this.Listing[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            } else if (this.filterArea === 'selledandselling') {
                // 同时已挂牌和已售出的小区
                ctx.fillStyle = "SpringGreen" // 设置网格颜色
                ctx2.fillStyle = "SpringGreen"
                for (let i = 0;i < this.ListingandSold.length;i++) {  // 绘制大地图网格
                    const matches = this.ListingandSold[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            } else {
                // 已分割的小区
                ctx.fillStyle = "OrangeRed" // 设置网格颜色
                ctx2.fillStyle = "OrangeRed"
                for (let i = 0;i < this.segmentation.length;i++) {  // 绘制大地图网格
                    const matches = this.segmentation[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            }
        },
        drawBigMapProcessedArea() { // 绘制大地图已分割、挂牌中、已售出的小区块
            const canvas = document.querySelector('.big-map-background');  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            // 根据筛选条件将含有特定状态的小区绘制成特定颜色
            if (this.filterArea === 'selled') {
                // 已售出的小区
                ctx.fillStyle = "yellow" // 设置网格颜色
                for (let i = 0;i < this.Sold.length;i++) {  // 绘制大地图网格
                    const matches = this.Sold[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                }
            } else if (this.filterArea === 'selling') {
                // 已挂牌的小区
                ctx.fillStyle = "Cyan" // 设置网格颜色
                for (let i = 0;i < this.Listing.length;i++) {  // 绘制大地图网格
                    const matches = this.Listing[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                }
            } else if (this.filterArea === 'selledandselling') {
                // 同时已挂牌和已售出的小区
                ctx.fillStyle = "SpringGreen" // 设置网格颜色
                for (let i = 0;i < this.ListingandSold.length;i++) {  // 绘制大地图网格
                    const matches = this.ListingandSold[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                }
            } else {
                ctx.fillStyle = "OrangeRed" // 设置网格颜色
                for (let i = 0;i < this.segmentation.length;i++) {  // 绘制大地图网格
                    const matches = this.segmentation[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                }
            }
        },
        drawSmallMapProcessedArea() { // 绘制小地图已分割、挂牌中、已售出的小区块
            const canvas2 = this.$refs.smallmap; // 小地图
            const ctx2 = canvas2.getContext("2d");
            // 根据筛选条件将含有特定状态的小区绘制成特定颜色
            if (this.filterArea === 'selled') {
                // 已售出的小区
                ctx2.fillStyle = "yellow"
                for (let i = 0;i < this.Sold.length;i++) {  // 绘制大地图网格
                    const matches = this.Sold[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            } else if (this.filterArea === 'selling') {
                // 已挂牌的小区
                ctx2.fillStyle = "Cyan"
                for (let i = 0;i < this.Listing.length;i++) {  // 绘制大地图网格
                    const matches = this.Listing[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            } else if (this.filterArea === 'selledandselling') {
                // 同时已挂牌和已售出的小区
                ctx2.fillStyle = "SpringGreen"
                for (let i = 0;i < this.ListingandSold.length;i++) {  // 绘制大地图网格
                    const matches = this.ListingandSold[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            } else {
                // 已分割的小区
                ctx2.fillStyle = "OrangeRed"
                for (let i = 0;i < this.segmentation.length;i++) {  // 绘制大地图网格
                    const matches = this.segmentation[i].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                    const x = parseInt(matches[0]) - 1;
                    const y = parseInt(matches[1]) - 1;
                    ctx2.fillRect(x * this.gridSize / 10,y * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10);
                }
            }
        },
        convexHull(xarray,yarray) { // 计算已分割小区的凸包范围
            for (let i = 0; i < xarray.length - 1; i++) { // 冒泡排序
                for (let j = 0; j < xarray.length - i - 1; j++) {
                    // 1.对每一个值和它的下一个值进行比较
                    if (xarray[j] > xarray[j + 1]) {
                        // 如果第一个值更多，则将其赋予自定义计数值 count
                        let count = xarray[j];
                        // 反复交换
                        xarray[j] = xarray[j + 1];
                        xarray[j + 1] = count;
                    };
                };
            };
            for (let i = 0; i < yarray.length - 1; i++) {
                for (let j = 0; j < yarray.length - i - 1; j++) {
                    if (yarray[j] > yarray[j + 1]) {
                        let count = yarray[j];
                        yarray[j] = yarray[j + 1];
                        yarray[j + 1] = count;
                    };
                };
            };
            
            let minX = xarray[0];
            let maxX = xarray[xarray.length - 1];
            let minY = yarray[0];
            let maxY = yarray[yarray.length - 1];
            const xlength = Math.floor(this.containerWidth / this.gridSize); // 屏幕容器内最大显示网格数-横坐标
            const ylength = Math.floor(this.containerHeight / this.gridSize); // 屏幕容器内最大显示网格数-纵坐标
            const xmax = Math.floor(xarray.length / 2) - 1;
            const ymax = Math.floor(yarray.length / 2) - 1;

            let i = 0;
            let flag = false; // 如果根本没有两个小区能限制在屏幕内，这个变为true
            while (maxX - minX >= xlength) { // 计算在容器内最远的两个小区的横坐标
                i++;
                minX = xarray[i];
                maxX = xarray[xarray.length - i];
                if (i === xmax) {
                    flag = true;
                    break;
                }
            }
            i = 0;
            while (maxY - minY >= ylength) { // 计算在容器内最远的两个小区的纵坐标
                i++;
                minY = yarray[i];
                maxY = yarray[yarray.length - i];
                if (i === ymax) {
                    flag = true;
                    break;
                }
            }

            let col = 0;
            let row = 0;
            if (flag) { // 没有在屏幕内的两个小区
                col = minX * this.gridSize - this.containerWidth / 2;
                row = minY * this.gridSize - this.containerHeight / 2;
                if (col<0) col=0;
                if (row<0) row=0;
            } else { // 计算该凸包的中心点所对应的容器坐标
                col = (maxX + minX) / 2 * this.gridSize - this.containerWidth / 2;
                row = (maxY + minY) / 2 * this.gridSize - this.containerHeight / 2;
                if (col<0) col=0;
                if (row<0) row=0;
            }

            const canvas = document.querySelector('.big-map-background');
            canvas.style.left = `${col}px`;
            canvas.style.top = `${row}px`;
            const canvas2 = document.querySelector('.big-map');
            canvas2.style.left = `${col}px`;
            canvas2.style.top = `${row}px`;
            // 更新小地图
            this.col = -parseInt(canvas.style.left) / (this.zoomFactor * 10);
            this.row = -parseInt(canvas.style.top) / (this.zoomFactor * 10);
            this.smallMapMove() // 移动边框
            this.drawProcessedArea() // 绘制已分割小区
        },



        /* --------------------------------------------------屏幕页面监视区-------------------------------------------------- */
        // 鼠标悬浮在文本上显示全部文本悬浮窗事件
        onMouseOverText(text) {
            this.toolTipText = text;
            this.showToolTipText = true;
        },
        onMouseOutText() {
            this.showToolTipText = false;
        },
        onMouseMoveText(event) {
            this.$refs.tooltip2.style.top = event.clientY + 'px';
            this.$refs.tooltip2.style.left = event.clientX + 'px';
        },
        // 鼠标点击事件
        onMouseClick() {
            if (this.mode === 'view') { // 查看模式只能选中单个小区
                this.bigMapSelect() // 将选中的土地方块高亮显示
            } else if (this.mode === 'partition') { // 批量分割模式详情见函数
                this.segSelect();
            } else if (this.mode === 'sell') { // 批量挂牌模式详情见函数
                this.segSoldSelect();
            } else if (this.mode === 'activity1') { // 活动1：拼拼乐
                this.segSelect();
            }
            this.showLandInfor() // 显示当前网格土地信息
        },
        // 鼠标按下事件
        onMouseDown(event) {
            this.originX = event.clientX; // 在鼠标按下时，获取鼠标的当前坐标值
            this.originY = event.clientY;
            this.isMouseDown = true;
        },
        // 鼠标松开事件
        onMouseUp() {
            this.isMouseDown = false;
        },
        // 鼠标悬浮事件
        onMouseOver(event) {
            event.target.style.cursor = "pointer";
            this.showToolTips = true;
        },
        onMouseOut() {
            this.showToolTips = false;
        },
        // 鼠标移动事件
        onMouseMove(event) {
            if (!this.isMouseDown) {
                this.floatingframe(event.clientX,event.clientY)
                return;
            }
            let moveX = event.clientX - this.originX; // 当鼠标滑动时，每当坐标值更改，获取一次鼠标坐标移动的偏差值
            let moveY = event.clientY - this.originY;
      
            // 获取当前容器显示的网格缓冲区位置，根据移动边界进行更新
            const canvas = document.querySelector('.big-map-background');
            const left = parseInt(canvas.style.left) + moveX
            const down = parseInt(canvas.style.top) + moveY
            const rightend = this.containerWidth - this.canvasWidth
            const downend = this.containerHeight - this.canvasHeight
            if (left > 0 || left < rightend) {
                moveX = 0
            }
            if(down > 0 || down < downend) {
                moveY = 0
            }
            canvas.style.left = `${parseInt(canvas.style.left || 0) + moveX}px`;
            canvas.style.top = `${parseInt(canvas.style.top || 0) + moveY}px`;

            // 双层大地图均需要移动
            const canvas2 = document.querySelector('.big-map');
            canvas2.style.left = `${parseInt(canvas2.style.left || 0) + moveX}px`;
            canvas2.style.top = `${parseInt(canvas2.style.top || 0) + moveY}px`;

            // 更新鼠标坐标
            this.originX = event.clientX;
            this.originY = event.clientY;
            // 更新容器坐标
            this.col = -parseInt(canvas.style.left) / (this.zoomFactor * 10)
            this.row = -parseInt(canvas.style.top) / (this.zoomFactor * 10)
            // 更新小地图
            this.smallMapMove() // 移动边框
            this.drawProcessedArea() // 绘制已分割小区

            event.preventDefault();
        },
        zoom(event) {
            const canvas = document.querySelector('.big-map-background');

            // 监听鼠标滚轮事件
            const delta = Math.sign(event.deltaY);
            const maxScale = 2; // 最大缩放比例
            const minScale = 0.5; // 最小缩放比例

            if (delta > 0) { // 缩小时可能出现缩小后的画布小于屏幕，此时不可缩小
                if (this.smallMapCanvasHeight <= this.containerHeight / (10 * this.zoomFactor - delta) || this.smallMapCanvasWidth <= this.containerWidth / (10 * this.zoomFactor - delta)) {
                    return
                }
            }

            // 根据滚轮方向调整缩放比例
            this.zoomFactor = Math.max(minScale, Math.min(maxScale, Math.round((this.zoomFactor - delta * 0.1) * 10) / 10));
            this.canvasHeight = this.zoomFactor * this.originalCanvasHeight
            this.canvasWidth = this.zoomFactor * this.originalCanvasWidth
            this.gridSize = this.zoomFactor * 44

            // 小地图容器边框的长宽也需要同比例扩大/缩小
            this.smallMapContainerHeight = this.containerHeight * this.smallMapCanvasHeight / this.canvasHeight
            this.smallMapContainerWidth = this.containerWidth * this.smallMapCanvasWidth / this.canvasWidth

            // 设置canvas的缩放比例和平移位置
            if (delta > 0) { // 缩小时需要注意是否到达画布最右侧，如果到达，需要将容器位置平移
                const canvas2 = document.querySelector('.big-map');
                const maxcol = this.smallMapCanvasWidth - this.smallMapContainerWidth;
                const maxrow = this.smallMapCanvasHeight - this.smallMapContainerHeight;
                const bigcol = this.containerWidth - this.canvasWidth;
                const bigrow = this.containerHeight - this.canvasHeight;
                if (this.col > maxcol && this.row > maxrow) { // 超出右下范围
                    this.col = maxcol;
                    this.row = maxrow;
                    canvas2.style.left = `${parseInt(bigcol)}px`;
                    canvas.style.left = `${parseInt(bigcol)}px`;
                    canvas2.style.top = `${parseInt(bigrow)}px`;
                    canvas.style.top = `${parseInt(bigrow)}px`;
                } else if (this.col > maxcol) { // 超出右侧范围
                    this.col = maxcol;
                    canvas.style.left = `${parseInt(bigcol)}px`;
                    canvas2.style.left = `${parseInt(bigcol)}px`;
                    canvas.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
                    canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
                } else if (this.row > maxrow) { // 超出下边范围
                    this.row = maxrow;
                    canvas.style.top = `${parseInt(bigrow)}px`;
                    canvas2.style.top = `${parseInt(bigrow)}px`;
                    canvas.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
                    canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
                } else { // 缩小时固定左上角
                    canvas.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
                    canvas.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
                    canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
                    canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
                }
            } else { // 放大时固定左上角
                canvas.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
                canvas.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
                const canvas2 = this.$refs.bigmap
                canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
                canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            }
      
            // 重新绘制画布
            let time1 = setTimeout(() => {  // 等待 100 毫秒后再绘制
                this.drawBigMapGrid() // 初始化大地图网格
                this.drawBigMapSelect() // 绘制已选中的小区块
                this.drawSmallMapBoxShadow() // 初始化小地图网格
                this.drawProcessedArea() // 绘制小地图已分割小区
                clearInterval(time1)
            }, 100);
        },





        /* --------------------------------------------------屏幕操作功能区-------------------------------------------------- */
        smallMapMove() { // 小地图跟随大地图页面容器移动更新
            const canvas = this.$refs.smallmap
            const ctx = canvas.getContext('2d')
            // 清除原有的矩形边框
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // 绘制新的矩形边框
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight);
        },
        bigMapSelect() { // 大地图鼠标按下时选中某个区域，此区域高亮显示
            this.canMoveIn = true; // 显示进入该小区按钮
            // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.bigmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            this.landcol = Math.floor(canvasX / this.gridSize);
            this.landrow = Math.floor(canvasY / this.gridSize);
            const ctx = canvas.getContext('2d');
            // 清除原有的所有大地图选中元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // 绘制新的选中元素
            ctx.fillStyle = "orange"
            ctx.fillRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
        },
        drawBigMapSelect() { // 放大缩小时固定选中的小区高亮处
            const canvas = this.$refs.bigmap;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            if (this.mode) {
                ctx.fillRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
            } else {
                for (let i = 0;i < this.selectedBlocksIndex.length;i++) {
                    ctx.fillRect(this.selectedBlocksIndex[i].col * this.gridSize + 1,this.selectedBlocksIndex[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                }
            }
        },
        smallMapMouseDown(event) { // 鼠标点中小地图
            this.isSmallMapMouseDown = true
            // 获取鼠标按下时在小地图画布上的坐标
            const canvas = this.$refs.smallmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = event.clientX - rect.left;
            const canvasY = event.clientY - rect.top;
            this.col = Math.max(Math.min(canvasX - this.smallMapContainerWidth * 0.5,this.smallMapCanvasWidth - this.smallMapContainerWidth),0)
            this.row = Math.max(Math.min(canvasY - this.smallMapContainerHeight * 0.5,this.smallMapCanvasHeight - this.smallMapContainerHeight),0)
            const ctx = canvas.getContext('2d')
            // 清除原有的矩形边框并绘制新的矩形边框
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight);
            // 大地图移动到相应位置
            const canvas2 = this.$refs.bigmapbackground
            canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            const canvas3 = this.$refs.bigmap
            canvas3.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas3.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            // 更新鼠标坐标
            this.originX = event.clientX;
            this.originY = event.clientY;
        },
        smallMapMouseMove(event) {
            if(!this.isSmallMapMouseDown) {
                return
            }
            // 获取当前容器显示的网格缓冲区位置，根据移动边界进行更新
            const canvas = this.$refs.smallmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = event.clientX - rect.left;
            const canvasY = event.clientY - rect.top;
            this.col = Math.max(Math.min(canvasX - this.smallMapContainerWidth * 0.5,this.smallMapCanvasWidth - this.smallMapContainerWidth),0)
            this.row = Math.max(Math.min(canvasY - this.smallMapContainerHeight * 0.5,this.smallMapCanvasHeight - this.smallMapContainerHeight),0)
            // 清除原有的矩形边框并绘制新的矩形边框
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight);
            // 大地图移动到相应位置
            const canvas2 = this.$refs.bigmapbackground
            canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            const canvas3 = this.$refs.bigmap
            canvas3.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas3.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
        },
        smallMapMouseUp() {
            this.isSmallMapMouseDown = false
            // 绘制小地图网格
            this.drawSmallMapBoxShadow()
            this.drawSmallMapProcessedArea() // 绘制小地图已分割小区
        },
        showLandInfor() { // 更新左侧菜单栏信息
            /* // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.bigmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            // 将位置坐标转化为土地块的坐标
            this.landcol = Math.floor(canvasX / this.gridSize)
            this.landrow = Math.floor(canvasY / this.gridSize) */
            // 二号土地坐标更新
            this.SecondLandCoordinates = '区(' + (this.landcol + 1) + ',' + (this.landrow + 1) + ')';
        },
        floatingframe(x,y) { // 绘制悬浮框时获取坐标
            // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.bigmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = x - rect.left;
            const canvasY = y - rect.top;
            this.toolcol = Math.floor(canvasX / this.gridSize);
            this.toolrow = Math.floor(canvasY / this.gridSize);
            this.$refs.tooltip.style.top = y - 100 + 'px';
            this.$refs.tooltip.style.left = x - 360 + 'px';
            if (x > this.containerWidth - 44) {
                this.$refs.tooltip.style.marginLeft = '-80px';
            } else {
                this.$refs.tooltip.style.marginLeft = '20px';
            }
            if (y > this.containerHeight - 44) {
                this.$refs.tooltip.style.marginTop = '-100px';
            } else {
                this.$refs.tooltip.style.marginTop = '-50px';
            }
        },
        moveIn() {
            this.$router.push({
                path:'/land-operate/area-map?land=' + this.originallandid + '&area=' + this.SecondLandCoordinates})
        },
        /* 切换批量分割模式 */
        changeMode(tab) {
            if (tab !== 'view') {
                // 清理查看模式可能存在的当前选中的小区
                const canvas = this.$refs.bigmap;
                const ctx = canvas.getContext('2d');
                ctx.clearRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                this.mode = tab;
            } else {
                this.selectedBlocksIndex = [];
                for (let i = 0;i < this.segChecked.length; i++) {
                    this.segChecked[i] = false;
                }
                this.selectedBlocks = []; // 清空已选中的批量分割小区
                this.segPlotName = ''; // 清空输入的分块土地名称
                // 重新绘制二层地图，清理选中的批量分割小区绘图
                const canvas = this.$refs.bigmap;
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                this.mode = 'view';
            }
        },
        /* 删除选中的批量分割小区 */
        deleteBlock(index) {
            /* const matches = this.selectedBlocks[index].match(/\d+/g);
            const num1 = parseInt(matches[0], 10); // 将第一个匹配到的数字转换为整数
            const num2 = parseInt(matches[1], 10); // 将第二个匹配到的数字转换为整数 */
            // 将该小区对应的网格绘图情空
            const canvas = this.$refs.bigmap;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(this.selectedBlocksIndex[index].col * this.gridSize + 1,this.selectedBlocksIndex[index].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
            this.selectedBlocks.splice(index,1);
            this.selectedBlocksIndex.splice(index,1);
        },
        /* 批量分割模式点选小区事件 */
        segSelect() {
            // 获取 canvas 的位置信息
            const canvas = this.$refs.bigmap;
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            this.landcol = Math.floor(canvasX / this.gridSize);
            this.landrow = Math.floor(canvasY / this.gridSize);
            const ctx = canvas.getContext('2d');
            // 判断选中的小区是否为已经录入到数组的小区，如果是，返回数组下标，不是，则返回-1
            const select = this.selectedBlocksIndex.findIndex(item => item.col === this.landcol && item.row === this.landrow);
            if (select !== -1) { // 选中已选中的小区，将该小区从数组内删除
                this.deleteBlock(select);
            } else { // 选中未选中的小区，判断是否为未分割，未分割则选中
                const blockNumber = '区(' + (this.landcol + 1) + ',' + (this.landrow + 1) + ')';
                const hasCommonElement = this.segmentation.findIndex(item => item === blockNumber);
                if (hasCommonElement >= 0) { // 选中的小区为已分割
                    this.$toast.fail(this.$t('LandMap1.l20'));
                } else {
                    this.selectedBlocksIndex.push({col:this.landcol,row:this.landrow});
                    this.selectedBlocks.push(blockNumber);
                    // 绘制新的选中元素
                    ctx.fillStyle = "orange"
                    ctx.fillRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                }
            }
        },
        /* 批量分割按钮事件 */
        openSegModal() {
            if (this.segPlotName === '') {
                this.$toast.fail(this.$t('LandMap1.l23'));
            } else {
                this.vmodal = true;
            }
        },
        /* 调用接口进行批量分割事件 */
        segAll() {
            const data = {
                communitynumber: this.selectedBlocks,
                size: this.segSize,
                originallandid: this.originallandid,
                mergelandname: this.segPlotName
            }
            this.$toast(this.$t('LandMap1.l24'));
            this.$http.post("/v1/terrain-map/segmentation-originalland-communitylist",data)
            .then((res) => {
                this.$toast.success(this.$t('LandMap1.l25'));
                window.location.reload(); // 刷新页面
            })
            .catch((error) => {
                this.$toast.fail(this.$t('LandMap1.l26'));
            });
        },
        /* 筛选含特定状态的分块土地的小区，以特定颜色突出显示 */
        drawFilterBackground(val) {
            if (this.filterArea === val) {
                this.filterArea = '';
            } else {
                this.filterArea = val;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.drawBigMapGrid() // 初始化大地图网格
            if (this.landcol !== null) {
                this.drawBigMapSelect() // 绘制已选中的小区块
            }
            this.drawSmallMapBoxShadow() // 初始化小地图网格
            this.drawProcessedArea(); // 绘制含特定状态分块土地的小区
            loadingToast.clear();
        },

        /* 批量挂牌模式点选小区事件 */
        segSoldSelect() {
            // 获取 canvas 的位置信息
            const canvas = this.$refs.bigmap;
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            this.landcol = Math.floor(canvasX / this.gridSize);
            this.landrow = Math.floor(canvasY / this.gridSize);
            const ctx = canvas.getContext('2d');
            // 判断选中的小区是否为已选入批量挂牌数组的小区，如果是，返回数组下标，不是，则返回-1
            const select = this.selectedBlocksIndex.findIndex(item => item.col === this.landcol && item.row === this.landrow);
            if (select !== -1) { // 选中已选中的小区，将该小区从数组内删除
                this.deleteSoldBlock(select);
            } else { // 选中未选中的小区，判断是否为可选入批量挂牌数组的小区，可选入则选中
                const hasCommonElement = this.canBeSoldBlocks.findIndex(item => item === '区(' + (this.landcol + 1) + ',' + (this.landrow + 1) + ')');
                if (hasCommonElement >= 0) { // 选中的小区可选入
                    this.selectedBlocksIndex.push({col:this.landcol,row:this.landrow});
                    this.segChecked[hasCommonElement] = true;
                    this.selectedBlocks.push('区(' + (this.landcol + 1) + ',' + (this.landrow + 1) + ')');
                    // 绘制新的选中元素
                    ctx.fillStyle = "orange"
                    ctx.fillRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                } else {
                    this.$toast.fail(this.$t('LandMap1.l27'));
                }
            }
        },
        /* 删除选中的批量挂牌小区 */
        deleteSoldBlock(index) {
            // 将该小区对应的网格绘图情空
            const canvas = this.$refs.bigmap;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(this.selectedBlocksIndex[index].col * this.gridSize + 1,this.selectedBlocksIndex[index].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
            this.selectedBlocks.splice(index,1);
            this.segChecked.splice(index,1);
            this.selectedBlocksIndex.splice(index,1);
        },
        /* 右侧可选挂牌小区选中或删除批量挂牌小区事件 */
        selectSoldBlock(index) {
            const canvas = this.$refs.bigmap;
            const ctx = canvas.getContext('2d');
            if (this.segChecked[index]) { // 选中小区，加入选中小区数组，选中小区高亮并移动到此处
                if (this.selectedBlocks.length >= 10) {
                    this.segChecked[index] = false;
                    this.$toast.fail(this.$t('all.uptoTenBlocks') + '!');
                }
                this.selectedBlocks.push(this.canBeSoldBlocks[index]);
                const matches = this.canBeSoldBlocks[index].match(/\d+/g); // 每一个小区坐标都是以'区(x,y)'格式获取的，使用正则表达式提取坐标数据
                const x = parseInt(matches[0]) - 1;
                const y = parseInt(matches[1]) - 1;
                this.selectedBlocksIndex.push({col:x,row:y});
                // 绘制新的选中元素
                ctx.fillStyle = "orange"
                ctx.fillRect(x * this.gridSize + 1,y * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
            } else {
                // 将该小区对应的网格绘图情空
                const findIndex = this.selectedBlocks.findIndex(item => item === this.canBeSoldBlocks[index]);
                if (findIndex >= 0) {
                    ctx.clearRect(this.selectedBlocksIndex[findIndex].col * this.gridSize + 1,this.selectedBlocksIndex[findIndex].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2);
                    this.selectedBlocks.splice(findIndex,1);
                    this.selectedBlocksIndex.splice(findIndex,1);
                }
            }
        },
        // 获取验证码前打开弹窗需要检测是否需要验证码
        openSellModal() {
            if (this.saleUnitPrice) {} else {
                this.$t('LandMap1.alertPrice');
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/if-operation')
                .then(res => {
                    loadingToast.clear();
                    this.needCode = res.data.data;
                    if (!this.needCode) { this.sellStep = false; }
                    this.bmodal = true;
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        // 关闭批量挂牌弹窗
        closeSellModal() {
            this.saleUnitPrice = '';
            this.code = '';
            this.sellStep = true;
            clearInterval(this.timer);
            this.timeCount = 0;
            this.bmodal = false;
        },
        // 获取验证码
        getVerificationCode() {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/verification-code')
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.startCountdown();
                        if (res.data.data === '邮箱验证码发送成功') {
                            this.$toast.success(this.$t('LandSellList.sendemail'));
                        } else {
                            this.$toast.success(this.$t('LandSellList.sendphone'));
                        }
                    } else {
                        this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.timeCount = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                    this.timeCount--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        /* 调用接口进行批量挂牌事件 */
        sellAll() {
            const data = {
                communitynumbers: this.selectedBlocks,
                salesmethods: this.type,
                originallandid: this.originallandid,
                pricenum: this.saleUnitPrice.toFixed(2)
            }
            if (this.segPlotName === '') {
                data["commodityname"] = this.segPlotName;
            }
            if (this.needCode) {
                data["code"] = this.code;
            }
            this.$toast(this.$t('LandMap1.l24'));
            this.$http.post("/v1/terrain-map/segmentation-originalland-communitylist",data)
            .then((res) => {
                if (res.data.status === 200) {
                    this.$toast.success(this.$t('LandMap3.saleSuccess'));
                    window.location.reload(); // 刷新页面
                } else {
                    this.$toast.fail(this.$t('all.sale失败'));
                    this.closeSellModal();
                }
            })
            .catch((error) => {
                this.$toast.fail(this.$t('LandMap1.l26'));
            });
        },
    },
}
</script>

<style scoped>
.canvas-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #F2F8FF;
    display: flex;
    flex-direction: row;
    position: relative;
}

.big-map-transform {
    transform-origin: top left;
}

.big-map-container {
    margin-top: 100px;
    height: calc(100% - 300px);
    width: 100%;
    position: relative;
    overflow: hidden;
    user-select: none; /* 防止选中文本影响拖动 */
}
@media (max-height: 980px) {
    .big-map-container {
        height: calc(100% - 100px);
    }
}

.big-map-background {
    background-image: none;
    background-size: cover;
    position: absolute;
}

.big-map {
    position: absolute;
}

.tooltip {
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    max-width: 300px;
    padding: 6px 20px;
    position: absolute;
}

.small-map-parent {
    height: 100%;
    position: absolute;
    right: 0;
    padding: 30px;
    transform-origin: top right;
}

.small-map-background {
    position: absolute;
    right: 30px;
    top: 30px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 1);
}

.small-map {
    position: absolute;
    right: 30px;
    top: 30px;
}

/* 屏幕左侧土地信息栏 */
.land-information-parent {
    height: 100%;
    width: 360px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    transform-origin: top left;
}
.land-information-menu {
    margin-top: 16px;
    min-height: 180px;
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    background-color: #FFFFFF;
    border-radius: 8px;
}
.land-information-menu-row {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 30px;
}
.land-information-menu-row >>> span {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.left-information-button {
    height: 36px;
    width: 100%;
    margin-top: 16px;
    border-radius: 16px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
/* 进入小区按钮 */
.button-movein {
    background-color: #165DFF;
}
/* 批量分割按钮 */
.button-batchsegmentation {
    background-color: #ff3737;
}
/* 批量挂牌按钮 */
.button-batchSell {
    background-color: #f2f159;
    color: #000000;
}
/* 取消批量分割模式按钮 */
.button-cancelbatch {
    background-color: #ff8c8c;
}
/* 批量分割右侧显示屏 */
.batch-seg-box {
    position: absolute;
    right: 30px;
    top: 50%;
    overflow: hidden;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 36px 32px;
    display: flex;
    flex-direction: column;
}
.batch-seg-box-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.batch-seg-box-title-icon {
    height: 31px;
    width: 6px;
    background-color: #3662EC;
}
.batch-seg-box-title-span {
    margin-left: 15px;
    font-size: 25px;
    font-weight: 600;
}
.batch-seg-box-items {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.batch-seg-box-items-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
}
/* 批量分割显示屏-分块土地名称输入框样式 */
.batch-seg-plotname-label {
    font-size: 14px;
    font-weight: 600;
}
.batch-seg-plotname-input {
    max-width: 250px;
    margin-left: 10px;
    height: 30px;
    flex-grow: 1;
    border-radius: 10px;
    font-size: 14px;
    background-color: #F1F4F8;
    box-sizing: border-box;
    border: 1px solid #165DFF;
    padding: 0 10px;
}



/* 信息栏缩放图标-没用了 */
.land-information-menu-show {
    display: flex;
    align-items: center;
    height: 30px;
    width: 20px;
    position: absolute;
    top: calc(50% - 15px);
    left: 350px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #767676;
}
.land-information-menu-hidden {
    display: flex;
    align-items: center;
    height: 30px;
    width: 20px;
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #767676;
}
/* 右上小地图显示/隐藏图标-没用了 */
.land-smallmap-show {
    height: 70px;
    width: 150px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #767676;
    border-radius: 12px;
    color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
}
</style>