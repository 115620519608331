<template>
  <div class="normal-card-box" style="transform-origin: top left;padding:26px 40px;" ref="mainContainer">
    <!-- 标题 -->
    <div class="normal-card-title flex flex-row justify-center">
      <span>{{ $t('MobileOrderList.title') }}</span>
      <div class="flex-grow flex justify-end">
        <button class="add" v-if="mode === 0 && orderList.length > 0" @click="mode = 1">{{ $t('MobileOrderList.segPay') }}</button>
        <button class="add add-gray" v-else @click="cancelSeg">{{ $t('MobileOrderList.cancelSeg') }}</button>
      </div>
    </div>

    <!-- 日期筛选 -->
    <div class="drop-filter">
      <el-dropdown @command="handleDate" class="date-drop">
        <span>
          {{ filter.datename }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="不限">{{ $t('PcOrderList.all') }}</el-dropdown-item>
          <el-dropdown-item command="近一周">{{ $t('LandSellList.tip2') }}</el-dropdown-item>
          <el-dropdown-item command="近一个月">{{ $t('LandSellList.tip3') }}</el-dropdown-item>
          <el-dropdown-item command="近三个月">{{ $t('LandSellList.tip4') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="infor-box">{{ $t('PcConfirmOrder.orderinfor') }}</div>
      <div class="price-box">{{ $t('MobileRecharge.sum') }}</div>
      <el-dropdown @command="handleStatu" class="statu-drop">
        <span>
          {{ getEnglish(filter.statu) }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="全部状态">{{ $t('MobileOrderList.statu0') }}</el-dropdown-item>
          <el-dropdown-item command="待付款">{{ $t('MobileOrderList.statu1') }}</el-dropdown-item>
          <el-dropdown-item command="已付款">{{ $t('MobileOrderList.statu2') }}</el-dropdown-item>
          <el-dropdown-item command="已退款">{{ $t('MobileOrderList.statu3') }}</el-dropdown-item>
          <el-dropdown-item command="退款中">{{ $t('MobileOrderList.statu4') }}</el-dropdown-item>
          <el-dropdown-item command="已驳回">{{ $t('MobileOrderList.statu5') }}</el-dropdown-item>
          <el-dropdown-item command="已取消">{{ $t('MobileOrderList.statu6') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="control-box">{{ $t('LandSellList.originallandname') }}</div>
    </div>

    <!-- 列表 -->
    <div class="order-list flex-grow flex flex-col w-full" style="margin-top:16px;">
      <OrderWindow ref="childRef" :order="order" :mode="mode" :balance="balance" v-for="(order, index) in orderList" :key="index" :style="index !== 0?'margin-top:16px;':''"></OrderWindow>
    </div>

    <!-- 分页 -->
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      background
      :current-page.sync="pageNum"
      :page-sizes="[5, 10, 20, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum">
    </el-pagination>
  </div>
</template>

<script>
import OrderWindow from './OrderWindow.vue'
export default {
  components: {
    OrderWindow,
  },
  mounted() {
    this.updateModalScale();
    // 监听窗口大小变化以更新弹窗缩放
    window.addEventListener('resize', this.updateModalScale);
    this.$parent.adjustBoxHeight('100%');
        this.$http.get('/v1/order/select-separateorder-list?pageSize=' + this.pageSize + '&pageNum=' + this.pageNum)
        .then(res => {
              // todo订单接口没有日期筛选
                this.orderList = res.data.data.rows;
                this.totalNum = res.data.data.total;
            }).catch(error => {
                this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
            });
    // 获取余额
    this.$http.get("/v1/recharge/check-balance")
      .then((res) => {
        this.balance = res.data.data.balance;
      })
      .catch((err) => {});
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('resize', this.updateModalScale);
    next();
  },
  data() {
    return {
      filter: {
        date: '',
        datename: this.$t('LandMap1.l3'),
        statu: '全部状态',
      },
      balance: 0,
      pageSize: 10,
      pageNum: 1,
      totalNum: 0, // 订单总数
      loading: true, // 控制骨架屏
      getOrderList: [ // 从后端获取的我的土地列表信息
        {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1925.0",
                    paymentStatus: "已付款",
                    orderDate: "2024-05-24T14:18:50",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黑土地",
                        totalprice: 1200.0,
                        orderstatus: "已付款",
                        landpicture: null,
                        canceltime: '111',
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黄土地",
                        totalprice: 2100.0,
                        orderstatus: "已退款",
                        landpicture: "/image/20230731145640.png",
                        canceltime: '111',
                    }],
                },
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1100.0",
                    paymentStatus: "售后中",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "红土地",
                        totalAmount: "1100.0",
                        paymentStatus: "退款中",
                        landpicture: null,
                        canceltime: '111',
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "泥土地",
                        totalAmount: "1200.0",
                        paymentStatus: "退款申请被驳回",
                        landpicture: null,
                        canceltime: '111',
                    }],
                },
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1300.0",
                    paymentStatus: "待付款",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "湿土地",
                        totalAmount: "1300.0",
                        paymentStatus: "待付款",
                        landpicture: null,
                        canceltime: '111',
                    }],
                },
      ],
      orderList: [], // 显示的我的土地列表信息
      mode: 0, // 模式，0为查看模式，1为批量支付模式，2为批量退款模式
      orders: [], // 批量支付选中的独立订单数据，注意1.切换模式时要清空2.批量支付模式元素是独立订单编号
      totalPrice: 0, // 批量支付传入页面的总金额值，切换模式注意清零
    };
  },
  methods: {
    // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal = this.$refs.mainContainer;
            if (window.innerWidth >= 1180) {
                modal.style.transform = `scale(1)`;
                this.$parent.controlRightBox(960);
            } else {
                const scaleX = (window.innerWidth - 200) / 980;
                modal.style.transform = `scale(${scaleX})`;
                this.$parent.controlRightBox(window.innerWidth - 200);
            }
        },
    // 筛选日期
    handleDate(v) {
      this.filter.datename = v;
      if (v === '不限') {
        this.filter.date = '';
      } else if (v === '近一周') {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.filter.date = [];
        this.filter.date.push(start);
        this.filter.date.push(end);
      } else if (v === '近一个月') {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.filter.date = [];
        this.filter.date.push(start);
        this.filter.date.push(end);
      } else if (v === '近三个月') {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        this.filter.date = [];
        this.filter.date.push(start);
        this.filter.date.push(end);
      }
      this.loadOrder();
    },
    // 筛选状态
    /* 订单状态在后端是中文，需要有双语 */
        getEnglish(statu) {
            if (statu === '全部状态') {
                return this.$t('LandSellList.origindetail')
            } else if (statu === '待付款') {
                return this.$t('MobileOrderList.time');
            } else if (statu === '已付款') {
                return this.$t('MobileOrderList.statu2');
            } else if (statu === '已退款') {
                return this.$t('MobileOrderList.statu3');
            } else if (statu === '退款中') {
                return this.$t('MobileOrderList.statu4');
            } else if (statu === '已驳回') {
                return this.$t('MobileOrderList.statu5');
            } else if (statu === '已取消') {
                return this.$t('MobileOrderList.statu6');
            }
        },
    handleStatu(v) {
      this.filter.statu = v;
      this.pageNum = 1;
      this.loadOrder();
    },
    loadOrder() {
      this.loading = true;
      let url = '/v1/order/select-separateorder-list?pageSize=' + this.pageSize + '&pageNum=' + this.pageNum;
      if (this.filter.statu !== '全部状态') {
        url += '&paymentstatus=' + this.filter.statu
      }
      this.$http.get(url)
        .then(res => {
          this.loading = false;
          this.orderList = res.data.data.rows;
          this.totalNum = res.data.data.total;
        }).catch(error => {
            this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
        });
    },
    pageSizeChange(val) {
      // 更新页距
      this.pageSize = val;
      // 更新显示的数据
      this.loadOrder();
    },
    pageCurrentChange(val) {
      // 更新页码
      this.pageNum = val;
      // 更新显示的数据
      this.loadOrder();
    },


    gotoLandDetail(unitlandid) {
      this.$router.push('/user/property/my-land/detail?id=' + unitlandid);
    },

    // 批量支付，给子组件使用的添加和删除orders的方法
    addOrders(id,price) {
      this.orders.push(id);
      this.totalPrice += price;
    },
    deleteOrders(id,price) {
      this.orders.filter(item => item.separateOrderId !== id);
      this.totalPrice -= price;
    },


    // 取消批量支付模式
    cancelSeg() {
      this.mode = 0;
      this.orders = [];
      this.totalPrice = 0;
      this.$refs.childRef.forEach(child => {
        child.initializeSelect();
      });
    },
    // 批量支付-进入继续支付页面
        segContinuePayment() {
            if (this.orders.length < 1) {
                return;
            }
            this.$router.push({
                path: "/user/continue-payment",
                name: 'UserPcContinue',
                params: {
                    totalPrice: JSON.stringify(this.totalPrice),
                    orders: JSON.stringify(this.orders)
                },
            });
        },
  },
}
</script>

<style scoped>
/* 列表父区 */
.order-list {
  flex-grow: 1;
  width: 100%;
  max-height: calc(100% - 150px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
/* 重置日期按钮 */
.reset-button {
  margin-left: 10px;
  width: 100px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #165DFF;
  cursor: pointer;
  font-size: 14px;
  color: #FFFFFF;
}
/* 查看详情 */
.goto-detail {
    cursor: pointer;
    text-decoration: underline;
    color: #476DFF;
    font-size: 14px;
}

/* 批量支付以及取消批量按钮 */
.add {
  width: 146px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #165DFF;
  font-size: 14px;
  font-weight: 350;
  color: #FFFFFF;
  cursor: pointer;
}
.add-gray {
  background-color: #8f8f8f;
}
</style>