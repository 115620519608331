<template>
    <div class="mobile-shop-home mobile-base-background2" ref="landMarket">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileMyLand.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>

        <div class="orderList-view-filter">
            <div class="orderList-view-filter-items" style="width: 33.3%;" @click="tabsControl('');">
                <span :style="filterMode===''?'color:#3662EC':''">{{$t('MobileOrderList.statu0')}}</span>
                <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode===''">
            </div>
            <div class="orderList-view-filter-items" style="width: 33.3%;" @click="tabsControl('未挂牌');">
                <span :style="filterMode==='未挂牌'?'color:#3662EC':''">{{$t('LandSellList.title')}}</span>
                <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode==='未挂牌'">
            </div>
            <div class="orderList-view-filter-items" style="width: 33.3%;" @click="tabsControl('已挂牌');">
                <span :style="filterMode==='已挂牌'?'color:#3662EC':''">{{$t('LandSellList.name')}}</span>
                <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode==='已挂牌'">
            </div>
        </div>

        <div class="flex-grow flex flex-col w-full overflow-y-auto" style="max-height: calc(100% - 60px)" ref="scrollContainer" @scroll="handleScroll">
            <van-skeleton  v-for="i in 10" :key="i" class="mt-3" title avatar :row="5" :loading="loading" />

            <van-empty v-if="LandInfor.length === 0" :description="$t('MobileMyLand.topic1') + $t('MobileMyLand.topic2')" class="flex-grow"/>

            <div class="normal-card-title ml-5 mt-3" v-show="showLength > 0">
                {{ $t('all.全部') }}
                <span class="text-sm text-gray-500 ml-3">{{$t('PcConfirmOrder.total1')}}</span>
                <span class="mx-1 text-gray-600 text-lg">{{ showLength }}</span>
                <span class="text-sm text-gray-500">{{$t('PcConfirmOrder.total2')}}</span>
            </div>

            <div v-for="(land, index) in LandInfor" :key="index">
                <ShopCartInfor class="animate__animated animate__fadeInUp" :LandInfor="land"/>
            </div>
        </div>

        <van-popup
            v-model="showvmodal"
            closeable
            close-icon="close"
            position="bottom">
            <div>
                <h1 class="text-center text-xl medium p-5">{{$t('MobileMyLandWindow.topic')}}</h1>
                <van-field v-model="newname" autosize :label="$t('MobileMyLandWindow.label')" type="textarea" maxlength="20" :placeholder="$t('MobileMyLandWindow.placeholder')" show-word-limit/>
                <div class="flex p-5 items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="closeModal">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="changeName">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
import MobileLand from "./MobileLand.vue";
import ShopCartInfor from "@/mobile/MobileMyLandWindow";
export default {
    components: {
        ShopCartInfor, // 土地市场组件
        MobileLand, // 挂牌土地信息组件
    },
    mounted() {
        // 初始化土地市场列表
        this.$http.post('/v1/myland/show-myland',{
            pagenum: 1,
            pagesize: this.pageSize,
            status: ''
        }).then(response => {
            this.LandInfor = response.data.data.rows;
            this.showLength = response.data.data.total;
            this.loading = false;
        }).catch(error => {
            this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
        })
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {})
    },
    data() {
        return {
            messages: 0,
            loading: true,
            LandInfor: [/* {
                "id":1,
                "userid":"43a89960781b41de9e16c2575772dc54",
                "unitlandid":"6003",
                "originallandid":"S751",
                "landname":"红土地",
                "totalprice":1301.0,
                "createtime":"2023-07-21T12:54:32",
                originalLand: {
                    originallandname: '黄土高坡',
                    geographicalcoordinates: '128.6,41.73'
                }
            } */],
            filterMode: '', // 筛选条件，全部/未挂牌/已挂牌
            hasMoreData: true, // 是否还有更多数据可加载
            pageNum: 2, // 页码
            pageSize: 10, // 页距
            showLength: 0, // 总数
            flag: true, // 防抖
            showvmodal: false, // 修改土地名称的弹出层
            unitlandid: '', // 修改土地名称时它的编号
            newname: '', // 修改后的分块土地名称
        };
    },
    methods: {
        tabsControl(n) { // 点击选项头选中此页面
            this.filterMode = n;
            this.pageNum = 1;
            this.LandInfor = [];
            this.loadMoreData();
        },
        // 当滚动条达到一定距离时加载新的商品数据
        handleScroll() {
            if (this.flag) { // 防抖
                return;
            }
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;

            // 判断滚动条距离底部的距离是否达到一定范围
            if (scrollHeight - (scrollTop + clientHeight) < 100) {
                this.flag = true;
                this.loadMoreData();
                setTimeout(()=>{
                    // 延时一秒
                    this.flag = false;
                } ,1000)
            }
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http.post("/v1/sale/get-saleselect-list",{
                    status: this.filterMode,
                    pagenum: this.pageNum,
                    pagesize: this.pageSize
                })
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        turnToDetail(index) {
            const newpath = "/mobile/my-land/detail?unitlandid="+this.LandInfor[index].unitlandid + "&orderid=" + this.LandInfor[index].orderid
            this.$router.push({
                path: newpath,
            });
        },
        openModal(id) {
            this.unitlandid = id;
            this.showvmodal = true;
        },
        closeModal() {
            this.showvmodal = false;
        },
        changeName() {},
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.mobile-shop-land {
    display: inline-block;
    width: 100%;
    box-shadow: 0 2px 12px 0 #dcdcdc;
    border-radius: 6px;
    margin-top: 5px;
}
/* ------------------------------把列表页放到这个文件里了-------------------------------- */
.market-background {
    height: 170px;
    width: calc(100% - 20px);
    background-color: #fff;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 6px;
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 30px;
    left: 170px;
    font-size: 20px;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 20px;
    left: 170px;
    font-size: 20px;
}
.market-landaddress {
    /* 地址（后续可能会改为购买按钮） */
    height: 30px;
    width: 80px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
.market-landtag {
    /* 小标签 */
    height: 20px;
    width: 80px;
    position: absolute;
    top: 90px;
    left: 210px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
</style>
