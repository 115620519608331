<template>
    <div class="developer-account-full">
        <!-- 电脑端修改手机号 -->
        <div class="flex flex-col">
            <span class="asociate-label">{{$t('AccountPhone.title')}}</span>
            <span class="asociate-label">{{ users.businessphone?users.businessphone:$t('MyInfor.letter6') }}</span>
            <button class="developer-common-button2" style="margin-top:100px;" @click="vmodal=true;step=1;">{{users.businessphone?$t('AccountEmail.button2'):$t('AccountEmail.button')}}</button>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-show="vmodal">
            <div class="developer-common-modal-box" v-if="step===1">
                <div class="w-full text-center mb-3">{{$t('AccountPhone.title2')}}</div>
                <div class="flex flex-col">
                    <span class="account-modal-input-label">{{$t('AccountPhone.new')}}:</span>
                    <div class="account-modal-input-parent">
                        <img src="../developer/account-phone.png" class="account-modal-input-icon">
                        <input class="account-modal-input" v-model="account" :placeholder="$t('AccountPhone.enternew')" @focus="loadCodeImg">
                    </div>
                </div>
                <div class="flex flex-col" v-if="imgCodeUrl">
                    <span class="account-modal-input-label">{{$t('forgetPassword.imgverify')}}:</span>
                    <div class="flex flex-row w-full items-center">
                        <div class="account-modal-input-parent">
                            <img src="../developer/account-password.png" class="account-modal-input-icon">
                            <input type="text" v-model="imgCode" :placeholder="$t('forgetPassword.enterimgverify')" class="account-modal-input" />
                        </div>
                        <img @click="loadCodeImg" class="h-10 w-30" :src="imgCodeUrl" alt="验证码" @mouseover="changeCursor"/>
                    </div>
                </div>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" @click="closeModal">{{$t('forgetPassword.no')}}</button>
                    <button class="developer-common-button2" style="margin-left:20px;" @click="getVerificationCode">{{$t('forgetPassword.getCode')}}</button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else-if="step===2">
                <div class="w-full text-center mb-3">{{$t('AccountPhone.tip')}}</div>
                <div class="flex flex-col">
                    <span class="account-modal-input-label">{{$t('forgetPassword.verify')}}:</span>
                    <div class="account-modal-input-parent">
                        <img src="../developer/account-password.png" class="account-modal-input-icon">
                        <input type="text" v-model="code" :placeholder="$t('computerlogin.alertEntercode')" class="account-modal-input" />
                    </div>
                </div>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" @click="closeModal">{{$t('AccountEmail.end')}}</button>
                    <button class="developer-common-button2" style="margin-left:20px;" @click="bind">{{$t('AccountEmail.bind')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            users: (state) => state.developer.userInfo,
        }),
    },
    data() {
        return {
            step: 0,
            account: '', // 账号
            code: '', // 验证码
            imgCode: "", // 手机端图像验证码
            imgCodeUrl: "", // 手机端图像验证码的url


            vmodal: false, // 显示弹窗
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        closeModal() {
            this.account = '';
            this.imgCode = '';
            this.imgCodeUrl = null;
            this.code = '';
            this.vmodal = false;
        },
        /*加载图像验证码*/
        loadCodeImg(){
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },
        getVerificationCode() {
            const re = /^[0-9]+$/
            if (this.account === '' || !re.test(this.account)) {
                this.$toast.fail(this.$t('computerlogin.alertPhoneError'));
                return;
            } else if (this.imgCode === '') {
                this.$toast(this.$t('computerlogin.alertNoGraphicCode'));
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.post('/v1/business-center/phone-verificationcode-business?businessphone=' + this.account + '&code=' + this.imgCode)
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountPhone.alertSendSuccessfully'));
                        this.step = 2;
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('AccountEmail.alertSendFail'));
                    }
                }).catch(err => {
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        InforNext() { // 下一步
            if (this.code.length === 0) {
                this.$toast.fail(this.$t('forgetPassword.alertNoCodeInput'));
                return;
            }
            const data = {
                contact: this.account,
                code: this.code
            }
            this.$http.post('/v1/register/user-update-password-authentication', data)
                .then(res => {
                    if (res.data.data === '用户验证成功') {
                        this.$toast.success(this.$t('AccountPhone.alertChangeSuccessfully'));
                        this.$store.dispatch("developer/fetchUserInfo");
                        this.closeModal();
                    } else {
                        this.$toast.fail(this.$t('AccountEmail.alertBindFail'));
                    }
                }).catch(err => {
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
    },
}
</script>

<style>

</style>