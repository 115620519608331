<template>
    <div class="pb-20 bg-gray-50">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileEmailSend.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <ShopCartInfor class="animate__animated animate__fadeInDown" :LandInfor="LandInfor" :showSelect="false" :show-delete="false" :index="0" ref="MobileLand" />
        <div class="rounded-lg shadow-lg bg-white pt-3 pb-5 mx-3 mt-3 animate__animated animate__fadeInUp">
            <van-field v-model="inputText" rows="15" autosize :label="$t('MobileEmailSend.message')" type="textarea" maxlength="1000" :placeholder="$t('MobileEmailSend.enter')" show-word-limit />
        </div>

        <div class="flex-shrink-0 fixed bottom-2 left-0 right-0 flex justify-center">
            <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="sendEmail">{{$t('MobileEmailSend.send')}}</button>
        </div>
    </div>
</template>

<script>
import ShopCartInfor from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        ShopCartInfor, // 挂牌土地信息组件
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.receiveId = this.$route.query.receive;
        this.saleid = this.$route.query.merch;
        if (this.$route.query.thread) {
            this.threadId = this.$route.query.thread;
        }
    },
    mounted() {
        // 获取土地详情信息
        this.$http
            .get("/v1/mail/select-onsale-by-saleid?saleid=" + this.saleid)
            .then((res) => {
                this.LandInfor = res.data.data;
                this.drawLand();
            })
            .catch((error) => {
                console.log(error);
            });
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            unitlandid: null, // 获取该商品详细信息
            receiveId: null, // 发送邮件时的接收方id
            threadId: null, // 主题id，有才用
            LandInfor: {
                originalLand: {
                    geographicalcoordinates: "",
                },
            },
            inputText: "", // 用户输入的内容
        };
    },
    methods: {
        handleKeyup() {
            const textarea = this.$refs.myInput;
            textarea.scrollTop = textarea.scrollHeight;
        },
        turnToDetail(val) {
            this.$router.push({
                path: "/mobile/land/detail?&merch=" + val.id,
            });
        },
        uploadImage() {},
        sendEmail() {
            // 发送信息
            const data = {
                saleid: this.LandInfor.id,
                emailscontent: this.inputText,
                threadid: this.threadId,
                landname: this.LandInfor.landname,
                recipientid: this.receiveId,
            };
            this.$http
                .post("/v1/mail/write-message", data, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    alert(res.data.data);
                    this.$router.go(-1);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background-color: #eeeeee;
    position: relative;
    overflow: hidden;
}
/* ---------------------------------顶部：返回与发送图标------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: sticky;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------第一格：土地商品列表信息------------------------------- */
.mobile-email-land {
    width: 100%;
    background-color: #eeeeee;
}
/* ---------------------------------第二格：用户输入聊天信息框------------------------------- */
.mobile-email-input {
    display: flex;
    height: calc(100% - 250px);
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    flex-direction: column;
    text-align: center;
}
.mobile-email-input-text {
    height: 60%;
    width: calc(100% - 6px);
    font-size: 20px;
    resize: none;
    overflow-y: auto;
}
/* ---------------------------------底部：上传图片------------------------------- */
.mobile-email-bottom {
    height: 70px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: absolute;
    bottom: 0;
}
.mobile-email-bottom-upload {
    height: 50px;
    width: 100px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 18px;
    font-weight: bold;
    background-color: #31e4ff;
    color: #ff1323;
}
.mobile-email-top-send {
    height: 50px;
    width: 80%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    border-radius: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    background-color: #fda1ff;
    color: #fff;
    font-weight: bold;
}
</style>
