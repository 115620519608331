<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>企业管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">新增企业</span>
        </div>
        <div class="admin-add-main">
            <div class="admin-manage-topic"><span>新增企业</span></div>
            <div class="admin-add-box" v-if="isSuccess">
                <div class="add-input-parent" v-show="phone === ''">
                    <div class="admin-add-input-label">
                        <img src="../adminImage/admin-add-star.png" class="admin-add-input-label-red">
                        企业邮箱账号:
                    </div>
                    <input type="text" class="add-input" v-model="email" placeholder="请输入邮箱号码">
                </div>
                <div class="add-input-parent" v-show="email === ''">
                    <div class="admin-add-input-label">
                        <img src="../adminImage/admin-add-star.png" class="admin-add-input-label-red">
                        企业联系人手机号码:
                    </div>
                    <input type="text" class="add-input" v-model="phone" placeholder="请输入手机号码">
                </div>
                <div class="admin-add-input-label">
                    <img src="../adminImage/admin-add-star.png" class="admin-add-input-label-red">
                    二者任选其一填写
                </div>
                <div>
                    <button class="admin-add-button" @click="showModal" @mouseover="changeCursor">
                        <img src="../adminImage/admin-add-add.png" class="admin-add-button-icon">
                        新增
                    </button>
                </div>
            </div>
            <div class="admin-add-box" v-else>
                <h2>新增成功，验证邮件已发至企业用户邮箱</h2>
                <h1>请提醒用户及时查看</h1>
                <h3 @click="goBack2" @mouseover="changeCursor" style="text-decoration: underline;">返回上一步</h3>
            </div>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="admin-apply-modal" v-if="vmodal">
            <div class="admin-apply-modal-box">
                <span style="padding:30px;">{{ modalText }}</span>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1" style="margin-right:20px;">取消</el-button>
                    <el-button type="primary" @click="add" class="admin-apply-button-1" v-if="!isSending">确定</el-button>
                    <el-button type="primary" class="admin-apply-button-1" style="background-color: #dcdcdc;" v-else>发送中</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            phone: '',
            isSuccess: true, // 点击新增弹框确定后显示给管理员的文本
            vmodal: false, // 显示弹窗
            isSending: false, // 点击弹窗确定按钮显示发送中...
            modalText: '', // 弹窗显示文本
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1);
        },
        showModal() {
            const re = /^[0-9]+$/
            if(this.email === '' && this.phone === '') {
                this.$toast.fail("请至少填写邮箱及手机号其中一种");
                return;
            }
            if (this.email !== '' && !this.email.includes('@')) {
                this.$toast.fail("邮箱格式错误");
                return;
            }
            if (this.phone !== '' && !re.test(this.phone)) {
                this.$toast.fail("手机号格式错误");
                return;
            }
            if(this.email !== '') {
                this.modalText = '即将向该邮箱账号发送验证邮件。';
            } else if (this.phone !== '') {
                this.modalText = '即将向该手机号发送验证短信。';
            }
            this.vmodal = true;
        },
        closeModal() {
            this.vmodal = false;
        },
        goBack2() {
            this.email = '';
            this.phone = '';
            this.isSuccess = true;
        },
        add() {
            let data = {}
            if(this.email !== '') {
                data = {
                    businessemail: this.email
                }
            } else if (this.phone !== '') {
                data = {
                    businessphone: this.phone
                }
            } else if (this.email !== '' && this.phone !== '') {
                data = {
                    businessemail: this.email,
                    businessphone: this.phone
                }
            } else {
                return;
            }
            this.isSending = true;
            this.$http.post('/v1/business/save-business-one', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$toast.success('已录入系统！');
                this.vmodal = false;
                this.isSuccess = false;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail('录入系统失败！请重试或重新登录');
                this.isSending = false;
            });
        }
    },
}
</script>

<style scoped>
.admin-add-main {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    flex: 1;
}
.admin-add-box {
    flex: 1;
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-direction: column;
}
.add-input-parent {
    display: flex;
    flex-direction: row;
    padding: 20px;
    font-size: 14px;
}
.admin-add-input-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.admin-add-input-label-red {
    height: 8px;
    width: 8px;
    margin-right: 5px;
}
.add-input {
    margin-left: 10px;
    color: #000;
    width: 366px;
    height: 32px;
    border-radius: 2px;
    background: #F2F3F5;
}
.admin-add-button {
    margin-top: 20px;
    width: 130px;
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #165DFF;
    color: #fff;
    border-radius: 6px;
}
.admin-add-button-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
}
</style>