<template>
    <div class="loadbar">
        <canvas ref="loadbar" :height="canvasheight" :width="canvaswidth" class="loadbar-position"></canvas>
    </div>
</template>

<script>
export default {
    props: { // 画布尺寸
        step: { // 加载节点，整数，比如十个节点就是最大10
            type: Number,
            required: true,
            default: 0,
        },
        stepcount: { // 加载节点总数
            type: Number,
            default: 10,
        },
    },
    watch: {
        step(n) {
            if (this.flag) {
                this.stepChangeWhenFlagIsFalse = false;
                this.loadImg(n);
            } else { // 在进度条绘制中时step的值变化
                this.stepChangeWhenFlagIsFalse = true;
            }
        },
        flag(n) {
            if (n && this.stepChangeWhenFlagIsFalse) {
                this.stepChangeWhenFlagIsFalse = false;
                this.loadImg(this.step);
            }
        },
    },
    mounted() {
        this.img = new Image();
        this.img.src = this.loadingbar;
        this.img.onload = () => {
            this.imgWidth = this.img.width;
            this.imgHeight = this.img.height;
            this.scaleImageCanvaswidth = this.canvaswidth / this.imgWidth;
        };
    },
    data () {
        return {
            flag: true, // 是否处于可绘制状态
            stepChangeWhenFlagIsFalse: false, // 当前处于不可绘制状态（绘制中状态）是step值增加，该参数变为true，此时在下一次flag恢复true时立即绘制
            laststep: 0, // 上一次绘制时step的值
            scaleImageCanvaswidth: 0, // 图片宽度与画布宽度的比例
            canvasheight: 30, // 画布高度
            canvaswidth: 400, // 画布宽度
            loadingbar: require('./v2Image/loading.jpg'),
            img: null,
            imgWidth: 0, // 进度条图片宽度
            imgHeight: 0, // 进度条图片高度
        }
    },
    methods: {
        loadImg(nowstep) {
            const canvas = this.$refs.loadbar;
            const ctx = canvas.getContext('2d');
            this.flag = false;
            const loadlength = this.imgWidth * (nowstep - this.laststep) / this.stepcount / 10; // 每一回合裁剪图片需要增加的宽度
            const showWidth = loadlength * this.scaleImageCanvaswidth; // 每一回合显示图片需要增加的宽度
            let drawX = this.laststep / this.stepcount * this.imgWidth; // 初始裁剪图片的x轴位置
            let showX = this.laststep / this.stepcount * this.canvaswidth; // 初始显示图片的x轴位置

            const interval = setInterval(() => {
                //            图片 裁剪坐标x 裁剪坐标y 裁剪宽度 裁剪高度 显示坐标x 显示坐标y 显示宽度 显示高度
                ctx.drawImage(this.img, drawX, 0, loadlength, this.imgHeight, showX, 0, showWidth, this.canvasheight);
                drawX += loadlength;
                showX += showWidth;
            }, 100);

            setTimeout(() => {
                clearInterval(interval); // 清除定时器
                this.laststep = nowstep;
                this.flag = true;
            }, 1000); // 设置定时器执行时间为1000毫秒
        },
    },
}
</script>

<style scoped>
.loadbar {
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-image: url('./v2Image/猫猫歪头.webp');
}
.loadbar-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-image: url('./loading.jpg'); */
}
</style>