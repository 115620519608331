<template>
    <div class="developer-common-safe">
        <div class="developer-common-safe-title">{{$t('AccountHelp.title')}}</div>
        <div class="qa-lists" v-for="(i, index) in item" :key="index">
            <h1 class="mobile-help-collapse-topic">{{$t('AccountHelp.t'+(index+1))}}</h1>
            <div class="qa-list" v-for="j in i" :key="j">
                <img src="../developer/service-icon.png" style="height:28px;width:28px;">
                <div class="qa-list-box">
                    <h4 class="font-bold">· {{$t('AccountHelp.q'+(index+1)+'-'+j)}}</h4>
                    <span style="white-space:break-spaces;">{{$t('AccountHelp.a'+(index+1)+'-'+j)}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: [6,2,2,4,6,1,3,4,1,2,3,5], // 循环次数，12个大标题，每个标题n个问题
        }
    },
    methods: {
        
    },
}
</script>

<style scoped>
.qa-lists {
    width: 60%;
    display: flex;
    flex-direction: column;
}
.qa-list {
    margin-top: 28px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.qa-list-box {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #E5E6E8;
    margin-left: 16px;
    padding-bottom: 28px;
}
</style>