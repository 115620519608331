<template>
    <div class="company-register-background">
        <div class="company-register-box" ref="mainContainer">
            <!-- 步骤条1 -->
            <div class="company-register-steps" v-if="active <= 4">
                <div class="company-register-steps-item" style="width:25%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(1)">
                            <img src="../developer/step-accept.png" v-if="active > 1">
                            <span v-else>{{ stepNumber(1) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step1')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step1t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:25%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(2)">
                            <img src="../developer/step-accept.png" v-if="active > 2">
                            <span v-else>{{ stepNumber(2) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step2')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step2t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:25%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(3)">
                            <img src="../developer/step-accept.png" v-if="active > 3">
                            <span v-else>{{ stepNumber(3) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step3')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step3t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:25%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(4)">
                            <img src="../developer/step-accept.png" v-if="active > 4">
                            <span v-else>{{ stepNumber(4) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step4')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step4t')}}</span>
                    </div>
                </div>
            </div>
            <!-- 步骤条2 -->
            <div class="company-register-steps" v-else>
                <div class="company-register-steps-item" style="width:100%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(5)">
                            <img src="../developer/step-accept.png" v-if="active > 5">
                            <span v-else>{{ stepNumber(5) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step5')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step5t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:100%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(6)">
                            <img src="../developer/step-accept.png" v-if="active > 6">
                            <span v-else>{{ stepNumber(6) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step3')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step3t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:100%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(7)">
                            <img src="../developer/step-accept.png" v-if="active > 7">
                            <span v-else>{{ stepNumber(7) }}</span>
                        </div>
                        <span class="company-register-step-title">{{$t('CompanyRegister.step4')}}</span>
                        <span class="company-register-step-subhead">{{$t('CompanyRegister.step4t')}}</span>
                    </div>
                </div>
            </div>


            <!-- 修改密码输入表单 -->
            <div class="company-register-form-box" v-if="active === 1">
                <div class="company-register-input-parent" style="width:800px;">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyRegister.oldpassword')}}:
                    </div>
                    <div class="company-register-input-right">
                        <input :type="showPassword1 ? '' : 'password'" class="company-register-input" :placeholder="$t('CompanyRegister.enteroldpassword')" v-model="changePassword1">
                        <el-tooltip class="item" effect="dark" :content="$t('computerlogin.showOrHidePassword')" placement="top-end"  slot="append">
                            <img src="../adminImage/admin-login-show.png" class="company-register-input-icon" v-if="showPassword1" @click="showPassword1=false">
                            <img src="../adminImage/admin-login-hide.png" class="company-register-input-icon" v-else @click="showPassword1=true">
                        </el-tooltip>
                    </div>
                </div>
                <div class="company-register-input-parent" style="width:800px;">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyRegister.newpassword')}}:
                    </div>
                    <div class="company-register-input-right">
                        <input :type="showPassword2 ? '' : 'password'" class="company-register-input" :placeholder="$t('CompanyRegister.enternewpassword')" v-model="changePassword2" @input="checkPassword">
                        <el-tooltip class="item" effect="dark" :content="$t('computerlogin.showOrHidePassword')" placement="top-end"  slot="append">
                            <img src="../adminImage/admin-login-show.png" class="company-register-input-icon" v-if="showPassword2" @click="showPassword2=false">
                            <img src="../adminImage/admin-login-hide.png" class="company-register-input-icon" v-else @click="showPassword2=true">
                        </el-tooltip>
                    </div>
                </div>
                <div class="flex flex-col" style="min-width:400px;">
                    <div class="flex flex-row items-center">
                        <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules1">
                        <img src="../developer/safe-access.png" class="infor-icon" v-else>
                        <span class="developer-account-input-tips" :style="showRules1?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip1')}}</span>
                    </div>
                    <div class="flex flex-row items-center">
                        <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules2">
                        <img src="../developer/safe-access.png" class="infor-icon" v-else>
                        <span class="developer-account-input-tips" :style="showRules2?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip2')}}</span>
                    </div>
                    <div class="flex flex-row items-center">
                        <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules3">
                        <img src="../developer/safe-access.png" class="infor-icon" v-else>
                        <span class="developer-account-input-tips" :style="showRules3?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip3')}}</span>
                    </div>
                </div>
                <el-button type="primary" class="developer-common-button" style="margin-top:20px;" @click="showModal">{{$t('CompanyRegister.next')}}</el-button>
                <!-- 二次确认弹窗，根据 vmodal 变量决定 -->
                <div class="developer-common-modal" v-if="vmodal" @click="closeModal">
                    <div class="developer-common-modal-box" v-if="!showError">
                        <span>{{$t('forgetPassword.resureChange')}}</span>
                        <div class="w-full flex flex-row justify-center" style="margin-top: 50px;">
                            <el-button type="infor" class="developer-common-button" @click="closeModal">{{$t('forgetPassword.no')}}</el-button>
                            <el-button type="primary" class="developer-common-button" @click="add" style="margin-left: 30px;">{{$t('forgetPassword.yes')}}</el-button>
                        </div>
                    </div>
                    <div class="developer-common-modal-box" v-if="showError">
                        <div>{{$t('forgetPassword.alertPasswordError')}}</div>
                    </div>
                </div>
            </div>


            <!-- 上传企业信息表单 -->
            <div class="company-register-form-box" v-else-if="active === 2">
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.companyName')}}:
                    </div>
                    <div class="company-register-input-right">
                        <input v-model="business.corporationname" class="company-register-input" :placeholder="$t('CompanyInfor.companyName1')">
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.corporationname">
                </div>
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.ownershipCertificate')}}:
                    </div>
                    <div class="company-register-input-right">
                        <input v-model="business.housingownership" class="company-register-input" :placeholder="$t('CompanyInfor.ownershipCertificate1')">
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.housingownership">
                </div>
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.companyContact')}}:
                    </div>
                    <div class="company-register-input-right">
                        <input v-model="business.businessname" class="company-register-input" :placeholder="$t('CompanyInfor.companyContact1')">
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.businessname">
                </div>
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.companyContactPhone')}}:
                    </div>
                    <div class="company-register-input-right relative">
                        <el-dropdown @command="handleCountry" class="company-register-input-select">
                            <span>
                                {{ business.countrycode }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="+86">{{ $t('all.86') }}</el-dropdown-item>
                            <el-dropdown-item command="+852">{{ $t('all.852') }}</el-dropdown-item>
                            <el-dropdown-item command="+853">{{ $t('all.853') }}</el-dropdown-item>
                            <el-dropdown-item command="+886">{{ $t('all.886') }}</el-dropdown-item>
                            <el-dropdown-item command="+1">{{ $t('all.1') }}</el-dropdown-item>
                            <el-dropdown-item command="+81">{{ $t('all.81') }}</el-dropdown-item>
                            <el-dropdown-item command="+44">{{ $t('all.44') }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <input v-model="business.businessphone" class="company-register-input absolute" style="padding-left:80px;" :placeholder="$t('CompanyInfor.companyContactPhone1')">
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.businessphone">
                </div>
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.idImgInput')}}:
                    </div>
                    <div class="company-register-input-right">
                        <!-- 身份证正面照 -->
                        <div class="ml-5">
                            <img :src="business.businesscard1" v-if="haveFrontIdImg" class="company-register-input-img" @click="handleClick(1)">
                            <div class="company-register-input-img" @click="handleClick(1)" v-else>
                                <span class="company-register-input-img-icon">+</span>
                                <span class="company-register-input-img-span">{{$t('CompanyInfor.frontIdImgInput')}}</span>
                            </div>
                            <input type="file" ref="frontIdImgInput" @change="handleFileInputChange('frontIdImgInput')" hidden>
                        </div>
                        <!-- 身份证背面照 -->
                        <div class="ml-5">
                            <img :src="business.businesscard2" v-if="haveReverseIdImg" class="company-register-input-img" @click="handleClick(2)">
                            <div class="company-register-input-img" @click="handleClick(2)" v-else>
                                <span class="company-register-input-img-icon">+</span>
                                <span class="company-register-input-img-span">{{$t('CompanyInfor.reverseIdImgInput')}}</span>
                            </div>
                            <input type="file" ref="reverseIdImgInput" @change="handleFileInputChange('reverseIdImgInput')" hidden>
                        </div>
                        <div class="company-register-input-tip ml-3">※{{$t('CompanyInfor.uploadImg')}}</div>
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.IdCard">
                </div>
                <!-- 公司注册信息与注册证明 -->
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.CPRTInput')}}:
                    </div>
                    <div class="company-register-input-right ml-3">
                        <a class="company-register-input-link" :href="business.companyregistration" target="_blank" v-if="business.companyregistration">{{$t('CompanyInfor.download')}}</a>
                        <a class="company-register-input-link" @click="handleClick(3)" target="_blank">{{$t('CompanyInfor.uploadpdf')}}</a>
                        <input type="file" ref="CPRTInput" @change="handleFileInputChange('CPRTInput')" hidden>
                        <span class="company-register-input-tip" v-if="business.companyregistration">※{{$t('LandUpload.tip1')}}</span>
                        <span class="company-register-input-tip" v-else>※{{$t('CompanyInfor.uploadPDF')}}</span>
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.companyregistration">
                </div>
                <!-- 平台合同扫描件 -->
                <div class="company-register-input-parent">
                    <div class="company-register-input-label">
                        <span style="color:red;margin-right: 5px;">*</span>
                        {{$t('CompanyInfor.PFCTInput')}}:
                    </div>
                    <div class="company-register-input-right ml-3">
                        <a class="company-register-input-link" :href="business.platformcontract" target="_blank" v-if="business.platformcontract">{{$t('CompanyInfor.download')}}</a>
                        <a class="company-register-input-link" @click="handleClick(4)" target="_blank">{{$t('CompanyInfor.uploadpdf')}}</a>
                        <input type="file" ref="PFCTInput" @change="handleFileInputChange('PFCTInput')" hidden>
                        <span class="company-register-input-tip" v-if="business.platformcontract">※{{$t('LandUpload.tip1')}}</span>
                        <span class="company-register-input-tip" v-else>※{{$t('CompanyInfor.uploadPDF')}}</span>
                    </div>
                    <img src="../adminImage/admin-company-attention.png" class="company-register-form-item-select" v-if="select.platformcontract">
                </div>
                <!-- 驳回原因 -->
                <div class="company-register-input-parent" style="color:red;" v-if="business.verificationstatus === '已驳回'">
                    <div class="company-register-input-label">
                        *{{$t('CompanyInfor.remark')}}:
                    </div>
                    <div class="company-register-input-right">
                        <span class="company-register-remark">{{ business.remark }}</span>
                    </div>
                </div>
                <el-button type="primary" class="developer-common-button" @click="bmodal=true">{{$t('CompanyRegister.next')}}</el-button>
                <!-- 二次确认弹窗，根据 bmodal 变量决定 -->
                <div class="developer-common-modal" v-if="bmodal">
                    <div class="developer-common-modal-box">
                        <span style="font-size:16px;text-align:center;">{{$t('CompanyInfor.tip1')}}</span>
                        <span class="mt-3" style="font-size:16px;text-align:center;">{{$t('CompanyInfor.tip2')}}</span>
                        <span class="mt-5">{{$t('CompanyInfor.tip3')}}</span>
                        <div class="flex flex-row mt-5 w-full">
                            <el-button type="infor" class="developer-common-button" @click="bmodal=false">{{$t('CompanyInfor.no')}}</el-button>
                            <el-button type="primary" class="developer-common-button" @click="upload">{{$t('CompanyInfor.yes')}}</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 等待审核提示页 -->
            <div class="company-register-form-box" style="flex-direction: initial;" v-else-if="active === 3">
                <div class="flex flex-col justify-center items-center" v-if="uploadSuccess">
                    <img src="../developer/developer-upload-seccess.png" style="height:48px;width:48px;">
                    <span class="mt-3">{{$t('CompanyRegister.head')}}</span>
                    <span class="company-register-input-img-span">{{$t('CompanyRegister.subhead')}}</span>
                    <el-button type="primary" class="developer-common-button" style="margin-top:15px;" @click="$router.go(-1)">{{$t('CompanyInfor.yes')}}</el-button>
                </div>
                <span class="big-text" v-else>{{$t('CompanyRegister.tip1')}}</span>
            </div>

            <!-- 审核驳回提示页 -->
            <div class="company-register-form-box" v-else-if="active === 4">
                <span class="big-text">{{$t('CompanyRegister.tip2')}}</span>
                <span class="mt-5 add-refund-button" @mouseover="changeCursor" @click="reUpload">{{$t('CompanyRegister.reUpload')}}</span>
            </div>


            <!-- 第一次上传土地凭证 -->
            <div class="company-register-form-box" style="overflow-y:hidden" v-else-if="active === 5">
                <LandUpload :LandInfor='LandInfor' style="width:100%;padding-top:300px;"></LandUpload>
            </div>

            <!-- 第一次上传土地凭证待审核 -->
            <div class="company-register-form-box" style="overflow-y:hidden" v-else-if="active === 6">
                <div class="flex flex-col justify-center items-center" v-if="uploadSuccess">
                    <img src="../developer/developer-upload-seccess.png" style="height:48px;width:48px;">
                    <span class="mt-3">{{$t('CompanyRegister.head')}}</span>
                    <span class="company-register-input-img-span">{{$t('CompanyRegister.subhead')}}</span>
                    <el-button type="primary" class="developer-common-button" style="margin-top:15px;" @click="$router.go(-1)">{{$t('CompanyInfor.yes')}}</el-button>
                </div>
                <span class="big-text" v-else>{{$t('CompanyRegister.tip1')}}</span>
            </div>

            <!-- 第一次上传土地凭证被驳回 -->
            <div class="company-register-form-box" style="overflow-y:hidden" v-else-if="active === 7">
                <span class="big-text">{{$t('CompanyRegister.tip3')}}</span>
                <span class="mt-5 add-refund-button" @mouseover="changeCursor" @click="reUploadLand">{{$t('CompanyRegister.reUpload')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import LandUpload from './LandUpload.vue';
import LandReview from './LandReview.vue';
export default {
    components: {
        LandUpload, // 上传土地信息
        LandReview, // 查看土地信息
    },
    computed: {
        ...mapState({
            businessid: (state) => state.developer.businessid,
        }),
    },
    mounted() {
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
        /* this.$http.get('/v1/index/get-verification-status')
        .then(res => {
            this.statu = res.data.data;

            if(this.statu === '未重置密码') {
                this.active = 1;
            } else if (this.statu === '未身份认证') {
                this.active = 2;
            } else if (this.statu === '未认证') {
                this.active = 3;
            } else if (this.statu === '已驳回') {
                this.active = 4;
                this.$http.get('/v1/business/select-mycompany')
                    .then(res => {
                        this.business = res.data.data;
                        this.haveFrontIdImg = true;
                        this.haveReverseIdImg = true;
                        const infor = JSON.parse(res.data.data.informationDetection)
                        if (infor[0]) { this.select.corporationname = true; }
                        if (infor[1]) { this.select.housingownership = true; }
                        if (infor[2]) { this.select.businessname = true; }
                        if (infor[3]) { this.select.businessphone = true; }
                        if (infor[4]) { this.select.IdCard = true; }
                        if (infor[5]) { this.select.companyregistration = true; }
                        if (infor[6]) { this.select.platformcontract = true; }
                    })
                    .catch(error => {
                        // 处理错误
                        this.$toast.fail('404 NOT FOUND')
                    });
            } else if (this.statu === '已认证') {
                this.$http.get('/v1/business/select-business-upload-first-land')
                    .then(res => {
                        const data = res.data.data;
                        if (data && data.landstatus === '已上线') {
                            this.$router.push('/developer');
                        } else if (data && data.landstatus === '已驳回') {
                            this.LandInfor = res.data.data;
                            this.active = 7;
                        } else if (data && data.landstatus === '待审核') {
                            this.LandInfor = res.data.data;
                            this.active = 6;
                        } else {
                            this.active = 5;
                        }
                    })
                    .catch(error => {
                        // 处理错误
                        this.$toast.fail('404 NOT FOUND')
                    });
            }
        }).catch(error => {}); */
    },
    beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.updateModalScale);
    },
    data() {
        return {
            statu: null, // 企业认证到达的步骤状态
            active: 2,
            landid: null, // 合并后第一次上传土地的原始土地id，有才获取凭证信息


            changePassword1: '',
            showPassword1: false,
            changePassword2: '',
            showPassword2: false,
            showRules1: true, // 三个显示新密码规则是否成功的参数
            showRules2: true,
            showRules3: true,
            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            loading: false, // 修改密码时有一段请求数据库的时间，此时禁止操作

            
            business: {
                businessid: '', // 企业编号
                corporationname: '', // 企业名称
                housingownership: '', // 房屋所有权证编号
                businessname: '', // 企业联络人姓名
                businessphone: '', // 企业联系电话
                businesscard1: require('../assets/no-image.png'), // 身份证正面照
                businesscard2: require('../assets/no-image.png'), // 身份证反面照
                companyregistration: null, // 公司注册信息与注册证明
                platformcontract: null, // 平台合同扫描件
                countrycode: '+86', // 手机国际号
                verificationstatus: '', // 状态
                remark: '', // 驳回原因
            },
            select: { // 已驳回时从后端提取出有问题的字段
                corporationname: false,
                housingownership: false,
                businessname: false,
                businessphone: false,
                IdCard: false,
                companyregistration: false,
                platformcontract: false,
            },
            frontIdImg: null, // 存储身份证正面照的文件类型参数，这个是用来向后端上传的，上面那块的是url
            reverseIdImg: null, // 存储身份证背面照的文件类型参数
            companyregistration: null, // 公司注册信息及注册证明
            platformcontract: null, // 平台合同扫描件
            haveFrontIdImg: false, // 已有身份证正面照
            haveReverseIdImg: false, // 已有身份证背面照
            bmodal: false,

            uploadSuccess: false, // 企业信息上传审核后需要一个提交完成的页面

            LandInfor: { // 土地凭证信息，后端获取
                ownershipnumber: '', // 土地产权编号
                originallandname: '', // 土地名称
                createdate: '', // 创建时间
                detailedaddress: '', // 详细地址
                geographicalcoordinates: '', // 地理坐标
                entrance: '东', // 土地入口方位
                landtype: '不限', // 土地类型
                landlength: '', // 土地长度
                landwidth: '', // 土地宽度
                height: '', // 海拔
                landcertificate: null, // 土地无贷款证明
                warrantydeed: null, // 担保地契
                landpicture: null, // 土地照片
                
                
                
                userBusiness: {
                    businessid: '',                    // 企业id
                    corporationname: '',               // 公司名称
                    housingownership: '',              // 房屋所有权证编号
                    businessname: '',                  // 企业负责人姓名
                    businessphone: '',                 // 企业负责人联系电话
                    createdate: '',                    // 企业信息认证成功时间
                },
                originalLand: {
                    originallandid: '',                // 土地id
                    originallandname: '',              // 土地名称
                    detailedaddress: '',               // 土地详细地址
                    landtype: '',                      // 土地类型
                    geographicalcoordinates: '',       // 土地经纬度
                    landlength: '',                    // 土地长度
                    landwidth: '',                     // 土地宽度
                    height: '',                        // 土地海拔
                    createdate: '',                    // 土地信息上传时间
                }
            },
        }
    },
    methods: {
        // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal = this.$refs.mainContainer;
            if (window.innerWidth >= 1400 && window.innerHeight >= 900) {
                modal.style.transform = `scale(1)`;
            } else {
                const scaleX = (window.innerWidth - 100) / 1300;
                const scaleY = (window.innerHeight - 100) / 800;
                const scale = Math.min(scaleX,scaleY);
                modal.style.transform = `scale(${scale})`;
            }
        },
        goBack() {
            this.$router.push('/developer/home-page');
        },
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        stepClass(n) { // 更换步骤条圆圈颜色
            if (this.active > n) { // 已完成变绿色
                return 'company-register-step-number-finish'
            } else if (this.active === n) { // 进行中蓝色
                return 'company-register-step-number-doing'
            } else { // 等待中不变色
                return ''
            }
        },
        stepNumber(n) { // 步骤条圆圈内数字内容
            if (this.active > n) { // 已完成对勾
                return '√'
            } else { // 未完成数字
                return n.toString()
            }
        },


        /* ------------------------------------------------步骤一：修改密码------------------------------------------------- */
        togglePassword(n) {
            if(n) {
                this.showPassword2 = !this.showPassword2
            } else {
                this.showPassword1 = !this.showPassword1
            }
        },
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            // 原正则表达式：/^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/
            const re1 = /^.{8,16}$/;
            const re21 = /^(?=.*[A-Za-z])(?=.*\d).{2,}$/; // 包含字母和数字
            const re22 = /^(?=.*[A-Za-z])(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含字母和符号
            const re23 = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字和符号
            const re24 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字、字母和符号
            const re3 = /\s/; // 包含空格
            this.showRules1 = !re1.test(this.changePassword2);
            if (re21.test(this.changePassword2) || re22.test(this.changePassword2) || re23.test(this.changePassword2) || re24.test(this.changePassword2)) {
                this.showRules2 = false;
            } else {
                this.showRules2 = true;
            }
            this.showRules3 = re3.test(this.changePassword2);
        },
        showModal() {
            const re = /^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/;
            if (re.test(this.changePassword2)) {
                // 合法操作
                this.showError = false;
            } else {
                // 非法操作
                this.showError = true;
            }
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        add() {
            const data = {
                oldpassword: this.changePassword1,
                newpassword: this.changePassword2
            }
            this.$http.put('/v1/index/reset-userbusiness-password',data)
            .then(res => {
                if(res.data.data === '密码重置成功') {
                    this.active++;
                } else {
                    this.$toast.fail(this.$t('all.modifyFail'))
                }
            })
            .catch(error => {
                // 处理错误
                this.$toast(this.$t('CompanyRegister.alertError'))
            });
        },


        /* ------------------------------------------------步骤二：上传企业信息------------------------------------------------- */
        /* 选择手机号国籍 */
        handleCountry(v) {
            this.business.countrycode = v;
        },
        /* 上传图片及pdf文件 */
        handleClick(n) {
            // 由于文件选择器被隐藏，因此只能设置该函数来执行文件选择器，点击时执行
            if(n===1) { // 1.身份证正面照 2.身份证背面照 3.公司注册信息与注册证明 4.平台合同扫描件
                this.$refs.frontIdImgInput.click();
            }else if(n===2) {
                this.$refs.reverseIdImgInput.click();
            }else if(n===3) {
                this.$refs.CPRTInput.click();
            }else if(n===4) {
                this.$refs.PFCTInput.click();
            }
        },
        handleFileInputChange(ref) { // 获取用户选择的文件
            const maxSize = 3 * 1024 * 1024; // 3 MB
            if(ref === 'frontIdImgInput') { // 获取身份证正面照并显示照片
                // 获取用户选择的文件对象
                this.frontIdImg = this.$refs.frontIdImgInput.files[0];
                if (this.frontIdImg.type !== 'image/png' && this.frontIdImg.type !== 'image/jpg') { // 上传文件格式错误，提示上传失败
                    this.frontIdImg = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadImg'));
                    return;
                } else if (this.frontIdImg.size >= maxSize) {
                    this.frontIdImg = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadImg'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.frontIdImg);
                // 更新当前文件 URL
                this.business.businesscard1 = url;
                // 把原图清除换为用户上传的图片
                this.haveFrontIdImg = true;
            } else if (ref === 'reverseIdImgInput') { // 获取身份证背面照并显示照片
                // 获取用户选择的文件对象
                this.reverseIdImg = this.$refs.reverseIdImgInput.files[0];
                if (this.reverseIdImg.type !== 'image/png' && this.reverseIdImg.type !== 'image/jpg') { // 上传文件格式错误，提示上传失败
                    this.reverseIdImg = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadImg'));
                    return;
                } else if (this.reverseIdImg.size >= maxSize) {
                    this.reverseIdImg = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadImg'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.reverseIdImg);
                // 更新当前头像 URL
                this.business.businesscard2 = url;
                // 把原图清除换为用户上传的图片
                this.haveReverseIdImg = true;
            } else if (ref === 'CPRTInput') { // 获取公司注册信息及注册证明
                // 获取用户选择的文件对象
                this.companyregistration = this.$refs.CPRTInput.files[0];
                if (this.companyregistration.type !== 'application/pdf') {
                    this.companyregistration = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                } else if (this.companyregistration.size >= maxSize) {
                    this.companyregistration = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.companyregistration);
                // 更新当前文件 URL
                this.business.companyregistration = url;
            } else if (ref === 'PFCTInput') { // 获取平台合同扫面件
                // 获取用户选择的文件对象
                this.platformcontract = this.$refs.PFCTInput.files[0];
                if (this.platformcontract.type !== 'application/pdf') {
                    this.platformcontract = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                } else if (this.platformcontract.size >= maxSize) {
                    this.platformcontract = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.platformcontract);
                // 更新当前文件 URL
                this.business.platformcontract = url;
            } else {
                return
            }
        },
        /* 提交按钮事件 */
        upload() {
            const maxSize = 3 * 1024 * 1024; // 3 MB
            if (this.business.corporationname === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoCompanyName'));
                return;
            } else if (this.business.housingownership === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoOnership'));
                return;
            } else if (this.business.businessname === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoContact'));
                return;
            } else if (this.business.businessphone === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoContactPhone'));
                return;
            } else if (this.frontIdImg === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoFrontid'));
                return;
            } else if (this.reverseIdImg === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoDeverseid'));
                return;
            } else if (this.companyregistration === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoCPRT'));
                return;
            } else if (this.platformcontract === '') {
                this.$toast.fail(this.$t('CompanyInfor.alertNoPFCT'));
                return;
            }
            if (!this.frontIdImg && this.haveFrontIdImg) {
                this.frontIdImg = this.business.businesscard1;
            }
            if (!this.reverseIdImg && this.haveReverseIdImg) {
                this.reverseIdImg = this.business.businesscard2;
            }
            if (!this.companyregistration && this.business.companyregistration) {
                this.companyregistration = this.business.companyregistration;
            }
            if (!this.platformcontract && this.business.platformcontract) {
                this.platformcontract = this.business.platformcontract;
            }
            if (this.frontIdImg.size >= maxSize) {
                this.$toast.fail(this.$t('CompanyInfor.alertFrontError'));
                return;
            } else if (this.reverseIdImg.size >= maxSize) {
                this.$toast.fail(this.$t('CompanyInfor.alertDeverseError'));
                return;
            } else if (this.companyregistration.size >= maxSize) {
                this.$toast.fail(this.$t('CompanyInfor.alertCPRTError'));
                return;
            } else if (this.platformcontract.size >= maxSize) {
                this.$toast.fail(this.$t('CompanyInfor.alertPFCTError'));
                return;
            }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('CompanyRegister.alertuploading'),
                forbidClick: true,
            });
            // 将文件转化为 FormData todo注意：如果后续需要加入上次上传的企业信息，那么图片及pdf文件是有可能未更新的，虽然依然是选择这些参数上传，但后端需要知道有的参数不更改
            const formData = new FormData();
            formData.append('businessname', this.business.businessname);
            formData.append('businessphone', this.business.businessphone);
            formData.append('corporationname', this.business.corporationname);
            formData.append('housingownership', this.business.housingownership);
            formData.append('businesscard1', this.frontIdImg);
            formData.append('businesscard2', this.reverseIdImg);
            formData.append('companyregistration', this.companyregistration);
            formData.append('platformcontract', this.platformcontract);
            formData.append('countrycode', this.business.countrycode);
            
      
            // 发送 Ajax 请求 TODO 接口未设置
            this.$http.post('/v1/business/personal-information', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(response => {
                toastLoading.clear();
                this.uploadSuccess = true;
                this.active++;
            }).catch(error => {
                toastLoading.clear();
                this.$toast.fail(this.$t('CompanyInfor.alertSendFail'));
            });
        },
        
        
        /* ------------------------------------------------步骤四：被驳回后重新上传企业信息------------------------------------------------- */
        reUpload() {
            this.active = 2;
        },

        /* ------------------------------------------------步骤五：上传土地后进入待审核提示界面------------------------------------------------- */
        upLoadLandSuccess() {
            this.uploadSuccess = true;
            this.active++;
        },
        
        /* ------------------------------------------------步骤六：被驳回后重新上传土地信息------------------------------------------------- */
        reUploadLand() {
            this.active = 5;
        },
    },
}
</script>

<style>
.company-register-background {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* 添加滚动条，超出屏幕高度时可以滚动 */
    background-image: url('../developer/background.png');
    background-size: cover;
}
.company-register-box {
    height: 800px;
    max-height: 80%;
    width: 1300px;
    max-width: 80%;
    padding: 60px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
}
@media (max-width: 1400px) or (max-height: 900px) {
    .company-register-box {
        max-height: none;
        max-width: none;
    }
}
/* -----------------------------------------步骤进度条样式------------------------------------------ */
.company-register-steps {
    width: 80%;
    display: flex;
    flex-direction: row;
}
.company-register-steps-item {
    /* 注意：本样式是上下两个样式的间隔层，需要配合width使用，有几个步骤就是几分之一的宽度 */
    display: flex;
    justify-content: center;
}
.company-register-step {
    width: 300px;
    height: 80px;
    position: relative;
}
.company-register-step-number {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #F2F3F5;
    position: absolute;
    top: 0;
    left: 0;
}
.company-register-step-number-doing {
    background-color: #165DFF;
}
.company-register-step-number-finish {
    background-color: #16ff39;
}
.company-register-step-title { /* 标题 */
    font-size: 16px;
    position: absolute;
    left: 40px;
    top: 0;
}
.company-register-step-subhead { /* 副标题 */
    position: absolute;
    left: 40px;
    top: 30px;
    color: #86909C;
    font-size: 12px;
}
/* -----------------------------------------表单样式------------------------------------------ */
.company-register-form-box {
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0px;
    overflow-y: auto;
}
/* -------------------------输入框样式------------------------- */
.company-register-input-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
}
.company-register-input-label {
    font-size: 18px;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    text-align: center;
}
.company-register-input-right {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}
.company-register-input {
    margin-left: 10px;
    height: 36px;
    width: calc(100% - 20px);
    max-width: 500px;
    background-color: #F1F4F8;
    border: 1px solid #165DFF;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 16px;
}
.company-register-input-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    height: 24px;
    width: 24px;
    cursor: pointer;
}
.company-register-input-tip {
    margin-left: 10px;
    font-size: 14px;
    color: red;
}
.company-register-form-item-select {
    position: absolute;
    right: 0;
    height: 24px;
    top: calc(50% - 10px);
}
.company-register-input-select {
    width: 100px;
    text-align: center;
    z-index: 1;
}
.company-register-remark {
    width: 500px;
    word-wrap: break-word;
}
/* -------------------------图片输入框样式------------------------- */
.company-register-input-img {
    height: 130px;
    width: 200px;
    border-radius: 6px;
    background-color: #F1F4F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.company-register-input-img-icon {
    font-size: 20px;
}
.company-register-input-img-span {
    font-size: 16px;
    color: #86909C;
    text-align: center;
}
/* -------------------------pdf链接样式------------------------- */
.company-register-input-link {
    color: #476DFF;
    text-decoration: underline;
    font-size: 20px;
    min-width: 100px;
    cursor: pointer;
}





.big-text {
    font-size: 20px;
    font-weight: bold;
}
/* 被驳回后重新上传的文本按钮 */
.add-refund-button {
    text-decoration: underline;
    color: #3662EC;
    font-size: 20px;
}
</style>