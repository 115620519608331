<template>
    <div class="full-container">
        <div class="box" ref="financeMenuBox" style="height:100%">
            <img src="../icons/logo.png" class="logo">

            <!-- 菜单栏 -->
            <el-menu
                :default-active="activeIndex"
                class="el-menu-vertical-demo"
                router>
                <el-submenu index="1">
                    <template #title>
                        <i class="el-icon-map-location"></i>
                        <span>{{$t('MobileMyLand.title')}}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="/user/property/home">{{$t('PcFinanceMenu.finance')}}</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                
                <el-menu-item index="2" disabled>
                    <i class="el-icon-box"></i>
                    <span>{{$t('PcFinanceMenu.mywupin')}}</span>
                </el-menu-item>
                <el-menu-item index="3" disabled>
                    <i class="el-icon-office-building"></i>
                    <span>{{$t('PcFinanceMenu.mybuilding')}}</span>
                </el-menu-item>
            </el-menu>
        </div>

        <div class="right-box" style="width: 1000px;" ref="rightBox">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        activeIndex(n) {
            localStorage.setItem('user-fmenu',n);
        },
    },
    created() {
        if (localStorage.getItem('user-fmenu')) {
            this.activeIndex = localStorage.getItem('user-fmenu');
        }
    },
    data() {
        return {
            activeIndex: '/user/property/home', // 菜单栏的index
        }
    },
    methods: {
        adjustBoxHeight(height) {
            this.$refs.financeMenuBox.style.height = height;
        },
        controlRightBox(width) {
            this.$refs.rightBox.style.width = width + 40 + 'px';
        },
    },
}
</script>

<style scoped>
.full-container {
    height: 100%;
    width: 100vw;
    padding: 46px 0 18px 0;
    display: flex;
    justify-content: center;
}
.box {
    width: 200px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #FFFFFF;
}
.logo {
    width: 170px;
    object-fit: contain;
    margin: 50px 15px 50px 15px;
}
.right-box {
    height: 100%;
    margin-left: 20px;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .box {
        transform: scale(0.8);
        transform-origin: top right; /* 确保从中心缩放 */
    }
}
</style>