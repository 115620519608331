<template>
    <div ref="container3" class="canvas-landmap3-container">
        <div class="canvas-container" style="width: 1100px;height: 1100px;" ref="middleContainer">
            <canvas
                ref="backgroundcanvas"
                :width="containerWidth"
                :height="containerHeight"
                class="land-map-background"></canvas>
            <canvas
                ref="canvas"
                :width="containerWidth"
                :height="containerHeight"
                class="land-map"></canvas>
            <canvas
                ref="tooltipcanvas"
                :width="containerWidth"
                :height="containerHeight"
                class="land-map-tooltip"
                @mousedown="onMouseDown"
                @mouseup="onMouseUp"
                @mousemove="onMouseMove"
                @click="onMouseClick"
                @mouseover="changeCursor"></canvas>
        </div>


        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal" @click="closeModal">
            <div class="developer-common-modal-box">
                <span>{{ vmodalText }}</span>
            </div>
        </div>


        <!-- 左侧土地信息栏 -->
        <div class="land-map-information-position" ref="leftContainer">
            <!-- 合并分割查看选项卡 -->
            <div class="map-mode-tabs">
                <div class="map-mode-tab" :class="isTab('0')" @click="tabsControl('0')">{{$t('LandMap3.mode1')}}</div>
                <div class="map-mode-tab" style="margin-left:40px;" :class="isTab('1')" @click="tabsControl('1')">{{$t('LandMap3.mode2')}}</div>
                <div class="map-mode-tab" style="margin-left:40px;" :class="isTab('2')" @click="tabsControl('2')">{{$t('LandMap3.mode3')}}</div>
            </div>
            <!-- 小区信息 -->
            <div class="land-map-information-box">
                <div class="land-map-information-box-title">
                    <div class="land-map-information-box-title-icon"></div>
                    <span class="land-map-information-box-title-span">{{$t('LandMap3.lefttitle1')}}</span>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.oid')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ area.originallandid }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.oname')}}:</div>
                    <div class="land-map-information-box-items-infor" @mouseover="onMouseOverText(area.originallandname)" @mouseout="onMouseOutText" @mousemove="onMouseMoveText">{{ area.originallandname }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.address')}}:</div>
                    <div class="land-map-information-box-items-infor" @mouseover="onMouseOverText(area.detailedaddress)" @mouseout="onMouseOutText" @mousemove="onMouseMoveText">{{ area.detailedaddress }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.block')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ areaid }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.createtime')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ area.createdate }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.landstatu')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ area.landstatus }}</div>
                </div>
            </div>
            <!-- 选中的分块土地信息 -->
            <div class="land-map-information-box" v-if="selectUnitland">
                <div class="land-map-information-box-title">
                    <div class="land-map-information-box-title-icon"></div>
                    <span class="land-map-information-box-title-span">{{$t('LandMap3.lefttitle3')}}</span>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.selectunit')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ '(' + col + ',' + row + ')' }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.plotcoor')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ selectUnitland.landcoordinates }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.plotname')}}:</div>
                    <div class="land-map-information-box-items-infor" @mouseover="onMouseOverText(selectUnitland.mergelandname)" @mouseout="onMouseOutText" @mousemove="onMouseMoveText">{{ selectUnitland.mergelandname?selectUnitland.mergelandname:$t('LandMap3.noname') }}</div>
                    <i class="el-icon-edit" @click="openNameModal" @mouseover="changeCursor" v-show="sellThisLand"></i>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.unitnum')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ selectUnitland.landblocks }}{{$t('LandMap3.unit')}}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.statu')}}:</div>
                    <div class="land-map-information-box-items-icon" :class="getStatuCss(selectUnitland.status)">{{ selectUnitland.status }}</div>
                </div>
            </div>
            <!-- 挂牌信息 -->
            <div class="land-map-information-box" v-if="selectUnitland && selectUnitland.status==='已挂牌'">
                <div class="land-map-information-box-title">
                    <div class="land-map-information-box-title-icon"></div>
                    <span class="land-map-information-box-title-span">{{$t('LandMap3.lefttitle4')}}</span>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.listtime')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ selectUnitland.onSale.createtime }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.sellername')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ selectUnitland.onSale.seller }}</div>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label">{{$t('LandMap3.price')}}:</div>
                    <div class="land-map-information-box-items-infor">
                        <span v-if="$setPayment === 'am'">${{ selectUnitland.onSale.totalprice }}</span>
                        <span v-else-if="$setPayment === 'zh'">￥{{ selectUnitland.onSale.totalprice * $exchangeRate }}</span>
                    </div>
                    <i class="el-icon-edit" @click="openChangePriceModal" @mouseover="changeCursor" v-show="sellThisLand"></i>
                </div>
            </div>
            <div class="mb-5 text-center" v-if="sellThisLand && ispartition">
                <button class="land-sell-button" style="width:120px;" @click="openSellModal" v-if="sellThisLand.status === '未挂牌'">{{$t('LandMap3.listbutton')}}</button>
                <button class="land-sell-button" style="width:120px;" @click="openUnSellModal" v-else>{{$t('LandMap3.unlistbutton')}}</button>
            </div>
            <!-- 缩放图标 -->
            <!-- <div class="land-map-information-hide" @click="isLandInforMenu = !isLandInforMenu">
                <img src="../images/left.png" class="land-map-information-hide-img" v-if="isLandInforMenu">
                <img src="../images/right.png" class="land-map-information-hide-img" v-else>
            </div> -->
        </div>

        <!-- 修改土地名称的弹窗，弹框的显示和隐藏状态，根据 changenamevmodal 变量决定 -->
        <div class="land-sell-modal" v-if="changenamevmodal">
            <div class="land-sell-modal-box">
                <!-- 标题 -->
                <div class="land-sell-modal-box-title">
                    <span>{{$t('LandMap3.changenametitle')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" class="cursor-pointer" @click="changenamevmodal=false">
                    </div>
                </div>
                <el-input type="textarea" v-model="changeLandName" :placeholder="$t('LandMap3.landnametip')" maxlength="20" show-word-limit class="mt-5"></el-input>
                <p v-show="showError" style="font-size: 12px;color: red;">*{{$t('LandMap3.changenametip')}}</p>
                <button class="land-sell-button" @click="changeMyLandName">{{$t('LandMap3.ok')}}</button>
            </div>
        </div>

        <!-- 挂牌/修改价格/摘牌弹窗 -->
        <LandSale ref="landsale" :LandInfor="sellLandChild"></LandSale>


        <div class="land-map-control-position" v-if="whichtab==='0'" ref="rightContainer">
            <!-- 土地颜色标识 -->
            <div class="land-map-information-box">
                <div class="land-map-information-box-title">
                    <div class="land-map-information-box-title-icon"></div>
                    <span class="land-map-information-box-title-span">{{$t('LandMap3.lefttitle2')}}</span>
                </div>
                <div class="land-map-information-box-items">
                    <div style="height:20px;width:20px;background-color:#faff00"></div>
                    <span class="ml-4">{{$t('LandMap3.myland')}}</span>
                </div>
                <div class="land-map-information-box-items">
                    <div style="height:20px;width:20px;background-color:#4fff42"></div>
                    <span class="ml-4">{{$t('LandMap3.listingland')}}</span>
                </div>
            </div>
        </div>


        <!-- 右侧合并侧边栏 -->
        <div class="land-map-control-position" v-else-if="whichtab==='1'" ref="rightContainer">
            <div class="land-map-information-box" v-if="isLandMergeMenu">
                <div class="land-map-information-box-title">
                    <div class="land-map-information-box-title-icon"></div>
                    <span class="land-map-information-box-title-span">{{$t('LandMap3.mergetitle')}}</span>
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label2">{{$t('LandMap3.plotname')}}:</div>
                    <input type="text" maxlength="20" show-word-limit class="land-sell-modal-box-items-input" :placeholder="$t('LandMap3.landnametip')" v-model="mergeLandName">
                </div>
                <div class="land-map-information-box-items">
                    <div class="land-map-information-box-items-label2">{{$t('LandMap3.unitnumber')}}:</div>
                    <div class="land-map-information-box-items-infor">{{ selectMergeLand.length }}{{$t('LandMap3.unit')}}</div>
                </div>
            </div>
            <button class="land-sell-button" @click="LandMerge">{{$t('LandMap3.okbutton')}}</button>
        </div>


        <!-- 右侧分割侧边栏 -->
        <div class="land-map-control-position" v-else-if="whichtab==='2'" ref="rightContainer">
            <div class="land-map-information-box" v-if="isLandPartition">
                <div class="flex flex-col" v-if="whichpartitiontab==='0'">
                    <div class="land-map-information-box-title">
                        <div class="land-map-information-box-title-icon"></div>
                        <span class="land-map-information-box-title-span">{{$t('LandMap3.auto')}}</span>
                        <div class="flex-grow flex flex-row justify-end items-center">
                            <img src="../developer/map-partition-icon.png" style="height:16px;width:16px;" @click="whichpartitiontab='1'">
                            <span class="land-partition-changemode" @click="whichpartitiontab='1'">{{$t('LandMap3.autoTohand')}}</span>
                        </div>
                    </div>
                    <div class="land-map-information-box-items" v-if="selectUnitland">
                        <div class="land-map-information-box-items-label2">{{$t('LandMap3.selectlandname')}}:</div>
                        <div class="land-map-information-box-items-infor">{{ selectUnitland.mergelandname?selectUnitland.mergelandname:'未命名' }}</div>
                    </div>
                    <div class="land-map-information-box-items">
                        <div class="land-map-information-box-items-label2">{{$t('LandMap3.plotname')}}:</div>
                        <input type="text" maxlength="20" class="land-sell-modal-box-items-input" :placeholder="$t('LandMap3.partitionplotname')" v-model="partitionLandName" show-word-limit>
                    </div>
                    <div class="land-map-information-box-items">
                        <div class="land-map-information-box-items-label2">{{$t('LandMap3.unitnumber')}}:</div>
                        <div class="land-map-information-box-items-infor">{{ LandPartition?LandPartition.length - 1:0 }}{{$t('LandMap3.unit')}}</div>
                    </div>
                    <div class="land-map-information-box-items">
                        <van-radio-group v-model="landPartitionAutoRadio" direction="horizontal">
                            <van-radio name="1x1">1x1</van-radio>
                            <van-radio name="2x2">2x2</van-radio>
                            <van-radio name="3x3">3x3</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="flex flex-col" v-if="whichpartitiontab==='1'">
                    <div class="land-map-information-box-title">
                        <div class="land-map-information-box-title-icon"></div>
                        <span class="land-map-information-box-title-span">{{$t('LandMap3.hand')}}</span>
                        <div class="flex-grow flex flex-row justify-end items-center">
                            <img src="../developer/map-partition-icon.png" style="height:16px;width:16px;" @click="whichpartitiontab='0'">
                            <span class="land-partition-changemode" @click="whichpartitiontab='0'">{{$t('LandMap3.handToauto')}}</span>
                        </div>
                    </div>
                    <div class="land-map-information-box-items">
                        <div class="land-map-information-box-items-label2">{{$t('LandMap3.plotname')}}:</div>
                        <input type="text" class="land-sell-modal-box-items-input" :placeholder="$t('LandMap3.partitionplotname')" v-model="partitionLandName" maxlength="20" show-word-limit>
                    </div>
                    <div class="land-map-information-box-items">
                        <div class="land-map-information-box-items-label2">{{$t('LandMap3.unitnumber')}}:</div>
                        <div class="land-map-information-box-items-infor">{{ LandPartition?LandPartition.length - 1:0 }}{{$t('LandMap3.unit')}}</div>
                    </div>
                    <div class="land-map-information-box-items">
                        <div class="land-map-information-box-items-label2">{{$t('LandMap3.partitionnumber')}}:</div>
                        <div class="land-map-information-box-items-infor">{{ selectPartitionLand?selectPartitionLand.length:0 }}{{$t('LandMap3.unit')}}</div>
                    </div>
                </div>
            </div>
            <button class="land-sell-button" @click="LandPartitionAuto" v-if="whichpartitiontab==='0'">{{$t('LandMap3.okbutton')}}</button>
            <button class="land-sell-button" @click="LandPartitionHand" v-else-if="whichpartitiontab==='1'">{{$t('LandMap3.okbutton')}}</button>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 partitionvmodal 变量决定 -->
        <div class="land-sell-modal" v-if="partitionvmodal" @click="closeModal">
            <div class="land-sell-modal-box">
                <span class="text-3xl p-5">{{$t('LandMap3.changelandtip')}}</span>
                <div class="flex flex-row w-4/5">
                    <button class="land-sell-button" @click="removePartitionLand">{{$t('LandMap3.ok')}}</button>
                    <div class="flex-grow flex justify-end">
                        <button class="land-sell-button" @click="closeModal">{{$t('LandMap3.cancel')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LandSale from '../LandSaleModal.vue'
export default {
    components: {
        LandSale
    },
    created() {
        this.originallandid = this.$route.query.land;
        this.areaid = this.$route.query.area;
        if (this.$route.params.id) { this.unitlandid = this.$route.params.id }
    },
    mounted() {
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
        // 获取权限
        this.$http.get("/v1/business/select-business-status?originallandid=" + this.originallandid)
            .then((res) => {
                this.haveOwnerShip = res.data
            })
            .catch((error) => {});
        // 所有用户的土地块
        this.$http.get('/v1/terrain-map/select-blockland-by-communitynumber?originallandid=' + this.originallandid + '&communitynumber=' + this.areaid)
            .then(res => {
                // 处理返回值
                if(res.data.data.unitLands.length === 0) { // 开发商用户第一次分割，整块小区土地都属于该用户
                    this.originLandMenu = [{
                        originallandid: this.originallandid,
                        mergelandname: this.$t('LandMap3.noname'),
                        communitynumber: this.areaid,
                        landblocks: 484,
                        landcoordinates: "(0,0) ~ (21,21)",
                        status: "未挂牌",
                        virtualownership: "111",
                    },]
                    this.myLandMenu = [{
                        originallandid: this.originallandid,
                        mergelandname: this.$t('LandMap3.noname'),
                        communitynumber: this.areaid,
                        landblocks: 484,
                        landcoordinates: "(0,0) ~ (21,21)",
                        status: "未挂牌",
                    },]
                    this.ispartition = false; // 分割参数设为false，禁止挂牌，同时在土地处理时加入到分割列表中
                    this.whichtab = '2'; // 直接转到分割模式
                } else {
                    this.originLandMenu = res.data.data.unitLands;
                    this.ispartition = true;
                }
                this.area = res.data.data.originalLand;
                this.load1 = true;
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.initialfail'));
            });
        // 当前用户的土地块
        this.$http.get('/v1/terrain-map/select-myblockland-by-communitynumber?originallandid=' + this.originallandid + '&communitynumber=' + this.areaid)
            .then(res => {
                // 处理返回值
                if (this.myLandMenu.length === 0) { // 如果我的土地数组为空则录入，防止上面的接口先运行出结果导致覆写
                    this.myLandMenu = res.data.data.unitLands;
                }
                this.load2 = true;
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.initialfail'));
            });
        const loadingToast = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t('LandMap1.l12'),
        });
        this.timer = setInterval(() => {  // 等待 100 毫秒后再绘制
            if (this.load1 && this.load2) { // 未获取到后端返回值，循环
                if (this.loadnum ++ > 5) {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('LandMap3.alertdrawfail'));
                    clearInterval(this.timer);
                    this.timer = null;
                }
                this.addOriginUserLand();
                this.addUnitlandOwner();
                this.drawOriginUserLand();
                if (this.unitlandid !== '') { this.drawEnterUnitland() }
                if (!this.ispartition) { this.noPartition() }
                loadingToast.clear();
                clearInterval(this.timer);
                this.timer = null;
            } else {
                this.loadnum += 1;
                if (this.loadnum > 5) {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('LandMap3.alertdrawfail'));
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }
        }, 1000);
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener('resize', this.updateModalScale);
        next();
    },
    data() {
        return {
            haveOwnerShip: false, // 通过后端获取所有权，限制初次分割
            timer: null, // 定时器
            loadnum: 0, // 循环定时器循环次数
            load1: false, // 获取所有用户分块土地是否完成
            load2: false, // 获取我的分块土地是否完成
            originallandid: '', // 原始土地编号
            areaid: '', // 小区编号
            unitlandid: '', // 如果是通过分块土地管理进入的该土地，会携带分块土地编号
            area: '', // 此处存储当前小区的原始土地信息
            testLandId: 'TD012023042096919', // 测试用土地编号
            userLandView: {
                id: null, // 土地id
                name: null, // 土地名称
                num: null, // 土地块数
            },
            ispartition: false, // 是否是第一次进行分割，如果未分割过，不能进行挂牌
            vmodalText: '',
            containerHeight: 1100,
            containerWidth: 1100,
            gridSize: 50,  // 单位土地边长
            colLength: 22, // 计算横坐标方向有多少小方块
            rowLength: 22,
            originX: 0, // 鼠标当前的位置
            originY: 0,
            col: 0, // 鼠标选中的单位土地坐标
            row: 0,
            originLandExample: [
                {
                    id: 6335,
                    originallandid: "S538",
                    mergelandname: "工业土地",
                    landnumber: "1",
                    communitynumber: "区(1,1)",
                    landblocks: 16,
                    landcoordinates: "(16,0) ~ (19,3)",
                    virtualownership: "10002",
                    remark: null,
                    status: "未挂牌",
                    createdate: "2023-10-26 10:32:10.772355",
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: {
                        id: 6579,
                        originallandid: "S666",
                        ownershipnumber: "889123654",
                        originallandname: "炎域",
                        detailedaddress: "青岛市",
                        landcertificate: "/usr/local/land/landpicture/USER_10023/9f217a1b9dad4c428c79ed79ce058d86.pdf",
                        warrantydeed: null,
                        ownerid: "ad73795b81d74dacb72f280109143cae",
                        entrance: "东",
                        geographicalcoordinates: "119.82 , 36.11",
                        landlength: "120",
                        landwidth: "150",
                        height: "2",
                        digitalright: "出售数字权",
                        surfaceright: "出售地表权",
                        landpicture: "https://test.datuland.com/img/20231031153519.png",
                        createdate: "2023-10-31 14:34:13",
                        landstatus: "已上线",
                        applicationtype: "上传原始土地",
                        reasonrejection: null,
                        starttime: null,
                        endtime: null,
                        pagesize: 0,
                        pagenum: 0,
                        start: 0,
                        userBusiness: null
                    },
                    onSale: null
                },
            ], // 范例
            originLandMenu: [], // 用户已经合并/分割后的土地,后端获取
            myLandMenu: [], // 属于该用户自己的所有土地块,后端获取
            myLand: [], // 我的分块土地，存储单位坐标
            unitOwner: [], // 每个相同的所有人对应一种颜色
            colors: [
                "rgb(255,250,250,0.5)",
                "rgb(255,250,205,0.5)",
                "rgb(255,218,185,0.5)",
                "rgb(240,255,240,0.5)",
                "rgb(230,230,250,0.5)",
                "rgb(211,211,211,0.5)",
                "rgb(100,149,237,0.5)",
                "rgb(106,90,205,0.5)",
                "rgb(132,112,255,0.5)",
                "rgb(0,191,255,0.5)",
                "rgb(173,216,230,0.5)",
                "rgb(0,206,209,0.5)",
                "rgb(127,255,212,0.5)",
                "rgb(60,179,113,0.5)",
                "rgb(0,255,0,0.5)",
                "rgb(255,255,0,0.5)"], // 颜色对照表

            sellThisLand: null, // 查看-选中的土地可以进行拍卖
            selectUnitland: null, // 查看-选中的土地块
            changenamevmodal: false, // 查看-修改该土地块名称-弹框显示
            changeLandName: null, // 查看-修改该土地块名称-修改后名称
            showError: false, // 查看-修改该土地块名称-弹框-名称校验错误
            sellLandChild: { // 挂牌-所需参数
                unitlandid: '', // 分块土地编号
                mergelandname: '', // 商品名称
                landblocks: 0, // 土地块数
                sellprice: 0, // 总价-接口上传参数
                tip: false, // 有内容未输入并点击挂牌按钮，则提示
                vmodal: false // 是否打开该窗口
            },

            selectMergeLand: [], // 合并-已经选中的分块土地信息集合
            onSelectUnitland: [], // 合并-已经选中的单位土地坐标集合
            mergeLand: [], // 合并-已经选中录入的单位土地坐标集
            isSelectLand: [], // 合并-可以选中的单位土地坐标集合
            mergeLandName: '', // 合并-选中合并土地后取名
            mergeLandNum: [], // 合并-被选中的土地块id

            selectPartitionLand: [], // 分割-选中的分块土地详情信息
            LandPartition: [], // 分割-选中的用户土地块的单位土地数组集
            partitionLandId: null, // 分割-选中的待分割土地块在用户土地块二维数组集中的一维下标
            partitionLandName: '', // 分割-设置的分割后土地名称

            isLandInforMenu: true, // 左侧信息栏是否显示
            isLandMergeMenu: true, // 右侧合并栏是否显示
            isLandPartition: true, // 右侧分割栏是否显示
            whichtab: '0', // 当前模式，0为查看/挂牌，1为合并，2为分割
            isMouseDown: false, // 鼠标是否按下
            whichpartitiontab: '0', // 分割的模式:0为自动分割，1为手动分割
            landPartitionAutoRadio: '1x1', // 自动分割选中的分割块
            vmodal: false, // 确定/取消弹窗
            partitionvmodal: false, // 分割土地的弹窗显示
            flag: true, // 手动分割在操作上有点难点，加个防抖

            showToolTipText: false, // 是否显示全部文本悬浮窗
            toolTipText: '', // 显示的文本
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },


/* -------------------------------------------------------------------初始化功能区------------------------------------------------------------------------------ */



        // 根据屏幕大小自适应地图大小
        updateModalScale() {
            const modal1 = this.$refs.leftContainer;
            const modal2 = this.$refs.rightContainer;
            const modal3 = this.$refs.middleContainer;
            if (window.innerWidth >= 1950 && window.innerHeight >= 1200) {
                modal1.style.transform = `scale(1)`;
                modal2.style.transform = `scale(1)`;
                modal3.style.transform = `scale(1)`;
            } else {
                const scaleX = window.innerWidth / 1950;
                const scaleY = (window.innerHeight - 60) / 1160;
                const scale = Math.min(scaleX, scaleY);
                modal1.style.transform = `scale(${scale})`;
                modal2.style.transform = `scale(${scale})`;
                modal3.style.transform = `scale(${scale})`;
            }
        },
        addOriginUserLand() { // 根据后端传值传来的用户已经合并分割过的大块土地存入土地坐标
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/; // 提取坐标数据规则
            for(let i = 0;i < this.originLandMenu.length;i++) { // 所有用户的土地
                const matches = this.originLandMenu[i].landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
                // 存储坐标数据
                this.originLandMenu[i]["minX"] = parseInt(matches[1]);
                this.originLandMenu[i]["minY"] = parseInt(matches[2]);
                this.originLandMenu[i]["maxX"] = parseInt(matches[3]);
                this.originLandMenu[i]["maxY"] = parseInt(matches[4]);
            }
            for(let i = 0;i < this.myLandMenu.length;i++) { // 录入属于该用户自己的所有土地块包含的单位土地信息
                const matches = this.myLandMenu[i].landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
                this.myLandMenu[i]["minX"] = parseInt(matches[1]);
                this.myLandMenu[i]["minY"] = parseInt(matches[2]);
                this.myLandMenu[i]["maxX"] = parseInt(matches[3]);
                this.myLandMenu[i]["maxY"] = parseInt(matches[4]);
                // 存储我的单位土地坐标集，用于合并分割
                let land = []
                for(let a = this.myLandMenu[i].minX;a <= this.myLandMenu[i].maxX;a++) {
                    for(let b = this.myLandMenu[i].minY;b <= this.myLandMenu[i].maxY;b++) {
                        land.push({col:a,row:b})
                    }
                }
                land.push({id:i});
                this.myLand.push(land);
            }
        },
        addUnitlandOwner() { // 给每一个所有人都添加一个随机的常见颜色
            // 使用 reduce 方法处理原始数组
            const newArray = this.originLandMenu.reduce((result, obj) => {
                // 检查新数组中是否已存在 virtualownership 相同的对象
                const existingObj = result.find(item => item.virtualownership === obj.virtualownership);

                if (existingObj) {
                } else {
                    // 若不存在，则创建新对象，并存入 virtualownership 和 color
                    /* for(let i=0;i<=5;i++) {
                        for(let j=0;j<=5;j++) {
                            result.push({
                                virtualownership: obj.virtualownership,
                                color: this.colors[result.length], // 旧的颜色选择
                                color: 'rgb(' + Math.floor(255-42.5*i) + ',' + Math.floor(255-42.5*j) + ',0.5)'
                            });
                        }
                    } */
                    result.push({
                        virtualownership: obj.virtualownership,
                        color: this.colors[Math.floor(Math.random() * this.colors.length)], // 颜色从颜色表选择
                    });
                }

                return result;
            }, []);
            this.unitOwner = newArray;
        },
        drawOriginUserLand() { // 绘制用户土地块
            const canvas = this.$refs.backgroundcanvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            canvas.style.backgroundImage = "url(" + this.area.landpicture + ")"; // 修改背景图片
            for(let i = 0;i < this.originLandMenu.length;i++) { // 加载所有用户土地
                const minX = this.originLandMenu[i].minX
                const minY = this.originLandMenu[i].minY
                const maxX = this.originLandMenu[i].maxX
                const maxY = this.originLandMenu[i].maxY
                const n = this.gridSize
                const width = maxX - minX + 1
                const height = maxY - minY + 1
                // 画线
                ctx.fillStyle = "rgb(0,0,0)";
                ctx.strokeRect(minX * n,minY * n,width * n,height * n);
                // 填充颜色
                const array = this.unitOwner.find(item => item.virtualownership === this.originLandMenu[i].virtualownership);
                ctx.fillStyle = array.color;
                ctx.fillRect(minX * n + 1,minY * n + 1,width * n - 2,height * n - 2);
            }
            for(let i = 0;i < this.myLandMenu.length;i++) { // 加载所有属于本用户的土地，用金黄色表示，挂牌中的用橙红色
                const minX = this.myLandMenu[i].minX
                const minY = this.myLandMenu[i].minY
                const maxX = this.myLandMenu[i].maxX
                const maxY = this.myLandMenu[i].maxY
                const n = this.gridSize
                const width = maxX - minX + 1
                const height = maxY - minY + 1
                // 画线
                ctx.fillStyle = "rgb(0,0,0)";
                ctx.strokeRect(minX * n,minY * n,width * n,height * n);
                // 填充颜色
                if (this.myLandMenu[i].status === '已挂牌') { ctx.fillStyle = '#4fff42'; } else { ctx.fillStyle = '#faff00'; }
                ctx.fillRect(minX * n + 1,minY * n + 1,width * n - 2,height * n - 2);
            }
        },
        drawEnterUnitland() { // 如果初始进入时有分块土地信息，则高亮显示该土地块
            this.selectUnitland = this.originLandMenu.find((item) => item.id === parseInt(this.unitlandid)); // 更新左侧信息栏
            const width = this.selectUnitland.maxX - this.selectUnitland.minX + 1
            const height = this.selectUnitland.maxY - this.selectUnitland.minY + 1
            // 填充颜色-橙色
            const canvas = this.$refs.canvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            ctx.fillStyle = "orange"
            ctx.fillRect(this.selectUnitland.minX * this.gridSize + 1,this.selectUnitland.minY * this.gridSize + 1,width * this.gridSize - 2,height * this.gridSize - 2);
        },
        initializeBigmap() { // 初始化清空第二层canvas
            const canvas = this.$refs.canvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            // 清除原有的所有大地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        },
        


/* -------------------------------------------------------------------屏幕页面功能区,包括监听鼠标事件、获取点击位置等------------------------------------------------------------------------------ */
        // 鼠标悬浮在文本上显示全部文本悬浮窗事件
        onMouseOverText(text) {
            this.toolTipText = text;
            this.showToolTipText = true;
        },
        onMouseOutText() {
            this.showToolTipText = false;
        },
        onMouseMoveText(event) {
            this.$refs.tooltip2.style.top = event.clientY + 'px';
            this.$refs.tooltip2.style.left = event.clientX + 'px';
        },
        /* 模式切换时需要改变样式 */
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'sell-list-tab-select'
            } else {
                return
            }
        },
        showLandInfor() {
            // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.canvas;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            // 将位置坐标转化为土地块的坐标
            this.col = Math.floor(canvasX / this.gridSize);
            this.row = Math.floor(canvasY / this.gridSize);
        },
        // 鼠标按下土地地图事件
        onMouseDown() {
            this.isMouseDown = true;
        },
        onMouseClick(event) {
            this.originX = event.clientX;
            this.originY = event.clientY;
            this.showLandInfor() // 获取土地坐标
            if (this.whichtab==='1') { // 将选中的土地方块渲染为橙色，以此表示选中此土地块
                if (!this.flag) { return; }
                this.initializeBigmap() // 清空之前的绘图
                this.bigMapSelect() // 在合并模式，选中的格子为橙色，相邻格子显示为绿色
            } else if(this.whichtab==='2') {
                this.selectLandPartition() // 在分割模式，选中格子为橙色，但是有单位土地边框
            } else {
                this.initializeBigmap() // 清空之前的绘图
                this.drawLandSelect() // 在仅查看模式，选中格子不做处理，只显示土地块信息
            }
        },
        onMouseMove(event) {
            if (!this.isMouseDown) {
                this.floatingframe(event.clientX,event.clientY);
                return;
            } else {
                this.originX = event.clientX;
                this.originY = event.clientY;
                this.showLandInfor() // 获取土地坐标
                if (this.whichtab==='1') { // 合并模式
                    if (!this.flag) { return; }
                    this.initializeBigmap();
                    this.bigMapSelectMove();
                } else if (this.whichtab==='2' && this.whichpartitiontab === '1') { // 手动分割模式
                    this.selectLandPartitionHand();
                }
            }
        },
        onMouseUp() {
            this.isMouseDown = false;
        },
        floatingframe(x,y) { // 绘制悬浮框时获取坐标
            // 根据鼠标悬浮的坐标确定土地块坐标
            const canvas = this.$refs.tooltipcanvas;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = x - rect.left;
            const canvasY = y - rect.top;
            // 将位置坐标转化为土地块的坐标
            const landcol = Math.floor(canvasX / this.gridSize)
            const landrow = Math.floor(canvasY / this.gridSize)
            // 确定绘制的悬浮框坐标
            const drawX = landcol * this.gridSize + this.gridSize / 2
            const drawY = landrow * this.gridSize

            // 清除Canvas
            const ctx = canvas.getContext('2d')

            this.drawHoverBox(ctx,drawX,drawY,landcol,landrow)
        },
        drawHoverBox(ctx,drawX,drawY,landcol,landrow) { // 绘制悬浮框
            // 清除Canvas
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // 确定悬浮框的宽为80px,高为40px,最上层悬浮框设置为下方,右侧设置为左侧,其他情况设置为上方
            if (landrow === 0 && landcol !== this.colLength - 1) { // 上方
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX + 5, y + 10);
                ctx.lineTo(drawX - 40, y + 10);
                ctx.lineTo(drawX - 40, y + 50);
                ctx.lineTo(drawX + 40, y + 50);
                ctx.lineTo(drawX + 40, y + 10);
                ctx.lineTo(drawX + 10, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 30, y + 35);
            } else if (landcol === this.colLength - 1 && landrow !== 0) { // 右侧
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX - 10, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 50);
                ctx.lineTo(drawX + 10, drawY - 50);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.lineTo(drawX - 5, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 65, drawY - 22);
            } else if (landcol === this.colLength - 1 && landrow === 0) { // 右上
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX - 10, y + 10);
                ctx.lineTo(drawX - 60, y + 10);
                ctx.lineTo(drawX - 60, y + 50);
                ctx.lineTo(drawX + 20, y + 50);
                ctx.lineTo(drawX + 20, y + 10);
                ctx.lineTo(drawX - 5, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 55, y + 35);
            } else {
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX + 5, drawY - 10);
                ctx.lineTo(drawX - 25, drawY - 10);
                ctx.lineTo(drawX - 25, drawY - 50);
                ctx.lineTo(drawX + 55, drawY - 50);
                ctx.lineTo(drawX + 55, drawY - 10);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 20, drawY - 22);
            }
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
            this.selectMergeLand = []
            this.isSelectLand = [];
            this.mergeLandNum = []
            this.LandPartition = []
            this.partitionLandId = null
            this.showTooltip = false
            this.mergeLandName = ''
            this.sellThisLandId = null
            this.selectUnitland = null
            this.initializeBigmap();
        },
        closeModal() { // 关闭弹窗
            this.vmodal = false
            this.partitionvmodal = false
            this.changenamevmodal = false
            this.userLandView.id = null
            this.showError = false
        },



/* -------------------------------------------------------------------查看模式功能区------------------------------------------------------------------------------ */
        drawLandSelect() { // 绘制所有被选中的土地格，用橙色标注
            this.sellThisLandId = null;
            this.selectUnitland = null;
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange";
            const result = this.myLandMenu.find(item => {return (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)});
            if(result !== undefined) { // 属于自己的土地块
                this.selectUnitland = result;
                this.sellThisLand = result // 该土地可以用于挂牌，将该分块土地录入挂牌土地中
                // 绘制土地块
                const width = result.maxX - result.minX + 1
                const height = result.maxY - result.minY + 1
                ctx.fillRect(result.minX * this.gridSize + 1,result.minY * this.gridSize + 1,this.gridSize * width - 2,this.gridSize * height - 2);
            } else {
                const otherland = this.originLandMenu.find(item => {return (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)});
                if(otherland !== undefined) { // 属于别人的土地块
                    this.selectUnitland = otherland; // 更新左侧信息栏
                    // 绘制土地块
                    const width = otherland.maxX - otherland.minX + 1
                    const height = otherland.maxY - otherland.minY + 1
                    ctx.fillRect(otherland.minX * this.gridSize + 1,otherland.minY * this.gridSize + 1,this.gridSize * width - 2,this.gridSize * height - 2);
                } else { // 正常来说不应该存在
                    ctx.fillRect(this.col * this.gridSize + 1,this.row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
                    this.userLandView.id = null;
                }
            }
        },
        openNameModal() { // 修改该土地块名称-打开弹窗
            this.changenamevmodal = true;
        },
        changeMyLandName() { // 修改该土地块名称
            if(this.changeLandName.length === 0 || this.changeLandName.length > 20) {
                // 校验名称格式
                this.showError = true;
                return;
            }
            // 后端组件
            this.$http.put('/v1/original-land/update-unitland-name?id=' + this.selectUnitland.id + '&mergelandname=' + this.changeLandName)
            .then(res => {
                if (res.data.status === 200) {
                    this.$toast.success(this.$t('MyInfor.letter19'));
                    // 更新前端缓存数据
                    const resultid = this.myLandMenu.findIndex(item => {return item.id === this.selectUnitland.id});
                    this.myLandMenu[resultid].mergelandname = this.changeLandName;
                    this.selectUnitland = this.myLandMenu[resultid];
                    this.closeNameModal();
                } else {
                    this.$toast.fail(this.$t('all.modifyFail'))
                }
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.neterror'));
            });
        },
        closeNameModal() { // 修改该土地块名称-关闭弹窗
            this.changeLandName = '';
            this.changenamevmodal = false;
        },
        openSellModal() { // 左侧信息栏点击挂牌按钮打开挂牌弹窗
            if(!this.ispartition) {
                this.$toast.fail(this.$t('LandMap3.alertnopartition'));
                return;
            }
            this.sellLandChild.unitlandid = this.sellThisLand.id;
            this.sellLandChild.landnum = this.sellThisLand.landblocks;
            this.sellLandChild.vmodal = true;
            this.codeNeedOrNot('挂牌');
        },
        // 打开下架弹窗按钮
        openUnSellModal() {
            this.sellLandChild.unitlandid = this.sellThisLand.id;
            this.sellLandChild.landnum = this.sellThisLand.landblocks;
            this.codeNeedOrNot('下架');
        },
        // 打开修改价格弹窗按钮
        openChangePriceModal() {
            this.sellLandChild.unitlandid = this.sellThisLand.id;
            this.sellLandChild.landnum = this.sellThisLand.landblocks;
            this.codeNeedOrNot('修改价格');
        },
        getStatuCss(statu) {
            if (statu === '未挂牌') {
                return 'icon-statu-weiguapai';
            } else if (statu === '已挂牌') {
                return 'icon-statu-yiguapai';
            }
        },
        getEnglishStatu(zh) {
            if (zh === '未挂牌') {
                return this.$t('LandMap3.unlist');
            } else if (zh === '已挂牌') {
                return this.$t('LandMap3.listing');
            }
        },



/* -------------------------------------------------------------------合并模式功能区------------------------------------------------------------------------------ */



        bigMapSelect() { // 在合并模式，大地图鼠标按下时选中某个区域，此区域高亮显示
            const resultid = this.myLandMenu.findIndex(item => {return (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)});
            const result = this.myLandMenu[resultid];
            if(resultid !== undefined) {
                if(this.selectMergeLand.length < 1) { // 第一次选中某个分块土地时，直接加入
                } else if (this.isSelectLand.find(item => (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)) !== undefined || this.mergeLandNum.some(item => item === result.id)){ // 在可选坐标集中
                } else { // 不符合相邻规则，清空原数据重新录入
                    this.selectMergeLand = []; // 清空选中的分块土地集合
                    this.mergeLand = []; // 清空选中的单位土地集合
                    this.isSelectLand = []; // 清空相邻的分块土地集合
                    this.mergeLandNum = []; // 清空选中的分块土地id集合
                }
                if(!this.mergeLandNum.includes(result.id)) { // 点到属于用户自己的非单位土地且未加入到合并土地编号集中
                    this.selectUnitland = result; // 更新左侧菜单栏
                    if (result.status === '已挂牌') {
                        this.$toast.fail(this.$t('LandMap3.alertlisting1'));
                        this.drawBigMapSelect(); // 绘图
                        return;
                    }
                    this.mergeLandNum.push(result.id); // 计数，选中的分块土地编号，用于合并接口
                    this.selectMergeLand.push(result); // 合并中的分块土地信息集，用于绘图
                    for(let i = result.minX;i <= result.maxX;i++) { // 计数，合并选中的单位土地坐标集，同时用于后续合并接口的格式判定
                        for(let j = result.minY;j <= result.maxY;j++) {
                            this.mergeLand.push({col:i,row:j});
                        }
                    }
                    this.confirmSlectLandAdd(); // 计算可合并的相邻土地集-仅用于增加分块土地的简易低负载版
                    this.drawBigMapSelect(); // 绘图
                } else { //  点中了已在合并集中的分块土地，将其删除
                    this.selectUnitland = result; // 更新左侧菜单栏
                    this.mergeLandNum = this.mergeLandNum.filter(item => item !== result.id); // 删除选中的分块土地id集合中符合条件的元素
                    this.selectMergeLand = this.selectMergeLand.filter(item => item.id !== result.id); // 删除选中的分块土地集合中符合条件的元素
                    this.mergeLand = this.mergeLand.filter(item => !this.myLand[resultid].some(el => el.col === item.col && el.row === item.row)); // 删除选中的单位土地集合中符合条件的元素
                    this.confirmSlectLandDelete(); // 计算可合并的相邻土地集-需要计算所有选中的单位土地相邻的分块土地集合-高负载版
                    this.initializeBigmap();
                    this.drawBigMapSelect();
                }
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 50 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 50);
            } else { // 不在我的分块土地集中，不可选
                this.vmodalText = this.$t('LandMap3.alertcannotselect')
                this.vmodal = true
                this.selectMergeLand = []
                this.isSelectLand = []
                this.mergeLandNum = []
                this.selectUnitland = null
                return
            }
        },
        bigMapSelectMove() { // 在合并模式，大地图鼠标按下时拖动选中，注意拖动不适合添加删除事件
            const resultid = this.myLandMenu.findIndex(item => {return (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)});
            const result = this.myLandMenu[resultid];
            if(resultid !== undefined) {
                if(this.selectMergeLand.length < 1) { // 第一次选中某个分块土地时，直接加入
                } else if (this.isSelectLand.find(item => (this.col >= item.minX && this.col <= item.maxX) && (this.row >= item.minY && this.row <= item.maxY)) !== undefined || this.mergeLandNum.some(item => item === result.id)){ // 在可选坐标集中
                } else { // 不符合相邻规则，不执行录入
                    this.drawBigMapSelect(); // 绘图
                    return;
                }
                if(!this.mergeLandNum.includes(result.id)) { // 点到属于用户自己的非单位土地且未加入到合并土地编号集中
                    this.selectUnitland = result; // 更新左侧菜单栏
                    if (result.status === '已挂牌') {
                        this.$toast.fail(this.$t('LandMap3.alertlisting1'));
                        this.drawBigMapSelect(); // 绘图
                        return;
                    }
                    this.mergeLandNum.push(result.id); // 计数，选中的分块土地编号，用于合并接口
                    this.selectMergeLand.push(result); // 合并中的分块土地信息集，用于绘图
                    for(let i = result.minX;i <= result.maxX;i++) { // 计数，合并选中的单位土地坐标集，同时用于后续合并接口的格式判定
                        for(let j = result.minY;j <= result.maxY;j++) {
                            this.mergeLand.push({col:i,row:j});
                        }
                    }
                    this.confirmSlectLandAdd(); // 计算可合并的相邻土地集-仅用于增加分块土地的简易低负载版
                    this.drawBigMapSelect(); // 绘图
                } else { //  点中了已在合并集中的分块土地，不删除而是重新绘图
                    this.drawBigMapSelect();
                }
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 50 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 50);
            }
        },
        confirmSlectLandAdd() { // 根据最新选中的坐标集计算出可选的坐标集
            let minX = this.selectUnitland.minX;
            let minY = this.selectUnitland.minY;
            let maxX = this.selectUnitland.maxX;
            let maxY = this.selectUnitland.maxY;
            for(let i = 0;i < (maxX - minX + 1);i++) {
                const array = {col:minX+i,row:minY-1} // 上边单位土地
                const result = this.myLandMenu.find(item => {return array.col >= item.minX && array.row >= item.minY && array.col <= item.maxX && array.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result !== undefined && this.isSelectLand.find(item => {return item.id === result.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result)
                }
                const array1 = {col:minX+i,row:maxY+1} // 下边单位土地
                const result1 = this.myLandMenu.find(item => {return array1.col >= item.minX && array1.row >= item.minY && array1.col <= item.maxX && array1.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result1 !== undefined && this.isSelectLand.find(item => {return item.id === result1.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result1)
                }
            }
            for(let i = 0;i < (maxY - minY + 1);i++) {
                const array = {col:minX-1,row:minY+i} // 左边单位土地
                const result = this.myLandMenu.find(item => {return array.col >= item.minX && array.row >= item.minY && array.col <= item.maxX && array.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result !== undefined && this.isSelectLand.find(item => {return item.id === result.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result)
                }
                const array1 = {col:maxX+1,row:minY+i} // 右边单位土地
                const result1 = this.myLandMenu.find(item => {return array1.col >= item.minX && array1.row >= item.minY && array1.col <= item.maxX && array1.row <= item.maxY}); // 上边n个单位土地之一对应的分块土地
                if(result1 !== undefined && this.isSelectLand.find(item => {return item.id === result1.id}) === undefined) { // 对应分块土地不在可选分块土地集中
                    this.isSelectLand.push(result1)
                }
            }
        },
        confirmSlectLandDelete() { // 根据所有选中的单位土地坐标集计算出可选的分块土地坐标集
            let array = [];
            this.mergeLand.forEach(element => { // 计算选中的单位土地集合的所有横纵相邻的单位土地集合
                // 计算上下左右四个相邻坐标
                const top = { col: element.col, row: element.row - 1 };
                const bottom = { col: element.col, row: element.row + 1 };
                const left = { col: element.col - 1, row: element.row };
                const right = { col: element.col + 1, row: element.row };

                // 判断相邻坐标是否已存在于 array 或 this.mergeLand 中
                if (!array.some(el => el.col === top.col && el.row === top.row) && !this.mergeLand.some(el => el.col === top.col && el.row === top.row)) {
                    array.push(top);
                }
                if (!array.some(el => el.col === bottom.col && el.row === bottom.row) && !this.mergeLand.some(el => el.col === bottom.col && el.row === bottom.row)) {
                    array.push(bottom);
                }
                if (!array.some(el => el.col === left.col && el.row === left.row) && !this.mergeLand.some(el => el.col === left.col && el.row === left.row)) {
                    array.push(left);
                }
                if (!array.some(el => el.col === right.col && el.row === right.row) && !this.mergeLand.some(el => el.col === right.col && el.row === right.row)) {
                    array.push(right);
                }
            });
            this.isSelectLand = [];
            for(let i = 0;i < array.length;i++) {
                const result = this.myLandMenu.find(item => item.minX <= array[i].col && item.minY <= array[i].row && item.maxX >= array[i].col && item.maxY >= array[i].row);
                if (result !== undefined) { // 比对this.isSelectLand和result是否有相同元素，如果有返回true，没有返回false
                    const flag = this.isSelectLand.some((item) => {return item.col >= result.minX && item.row >= result.minY && item.col <= result.maxX && item.row <= result.maxY});
                    if (!flag) { this.isSelectLand.push(result); }
                }
            }
        },
        drawBigMapSelect() { // 合并模式根据选中的单位土地集和可选的单位土地集改变相应土地格颜色进行标注
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "green"
            for(let i = 0;i < this.isSelectLand.length;i++) { // 绘制所有可选择的土地格，用绿色标注
                const height = this.isSelectLand[i].maxY - this.isSelectLand[i].minY + 1;
                const width = this.isSelectLand[i].maxX - this.isSelectLand[i].minX + 1;
                ctx.fillRect(this.isSelectLand[i].minX * this.gridSize + 1,this.isSelectLand[i].minY * this.gridSize + 1,width * this.gridSize - 2,height * this.gridSize - 2);
            }
            ctx.fillStyle = "orange"
            for(let i = 0;i < this.selectMergeLand.length;i++) { // 绘制所有已录入合并分块土地集的土地格，用橙色标注
                const height = this.selectMergeLand[i].maxY - this.selectMergeLand[i].minY + 1;
                const width = this.selectMergeLand[i].maxX - this.selectMergeLand[i].minX + 1;
                ctx.fillRect(this.selectMergeLand[i].minX * this.gridSize + 1,this.selectMergeLand[i].minY * this.gridSize + 1,width * this.gridSize - 2,height * this.gridSize - 2);
            }
        },
        LandMerge() { // 合并按钮
            if (this.mergeLandNum.length < 2) {
                this.vmodalText = this.$t('LandMap3.alertmergetip1')
                this.vmodal = true
                return;
            } else if (this.mergeLandName === '') {
                this.vmodalText = this.$t('LandMap3.alertmergetip2')
                this.vmodal = true
                return;
            } else if (this.mergeLandName.length > 10) {
                this.vmodalText = this.$t('LandMap3.alertmergetip3')
                this.vmodal = true
                return;
            }
            const minX = Math.min(...this.selectMergeLand.map(coord => Number(coord.minX)));
            const minY = Math.min(...this.selectMergeLand.map(coord => Number(coord.minY)));
            const maxX = Math.max(...this.selectMergeLand.map(coord => Number(coord.maxX)));
            const maxY = Math.max(...this.selectMergeLand.map(coord => Number(coord.maxY)));
            const width = maxX - minX + 1;
            const height = maxY - minY + 1;
            const aspectRatio = width / height;
            if (aspectRatio >= 4 || aspectRatio <= 0.25 || this.mergeLand.length < width * height) {
                this.vmodalText = this.$t('LandMap3.alerttip');
                this.vmodal = true;
                return;
            }
            
            // 符合要求，进行后续操作
            // const landcoordinates = '(' + minX + ',' + minY + ') ~ (' + maxX + ',' + maxY + ')';
            let string = 'communitynumber=' + this.areaid + '&mergelandname=' + this.mergeLandName;
            for(let i=0;i<this.mergeLandNum.length;i++) {
                string += '&ids[]=' + this.mergeLandNum[i];
            }
            this.$http.post('/v1/terrain-map/blockland-merge-byid?' + string)
            .then(res => {
                // 处理返回值
                this.$toast.success(res.data.msg);
                window.location.reload(); // 刷新页面
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.neterror'));
            });
        },



/* -------------------------------------------------------------------分割模式功能区------------------------------------------------------------------------------ */



        noPartition() { // 未分割的小区进入时直接选中该小区分块土地
            this.partitionLandId = '0';
            this.selectUnitland = this.myLandMenu[0];
            this.LandPartition = this.myLand[0];
            this.drawLandPartition();
        },
        selectLandPartition() { // 在分割模式，选中用户自己的大土地块时，将其加入分割数组集
            if (!this.flag) { return; }
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row)); // 获取土地块的单位土地数组集
            if (this.myLandMenu[result[result.length - 1].id].status === '已挂牌') {
                this.$toast.fail(this.$t('LandMap3.alertlisting2'));
                return;
            }
            if(result !== undefined && result.length > 2 && this.LandPartition.length === 0) { // 第一次选中该用户的土地中其中一块
                this.partitionLandId = result[result.length - 1].id; // 获取选中的土地块在我的土地集中的一维下标
                this.selectUnitland = this.myLandMenu[this.partitionLandId]; // 更新左侧信息栏
                this.LandPartition = result; // 用于绘制被分割选中的分块土地
                this.drawLandPartition();
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 50 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 50);
            } else if (result !== undefined && result.length > 2 && this.LandPartition.length > 0) { // 在已选中该用户土地时,需要判断点的是否为已选中的土地块
                const flag = this.LandPartition.find(item => item.col === this.col && item.row === this.row);
                if (flag && this.whichpartitiontab === '1') { // 处于手动分割模式，用户选中待分割的分块土地内的单位土地，录入到手动分割单位土地集
                    const index = this.selectPartitionLand.findIndex(item => item.col === this.col && item.row === this.row);
                    if (index < 0) { // 未录入，直接录入
                        this.selectPartitionLand.push({col:this.col,row:this.row});
                        this.drawLandPartition2();
                    } else { // 已录入，直接删除
                        const index = this.selectPartitionLand.findIndex(item => item.col === this.col && item.row === this.row);
                        this.selectPartitionLand.splice(index,1);
                        this.drawLandPartition3(); // 重新绘制手动分割选中的单位土地
                    }
                    this.flag = false;
                    let timer = setTimeout(() => {
                        // 等待 50 毫秒后才能再触发
                        this.flag = true;
                        clearInterval(timer);
                    }, 50);
                } else if (flag && this.whichpartitiontab === '0'){ // 处于自动分割模式，用户选中待分割的分块土地内的单位土地，提示用户转手动模式
                    this.vmodalText = this.$t('LandMap3.alertpartitionmode');
                    this.vmodal = true;
                    return;
                } else { // 点中了其他该用户土地块，询问是否更改
                    this.partitionvmodal = true;
                }
            } else {
                this.vmodalText = this.$t('LandMap3.alertcannotselect');
                this.vmodal = true;
            }
        },
        selectLandPartitionHand() { // 专门用于手动分割拖动划入选中的功能
            if (!this.flag) { return; }
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row)); // 获取土地块的单位土地数组集
            if (result !== undefined && result.length > 1 && this.partitionLandId) { // 在已选中该用户土地时,需要判断点的是否为已选中的土地块
                const flag = this.LandPartition.find(item => item.col === this.col && item.row === this.row);
                if (flag && this.whichpartitiontab === '1') { // 处于手动分割模式，用户选中待分割的分块土地内的单位土地，录入到手动分割单位土地集
                    const index = this.selectPartitionLand.findIndex(item => item.col === this.col && item.row === this.row);
                    if (index < 0) { // 未录入，直接录入
                        this.selectPartitionLand.push({col:this.col,row:this.row});
                        this.drawLandPartition2();
                    }/*  else { // 已录入，直接删除，不太好用，只要拖动鼠标就会触发，会导致选中的单位土地闪烁
                        const index = this.selectPartitionLand.findIndex(item => item.col === this.col && item.row === this.row);
                        this.selectPartitionLand.splice(index,1);
                        this.drawLandPartition3(); // 重新绘制手动分割选中的单位土地
                    } */
                    this.flag = false;
                    let timer = setTimeout(() => {
                        // 等待 50 毫秒后才能再触发
                        this.flag = true;
                        clearInterval(timer);
                    }, 50);
                }
            }
        },
        removePartitionLand() { // 在已选中某个土地块时更改土地块
            this.initializeBigmap() // 清空之前的绘图
            this.selectPartitionLand = [] // 清空手动分割的数据
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row)); // 获取选中土地块一维数组
            this.partitionLandId = result[result.length - 1].id; // 获取选中的土地块在我的土地集中的一维下标
            this.selectUnitland = this.myLandMenu[this.partitionLandId]; // 更新左侧信息栏
            this.LandPartition = result; // 用于绘制被分割选中的分块土地
            this.drawLandPartition();
            this.partitionvmodal = false; // 关闭弹窗
        },
        drawLandPartition() { // 选中分割土地块后，将其颜色填充为橙色
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            for(let i = 0;i < this.LandPartition.length - 1;i++) {
                ctx.fillRect(this.LandPartition[i].col * this.gridSize + 1,this.LandPartition[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        drawLandPartition2() { // 在手动分割模式中，用户自己点选该土地块的单位土地
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "#FF7F50"
            for(let i = 0;i < this.selectPartitionLand.length;i++) {
                ctx.fillRect(this.selectPartitionLand[i].col * this.gridSize + 1,this.selectPartitionLand[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        drawLandPartition3() {
            this.initializeBigmap();
            this.drawLandPartition();
            this.drawLandPartition2();
        },
        PartitionTabsControl(n) {
            this.whichpartitiontab = n;
            this.partitionLandName = '';
        },
        isPartitionTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichpartitiontab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        /* ------------------------------------------------自动分割------------------------------------------- */
        LandPartitionAuto() { // 自动分割土地
            if(this.LandPartition.length < 2) { // 未选中土地块，不可操作
                this.vmodalText = this.$t('LandMap3.alertpartip1');
                this.vmodal = true;
                return;
            } else if (this.partitionLandName === '') {
                this.$toast.fail(this.$t('LandMap3.alertpartip2'));
                return;
            }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('LandMap3.loading'),
                forbidClick: true,
            });
            // 符合要求，进行后续操作
            if (this.ispartition) { // 该小区已分割
                this.$http.get('/v1/terrain-map/segmentation-blockland?id=' + this.selectUnitland.id + '&size=' + this.landPartitionAutoRadio)
                .then(res => {
                    toastLoading.clear();
                    this.$toast.success(res.data.msg);
                    // 获取当前页面的完整 URL
                    const currentUrl = window.location.href;
                    // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                    const url = new URL(currentUrl);
                    url.searchParams.set('id', res.data.data[0]);
                    // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                    history.replaceState({}, null, url.toString());
                    location.reload();
                }).catch(err => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('LandMap3.neterror'));
                });
            } else { // 该小区未分割
                if (!this.haveOwnerShip) { // 没有权限进行初次分割
                    toastLoading.clear();
                    this.$toast.fail(this.$t('LandMap3.noownership'));
                    return;
                }
                this.$http.get('/v1/terrain-map/segmentation-originalland?originallandid=' + this.originallandid + '&communitynumber=' + this.areaid + '&mergelandname=' + this.partitionLandName + '&size=' + this.landPartitionAutoRadio)
                .then(res => {
                    toastLoading.clear();
                    this.$toast.success(res.data.msg);
                    // 获取当前页面的完整 URL
                    const currentUrl = window.location.href;
                    // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                    const url = new URL(currentUrl);
                    url.searchParams.set('id', res.data.data[0]);
                    // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                    history.replaceState({}, null, url.toString());
                    location.reload();
                }).catch(err => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('LandMap3.neterror'));
                });
            }
        },
        /* ------------------------------------------------手动分割------------------------------------------- */
        LandPartitionHand() { // 手动分割土地
            if (this.LandPartition.length < 3) {
                this.$toast.fail(this.$t('LandMap3.alertpartip3'))
                return;
            } else if (this.selectPartitionLand.length === 0) {
                this.$toast.fail(this.$t('LandMap3.alertpartip4'))
                return;
            } else if (this.partitionLandName === '') {
                this.$toast.fail(this.$t('LandMap3.alertpartip2'));
                return;
            }
            const minX = Math.min(...this.selectPartitionLand.map(coord => coord.col));
            const minY = Math.min(...this.selectPartitionLand.map(coord => coord.row));
            const maxX = Math.max(...this.selectPartitionLand.map(coord => coord.col));
            const maxY = Math.max(...this.selectPartitionLand.map(coord => coord.row));
            const width = maxX - minX + 1;
            const height = maxY - minY + 1;
            const aspectRatio = width / height;
            if (aspectRatio >= 4 || aspectRatio <= 0.25 || this.selectPartitionLand.length < width * height) {
                this.$toast.fail(this.$t('LandMap3.alerttip'));
                return;
            } else if (this.selectPartitionLand.length >= this.LandPartition.length) {
                this.$toast.fail(this.$t('LandMap3.alertpartip5'));
                return;
            }
            const coordinate = '(' + minX + ',' + minY + ') ~ (' + maxX + ',' + maxY + ')';

            // 符合要求，进行后续操作
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('LandMap3.loading'),
                forbidClick: true,
            });
            if (this.ispartition) { // 该小区已分割
                this.$http.get('/v1/terrain-map/handmovement-segmentation-blockland?id=' + this.selectUnitland.id + '&coordinate=' + coordinate + '&mergelandname=' + this.partitionLandName)
                .then(res => {
                    toastLoading.clear();
                    this.$toast.success(res.data.msg);
                    /* // 获取当前页面的完整 URL
                    const currentUrl = window.location.href;
                    // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                    const url = new URL(currentUrl);
                    url.searchParams.set('id', res.data.data);
                    // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                    history.replaceState({}, null, url.toString());
                    location.reload(); */
                    window.location.reload();
                }).catch(err => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('LandMap3.neterror'));
                });
            } else { // 该小区未分割
                if (!this.haveOwnerShip) { // 没有权限进行初次分割
                    toastLoading.clear();
                    this.$toast.fail(this.$t('LandMap3.noownership'));
                    return;
                }
                this.$http.get('/v1/terrain-map/handmovement-segmentation-originalland?originallandid=' + this.originallandid + '&communitynumber=' + this.areaid + '&mergelandname=' + this.partitionLandName + '&coordinate=' + coordinate)
                .then(res => {
                    toastLoading.clear();
                    this.$toast.success(res.data.msg);
                    window.location.reload();
                    /* // 获取当前页面的完整 URL
                    const currentUrl = window.location.href;
                    // 使用 URL 对象解析当前 URL，并修改 Query 参数的值
                    const url = new URL(currentUrl);
                    url.searchParams.set('id', res.data.data);
                    // 使用 `history.replaceState()` 修改当前 URL，并触发页面刷新
                    history.replaceState({}, null, url.toString());
                    location.reload(); */
                }).catch(err => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('LandMap3.neterror'));
                });
            }
        },
    }
}
</script>

<style scoped>
.canvas-landmap3-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  background-color: #F2F8FF;
}


/* ----------------------------------------三重canvas，第一层背景区，第二层操作区，第三层坐标绘制区---------------------------------------- */
.canvas-container {
    transform-origin: top left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.land-map-background {
  background-image: none;
  background-size: cover;
  position: absolute;
  transform: translate(-50%, -50%);
}
.land-map {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);
}
/* 悬浮坐标绘制的canvas图 */
.land-map-tooltip {
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -50%);
}


/* --------------------------------------------选项卡,三个模式查看、合并、分割-------------------------------------------- */
.map-mode-tabs {
    margin-top: 30px;
    height: 60px;
    width: 100%;
    border-bottom: 2px solid rgba(83, 83, 83, 0.1);
}
.map-mode-tab {
    display: inline-block;
    height: 100%;
    min-width: 70px;
    font-size: 20px;
    color: #3D3D3D;
    cursor: pointer;
}
.map-mode-tab-select {
    font-size: 24px;
    font-weight: 600;
    color: #3D3D3D;
    border-bottom: 2px solid #165DFF;
}


/* ----------------------------------------屏幕左侧土地信息栏---------------------------------------- */
/* 该小区土地信息框 */
.land-map-information-position {
    /* 确定位置和主题色、字体 */
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    z-index: 4;
    transform-origin: top left;
}
.land-map-information-box {
    width: 360px;
    background-color: #FFFFFF;
    margin-top: 16px;
    border-radius: 16px;
    padding: 36px 32px;
    display: flex;
    flex-direction: column;
}
.land-map-information-box-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.land-map-information-box-title-icon {
    height: 31px;
    width: 6px;
    background-color: #3662EC;
}
.land-map-information-box-title-span {
    margin-left: 15px;
    font-size: 25px;
    font-weight: 600;
    white-space: nowrap;
}
.land-map-information-box-items {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.land-map-information-box-items-label {
    width: 150px;
    font-size: 16px;
    color: #3a3a3a;
}
.land-map-information-box-items-label2 {
    width: 120px;
    font-size: 16px;
    color: #3a3a3a;
}
.land-map-information-box-items-infor {
    font-size: 16px;
    max-width: 150px;
    white-space: nowrap; /* 不换行 */
    overflow: hidden;
    text-overflow: ellipsis;
}
.land-map-information-box-items-icon {
    height: 26px;
    border-radius: 4px;
    padding: 3px 10px;
    font-size: 16px;
    font-weight: 500;
}
.icon-statu-weiguapai {
    background-color: rgba(54, 98, 236, 0.1);
    color: #3662EC;
}
.icon-statu-yiguapai {
    background-color: rgba(0, 181, 120, 0.1);
    color: #00B578;
}
/* 挂牌及其他左侧信息栏内按钮 */
.land-information-menu-button {
  height: 50px;
  width: 120px;
  margin-top: 20px;
  background-color: #fff;
}

/* 显示隐藏的按钮样式--没用了 */
.land-map-information-hide {
    height: 30px;
    width: 20px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #767676;
}
.land-map-information-hide-img {
    height: 30px;
    width: 20px;
    object-fit: cover;
}


/* ----------------------------------------屏幕右侧合并信息栏---------------------------------------- */
.land-map-control-position {
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    z-index: 4;
    transform-origin: top right;
}
.land-map-right-hide {
    /* 显示隐藏的按钮样式 */
    height: 30px;
    width: 20px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #767676;
}
/* 合并分割按钮 */
.land-map-right-button {
    height: 50px;
    width: 120px;
    margin-top: 20px;
    font-size: 24px;
    background-color: #fff;
    color: #000;
}


/* ----------------------------------------屏幕右侧分割信息栏---------------------------------------- */
.land-partition-changemode {
    font-size: 16px;
    margin-left: 7px;
    color: #3662EC;
    cursor: pointer;
}


/* ----------------------------------------合并/分割选项卡-没用了---------------------------------------- */
.tabs-select {
    position: absolute;
    bottom: 50px;
    right: 100px;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}
.tabs-select-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 20px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #165DFF;
    color: aqua;
    font-size: 20px;
}
.tabs-select-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}



/* ----------------------------------------确定/取消的弹窗---------------------------------------- */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 99; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    min-height: 150px;
    padding: 50px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>