<template>
  <div class="main-container" ref="mainContainer">
    <h1 class="text-3xl p-5">{{$t('MobileMessageWindow.topic1')}}<span class="px-3">{{ name }}</span>{{$t('MobileMessageWindow.topic2')}}</h1>
    <div class="seller-chat-box">
      <ChatWindow :sale="sale"></ChatWindow>
    </div>
  </div>
</template>

<script>
import ChatWindow from './ChatCustomer.vue'
export default {
  components: {
    ChatWindow,
  },
  created() { // 如果是开发商联系用户，那应该有这些参数
        if (this.$route.query.merch) {
            this.merch = this.$route.query.merch
        }
  },
  mounted() {
    this.controlWindow();
      window.addEventListener('resize', this.controlWindow);
        if (this.merch) {
            this.$http.get('/v1/mail/select-onsale-by-saleid?saleid=' + this.merch)
                .then(res => {
                    this.sale = res.data.data;
                    this.$parent.changeName(sale.seller);
                    if (this.receiveid !== this.sale.sellerid) {
                        this.noSaleAlert = true;
                    }
                }).catch(error => {
                    this.noSaleAlert = true;
                })
        }
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.controlWindow);
  },
  data() {
    return {
      name: 'xxx', // 标题
      sale: null,
      saleExample: {
                "id":140,
                "originallandid":"S751",
                "unitlandid":"6006",
                "landname":"黄土地",
                "sellerid":"b4bcab5f1afa44e8bbba5449afa13d6d",
                "seller":"Mrs.wang",
                "saleslandpicture":"",
                "salesmethods":"立即购买",
                "expirydate":"",
                "unitlandprice":null,
                "pricenum":1200,
                "unitlandminprice":null,
                "minimumprice":null,
                "blocks":2,
                "totalprice":1200,
                "saleslandpicture":null,
                "createtime":"2023-06-20 13:41:41.131",
                "status":"挂牌中",
                "remark":null,
                "originalLand":null,
                "unitLand":null
            }, // 商品信息
    }
  },
  methods: {
    // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 980 || window.innerHeight <= 1080) {
                const scaleX = (window.innerWidth - 280) / 700;
                const scaleY = (window.innerHeight - 100) / 980;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
                this.$refs.mainContainer.style.height = 600 / scale + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = '100%';
            }
        },
    changeName(value) {
      this.name = value;
    },
  },
}
</script>

<style scoped>
.seller-chat-box {
  width: 700px;
  height: 80%;
}
@media (max-width: 980px) or (max-height: 1080px) {
    .main-container {
      transform-origin: top center;
      justify-content: start;
    }
}
</style>