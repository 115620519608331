<template>
    <div class="main-container">
        <div class="normal-card-box overflow-auto">
            <div class="content-container" ref="mainContainer">
                <!-- 标题 -->
                <div class="normal-card-title">
                {{ $t('PcMyLandList.title') }}
                </div>

                <!-- 日期筛选 -->
                <div class="filter">
                    <span>{{ $t('PcMyLandList.time') }}</span>
                    <el-date-picker
                        v-model="value"
                        type="daterange"
                        align="right"
                        unlink-panels
                        :range-separator="$t('LandSellList.mergename')"
                        :start-placeholder="$t('LandSellList.price')"
                        :end-placeholder="$t('LandSellList.saletime')"
                        :picker-options="pickerOptions"
                        class="ml-3">
                    </el-date-picker>
                    <button class="reset-button" @click="value = ''">{{ $t('PcMyLandList.clear') }}</button>
                </div>

                <!-- 未挂牌/已挂牌选项卡 -->
                <div class="sell-list-tabs">
                    <div class="sell-list-tab" :class="isTab('未挂牌')" @click="tabsControl('未挂牌')">{{$t('LandSellList.title')}}</div>
                    <div class="sell-list-tab" style="margin-left:120px;" :class="isTab('已挂牌')" @click="tabsControl('已挂牌')">{{$t('LandSellList.name')}}</div>
                </div>

                <!-- 批量选择 -->
                <div class="filter">
                <!-- 批量选择按钮 -->
                    <div class="developer-search-input" style="margin-top: 20px;" v-if="mode">
                        <button class="developer-common-card-button infor1-button-blueground" @click="mode = false">{{$t('all.batchMode')}}</button>
                    </div>
                    <!-- 批量模式按钮 -->
                    <div class="developer-search-input" style="margin-top: 20px;" v-else>
                        <button class="developer-common-card-button infor1-button-blueground" v-if="whichtab === '未挂牌'" @click="openSellModalSeg(1)">{{$t('all.sale')}}</button>
                        <button class="developer-common-card-button infor1-button-redground" v-if="whichtab === '已挂牌'" @click="openSellModalSeg(2)">{{$t('LandSellDetail.unsale')}}</button>
                        <button class="developer-common-card-button infor1-button-whiteground" style="margin-left: 12px;" v-if="whichtab === '已挂牌'" @click="openSellModalSeg(3)">{{$t('LandSellDetail.changeprice')}}</button>
                        <button class="developer-common-card-button infor1-button-grayground" style="margin-left: 12px;" @click="cancelSeg">{{$t('MobileOrderList.cancelSeg')}}</button>
                        <el-checkbox style="margin-left: 12px;" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('all.selectPage')}}</el-checkbox>
                    </div>
                </div>

                <!-- 列表 -->
                <div class="flex-grow flex flex-col w-full" style="margin-top:30px;">
                    <div class="home-account-col">
                        <div class="home-account-title">
                            <el-col :span="6"><span>{{$t('useraccount.originalid')}}</span></el-col>
                            <el-col :span="6"><span>{{$t('useraccount.landName')}}</span></el-col>
                            <el-col :span="4"><span>{{$t('LandList.getTime')}}</span></el-col>
                            <el-col :span="8"><span>{{$t('useraccount.control')}}</span></el-col>
                        </div>
                        <el-skeleton :loading="loading" :rows="20" animated />
                        <div class="home-account-data" v-if="!loading && showLength !== 0">
                            <el-row class="relative text-center" v-for="(user, index) in myLand" :key="index" style="width:100%;">
                            <el-checkbox class="land-sell-checkbox" style="position: absolute;" v-model="segChecked[index]" @change="changeCheckBoxValue(segChecked[index],user.unitLand.id)" v-show="!mode"></el-checkbox>
                                <div class="users-information-col" :style="tradeIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="tradeIndex = index" @mouseleave="tradeIndex = -1">
                                    <el-col :span="6"><span class="users-information-col-span">{{ user.originallandid }}</span></el-col>
                                    <el-col :span="6"><span class="users-information-col-span">{{ user.landname }}</span></el-col>
                                    <el-col :span="4"><span class="users-information-col-span">{{ manageTime(user.createtime) }}</span></el-col>
                                    <el-col :span="8">
                                    <span class="goto-detail" @click="gotoLandDetail(user.unitlandid)">{{$t('useraccount.certificate')}}</span>
                                    &nbsp;
                                    <span class="goto-detail" @click="gotoLand(user.originallandid,user.unitLand.communitynumber,user.unitLand.id)">{{$t('LandSellList.detailedaddress')}}</span>
                                    &nbsp;
                                    <span class="goto-detail" @click="openSellModal(1,user)" v-if="user.unitLand.status === '未挂牌'">{{$t('all.sale')}}</span>
                                    &nbsp;
                                    <span class="goto-detail" @click="openSellModal(3,user)" v-if="user.unitLand.status === '已挂牌'">{{$t('LandSellDetail.changeprice')}}</span>
                                    &nbsp;
                                    <span class="goto-detail" @click="openSellModal(2,user)" v-if="user.unitLand.status === '已挂牌'">{{$t('LandSellList.geo')}}</span>
                                    </el-col>
                                </div>
                            </el-row>
                        </div>
                        <div class="home-account-data justify-center items-center" v-else-if="!loading && showLength === 0">
                            <span>{{$t('MobileMyLand.topic1')}}</span>
                            <span class="text-blue-500 text-md mt-3 cursor-pointer" @click="$router.push('/user/land-market')">{{$t('MobileMyLand.topic2')}}</span>
                        </div>
                    </div>
                    <!-- 分页 -->
                    <el-pagination
                            @size-change="pageSizeChange"
                            @current-change="pageCurrentChange"
                            background
                            :current-page.sync="currentPage"
                            :page-sizes="[10, 20, 30, 50]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>

    <!-- 挂牌弹框-显示和隐藏状态根据 smodal 变量决定 -->
        <div class="land-sell-modal" v-if="smodal">
            <div class="land-sell-modal-box">
                <!-- 标题 -->
                <div class="land-sell-modal-box-title">
                    <span>{{$t('LandMap3.listtitle')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" @click="closeSaleModal" class="cursor-pointer">
                    </div>
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('useraccount.merch')}}</div>
                    <input type="text" v-model="salename" maxlength="20" show-word-limit class="land-sell-modal-box-items-input" :placeholder="$t('all.default') + ':' + $t('LandSellDetail.name') + '+ id'">
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.buytype')}}</div>
                    <el-select v-model="type" :placeholder="$t('LandMap3.pleasechoose')" style="width: 200px;margin-left:10px;">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- 单位土地价格 -->
                <div class="land-sell-modal-box-items" v-if="!isSeg">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.unitprice')}}</div>
                    <input type="number" v-model="saleUnitPrice" maxlength="10" show-word-limit class="land-sell-modal-box-items-input" @input="changeSellPrice">
                </div>
                <!-- 单位土地块数 -->
                <div class="land-sell-modal-box-items" v-if="!isSeg">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.unitnumber')}}</div>
                    <div class="land-sell-modal-box-items-span">
                        <span class="text-red-500">{{ selectunitland.landblocks }}</span>
                        <span class="ml-2">{{$t('LandMap3.unitunit')}}</span>
                    </div>
                </div>
                <!-- 售价 -->
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.totalpeice')}}</div>
                    <input type="number" v-model="sellprice" maxlength="15" show-word-limit class="land-sell-modal-box-items-input" @input="changeSaleUnitPrice">
                </div>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <span style="font-size: 10px;color: red;margin-top: 8px;">*{{$t('LandMap3.listtip1')}}</span>
                <span style="font-size: 10px;color: red;">*{{$t('LandMap3.listtip2')}}</span>
                <span style="font-size: 10px;color: red;">*{{$t('LandMap3.listtip3')}}</span>
                <button class="land-sell-button" @click="sellFunction">{{$t('LandMap3.ok')}}</button>
            </div>
        </div>
        <!-- 修改价格的弹窗，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box" v-if="needCode">
                <span>{{$t('all.verifyId')}}</span>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-input v-model="newprice" class="mt-5" :placeholder="$t('LandSellDetail.pricenum')"></el-input>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('LandSellDetail.changeprice')}}</span>
                <el-input v-model="newprice" class="mt-5" :placeholder="$t('LandSellDetail.pricenum')"></el-input>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
        </div>
        <!-- 二次确认下架商品的弹窗 -->
        <div class="developer-common-modal" v-if="bmodal">
            <div class="developer-common-modal-box" v-if="needCode">
                <span>{{$t('all.verifyId')}}</span>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('all.removeNow')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('LandSellDetail.alertunsale')}}</span>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="sellFunction">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
        </div>


  </div>
</template>

<script>
export default {
    mounted() {
        this.$parent.adjustBoxHeight('100%');
        this.$http.post('/v1/myland/show-myland',{
            status: this.whichtab,
            pagenum: 1,
            pagesize: this.pageSize
        }).then(res => {
            this.myLand = res.data.data.rows;
            this.showLength = res.data.data.total;
            this.loading = false;
        }).catch(error => {
            this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
        });
    },
    watch: {
        value(n) {
            this.currentPage = 1;
            this.loadOrder();
        },
    },
    data() {
        return {
        loading: true, // 控制骨架屏
        showLength: 0, // 条数
        currentPage: 1, // 页码
        pageSize: 10, // 每页条数
        whichtab: '未挂牌',
        myLand: [], // 显示的我的土地列表信息
        tradeIndex: -1, // 鼠标悬浮的位置
        pickerOptions: {
            shortcuts: [{
            text: this.$t('LandSellList.tip2'),
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
            }
            }, {
                text: this.$t('LandSellList.tip3'),
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: this.$t('LandSellList.tip4'),
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
                }
            }]
            },
            value: '',

            mode: true, // 查看模式和批量选择模式，true为查看，false为批量
            unitlandids: [], // 批量选择模式选中的分块土地编号集
            segChecked: [], // 批量选择模式选择坐标集
            checkAll: false, // 全选属性
            isIndeterminate: false,
            allUnitlandids: [], // 当前页全部分块土地编号集

            vmodal: false, // 修改售价弹窗
            newprice: '', // 价格
            bmodal: false, // 下架弹窗

            smodal: false, // 挂牌弹窗
            type: '立即购买', // 挂牌-购买形式
            options: [{value: '立即购买',label: this.$t('LandMap3.buytype1')},], // 挂牌-购买形式的选择集
            saleUnitPrice: 0, // 单位土地售价
            sellprice: 0, // 售价
            salename: '', // 设置的商品名称

            isSeg: false, // 是否为批量触发事件
            selectunitland: null, // 单个商品触发事件存储的id

            needCode: true, // 是否需要验证码
            code: '', // 挂牌验证码
            timeCount: 0, // 倒计时
            timer: null, // 定时器
        };
    },
    methods: {
        tabsControl(n) { // 点击选项头选中此页面
            this.mode = true;
            this.checkAll = false;
            this.unitlandids = [];
            this.isIndeterminate = false;
            this.whichtab = n;
            this.currentPage = 1;
            this.pageSize = 10;
            this.value = '';
            this.loadOrder();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'sell-list-tab-select'
            } else {
                return
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loadOrder();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            this.loadOrder();
        },
        // 处理一下时间字符串
        manageTime(time) {
            return time.split('T')[0];
        },
        loadOrder() {
            this.loading = true;
            this.segChecked = [];
            // 这个页面有两个筛选：日期筛选和页数筛选
            let data = {
                status: this.whichtab,
                pagenum: this.currentPage,
                pagesize: this.pageSize
            };
            if (this.value !== '') {
                data["starttime"] = this.value[0];
                data["endtime"] = this.value[1];
            }
            this.$http.post('/v1/myland/show-myland',data)
            .then(res => {
                this.myLand = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.allUnitlandids = res.data.data.rows.map((item) => {
                    this.segChecked.push(false);
                    return item.id;
                });
                this.loading = false;
            }).catch(error => {
                this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
            });
        },
        gotoLandDetail(unitlandid) {
            this.$router.push('/user/property/my-land/detail?land=' + unitlandid);
        },
        gotoLand(originallandid,area,unitlandid) { // 进入土地块
            this.$router.push({
                path:'/user/land-operate/area-map?land=' + originallandid + '&area=' + area + '&id=' + unitlandid})
        },

        // 批量选择
        changeCheckBoxValue(boolean,value) {
            if (boolean) {
                this.unitlandids.push(value);
            } else {
                this.unitlandids = this.unitlandids.filter(item => item !== value);
            }
            let checkedCount = 0; // 初始化计数器
            this.segChecked.forEach(item => {
                if (item === true) {
                    checkedCount++;
                }
            });
            this.checkAll = (checkedCount === this.pageSize);
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.pageSize;
        },
        handleCheckAllChange(val) {
            this.segChecked = [];
            if (val) {
                this.unitlandids = this.allUnitlandids;
                for (let i = 0;i < this.pageSize;i++) {
                    this.segChecked.push(true);
                }
            } else {
                this.unitlandids = [];
                for (let i = 0;i < this.pageSize;i++) {
                    this.segChecked.push(false);
                }
            }
            this.isIndeterminate = false;
        },
        // 取消批量
        cancelSeg() {
            this.mode = true;
            this.checkAll = false;
            this.unitlandids = [];
            this.segChecked = [];
            for (let i =0;i < this.pageSize;i++) {
                this.segChecked.push(false);
            }
            this.isIndeterminate = false;
        },
        // 打开批量模式弹窗
        openSellModalSeg(tab) {
            this.isSeg = true;
            if (this.unitlandids.length < 1) {
                this.$toast.fail(this.$t('LandSellList.tip9'));
                return;
            }
            if (tab === 1) { // 挂牌模式
                this.codeNeedOrNot('挂牌');
            } else if (tab === 2) { // 下架模式
                this.codeNeedOrNot('下架');
            } else if (tab === 3) { // 修改价格模式
                this.codeNeedOrNot('修改价格');
            }
        },
        // 打开单土地模式弹窗
        openSellModal(tab,value) {
            this.isSeg = false;
            this.selectunitland = value;
            if (tab === 1) { // 挂牌模式
                this.codeNeedOrNot('挂牌');
            } else if (tab === 2) { // 下架模式
                this.codeNeedOrNot('下架');
            } else if (tab === 3) { // 修改价格模式
                this.codeNeedOrNot('修改价格');
            }
        },

        // 挂牌弹窗修改单位土地价格时总价格也会变
        changeSellPrice() {
            this.sellprice = this.saleUnitPrice * this.selectunitland.landblocks;
        },
        changeSaleUnitPrice() {
            if (this.selectunitland) {
                let quotient = this.sellprice / this.selectunitland.landblocks;
                this.saleUnitPrice = quotient.toFixed(2);
            }
        },

        // 获取验证码前打开弹窗需要检测是否需要验证码
        codeNeedOrNot(tab) {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/if-operation')
                .then(res => {
                    loadingToast.clear();
                    this.needCode = res.data.data;
                    if (tab === '挂牌') {
                        this.smodal = true;
                    } else if (tab === '下架') {
                        this.bmodal = true;
                    } else if (tab === '修改价格') {
                        this.vmodal = true;
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        // 获取验证码
        getVerificationCode() {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/verification-code')
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.startCountdown();
                        if (res.data.data === '邮箱验证码发送成功') {
                            this.$toast.success(this.$t('LandSellList.sendemail'));
                        } else {
                            this.$toast.success(this.$t('LandSellList.sendphone'));
                        }
                    } else {
                        this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.timeCount = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                    this.timeCount--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        // 三种事件，两种模式，六种触发
        sellFunction() {
            if (this.isSeg) { // 批量
                if (this.vmodal) { // 修改价格弹窗开启中
                    this.changePrice(this.unitlandids);
                } else if (this.bmodal) { // 下架弹窗开启中
                    this.unsale(this.unitlandids);
                } else if (this.smodal) { // 挂牌弹窗开启中
                    this.sellLand(this.unitlandids);
                }
            } else {
                if (this.vmodal) { // 修改价格弹窗开启中
                    this.changePrice([this.selectunitland.id]);
                } else if (this.bmodal) { // 下架弹窗开启中
                    this.unsale([this.selectunitland.id]);
                } else if (this.smodal) { // 挂牌弹窗开启中
                    this.sellLand([this.selectunitland.id]);
                }
            }
        },
        // 挂牌事件
        sellLand(unitlandids) {
            let data = {
                unitlandids: unitlandids,
                salesmethods: this.type,
                pricenum: this.sellprice,
            }
            if (needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            if (this.salename !== '') {
                data["commodityname"] = this.salename;
            }
            this.$http.post('/v1/sale/add-sale-list',data)
            .then(res => {
                if (res.data.status === 200) {
                    this.$toast.success(this.$t('LandMap3.saleSuccess'));
                    window.location.reload(); // 刷新页面
                } else {
                    this.$toast.fail(this.$t('LandMap3.saleFail'));
                }
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.neterror'));
            });
        },
        // 关闭挂牌弹窗
        closeSaleModal() {
            this.saleUnitPrice = '';
            this.sellprice = '';
            this.salename = '';
            this.newprice = '';
            this.code = '';
            clearInterval(this.timer);
            this.timeCount = 0;
            this.smodal = false;
            this.vmodal = false;
            this.bmodal = false;
        },
        // 下架
        unsale(unitlandids) {
            let data = {
                unitlandids: unitlandids,
            }
            if (needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            this.$http.DELETE('/v1/sale/delete-sale-list',data)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('all.removeSuccessful'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(this.$t('all.removeFail'));
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        // 修改价格
        changePrice(unitlandids) {
            const re = /^(?:(?:0|[1-9]\d*)(?:\.\d+)?|\d+(?:\.\d*)?)$/;
            if (this.newprice !== '' && re.test(this.newprice)) {
                let data = {
                    unitlandids: unitlandids,
                    salesmethods: this.type,
                    pricenum: this.newprice
                }
                if (this.needCode && this.code === '') {
                    this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                    return;
                } else if (this.needCode) {
                    data["code"] = this.code;
                }
                this.$http.put('/v1/sale/update-sale-list',data)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MyInfor.letter19'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                }).catch(error => {
                    this.$toast(this.$t('computerlogin.alertAxiosError'));
                });
            } else {
                this.$toast.fail(this.$t('LandSellDetail.tip4'));
            }
        },
    },
    destroyed() {
        clearInterval(this.timer);
    },
}
</script>

<style scoped>
.content-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
  font-size: 14px;
  color: #3D3D3D;
}
.home-account-col {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}
.home-account-title {
    height: 40px;
    width: 100%;
    min-width: 920px;
    color: rgb(120, 138, 161);
    text-align: center;
}
.home-account-data {
    flex-grow: 1;
    max-height: calc(100% - 200px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
/* 重置日期按钮 */
.reset-button {
  margin-left: 10px;
  width: 100px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #165DFF;
  cursor: pointer;
  font-size: 14px;
  color: #FFFFFF;
}
/* 查看详情 */
.goto-detail {
    cursor: pointer;
    text-decoration: underline;
    color: #476DFF;
    font-size: 14px;
}
@media (max-width: 1220px) or (max-height: 720px) {
    .normal-card-box {
        width: 100%;
        height: 100%;
    }
    .filter {
        margin-top: 8px;
    }
    .sell-list-tabs {
        margin-top: 10px;
        height: 48px;
    }
    .sell-list-tab {
        font-size: 14px;
    }
    .sell-list-tab-select {
        font-size: 16px;
    }
    .home-account-col {
        max-width: calc(100vw - 220px);
    }
    .home-account-data {
        max-height: none;
    }
}
</style>