<template>
    <div class="flex flex-col h-full mobile-base-background2">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileTitleDeed.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="flex-grow w-full overflow-hidden base-p-16px" v-if="id">
            <img :src="titleDeed" class="title-deed">
        </div>
        <div class="flex-grow w-full overflow-hidden flex justify-center items-center" v-else>
            <span class="font-semibold text-3xl">404 NOT FOUND</span>
        </div>
        <div class="w-full py-3 flex items-center justify-end bg-white">
            <button class="px-4 mr-2 py-1 bg-white text-blue-500 text-sm rounded-3xl w-auto" style="border: 1px solid #3662EC;" @click="download">{{$t('MobileTitleDeed.download')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.id = this.$route.params.landid;
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread').then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
        this.$http.get('/v1/myland/show-title-deed?unitlandid=' + this.id)
        .then(res => {
            this.titleDeed = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            id: null, // 从地址上传来的用于后端确定地契的参数
            messages: 0, // 未读消息
            titleDeed: require('../assets/no-image.png'), // 地契的url
        };
    },
    methods: {
        download() {
            if (this.id) {
                const fileUrl = this.titleDeed;
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', 'titledeed.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.$toast.fail(this.$t('MobileTitleDeed.alert'))
            }
        },
    },
};
</script>

<style scoped>
.title-deed {
    width: 100%;
    object-fit: contain;
}
</style>
