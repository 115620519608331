<template>
  <div class="main-container">
      <div class="goback-parent my-10" style="width:900px;" @click="$router.go(-1)">
        <span class="text-2xl">{{ '<' }}</span>
        <span class="ml-2">{{$t('all.topup')}}</span>
      </div>
      <TopUp></TopUp>
  </div>
</template>

<script>
import TopUp from './TopUp.vue'
export default {
  components: {
    TopUp,
  }
}
</script>

<style>
.goback-parent {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  color: #3D3D3D;
  font-family: Alibaba PuHuiTi 2.0;
  cursor: pointer;
}
@media (max-width: 980px) or (max-height: 980px) {
    .goback-parent {
        justify-content: center;
    }
}
</style>