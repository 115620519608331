<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>平台管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">操作日志信息查询列表</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('')" @click="tabsControl('')">
                        <span @mouseover="changeCursor">全部</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('用户管理')" @click="tabsControl('用户管理')">
                        <span @mouseover="changeCursor">用户管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('企业管理')" @click="tabsControl('企业管理')">
                        <span @mouseover="changeCursor">企业管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('挂牌管理')" @click="tabsControl('挂牌管理')">
                        <span @mouseover="changeCursor">挂牌管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('订单管理')" @click="tabsControl('订单管理')">
                        <span @mouseover="changeCursor">订单管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('聊天记录管理')" @click="tabsControl('聊天记录管理')">
                        <span @mouseover="changeCursor">聊天记录管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('分块土地管理')" @click="tabsControl('分块土地管理')">
                        <span @mouseover="changeCursor">分块土地管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('原始土地管理')" @click="tabsControl('原始土地管理')">
                        <span @mouseover="changeCursor">原始土地管理</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('平台管理')" @click="tabsControl('平台管理')">
                        <span @mouseover="changeCursor">平台管理</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">管理员编号:</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入管理员编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">管理员职称:</span>
                            <el-select style="width:256px;" v-model="search.administratorrole" placeholder="请选择">
                                <el-option v-for="item in restaurants2" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">操作时间:</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadUser">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetUser">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="3"><span>操作时间</span></el-col>
                        <el-col :span="3"><span>管理员编号</span></el-col>
                        <el-col :span="3"><span>管理员名称</span></el-col>
                        <el-col :span="3"><span>管理员职称</span></el-col>
                        <el-col :span="2"><span>操作类型</span></el-col>
                        <el-col :span="10"><span>操作详情</span></el-col>
                    </el-row>
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in userArray" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.time" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.time }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.administratorid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.administratorid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.administratorname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.administratorname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.administratorrole" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.administratorrole }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.operatetype" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.operatetype }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="10">
                                    <el-tooltip class="item" :content="apply.specificoperate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.specificoperate }}</span>
                                    </el-tooltip>
                                </el-col>
                            </div>
                        </el-row>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            users: (state) => state.admin.adminInfo,
        }),
    },
    mounted() {
        this.$http.post('/v1/administrator/select-logsmanage-list', {
            operatetype: "",
            pagesize: this.pageSize,
            pagenum: this.currentPage
        }).then(res => {
            this.userArray = res.data.data.rows;
            this.showLength = res.data.data.total;
        }).catch(error => {
            this.$toast.fail("初始化失败，请刷新页面或重新登录");
        });
    },
    data() {
      return {
        userNormal: [{
            "id": 25,
                "administratorid": "10002",
                "administratorname": "高级管理员",
                "administratorrole": "高级管理员",
                "operateid": "c55468def68d414ea9cc4b74b573e52e",
                "operatetype": "用户管理",
                "operateproject": "封禁账号",
                "sort": "用户",
                "specificoperate": "封禁用户（用户ID：c55468def68d414ea9cc4b74b573e52e）,封禁时长3天",
                "time": "2023-10-11T13:59:37.738353",
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0
        }], // 测试数据，正常用户数据

        userArray: [], // 用于显示的数组，根据需要显示
        showLength: 0, // 所有数据的长度
        
        whichtab: '',
        pageSize: 50, // 每页条数
        currentPage: 1,// 页码

        search: {
            id: '',
            administratorrole: '不限',
            date: '',
        },
        restaurants1: [],
        restaurants2: [],
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            this.currentPage = 1;
            this.loadUser();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        loadUser() { // 更新显示的数据
            let data = {
                operatetype: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.id !== '') {
                data["administratorid"] = this.search.id;
            }
            if(this.search.administratorrole !== '不限') {
                data["role"] = this.search.administratorrole;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }

            this.$http.post('/v1/administrator/select-logsmanage-list', data)
            .then(res => {
                this.userArray = res.data.data.rows;
                this.showLength = res.data.data.total;
            }).catch(error => {
                // 处理错误
                this.$toast.fail("加载失败，请重试或重新登录");
            });
        },
        resetUser() {
            this.search.id = '';
            this.search.administratorrole = '不限'
            this.search.date = '';
            this.loadUser();
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loadUser();
        },
        pageCurrentChange(val) {
            // 更新页距
            this.currentPage = val;
            this.loadUser();
        },
    },
}
</script>

<style scoped>
</style>