<template>
    <div class="mobile-email-home">
        <div class="flex flex-row items-center mobile-base-back">
            <img src="../mImage/login-back.png" class="justify-start" style="height:25px;width:25px;" @click="goBack">
            <div class="mobile-goback-title">{{$t('MobileServiceQ.title')}}</div>
        </div>
        <div class="rounded-lg shadow-lg bg-white pt-3 pb-5 mx-3 mt-3 animate__animated animate__fadeInUp flex flex-col items-center justify-center" v-if="beforeSend" style="height:80%">
            <van-field v-model="inputText" rows="15" autosize :label="$t('MobileServiceQ.message')" type="textarea" maxlength="1000" :placeholder="$t('MobileServiceQ.placeholder')" show-word-limit style="flex-grow:1" />
            <van-uploader v-model="fileList" multiple :after-read="afterRead" :max-count="1" ref="imageFile" />
        </div>
        <div class="flex-shrink-0 fixed bottom-2 left-0 right-0 flex justify-center" v-if="beforeSend">
            <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="sendEmail">{{$t('MobileServiceQ.send')}}</button>
        </div>
        <!-- 发送完成后显示信息 -->
        <div class="mobile-email-aftersend" v-if="!beforeSend">
            <span>{{$t('MobileServiceQ.text1')}}</span>
            <span class="mt-5">{{$t('MobileServiceQ.text2')}}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            threadId: '', // 主题id
            inputText: "", // 用户输入的内容
            fileList: [
                // Uploader 根据文件后缀来判断是否为图片文件
                // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
            ],
            imageFile: null, // 存储的图片文件
            timer: null, // 定时器
            beforeSend: true, // 发送完成后显示已发送，该参数为false时隐藏输入框和按钮
        };
    },
    methods: {
        handleKeyup() {
            const textarea = this.$refs.myInput;
            textarea.scrollTop = textarea.scrollHeight;
        },
        afterRead(file) {
            // 将图片文件存入data中，后续上传服务器
            this.imageFile = file.file;
        },
        sendEmail() {
            if (this.inputText === '' && this.fileList.length > 0) { // 只传图片
                // 将文件转化为 FormData
                const formData = new FormData();
                formData.append('picture', this.imageFile);
                this.$http.post("/v1/csmail/cswrite-picture", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then((res) => {
                        this.$toast.success(this.$t('MobileServiceQ.alertSendSuccessfully'));
                        this.threadId = res.data.data;
                        this.beforeSend = false;
                    })
                    .catch((error) => {
                        this.$toast.fail('error')
                        console.log(error.data);
                    });
            } else if (this.inputText !== '' && this.fileList.length === 0) { // 只传文本
                // 发送信息
                const data = {
                    emailscontent: this.inputText,
                    threadid: this.threadId,
                };
                this.$http.post("/v1/csmail/cswrite-message", data)
                    .then((res) => {
                        this.$toast.success(this.$t('MobileServiceQ.alertSendSuccessfully'));
                        this.threadId = res.data.data;
                        this.beforeSend = false;
                    })
                    .catch((error) => {
                        this.$toast.fail('error')
                        console.log(error.data);
                    });
            } else if (this.inputText !== '' && this.fileList.length > 0) { // 两者都传
                // 先上传文本
                const data = {
                    emailscontent: this.inputText,
                    threadid: this.threadId,
                };
                this.$http.post("/v1/csmail/cswrite-message", data)
                    .then((res) => {
                        this.threadId = res.data.data;
                        this.beforeSend = false;
                    })
                    .catch((error) => {
                        this.$toast.fail('error')
                        console.log(error.data);
                    });
                this.timer = setInterval(() => {  // 等待1秒后上传图片
                    if (!this.beforeSend) {
                        const formData = new FormData();
                        formData.append('picture', this.imageFile);
                        formData.append('serviceid', this.threadId);
                        console.log(this.threadId,formData)
                        this.$http.post("/v1/csmail/cswrite-picture", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((res) => {
                            clearInterval(this.timer);
                            this.timer = null;
                        }).catch((error) => {
                            this.$toast.fail('error')
                            console.log(error.data);
                            clearInterval(this.timer);
                            this.timer = null;
                        });
                    }
                }, 1000);
            }
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    /* 生命周期结束时清除计时器 */
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: hidden;
}
/* ---------------------------------发送完成后清理页面------------------------------- */
.mobile-email-aftersend {
    width: 100%;
    height: calc(100% - 46px);
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 20px;
    font-weight: bold;
    color: #dcdcdc;
    background-color: #fff;
    flex-direction: column;
}
</style>
