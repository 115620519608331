<template>
  <MobilePaySuccess v-if="isMobile"></MobilePaySuccess>
  <PcPaySuccess v-else></PcPaySuccess>
</template>

<script>
import MobilePaySuccess from '../mobile/MobilePaySuccessful.vue'
import PcPaySuccess from '../normalUsers/PaySuccess.vue'
export default {
  components: {
    MobilePaySuccess,
    PcPaySuccess,
  },
  created() {
    // 根据请求头信息判断是否为手机端
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    /* this.$i18n.locale = localStorage.getItem('language') */
    document.title = this.$t('pageTitle')
  },
  data() {
    return {
      isMobile: false,
    }
  },
}
</script>

<style>

</style>