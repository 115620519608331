<template>
    <div class="mobile-homepage-home">
        <div class="mx-3 my-3" ref="imgBack">
            <van-swipe :autoplay="3000" class="rounded-lg">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <img v-lazy="image"/>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="flex flex-col w-full rounded-t-xl pb-2" style="background-color: #F4F5F9;">
            <div class="flex flex-row items-center w-full mt-3 px-3">
                <div class="flex flex-row items-center">
                    <img src="../mImage/hot.png" style="height:16px;width:16px;">
                    <span style="font-size: 16px;font-weight: 600;margin-left: 6px;">{{$t('homePage.title')}}</span>
                </div>
                <div class="flex-grow flex justify-end">
                    <img src="../mImage/home-mode.png" style="height:16px;width:16px;" @click="mode = !mode">
                </div>
            </div>
            <div v-if="!loaded">
                <van-skeleton avatar  title :row="2" />
            </div>
            <div v-else>
                <div v-if="mode" class="flex justify-between flex-wrap px-3 pb-5"><MobileHomeLand1 class="w-1/2 mt-3" style="width:48%;" v-for="(landinfor,index) in LandInfor" :key="index" :LandInfor="landinfor" ref="MobileLand"></MobileHomeLand1></div>
                <div v-else><MobileHomeLand2 class="mt-3" v-for="(landinfor,index) in LandInfor" :key="index" :LandInfor="landinfor" @turnToDetail="turnToDetail" ref="MobileLand"></MobileHomeLand2></div>
            </div>
        </div>
        <van-popup v-model="showPop" class="mobile-message-pop-back" v-if="showPop">
            <span class="mobile-system-message-title">{{$t('ChatMain.systemTitle')}}</span>
            <div class="mobile-system-message-announcement">
                <div class="mobile-system-message-announcement-element" v-for="(apply, index) in system" :key="index" v-show="!apply.read">
                    <span class="mobile-system-message-date">{{ apply.createdAt }}</span>
                    <div class="mobile-system-message-content">{{ apply.content }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import MobileHomeLand1 from "./MobileHomeLand.vue";
import MobileHomeLand2 from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        MobileHomeLand1, // 挂牌土地信息组件
        MobileHomeLand2, // 挂牌土地信息组件
    },
    created() {
        this.$store.dispatch("user/fetchUserInfo");
    },
    mounted() {
        // 初始化土地市场列表
        this.$http.post('/v1/sale/get-sale-selectlist-recommend',{
            pageSize: this.pageSize,
            pageNum: 1
        })
            .then(response =>
            {
                if(response.data.status === 200) {
                    this.pageNum = 2;
                    this.LandInfor = response.data.data.rows;
                    this.loaded = true;
                }
                else {
                    this.$toast.fail(response.data.message)
                }
            }
        ).catch(error => {
            this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
        })

        // 初始化公告
        this.$http.get('/v1/message/select-system-messages')
            .then(response => {
                this.system = response.data.data;
                if (this.system[0].read === false) { this.showPop = true; }
            }).catch(error => {
                console.log(error)
            })
    },
    data() {
        return {
            loaded:false,
            mode: true, // 推荐商品的显示格式，true为半格，false为满格
            screenWidth: 400,
            pageSize: 20, // 页距
            hasMoreData: true, // 是否还有更多数据可加载
            pageNum: 1, // 页码
            flag: true, // 防抖
            images: [
                require("../images/propagandizeImage1.jpg"),
                require("../images/propagandizeImage2.jpg"),
                require("../images/propagandizeImage3.jpg"),
                require("../images/propagandizeImage4.jpg"),
            ],
            LandInfor: [],
            showPop: false, // 系统消息弹窗
            system: [{
                id: 0,
                title: "",
                content: "",
                createdAt: "",
                recipientIds: "",
                read: false, // true是已读，按理说在聊天记录里的系统消息都是已读
            }], // 显示的单条系统消息
        };
    },
    methods: {
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?land=" + this.LandInfor[index].originallandid + "&merch=" + this.LandInfor[index].id;
            this.$router.push({
                path: newpath,
            });
        },
        handleScroll() {
            let scrollHeight = this.$refs.landMarket.scrollHeight;
            let scrollTop = this.$refs.landMarket.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight <= 0 && this.hasMoreData && this.flag) {
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 300 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 300);
                // 当滚动到底部并且还有更多数据可加载时
                this.loadMoreData();
            }
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http
                .post("/v1/sale/get-saleselect-list",{
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                })
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.mobile-homepage-home {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.mobile-homepage-swipe .van-swipe-item {
    height: 80%;
    width: 80%;
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
}
.mobile-homepage-land {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

/* 弹窗背景图 */
.mobile-message-pop-back {
    height: 500px;
    width: 300px;
    max-height: 70%;
    background-image: url('../images/systemMessage.jpg');
    position: fixed;
    z-index: 99;
}
.mobile-system-message-title {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 24px;
    font-weight: bold;
}
.mobile-system-message-announcement {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 400px;
    padding: 30px;
    overflow: auto;
    position: absolute;
    top: 30px;
}
.mobile-system-message-announcement-element {
    display: flex;
    flex-direction: column;
}
.mobile-system-message-date {
    margin-bottom: 10px;
    white-space: nowrap;
}
.mobile-system-message-content {
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
</style>
