<template>
    <div class="h-full w-full flex flex-col justify-center items-center" style="background: linear-gradient(243deg, #F5EAE6 8%, #ECF0F9 90%);">
        <!-- 返回文本链接 -->
        <div class="goback-parent" style="width:900px;" @click="$router.go(-1)">
            <span class="text-2xl">{{ '<' }}</span>
            <span class="ml-2">{{$t('PcLandDetail.landdetail')}}</span>
        </div>

        <!-- 卖家box -->
        <div class="seller-box" style="margin-top:20px">
            <!-- 卖家头像 -->
            <div class="seller-avatar">
                <img :src="LandInfor.userBusiness.identifier ? LandInfor.userBusiness.userphoto : LandInfor.userBusiness.avatarUrl" class="seller-avatar-img">
            </div>
            <!-- 昵称和联系方式 -->
            <div class="seller-infor">
                <span>{{ $t('MobileServiceAWindow.name') + ':' + LandInfor.userBusiness.identifier ? LandInfor.userBusiness.businessname : LandInfor.userBusiness.username }}</span>
                <span>{{ $t('SellerHome.contacttype') + getSellerInfor() }}</span>
            </div>
            <!-- 右侧按钮 -->
            <div class="seller-contact">
                <button class="infor1-button infor1-button-whiteground" style="margin-left:17px" @click="TalkSeller">{{$t('MobilePurchaseDetails.private')}}</button>
            </div>
        </div>

        <!-- 商品信息框 -->
        <div class="merch-detail-box" style="margin-top:16px">
            <div class="w-full flex flex-row">
                <canvas :height="canvasSize" :width="canvasSize" ref="bigmap" class="map-container" style="border-radius:8px"></canvas>
                <div class="order-detail-infor1-box">
                <span class="infor1-landname">{{ LandInfor.landname }}</span>
                <span class="infor1-landblock">{{ $t('OrderList.l8') }}:{{ 'xxx' }}</span>
                <div class="infor1-price" v-if="$setPayment === 'am'">{{ $t('LandMap3.unitprice') }}:<span style="color: #FA5151;">${{ LandInfor.totalprice }}</span></div>
                <div class="infor1-price" v-else-if="$setPayment === 'zh'">{{ $t('LandMap3.unitprice') }}:<span style="color: #FA5151;">￥{{ LandInfor.totalprice * $exchangeRate }}</span></div>
                <!-- 选择数量 -->
                <div class="infor1-select">
                    <span style="width: 40%;">选择数量</span>
                    <el-input-number size="small" v-model="num" :min="1" :max="484"></el-input-number>
                </div>
                <!-- 是否连贯 -->
                <div class="infor1-select">
                    <span style="width: 40%;">是否连贯</span>
                    <el-radio v-model="radio" :label="true">{{ $t('MobilePurchaseDetails.yes') }}</el-radio>
                    <el-radio v-model="radio" :label="false" style="margin-left: 50px;">{{ $t('MobilePurchaseDetails.no') }}</el-radio>
                </div>
                <div class="flex-grow flex flex-col justify-end">
                    <div class="flex flex-row">
                        <button class="sale-left-button" @click="onBuy">{{$t('MobileLandDetail.buy')}}</button>
                        <button class="sale-right-button" @click="OpenSeller">{{$t('SellerHome.title')}}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 下部分三个框 -->
        <div class="flex flex-row" style="margin-top:32px">
            <div class="infor2-box">
                    <van-tabs v-model="tabControl">
                        <van-tab :title="'活动规则'">
                            <pre>{{ '1.用户在当前小区选择' }}</pre>
                        </van-tab>
                        <van-tab :title="$t('MobileLandDetail.origindetail')">
                            <div class="flex flex-col mb-3">
                                <div class="mt-1">
                                    <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landName')}}:</span>
                                    <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.developer')}}:</span>
                                    <span class="text-gray-700 text-sm">{{ LandInfor.userBusiness.identifier ? LandInfor.userBusiness.businessname : LandInfor.userBusiness.username }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.location')}}:</span>
                                    <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coordinate')}}:</span>
                                    <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.length')}}:</span>
                                    <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.width')}}:</span>
                                    <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>
                <!-- 地权解释 -->
                <div class="infor3-box">
                    <span class="text-md font-semibold">{{$t('MobileLandDetail.landRights')}}</span>
                    <div class="flex flex-col mb-3 mt-3">
                        <div class="mt-1 flex flex-row">
                            <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.surface')}}:</span>
                            <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.surfaceIntro')}}</span>
                        </div>
                        <div class="mt-1 flex flex-row">
                            <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.digital')}}:</span>
                            <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.digitalIntro')}}</span>
                        </div>
                    </div>
                </div>
        </div>


        </div>
    </div>
</template>

<script>
export default {
  created() {
    // 不要忘了获取传输过来的土地信息
    this.originallandid = this.$route.query.oid;
    this.communitynumber = this.$route.query.cid;
  },
  mounted() {
    // todo让后端做一个根据原始土地编号和小区编号查询活动内剩余未售出单位土地数量以及原始土地信息
    this.$http.get("/v1/mail/select-onsale-by-saleid?saleid=" + this.unitlandid)
      .then((res) => {
        const response = res.data.data;
        if (response) {
            this.LandInfor = res.data.data;
            let time1 = setTimeout(() => {
                const canvas = this.$refs.bigmap; // 获取 canvas 元素
                canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                clearInterval(time1);
            }, 1000);
        }
      }).catch((error) => {});
  },
  data() {
    return {
      originallandid: null, // url传来的原始土地编号
      communitynumber: null, // url传来的小区编号

      canvasSize: 352, // 画布尺寸
      gridSize: 0, // 网格尺寸

      tabControl: 0, // 第三格土地详情栏tab控制参数，0是土地详情，1是原始土地详情，2是卖家信息
      mode: true, // 图片模式，true为显示小区地图，false为显示原始土地图片

      num: 1, // 选择数量
      radio: true, // 是否连贯

            LandInfor: { // todo关联原始土地表和原始土地所属卖家表
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: this.$t('OriginalLandDetail.l2'),
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
                onSale: {
                    seller: this.$t('MobileLandDetail.none'),
                },
                userBusiness: {
                    identifier: '',
                    businessname: '',
                    businessemail: '',
                    businessphone: '',
                    userphoto: '',
                }
            },
    }
  },
  methods: {
    // 绘制网格
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;
            // 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#000000"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);

            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col + 1;
            const height = end.row - start.row + 1;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "#FF9500";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },

    // 卖家联系方式
        getSellerInfor() {
            if (this.LandInfor.userBusiness.businessemail) {
                return this.LandInfor.userBusiness.businessemail;
            } else if (this.LandInfor.userBusiness.businessphone) {
                return this.LandInfor.userBusiness.businessphone;
            } else {
                return this.$t('MobileLandDetail.none');
            }
        },

        OpenSeller() {
            // 查看卖家
            this.$router.push({
                path: "/user/seller-home?merch=" + this.LandInfor.id + "&seller=" + this.LandInfor.sellerid,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/user/seller-chat?merch=" + this.LandInfor.id,
            });
        },

    // 立即支付-进入确认订单页面
        onBuy() {
            this.$router.push({
                path: "/user/confirm-order",
                name: "UserPcConfirmOrder",
                query: {
                    LandInfor: JSON.stringify([this.LandInfor]),
                    totalPrice: JSON.stringify(this.LandInfor.totalprice),
                },
            });
        },
  },
}
</script>

<style scoped>
/* 商品数据 */
.infor1-price {
    margin-top: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #3D3D3D;
}
.infor1-select {
    margin-top: 15px;
    font-size: 14px;
    color: #B4B4B4;
    display: flex;
    align-items: center;
}
</style>