<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>分块土地管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">分块土地信息列表</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('已挂牌')" @click="tabsControl('已挂牌')">
                        <span @mouseover="changeCursor">已挂牌</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('未挂牌')" @click="tabsControl('未挂牌')">
                        <span @mouseover="changeCursor">未挂牌</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">原始土地编号</span>
                            <el-input style="width:256px;" v-model="search.originallandid" placeholder="请输入原始土地编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">小区编号</span>
                            <el-input style="width:256px;" v-model="search.areaid" :placeholder="canInputAreaid?'请先输入原始土地编号':'格式:区(1,1)'" :disabled="canInputAreaid"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">分块土地编号</span>
                            <el-input style="width:256px;" v-model="search.unitlandid" placeholder="请输入分块土地编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">分块土地名称</span>
                            <el-input style="width:256px;" v-model="search.mergelandname" placeholder="请输入分块土地名称"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">创建时间</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadCompany">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetKey">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content" v-if="loading || showLand.length !== 0">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="3"><span>分块土地编号</span></el-col>
                        <el-col :span="3"><span>分块土地名称</span></el-col>
                        <el-col :span="3"><span>所属原始土地编号</span></el-col>
                        <el-col :span="3"><span>创建时间</span></el-col>
                        <el-col :span="3"><span>区块号</span></el-col>
                        <el-col :span="3"><span>区块内坐标</span></el-col>
                        <el-col :span="3"><span>单位土地块数</span></el-col>
                        <el-col :span="3"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in showLand" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="toString(apply.id)" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.id }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.mergelandname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.mergelandname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.originallandid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.originallandid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.createdate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.createdate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.communitynumber" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.communitynumber }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.landcoordinates" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.landcoordinates }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.landblocks" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.landblocks }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.id)">查看详情</span>
                                    &nbsp;
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="goToLand(apply.id,apply.originallandid,apply.communitynumber)">进入土地</span>
                                </el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        if (localStorage.getItem("admin-unitland")) {
            this.whichtab = localStorage.getItem("admin-unitland")
        }
    },
    mounted() {
        let data = {
            status: this.whichtab,
            pagesize: this.pageSize,
            pagenum: this.currentPage
        }
        this.$http.post('/v1/administrator/select-blocklandmanage-list',data)
            .then(res => {
                this.showLand = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
            });
    },
    watch: {
        'search.originallandid': {
            handler(newVal, oldVal) {
                if (newVal !== '') {
                    this.canInputAreaid = false;
                } else {
                    this.search.areaid = '';
                    this.canInputAreaid = true;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            showLand: [], // 显示的当页公司信息，根据页码页距控制
            showLength: 0, // 长度
            currentPage: 1, // 页码
            pageSize: 50, // 每页条数
            loading: true, // 控制加载项
            whichtab: '已挂牌', // 两种状态-已挂牌，未挂牌
            
            search: {
                unitlandid: '',
                originallandid: '',
                areaid: '',
                mergelandname: '',
                date: '',
            },
            canInputAreaid: true, // 是否可输入小区编号,true是不可输入，false是可输入
            pickerOptions: { // 选择日期的组件代码
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },

            example: { // 已挂牌
                "id": 6003,
                "originallandid": "S725",
                "mergelandname": "工业土地",
                "landnumber": "485",
                "communitynumber": "区(4,5)",
                "landblocks": 36,
                "landcoordinates": "(88, 94) ~ (93, 99)",
                "virtualownership": "00e3f6867e364662bb02475f6fb7b84f",
                "remark": "已合并",
                "status": "已挂牌",
                "createdate": "2023-06-19T13:41:56.914",
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0,
                "originalLand": null,
                "onSale": null
            },
        };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        loadCompany() { // 加载企业列表
            let data = {
                status: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.unitlandid !== '') {
                data["id"] = this.search.unitlandid
            }
            if(this.search.originallandid !== '') {
                data["originallandid"] = this.search.originallandid
            }
            if(this.search.areaid !== '') {
                data["communitynumber"] = this.search.areaid
            }
            if(this.search.mergelandname !== '') {
                data["mergelandname"] = this.search.mergelandname
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0]
                data["endtime"] = this.search.date[1]
            }
            this.$http.post('/v1/administrator/select-blocklandmanage-list',data,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                this.showLand = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail("加载失败，请重试或重新登录");
            });
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            localStorage.setItem("admin-unitland", this.whichtab);
            this.currentPage = 1;
            this.search = {
                unitlandid: '',
                originallandid: '',
                areaid: '',
                mergelandname: '',
                date: ''
            }
            this.loading = true;
            this.loadCompany();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select';
            } else {
                return;
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loading = true;
            this.loadCompany();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            this.loading = true;
            this.loadCompany();
        },
        landReview(id) { // 打开展示详情
            this.$router.push('/admin/unitland-manage/detail?id=' + id);
        },
        goToLand(id,oid,area) { // 进入区块土地
            this.$router.push('/admin/unitland-manage/unitland?id=' + id + '&oid=' + oid + '&area=' + area);
        },
        resetKey() {
            this.search.unitlandid = '';
            this.search.originallandid = '';
            this.search.areaid = '';
            this.search.mergelandname = '';
            this.search.date = '';
            this.currentPage = 1;
            this.loading = true;
            this.loadCompany();
        },
    },
}
</script>

<style scoped>
</style>