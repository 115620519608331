<template>
    <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-2 py-3 mx-3 mt-2 mb-1 relative flex" @click="openSystemModal">
        <div class="flex-shrink-0">
            <img :src="userhead" class="w-12 h-12 rounded-full bg-black" />
        </div>
        <div class="flex flex-col ml-4">
            <div class="flex items-center mb-1">
                <span class="font-semibold text-sm w-40">{{$t('ChatMain.systemTitle')}}</span>
                <span class="text-xs text-gray-500 absolute top-3 right-3">{{ Message.createdAt }}</span>
                <div class="bg-red-600 rounded-full h-5 w-5 flex absolute top-10 right-5" v-if="!Message.read"></div>
            </div>
            <div class="mb-3 text-gray-600 text-sm" style="width:80%">
                <span class="mobile-message-text">{{ Message.content }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        Message: {
            // 包含信息在data查看
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userhead: require("../images/logo.png"),
        };
    },
    methods: {
        openSystemModal() {
            this.$emit('fatherMethod',this.Message);
        },
    },
};
</script>

<style>
.mobile-message-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
</style>