<template>
    <div class="flex items-center rounded-lg hover:bg-gray-200 bg-white px-2 py-3 mx-3 mt-2 relative">
        <van-checkbox v-model="LandInfor.isSelect" v-show="showSelect" @click.stop="SelectThis"></van-checkbox>
        <img :src="LandInfor.unitpicture" class="land-picture" @click="turnToDetail"/>
        <div class="flex-grow" @click="turnToDetail">
            <!-- 商品名称 -->
            <div class="font-bold text-base">{{ LandInfor.landname }}</div>
            <!-- 单位土地个数 -->
            <div class="text-gray-700 mb-2 text-xs">{{$t('ShopCartInfor.num')}}: {{ LandInfor.blocks }}</div>
            <!-- 原始土地名称 -->
            <div class="mt-2">
                <van-tag  round color="#7232dd" class="text-xs px-1" plain>{{ LandInfor.originalLand.originallandname }}</van-tag>
            </div>
            <!-- 经纬度 -->
            <div class="flex items-center rounded-full bg-gray-100 px-1 mt-2" style="width:120px;">
                <img src="../images/locate.png" class="w-3 h-3" alt="" />
                <span  class="ml-1 text-xs">{{  LandInfor.originalLand.geographicalcoordinates }}</span>
            </div>
            <!-- 价格 -->
            <div class="absolute" style="bottom:8px;right:10px;" v-if="LandInfor.status === '已挂牌' || LandInfor.unitLand.status === '已挂牌'">
                <div class="flex items-center">
                    <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                    <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
                </div>
            </div>
        </div>
        <div class="ml-auto hover:bg-gray-300" @click="deleteThis" v-show="showDelete">
            <img src="../images/delete.png" class="h-5 w-5" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        LandInfor: {
            // 包含图片链接image、landName、landPrice、landAddress
            type: Object,
            required: true,
            default:{
                originalLand: {
                    geographicalcoordinates: " ",
                },
            }
        },
        showSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: false,
        },
        index: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {};
    },
    methods: {
        SelectThis() {
            // this.LandInfor.isSelect = !this.LandInfor.isSelect;
            this.$emit("selectThis", this.LandInfor.totalprice, this.LandInfor.isSelect);
        },
        turnToDetail() {
            this.$emit('turnToDetail',this.index);
        },
        deleteThis() {

        },
    },
};
</script>

<style scoped>
.land-picture {
    height: 100px;
    width: 100px;
    border-radius: 12px;
    @apply shadow-lg mr-4 ml-2
}
</style>
