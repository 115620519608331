<template>
    <div class="admin-detail-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>用户管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/user-manage');">用户信息列表</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">查看详情</span>
        </div>
        <div class="admin-detail-form-parent">
            <div class="flex flex-row">
                <div class="admin-detail-form" style="width:50%;">
                    <div class="admin-detail-form-topic">用户信息详情</div>
                    <div class="admin-detail-form-full">
                        <div class="admin-detail-form-half" style="width:100%;">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">注册时间:</span>
                                <span>{{ userInfor.createdate }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">账号id:</span>
                                <span>{{ userInfor.userid }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">用户昵称:</span>
                                <span>{{ userInfor.username }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">邮箱账号:</span>
                                <span>{{ userInfor.useremail?userInfor.useremail:'未绑定' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">手机号码:</span>
                                <span>{{ userInfor.userphone?userInfor.userphone:'未绑定' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">性别:</span>
                                <span>{{ userInfor.usersex?userInfor.usersex:'未设置' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">出生日期:</span>
                                <span>{{ userInfor.birthdate?userInfor.birthdate:'未设置' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <div class="admin-apply-form-item-text">
                                    <span class="admin-apply-form-item-label">头像照片:</span>
                                </div>
                                <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                                    <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                        <img :src="avatarUrl" class="admin-apply-form-item-img-i" @click="this.showImage=true">
                                    </el-tooltip>
                                    <div class="admin-manage-image-modal" v-if="showImage" @click="this.showImage=false">
                                        <img class="full-image" :src="frontIdImg"/>
                                    </div>
                                </div>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="admin-detail-form" style="width:50%;margin-left:20px;">
                    <div class="admin-detail-form-topic">收货地址</div>
                    <div class="admin-detail-form-full">
                        <div class="admin-detail-form-half" style="width:100%;">
                            <div v-for="(address, index) in userInfor.address" :key="index">
                                <el-row class="admin-detail-form-row">
                                    <span class="admin-detail-form-item-label">收货地址:</span>
                                    <span>{{ address.province + address.city + address.area + address.detailedaddress }}</span>
                                </el-row>
                                <el-row class="admin-detail-form-row">
                                    <span class="admin-detail-form-item-label">收件人:</span>
                                    <span>{{ address.consigneename }}</span>
                                </el-row>
                                <el-row class="admin-detail-form-row">
                                    <span class="admin-detail-form-item-label">创建时间:</span>
                                    <span>{{ address.createtime }}</span>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;" v-if="userInfor.status === '封禁中'">
                <div class="admin-detail-form-topic">封禁信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">封禁原因:</span>
                            <span>{{ userInfor.remark }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">封禁开始日期:</span>
                            <span>{{ userInfor.bantime }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">封禁时间:</span>
                            <span>{{ userInfor.bandays }}天</span>
                        </el-row>
                    </div>
                </div>
            </div>
                <!-- <div class="admin-detail-form-button">
                    <button @mouseover="changeCursor" class="admin-detail-form-button-button" @click="banUserModal()" v-if="userInfor.status === '正常'">封禁用户</button>
                    <button @mouseover="changeCursor" class="admin-detail-form-button-button" @click="unlockUserModal()" v-else-if="userInfor.status === '封禁中'">解封用户</button>
                </div> -->
        </div>
        <!-- 这个是用来封禁用户的弹窗，由modal.bmodal控制 -->
        <div class="admin-manage-modal" v-show="modal.bmodal">
            <div class="admin-manage-modal-box">
                <span>确定封禁该账号？</span>
                <div class="flex flex-row mt-5">
                    <span class="flex items-center mr-3">封禁时长:</span>
                    <el-select v-model="selectValue" placeholder="请选择" class="admin-manage-modal-select">
                        <el-option
                            v-for="item in selectArray"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="flex flex-row mt-5">
                    <span class="flex mr-3" style="width:130px;">封禁原因:</span>
                    <el-input type="textarea" :rows="4" placeholder="请输入封禁原因" v-model="banReason"></el-input>
                </div>
                <div style="margin-top: 50px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="banUser" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
        <!-- 这个是用来解禁用户的弹窗，由modal.vmodal控制 -->
        <div class="admin-manage-modal" v-show="modal.vmodal">
            <div class="admin-manage-modal-box" v-if="!modal.showError">
                <span>此账号于</span>
                <span>{{ userInfor.bantime }}</span>
                <span>被封禁 {{ userInfor.bandays }} 天</span>
                <span style="margin-top: 10px;">是否立即解封？</span>
                <div style="margin-top: 50px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="unlockUser" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.userid = this.$route.query.id;
    },
    mounted() {
        this.$http.get('/v1/administrator/select-usermanage-userbyid?userid=' + this.userid)
            .then(res => {
                this.userInfor = res.data.data;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail("初始化失败，请刷新页面或重新登录")
            });
    },
    data() {
        return {
            userid: null, // 订单编号，从网址获取
            userInfor: { // 范例数据
                id: 0,
                userid: "",
                username: "",
                userpassword: "",
                paymentpassword: "",
                useremail: "",
                userphone: "",
                userpart: "",
                userrealname: "",
                usercard: "",
                usersex: "",
                birthdate: "",
                createdate: "",
                updatedate: "",
                status: "",
                remark: null,
                verificationstatus: "",
                userphoto: "",
                isbanned: true,
                bantime: "",
                bandays: 0,
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                userBalance: {
                    balance: 0
                },
                addresss: [
                    {
                        id: null,
                        userid: "",
                        consigneename: "",
                        contactinformation: "",
                        provincecode: "",
                        province: "",
                        citycode: "",
                        city: "",
                        areacode: "",
                        area: "",
                        detailedaddress: "",
                        createtime: "",
                        isdefault: false
                    },
                    {
                        id: null,
                        userid: "",
                        consigneename: "",
                        contactinformation: "",
                        provincecode: "",
                        province: "",
                        citycode: "",
                        city: "",
                        areacode: "",
                        area: "",
                        detailedaddress: "",
                        createtime: "",
                        isdefault: true
                    },
                    {
                        id: null,
                        userid: "",
                        consigneename: "",
                        contactinformation: "",
                        provincecode: "",
                        province: "",
                        citycode: "",
                        city: "",
                        areacode: "",
                        area: "",
                        detailedaddress: "",
                        createtime: "",
                        isdefault: false
                    },
                ]
            }, // 用户数据，从后端获取

            modal: {
                bmodal: false, // 封禁用户的弹窗
                vmodal: false, // 弹窗开关
                showError: false, // true是带按钮的，false是仅提示
            },
            selectValue: '3', // 封禁时间对应的参数
            banReason: '', // 封禁用户原因
            selectArray: [{
                value: '3',
                label: '3天'
            }, {
                value: '7',
                label: '7天'
            }, {
                value: '30',
                label: '1个月'
            }, {
                value: '10y',
                label: '10年'
            }],
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 返回按钮事件 */
        goBack() {
            this.$router.go(-1)
        },
        closeModal() {
            this.modal.vmodal = false;
            this.modal.bmodal = false;
            this.banReason = '';
            this.selectValue = '3';
        },
        banUserModal() {
            this.modal.bmodal = true;
        },
        banUser() {
            this.$http.patch('/v1/administrator/prohibited-users-byid?userid=' + this.userInfor.userid + '&remark=' + this.banReason + '&days=' + this.selectValue)
                .then(res => {
                    this.$toast.success("成功封禁该用户,封禁时间: " + this.selectValue + "天");
                    this.$router.go(-1);
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("封禁失败,请重试或重新登录");
                });
        },
        unlockUserModal() {
            this.modal.vmodal = true;
        },
        unlockUser() {
            this.$http.put('/v1/administrator/manual-unblocking-byid?userid=' + this.userInfor.userid)
                .then(res => {
                    this.$toast.success("成功解封该用户");
                    this.$router.go(-1);
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("解封失败,请重试或重新登录");
                });
        },
    },
}
</script>

<style>
/* 弹窗 */
.admin-manage-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-manage-modal-box {
    padding: 20px 50px;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; 
    border-radius: 12px;
    z-index: 2;
}
.admin-manage-modal-text {
    max-width: 500px; /* 设置文本容器的宽度 */
    line-height: 2;
    overflow-wrap: break-word; /* 标准的word-wrap属性的替代写法 */
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.admin-manage-modal-input {
    padding: 25px;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
}
.admin-manage-modal-select {
    width: 200px;
}
.admin-manage-modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>