import { render, staticRenderFns } from "./MyLandDetail.vue?vue&type=template&id=2151e868&scoped=true&"
import script from "./MyLandDetail.vue?vue&type=script&lang=js&"
export * from "./MyLandDetail.vue?vue&type=script&lang=js&"
import style0 from "./MyLandDetail.vue?vue&type=style&index=0&id=2151e868&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2151e868",
  null
  
)

export default component.exports