<template>
  <div class="h-full w-full flex flex-col justify-center items-center refund-container">
    <!-- 返回文本链接 -->
    <div class="goback-parent" style="width:1200px;" @click="$router.go(-1)">
      <span class="text-2xl">{{ '<' }}</span>
      <span class="ml-2">{{$t('all.refundProgress')}}</span>
    </div>

    <!-- 退款信息框 -->
    <div class="refund-detail-box" style="margin-top:16px">
      <!-- 退款进度条 -->
      <div class="refund-step-box">
        <el-steps :active="activeIndex" :space="266" finish-status="success" align-center>
          <el-step :title="$t('all.applyRefund')"></el-step>
          <el-step :title="$t('all.underReview')"></el-step>
          <el-step :title="$t('all.refundSuccessful')"></el-step>
        </el-steps>
      </div>

      <!-- 订单图鉴第一行 -->
      <div class="drop-filter" style="width:892px">
        <div class="date-drop">{{$t('all.order')}}</div>
        <div class="infor-box">{{$t('all.orderinfor')}}</div>
        <div class="price-box">{{$t('all.price')}}</div>
        <div class="statu-drop">{{$t('all.statu')}}</div>
        <div class="control-box">{{$t('all.control ')}}</div>
      </div>

      <!-- 订单图鉴本体 -->
      <OrderWindow ref="childRef" :order="separateOrder" :mode="2" :balance="balance" style="margin-top:16px"></OrderWindow>

      <!-- 退款原因/退款进度标题 -->
      <div class="refund-reason-title">{{ getRefundTitle() }}</div>

      <!-- 退款进度文本框 -->
      <div class="refund-reason-box" v-if="activeIndex > 0">
        <!-- 第一行-审核结果 -->
        <div class="flex flex-col" v-if="activeIndex > 1">
          <div class="flex flex-row font-14px">
            <span class="font-color-FA5151" v-if="separateOrder.separateOrders[0].orderstatus === '已驳回'">{{ '3、' + $t('all.auditRejected') }}</span>
            <span class="font-color-FA5151" v-else>{{ '3、' + $t('all.auditPass') }}</span>
            <span class="margin-left-70px font-color-B4B4B4">{{ separateOrder.separateOrders[0].lastrefundtime }}</span>
          </div>
          <div class="font-14px font-color-3D3D3D margin-left-20px" v-if="separateOrder.separateOrders[0].orderstatus === '已驳回'">
            <span>{{ $t('all.rejectReason') }}:{{ separateOrder.separateOrders[0].rejectionreason }}</span>
          </div>
        </div>

        <!-- 第二行-审核中 -->
        <div class="flex flex-col mt-4">
          <div class="flex flex-row font-14px">
            <span class="font-color-FA5151">{{ '2、' + $t('all.backAudit') }}</span>
            <span class="margin-left-70px font-color-B4B4B4">{{ separateOrder.separateOrders[0].refundtime }}</span>
          </div>
          <div class="font-14px font-color-3D3D3D margin-left-20px">
            <span>{{ $t('all.wait') }}</span>
          </div>
        </div>

        <!-- 第三行-用户申请退款原因 -->
        <div class="flex flex-col mt-4">
          <div class="flex flex-row font-14px">
            <span class="font-color-FA5151">{{ '1、' + $t('all.refundrequest') }}</span>
            <span class="margin-left-70px font-color-B4B4B4">{{ separateOrder.separateOrders[0].refundtime }}</span>
          </div>
          <div class="font-14px font-color-3D3D3D margin-left-20px">
            <span>{{ $t('all.refundreason') }}:{{ separateOrder.separateOrders[0].refundreason }}</span>
          </div>
        </div>
      </div>

      <!-- 退款原因输入框 -->
      <textarea type="text" cols="6" rows="20" maxlength="100" show-word-limit class="refund-reason-box" v-model="refundreason" :placeholder="$t('MobileRefundDetail.input')" v-if="activeIndex === 0"></textarea>

      <!-- 输入退款原因后申请退款按钮 -->
      <div class="mt-5 flex flex-col" style="width:892px;align-items:flex-end;" v-if="activeIndex === 0">
        <span class="font-14px font-color-3D3D3D">{{ $t('all.refundprice') }}:</span>
        <span class="font-14px font-color-FA5151 mt-3" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
        <span class="font-14px font-color-FA5151 mt-3" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
        <button class="infor1-button infor1-button-blueground mt-3" @click="RefundConfirm">{{ $t('all.submit') }}</button>
      </div>

      <!-- 退款申请被驳回后重新退款按钮 -->
      <button class="infor1-button infor1-button-blueground mt-5" @click="activeIndex = 0" v-if="activeIndex === 3 && separateOrder.separateOrders[0].orderstatus === '已驳回'">{{ $t('all.reapply') }}</button>


    </div>
  </div>
</template>

<script>
import OrderWindow from './OrderWindow.vue'
export default {
  components: {
    OrderWindow,
  },
  created() {
    // 不要忘了获取传输过来的土地信息
    this.orderid = this.$route.query.order;
    this.activeIndex = this.$route.query.index;
  },
  mounted() {
    this.$http.get("/v1/order/select-process-status?separateOrderId=" + this.orderid)
      .then((res) => { // 加载数据调整到符合子组件逻辑的格式
        const LandInfor = res.data.data;
        this.separateOrder.combinedOrderId = LandInfor.separateOrderId;
        this.separateOrder.totalAmount = LandInfor.totalprice;
        this.separateOrder.orderDate = LandInfor.ordertime;
        this.separateOrder.separateOrders.push(LandInfor);
        if (this.activeIndex) {
          return;
        } else if (this.separateOrder.separateOrders[0].orderstatus === '已付款') {
          this.activeIndex = 0;
        } else if (this.separateOrder.separateOrders[0].orderstatus === '退款中') {
          this.activeIndex = 1;
        } else if (this.separateOrder.separateOrders[0].orderstatus === '已驳回') {
          this.activeIndex = 3;
        }
      }).catch((error) => {
        this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
      });
      // 获取余额
      this.$http.get("/v1/recharge/check-balance")
      .then((res) => {
        this.balance = res.data.data.balance;
      })
      .catch((err) => {});
  },
  data() {
    return {
      orderid: null, // url传来的订单编号，用来查询订单信息
      balance: 0,

      activeIndex: null, // 当前进度

      refundreason: '', // 填写的退款原因
      flag: true, // 防抖

      separateOrder: { // 传给子组件订单信息的参数
        combinedOrderId: "",
        totalAmount: 0,
        orderDate: null,
        separateOrders: [],
      },

      separateOrderExample: { // 传给子组件订单信息的参数
        combinedOrderId: "1001-1694160-8528650",
        totalAmount: 1200.0,
        orderDate: null,
        separateOrders: [{
          separateOrderId: "1001-1694160-8528650",
          ordertime: "2024-06-13 14:18:50",
          landname: "黑土地",
          totalprice: 1200.0,
          orderstatus: "退款中",
          refundtime: "2024-06-13 14:18:50",
          refundreason: "用户申请退款原因",
          lastrefundtime: "2023-06-13 14:18:50",
          rejectionreason: "管理员驳回退款原因",
          landpicture: null,
          canceltime: '111',
        },],
      },

      LandInfor: {
        separateOrderId: "1001-1694160-8528650",
        ordertime: "2024-06-13 14:18:50",
        landname: "黑土地",
        totalprice: 1200.0,
        orderstatus: "退款中",
        refundtime: "2024-06-13 14:18:50",
        refundreason: "用户申请退款原因",
        lastrefundtime: "2023-06-13 14:18:50",
        rejectionreason: "管理员驳回退款原因",
        landpicture: null,
        canceltime: '111',
      },
    }
  },
  methods: {
    // 退款原因/退款进度标题
    getRefundTitle() {
      if (this.activeIndex === 0) {
        return this.$t('all.refundreason');
      } else {
        return this.$t('all.refundProgress');
      }
    },
    // 单条独立订单申请退款
        RefundConfirm(){ // 发送退款申请
            if (!this.flag) {
              return;
            }
            if (this.separateOrder.separateOrders.orderstatus !== '已付款' && this.separateOrder.separateOrders.orderstatus !== '已驳回') {
              this.$toast.fail(this.$t('MobileRefundDetail.alertCannotRefund'));
              return;
            }
            if (this.refundreason === '') {
              this.$toast.fail(this.$t('MobileRefundDetail.input'));
              return;
            }
            this.flag = false;
            this.dateTime = new Date().getTime();
            if (this.separateOrder.separateOrders[0].terminationtime > this.dateTime) {
                // todo 开始退款
                const toastLoading = this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                const data = {
                    separateOrderId: this.separateOrder.combinedOrderId,
                    refundreason: this.refundReason
                }
                this.$http.get('/v1/order/request-refund',data).then(response => {
                    toastLoading.clear();
                    this.$toast.success(this.$t('MobileOrderList.alertRefundSuccess'));
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    console.log(error)
                })
            } else {
                this.$toast.fail(this.$t('MobileOrderList.alertRefund'));
            }
        },
  }
}
</script>

<style>
/* 商品数据 */
.refund-detail-box {
  height: 781px;
  max-height: calc(100vh - 275px);
  width: 1200px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: #FFFFFF;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .goback-parent {
        margin-top: 30px;
    }
    .refund-container {
        height: 120%;
        transform: scale(0.8);
        transform-origin: top center;
    }
    .refund-detail-box {
      max-height: 100%;
    }
}
.refund-step-box {
  width: 800px;
  height: 120px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  background: #FFFFFF;
  padding: 32px 0;
}

/* 订单图鉴第一行 */
.drop-filter {
  width: 100%;
  height: 36px;
  border-radius: 8px;
  background: #F4F4F4;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
  font-size: 14px;
  color: #3D3D3D;
}
.date-drop {
  width: 140px;
  text-align: center;
}
.infor-box {
  width: 288px;
  text-align: center;
}
.price-box {
  width: 168px;
  text-align: center;
}
.statu-drop {
  width: 148px;
  text-align: center;
}
.control-box {
  width: 148px;
  text-align: center;
}

.refund-reason-title {
  width: 892px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  color: #3D3D3D;
}
.refund-reason-box {
  width: 892px;
  min-height: 144px;
  max-height: 280px;
  padding: 16px;
  overflow-y: auto;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.font-14px {
  font-size: 14px;
}
.font-color-FA5151 {
  color: #FA5151;
}
.margin-left-70px {
  margin-left: 70px;
}
.font-color-B4B4B4 {
  color: #B4B4B4;
}
.font-color-3D3D3D {
  color: #3D3D3D;
}
.margin-left-20px {
  margin-left: 20px;
}
</style>