<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>原始土地管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">原始土地凭证查看</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-topic">土地凭证信息</div>
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('待审核')" @click="tabsControl('待审核')">
                        <span @mouseover="changeCursor">待审核</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已上线')" @click="tabsControl('已上线')">
                        <span @mouseover="changeCursor">已上线</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已驳回')" @click="tabsControl('已驳回')">
                        <span @mouseover="changeCursor">已驳回</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已下线')" @click="tabsControl('已下线')">
                        <span @mouseover="changeCursor">已下线</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box" v-if="landChild.whichtab === '待审核'">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">原始土地编号</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入原始土地编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">原始土地名称</span>
                            <el-input style="width:256px;" v-model="search.name" placeholder="请输入原始土地名称"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box" v-if="landChild.whichtab === '待审核'">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label" style="margin-top: 4px;">审核类型</span>
                            <el-select  style="width:256px;" v-model="search.status" placeholder="请选择">
                                <el-option v-for="item in restaurants" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">时间范围</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box" style="flex-direction:row" v-else>
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">原始土地编号</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入原始土地编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input ml-5">
                            <span class="admin-manage-search-input-label">原始土地名称</span>
                            <el-input style="width:256px;" v-model="search.name" placeholder="请输入原始土地名称"></el-input>
                        </div>
                        <div class="admin-manage-search-input ml-5">
                            <span class="admin-manage-search-input-label">时间范围</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="loadLand">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetKey">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <el-col :span="3"><span>申请时间</span></el-col>
                        <el-col :span="2"><span>原始土地编号</span></el-col>
                        <el-col :span="3"><span>原始土地名称</span></el-col>
                        <el-col :span="5"><span>所有者ID</span></el-col>
                        <el-col :span="6"><span>详细地址</span></el-col>
                        <el-col :span="3"><span>地理坐标</span></el-col>
                        <el-col :span="2"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in view" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.createdate" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.createdate }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.originallandid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.originallandid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.originallandname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.originallandname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="5">
                                    <el-tooltip class="item" :content="apply.ownerid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.ownerid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="6">
                                    <el-tooltip class="item" :content="apply.detailedaddress" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.detailedaddress }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="3">
                                    <el-tooltip class="item" :content="apply.geographicalcoordinates" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.geographicalcoordinates }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2"><span @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.id,apply.landstatus)">资格审查</span></el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() { // 初始化
        this.loadLand();
    },
    data() {
        return {
            loading: true, // 骨架屏
            currentPage: 1,
            pageSize: 50,
            showLength: 0,
            view: null, // 显示数据存储区

            example: {
                "id": 87,
                "originallandid": "S916",
                "ownershipnumber": "889123654",
                "originallandname": "沙漠之都",
                "detailedaddress": "青岛市",
                "landcertificate": "/usr/local/land/landpicture/USER_10023/e406db1396474d20ba83a3f6f5e2cc09.pdf",
                "warrantydeed": "/usr/local/land/landpicture/USER_10023/22b97fe7570a40feb359290730635197.pdf",
                "ownerid": "ad73795b81d74dacb72f280109143caf",
                "entrance": "东",
                "geographicalcoordinates": "119.75 , 36.14",
                "landlength": "120",
                "landwidth": "150",
                "height": "2",
                "digitalright": "出售数字权",
                "surfaceright": "出售地表权",
                "landpicture": "/image/20230731144249.png",
                "createdate": "2023-05-11 11:10:23",
                "landstatus": "待审核",
                "applicationtype": "上传原始土地",
                "reasonrejection": null,
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0
            },

            search: {
                id: '', // 原始土地编号
                name: '', // 原始土地名称
                status: '不限', // 状态，有已上线，已驳回，已下线三种
                date: '',
            },
            restaurants: [{
                value: '不限',
                label: '不限'
            }, {
                value: '上传原始土地',
                label: '上传原始土地'
            },{
                value: '下线原始土地',
                label: '下线原始土地'
            }], // 状态的选择域
            pickerOptions: { // 选择日期的组件代码
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },


            businessurl: 'http://192.168.1.145:8092/v1/business/see-images?', // 获取企业信息图片的后端接口，还需要value=73663ebd471246a38fac4aef32623037.png、businessid=c55468def68d414ea9cc4b74b573e52e
            landurl: 'http://192.168.1.145:8092/v1/business/see-images?', // 获取土地上传信息图片的后端接口,还需要value=fee424f5de534617b4a575f201d567b2.png&businessid=b64151dc874548e782ebec4fa8f701bf
            pdfurl: 'http://192.168.1.145:8092/v1/business/see-pdffile?',
            landChild: { // 父子组件传值
                whichtab: '待审核', // 选定哪一个页面，这里有四个页面
                landId: '', // 向子组件传入土地编号，以获取土地上传信息
                isReview: true, // 土地上传资格审查详情页是否点入
                companyName: '',
                ownershipCertificate: '',
                companyContact: '',
                companyContactPhone: '',
                frontIdImg: require('../assets/no-image.png'), // 身份证正面照
                isfrontIdImg: false,
                reverseIdImg: require('../assets/no-image.png'), // 身份证反面照
                isreverseIdImg: false,
                landcertificateImg: '', // 土地无贷款证明
                CPRTImg: '', // 公司注册信息及注册证明
                PFCTImg: '', // 平台合同扫描件
                createdate: '', // 企业认证日期


                // 土地信息
                landid: '', // 土地编号
                landname: '', // 土地名称
                landaddress: '', // 详细地址
                longilati: '', // 经纬度
                landtype: '', // 土地类型
                landlength: '', // 土地长度
                landwidth: '', // 土地宽度
                landheight: '', // 海拔
                landdate: '', // 土地上传日期
                landImage: '', // 土地照片
                islandimg: false,
                landUrl: require('../assets/no-image.png'), // 土地照片
            },
        };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        loadLand() {
            let data = {
                landstatus: this.landChild.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if(this.search.id !== '') {
                data["originallandid"] = this.search.id;
            }
            if(this.search.name !== '') {
                data["originallandname"] = this.search.name;
            }
            if(this.landChild.whichtab === '待审核') {
                data["applicationtype"] = this.search.status;
            }
            if(this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }
            this.$http.post('/v1/administrator/select-originallandmanage-list',data)
            .then(res => {
                // 处理返回值
                this.view = res.data.data.rows;
                this.showLength = res.data.data.total;
                this.loading = false;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail("加载失败，请刷新页面或重新登录");
            });
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.landChild.whichtab = n;
            this.landChild.isReview = true;
            this.loading = true;
            this.loadLand();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.landChild.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            this.loading = true;
            this.loadLand();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            this.loading = true;
            this.loadLand();
        },
        landReview(id,statu) {
            if (statu === '已驳回') {
                this.$router.push('/admin/land-manage/detail?id=' + id + '&mode=' + statu);
            } else {
                this.$router.push('/admin/land-manage/detail?id=' + id + '&mode=' + statu);
            }
        },
        resetKey() {
            this.search.id = '';
            this.search.name = '';
            this.search.status = '不限';
            this.search.date = '';
            this.loading = true;
            this.loadLand();
        },
    },
}
</script>

<style>
</style>