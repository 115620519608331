<template>
  <MobilePayFail v-if="isMobile"></MobilePayFail>
  <PcPayFail v-else></PcPayFail>
</template>

<script>
import MobilePayFail from '../mobile/MobilePayFail.vue'
import PcPayFail from '../normalUsers/PayFail.vue'
export default {
  components: {
    MobilePayFail,
    PcPayFail,
  },
  created() {
    // 根据请求头信息判断是否为手机端
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    /* this.$i18n.locale = localStorage.getItem('language') */
    document.title = this.$t('pageTitle')
  },
  data() {
    return {
      isMobile: false,
    }
  },
}
</script>

<style>

</style>