<template>
    <div class="admin-my-container">
        <div class="admin-admin-add-box">
            <span class="admin-admin-add-title">管理员资料</span>
            <div class="flex flex-col justify-center items-center">
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">管理员编号:</span>
                    <span class="admin-admin-add-row-font">{{ adminChild.administratorid }}</span>
                </div>
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">管理员登录账号(昵称):</span>
                    <el-input v-model="login" :placeholder="adminChild.loginname"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">管理员登录密码:</span>
                    <el-input v-model="newPassword" :placeholder="adminChild.loginpassword"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">真实姓名:</span>
                    <el-input v-model="realname" :placeholder="adminChild.administratorname? adminChild.administratorname:'选填'"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">手机号码:</span>
                    <el-input v-model="phone" :placeholder="adminChild.administratorphone"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">管理员职称:</span>
                    <el-radio v-model="adminChild.role" label="普通管理员">普通管理员</el-radio>
                    <el-radio v-model="adminChild.role" label="高级管理员">高级管理员</el-radio>
                    <el-radio v-model="adminChild.role" label="超级管理员">超级管理员</el-radio>
                </div>
                <div class="admin-admin-add-col">
                    <span class="admin-admin-add-row-label">状态:</span>
                    <div class="admin-manage-status-normal" v-if="adminChild.status==='可用'" @click="adminChild.status='不可用'" style="cursor:pointer;">正常</div>
                    <div class="admin-manage-status-stop" v-else @click="adminChild.status='可用'" style="cursor:pointer;">停用</div>
                </div>
                <div class="admin-admin-add-col flex justify-center align-center">
                    <el-button type="info" plain @click="goBack" class="admin-apply-button-1">返回</el-button>
                    <el-button type="primary" @click="change" class="admin-apply-button-1" style="margin-left: 20px;">上传修改</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        adminChild: { // 
            type: Object,
            required: true
        }
    },
    data () {
        return {
            login: '',
            newPassword: '', // 新密码
            realname: '',
            phone: '',
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$parent.closeDetail();
        },
        change() {
            let data = {
                id: this.adminChild.id,
                role: this.adminChild.role,
                status: this.adminChild.status
            }
            if (this.realname !== '') {
                data["administratorname"] = this.realname;
            }
            if (this.login !== '') {
                data["loginname"] = this.login;
            }
            if (this.newPassword !== '') {
                data["loginpassword"] = this.newPassword;
            }
            if (this.phone !== '') {
                data["administratorphone"] = this.phone;
            }
            this.$http.put("/v1/administrator/update-platformmanage-byid", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success("修改成功！");
                        this.$parent.refresh(); // 刷新页面
                    } else {
                        this.$toast.fail(data.data);
                    }
                })
                .catch((error) => {
                    this.$toast.fail("修改失败,请检查网络或重新登录")
                });
        },
    },
}
</script>

<style scoped>
.admin-my-container {
    margin-top: 30px;
    min-height: 80%;
    background-color: #fff;
}
</style>