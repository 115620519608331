<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>挂牌管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/sell-manage');">挂牌列表查看</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">查看详情</span>
        </div>
        <div class="admin-detail-form-parent" style="flex:1;">
            <div class="admin-detail-form" style="height:100%;">
                <div class="admin-detail-form-topic"><span>商品详情</span></div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">商品编号:</span>
                            <span>{{ orderInfor.id }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">商品名称:</span>
                            <span v-if="mode">{{ orderInfor.landname }}</span>
                            <el-input style="width:300px;" v-model="orderInfor.landname" v-else></el-input>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">挂牌时间:</span>
                            <span>{{ orderInfor.createtime }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">分块土地编号:</span>
                            <span>{{ orderInfor.unitlandid }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">分块土地名称:</span>
                            <span>{{ orderInfor.unitLand.mergelandname }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">原始土地编号:</span>
                            <span>{{ orderInfor.originallandid }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">原始土地名称:</span>
                            <span>{{ orderInfor.originalLand.originallandname }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">小区编号:</span>
                            <span>{{ orderInfor.unitLand.communitynumber }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">坐标范围:</span>
                            <span>{{ orderInfor.unitLand.landcoordinates }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">
                                <span class="admin-apply-form-item-label">土地照片:</span>
                            </div>
                            <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                                <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                    <img :src="orderInfor.originalLand.landpicture" class="admin-apply-form-item-img-i" @click="this.modal.image=true">
                                </el-tooltip>
                                <div class="admin-manage-image-modal" v-if="modal.image" @click="this.modal.image=false">
                                    <img class="full-image" :src="frontIdImg"/>
                                </div>
                            </div>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家ID:</span>
                            <span v-if="mode">{{ orderInfor.sellerid }}</span>
                            <el-input style="width:300px;" v-model="orderInfor.sellerid" v-else></el-input>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">卖家昵称:</span>
                            <span>{{ orderInfor.seller }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">商品价格:</span>
                            <span v-if="mode">{{ orderInfor.totalprice }}</span>
                            <el-input style="width:300px;" v-model="orderInfor.totalprice" v-else></el-input>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">单位土地块数:</span>
                            <span>{{ orderInfor.blocks }}块</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-detail-form-item-label">挂牌方式:</span>
                            <span>{{ orderInfor.salesmethods }}</span>
                        </el-row>
                    </div>
                </div>
                <div class="admin-detail-form-inn" v-if="orderInfor.status === '已摘牌' && orderInfor.reasondelisting">
                    <div style="font-size: 20px;margin-bottom: 20px;">该商品已被管理员强制摘牌</div>
                    <el-row class="admin-detail-form-row">
                        <span class="admin-detail-form-item-label">摘牌原因:</span>
                        <span>{{ orderInfor.reasondelisting }}</span>
                    </el-row>
                </div>
                <div class="admin-detail-form-button" v-if="orderInfor.status === '已挂牌'">
                    <button @mouseover="changeCursor" class="admin-detail-form-button-button" @click="banUserModal()" v-if="orderInfor.status === '已挂牌'">强制摘牌</button>
                    <button @mouseover="changeCursor" class="admin-detail-form-button-button ml-4" @click="mode = false" v-show="mode">强制修改</button>
                    <button @mouseover="changeCursor" class="admin-detail-form-button-button ml-4" @click="mode = true" v-show="!mode">取消修改</button>
                    <button @mouseover="changeCursor" class="admin-detail-form-button-button ml-4" @click="change" v-show="!mode">保存</button>
                </div>
            </div>
        </div>
        <!-- 这个是用来强制摘牌的弹窗，由modal.bmodal控制 -->
        <div class="admin-manage-modal" v-show="modal.bmodal">
            <div class="admin-manage-modal-box">
                <div class="flex flex-row">
                    <img src="../adminImage/admin-company-modal.png" class="admin-apply-modal-icon">
                    <span>强制摘牌原因</span>
                </div>
                <el-input type="textarea" :rows="4" class="admin-manage-modal-input" placeholder="请输入摘牌原因" v-model="banReason"></el-input>
                <div style="margin-top: 50px;">
                    <button class="admin-manage-modal-button" @click="banUser" style="margin-right: 30px;">确定</button>
                    <button class="admin-manage-modal-button" @click="closeModal">取消</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.orderid = this.$route.query.merch;
    },
    mounted() {
        this.$http.get('/v1/administrator/select-salemanage-byid?id=' + this.orderid)
            .then(res => {
                this.orderInfor = res.data.data;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail("初始化失败，请刷新页面或重新登录")
            });
    },
    data() {
        return {
            orderid: null, // 订单编号，从网址获取
            mode: true, // true为查看模式和false为修改模式
            orderInfor: { // 范例数据
                "id": 144,
                "originallandid": "S538",
                "unitlandid": "6001",
                "landname": "陆地",
                "sellerid": "b4bcab5f1afa44e8bbba5449afa13d6d",
                "seller": "Mrs.wang",
                "salesmethods": "立即购买",
                "expirydate": "",
                "unitlandprice": null,
                "pricenum": 1301.0,
                "unitlandminprice": null,
                "minimumprice": null,
                "blocks": 3,
                "totalprice": 1301.0,
                "unitpicture": "/image/20230731145802.png",
                "createtime": "2023-06-20 13:41:41.131",
                "reasondelisting": "商品不合格",
                "status": "已摘牌",
                "remark": "1",
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0,
                originalLand: {
                    id: 6006,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 6006,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
            }, // 订单数据，从后端获取
            modal: {
                bmodal: false, // 封禁用户的弹窗
                vmodal: false, // 弹窗开关
                showError: false, // true是带按钮的，false是仅提示
                image: false, // 图片弹窗
            },
            banReason: '', // 摘牌原因
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 返回按钮事件 */
        goBack() {
            this.$router.go(-1)
        },
        closeModal() {
            this.modal.bmodal = false;
            this.banReason = '';
        },
        banUserModal() {
            this.modal.bmodal = true;
        },
        banUser() {
            this.$http.put('/v1/administrator/salemanage-delist', {
                id: this.orderInfor.id,
                reasondelisting: this.banReason
            }).then(res => {
                    this.$toast.success("强制摘牌成功");
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("修改失败,请重试或重新登录");
                });
        },
        change() {
            this.$http.put('/v1/administrator/update-salemanage-byid', {
                id: this.orderInfor.id,
                landname: this.orderInfor.landname,
                totalprice: this.orderInfor.totalprice
            }).then(res => {
                    this.$toast.success("修改成功");
                    window.location.reload(); // 刷新页面
                }).catch(error => {
                    // 处理错误
                    this.$toast.fail("修改失败,请重试或重新登录");
                });
        },
    },
}
</script>

<style>
</style>