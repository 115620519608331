<template>
    <div class="admin-detail-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>分块土地管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/unitland-manage');">分块土地信息列表</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">查看详情</span>
        </div>
        <div class="admin-detail-form-parent">
            <div class="admin-detail-form">
                <div class="admin-detail-form-inn" ref="bigmapcontainer">
                    <div class="admin-detail-form-topic mb-5">分块土地信息</div>
                    <div class="flex flex-row">
                        <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="bg-cover mx-1 mb-3 shadow-lg rounded-lg  border-3 border-gray"></canvas>
                        <div class="flex flex-col ml-10">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">分块土地编号:</span>
                                <span>{{ LandInfor.id }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">分块土地名称:</span>
                                <span>{{ LandInfor.mergelandname }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">小区坐标:</span>
                                <span>{{ LandInfor.communitynumber }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">分块土地坐标范围:</span>
                                <span>{{ LandInfor.landcoordinates }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">单位土地块数:</span>
                                <span>{{ LandInfor.landblocks }}块</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">海拔:</span>
                                <span>{{ LandInfor.originalLand.height }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row" style="margin-top:50px;">
                                <el-button type="primary" class="admin-apply-button-1" @click="gotoLand">进入小区</el-button>
                            </el-row>
                        </div>
                    </div>
                </div>
                <el-divider v-if="LandInfor.status === '已挂牌'"></el-divider>
                <div class="admin-detail-form-inn" style="margin-top: 20px;" v-if="LandInfor.status === '已挂牌'">
                    <div class="admin-detail-form-topic">挂牌商品信息</div>
                    <div class="flex flex-row ml-10 mt-5">
                        <div class="flex flex-col w-2/4">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">上架时间:</span>
                                <span>{{ LandInfor.onSale.createtime }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">挂牌编号:</span>
                                <span>{{ LandInfor.onSale.id }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">卖家编号:</span>
                                <span>{{ LandInfor.onSale.sellerid }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">挂牌方式:</span>
                                <span>{{ LandInfor.onSale.salesmethods }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">单位土地个数:</span>
                                <span>{{ LandInfor.landblocks }}</span>
                            </el-row>
                        </div>
                        <div class="flex flex-col">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">商品名称:</span>
                                <span>{{ LandInfor.onSale.landname }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">商品金额:</span>
                                <span>{{ LandInfor.onSale.totalprice }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">卖家编号:</span>
                                <span>{{ LandInfor.onSale.sellerid }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">卖家昵称:</span>
                                <span>{{ LandInfor.onSale.seller }}</span>
                            </el-row>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="admin-detail-form-inn" style="margin-top: 20px;">
                    <div class="admin-detail-form-topic">原始土地信息</div>
                    <div class="flex flex-row ml-10 mt-5">
                        <div class="flex flex-col w-2/4">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">原始土地编号:</span>
                                <span>{{ LandInfor.originalLand.originallandid }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">原始土地名称:</span>
                                <span>{{ LandInfor.originalLand.originallandname? LandInfor.originalLand.originallandname : '' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">详细地址:</span>
                                <span>{{ LandInfor.originalLand.detailedaddress }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">开发商编号:</span>
                                <span>{{ LandInfor.originalLand.ownerid }}</span>
                            </el-row>
                        </div>
                        <div class="flex flex-col">
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">大门朝向:</span>
                                <span>{{ LandInfor.originalLand.entrance? LandInfor.originalLand.entrance : '' }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">创建时间:</span>
                                <span>{{ LandInfor.originalLand.createdate }}</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">原始土地长度:</span>
                                <span>{{ LandInfor.originalLand.landlength }}英尺</span>
                            </el-row>
                            <el-row class="admin-detail-form-row">
                                <span class="admin-detail-form-item-label">原始土地宽度:</span>
                                <span>{{ LandInfor.originalLand.landwidth }}英尺</span>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.unitlandid = this.$route.query.id;
    },
    mounted() {
        this.canvasSize=this.$refs.bigmapcontainer.clientHeight;
        this.$http.get('/v1/administrator/select-blocklandmanage-byid?id=' + this.unitlandid)
            .then(res => {
                this.LandInfor = res.data.data;
                let time = setTimeout(() => {
                    // 等待 100 毫秒后再绘制
                    const canvas = this.$refs.bigmap; // 获取 canvas 元素
                    canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                    this.drawGrid();
                    this.drawLand();
                    clearInterval(time);
                }, 100);
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail("初始化失败，请刷新页面或重新登录")
            });
    },
    data() {
        return {
            orderid: null, // 订单编号，从网址获取
            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            LandInfor: { // 范例数据
                "id": 6001,
        "originallandid": "S538",
        "mergelandname": "工业土地",
        "landnumber": "485",
        "communitynumber": "区(5,4)",
        "landblocks": 36,
        "landcoordinates": "(10, 8) ~ (15, 13)",
        "virtualownership": "2",
        "remark": "已合并",
        "status": "已挂牌",
        "createdate": "2023-06-19T13:41:56.905",
        "starttime": null,
        "endtime": null,
        "pagesize": 0,
        "pagenum": 0,
        "start": 0,
        "originalLand": {
            "id": 6001,
            "originallandid": "S538",
            "ownershipnumber": "889123654",
            "originallandname": "沼泽之都",
            "detailedaddress": "青岛市",
            "landcertificate": "/usr/local/land/landpicture/USER_10022/8c1d34694ed242f397dabc8a8ad34867.pdf",
            "warrantydeed": null,
            "ownerid": "b4bcab5f1afa44e8bbba5449afa13d6d",
            "entrance": "东",
            "geographicalcoordinates": "120.09 , 36.26",
            "landlength": "120",
            "landwidth": "150",
            "height": "2",
            "digitalright": "出售数字权",
            "surfaceright": "出售地表权",
            "landpicture": "/image/20230731144249.png",
            "createdate": "2023-06-19 13:41:56.905",
            "landstatus": "待审核",
            "starttime": null,
            "endtime": null,
            "pagesize": 0,
            "pagenum": 0,
            "start": 0
        },
        "onSale": {
            "id": 6001,
            "originallandid": "S538",
            "unitlandid": "6001",
            "landname": "陆地",
            "sellerid": "b4bcab5f1afa44e8bbba5449afa13d6d",
            "seller": "Mrs.wang",
            "salesmethods": "立即购买",
            "expirydate": "",
            "unitlandprice": null,
            "pricenum": 1301.0,
            "unitlandminprice": null,
            "minimumprice": null,
            "blocks": 3,
            "totalprice": 1301.0,
            "unitpicture": "/image/20230731145802.png",
            "createtime": "2023-06-20 13:41:41.131",
            "reasondelisting": null,
            "status": "已挂牌",
            "remark": "已合并",
            "starttime": null,
            "endtime": null,
            "pagesize": 0,
            "pagenum": 0,
            "start": 0,
            "originalLand": null,
            "unitLand": null
        }
            }, // 订单数据，从后端获取
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 返回按钮事件 */
        goBack() {
            this.$router.go(-1);
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            console.log(matches)
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        gotoLand() {
            this.$router.push('/admin/unitland-manage/unitland?id=' + this.LandInfor.id + '&oid=' + this.LandInfor.originallandid + '&area=' + this.LandInfor.communitynumber);
        },
    },
}
</script>

<style>
</style>