<template>
  <div class="developer-common-safe">
    <div class="my-infor-container">
      <div class="developer-common-safe-title">{{ $t('MyInfor.letter1') }}</div>
      <div class="user-infor-box">
        <div class="user-avatar">
          <div class="user-avatar-a shadow-md border-gray">
            <img
              :src="avatarUrl?avatarUrl:require('../pImage/account.png')"
              @click="handleClick"
              style="height: 100px; width: 100px"
            />
            <input
              type="file"
              ref="fileInput"
              hidden
              @change="handleFileSelect"
            />
          </div>
          <div class="user-avatar-a shadow-md border-gray user-avatar-icon">
            <img
              src="../adminImage/admin-myinfor-photo.png"
              @click="handleClick"
              style="height: 30px; width: 30px"
            />
          </div>
        </div>
        <div class="user-infor-col">
          <span class="user-infor-row-label"
            >{{ $t('MobileUserEditInfo.username') }}:</span
          >
          <span class="user-infor-row-font" v-if="mode">{{
            users.username ? users.username : $t('MyInfor.letter4')
          }}</span>
          <input type="text" maxlength="10" show-word-limit class="user-infor-input" v-model="users.username" v-else>
        </div>
        <div class="user-infor-col">
          <span class="user-infor-row-label"
            >{{ $t('MyInfor.letter5') }}:</span
          >
          <span class="user-infor-row-font">{{
            users.useremail ? users.useremail : $t('MyInfor.letter9')
          }}</span>
        </div>
        <div class="user-infor-col">
          <span class="user-infor-row-label"
            >{{ $t('MyInfor.letter8') }}:</span
          >
          <span class="user-infor-row-font">{{
            users.userphone ? users.userphone : $t('MyInfor.letter9')
          }}</span>
        </div>
        <div class="user-infor-col">
          <span class="user-infor-row-label"
            >{{ $t('MyInfor.letter11') }}:</span
          >
          <span class="user-infor-row-font" v-if="mode">{{
            users.usersex ? setUserSex(users.usersex) : $t('MyInfor.letter6')
          }}</span>
          <el-radio-group
            style="display: flex; align-items: center"
            :value="sex"
            @input="changeSex"
            v-else
          >
            <el-radio :value="'男'">{{ $t('MyInfor.letter12') }}</el-radio>
            <el-radio :value="'女'">{{ $t('MyInfor.letter13') }}</el-radio>
            <el-radio :value="'隐藏'">{{ $t('MyInfor.letter14') }}</el-radio>
          </el-radio-group>
        </div>
        <div class="user-infor-col">
          <span class="user-infor-row-label">{{ $t('MyInfor.date') }}:</span>
          <span class="user-infor-row-font" v-if="mode">{{
            users.birthdate ? users.birthdate.split('T')[0] : $t('MyInfor.letter6')
          }}</span>
          <el-date-picker
            :value="birthdate"
            type="date"
            :placeholder="$t('MyInfor.choosedate')"
            @change="changeBirth"
            v-else
          ></el-date-picker>
        </div>
        <el-divider></el-divider>
        <div class="user-infor-col">
          <button
            class="user-infor-button"
            @click="mode = !mode"
            v-if="mode"
          >
            {{ $t('MyInfor.letter15') }}
          </button>
          <button class="user-infor-button" @click="mode = !mode" v-else>
            {{ $t('MyInfor.letter16') }}
          </button>
          <button class="user-infor-button ml-10" @click="exit" v-if="mode">
            {{ $t('MyInfor.letter17') }}
          </button>
          <button
            class="user-infor-button ml-10"
            @click="changeInfor"
            v-else
          >
            {{ $t('MyInfor.letter18') }}
          </button>
        </div>
      </div>
    </div>

    <!-- 用户名不可修改的提示弹窗，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box">
                <!-- 标题 -->
                <div class="developer-common-modal-box-title">
                    <div class="flex-grow flex justify-end">
                        <img src="../developer/map-sell-cancel.png" @click="vmodal=false;mode=true;">
                    </div>
                </div>
                <span class="text-center mt-3">{{$t('MobileUserEditInfo.newCannotChange')}}</span>
                <span class="text-center text-xl mt-3">{{ returnDate(lastTime) }}</span>
                <div class="developer-common-buttons">
                    <button class="developer-common-button" @click="vmodal=false;mode=true;">{{$t('MobileConfirmOrder.confirm')}}</button>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
  computed: {
    users() {
      return this.$store.state.user.userInfo;
    },
    avatarUrl() {
      return this.$store.state.user.avatarUrl;
    },
  },
  mounted() {
    if (this.users.birthdate) {
      this.birthdate = this.users.birthdate
    }
    this.$store.dispatch("user/fetchUserInfo");
    this.judgeChangeName();
  },
  data() {
    return {
      mode: true, // 模式切换，查看个人资料和修改个人资料
      sex: this.$t('MyInfor.letter14'), // 性别
      birthdate: '',
      lastTime: null, // 上一次修改用户名的时间，Date类型参数，后端获取
      canChangeName: false, // 判断是否可以修改用户名
      vmodal: false, // 弹窗
    }
  },
  methods: {
    changeCursor(event) {
      // 鼠标移上去时改变样式
      event.target.style.cursor = 'pointer'
    },
    // 获取日期
    returnDate(date) {
      if (date instanceof Date) {
        const YEAR = date.getFullYear();
        const MONTH = date.getMonth();
        const DAY = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
        return YEAR + '-' + MONTH + '-' + DAY;
      } else {
        return date.split('T')[0];
      }
    },
    /* 此处为点击用户头像上传图片的点击事件 */
    handleClick() {
      this.$refs.fileInput.click()
    },
    handleFileSelect(event) {
      // 获取用户选择的文件对象
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('userphoto', file)
      this.$http
        .put('/v1/user/update-userphoto', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          const data = response.data
          if (data.status === 200) {
            this.$store.dispatch('developer/loadAvatar') // 更新开发商头像
          } else {
            console.error(data.msg)
          }
        })
        .catch((error) => {
          console.error(error)
        })
      // 将文件对象转换为 URL
      const url = URL.createObjectURL(file)
      // 更新当前头像 URL
      this.avatarUrl = url
      // 清空 input 内容
      event.target.value = ''
    },
    // 是否可修改用户名
    judgeChangeName() {
      this.$http.get("/v1/user/user/get-updatetime")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        //获取时间计算是否已经过去了一年？
                        this.lastTime = data.data;
                        if (this.lastTime) {
                            const currentTime = new Date();
                            const savedTime = new Date(this.lastTime);
                            const timeDifference = currentTime - savedTime;
                            const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
                            const hasPassedOneYear = timeDifference >= oneYearInMilliseconds;
                            if (hasPassedOneYear) {
                                this.canChangeName = true;
                            } else {
                                this.canChangeName = false;
                            }
                        } else {
                            this.canChangeName = true;
                        }
                    }
                }).catch((error) => {});
    },
    // 获取日期
        returnDate(date) {
            if (date instanceof Date) {} else { return }
            const YEAR = date.getFullYear();
            const MONTH = date.getMonth();
            const DAY = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
            return YEAR + '-' + MONTH + '-' + DAY
        },
    // 修改用户名
    changeInfor() {
      if (!this.canChangeName && this.lastTime) {
        this.vmodal = true;
        return;
      }
      const data = {
        username: this.users.username,
      }
      this.$http
        .put('/v1/business-center/update-userbusiness', data)
        .then((response) => {
          const data = response.data
          if (data.status === 200) {
            this.$toast.success(this.$t('MyInfor.letter19'))
            this.$store.dispatch('developer/fetchUserInfo')
          } else {
            this.$toast.fail(data.data)
          }
        })
        .catch((error) => {
          this.$toast.fail('error')
        })
    },
    // 修改性别
    changeSex() {
      const data = {
        usersex: this.sex,
      }
      this.$http
        .put('/v1/business-center/update-userbusiness', data)
        .then((response) => {
          const data = response.data
          if (data.status === 200) {
            this.$toast.success(this.$t('MyInfor.letter19'))
            this.$store.dispatch('developer/fetchUserInfo')
          } else {
            this.$toast.fail(data.data)
          }
        })
        .catch((error) => {
          this.$toast.fail('error')
        })
    },
    // 修改生日
    changeBirth() {
      const data = {
        birthdate: this.birthdate,
      }
      this.$http
        .put('/v1/business-center/update-userbusiness', data)
        .then((response) => {
          const data = response.data
          if (data.status === 200) {
            this.$toast.success(this.$t('MyInfor.letter19'))
            this.$store.dispatch('developer/fetchUserInfo')
          } else {
            this.$toast.fail(data.data)
          }
        })
        .catch((error) => {
          this.$toast.fail('error')
        })
    },
    exit() {
      //退出登录 /index/exit
      this.$http
        .get('/v1/index/exit')
        .then((response) => {
          const data = response.data
          if (data.status === 200) {
            localStorage.removeItem('vuex')
            this.$router.push('/user')
          } else {
            console.error(data.msg)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    setUserSex(sex) {
      // 英文版需要套一层皮显示性别
      if (this.$i18n.locale === 'en') {
        if (sex === '男') {
          return 'male'
        } else if (sex === '女') {
          return 'female'
        } else if (sex === '隐藏') {
          return 'hidden'
        } else {
          return 'Not Set'
        }
      } else {
        if (sex !== '') {
          return sex;
        } else {
          return '未设置';
        }
      }
    },
  },
}
</script>

<style>
.my-infor-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .my-infor-container {
        transform: scale(0.8);
        transform-origin: top left; /* 确保从中心缩放 */
    }
}
@media (max-width: 980px) or (max-height: 720px) {
    .my-infor-container {
        transform: scale(0.6);
        transform-origin: top left; /* 确保从中心缩放 */
    }
}
.user-infor-cover {
  min-height: 100%;
  width: 100%;
  flex-direction: column;
}
.user-infor-box {
  min-height: 700px;
  min-width: 600px;
  padding: 10px 30px;
  border-radius: 12px;
}
.user-infor-title {
  font-size: 24px;
  font-weight: bold;
}
.user-avatar {
  height: 100px;
  width: 120px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  position: relative;
}
.user-avatar-a {
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.user-avatar-icon {
  position: absolute;
  bottom: 0;
  right: 10px;
}
.user-infor-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}
.user-infor-row-label {
  width: 150px;
  font-size: 20px;
}
.user-infor-row-font {
  font-size: 20px;
  color: #8185a3;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-infor-input {
  width: 180px;
  height: 34px;
  padding: 0 8px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #464646;
  color: #3D3D3D;
}
.user-infor-button {
  height: 36px;
  padding: 7px 20px;
  border-radius: 8px;
  background-color: #165DFF;
  color: #FFFFFF;
}
.circle-image {
  border-radius: 50%;
  overflow: hidden;
}
.circle-image > img {
  width: 150px;
  height: 150px;
}
</style>
