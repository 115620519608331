<template>
    <div class="mobile-detail-home mobile-base-background2">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title" v-if="orderStatus === '已付款'">
                <img src="../mImage/order-1.png" class="mr-2" style="height:22px;width:22px;">
                {{$t('MobilePurchaseDetails.title1')}}
            </div>
            <div class="mobile-goback-title" v-else-if="orderStatus === '待付款'">
                <img src="../mImage/order-2.png" class="mr-2" style="height:22px;width:22px;">
                {{$t('MobilePurchaseDetails.title2')}}
            </div>
            <div class="mobile-goback-title" v-else-if="orderStatus === '已取消'">
                <img src="../mImage/order-3.png" class="mr-2" style="height:22px;width:22px;">
                {{$t('MobilePurchaseDetails.title3')}}
            </div>
            <div class="mobile-goback-title" v-else>
                {{$t('MobilePurchaseDetails.title')}}
            </div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>

        <!-- 待支付剩余时间 -->
        <div class="w-full p-5 pt-0 flex flex-row justify-center items-center" v-if="countdown">{{$t('MobileOrderList.canceltip1')}}<span class="mx-1 text-red-500">{{ countdown.hour }}h{{ countdown.minute }}m{{ countdown.second }}s</span>{{$t('MobileOrderList.canceltip1')}}</div>
        <!-- 退款截止日期 -->
        <div class="w-full p-5 pt-0 flex flex-row justify-center items-center" v-else-if="LandInfor.terminationtime">{{$t('MobileOrderList.refundtip')}}<span class="mx-1 text-red-500">{{ LandInfor.terminationtime }}</span></div>

        <div class="flex-grow overflow-y-auto flex flex-col animate__animated animate__fadeInUp" style="padding: 20px;padding-top:0;">
            <!-- 用户个人地址信息 -->
            <div class="flex flex-col rounded-lg bg-white px-5 py-4 mt-0">
                <div class="flex flex-row items-center">
                    <img src="../mImage/order-detail-user.png" style="height:16px;width:16px;">
                    <span class="text-lg font-semibold ml-3">{{ LandInfor.address.consigneename }}</span>
                    <span class="text-sm ml-3 text-gray-400">{{ LandInfor.address.contactinformation }}</span>
                </div>
                <div class="text-sm text-gray-400 mt-1">{{ LandInfor.address.province + '-' + LandInfor.address.city + '-' + LandInfor.address.area }}</div>
                <div class="text-sm text-gray-400 mt-1">{{ LandInfor.address.detailedaddress }}</div>
            </div>
            
            
            <!-- 商品图鉴 -->
            <div class="flex items-center rounded-lg hover:bg-gray-200 bg-white px-2 py-3 mt-2 mb-2 relative">
                <img :src="LandInfor.unitpicture" class="shadow-lg mr-4 ml-2" style="height:100px;width:100px;border-radius:12px;"/>
                <div class="flex-grow">
                    <!-- 商品名称 -->
                    <div class="font-bold text-base">{{ LandInfor.landname }}</div>
                    <!-- 单位土地个数 -->
                    <div class="text-gray-700 mb-2 text-xs">{{$t('ShopCartInfor.num')}}: {{ LandInfor.blocks }}</div>
                    <!-- 原始土地名称 -->
                    <div class="mt-2">
                        <van-tag round color="#7232dd" class="text-xs px-1" plain>{{ LandInfor.originalLand.originallandname }}</van-tag>
                    </div>
                    <!-- 经纬度 -->
                    <div class="flex items-center rounded-full bg-gray-100 px-1 mt-2" style="width:120px;">
                        <img src="../images/locate.png" class="w-3 h-3"/>
                        <span  class="ml-1 text-xs">{{ LandInfor.originalLand.geographicalcoordinates }}</span>
                    </div>
                    <!-- 价格 -->
                    <div class="absolute" style="bottom:8px;right:10px;">
                        <div class="flex items-center">
                            <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                            <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 挂牌信息 -->
            <div class="relative items-center rounded-lg bg-white px-5 py-4 mt-0 mb-3">
                <div class="absolute top-2 left-3 text-sm text-gray-400">
                    {{$t('MobilePurchaseDetails.infor')}}
                </div>
                <div class="mt-3">
                    <div class="flex justify-between mt-5">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.orderno')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.separateOrderId }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.ordertime')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.ordertime }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.totalprice')}}</p>
                        <p class="text-sm text-right" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</p>
                        <p class="text-sm text-right" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.unitlandid')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.unitlandid }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.landname')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.landname }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.sellerid')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.onSale.seller }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.certificate')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.needpaper?$t('MobilePurchaseDetails.yes'):$t('MobilePurchaseDetails.no') }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.address')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.originalLand.detailedaddress }}</p>
                    </div>
                </div>
            </div>
            <!-- 详细信息及原始土地信息 -->
            <div class="rounded-lg bg-white px-5 mt-0 mb-3">
                <van-tabs v-model="tabControl">
                    <van-tab :title="$t('LandMap3.lefttitle3')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.area')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.landcoordinates }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.landblocks }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('LandSellDetail.origindetail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.landId')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.onSale.seller }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="rounded-lg bg-white px-5 mt-1 mb-1 pt-1">
                <span class="text-sm text-gray-400">{{$t('MobileLandDetail.landRights')}}</span>
                <div class="flex flex-col mb-3">
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobilePurchaseDetails.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobilePurchaseDetails.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobilePurchaseDetails.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobilePurchaseDetails.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full py-3 flex items-center justify-end bg-white">
            <button class="px-4 mr-2 py-1 bg-white text-sm rounded-3xl w-auto" style="box-shadow: 0 0 0 1px #000000" @click="gotoreceipt" v-if="orderStatus==='已付款' || orderStatus==='退款申请被驳回'">{{$t('MobileOrderList.receipt')}}</button>
            <button :class="'px-4 mr-2 py-1 text-sm rounded-3xl w-auto '
            +(orderStatus==='已付款' || orderStatus==='退款申请被驳回' || orderStatus==='待付款'?'text-grey-400 hover:bg-yellow-500 bg-yellow-300 ':'bg-gray-200 text-black')"
                    @click="onRefund" v-if="orderStatus!=='已取消'">
                    {{(orderStatus==='已付款' || orderStatus==='退款申请被驳回')?$t('MobilePurchaseDetails.refund'):$t('MobileOrderList.continue')}}
                    <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                    <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
            </button>
            <button class="px-4 mr-2 py-1 text-white hover:bg-green-600 bg-green-500 text-sm rounded-3xl w-auto"
                    @click="TalkSeller">{{$t('MobilePurchaseDetails.private')}}</button>
        </div>

        <!-- 退款弹窗 -->
        <van-popup v-model="showRefund" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.refundNum')}}</span>
                        <span class="text-gray-600 text-lg my-2" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                        <span class="text-gray-600 text-lg my-2" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md ">{{$t('MobilePurchaseDetails.reason')}}</span>
                        <van-field
                            v-model="refundReason"
                            :placeholder="$t('MobilePurchaseDetails.reasonInput')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                        />
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showRefund = false">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="RefundConfirm">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>

        <!-- 继续支付弹窗 -->
        <van-popup v-model="showContinue" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobileOrderList.continueTitle')}}</span>
                        <span v-if="$setPayment === 'am'">${{LandInfor.totalprice.toFixed(2)}}</span>
                        <span v-if="$setPayment === 'zh'">￥{{(LandInfor.totalprice * $exchangeRate).toFixed(2)}}</span>
                    </div>
                    <div class="grid grid-cols-1 gap-2 text-lg">
                        <!-- 支付方式 -->
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                            <div class="flex items-center">
                                <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.aliPay')}}
                            </div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="aliPay" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                            <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="wechat" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'am'">
                            <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="PayPal"></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10">
                            <div class="flex items-center"><img src="../images/balance.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.balance')}}
                            </div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="balance"></van-radio>
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showContinue = false;">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="onSubmit">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
        <van-popup id="popup" v-model="showPopup" closeable close-icon-position="top-left" position="bottom" round class="pb-14" @close="onClosePop">
            <div class="flex justify-center items-center text-center text-3xl mt-12" >
                <span v-if="$setPayment === 'am'">${{LandInfor.totalprice.toFixed(2)}}</span>
                <span v-if="$setPayment === 'zh'">￥{{(LandInfor.totalprice * $exchangeRate).toFixed(2)}}</span>
            </div>
            <div v-if="!showPasswordInput" class="flex justify-center items-center text-center text-sm text-gray-400 mt-1" >
                <span>{{$t('MobileConfirmOrder.balance')}} <span class="text-red-500 ml-2">{{ balance[0] }}.<span class="text-sm">{{ balance[1] }}</span></span></span>
                <span class="ml-3 text-red-300">{{balance[2] < LandInfor.totalprice?$t('MobileConfirmOrder.insufficientBalance'):''}}</span>
                <router-link to="/usermobile/recharge" class="text-blue-500"> &nbsp;&nbsp;{{$t('MobileConfirmOrder.topup')}}</router-link>

            </div>
            <div class="mx-5" v-if="!showPasswordInput">
                <div class="mt-2">
                    <span class="text-blue-500 ">{{$t('MobileConfirmOrder.mixPay')}}</span>
                    <span v-if="$setPayment === 'am'">${{(LandInfor.totalprice-balance[2]).toFixed(2)}}:</span>
                    <span v-if="$setPayment === 'zh'">￥{{((LandInfor.totalprice-balance[2]) * $exchangeRate).toFixed(2)}}：</span>
                </div>
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <!-- 支付方式 -->
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixAliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixWechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'am'">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixPaypal"></van-radio>
                    </div>
                </div>
            </div>
            <!-- 密码输入框 -->
            <van-password-input
                v-if="showPasswordInput"
                :value="payPassword"
                :focused="showKeyboard"
                :info="$t('MobileConfirmOrder.enterNewPassword')"
                :error-info="errorInfo"
                @focus="onFocusPassword"
                class="mb-60"
            />
            <div v-if="!showPasswordInput" @click="onPay" class="rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileConfirmOrder.pay')}}</span>
            </div>

            <!-- 数字键盘 -->
            <van-number-keyboard
                v-model="payPassword"
                :show="showKeyboard"
                @blur="onBlurPassword"
            />
        </van-popup>
    </div>
</template>
<script>
export default {
    name: "MobilePurchaseDetails",
    created() {
        // 不要忘了获取传输过来的土地信息
        this.orderid = this.$route.query.order;
    },
    mounted() {
        // 获取土地详情信息
        if (this.flag) {
            this.flag = false;
            this.$http
                .get("/v1/order/select-separateorder-one?separateOrderId=" + this.orderid)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.orderStatus = this.LandInfor.orderstatus;
                    this.initializeSelect(); // 初始化计时器
                    /* let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 1000); */
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                const value = Number(res.data.data.balance);
                this.balance = value.toFixed(2).toString().split(".");
                this.balance.push(value);
            })
            .catch((err) => {
                console.log(err);
            });
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            selectPage: 2, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            orderid: null, // 订单id
            flag: true, // 防抖
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            timer: null, // 定时器
            countdown: null, // 定时器剩余时间
            LandInfor: {
                ids: 0,
                separateOrderId: "",
                combinedOrderId: "",
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: "",
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                combinationpay: null,
                buyerid: "",
                sellerid: "",
                addressid: "",
                needpaper: true,
                orderstatus: "",
                canceltime: null, // 待支付剩余时间
                terminationtime: null, // 退款截止日期
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                originalLand: {
                    id: 0,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 0,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 0,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                },
                onSale: {
                    id: 0,
                    originallandid: "",
                    unitlandid: "",
                    landname: "",
                    communitynumber: null,
                    sellerid: "",
                    seller: "",
                    salesmethods: "",
                    expirydate: "",
                    unitlandprice: null,
                    pricenum: 0,
                    unitlandminprice: null,
                    minimumprice: null,
                    blocks: 0,
                    totalprice: 0,
                    unitpicture: "",
                    createtime: "",
                    reasondelisting: null,
                    status: "未挂牌",
                    remark: "",
                    starttime: null,
                    endtime: null,
                    pagesize: 0,
                    pagenum: 0,
                    start: 0,
                    originalLand: null,
                    unitLand: null
                },
            },
            showRefund: false, // 退款弹窗
            refundReason: "", // 退款原因
            orderStatus: '', // 订单状态
            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: 0, // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
            balance: ['0','00',0], // 余额
            showContinue: false, // 继续支付弹窗
            payWay: 'PayPal', // 继续支付支付方式，默认PayPal支付
            orderInfor: null, // 继续支付交易信息
            totalPrice: 0, // 继续支付所需金额
            showPopup: false, // 继续支付余额支付余额不足选择支付方式弹窗
            showPasswordInput: false, // 余额支付密码弹窗
            payPassword: "", // 余额支付密码
            showKeyboard: false, // 键盘
            errorInfo: "", // 错误信息
        };
    },
    methods: {
        initializeSelect() { // 初始化定时器
            if (this.orderStatus !== '待付款') { return; }
            const NOW = new Date().getTime();
            let totalSeconds = Math.floor((parseInt(this.LandInfor.canceltime) - NOW) / 1000);
            let hour = Math.floor(totalSeconds / 3600);
            let minute = Math.floor((totalSeconds % 3600) / 60);
            let second = totalSeconds % 60;
            this.countdown = {
                hour: hour,
                minute: minute,
                second: second,
                totalSeconds: totalSeconds
            }
            this.timer = setInterval(() => {
                this.countdown.totalSeconds--;
                if (this.countdown.totalSeconds <= 0) {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.$http.put("/v1/order/cancel-order?separateOrderId=" + this.orderid).then((res) => {
                        this.$toast.fail(this.$t('OrderList.tip4'));
                        this.$router.go(-1);
                    }).catch((err) => {});
                }
                this.countdown.hour = Math.floor(this.countdown.totalSeconds / 3600);
                this.countdown.minute = Math.floor((this.countdown.totalSeconds % 3600) / 60);
                this.countdown.second = this.countdown.totalSeconds % 60;
            }, 1000);
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;
            // 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#000000"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);

            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "#FF9500";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        onRefund(){
            if (this.orderStatus === '已付款' || this.orderStatus === '退款申请被驳回') {
                this.showRefund = true;
            } else if (this.orderStatus === '待付款') {
                this.showContinue = true;
            } else {
                return;
            }
        },
        RefundConfirm(){
            const date = new Date().getTime();
            if (date > this.LandInfor.terminationtime) {
                this.$toast.fail(this.$t('MobileOrderList.alertRefund'));
                return;
            }
            // 退款确认
            this.$http.post("/v1/order/request-refund", {
                    separateOrderId: this.orderid,
                    refundreason: this.refundReason,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MobilePurchaseDetails.alertSeccess'));
                        this.showRefund = false;
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(res.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.LandInfor.originalLand.originallandid + "&merch=" + this.LandInfor.onSale.id + "&receive=" + this.LandInfor.sellerid,
            });
        },
        gotoreceipt() {
            // todo查看收据
            this.$router.push({
                path: "/mobile/receipt?order=" ,
            });
        },
        onClosePop(){
            this.payWay='balance';
            // 等待500ms
            setTimeout(() => {
                this.showPasswordInput = false;
            }, 500);
        },
        onFocusPassword() {
            this.showKeyboard = true;
            // document.querySelector('#popup').style.height = "80%";
        },
        onBlurPassword() {
            this.showKeyboard = false;
            //删除height的设置
            document.querySelector('#popup').style.height = "";
        },
        /** 提交订单&&正常支付*/
        onSubmit() {
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.LandInfor.address.id,
                ischeck: true,
            };
            if (this.payWay==='balance'){
                this.showContinue = false;
                this.showPopup = true;
                /** 检查余额是否足够*/
                if(this.balance<this.LandInfor.totalprice){
                    this.$toast(this.$t('MobileConfirmOrder.alertNoMoney'));
                }
                /** 余额足够*/
                else {
                    this.showPasswordInput = true;
                }
            } else if (this.payWay === "aliPay") {
                this.$toast(this.$t('MobileConfirmOrder.alertalipay'));
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/alipay/pay", data).then((res) => {
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            } else if (this.payWay === "wechat") {
                this.$toast(this.$t('MobileConfirmOrder.alertWechat'));
            } else if (this.payWay==='paypal') {
                this.$toast(this.$t('MobileConfirmOrder.alertPaypal'));
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/paypal/pay", data).then((res) => {
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            }
        },
        /** 点击余额支付*/
        onPay(){
            const unitlandids = [this.LandInfor.unitlandid];
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.LandInfor.needpaper,
                unitlandid: unitlandids,
                addressid: this.LandInfor.address.id,
                paymentpassword: this.payPassword,
                ischeck: true,
            };
            /** 根据支付方式开始支付*/
            if (this.payWay==='balance'){
                toastLoading.clear();
                this.goPay();
            }
            else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data)
                    .then((res) => {
                        if(res.data.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return;
                        }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
            }
            else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
            }
            else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data)
                    .then((res) => {
                        if (res.data.data==='密码错误') {
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return;
                        }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
            }
        },
        /** 去支付*/
        goPay(){
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.LandInfor.needpaper,
                unitlandid: [this.LandInfor.unitlandid],
                addressid: this.LandInfor.address.id,
                paymentpassword: this.payPassword,
                ischeck: true,
            };
            if(this.payWay==='balance'){
                this.$http.post("/v1/balance-payment/pay", data)
                    .then((res) => {
                    toastLoading.clear();
                    if (res.data.data==='密码错误'){
                        this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                        this.showKeyboard=false;
                        this.payPassword='';
                        return ;
                    }
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.go(-1);
                });
            }
        },
    },
    watch:{
        // 密码输入完成
        payPassword(payPassword){
            if (payPassword.length >= 6) {
                this.payPassword = payPassword.substring(0, 6);  //  截取前六位
                this.onPay();
            }
        }
    },
    /* 生命周期结束时清除计时器 */
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
};
</script>
<style scoped>
.mobile-detail-home {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
</style>