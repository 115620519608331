<template>
  <div class="developer-common-safe">
    <div class="account-associate-container">
      <div class="developer-common-safe-title">
        {{ $t('PcPay.title') }}
      </div>
      <div class="flex flex-col" style="width:400px;height:600px;">
        <div v-if="showSet">
          <!--忘记/修改支付密码-->
          <div v-if="hasSetPassword">
            <div class="flex justify-center items-center">
              <div class="mt-5 text-xl text-center">
                {{ $t('MobileChangePayPassword.enterOldPassword') }}
              </div>
            </div>
            <!-- 密码输入框 -->
            <div class="pay-password-input" @click="handleClick(3)">
              <div v-for="(item, index) in 6" :key="index" class="pay-password-item" :class="{ active: index === oldPassword }">
                <span class="input-cursor" v-show="inputFocus && index === oldPassword.length"></span>
                <span v-if="oldPassword.length > index" class="pay-password-item-hidden"></span>
              </div>
            </div>
            <!-- 隐藏的input标签 -->
            <input type="text" ref="firstSet3" v-model="oldPassword" style="opacity:0;height:1px;width:1px;" @focus="inputFocus=true" @blur="inputFocus=false">
            <!-- 忘记密码标签 -->
            <div class="flex justify-end items-center">
              <div
                class="text-blue-500 text-sm mr-5 mt-3"
                @click="onForgetPayPassword"
              >
                {{ $t('MobileChangePayPassword.forgetOldPassword') }}
              </div>
            </div>
          </div>
          <!-- 初次设置支付密码 -->
          <div v-else-if="firstInput && !hasSetPassword">
            <div
              class="flex justify-center items-center animate__animated animate__flipInX"
            >
              <div class="mt-5 text-xl text-center">
                {{ $t('MobileChangePayPassword.enterNewPassword') }}
              </div>
            </div>
            <!-- 密码输入框 -->
            <div class="pay-password-input" @click="handleClick(1)">
              <div v-for="(item, index) in 6" :key="index" class="pay-password-item" :class="{ active: index === firstInputPassword }">
                <span class="input-cursor" v-show="inputFocus && index === firstInputPassword.length"></span>
                <span v-if="firstInputPassword.length > index" class="pay-password-item-hidden"></span>
              </div>
            </div>
            <!-- 隐藏的input标签 -->
            <input type="text" ref="firstSet1" v-model="firstInputPassword" style="opacity:0;height:1px;width:1px;" @focus="inputFocus=true" @blur="inputFocus=false">
          </div>
          <div v-else-if="!firstInput && !hasSetPassword">
            <!--重复输入-->
            <div
              class="flex justify-center items-center animate__animated animate__flipInX"
            >
              <div class="mt-5 text-xl text-center">
                {{ $t('MobileChangePayPassword.resetPassword') }}
              </div>
            </div>
            <!-- 密码输入框 -->
            <div class="pay-password-input" @click="handleClick(2)">
              <div v-for="(item, index) in 6" :key="index" class="pay-password-item" :class="{ active: index === secondInputPassword }">
                <span class="input-cursor" v-show="inputFocus && index === secondInputPassword.length"></span>
                <span v-if="secondInputPassword.length > index" class="pay-password-item-hidden"></span>
              </div>
            </div>
            <!-- 隐藏的input标签 -->
            <input type="text" ref="firstSet2" v-model="secondInputPassword" style="opacity:0;height:1px;width:1px;" @focus="inputFocus=true" @blur="inputFocus=false">
          </div>
        </div>

        <!-- 验证码修改支付密码 -->
        <div v-else-if="!showSet">
          <div class="flex justify-center items-center my-4">
            <div class="text-md text-center">
              {{ $t('MobileChangePayPassword.verifyIdentity') }}
            </div>
          </div>
          <!-- 手机验证码验证 -->
          <div>
            <!-- 标题：验证码将发送到您的邮箱/手机号xxx -->
            <div class="flex items-center justify-center">
              <span
                v-if="hideMobile === ''"
                class="text-gray-500 text-sm mb-3 text-center"
              >
                {{ $t('MobileChangePayPassword.tip2') }}{{ hideMobile
                }}{{
                  $i18n.locale === 'en' ? $t('MobileChangePayPassword.tip3') : ''
                }}
              </span>
              <span
                v-else-if="hideEmail !== ''"
                class="text-gray-500 text-sm mb-3 text-center"
              >
                {{ $t('MobileChangePayPassword.tip1') }}{{ hideEmail
                }}{{
                  $i18n.locale === 'en' ? $t('MobileChangePayPassword.tip3') : ''
                }}
              </span>
            </div>

            <!-- 新支付密码输入区 -->
            <div class="flex justify-center items-center">
              <div class="mt-5 text-xl text-center">
                {{ $t('MobileChangePayPassword.newPassword') }}
              </div>
            </div>
            <!-- 密码输入框 -->
            <div class="pay-password-input" @click="handleClick(4)">
              <div v-for="(item, index) in 6" :key="index" class="pay-password-item" :class="{ active: index === newPassword }">
                <span class="input-cursor" v-show="inputFocus && index === newPassword.length"></span>
                <span v-if="newPassword.length > index" class="pay-password-item-hidden"></span>
              </div>
            </div>
            <!-- 隐藏的input标签 -->
            <input type="text" ref="firstSet4" v-model="newPassword" style="opacity:0;height:1px;width:1px;" @focus="inputFocus=true" @blur="inputFocus=false">

            <!-- 图像验证码输入区 -->
            <div class="flex items-center justify-center mt-6">
              <span class="text-gray-500 text-sm"
                >{{ $t('MobileChangePayPassword.graphicCode') }}：</span
              >
              <input
                v-model="imgCode"
                :placeholder="$t('MobileChangePayPassword.graphicCodeInput')"
                style="width: 110px"
                class="my-input"
              />
              <img
                @click="loadCodeImg"
                class="h-8 w-20"
                :src="imgCodeUrl"
                alt="验证码"
              />
            </div>
            <!-- 手机/邮箱验证码输入区 -->
            <div
              v-if="imgCode.length >= 4"
              class="flex items-center mt-2 justify-center animate__animated animate__flipInX"
            >
              <span class="text-gray-500 text-sm"
                >{{ $t('MobileChangePayPassword.verify') }}：</span
              >
              <input
                v-model="code"
                :placeholder="$t('MobileChangePayPassword.verifyInput')"
                style="width: 110px"
                class="my-input"
              />
              <div v-if="mobile.hasSent">
                <span
                  @click="getMobilePhoneCode"
                  class="h-8 w-20 text-sm px-1 py-1 text-white bg-yellow-500 rounded cursor-pointer hover:bg-yellow-600"
                  >{{ $t('MobileChangePayPassword.getCode') }}</span
                >
              </div>
              <div v-else>
                <span
                  disabled
                  class="h-8 w-20 text-sm px-1 py-1 text-gray-500 bg-gray-200 rounded opacity-50"
                  >{{ $t('MobileChangePayPassword.resendCode')
                  }}{{ mobile.timeCount }}s</span
                >
              </div>
            </div>
          </div>
          <!-- 修改支付密码按钮 -->
          <div class="mt-4 flex justify-center items-center">
            <div
              @click="onNext"
              class="text-sm px-4 py-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
            >
              {{ $t('MobileChangePayPassword.change') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (this.$route.query.mode === 'forget') { // 从支付页转来的忘记密码
      this.forgetPassword = true
    }
    this.$http.get('/v1/register/getppd-status').then((res) => {
      if (res.data.status === 200) {
        this.hasSetPassword = !res.data.data
      }
    })
  },
  computed: {
    users() {
      return this.$store.state.user.userInfo
    },
    hideEmail() {
      if (!this.users.useremail || this.users.useremail.length < 3) {
        return this.users.useremail
      }
      const atIndex = this.users.useremail.indexOf('@')
      const username = this.users.useremail.slice(0, 2)
      const domain = this.users.useremail.slice(atIndex + 1)
      return `${username}****@${domain}`
    },
    hideMobile() {
      if (!this.users.userphone || this.users.userphone.length !== 11) {
        return this.users.userphone
      }
      return `${this.users.userphone.slice(
        0,
        3
      )}****${this.users.userphone.slice(-4)}`
    },
  },
  data() {
    return {
      messages: 0,
      forgetPassword: false,
      showSet: true,
      hasSetPassword: false,
      firstInput: true,
      oldPassword: '',
      firstInputPassword: '',
      secondInputPassword: '',
      newPassword: '',
      newPasswordAgain: '',
      showKeyboard: false,
      inputFocus: false, // 输入框获取焦点
      imgCode: '',
      imgCodeUrl: '',

      code: '',
      mobile: {
        hasSent: true,
        timeCount: 60,
      },
    }
  },
  watch: {
    // 输入原密码进行认证
    oldPassword(oldPassword,o) {
      // 判断输入的是否为数字
      const re = /^[0-9]+$/;
      if (!re.test(oldPassword)) {
        this.oldPassword = o;
        return;
      }
      if (oldPassword.length >= 6) {
        this.oldPassword = this.oldPassword.substring(0, 6) //  截取前六位
        const toastLoading = this.$toast.loading({
          duration: 0, // 持续展示 toast
          message: this.$t('MobileChangePayPassword.alertLoading'),
          forbidClick: true,
        })
        this.$http
          .post('/v1/register/originalppd-status', this.oldPassword, {
            headers: {
              'Content-Type': 'application/octet-stream', // 设置请求头为二进制流
            },
          })
          .then((res) => {
            toastLoading.clear()
            if (res.data.data) {
              this.$toast.success(
                this.$t('MobileChangePayPassword.alertVerifySuccessfully')
              )
              this.firstInput = true
              this.hasSetPassword = false
            } else {
              this.$toast.fail(this.$t('MobileChangePayPassword.alertVerifyFailed'));
              this.oldPassword = ''
            }
          })
          .catch((err) => {
            toastLoading.clear()
            this.$toast.fail(
              this.$t('MobileChangePayPassword.alertVerifyFailed')
            )
            this.oldPassword = ''
          })
      }
    },
    //  输入新密码-两次
    firstInputPassword(firstInputPassword,o) {
      const re = /^[0-9]+$/;
      if (!re.test(firstInputPassword)) {
        this.firstInputPassword = o;
        return;
      }
      if (firstInputPassword.length >= 6) {
        this.firstInput = false
        this.firstInputPassword = this.firstInputPassword.substring(0, 6) //  截取前六位
      }
    },
    secondInputPassword(secondInputPassword,o) {
      const re = /^[0-9]+$/;
      if (!re.test(secondInputPassword)) {
        this.secondInputPassword = o;
        return;
      }
      if (secondInputPassword.length >= 6) {
        this.secondInputPassword = this.secondInputPassword.substring(0, 6) //  截取前六位
        if (this.secondInputPassword !== this.firstInputPassword) {
          // 两次密码输入不一致
          this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordDifferent'))
          this.secondInputPassword = ''
        } else if (this.forgetPassword) {
          //忘记密码
          const toastLoading = this.$toast.loading({
            duration: 0, // 持续展示 toast
            message: this.$t('MobileChangePayPassword.alertSettingPassword'),
            forbidClick: true,
          })
          this.$http
            .post('/v1/register/user-updateppd-authentication', {
              code: this.code,
              paymentpassword: this.secondInputPassword,
            })
            .then((res) => {
              toastLoading.clear()
              if (res.data.data === '用户验证成功') {
                this.$toast.success(
                  this.$t('MobileChangePayPassword.alertPasswordSuccessfully')
                )
                this.$router.go(-1)
              } else {
                this.$toast.fail(this.$t('AccountEmail.alertBindFail'))
                this.firstInputPassword = ''
                this.secondInputPassword = ''
                this.code = ''
                this.firstInput = true
              }
            })
            .catch((err) => {
              toastLoading.clear()
              this.$toast.fail(
                this.$t('MobileChangePayPassword.alertPasswordFailed')
              )
              this.firstInputPassword = ''
              this.secondInputPassword = ''
              this.code = ''
              this.firstInput = true
            })
        } else if (!this.hasSetPassword && this.oldPassword === '') {
          // 初次设置密码(注意要多判断oldPassword是否为空)
          const toastLoading = this.$toast.loading({
            duration: 0, // 持续展示 toast
            message: this.$t('MobileChangePayPassword.alertSettingPassword'),
            forbidClick: true,
          })
          this.$http
            .post('/v1/register/insertppd', this.secondInputPassword, {
              headers: {
                'Content-Type': 'application/octet-stream', // 设置请求头为二进制流
              },
            })
            .then((res) => {
              toastLoading.clear()
              if (res.data.data === '支付密码设置成功') {
                this.$toast.success(
                  this.$t('MobileChangePayPassword.alertPasswordSuccessfully')
                )
                this.$router.go(-1)
              } else {
                this.$toast.fail(
                  this.$t('MobileChangePayPassword.alertPasswordFailed')
                )
                this.firstInputPassword = ''
                this.secondInputPassword = ''
                this.firstInput = true
                this.hasSetPassword = false
              }
            })
            .catch((err) => {
              toastLoading.clear()
              this.$toast.fail(
                this.$t('MobileChangePayPassword.alertPasswordFailed')
              )
              this.firstInputPassword = ''
              this.secondInputPassword = ''
              this.firstInput = true
              this.hasSetPassword = false
            })
        }
        //根据原始密码修改密码
        else {
          const toastLoading = this.$toast.loading({
            duration: 0, // 持续展示 toast
            message: this.$t('MobileChangePayPassword.alertChangingPassword'),
            forbidClick: true,
          })
          this.$http
            .post('/v1/register/original-isok-updateppd', {
              originalpaymentpassword: this.oldPassword,
              paymentpassword: this.secondInputPassword,
            })
            .then((res) => {
              toastLoading.clear()
              if (res.data.data === '支付密码设置成功') {
                this.$toast.success(
                  this.$t(
                    'MobileChangePayPassword.alertChangePasswordSuccessfully'
                  )
                )
                this.$router.go(-1)
              } else {
                this.$toast.fail(
                  this.$t('MobileChangePayPassword.alertChangePasswordFailed')
                )
                this.firstInputPassword = ''
                this.secondInputPassword = ''
                this.firstInput = true
                this.oldPassword = ''
                this.hasSetPassword = false
              }
            })
            .catch((err) => {
              toastLoading.clear()
              this.$toast.fail(
                this.$t('MobileChangePayPassword.alertChangePasswordFailed')
              )
              this.secondInputPassword = ''
            })
        }
        this.loadCodeImg()
      }
    },
    // 验证码修改密码输入新密码需要验证格式
    newPassword(n,o) {
      const re = /^[0-9]+$/;
      if (!re.test(n)) {
        this.newPassword = o;
      }
    },
  },
  methods: {
    // 点击输入框触发输入监听事件
    handleClick(tab) {
      if (tab === 1) { // 初次设置密码第一次输入
        this.$refs.firstSet1.focus()
      } else if (tab === 2) { // 初次设置密码第二次输入
        this.$refs.firstSet2.focus()
      } else if (tab === 3) { // 修改密码输入原密码
        this.$refs.firstSet3.focus()
      } else if (tab === 4) { // 验证码修改密码输入新密码
        this.$refs.firstSet4.focus()
      }
    },
    onForgetPayPassword() {
      this.loadCodeImg()
      this.showSet = false
      this.hasSetPassword = false
      // 稍后需经过手机验证
      Notify({ type: 'primary', message: this.$t('MobileChangePayPassword.alertNotify') })
    },
    getMobilePhoneCode() {
      if (imgCode === '') {
        this.$toast.fail('computerlogin.alertNoGraphicCode');
        return;
      }
      // 手机端点击获取验证码
      const toastLoading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        message: this.$t('MobileChangePayPassword.alertCodeLoading'),
        forbidClick: true,
      })

      this.$http
        .post('/v1/register/get-code-ofppd', this.imgCode, {
          headers: {
            'Content-Type': 'application/octet-stream', // 设置请求头为二进制流
          },
        })
        .then((res) => {
          toastLoading.clear()
          this.startCountdown()
          if (res.data.status === 200) {
            this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
          } else if (res.data.status === 201) {
            this.$toast.fail(this.$t('all.图形验证码错误'));
            this.imgCode = '';
            this.loadCodeImg();
          } else if (res.data.status === 202) {
            this.$toast.fail(this.$t('all.codeLimit'));
          } else {
            this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
          }
        })
        .catch((error) => {
          // 处理错误
          toastLoading.clear()
          this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'))
        })
    },
    /* 获取验证码后计时重新发送 */
    startCountdown() {
      // 设置倒计时60s
      this.mobile.timeCount = 60
      this.mobile.hasSent = false
      // 计时器
      this.mobile.timer = setInterval(() => {
        if (this.mobile.timeCount > 0) {
          this.mobile.timeCount--
        } else {
          this.mobile.hasSent = true
          clearInterval(this.mobile.timer)
          this.mobile.timer = null
        }
      }, 1000)
    },
    onNext() {
      if (this.newPassword.length !== 6) {
        this.$toast(this.$t('MobileChangePayPassword.alertNoPassword'))
        return
      } else if (this.code === '') {
        this.$toast(this.$t('MobileChangePayPassword.alertNoCode'))
        return
      }
      const toastLoading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        message: this.$t('MobileChangePayPassword.alertCodeLoading'),
        forbidClick: true,
      })
      this.$http
        .post('/v1/register/user-updateppd-authentication', {
          code: this.code,
          paymentpassword: this.newPassword,
        })
        .then((response) => {
          toastLoading.clear()
          if (res.data.status === 200) {
            // 修改成功
            this.$toast.success(
              this.$t('MobileChangePayPassword.alertChangePasswordSuccessfully')
            )
            this.$router.go(-1)
          } else {
            // 验证码错误
            this.$toast.fail(this.$t('AccountEmail.alertBindFail'))
          }
        })
        .catch((error) => {
          // 处理错误
          toastLoading.clear()
          this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'))
        })
    },
    loadCodeImg() {
      this.$http
        .get('/v1/register/captcha', {
          responseType: 'blob', // 设置响应类型为blob
        })
        .then((response) => {
          // 处理返回值
          const imageBlob = response.data
          this.imgCodeUrl = URL.createObjectURL(imageBlob)
        })
    },
  },
}
</script>

<style>
.pay-password-input {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  cursor: pointer;
}

.pay-password-item {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pay-password-item.active {
  border-color: #000;
}

.pay-password-item-hidden {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
}

.input-cursor {
  width: 2px;
  height: 20px;
  background-color: #000;
  background-color: black;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>