<template>
  <div class="order-window">
    <div class="window-combin">
      <!-- 创建时间 -->
      <div class="window-combin-time">{{ returnDate(order.orderDate) }}</div>
      <!-- 订单编号 -->
      <div class="window-combin-id">{{'订单编号:'}}{{ order.combinedOrderId }}</div>
      <!-- 总金额 -->
      <div class="window-combin-price">{{'总金额:'}}{{ order.totalAmount }}</div>
    </div>

    <div class="window-infor" v-for="(separateOrders, index) in order.separateOrders" :key="index" @click="turnToDetail(index,separateOrders.unitlandid)">
      <!-- 批量支付的单选框 -->
      <div class="h-full flex items-center" style="margin-left:10px;" v-if="mode===1">
        <van-checkbox icon-size="20px" class="order-select" v-model="select[index].isSelect" @change="checkBoxChange(index,separateOrders.orderstatus)"></van-checkbox>
      </div>
      <!-- 土地图片 -->
      <div class="window-infor-picture">
        <img :src="separateOrders.landpicture">
      </div>
      <!-- 土地信息 -->
      <div class="window-infor-box" :style="mode===1?'width:258px':'width:288px'">
        <span class="window-infor-name">{{ separateOrders.landname }}</span>
      </div>
      <!-- 分割线 -->
      <div class="window-devider"></div>
      <!-- 商品价格 -->
      <div class="window-infor-price">
        <span v-if="$setPayment === 'am'">${{ separateOrders.totalprice }}</span>
        <span v-else-if="$setPayment === 'zh'">￥{{ separateOrders.totalprice * $exchangeRate }}</span>
      </div>
      <!-- 分割线 -->
      <div class="window-devider"></div>
      <!-- 状态 -->
      <div class="window-infor-statu">
        <span :style="refundStyleFontColor(separateOrders.orderstatus)">{{ getEnglish(separateOrders.orderstatus) }}</span>
        <span class="mt-5" v-if="separateOrders.orderstatus === '待付款'">{{ countdown[index]?countdown[index].hour + 'h-' + countdown[index].minute + 'm-' + countdown[index].second + 's':'error' }}</span>
      </div>
      <!-- 分割线 -->
      <div class="window-devider"></div>
      <!-- 操作 -->
      <div class="window-infor-control">
        <!-- 原退款按钮打开弹窗@click.stop="showRefund=true;refundPrice=order.totalAmount;orderInfor = separateOrders" -->
        <span
          style="color: #0256FF;cursor: pointer;margin-bottom: 14px;"
          v-if="separateOrders.orderstatus === '已付款' || separateOrders.orderstatus === '已驳回'"
          v-show="mode === 0"
          @click.stop="RefundConfirm(separateOrders.separateOrderId)"
          >{{$t('PcOrderDetail.refund')}}
        </span>
        <span
          style="color: #0256FF;cursor: pointer;margin-bottom: 14px;"
          v-if="separateOrders.orderstatus === '待付款'"
          v-show="mode === 0"
          @click.stop="continuePayment(separateOrders)"
          >{{$t('MobileOrderList.continueTitle')}}
        </span>
        <span style="cursor: pointer;" @click.stop="turnToDetail(index,separateOrders.unitlandid)">{{$t('LandSellList.ownerid')}}</span>
      </div>
    </div>

    <!-- 退款弹窗 -->
    <div class="developer-common-modal" v-if="showRefund">
      <div class="developer-common-modal-box">
        <div class="h-full w-full flex flex-col items-center justify-center">
          <div class="flex flex-col items-center justify-center">
            <div class="flex flex-col items-center justify-center">
              <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.refundNum')}}</span>
              <span class="text-gray-600 text-lg my-2 ">￥{{ refundPrice }}</span>
            </div>
            <div class="flex flex-col items-center justify-center p-2">
              <span class="text-gray-600 text-md ">{{$t('MobilePurchaseDetails.reason')}}</span>
              <van-field
                            v-model="refundReason"
                            :placeholder="$t('MobilePurchaseDetails.reasonInput')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                            class="refund-modal-input-box"
              />
            </div>
          </div>
          <div class="flex  items-center justify-center">
            <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showRefund = false;refundReason = '';">{{$t('MobilePurchaseDetails.cancel')}}</button>
            <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="RefundConfirm">{{$t('MobilePurchaseDetails.confirm')}}</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  props: { // 父子组件传值
    order: {
      type: Object,
      default: {
        combinedOrderId: "",
        totalAmount: "",
        paymentStatus: "",
        orderDate: new Date(),
        separateOrders: [{
          separateOrderId: "",
            landname: "",
            totalprice: 0,
            paymentStatus: "",
            landpicture: '',
            canceltime: '',
            orderstatus: '',
        }],
      },
    },
    mode: { // 模式，0为查看模式，1为批量支付模式，2为批量退款模式
      type: Number,
      require: true,
    },
    balance: {
      type: Number,
      require: true,
      default: 0
    }
  },
  created() {
    this.initializeSelect();
    this.initializeInterval();
  },
  data() {
    return {
      example: {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1925.0",
                    paymentStatus: "已付款",
                    orderDate: "2024-05-24T14:18:50",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黑土地",
                        totalprice: 1200.0,
                        orderstatus: "已付款",
                        landpicture: null,
                        canceltime: '111',
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黄土地",
                        totalprice: 2100.0,
                        orderstatus: "已退款",
                        landpicture: "/image/20230731145640.png",
                        canceltime: '111',
                    }],
                },

      countdown: [], // 待付款订单倒计时
      
      timer: null, // 定时器
      countdown: [], // 定时器剩余时间数组

      showRefund: false, // 退款弹窗
      refundPrice: 0, // 退款金额
      refundReason: '', // 退款弹窗输入框临时存储区
      flag: true, // 防抖
      orderInfor: null, // 继续支付和退款发给后端用的交易信息

      select: [], // 批量选择的数组

      orders: [], // 继续支付选择的独立订单数据组成的数组
    }
  },
  methods: {
    /* 订单状态在后端是中文，需要有双语 */
        getEnglish(statu) {
            if (statu === '待付款') {
                return this.$t('MobileOrderList.time');
            } else if (statu === '已付款') {
                return this.$t('MobileOrderList.statu2');
            } else if (statu === '已退款') {
                return this.$t('MobileOrderList.statu3');
            } else if (statu === '退款中') {
                return this.$t('MobileOrderList.statu4');
            } else if (statu === '已驳回') {
                return this.$t('MobileOrderList.statu5');
            } else if (statu === '已取消') {
                return this.$t('MobileOrderList.statu6');
            }
        },
    // 给退款的状态加一个颜色标识
        refundStyleFontColor(statu) {
            if (statu === '已退款') {
              return 'color:#00B578;';
            } else if (statu === '退款中') {
                return 'color: #3662EC;';
            } else if (statu === '已驳回') {
                return 'color: #FA5151;';
            }
        },
    // 初始化复选框列表
        initializeSelect() {
            this.select = [];
            for (let i =0;i < this.order.separateOrders.length;i++) {
                this.select.push({isSelect: false});
            }
        },
    // 初始化待付款订单的剩余时间
        initializeInterval() {
            for (let i = 0;i < this.order.separateOrders.length; i++) {
                if (this.order.separateOrders[i].orderstatus === '待付款') {
                    if (!this.order.separateOrders[i].canceltime) {
                      this.countdown[i] = null;
                    }
                    let totalSeconds = Math.floor(this.order.separateOrders[i].canceltime / 1000);
                    let hour = Math.floor(totalSeconds / 3600);
                    let minute = Math.floor((totalSeconds % 3600) / 60);
                    let second = totalSeconds % 60;
                    this.countdown[i] = {
                        hour: hour,
                        minute: minute,
                        second: second,
                        totalSeconds: totalSeconds
                    };
                }
            }
            if (this.countdown.length < 1) { return; }
            this.timer = setInterval(() => {
                this.countdown = this.countdown.map(item => {
                    if (item) {
                        if (--item.totalSeconds <= 0) {
                            item.totalSeconds = 0;
                        }
                        item.hour = Math.floor(item.totalSeconds / 3600);
                        item.minute = Math.floor((item.totalSeconds % 3600) / 60);
                        item.second = item.totalSeconds % 60;
                        return item;
                    }
                });
            }, 1000);
        },
    // 获取日期
    returnDate(date) {
      if (date instanceof Date) {
        const YEAR = date.getFullYear();
        const MONTH = date.getMonth();
        const DAY = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
        return YEAR + '-' + MONTH + '-' + DAY;
      } else {
        if (date.includes('T')) {
          return date.split('T')[0];
        } else {
          return date.split(' ')[0];
        }
      }
    },
    // 对于单选框如果不可选就重置为未选中
    checkBoxChange(index,statu) {
      if (statu !== '待付款') {
        this.select[index].isSelect = false;
      }
    },
    turnToDetail(index,unitlandid) {
            if (this.mode === 0) { // 进入详情
                const status = this.order.separateOrders[index].orderstatus
                if (status === '已付款' || status === '待付款' || status === '已驳回' || status === '已取消') {
                    const newpath = "/user/my-order/detail?order=" + this.order.separateOrders[index].separateOrderId
                    this.$router.push({
                        path: newpath,
                    });
                } else if (status === '已退款' || status === '退款中') {
                    const newpath = "/user/refund/detail?order=" + unitlandid
                    this.$router.push({
                        path: newpath,
                    });
                }
            } else if (this.mode === 1) { // 批量支付模式
                if (this.order.separateOrders[index].orderstatus !== '待付款') {
                    this.$toast.fail(this.$t('MobileOrderList.alertCanotSelect'));
                    this.select[index].isSelect = false;
                    return;
                }
                // 切换该位置单选框的选中状态
                this.select[index].isSelect = !this.select[index].isSelect;
                // 添加或删除该条独立订单在父组件中选中订单数组的位置
                if (this.select[index].isSelect) {
                  this.$parent.addOrders(this.order.separateOrders[index].separateOrderId,this.order.separateOrders[index].totalprice);
                } else {
                  this.$parent.deleteOrders(this.order.separateOrders[index].separateOrderId,this.order.separateOrders[index].totalprice);
                }
            } else { // 其他情况一律进入订单详情页
                const newpath = "/user/my-order/detail?order=" + unitlandid
                this.$router.push({
                    path: newpath,
                });
            }
            
            
            // 批量退款模式，目前后端没有批量退款的接口，也没有查询多个订单合并退款的接口，因此注释掉
            /* else if (this.mode === 2) {
                if (this.order.separateOrders[index].orderstatus !== '已付款' && this.order.separateOrders[index].orderstatus !== '退款申请被驳回') {
                    this.$toast.fail('不能选择该订单');
                    this.select[index].isSelect = false;
                    return;
                }
                this.select[index].isSelect = !this.select[index].isSelect;
                if (this.select[index].isSelect) {
                    this.orders.push(this.order.separateOrders[index]);
                    this.refundPrice += this.order.separateOrders[index].totalprice;
                } else {
                    this.orders.filter(item => item.ids !== this.order.separateOrders[index].ids);
                    this.refundPrice -= this.order.separateOrders[index].totalprice;
                }
            } */
    },
    // 单条独立订单申请退款
        RefundConfirm(separateOrderId){ // 发送退款申请
          this.$router.push({
            name: 'UserPcRefundDetail',
            query: {
              order: separateOrderId,
              index: 0,
            }
          });
        },
    // 单个独立订单继续支付-进入继续支付页面
        continuePayment(order) {
          this.orders = [];
          this.orders.push(order);
          this.$router.push({
            path: "/user/continue-payment",
            name: 'UserPcContinue',
            params: {
              totalPrice: JSON.stringify(this.totalPrice),
              orders: JSON.stringify(this.orders)
            },
          });
        },
  },
}
</script>

<style scoped>
.order-window {
  width: 892px;
  border-radius: 8px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  display: flex;
  flex-direction: column;
}
.window-combin {
  width: 100%;
  height: 35px;
  background: #F4F4F4;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #9DA1AD;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.window-combin-time {
  width: 140px;
  text-align: center;
}
.window-combin-id {
  width: 288px;
  text-align: center;
}
.window-combin-price {
  width: 168px;
  text-align: center;
}

.window-infor {
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.window-infor-picture {
  margin-left: 20px;
  width: 89px;
  height: 89px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
}
.window-infor-picture > img {
  object-fit: contain;
  width: 89px;
  border-radius: 8px;
}
.window-infor-box {
  margin-left: 30px;
  width: 288px;
  max-width: 288px;
  max-height: 114px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.window-infor-name {
  font-size: 16px;
  color: #3D3D3D;
}
/* 竖直的分割线 */
.window-devider {
  width: 1px;
  height: 100%;
  background: #D8D8D8;
}
.window-infor-price {
  width: 167px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #3D3D3D;
  max-width: 167px;
  padding: 16px;
}
.window-infor-statu {
  width: 147px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  color: #3D3D3D;
}
.window-infor-control {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #3D3D3D;
}
</style>