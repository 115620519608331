<template>
    <div class="mobile-base-background2 pb-5">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileRecharge.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="flex flex-col items-center">
            <span class="text-md text-gray-500">{{$t('MobileRecharge.balance')}}</span>
            <span class="text-3xl mt-3">{{ balance[0] }}<span class="text-xl">{{ balance[1] }}</span></span>
        </div>
        <div class="my-4 px-8 mt-8">
            <hr class="border-t-2 border-gray-200" />
        </div>
        <div class="mx-5">
            <div class="text-lg mb-3 flex flex-row items-center">
                {{$t('MobileRecharge.recharge')}}
                <img src="../mImage/wallet-q.png" class="ml-2" style="height:12px;width:12px;" @click="showModal=true;">
            </div>
            <div class="grid grid-cols-3 gap-2 text-lg">
                <div
                    v-for="(number,index) in numberList"
                    :key="index"
                    @click="onNumberSelect(number)"
                    class="bg-gray-100 flex flex-col justify-center items-center text-center text-xl rounded-lg w-30 h-20"
                    :class="selectedNumber === number ? 'bg-blue-200 border-2 border-blue-300' : ''">
                    <span v-if="index !== numberList.length - 1">{{ number }}{{$t('MobileMyWallet.sum')}}</span>
                    <span class="text-gray-500 mt-1 text-sm" v-if="index !== numberList.length - 1">{{$t('MobileRecharge.price')}}{{ getPrice(number) }}</span>
                    <span v-else>{{ number }}</span>
                </div>
            </div>
            <div v-if="customNumber != null" class="flex justify-center mt-3 mx-8">
                <div class="flex items-center">
                    <span class="text-md w-full text-center">{{$t('MobileRecharge.customize')}}:</span>
                    <input type="number" class="w-full rounded-lg bg-gray-100 py-2 px-8 border-solid border-1 border-current" v-model="selectedNumber" :placeholder="$t('MobileRecharge.sum')" />
                    <span class="text-gray-500 mt-1 text-sm ml-2">{{$t('MobileRecharge.price')}}{{ getPrice(selectedNumber) }}</span>
                </div>
            </div>
        </div>
        <div class="my-4 px-8 mt-8">
            <hr class="border-t-2 border-gray-200" />
        </div>
        <div>
            <span class="px-8 text-lg">{{$t('MobileRecharge.method')}}</span>
            <div class="grid grid-cols-3 gap-2 px-8 text-lg">
                <div class="flex justify-center items-center text-center text-lg rounded-lg w-30 h-20" v-if="$setPayment === 'zh'">
                    <img src="../images/alipay.png" alt="" class="w-9 h-9 mr-1" />
                    {{$t('MobileRecharge.alipay')}}
                </div>
                <div class="flex justify-center items-center text-center text-lg rounded-lg w-30 h-20"><img src="../images/wechat.png" alt="" class="w-10 h-10 mr-1" v-if="$setPayment === 'zh'" />{{$t('MobileRecharge.wechat')}}</div>
                <div class="flex justify-center items-center text-center text-lg rounded-lg w-30 h-20"><img src="../images/paypal.png" alt="" class="w-10 h-10 mr-1" v-if="$setPayment === 'am'" />{{$t('MobileRecharge.paypal')}}</div>
            </div>

            <!-- TODO 注释掉了支付宝和微信支付 -->
            <van-radio-group class="grid grid-cols-3 gap-2 px-8 text-lg" style="display:grid;" v-model="payWay" direction="horizontal">
                <van-radio class="flex justify-center items-center" name="aliPay" v-if="$setPayment === 'zh'" disabled></van-radio>
                <van-radio class="flex justify-center items-center" name="wechat" v-if="$setPayment === 'zh'" disabled />
                <van-radio class="flex justify-center items-center" name="paypal" v-if="$setPayment === 'am'" />
            </van-radio-group>

            <div @click="onSubmit" class="rounded-3xl shadow-md hover:bg-blue-600 bg-blue-700 px-8 pt-2 pb-2 mx-6 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileRecharge.button')}}</span>
            </div>
        </div>
        <!-- 支付表单-->
        <div>
            <div v-html="paymentForm"></div>
        </div>

        <!-- 提示用户关于余额充值注意事项的弹窗 -->
        <van-popup v-model="showModal" closeable class="p-2 pt-10">
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>{{$t('MobileRecharge.tip1')}}</span>
            <span>{{$t('MobileRecharge.tip2')}}</span>
            <span>{{$t('MobileRecharge.tip3')}}</span>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "MobileRecharge",
    data() {
        return {
            messages: 0,
            balance: ['0','00'],
            numberList: [10, 20, 50, 100, 200],
            customNumber: null,
            selectedNumber: 10,
            payWay: "paypal",
            paymentForm: "",
            showModal: false, // 告诉用户充值余额的注意事项的弹窗
        };
    },
    created() {
        this.numberList.push(this.$t('MobileRecharge.customize'))
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                const value = Number(res.data.data.balance);
                this.balance = value.toFixed(2).toString().split(".");
            })
            .catch((err) => {
                console.log(err);
            });
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    methods: {
        /* 获取支付金额 */
        getPrice(n) {
            if (isNaN(n)) {
                return;
            }
            if (this.$i18n.locale === 'zh') {
                return (n * this.$exchangeRate).toFixed(2).toString().split(".");
            } else {
                return n.toFixed(2).toString().split(".");
            }
        },
        onNumberSelect(number) {
            this.selectedNumber = number;
            if (number === this.$t('MobileRecharge.customize')) {
                this.customNumber = 0;
            } else {
                this.customNumber = null;
            }
        },
        /* 异步等待接口 */
        async fetchDataWithTimeout(url) {
            try {
                // 发送POST请求
                const response = await this.$http.post(url);

                // 设置最长十分钟的超时时间
                const timeout = 10 * 60 * 1000; // 十分钟的毫秒数

                // 定义一个Promise，等待十分钟内收到状态为200的响应
                const response200 = new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(new Error('Timeout: Did not receive response within 10 minutes.'));
                    }, timeout);

                    if (response.status === 200) {
                        resolve(response);
                    }
                });

                // 等待Promise的返回结果
                const result = await Promise.race([response200]);

                // 处理状态为200的响应
                if (result.data) {
                    this.$toast.success(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.push('/mobile/pay-success');
                } else {
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    this.$router.push('/mobile/pay-fail');
                }
            } catch (error) {
                // 捕获异常并进行处理
                console.error('Error occurred:', error.message);
            }
        },
        /** 提交订单*/
        onSubmit() {
            // this.submitLoading = true;
            const data = {
                "balance": this.selectedNumber===this.$t('MobileRecharge.customize')?this.customNumber:this.selectedNumber,
            };
            if(data.balance <= 0){
                this.$toast(this.$t('MobileRecharge.alertNoRecharge'));
                return;
            }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileRecharge.alertGoPay'),
                forbidClick: true,
            });
            if (this.payWay === "aliPay") {
                this.$http.post("/v1/recharge/alipay-add", data,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    this.paymentForm = res.data.html;
                    // 保存订单号后提示用户支付成功并做后续操作
                    // 等待1秒
                    setTimeout(() => {
                        this.submitLoading = false;
                        document.querySelector('form[name="punchout_form"]').submit();
                        toastLoading.clear();
                    }, 500);
                });
            }
            else if (this.payWay === "wechat") {
                // this.$toast("微信支付");
            }
            else if (this.payWay==='paypal'){ // paypal充值
                this.$toast("PayPal支付");
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileRecharge.alertGoPay'),
                    forbidClick: true,
                });
                this.$http.post("/v1/recharge/paypal-add", data).then((res) => {
                    toastLoading.clear();
                    //访问paylink
                    window.location.href = res.data.html;
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.orderid = res.data.orderid;
                    // window.open(res.data.html,'_blank'); // 打开一个新的PayPal标签页
                    // fetchDataWithTimeout("/v1/paypal/callback/success?orderid=" + this.orderid);
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            }
        },
    },
};
</script>

<style scoped></style>
