<template>
    <div class="mobile-detail-home mobile-base-background2 p-0 bg-gray-50">
        <div class="fixed top-0 w-full flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{'活动:拼拼乐'}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="flex flex-col animate__animated animate__fadeInUp detail-main">
            <div class="original-picture" ref="background">
                <img :src="LandInfor.originalLand.landpicture">
            </div>
            <!-- 价格 -->
            <div class="flex flex-row mt-4 mb-4">
                <span class="text-gray-600 text-md mr-2">{{ '单位土地价格' }}:</span>
                <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
            </div>
            <!-- 原始土地名称及开发商/平台标签 -->
            <div class="flex flex-row items-center">
                <span class="text-lg font-semibold">{{ LandInfor.landname }}</span>
                <div class="detail-merch-icon-developer" v-if="LandInfor.userBusiness.identifier === '企业'">{{$t('SellerHome.developer')}}</div>
                <div class="detail-merch-icon-platform" v-else-if="LandInfor.userBusiness.identifier === '平台'">{{$t('SellerHome.platform')}}</div>
            </div>
            <!-- 剩余数量 -->
            <span class="text-gray-500 mt-1" style="font-size:12px;">{{'剩余数量'}}: {{ LandInfor.createtime }}</span>
            <!-- 选择数量 -->
            <div class="mt-2">
                <span style="width: 40%;">选择数量</span>
                <el-input-number class="ml-5" size="small" v-model="num" :min="1" :max="484"></el-input-number>
            </div>
            <!-- 是否连贯 -->
            <div class="mt-2">
                <span style="width: 40%;">是否连贯</span>
                <el-radio class="ml-5" v-model="radio" :label="true">{{ $t('MobilePurchaseDetails.yes') }}</el-radio>
                <el-radio v-model="radio" :label="false" style="margin-left: 50px;">{{ $t('MobilePurchaseDetails.no') }}</el-radio>
            </div>
            <!-- 活动规则及原始土地信息 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-3 mb-3 pb-5">
                <van-tabs v-model="tabControl">
                    <!-- 活动规则 -->
                    <van-tab :title="'活动规则'">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <pre>{{ '1.用户在当前小区选择' }}</pre>
                            </div>
                        </div>
                    </van-tab>
                    <!-- 原始土地信息 -->
                    <van-tab :title="$t('MobileLandDetail.origindetail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.userBusiness.identifier?LandInfor.userBusiness.businessname:LandInfor.userBusiness.username }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <!-- 开发商信息 -->
                    <van-tab :title="$t('MobileLandDetail.sellerInfor')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.sellername')}}:</span>
                                <span class="text-gray-700 text-sm">{{ getSellerInfor('name') }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.selleremail')}}:</span>
                                <span class="text-gray-700 text-sm">{{ getSellerInfor('email') }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.sellerphone')}}:</span>
                                <span class="text-gray-700 text-sm">{{ getSellerInfor('phone') }}</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="mt-1 mb-3 pt-1">
                <span class="text-md font-semibold">{{$t('MobileLandDetail.landRights')}}</span>
                <div class="flex flex-col mb-3 mt-3">
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1 flex flex-row">
                        <span class="text-gray-500 text-xs mr-3 whitespace-nowrap">{{$t('MobileLandDetail.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 mobile-detail-bottom border-t border-gray-300 py-3 flex items-center pr-3">
            <div class="px-2 py-1 mr-1 ml-3 text-xs rounded w-auto flex items-center flex-col" @click="OpenSeller">
                <img src="../mImage/merch-detail-seller.png" style="height:16px;width:16px;">
                <span class="mt-1">{{$t('MobileLandDetail.seller')}}</span>
            </div>
            <div class="px-2 py-1 mr-1 text-xs rounded w-auto flex items-center flex-col" @click="TalkSeller">
                <img src="../mImage/merch-detail-talk.png" style="height:16px;width:16px;">
                <span class="mt-1">{{$t('MobileLandDetail.private')}}</span>
            </div>
            <div class="flex-grow flex justify-end">
                <button class="mobile-detail-bottom-button button-back3" @click="onBuy">{{$t('MobileLandDetail.buy')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        // 不要忘了获取传输过来的土地信息
        this.originallandid = this.$route.query.oid;
        this.communitynumber = this.$route.query.cid;
    },
    mounted() {
        // 设置图片高度
        this.$refs.background.style.height = this.$refs.background.clientWidth + 'px';

        // 获取土地详情信息
            this.$http
                .get("/v1/mail/select-onsale-by-saleid?saleid=" + this.unitlandid)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.LandInfor.createtime = this.LandInfor.createtime.split("")[0];
                    // this.$refs.background.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")"
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(" + this.LandInfor.unitpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 100);
                })
                .catch((error) => {
                    console.log(error);
                });

        // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            originallandid: null, // 原始土地编号
            communitynumber: null, // 小区编号
            tabControl: 0, // 第三格土地详情栏tab控制参数，0是活动规则，1是原始土地详情，2是卖家信息
            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: "原始土地名称",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
                onSale: {
                    seller: this.$t('MobileLandDetail.none'),
                },
                userBusiness: {
                    identifier: '企业',
                    businessname: '',
                    businessemail: '',
                    businessphone: '',
                }
            },
        };
    },
    methods: {
        getSellerInfor(tab) {
            if (this.LandInfor.userBusiness.identifier) {
                if (tab === 'name') {
                    if (this.LandInfor.userBusiness.businessname) {
                        return this.LandInfor.userBusiness.businessname;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'email') {
                    if (this.LandInfor.userBusiness.businessemail) {
                        return this.LandInfor.userBusiness.businessemail;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'phone') {
                    if (this.LandInfor.userBusiness.businessphone) {
                        return this.LandInfor.userBusiness.businessphone;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                }
            } else {
                if (tab === 'name') {
                    if (this.LandInfor.userBusiness.username) {
                        return this.LandInfor.userBusiness.username;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'email') {
                    if (this.LandInfor.userBusiness.useremail) {
                        return this.LandInfor.userBusiness.useremail;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                } else if (tab === 'phone') {
                    if (this.LandInfor.userBusiness.userphone) {
                        return this.LandInfor.userBusiness.userphone;
                    } else {
                        return this.$t('MobileLandDetail.none');
                    }
                }
            }
        },
        OpenSeller() {
            // 查看卖家
            this.$router.push({
                path: "/mobile/seller-home?merch=" + this.LandInfor.id + "&seller=" + this.LandInfor.sellerid,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?merch=" + this.LandInfor.id + "&receive=" + this.LandInfor.sellerid,
            });
        },
        onBuy() {
            this.$router.push({
                path: "/mobile/confirm-order",
                name: "MobileConfirmOrder",
                query: {
                    LandInfor: JSON.stringify([this.LandInfor]),
                    totalPrice: JSON.stringify(this.LandInfor.totalprice),
                },
            });
        },
    },
};
</script>

<style scoped>
.original-picture {
    border: 1px solid #a3a3a3;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;
}
</style>