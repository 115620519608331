<template>
    <div class="first-main-container">
        <!-- 左上角返回 -->
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{ topicTitle }}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <!-- 用户刷新 -->
        <div class="mobile-associated-box-container" v-if="webError">
            <div class="mobile-associated-form-topic">{{$t('MobileAssociatedReplace.title1')}}</div>
        </div>
        <!-- 身份认证 -->
        <div class="mobile-associated-box-container" v-if="!webError && isverifing">
            <div class="mobile-associated-form-topic">{{ verifyTopic }}</div>
            <div class="items-center justify-end mobile-associated-box-input-parent">
                <!--图像验证码-->
                <div class="w-full flex flex-col">
                    <div class="w-full flex flex-row">
                        <span class="mobile-associated-box-input-labal">{{$t('mobileLogin.graphicCode')}}:</span>
                        <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="mobile-associated-box-input" style="width: 60%" />
                    </div>
                    <div class="w-full flex flex-row justify-center items-end mt-5">
                        <img @click="loadCodeImg" style="width:120px;" :src="imgCodeUrl" alt="验证码"/>
                        <span class="mobile-associated-box-imgcode-change" @click="loadCodeImg">{{$t('MobileAssociatedReplace.change')}}</span>
                    </div>
                </div>
            </div>
            <div class="mobile-associated-box-input-parent">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedReplace.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedReplace.codeInput')" class="mobile-associated-box-input" />
                <span @click="getVerifyCode" v-if="isCode" class="mobile-associated-box-code-button">{{$t('MobileAssociatedReplace.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-associated-box-code-button">{{$t('MobileAssociatedReplace.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 90%; margin-bottom: 10px">
                <button class="mobile-associated-box-button" @click="nextStep">{{$t('MobileAssociatedReplace.nextStep')}}</button>
            </div>
        </div>
        <!-- 设置邮箱账号 -->
        <div class="mobile-associated-box-container" v-if="!webError && setType === 'email' && !isverifing">
            <div class="mobile-associated-box-input-parent">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedReplace.email')}}:</span></div>
                <input type="email" v-model="email" class="mobile-associated-box-input" :placeholder="$t('MobileAssociatedReplace.emailInput')" />
            </div>
            <div class="items-center justify-end mobile-associated-box-input-parent">
                <!--图像验证码-->
                <div class="w-full flex flex-col">
                    <div class="w-full flex flex-row">
                        <span class="mobile-associated-box-input-labal">{{$t('mobileLogin.graphicCode')}}:</span>
                        <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="mobile-associated-box-input" style="width: 60%" />
                    </div>
                    <div class="w-full flex flex-row justify-center items-end mt-5">
                        <img @click="loadCodeImg" style="width:120px;" :src="imgCodeUrl" alt="验证码"/>
                        <span class="mobile-associated-box-imgcode-change" @click="loadCodeImg">{{$t('MobileAssociatedReplace.change')}}</span>
                    </div>
                </div>
            </div>
            <div class="mobile-associated-box-input-parent">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedReplace.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedReplace.codeInput')" class="mobile-associated-box-input" />
                <span @click="getEmailCode" v-if="isCode" class="mobile-associated-box-code-button">{{$t('MobileAssociatedReplace.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-associated-box-code-button">{{$t('MobileAssociatedReplace.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 90%; margin-bottom: 10px">
                <button class="mobile-associated-box-button" @click="AddEmailAssociated">{{$t('MobileAssociatedReplace.confirm')}}</button>
            </div>
        </div>
        <!-- 设置手机号码 -->
        <div class="mobile-associated-box-container" v-if="!webError && setType === 'phone' && !isverifing">
            <div class="mobile-associated-box-input-parent">
                <span class="mobile-associated-box-input-labal">{{$t('MobileAssociatedReplace.phone')}}:</span>
                <input type="tel" v-model="phone" class="mobile-associated-box-input" :placeholder="$t('MobileAssociatedReplace.phoneInput')" />
            </div>
            <div class="items-center justify-end mobile-associated-box-input-parent">
                <!--图像验证码-->
                <div class="w-full flex flex-col">
                    <div class="w-full flex flex-row">
                        <span class="mobile-associated-box-input-labal">{{$t('mobileLogin.graphicCode')}}:</span>
                        <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="mobile-associated-box-input" style="width: 60%" />
                    </div>
                    <div class="w-full flex flex-row justify-center items-end mt-5">
                        <img @click="loadCodeImg" style="width:120px;" :src="imgCodeUrl" alt="验证码"/>
                        <span class="mobile-associated-box-imgcode-change" @click="loadCodeImg">{{$t('MobileAssociatedReplace.change')}}</span>
                    </div>
                </div>
            </div>
            <div class="mobile-associated-box-input-parent" v-show="imgCode.length === 4">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedReplace.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedReplace.codeInput')" class="mobile-associated-box-input" />
                <span @click="getPhoneCode" v-if="isCode" class="mobile-associated-box-code-button">{{$t('MobileAssociatedReplace.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-associated-box-code-button">{{$t('MobileAssociatedReplace.resendCode')}}{{ time }}s</span>
            </div>
            <button class="mobile-associated-box-button" @click="AddPhoneAssociated">{{$t('MobileAssociatedReplace.confirm')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.setType = this.$route.params.type;
        this.useremail = this.$route.params.useremail;
        this.userphone = this.$route.params.userphone;
        this.topicTitle = this.$t('MobileAssociatedReplace.title1');
        if (this.setType === "email") {
            this.verifyTopic = this.$t('MobileAssociatedReplace.sendToEmail') + this.useremail;
        } else if (this.setType === "phone") {
            this.verifyTopic = this.$t('MobileAssociatedReplace.sendToPhone') + this.userphone;
        } else {
            this.webError = true;
        }
        this.loadCodeImg();
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            webError: false, // 由于用了param传值，如果用户刷新，则值消失
            useremail: "",
            userphone: "",
            isverifing: false, // 是否正在身份认证，初始进入页面时先身份认证，再绑定新账号
            topicTitle: "", // 标题名称,根据是邮箱还是手机号修改名称
            verifyTopic: "", // 发送到的账户
            setType: "", // 本次添加关联的类型，分为邮箱email和手机号phone
            email: "", // 邮箱
            phone: "", // 手机号
            code: "", // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            imgCode: "", // 手机端图像验证码
            imgCodeUrl: "", // 手机端图像验证码的url
            timer: null, // 计时器
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        checkPassword() {
            // 点击密码输入框后检查密码格式是否错误
            const re = /^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        /*加载图像验证码*/
        loadCodeImg(){
            ///register/captcha
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },
        setMyInterval() {
            // 设置计时器
            this.time = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false;
        },

        getVerifyCode() {
            if (this.imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return;
            }
            // 身份认证获取验证码
            if (this.setType === "email") {
                this.setMyInterval();
                this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
                this.$http
                    .post("/v1/index/get-verification-code?useremail=" + this.useremail + '&code=' + this.imgCode)
                    .then((res) => {
                        if (res.data.status === 200) {
                            this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessfully'));
                        } else if (res.data.status === 201) {
                            this.$toast.fail(this.$t('all.图形验证码错误'));
                            this.imgCode = '';
                            this.loadCodeImg();
                        } else if (res.data.status === 202) {
                            this.$toast.fail(this.$t('all.codeLimit'));
                        } else {
                            this.$toast.fail(this.$t('MobileAssociatedReplace.alertCodeError'));
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(this.$t('MobileAssociatedReplace.alertAxiosError'));
                    });
            } else if (this.setType === "phone") {
                this.setMyInterval();
                this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
                this.$http
                    .post("/v1/register/phone-verificationcode?userphone=" + this.userphone + '&code=' + this.imgCode)
                    .then((res) => {
                        if (res.data.status === 200) {
                            this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessfully'));
                        } else if (res.data.status === 201) {
                            this.$toast.fail(this.$t('all.图形验证码错误'));
                            this.imgCode = '';
                            this.loadCodeImg();
                        } else if (res.data.status === 202) {
                            this.$toast.fail(this.$t('all.codeLimit'));
                        } else {
                            this.$toast.fail(this.$t('MobileAssociatedReplace.alertCodeError'));
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(this.$t('MobileAssociatedReplace.alertAxiosError'));
                    });
            }
        },
        nextStep() {
            // 身份认证下一步
            if (this.code === "") {
                this.$toast(this.$t('MobileAssociatedReplace.alertNoCodeInput'));
                return;
            }
            let data = {};
            if (this.setType === "email") {
                data = {
                    loginname: this.useremail,
                    code: this.code,
                };
            } else if (this.setType === "phone") {
                data = {
                    loginname: this.userphone,
                    code: this.code,
                };
            }
            this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
            this.$http
                .post("/v1/register/user-update-password-authentication", data)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.topicTitle = this.$t('MobileAssociatedReplace.alertPhoneError');
                        this.isverifing = false;
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else {
                        this.$toast.fail(this.$t('AccountEmail.alertBindFail'));
                    }
                })
                .catch((err) => {
                    this.$toast.fail(this.$t('MobileAssociatedReplace.alertAxiosError'));
                });
        },

        getEmailCode() {
            // 获取邮箱验证码
            if (!this.email.includes("@")) {
                this.$toast(this.$t('MobileAssociatedReplace.alertEmailError'));
                return;
            } else if (this.imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return;
            }
            this.setMyInterval();
            this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
            this.$http
                .post("/v1/index/get-verification-code?useremail=" + this.email + '&code=' + this.imgCode)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessfully'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('MobileAssociatedReplace.alertCodeError'));
                    }
                })
                .catch((err) => {
                    this.$toast.fail(this.$t('MobileAssociatedReplace.alertAxiosError'));
                });
        },
        AddEmailAssociated() {
            if (!this.email.includes("@")) {
                this.$toast(this.$t('MobileAssociatedReplace.alertEmailError'));
                return;
            }
            if (this.code === "") {
                this.$toast(this.$t('MobileAssociatedReplace.alertNoCodeInput'));
                return;
            }
            this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
            this.$http
                .post("/v1/register/association?contact=" + this.email + "&code=" + this.code)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountEmail.alertChangeSuccessfully'));
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                })
                .catch((err) => {
                    this.$toast(this.$t('MobileAssociatedReplace.alertAxiosError'));
                });
        },


        
        getPhoneCode() {
            // 获取手机验证码
            const re = /^[0-9]*$/;
            if (this.phone.length === 0 || !re.test(this.phone)) {
                this.$toast(this.$t('MobileAssociatedReplace.alertPhoneError'));
                return;
            } else if (this.imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return;
            }
            this.setMyInterval();
            this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
            this.$http
                .post("/v1/register/phone-verificationcode?userphone=" + this.phone+"&code="+this.imgCode)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessfully'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('MobileAssociatedReplace.alertCodeError'));
                    }
                })
                .catch((err) => {
                    this.$toast(this.$t('MobileAssociatedReplace.alertAxiosError'));
                });
        },
        AddPhoneAssociated() {
            const re = /^[0-9]*$/;
            if (this.phone.length === 0 || !re.test(this.phone)) {
                this.$toast(this.$t('MobileAssociatedReplace.alertPhoneError'));
                return;
            }
            if (this.code === "") {
                this.$toast(this.$t('MobileAssociatedReplace.alertNoCodeInput'));
                return;
            }
            this.$toast(this.$t('MobileAssociatedReplace.alertLoading'));
            this.$http
                .post("/v1/register/association?contact=" + this.phone + "&code=" + this.code)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountPhone.alertChangeSuccessfully'));
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                })
                .catch((err) => {
                    this.$toast(this.$t('MobileAssociatedReplace.alertAxiosError'));
                });
        },
    },

    destroyed() {
        clearInterval(this.timer);
    },
};
</script>

<style>
.mobile-associated-form-topic {
    /* 小标题 */
    font-size: 20px;
    padding: 10px;
}
.mobile-associated-box-input-parent {
    /* 输入框样式 */
    width: 90%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.mobile-associated-box-input-labal {
    /* 输入框前文字信息样式 */
    width: 70px;
    display: flex;
    align-items: center; /* 水平居中 */
}
.mobile-associated-box-input {
    /* 输入框样式 */
    height: 50px;
    flex-grow: 1;
    font-size: 14px;
    color: #dcdcdc;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
}
.mobile-associated-box-imgcode-change {
    /* 看不清，换一个-更换验证码 */
    border-bottom: 1px solid #000000;
    font-size: 14px;
    color: #bcbcbc;
    margin-left: 10px;
}
.mobile-associated-box-code-button {
    /* 获取验证码按钮 */
    height: 40px;
    padding-left: 10px;
    border: none;
    border-left: 1px solid #dcdcdc;
    display: flex;
    align-items: center; /* 水平居中 */
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 5px;
}
.mobile-associated-box-container {
    /* 手机端登陆页面 */
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-associated-box-button {
    /* 按钮样式 */
    height: 45px;
    width: 90%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #3662EC;
    border-radius: 30px;
    box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
    margin-top: 40px;
    margin-bottom: 20px;
}
</style>
