<template>
    <!-- 页面背景-body之下第一层布置 -->
    <div class="main_container">
        <!-- 给登录页面设置一个块，里面包含logo、公司名、登录框，这样可以作为父块让它们水平垂直居中 -->
        <div class="form_container" ref="loginBox">
            <!-- logo -->
            <div class="logo_container">
                <img src="../images/logo.png" class="logoImg_container">
            </div>
            <!-- 公司名称 -->
            <div class="logo_container">
                <span>{{$t('computerlogin.datuland')}}</span>
            </div>
            <!-- 登录框，包含左边的讲解图区域和右边的登录/注册输入区域，再加个边框，更美观 -->
            <div class="shadow-lg border border-gray-400 rounded-md bg-white flex flex-row">
                <!-- 左半边-讲解图 -->
                <div class="boot">
                    <el-carousel height="600px">
                        <el-carousel-item v-for="item in images" :key="item">
                            <div class="small">
                                <img :src="item" style="height:600px;object-fit:contain;">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- 右半边-登录框-邮箱登录/注册 -->
                <div class="loginTab" v-if="computer.mode === 'login'">
                    <!-- 登录标签模块—密码登录—让用户选择自己是普通用户还是企业用户登录 -->
                    <div class="login-tabs-parent justify-center" v-if="lastLoginMethod==='account'">
                        <!-- <div class="login-tabs-item">
                            <div class="login-tabs-item-text" :class="computer.isCompany?'':'login-tabs-item-text-select'" @click="computer.isCompany=false;">{{$t('computerlogin.userLogin')}}</div>
                        </div> -->
                        <div class="login-tabs-item">
                            <div class="login-tabs-item-text" :style="computer.isCompany?'color:#1D2129;':''" @click="computer.isCompany=true;">
                                <span>{{$t('computerlogin.developerLogin')}}</span>
                                <div class="login-tabs-item-text-select" v-if="computer.isCompany"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 三合一账号登陆-普通用户登录 -->
                    <div class="flex flex-col justify-center items-center" style="width:600px;" v-if="lastLoginMethod==='account'">
                        <!-- 账号三合一区域 -->
                        <div class="input_with_select" style="margin-top:40px;">
                            <img class="login-input-icon" src="../developer/login-loginname.png">
                            <input type="text" :placeholder="$t('computerlogin.passwordLoginAccountInput')" v-model="computer.accountLogin">
                        </div>
                        <!-- 密码区域 -->
                        <div class="input_with_select" style="margin-top:20px;">
                            <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                            <input :type="showPassword ? '' : 'password'" :placeholder="$t('computerlogin.passwordLoginPasswordInput')" v-model="computer.pswdLogin">
                            <img src="../adminImage/admin-login-show.png" class="login-input-icon-show" v-if="showPassword" @click="togglePassword">
                            <img src="../adminImage/admin-login-hide.png" class="login-input-icon-show" v-else @click="togglePassword">
                        </div>
                        <div class="flex flex-row mt-4 w-4/5">
                            <el-checkbox v-model="computer.remember" style="font-size:20px;">{{$t('computerlogin.remember')}}</el-checkbox>
                            <span class="flex-grow text-right forget-password cursor-pointer" style="font-size:20px;" @click="gotoForget">{{$t('computerlogin.forgotPassword')}}</span>
                        </div>
                        <el-button class="button_container2" style="margin-top:20px;" @click="computerLogin">{{$t('computerlogin.login')}}</el-button>
                        <!-- <div class="computer-changemode" @click="computer.mode = 'register';computer.graphicCodeImg = null;"><el-link style="font-size:22px;">{{$t('computerlogin.goToRegistration')}}</el-link></div> -->
                        <!-- 切换密码登录和验证码登录的按钮区域 -->
                        <div class="change-mode-parent">
                            <div class="change-mode-item change-mode-item-select">
                                <div class="change-mode-circle change-mode-circle-select">
                                    <img src="../developer/login-password-select.png">
                                </div>
                                <span style="font-size: 20px;">{{$t('computerlogin.passwordLogin')}}</span>
                            </div>
                            <div class="change-mode-item" style="margin-left:20px;">
                                <div class="change-mode-circle" @click="lastLoginMethod='phone'">
                                    <img src="../developer/login-code.png">
                                </div>
                                <span style="font-size: 20px;">{{$t('computerlogin.codeLogin')}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 登录标签页—手机验证码登录—让用户选择自己是普通用户还是企业用户登录 -->
                    <div class="login-tabs-parent justify-center" v-if="lastLoginMethod==='phone'">
                        <div class="login-tabs-item">
                            <div class="login-tabs-item-text" :style="computer.isCompany?'color:#1D2129;':''" @click="computer.isCompany=true;">
                                <span>{{$t('computerlogin.developerLogin')}}</span>
                                <div class="login-tabs-item-text-select" v-if="computer.isCompany"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 手机验证码登陆-仅限普通用户登录 -->
                    <div class="flex flex-col justify-center items-center" style="width:600px;" v-if="lastLoginMethod==='phone'">
                        <!-- 手机号输入区域 -->
                        <div class="input_with_select" style="margin-top:40px;">
                            <img class="login-input-icon" src="../developer/login-loginname.png">
                            <input type="phone" :placeholder="$t('computerlogin.passwordLoginAccountInput')" v-model="computer.codeLogin" @focus="applyForGraphicCode">
                        </div>
                        <!-- 图像验证码区域 -->
                        <div class="flex flex-row w-4/5" style="margin-top:20px;" v-show="computer.graphicCodeImg">
                            <div class="input_with_select">
                                <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                                <input :placeholder="$t('computerlogin.codeLoginGraphicInput')" v-model="computer.graphicCode">
                            </div>
                            <!-- 点击密码框申请图形验证码，显示在密码框下方 -->
                            <el-tooltip class="item" effect="dark" :content="$t('computerlogin.changeImage')" placement="top-start">
                                <div class="flex flex-col cursor-pointer" style="margin-left:10px;" @click="resetGraphicCode">
                                    <img :src="computer.graphicCodeImg" class="form-right-graphicCode-img">
                                    <!-- <el-link class="form-right-changeGraphic">{{$t('computerlogin.changeImage')}}</el-link> -->
                                </div>
                            </el-tooltip>
                        </div>
                        <!-- 手机短信验证码区域 -->
                        <div class="flex flex-row w-4/5" style="margin-top:20px;">
                            <div class="input_with_select">
                                <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                                <input :type="showPassword ? '' : 'password'" :placeholder="$t('computerlogin.codeLoginPhoneInput')" v-model="computer.code.mobileCode">
                                <img src="../adminImage/admin-login-show.png" class="login-input-icon-show" v-if="showPassword" @click="togglePassword">
                                <img src="../adminImage/admin-login-hide.png" class="login-input-icon-show" v-else @click="togglePassword">
                            </div>
                            <!-- 点击密码框申请验证码，显示在密码框下方 -->
                            <div class="login-input-code-get" style="margin-left:10px;">
                                <span v-if="computer.code.TimeCount === 0" @mouseover="changeCursor" slot="append" @click="phoneLoginGetCode">{{ $t('computerlogin.getCode') }}</span>
                                <span v-else slot="append">{{ $t('computerlogin.resendCode') }} {{ computer.code.TimeCount }}s</span>
                            </div>
                        </div>
                        <el-button class="button_container2" style="margin-top:20px;" @click="computerLogin">{{$t('computerlogin.login')}}</el-button>
                        <!-- <div class="computer-changemode" @click="computer.mode = 'register';computer.graphicCodeImg = null;"><el-link style="font-size:22px;">{{$t('computerlogin.goToRegistration')}}</el-link></div> -->
                        <!-- 切换密码登录和验证码登录的按钮区域 -->
                        <div class="change-mode-parent">
                            <div class="change-mode-item">
                                <div class="change-mode-circle" @click="lastLoginMethod='account'">
                                    <img src="../developer/login-password.png">
                                </div>
                                <span style="font-size: 20px;">{{$t('computerlogin.passwordLogin')}}</span>
                            </div>
                            <div class="change-mode-item change-mode-item-select" style="margin-left:20px;">
                                <div class="change-mode-circle change-mode-circle-select">
                                    <img src="../developer/login-code-select.png">
                                </div>
                                <span style="font-size: 20px;">{{$t('computerlogin.codeLogin')}}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- 右半边-忘记密码框-邮箱、手机号注册 -->
                <div class="loginTab relative" v-if="computer.mode === 'forget'">
                    <!-- 返回上一级 -->
                    <div class="login-back" @click="backtoLogin">
                        <img src="../developer/login-back.png">
                        <span class="ml-3">{{$t('all.backTo')}}</span>
                    </div>
                    <!-- 忘记密码标题 -->
                    <div class="login-tabs-parent justify-center">
                        <div class="login-tabs-item">
                            <div class="login-tabs-item-text" style="color:#1D2129;">
                                <span>{{$t('forgetPassword.title')}}</span>
                                <div class="login-tabs-item-text-select"></div>
                            </div>
                        </div>
                    </div>
                    <!-- ------------------------------------忘记密码输入区域---------------------------------------- -->
                    <div class="flex flex-col justify-center items-center" style="width:600px;">
                            <!-- 账号三合一区域 -->
                            <div class="input_with_select" style="margin-top:40px;">
                                <img class="login-input-icon" src="../developer/login-loginname.png">
                                <input type="text" :placeholder="$t('forgetPassword.passwordLoginAccountInput')" v-model="computer.accountForget" @input="applyForGraphicCode">
                            </div>
                            <!-- 图像验证码区域 -->
                            <div class="flex flex-row w-4/5" style="margin-top:20px;" v-show="computer.graphicCodeImg">
                                <div class="input_with_select">
                                    <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                                    <input :placeholder="$t('computerlogin.codeLoginGraphicInput')" v-model="computer.graphicCode">
                                </div>
                                <!-- 点击密码框申请图形验证码，显示在密码框下方 -->
                                <el-tooltip class="item" effect="dark" :content="$t('computerlogin.changeImage')" placement="top-start">
                                    <div class="flex flex-col cursor-pointer" style="margin-left:10px;" @click="resetGraphicCode">
                                        <img :src="computer.graphicCodeImg" class="form-right-graphicCode-img">
                                    </div>
                                </el-tooltip>
                            </div>
                            <!-- 邮箱/手机号验证码区域 -->
                            <div class="flex flex-row w-4/5" style="margin-top:20px;">
                                <div class="input_with_select">
                                    <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                                    <input :placeholder="$t('computerlogin.alertEntercode')" v-model="computer.code.mobileCode">
                                </div>
                                <!-- 点击获取验证码申请验证码 -->
                                <div class="login-input-code-get" style="margin-left:10px;">
                                    <span v-if="computer.code.TimeCount === 0" @mouseover="changeCursor" slot="append" @click="getVerificationCode">{{ $t('computerlogin.getCode') }}</span>
                                    <span v-else slot="append">{{ $t('computerlogin.resendCode') }} {{ computer.code.TimeCount }}s</span>
                                </div>
                            </div>
                            <!-- 新密码区域 -->
                            <div class="input_with_select" style="margin-top:20px;">
                                <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                                <input :type="showPassword ? '' : 'password'" :placeholder="$t('forgetPassword.newPassword')" v-model="computer.newpassword" @input="checkPassword">
                                <img src="../adminImage/admin-login-show.png" class="login-input-icon-show" v-if="showPassword" @click="togglePassword">
                                <img src="../adminImage/admin-login-hide.png" class="login-input-icon-show" v-else @click="togglePassword">
                            </div>
                            <!-- 确认密码区域 -->
                            <!-- <div class="input_with_select" style="margin-top:40px;">
                                <img class="login-input-icon" src="../adminImage/admin-login-loginpassword.png">
                                <input :type="showPassword ? '' : 'password'" :placeholder="$t('forgetPassword.newPassword2')" v-model="computer.newpassword2">
                                <img src="../adminImage/admin-login-show.png" class="login-input-icon-show" v-if="showPassword2" @click="togglePassword2">
                                <img src="../adminImage/admin-login-hide.png" class="login-input-icon-show" v-else @click="togglePassword2">
                            </div> -->
                            <div class="flex flex-row w-4/5" style="margin-top:20px;">
                                <div class="flex flex-row items-center">
                                    <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules1">
                                    <img src="../developer/safe-access.png" class="infor-icon" v-else>
                                    <span class="developer-account-input-tips" :style="showRules1?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip1')}}</span>
                                </div>
                                <div class="flex flex-row items-center">
                                    <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules2">
                                    <img src="../developer/safe-access.png" class="infor-icon" v-else>
                                    <span class="developer-account-input-tips" :style="showRules2?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip2')}}</span>
                                </div>
                                <div class="flex flex-row items-center">
                                    <img src="../adminImage/admin-company-attention.png" class="infor-icon" v-if="showRules3">
                                    <img src="../developer/safe-access.png" class="infor-icon" v-else>
                                    <span class="developer-account-input-tips" :style="showRules3?'':'color: rgba(0, 0, 0, 0.4);'">{{$t('AccountPassword.tip3')}}</span>
                                </div>
                            </div>
                        <!-- 确认按钮 -->
                        <el-button class="button_container2" style="margin-top:20px;" @click="forgetPasswordChange">{{$t('forgetPassword.resetPassword')}}</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部许可证（已放弃）/生产编号等位置 -->
        <div class="footer_container">
            <!-- <span style="font-size: 24px;margin-bottom: 10px;">{{ $t('computerlogin.licenseNumber') }}</span>
            <span style="font-size: 24px;margin-bottom: 10px;">{{ $t('computerlogin.businessLicense') }}</span> -->
            <span style="font-size: 24px;">{{ $t('computerlogin.copyright') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        if (localStorage.getItem('loginMethod')==='developer') { // 企业登录
            this.computer.isCompany = true;
        } else if (localStorage.getItem('loginMethod')==='phone') { // 普通用户手机验证码登录
            this.lastLoginMethod = 'phone';
        }
        if (localStorage.getItem('p-n')) {
            this.computer.accountLogin = localStorage.getItem('p-n');
            this.computer.pswdLogin = localStorage.getItem('p-p');
        }
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
    },
    beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.updateModalScale);
    },
    data () {
        return {
            images: [ // 首页轮播图
                require('@/images/propagandizeImage1.jpg'),
                require('@/images/propagandizeImage2.jpg'),
                require('@/images/propagandizeImage3.jpg'),
                require('@/images/propagandizeImage4.jpg'),
            ],

            lastLoginMethod: 'account', // 根据用户上一次登录方式自动选择登录方式，有account三合一登录，或phone手机号登录

            computer: {
                mode: 'login', // 模式-注册还是登陆,初始为登陆
                loading: false, // elui组件加载的判断条件，点击注册/登录/获取验证码时有一段等待请求的时间，这时候要中止操作
                activeName: 'first', // tab标签选择邮箱、手机号、用户名分别为first、second、third
        
                /* 下面几个是用户登录的输入信息 */
                accountLogin: '', // 三合一账号，需要用户输入
                pswdLogin: '', // 登陆密码直接用一个参数共用
                codeLogin: '', // 验证码登陆，暂时共用邮箱、手机号
                remember: true, // 是否记住账号密码，true为记住，false为不记住
        
                /* 下面几个是用户注册的输入信息 */
                emailRegistration: '', // 同样需要加select
                phoneArea: '+86', // 手机前缀，代表手机地区
                phoneRegistration: '', // 手机号注册
                pswdRegistration: '', // 注册也共用此参数

                /* 下面几个是用户忘记密码的输入信息 */
                accountForget: '', // 三合一账号
                newpassword: '', // 新密码
                newpassword2: '', // 二次确认新密码

                /* 验证码所需参数实例 */
                code: {
                    TimeCount: 0,   // 控制获取验证码按钮的实例，与计时器同步
                    text1: '获取验证码',
                    text2: '重新发送',
                    timer: null,  // 计时器
                    mobileCode: '', // 手机号获取验证码的验证码输入
                },

                /* 图形验证码 */
                graphicCode: '',
                graphicCodeImg: null,
                /* 控制图形验证码重置频率 */
                graphicCodeFlag: true,
                /* 这是关于用户是否点击同意协议的参数 */
                radio: '',
                /* 这是关于用户登陆时说自己是否是企业用户的参数 */
                isCompany: true,
                /* 这两个是返回时接收的消息存储的变量 */
                message: '',
                logindata: '',
            },
            rules: {
                accountLogin: [
                    { required: true, message: this.$t('computerlogin.alertEnterEmail'), trigger: 'blur' },
                ],
                pswdLogin: [
                    { required: true, message: this.$t('computerlogin.alertEnterpswd'), trigger: 'blur' },
                ],
                codeLogin: [
                    { required: true, message: this.$t('computerlogin.alertEntercodeLogin'), trigger: 'blur' },
                ],
                accountLoginCode: [
                    { required: true, message: this.$t('computerlogin.alertEntergraphic'), trigger: 'blur' },
                ],
                code: [
                    { required: true, message: this.$t('computerlogin.alertEntercode'), trigger: 'blur' },
                ],
                emailRegister: [
                    { required: true, message: this.$t('computerlogin.alertEnteremail'), trigger: 'blur' },
                ],
                phoneRegister: [
                    { required: true, message: this.$t('computerlogin.alertEnterphone'), trigger: 'blur' },
                ],
            },
            
            showPassword: false, // 改变密码显示/隐藏状态的参数
            showPassword2: false, // 忘记密码确认密码显示/隐藏
            showRules1: true,
            showRules2: true,
            showRules3: true,
            forgetPasswordStep: true, // 忘记密码步骤，true是第一步身份验证，false是第二步密码
            
            showRules: false, // 注册时密码格式校验，出现问题时显示true

            vmodal: false, // 手机端邮箱注册时获取验证码的弹窗，由于邮箱验证码获取太慢，给用户一个提示
        }
    },
    methods: {
        // 根据屏幕大小自适应弹窗大小
        updateModalScale() {
            const modal = this.$refs.loginBox;
            if (window.innerWidth >= 1900 && window.innerHeight >= 980) {
                modal.style.transform = `scale(1)`;
            } else {
                const scaleX = (window.innerWidth - 100) / 1800;
                const scaleY = (window.innerHeight - 100) / 880;
                const scale = Math.min(scaleX, scaleY);
                modal.style.transform = `scale(${scale})`;
            }
        },
        /* --------------------------------------------通用------------------------------------------------ */

        /* 点击眼睛图标显示/隐藏密码 */
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        togglePassword2() {
            this.showPassword2 = !this.showPassword2;
        },

        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* --------------------------------------------电脑端------------------------------------------------ */
    
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            const re = /^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/;
            this.showRules = !re.test(this.computer.pswdRegistration);
        },
    
        /* 邮箱登录 */
        computerLogin () {
            this.computer.loading = true;
            if(this.computer.isCompany) { // 企业用户登录
                // 设置raw格式数据
                const data1 = '{"businessemail":"' +  this.computer.emailLogin + this.computer.select + '","businesspassword":"' + this.computer.pswdLogin + '"}'
                const data = {
                    businessemail: this.computer.accountLogin,
                    businesspassword: this.computer.pswdLogin,
                    isRemPwd: true
                }
                // 向后端发送数据 测试地址http://192.168.1.145:8092/index/login?loginname=18766213616@163.com&loginpassword=123456
                this.$http.post('/v1/index/business-login', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        withCredentials: true,
                    },
                }).then(response => {
                    if(response.data.msg === '登陆成功') {
                        localStorage.setItem('loginMethod','developer'); // 记录本次登录方式
                        if (this.computer.remember) { // 记住账号密码
                            localStorage.setItem('p-n',this.computer.accountLogin);
                            localStorage.setItem('p-p',this.computer.pswdLogin);
                        }
                        if (response.data.data.status === "已上传土地") {
                            this.$store.dispatch("developer/fetchUserInfo"); // 加载开发商用户个人信息
                            this.$router.push('/developer')
                        } else {
                            this.$store.dispatch("developer/fetchUserInfo");
                            this.$router.push('/login/register')
                        }
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountIncorrect'));
                        this.computer.loading = false;
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                    this.computer.loading = false;
                });
            } else { // 普通用户登录
                if (this.computer.accountLogin && this.computer.pswdLogin) {} else {
                    this.$toast.fail(this.$t('computerlogin.alertNoAccount'))
                    this.computer.loading = false
                    return
                }
                const data = {
                    loginname: this.computer.accountLogin,
                    loginpassword: this.computer.pswdLogin
                }
                // 向后端发送数据 测试地址http://192.168.1.145:8092/index/login?loginname=18766213616@163.com&loginpassword=123456
                this.$http.post('/v1/index/login', data,{
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(response => {
                    if (response.data.data==="登陆成功") { // PC端登录
                        localStorage.setItem('loginMethod','account'); // 记录本次登录方式
                        this.$router.push('/pc/home-page')
                    } else {
                        this.computer.loading = false
                        this.$toast.fail(this.$t('all.loginFail'))
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'))
                    this.computer.loading = false
                });
            }
        },

        /* 手机验证码登陆获取验证码 */
        phoneLoginGetCode() {
            if (this.computer.codeLogin) {
                this.$toast.fail(this.$t('computerlogin.alertNoCodeLogin'))
                return
            }
            if (this.computer.graphicCode === '') {
                this.$toast(this.$t('computerlogin.alertNoGraphicCode'))
                return
            }
            this.startCountdown();
            this.$http.post('/v1/register/phone-verificationcode?userphone=' + this.computer.codeLogin)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessfully'))
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.computer.graphicCode = '';
                        this.resetGraphicCode();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'))
                });
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.computer.code.TimeCount = 60;
            // 计时器
            this.computer.code.timer = setInterval(() => {
                if (this.computer.code.TimeCount > 0) {
                    this.computer.code.TimeCount--;
                } else {
                    clearInterval(this.computer.code.timer);
                    this.computer.code.timer = null;
                }
            }, 1000);
        },

        /* 手机验证码登录 */
        codeLogin() {
            this.computer.loading = true
                let data = null
                if (!this.computer.codeLogin) {
                    this.$toast.fail(this.$t('computerlogin.alertNoCodeLogin'))
                    this.computer.loading = false
                    return
                }
                if (!this.computer.code.mobileCode) {
                    this.$toast.fail(this.$t('computerlogin.alertNoCode'))
                    this.computer.loading = false
                    return
                }
                if (this.computer.codeLogin.test(/^[0-9]*$/)) {
                    data = {
                        userphone: this.computer.codeLogin,
                        remark: this.computer.code.mobileCode
                    }
                } else {
                    this.$toast.fail(this.$t('computerlogin.alertPhoneError'))
                    this.computer.loading = false
                    return
                }
                // 向后端发送数据 测试地址http://192.168.1.145:8092/index/login?loginname=18766213616@163.com&loginpassword=123456
                this.$http.post('/v1/index/login', data,{
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(response => {
                    if (response.data.msg==="登陆成功") { // PC端登录
                        localStorage.setItem('loginMethod','phone'); // 记录本次登录方式
                        this.$router.push('/pc')
                    } else {
                        this.computer.loading = false
                        this.$toast.fail(this.$t('all.loginFail'))
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'))
                    this.computer.loading = false
                });
        },
    
    
        /* 邮箱注册 */
        emailRegister () {
            /* 判断各种不能进行下一步的情况：1.邮箱、密码任一为空。2.密码校验失败 */
            if (this.computer.emailRegistration && this.computer.pswdRegistration && !this.showRules) {
                // 用户需要同意协议
                if (this.radio === '1') {
                    this.computer.loading = true
                    // 设置请求体（键值对）格式数据
                    const data = {
                        useremail: this.computer.emailRegistration + this.computer.select,
                        userpassword: this.computer.pswdRegistration
                    }
                    // 向后端发送请求，这里要将邮箱（？）、密码、验证码一起发送过去
                    this.$http.post('/v1/register/useremail-register',data , {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        this.computer.message = response.data.data
                        if (this.computer.message==="注册成功验证码发送成功") {
                            this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessful'))
                        } else if (this.computer.message==="用户已存在") {
                            this.$toast.fail(this.$t('computerlogin.alertEmailRegisted'));
                            this.computer.loading = false;
                        }
                    }).catch(error => {
                        this.$toast.fail(this.$t('computerlogin.alertAxiosError'))
                        this.computer.loading = false
                    });
                } else {
                    this.$toast(this.$t('computerlogin.alertNoAgree'))
                }
            } else {
                this.$toast(this.$t('computerlogin.alertAccountError'))
            }
        },

        /* 手机号注册 */
        phoneRegister () {
            if (this.computer.phoneRegistration === '' || this.computer.code.mobileCode === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoPhoneCode'))
            }
            /* 判断各种不能进行下一步的情况：1.邮箱、密码任一为空。2.密码校验失败 */
            if (this.computer.phoneRegistration.test(/^[0-9]*$/)) {
                // 用户需要同意协议
                if (this.radio === '1') {
                    this.computer.loading = true
                    // 设置请求体（键值对）格式数据
                    const data = {
                        userphone: this.computer.phoneRegistration,
                        remark: this.computer.code.mobileCode
                    }
                    // 向后端发送请求，这里要将邮箱（？）、密码、验证码一起发送过去
                    this.$http.post('/v1/register/userphone-register',data , {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        this.computer.message = response.data.data
                        if (this.computer.message==="注册成功验证码发送成功") {
                            this.$toast.success(this.computer.message)
                            this.$router.push('/pc')
                        } else if (this.computer.message==="手机号已存在") {
                            this.$toast.fail(this.$t('computerlogin.alertPhoneRegisted'));
                            this.computer.loading = false;
                        }
                    }).catch(error => {
                        this.$toast.fail(this.$t('computerlogin.alertAxiosError'))
                        this.computer.loading = false
                    });
                } else {
                    this.$toast.fail(this.$t('computerlogin.alertNoAgree'))
                }
            } else {
                this.$toast(this.$t('computerlogin.alertAccountError'))
            }
        },

        /* 申请图片验证码 */
        applyForGraphicCode() {
            if (!this.computer.graphicCodeImg) {
                this.$http.get('/v1/register/captcha',{
                        responseType: "blob", // 设置响应类型为blob
                    })
                    .then(response => {
                        const imageBlob = response.data;
                        const imageUrl = URL.createObjectURL(imageBlob);
                        this.computer.graphicCodeImg = imageUrl
                    }).catch(error => {
                        this.computer.graphicCodeImg = this.$t('computerlogin.alertImgError')
                    });
            }
        },

        /* 重置图片验证码 */
        resetGraphicCode() {
            if (this.computer.graphicCodeFlag) {
                this.computer.graphicCodeFlag = false
                this.$http.get('/v1/register/captcha',{
                        responseType: "blob", // 设置响应类型为blob
                    })
                    .then(response => {
                        const imageBlob = response.data;
                        const imageUrl = URL.createObjectURL(imageBlob);
                        this.computer.graphicCodeImg = imageUrl
                        this.computer.graphicCodeFlag = true
                    }).catch(error => {
                        this.computer.graphicCodeImg = this.$t('computerlogin.alertImgError')
                        this.computer.graphicCodeFlag = true
                    });
            }
        },

        /* --------------------------------------------忘记密码------------------------------------------------ */

        // 切换到忘记密码模式
        gotoForget() {
            this.computer.mode = 'forget';
            this.computer.graphicCode = '';
            this.computer.graphicCodeImg = null;
            this.showPassword = false;
            this.showPassword2 = false;
            clearInterval(this.computer.code.timer);
            this.computer.code.TimeCount = 0;
            this.computer.code.mobileCode = '';
            this.forgetPasswordStep = true;
        },

        // 切换回登录模式
        backtoLogin() {
            this.computer.mode = 'login';
            this.computer.graphicCode = '';
            this.computer.graphicCodeImg = null;
            clearInterval(this.computer.code.timer);
            this.computer.code.TimeCount = 0;
            this.computer.code.mobileCode = '';
        },

        setMyInterval() { // 设置计时器
            this.computer.code.TimeCount = 60
            // 计时器
            this.computer.code.timer = setInterval(() => {
                if (this.computer.code.TimeCount > 0) {
                    this.computer.code.TimeCount--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        // 忘记密码获取验证码
        getVerificationCode() {
            const re = /^[0-9]+$/;
            if(this.computer.accountForget === '') {
                this.$toast.fail(this.$t('forgetPassword.alertNoAccountInput'));
                return;
            } else if (!re.test(this.computer.accountForget) && !this.computer.accountForget.includes('@')) {
                this.$toast.fail(this.$t('forgetPassword.alertAccountInputError'));
                return;
            } else if (this.computer.graphicCode === '') {
                this.$toast.fail(this.$t('forgetPassword.alertNoimgCodeInput'));
                return;
            }
            this.setMyInterval();
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.post('/v1/index/business-get-verification-code',{
                businessemail: this.computer.accountForget,
                code: this.computer.graphicCode
                }).then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.computer.graphicCode = '';
                        this.resetGraphicCode();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                    }
                }).catch(err => {
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },

        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            // 原正则表达式：/^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/
            const re1 = /^.{8,16}$/;
            const re21 = /^(?=.*[A-Za-z])(?=.*\d).{2,}$/; // 包含字母和数字
            const re22 = /^(?=.*[A-Za-z])(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含字母和符号
            const re23 = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字和符号
            const re24 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/; // 包含数字、字母和符号
            const re3 = /\s/; // 包含空格
            this.showRules1 = !re1.test(this.computer.newpassword);
            if (re21.test(this.computer.newpassword) || re22.test(this.computer.newpassword) || re23.test(this.computer.newpassword) || re24.test(this.computer.newpassword)) {
                this.showRules2 = false;
            } else {
                this.showRules2 = true;
            }
            this.showRules3 = re3.test(this.computer.newpassword);
        },

        // 修改密码
        forgetPasswordChange() {
            const re = /^[0-9]+$/;
            if(this.computer.accountForget === '') {
                this.$toast.fail(this.$t('forgetPassword.alertNoAccountInput'));
                return;
            } else if (!re.test(this.computer.accountForget) && !this.computer.accountForget.includes('@')) {
                this.$toast.fail(this.$t('forgetPassword.alertAccountInputError'));
                return;
            } else if (this.computer.code.mobileCode === '') {
                this.$toast.fail(this.$t('forgetPassword.alertNoCodeInput'));
                return;
            } else if (this.computer.newpassword === '') {
                this.$toast.fail(this.$t('forgetPassword.setNewPassword'));
                return;
            } else if (this.showRules1 || this.showRules2 || this.showRules3) {
                this.$toast.fail(this.$t('forgetPassword.alertPasswordError'));
                return;
            }/*  else if (this.computer.newpassword2 !== this.computer.newpassword) {
                this.$toast.fail(this.$t('forgetPassword.alertNewpasswordError'));
                return;
            } */
            const data = {
                businessemail: this.computer.accountForget,
                businesspassword: this.computer.newpassword,
                code: this.computer.code.mobileCode
            }
            this.$http.post('/v1/index/business-forgot-pd', data)
                .then(res => {
                    // 处理返回值
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('forgetPassword.alertResetSuccessfully'));
                        this.backtoLogin();
                    } else {
                        this.$toast.fail(this.$t('all.验证失败'));
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                });
        },
    },
}
</script>

<style scoped>
/* 主页区域设置 */
.main_container {
  height: 100vh;
  width: 100vw;
  /* 背景图片 */
  background-image: url('../developer/background.png');
  background-size: cover;
  /* flex布局，子元素垂直水平居中 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
/* ------------------------------------------------以下是PC端---------------------------------------------------- */
/* 表单设置 */
.form_container {
  height: auto;
  width: auto;
  text-align: center;  /* 如果子元素是`inline`或`inline-block`类型的元素，则可以通过设置父元素的`text-align`属性为`center`来实现水平居中。 */
  margin: 0;
  padding: 0;
}
/* logo区域标识 */
.logo_container {
    height: auto;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}
/* logo图片设置 */
.logoImg_container {
    display: inline-block;
    margin: 0;
    max-height: 150px;
    max-width: 150px;
}
/* login框布置 */
.login_container {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    height: 400px;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}
/* 左半边引导图设置 */
.boot {
    padding: 30px;
    text-align: center;
    border-right: 1px solid #eff2f6;
    display: inline-block;
    width: 1100px;
    box-sizing: border-box;
}
/* 右半边登录框设置 */
.loginTab {
    height: 660px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
/* 标签页设置 */
.tab_container {
    height: 100%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login-tabs-parent {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.login-tabs-item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.login-tabs-item-text {
    font-size: 24px;
    font-weight: 600;
    color: #86909C;
    cursor: pointer;
}
.login-tabs-item-text-select {
    width: 30px;
    height: 5px;
    background-color: #165DFF;
    margin-top: 8px;
}
/* 登陆页面输入框长度设置 */
.form_item_container {
  width: 300px;
}
/* 账号登录/注册邮箱输入框设置 */
.input_with_select {
    width: 80%;
    height: 60px;
    border-radius: 10px;
    background-color: #F1F4F8;
    border: 1px solid #165DFF;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.input_with_select > input {
    height: 100%;
    width: 100%;
    background-color: #F1F4F8;
    font-size: 20px;
}
.login-input-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}
.login-input-icon-show {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    height: 24px;
    width: 24px;
    cursor: pointer;
}
/* 账号登录/注册密码显示/隐藏密码眼睛图标样式 */
.el_input_icon {
  display: inline-block;
}
/* 图形验证码区域 */
.form-right-graphiccode {
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/* 图形验证码图片格式设置 */
.form-right-graphicCode-img {
    height: 60px;
}
/* 更换图形验证码文字链接 */
.form-right-changeGraphic {
    font-size: 12px;
    color: yellow;
}
/* 获取验证码 */
.login-input-code-get {
    height: 60px;
    width: 180px;
    border-radius: 10px;
    background-color: #F1F4F8;
    border: 1px solid #165DFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
/* 各类按钮放入一个div中固定位置-包括忘记密码、登陆/注册按钮、切换登录注册模式等 */
.form-right-buttons {
    margin-top: 20px;
}
/* 注册按钮样式 */
.button_container {
  background-color: rgb(243, 176, 87);
  width:300px;
  margin-top: 68px;
}
/* 忘记密码样式设置 */
.password_reset {
  width: 100%;
  text-align: right;
}
.forget-password {
    flex-grow: 1;
    text-align: right;
    font-size: 14px;
    color: #165DFF;
}
/* 没有账号，立即注册/已有账号，立即登录的模式切换 */
.computer-changemode {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
}
/* 登陆按钮样式 */
.button_container2 {
    width: 80%;
    height: 60px;
    border-radius: 10px;
    background-color: #165DFF;
    color: #fff;
    font-size: 24px;
}
/* 切换登录方式：三合一登录与手机验证码登录之间切换 */
.change-mode-parent {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}
.change-mode-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #86909C;
    font-size: 14px;
}
.change-mode-item-select {
    color: #1D2129;
}
.change-mode-circle {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F4F8;
    margin-bottom: 10px;
    cursor: pointer;
}
.change-mode-circle-select {
    background-color: #165DFF;
}
.change-mode-circle > img {
    height: 24px;
    width: 24px;
}
/* 忘记密码返回上一级 */
.login-back {
    position: absolute;
    left: 23px;
    top: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #3D3D3D;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/* 最底部更多信息 */
.footer_container {
  display: flex;
  position: fixed; /* 定位方式为fixed，可以将其固定在某个位置 */
  bottom: 0; /* 距离底部0px */
  left: 0; /* 距离左边0px */
  right: 0; /* 距离右边0px */
  margin-bottom: 20px; /* 容器内部的间距 */
  text-align: center;
  flex-direction: column;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .footer_container {
        transform: scale(0.8);
        transform-origin: bottom center; /* 确保从中心缩放 */
    }
}
</style>