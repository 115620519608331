<template>
    <div class="first-main-container">
        <div class="developer-goback-parent" @click="goBack">
            <div class="developer-goback-icon">
                <img src="../developer/developer-goback.png">
            </div>
            <span>{{$t('LandRegister.title')}}</span>
        </div>
        <div class="add-parent" v-if="loading">
            <!-- 步骤条 -->
            <div class="company-register-steps" style="margin-top:20px;">
                <div class="company-register-steps-item" style="width:33%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(1)">{{ stepNumber(1) }}</div>
                        <span class="company-register-step-title">{{$t('LandRegister.step1')}}</span>
                        <span class="company-register-step-subhead">{{$t('LandRegister.step1t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:33%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(2)">{{ stepNumber(2) }}</div>
                        <span class="company-register-step-title">{{$t('LandRegister.step2')}}</span>
                        <span class="company-register-step-subhead">{{$t('LandRegister.step2t')}}</span>
                    </div>
                </div>
                <div class="company-register-steps-item" style="width:33%;">
                    <div class="company-register-step">
                        <div class="company-register-step-number" :class="stepClass(3)">{{ stepNumber(3) }}</div>
                        <span class="company-register-step-title">{{$t('LandRegister.step3')}}</span>
                        <span class="company-register-step-subhead">{{$t('LandRegister.step3t')}}</span>
                    </div>
                </div>
            </div>
            <div class="add-box" v-if="active === 1">
                <LandUpload :LandInfor='LandInfor'></LandUpload>
            </div>
            <div class="add-box" v-else-if="active === 2">
                <div class="flex flex-row justify-center" v-show="!landView">
                    <img src="../images/loading.png" style="height: 24px;">
                    <span class="topic-text text-center">{{$t('LandRegister.tip1')}}</span>
                </div>
                <LandReview :LandInfor='LandInfor'></LandReview>
            </div>
            <div class="add-box flex flex-col justify-center items-center" v-else-if="active === 3">
                <i class="el-icon-close"></i>
                <span class="topic-text text-center">{{$t('LandRegister.tip2')}}</span>
                <span @mouseover="changeCursor" @click="gotolandupload">{{$t('LandRegister.reUpload')}}</span>
            </div>
            <div class="add-box" v-else-if="active === 4">
                <LandReview :LandInfor='LandInfor'></LandReview>
            </div>
        </div>
        <div class="add-parent" v-else>
            <div class="add-box flex justify-center items-center flex-grow">
                <span class="text-5xl" style="color:#000;">404 Not Found</span>
            </div>
        </div>
    </div>
</template>

<script>
import LandUpload from './LandUpload.vue';
import LandReview from './LandReview.vue';
export default {
    components: {
        LandUpload, // 上传土地信息
        LandReview, // 查看土地信息
    },
    created() {
        if (this.$route.query.land) { this.landid = this.$route.query.land; }
        if (this.$route.params.index || this.$route.params.index === 0) {
            this.active = this.$route.params.index;
        } else {
            this.loading = false;
        }
    },
    mounted() {
        if(this.landid) {
            this.$http.get('/v1/original-land/select-one-originalland?originallandid=' + this.landid)
            .then(res => {
                this.LandInfor = res.data.data;
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail('404 NOT FOUND')
            });
        }
    },
    data() {
        return {
            loading: true, // 如果用户刷新页面，不能显示该页面
            active: null,
            landView: false, // 查看土地上传信息
            vmodal: false, // 显示弹窗
            landid: null, // 土地编号，只有当查看土地时有编号
            businessurl: 'http://192.168.1.145:8092/v1/business/see-images?', // 获取企业信息图片的后端接口，还需要value=73663ebd471246a38fac4aef32623037.png、businessid=c55468def68d414ea9cc4b74b573e52e
            landurl: 'http://192.168.1.145:8092/v1/business/see-images?', // 获取土地上传信息图片的后端接口,还需要value=fee424f5de534617b4a575f201d567b2.png&businessid=b64151dc874548e782ebec4fa8f701bf
            pdfurl: 'http://192.168.1.145:8092/v1/business/see-pdffile?',
            LandInfor: { // 土地凭证信息，后端获取
                ownershipnumber: '', // 土地产权编号
                originallandname: '', // 土地名称
                createdate: '', // 创建时间
                detailedaddress: '', // 详细地址
                geographicalcoordinates: '', // 地理坐标
                entrance: '东', // 土地入口方位
                landtype: '不限', // 土地类型
                landlength: '', // 土地长度
                landwidth: '', // 土地宽度
                height: '', // 海拔
                landarea: '', // 面积
                landcertificate: null, // 土地无贷款证明
                warrantydeed: null, // 担保地契
                landpicture: null, // 土地照片
                
                
                
                userBusiness: {
                    businessid: '',                    // 企业id
                    corporationname: '',               // 公司名称
                    housingownership: '',              // 房屋所有权证编号
                    businessname: '',                  // 企业负责人姓名
                    businessphone: '',                 // 企业负责人联系电话
                    createdate: '',                    // 企业信息认证成功时间
                },
                originalLand: {
                    originallandid: '',                // 土地id
                    originallandname: '',              // 土地名称
                    detailedaddress: '',               // 土地详细地址
                    landtype: '',                      // 土地类型
                    geographicalcoordinates: '',       // 土地经纬度
                    landlength: '',                    // 土地长度
                    landwidth: '',                     // 土地宽度
                    height: '',                        // 土地海拔
                    createdate: '',                    // 土地信息上传时间
                }
            },
        }
    },
    methods: {
        goBack() { // 返回文本按钮事件
            this.$router.go(-1)
        },
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        stepClass(n) { // 更换步骤条圆圈颜色
            if (this.active > n) { // 已完成变绿色
                return 'company-register-step-number-finish'
            } else if (this.active === n) { // 进行中蓝色
                return 'company-register-step-number-doing'
            } else { // 等待中不变色
                return ''
            }
        },
        stepNumber(n) { // 步骤条圆圈内数字内容
            if (this.active > n) { // 已完成对勾
                return '√'
            } else { // 未完成数字
                return n.toString()
            }
        },
        stepTitle(n) {
            if(this.active === n) {
                return this.$t('LandRegister.ing')
            } else if(this.active > n) {
                return this.$t('LandRegister.end')
            } else if(this.active < n) {
                return this.$t('LandRegister.wait')
            }
        },
        view() {
            this.landView = true;
        },
        gotolandupload() {
            this.active = 1;
        },
        gotoland() {
            this.$router.push({
                path: '/land-operate',
                name: 'Originland',
                query: {
                    land: this.landid,
                    landName: this.landName,
                }
            })
        },
        upLoadLandSuccess() {
            this.$router.go(-1);
        },
    },
}
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #F2F8FF;
    padding: 50px;
}
.add-parent {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    overflow-y: hidden;
    padding: 10px;
}
.el-steps {
    display: flex;
    justify-content: center; /* 水平居中 */
}
.add-box {
    flex-grow: 1;
    margin-top: 20px;
    max-height: calc(100% - 100px);
    overflow-y: hidden;
}
.step2-view {
    text-decoration: underline;
    margin-top: 10px;
}
.topic-text {
    font-size: 22px;
    color: #f04;
    font-weight: bold;
}
.steps-box {
    padding-left: 30%;
    padding-right: 30%;
}
</style>