<template>
  <div>
    <!-- 没有地址 -->
    <div class="nolist-box" v-if="addressList.length === 0" @click="onAdd">
      <img src="../pImage/address-none.png">
      <span class="ml-3">{{ $t('PcAddress.add') }}</span>
    </div>

    <!-- 地址图鉴 -->
    <div class="list-box" v-for="(address, index) in addressList" :key="index" @click="onHandle(address,index)">
      <div class="list-infor-box">
        <div class="list-infor-row">
          <div class="list-infor-row-label">{{ $t('MobileConfirmOrder.personInput') }}:</div>
          <div class="list-infor-row-label" v-if="address.province">{{ $t('PcAddressWindow.title') }}:</div>
          <div class="list-infor-row-label">{{ $t('LandSellDetail.detailedaddress') }}:</div>
          <div class="list-infor-row-label">{{ $t('CompanyInfor.companyContactPhone') }}:</div>
        </div>
        <div class="list-infor-row">
          <div class="list-infor-row-data">{{ address.name }}</div>
          <div class="list-infor-row-data" v-if="address.province">{{ address.province + '-' + address.city + '-' + address.county }}</div>
          <div class="list-infor-row-data">{{ address.addressDetail }}</div>
          <div class="list-infor-row-data">{{ address.tel }}</div>
        </div>
      </div>
      <div class="list-default-tab" v-if="address.isDefault">{{ $t('PcAddressWindow.address') }}</div>
      <div class="list-edit">
        <span v-if="!address.isDefault" style="margin-right:24px" @click.stop="onEditDefault(address)">{{ $t('PcAddressWindow.default') }}</span>
        <span @click.stop="onEdit(address,index)">{{ $t('PcAddressWindow.edit') }}</span>
      </div>
    </div>


    <!-- 编辑/修改地址 -->
    <van-dialog
      v-model="editAddress"
      :show-confirm-button="false"
      show-cancel-button
      class="pt-5"
    >
      <van-address-edit
        show-delete
        show-set-default
        show-search-result
        :show-area="$i18n.locale === 'zh'"
        :area-list="areaList"
        :area-columns-placeholder="[
          $t('MobileAddressManagement.choose'),
          $t('MobileAddressManagement.choose'),
          $t('MobileAddressManagement.choose'),
        ]"
        :address-info="addressList[editing]"
        @save="onEditSave"
        @delete="onEditDelete"
      />
    </van-dialog>


    <!-- 新增地址 -->
    <van-dialog
      v-model="addAddress"
      :show-confirm-button="false"
      show-cancel-button
    >
      <van-address-edit
        :area-list="areaList"
        show-set-default
        :show-area="$i18n.locale === 'zh'"
        :area-columns-placeholder="[
          $t('MobileAddressManagement.choose'),
          $t('MobileAddressManagement.choose'),
          $t('MobileAddressManagement.choose'),
        ]"
        @save="onAddSave"
      />
    </van-dialog>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data'
export default {
  props: { // 父子组件传值
    addressList: {
      type: Array,
      require: true, // 如果是个空数组，则显示新增窗口
    },
    chosenAddressId: {
      type: Number,
      default: -1,
    },
    mode: {
      type: Boolean,
      default: false, // false为编辑模式，点击进入编辑，true为切换模式，点击切换该地址
    }
  },
  data() {
    return {
      addressExample: {
                id: 1,
                name: 'userData[i].consigneename',
                tel: 'userData[i].contactinformation',
                province: 'userData[i].province',
                city: 'userData[i].city',
                county: 'userData[i].area',
                addressDetail: 'userData[i].detailedaddress',
                address:
                  'userData[i].province' +
                  'userData[i].city' +
                  'userData[i].area' +
                  'userData[i].detailedaddress',
                isDefault: true,
                areaCode: 'userData[i].provincecode',
                areaCode: 'userData[i].citycode',
                areaCode: 'userData[i].areacode',
      },
      editAddress: false,
      addAddress: false,
      areaList,
      editing: 0,
      areaInfor: {
        name: 'Name',
        tel: 'telephone',
        addressDetail: 'DetailAddress',
      },
    }
  },

  methods: {
    onEdit(item, index) {
      this.editAddress = true
      this.editing = index
    },
    onEditDefault(address) {
      address.isDefault = true;
      this.onEditSave(address);
    },
    onEditSave(addressInfo) {
      if (addressInfo.isDefault) {
        for (let i = 0; i < this.addressList.length; i++) {
          if (i === this.editing) continue
          if (this.addressList[i].isDefault) {
            this.addressList[i].isDefault = false
            let t = this.editing
            this.editing = i
            this.onEditSave(this.addressList[i])
            this.editing = t
            break
          }
        }
      }
      this.addressList[this.editing].name = addressInfo.name
      this.addressList[this.editing].tel = addressInfo.tel
      this.addressList[this.editing].province = addressInfo.province
      this.addressList[this.editing].city = addressInfo.city
      this.addressList[this.editing].county = addressInfo.county
      this.addressList[this.editing].areaCode = addressInfo.areaCode
      this.addressList[this.editing].addressDetail = addressInfo.addressDetail
      this.addressList[this.editing].address =
        addressInfo.province +
        addressInfo.city +
        addressInfo.county +
        addressInfo.addressDetail
      this.addressList[this.editing].isDefault = addressInfo.isDefault
      this.editAddress = false
      this.$http({
        method: 'put',
        url: '/v1/address/update-address',
        data: {
          id: this.addressList[this.editing].id,
          consigneename: this.addressList[this.editing].name,
          contactinformation: this.addressList[this.editing].tel,
          province: this.addressList[this.editing].province,
          city: this.addressList[this.editing].city,
          area: this.addressList[this.editing].county,
          detailedaddress: this.addressList[this.editing].addressDetail,
          isdefault: this.addressList[this.editing].isDefault,
        },
      })
        .then((res) => {
          this.editing = -1
        })
        .catch((err) => {
          this.editing = -1
        })
    },
    onEditDelete(addressInfo) {
      this.editAddress = false
      this.$http({
        method: 'delete',
        url: '/v1/address/delete-address',
        params: {
          id: this.addressList[this.editing].id,
        },
      })
        .then((res) => {
          console.log(res)
          this.addressList.splice(this.editing, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onEditCancel() {
      this.editAddress = false
    },
    onAdd() {
      this.addAddress = true
    },
    onAddSave(addressInfo) {
      if (addressInfo.isDefault) {
        for (let i = 0; i < this.addressList.length; i++) {
          if (i === this.editing) continue
          if (this.addressList[i].isDefault) {
            this.addressList[i].isDefault = false
            let t = this.editing
            this.editing = i
            this.onEditSave(this.addressList[i])
            // this.onLoad();
            this.editing = t
            break
          }
        }
      }
      this.addressList.push({
        name: addressInfo.name,
        tel: addressInfo.tel,
        address:
          addressInfo.province +
          addressInfo.city +
          addressInfo.county +
          addressInfo.addressDetail,
        isDefault: addressInfo.isDefault,
      })
      this.$http({
        method: 'post',
        url: '/v1/address/create-address',
        data: {
          consigneename: addressInfo.name,
          contactinformation: addressInfo.tel,
          province: addressInfo.province,
          city: addressInfo.city,
          area: addressInfo.county,
          detailedaddress: addressInfo.addressDetail,
          isdefault: addressInfo.isDefault,
        },
      })
        .then((res) => {
          this.onLoad()

          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
      this.addAddress = false
    },

    // 如果是收货地址页，点击地址图鉴会触发编辑事件，如果是确认订单页，点击地址图鉴会发送该条地址数据并切换地址
    onHandle(address,index) {
      if (this.mode) {
        this.$parent.onChange(address);
      } else {
        this.onEdit(address,index);
      }
    },
  },
}
</script>

<style scoped>
/* 没有地址的时候显示这个 */
.nolist-box {
  margin-top: 16px;
  height: 96px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 25px;
  cursor: pointer;
}
.nolist-box > .img {
  object-fit: contain;
  height: 16px;
}
.nolist-box > .span {
  margin-left: 8px;
  color: #3D3D3D;
  font-size: 14px;
  font-weight: 350;
}

.list-box {
  margin-top: 16px;
  height: 175px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  cursor: pointer;
}
.list-infor-box {
  position: absolute;
  top: 11px;
  left: 40px;
  height: 153px;
  display: flex;
  flex-direction: row;
}
.list-infor-row {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.list-infor-row-label {
  height: 25%;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
  color: #B4B4B4;
  white-space: nowrap;
}
.list-infor-row-data {
  margin-left: 8px;
  height: 25%;
  display: flex;
  align-items: center;
  color: #4E5969;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.list-default-tab {
  position: absolute;
  top: 18px;
  right: 25px;
  border-radius: 8px;
  padding: 5px 10px;
  background-color: rgba(250, 81, 81, 0.1);
  box-sizing: border-box;
  border: 1px solid #FA5151;
  color: #FA5151;
  font-size: 14px;
  font-weight: 350;
}
.list-edit {
  position: absolute;
  bottom: 9px;
  right: 25px;
  font-size: 14px;
  font-weight: 350;
  color: #0256FF;
}
.list-edit span {
  cursor: pointer;
}
</style>