<template>
    <div class="upload-form">
        <!-- 土地产权编号 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.ownershipnumber')}}:
            </div>
            <div class="company-register-input-right">
                <input type="text" v-model="LandInfor.ownershipnumber" class="company-register-input" :placeholder="$t('LandReview.ownershipnumberInput')">
            </div>
        </div>
        <!-- 土地名称 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.originallandname')}}:
            </div>
            <div class="company-register-input-right">
                <div class="flex flex-col">
                    <div class="flex-flex-row">
                        <input type="text" v-model="LandInfor.originallandname" :placeholder="$t('LandUpload.setname')" @input="checkInput" maxlength="20" show-word-limit class="company-register-input">
                        <span class="ml-3">{{ LandInfor.originallandname.length + '/20' }}</span>
                    </div>
                    <div v-if="error" class="error">{{ error }}</div>
                </div>
            </div>
        </div>
        <!-- 详细地址 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.detailedaddress')}}:
            </div>
            <div class="company-register-input-right">
                <textarea type="text" v-model="LandInfor.detailedaddress" class="company-register-input" cols="5" rows="20" maxlength="100" show-word-limit :placeholder="$t('LandReview.detailedaddressInput')"></textarea>
            </div>
        </div>
        <!-- 土地无贷款证明 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.landcertificate')}}:
            </div>
            <div class="company-register-input-right ml-3">
                <a class="company-register-input-link" :href="LandInfor.landcertificate" target="_blank" v-if="LandInfor.landcertificate">{{$t('LandUpload.download')}}</a>
                &nbsp;
                <a class="company-register-input-link" @click="handleClick(1)" target="_blank">{{$t('CompanyInfor.uploadpdf')}}</a>
                &nbsp;
                <input type="file" ref="landcertificate" @change="handleFileInputChange(1)" hidden>
                <span class="company-register-input-tip" v-if="LandInfor.landcertificate">※{{$t('LandUpload.tip1')}}</span>
                <span class="company-register-input-tip" v-else>※{{$t('CompanyInfor.uploadPDF')}}</span>
            </div>
        </div>
        <!-- 土地担保地契 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.warrantydeed')}}:
            </div>
            <div class="company-register-input-right ml-3">
                <a class="company-register-input-link" :href="LandInfor.warrantydeed" target="_blank" v-if="LandInfor.warrantydeed">{{$t('LandUpload.download')}}</a>
                &nbsp;
                <a class="company-register-input-link" @click="handleClick(2)" target="_blank">{{$t('CompanyInfor.uploadpdf')}}</a>
                &nbsp;
                <input type="file" ref="warrantydeed" @change="handleFileInputChange(2)" hidden>
                <span class="company-register-input-tip" v-if="LandInfor.warrantydeed">※{{$t('LandUpload.tip1')}}</span>
                <span class="company-register-input-tip" v-else>※{{$t('CompanyInfor.uploadPDF')}}</span>
            </div>
        </div>
        <!-- 地理坐标 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.geo')}}:
            </div>
            <div class="company-register-input-right">
                <input type="text" v-model="LandInfor.geographicalcoordinates" class="company-register-input" :placeholder="$t('LandReview.geoTip')">
                <span class="company-register-input-tip">※{{$t('LandUpload.tip2')}}</span>
            </div>
        </div>
        <!-- 土地入口方位 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandUpload.enter')}}:
            </div>
            <div class="company-register-input-right">
                <div class="land-register-input-dropdown" @mouseover="showDropDown=true" @mouseleave="showDropDown=false;hoverIndex=-1;">
                    {{ entrance }}
                    <div class="flex-grow flex justify-end"><img src="../developer/developer-land-dropdown.png" style="height:16px;width:16px;"></div>
                    <div class="land-register-input-dropdown-modal" v-if="showDropDown">
                        <div class="land-register-input-dropdown-box" :class="hoverIndex === 0 ? 'land-register-input-dropdown-box-select' : ''" @mouseover="hoverIndex = 0" @click="setEntrance(1)">{{$t('LandUpload.east')}}</div>
                        <div class="land-register-input-dropdown-box" :class="hoverIndex === 1 ? 'land-register-input-dropdown-box-select' : ''" @mouseover="hoverIndex = 1" @click="setEntrance(2)">{{$t('LandUpload.west')}}</div>
                        <div class="land-register-input-dropdown-box" :class="hoverIndex === 2 ? 'land-register-input-dropdown-box-select' : ''" @mouseover="hoverIndex = 2" @click="setEntrance(3)">{{$t('LandUpload.south')}}</div>
                        <div class="land-register-input-dropdown-box" :class="hoverIndex === 3 ? 'land-register-input-dropdown-box-select' : ''" @mouseover="hoverIndex = 3" @click="setEntrance(4)">{{$t('LandUpload.north')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 土地长度 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.landlength')}}:
            </div>
            <div class="company-register-input-right">
                <input type="text" v-model="LandInfor.landlength" class="company-register-input" :placeholder="$t('LandUpload.tip4')">
                <span class="company-register-input-tip">※{{$t('LandUpload.tip2')}}</span>
            </div>
        </div>
        <!-- 土地宽度 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.landwidth')}}:
            </div>
            <div class="company-register-input-right">
                <input type="text" v-model="LandInfor.landwidth" class="company-register-input" :placeholder="$t('LandUpload.tip4')">
                <span class="company-register-input-tip">※{{$t('LandUpload.tip2')}}</span>
            </div>
        </div>
        <!-- 海拔高度 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.height')}}:
            </div>
            <div class="company-register-input-right">
                <input type="text" v-model="LandInfor.height" class="company-register-input" :placeholder="$t('LandUpload.tip4')">
                <span class="company-register-input-tip">※{{$t('LandUpload.tip2')}}</span>
            </div>
        </div>
        <!-- 土地面积 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.area')}}:
            </div>
            <div class="company-register-input-right">
                <input v-model="LandInfor.landarea" class="company-register-input" :placeholder="$t('LandUpload.areaunit')">
                <span class="company-register-input-tip">※{{$t('LandUpload.tip2')}}</span>
            </div>
        </div>
        <!-- 土地照片 -->
        <div class="company-register-input-parent">
            <div class="company-register-input-label">
                <span style="color:red;margin-right: 5px;">*</span>
                {{$t('LandReview.landpicture')}}:
            </div>
            <div class="company-register-input-right">
                <div class="ml-5">
                    <img :src="LandInfor.landpicture" v-if="LandInfor.landpicture" class="company-register-input-img" @click="handleClick(3)">
                    <div class="company-register-input-img" @click="handleClick(3)" v-else>
                        <span class="company-register-input-img-icon">+</span>
                        <span class="company-register-input-img-span" style="font-size:12px;">{{$t('LandUpload.landpictureUpload')}}</span>
                    </div>
                    <input type="file" ref="landInput" @change="handleFileInputChange(3)" hidden>
                </div>
                <div class="company-register-input-tip ml-3">※{{$t('CompanyInfor.uploadImg')}}</div>
            </div>
        </div>
        <div class="upload-button-parent">
            <el-button type="primary" class="developer-common-button" @click="upload">{{$t('CompanyInfor.upload')}}</el-button>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal" @click="closeModal">
            <div class="developer-common-modal-box" v-if="!showError">
                <span style="white-space: normal;">{{$t('CompanyInfor.tip1')}}{{$t('CompanyInfor.tip2')}}{{$t('CompanyInfor.tip3')}}</span>
                <div style="margin-top: 50px;">
                    <el-button type="infor" class="developer-common-button" @click="closeModal">{{$t('CompanyInfor.no')}}</el-button>
                    <el-button type="primary" class="developer-common-button" @click.prevent="add">{{$t('CompanyInfor.yes')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-if="showError">
                <div>{{ errorText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        LandInfor: {
            type: Object,
            required: true
        },
    },
    mounted() {
        if (this.LandInfor.entrance === '东') {
            this.entrance = this.$t('LandUpload.east');
        } else if (this.LandInfor.entrance === '西') {
            this.entrance = this.$t('LandUpload.west');
        } else if (this.LandInfor.entrance === '南') {
            this.entrance = this.$t('LandUpload.south');
        } else if (this.LandInfor.entrance === '北') {
            this.entrance = this.$t('LandUpload.north');
        } else {
            this.entrance = this.$t('LandUpload.east');
        }
    },
    data() {
        return {
            error: null, // 土地名称
            vmodal: false, // 弹窗的显示控制
            showError: false, // 控制弹窗是否显示按钮面板,true是带按钮,false不带
            errorText: '', // 提示文本,可修改

            entrance: '', // 土地入口方位显示的文本，需要根据中英文切换
            showDropDown: false, // 显示土地入口下拉选项的控制参数，true是显示
            hoverIndex: -1, // 鼠标悬浮到下拉选项单时变色


            landpicture: null, // 上传的土地照片-文件格式
            landcertificate: null, // 土地无贷款证明-文件格式
            warrantydeed: null, // 土地担保地契-文件格式
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        closeModal() {
            this.vmodal = false
        },
        

        /* 上传图片功能样式 */
        handleClick(n) {
            // 点击时执行相应逻辑
            // 触发文件选择器
            if (n===1) {
                this.$refs.landcertificate.click();
            } else if (n===2) {
                this.$refs.warrantydeed.click();
            } else if (n===3) {
                this.$refs.landInput.click();
            }
        },
        handleFileInputChange(n) { // 获取用户选择的文件
            const maxSize = 3 * 1024 * 1024; // 3 MB
            if(n === 1) {
                // 获取用户选择的文件对象
                /* const file = this.$refs.landcertificate.files[0];
                const blob = file.slice(0, file.size, file.type); // 获取 Blob 对象
                this.landcertificate = new File([blob], file.name, { type: file.type }); // 将 Blob 转换为 File 对象 */
                this.landcertificate = this.$refs.landcertificate.files[0];
                if (this.landcertificate.type !== 'application/pdf') { // 上传文件格式错误，提示上传失败
                    this.landcertificate = null;
                    this.LandInfor.landcertificate = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                } else if (this.landcertificate.size >= maxSize) {
                    this.landcertificate = null;
                    this.LandInfor.landcertificate = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.landcertificate);
                // 更新当前文件 URL
                this.LandInfor.landcertificate = url;
            } else if (n === 2) {
                /* const file = this.$refs.warrantydeed.files[0];
                const blob = file.slice(0, file.size, file.type); // 获取 Blob 对象
                this.warrantydeed = new File([blob], file.name, { type: file.type }); // 将 Blob 转换为 File 对象 */
                this.warrantydeed = this.$refs.warrantydeed.files[0];
                if (this.warrantydeed.type !== 'application/pdf') { // 上传文件格式错误，提示上传失败
                    this.warrantydeed = null;
                    this.LandInfor.warrantydeed = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                } else if (this.warrantydeed.size >= maxSize) {
                    this.warrantydeed = null;
                    this.LandInfor.warrantydeed = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadPDF'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.warrantydeed);
                // 更新当前文件 URL
                this.LandInfor.warrantydeed = url;
            } else if (n === 3) {
                this.landpicture = this.$refs.landInput.files[0];
                if (this.landpicture.type !== 'image/png' && this.landpicture.type !== 'image/jpg' && this.landpicture.type !== 'image/jpeg') { // 上传文件格式错误，提示上传失败
                    this.LandInfor.landpicture = null;
                    this.landpicture = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadImg'));
                    return;
                } else if (this.landpicture.size >= maxSize) {
                    this.LandInfor.landpicture = null;
                    this.landpicture = null;
                    this.$toast.fail(this.$t('CompanyInfor.uploadImg'));
                    return;
                }
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.landpicture);
                // 更新当前头像 URL
                this.LandInfor.landpicture = url;
            }
        },

        /* 限制土地名称格式 */
        checkInput() {
            const regex = new RegExp('^[a-zA-Z0-9\u4E00-\u9FA5]*$');
            if (this.LandInfor.originallandname.length >= 20) {
                this.error = '输入字数已达上限';
            } else if (!regex.test(this.originallandname)) {
                this.error = this.$t('LandUpload.alertFormatError');
            } else {
                this.error = null;
            }
        },

        /* 土地详情-土地类型下拉菜单事件 */
        setEntrance(n) {
            if (n===1) {
                this.LandInfor.entrance = '东';
                this.entrance = this.$t('LandUpload.east');
            } else if (n===2) {
                this.LandInfor.entrance = '西';
                this.entrance = this.$t('LandUpload.west');
            } else if (n===3) {
                this.LandInfor.entrance = '南';
                this.entrance = this.$t('LandUpload.south');
            } else if (n===4) {
                this.LandInfor.entrance = '北';
                this.entrance = this.$t('LandUpload.north');
            }
            this.showDropDown = false;
        },

        /* 提交按钮事件 */
        upload() {
            const maxSize = 3 * 1024 * 1024; // 5MB
            if (this.LandInfor.ownershipnumber === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoOwner'));
                return;
            } else if (this.LandInfor.originallandname === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoName'));
                return;
            } else if (this.LandInfor.detailedaddress === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoAddress'));
                return;
            } else if (this.LandInfor.geographicalcoordinates === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoGeo'));
                return;
            } else if (this.LandInfor.landlength === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoLength'));
                return;
            } else if (this.LandInfor.landwidth === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoWidth'));
                return;
            } else if (this.LandInfor.height === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoHeight'));
                return;
            } else if (this.LandInfor.landarea === '') {
                this.$toast.fail(this.$t('LandUpload.alertNoArea'));
                return;
            } else if (!this.LandInfor.landcertificate && !this.landcertificate) {
                this.$toast.fail(this.$t('LandUpload.alertNoCert'));
                return;
            } else if (!this.LandInfor.warrantydeed && !this.warrantydeed) {
                this.$toast.fail(this.$t('LandUpload.alertNoWarr'));
                return;
            } else if (!this.LandInfor.landpicture && !this.landpicture) {
                this.$toast.fail(this.$t('LandUpload.alertNoPicture'));
                return;
            } else if (this.LandInfor.warrantydeed.size >= maxSize) {
                this.$toast.fail(this.$t('LandUpload.alertWarrBig'));
                return;
            } else if (this.LandInfor.landcertificate.size >= maxSize) {
                this.$toast.fail(this.$t('LandUpload.alertCertBig'));
                return;
            } else if (this.landpicture.size >= maxSize) {
                this.$toast.fail(this.$t('LandUpload.alertPictureBig'));
                return;
            } else {
                this.showError = false;
                this.vmodal = true;
            }
        },

        add() {
            // 将文件转化为 FormData
            const formData = new FormData();
            if (this.LandInfor.originallandid) {
                formData.append('originallandid', this.LandInfor.originallandid);
            }
            formData.append('ownershipnumber', this.LandInfor.ownershipnumber);
            formData.append('originallandname', this.LandInfor.originallandname);
            formData.append('detailedaddress', this.LandInfor.detailedaddress);
            if (this.LandInfor.landcertificate && !this.landcertificate) {
                formData.append('landcertificate', this.LandInfor.landcertificate);
            } else {
                formData.append('landcertificate', this.landcertificate);
            }
            if (this.LandInfor.warrantydeed && !this.warrantydeed) {
                formData.append('warrantydeed', this.LandInfor.warrantydeed);
            } else {
                formData.append('warrantydeed', this.warrantydeed);
            }
            formData.append('geographicalcoordinates', this.LandInfor.geographicalcoordinates);
            formData.append('entrance', this.LandInfor.entrance);
            formData.append('landlength', this.LandInfor.landlength);
            formData.append('landwidth', this.LandInfor.landwidth);
            formData.append('height', this.LandInfor.height);
            formData.append('landarea', this.LandInfor.landarea);
            if (this.LandInfor.landpicture && !this.landpicture) {
                formData.append('landpicture', this.LandInfor.landpicture);
            } else {
                formData.append('landpicture', this.landpicture);
            }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('CompanyRegister.alertuploading'),
                forbidClick: true,
            });
            
      
            // 发送 Ajax 请求
            this.$http.post('/v1/original-land/personal-information', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(response => {
                toastLoading.clear();
                this.$toast.success(this.$t('LandUpload.alertUploadSuccess'))
                this.$parent.upLoadLandSuccess();
            }).catch(error => {
                toastLoading.clear();
                this.$toast.fail(this.$t('CompanyInfor.alertSendFail'));
            });
        }
    }
}
</script>

<style>
/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    white-space: nowrap;
    overflow-y: auto;
    height: 100%;
}
.upload-in-the-form {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.land-register-input {
    margin-left: 15px;
    height: 40px;
    width: 310px;
    background-color: #F1F4F8;
    border: 1px solid #165DFF;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 14px;
}
.land-register-input-dropdown {
    margin-left: 10px;
    height: 36px;
    width: 130px;
    background-color: #F1F4F8;
    border: 1px solid #165DFF;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    position: relative;
}
.land-register-input-dropdown-modal {
    width: 130px;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(242 242 242);
    border-radius: 6px;
}
.land-register-input-dropdown-box {
    width: 100%;
    cursor: pointer;
    padding: 5px;
    text-align: center;
}
.land-register-input-dropdown-box-select {
    background-color: rgba(22, 93, 255, 0.05);
}
/* 土地名称输入错误提示 */
.error {
    color: red;
    font-size: 14px;
    margin-left: 15px;
    margin-top: 5px;
}


/* ------------------------------上传提交土地信息表单按钮------------------------------- */
.upload-button-parent {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.upload-button {
    height: 50px;
    width: 100px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 1);
    border-radius: 6px;
}
</style>