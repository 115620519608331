<template>
    <div class="developer-common-modal" v-if="step">
        <!-- 活动弹窗第一层：多个活动图片，点击相应图片跳转到对应活动的规则文本 -->
        <div class="activity-box" v-if="vmodal">
            <div class="activity-title">精彩活动</div>
            <img :src="img" class="activity-image" v-for="(img,index) in imageSrc" :key="index" @click="vmodal = false;ruleIndex = index;">
        </div>
        <!-- 活动弹窗第二层：活动规则文本说明 -->
        <div class="activity-box" v-else>
            <div class="activity-title">活动规则</div>
            <div class="activity-rule-box">
                <pre class="activity-rule-text">{{ ruleText[ruleIndex] }}</pre>
            </div>
            <div class="developer-common-buttons">
                <button class="infor1-button infor1-button-grayground" @click="closeActivityModal">拒绝</button>
                <div class="flex-grow flex justify-end">
                    <button class="infor1-button infor1-button-blueground" @click="acceptActivity">我已同意</button>
                </div>
            </div>
        </div>
    </div>

    <!-- 活动第二步-处理活动选中的土地 -->
    <div class="developer-common-modal" v-else>
        <!-- 一号活动：暂命名为拼拼乐 -->
        <div class="activity-box" v-if="ruleIndex === 0">
            <div class="activity-title">活动:拼拼乐</div>
            <div class="mt-5 w-full flex justify-center items-center font-bold text-lg">选中参与的小区</div>
            <div class="activity-rule-box">
                <div class="activity-blocks" v-for="(block,index) in activity1Selected" :key="index">
                    {{ block }}
                    <img class="ml-4 activity-blocks-icon" src="../developer/map-sell-cancel.png" @click="deleteBlock(index)">
                </div>
            </div>
            <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="width: 300px;margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="../developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="developer-common-buttons">
                <button class="infor1-button infor1-button-grayground" @click="closeActivityModal">关闭</button>
                <div class="flex-grow flex justify-end">
                    <button class="infor1-button infor1-button-blueground" @click="participateActivity1">参与活动</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        step: { // 活动选择界面和活动参与界面
            type: Boolean,
            default: true,
        },
        activity1Selected: { // 活动1：拼拼乐选中的小区编号集
            type: Array,
            default: [],
        },
        originallandid: {
            type: String,
            require: true,
        },
    },
    data() {
        return {
            vmodal: true, // 活动选择界面-活动选择和规则说明
            imageSrc: [require('../activityImage/activityTest.png')],
            ruleIndex: null,
            ruleText: ['1.开发商需选择未分割过的小区加入活动\n2.参与活动的小区根据单位土地价格向用户进行售卖，单位土地价格由开发商决定\n3.参与活动的小区活动期间开发商无法对其进行合并、分割等操作\n4.参与活动的小区上传后立即锁定，开发商无法主动撤销，请谨慎选择\n5.如果需要撤销参与活动的小区，请在活动前联系客服'],
            needCode: true, // 是否需要验证码
            code: '', // 挂牌验证码
            timeCount: 0, // 倒计时
            timer: null, // 定时器
        }
    },
    methods: {
        closeActivityModal() {
            this.$parent.closeActivityModal();
        },
        acceptActivity() {
            this.$parent.acceptActivity(this.ruleIndex);
        },

        // 活动1：拼拼乐
        deleteBlock(index) {
            this.$parent.deleteBlock(index);
        },
        participateActivity1() {
            if (this.needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            // 请求发送后要求父组件关闭并重置弹窗
            this.$parent.resetActivityModal();
        },
        // 获取验证码前打开弹窗需要检测是否需要验证码
        codeNeedOrNot() {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/if-operation')
                .then(res => {
                    loadingToast.clear();
                    this.needCode = res.data.data;
                    this.$parent.openActivityModal();
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        // 获取验证码
        getVerificationCode() {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/verification-code')
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.startCountdown();
                        if (res.data.data === '邮箱验证码发送成功') {
                            this.$toast.success(this.$t('LandSellList.sendemail'));
                        } else {
                            this.$toast.success(this.$t('LandSellList.sendphone'));
                        }
                    } else {
                        this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.timeCount = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                    this.timeCount--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
    },
}
</script>

<style>
.activity-box {
    background-color: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.activity-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 500;
}
.activity-image {
    margin-top: 30px 16px;
    height: 250px;
    width: 750px;
    object-fit: fill;
    border-radius: 8px;
    cursor: pointer;
}
.activity-rule-box {
    margin-top: 16px;
    max-height: 350px;
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 16px;
    background-color: #ebebeb;
    border-radius: 12px;
    border: 1px solid #b9b9b9;
    display: flex;
    flex-direction: column;
}
.activity-rule-text {
    white-space: break-spaces;
    line-height: 2;
    font-size: 16px;
}

/* 活动1：拼拼乐 */
.activity-blocks {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: blue;
}
.activity-blocks-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
}
</style>