<template>
    <div class="mobile-email-home mobile-base-background2">
        <div class="flex flex-row items-center mobile-base-back">
            <img src="../mImage/login-back.png" class="justify-start" style="height:25px;width:25px;" @click="goBack">
            <div class="mobile-goback-title">{{$t('MobileServiceA.title')}}</div>
        </div>
        <div style="height: calc(100% - 100px)" class="animate__animated animate__fadeInUp mt-2 mx-3 overflow-y-auto" ref="messageList">
            <MobileMessageInfor v-for="(message, index) in Message" :key="index" :Message="message" />
        </div>
        <div class="fixed bottom-2 left-0 right-0 flex justify-center">
            <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="showModel = true">{{$t('MobileServiceA.reply')}}</button>
        </div>

        <!-- 回复消息弹窗 -->
        <van-popup v-model="showModel" position="bottom" round class="py-5" >
            <div class="flex flex-col items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md ">{{$t('MobileServiceA.topic')}}</span>
                        <van-field
                            v-model="messageReply"
                            :placeholder="$t('MobileServiceA.placefolter')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                            class="rounded-lg shadow mt-3"
                        />
                    </div>
                </div>
                <van-uploader class="mt-5" v-model="fileList" multiple :after-read="afterRead" :max-count="1" />
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showModel = false;messageReply = '';fileList = [];">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="reply">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import MobileMessageInfor from "./MobileMessageInfor.vue";
export default {
    components: {
        // 土地市场组件
        MobileMessageInfor, // 对话信息
    },
    created() {
        this.threadid = this.$route.query.thread;
    },
    mounted() { // 获取主题列表
        this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + this.threadid)
        .then(res => {
            this.Message = res.data.data;
            // 滚动到最新消息
            this.$nextTick(() => {
                const messageList = this.$refs.messageList;
                messageList.scrollTop = messageList.scrollHeight;
            });
        }).catch(error => {
            console.log(error)
        })
        this.timer = setInterval(() => { // 更新消息
            this.refreshMessage();
        }, 60000); // 每分钟进行一次
    },
    data() {
        return {
            threadid: null, // 主题id，由页面url获取
            timer: null, // 定时器
            flag: true,
            Message: null,
            showModel: false, // 回复弹窗
            messageReply: '', // 回复的消息
            imageFile: null, // 回复的图片
            example: [
                {
                    id: 6134,
                    emailsid: "3732cf6ce03047dc996c003940212eff",
                    threadid: "3732c6ce03047dc996c003940212eff",
                    saleid: null,
                    fromid: "43a89960781b41de9e16c2575772dc54",
                    fromname: "亚瑟",
                    recipientid: "b4bcab5flafa44e8bbba5449afa13d6d",
                    recipientname: "隔壁老王",
                    emailscontent: "你好，你家土地有问题，地里都是虫子!!!",
                    createdate: "2023-07-10T16:49:02",
                    receivedate: null,
                    fromdelete: false,
                    recipientdelete: false,
                    isme: true, // 当前用户是否为发送方
                },
                {
                    id: 6134,
                    emailsid: "3732cf6ce03047dc996c003940212eff",
                    threadid: "3732c6ce03047dc996c003940212eff",
                    saleid: null,
                    fromid: "43a89960781b41de9e16c2575772dc54",
                    fromname: "亚瑟",
                    recipientid: "b4bcab5flafa44e8bbba5449afa13d6d",
                    recipientname: "隔壁老王",
                    emailscontent: "你好,工号2023为您服务~",
                    createdate: "2023-07-10T16:49:02",
                    receivedate: null,
                    fromdelete: false,
                    recipientdelete: false,
                    isme: false, // 当前用户是否为发送方
                },
            ],
            fileList: [], // 上传图片预览
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        refreshMessage() {
            this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + this.threadid)
            .then(res => {
                this.Message = res.data.data;
                // 滚动到最新消息
                this.$nextTick(() => {
                    const messageList = this.$refs.messageList;
                    messageList.scrollTop = messageList.scrollHeight;
                });
            }).catch(error => {
                this.$toast.fail('聊天信息更新失败，请刷新页面或重新登录');
                console.error('聊天信息更新失败，请刷新页面或重新登录')
                clearInterval(this.timer);
                this.timer = null;
            });
        },
        afterRead(file) {
            // 将图片文件存入data中，后续上传服务器
            this.imageFile = file.file;
        },
        reply() {
            if (this.messageReply === '' && this.fileList.length > 0) { // 只传图片
                const formData = new FormData();
                formData.append('picture', this.imageFile);
                formData.append('serviceid', this.threadId);
                this.$http.post("/v1/csmail/cswrite-picture", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then((res) => {
                        this.$toast.success(this.$t('MobileServiceQ.alertSendSuccessfully'));
                        this.showModel = false;
                        this.refreshMessage();
                    })
                    .catch((error) => {
                        this.$toast.fail('error')
                        console.log(error.data);
                    });
            } else if (this.messageReply !== '' && this.fileList.length === 0) { // 只传文本
                const data = {
                    emailscontent: this.messageReply,
                    threadid: this.threadid
                }
                this.$http.post("/v1/csmail/cswrite-message", data)
                    .then((res) => {
                        this.$toast.success(this.$t('MobileServiceQ.alertSendSuccessfully'));
                        this.showModel = false;
                        this.refreshMessage();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (this.messageReply !== '' && this.fileList.length > 0) {
                const formData = new FormData();
                formData.append('picture', this.imageFile);
                formData.append('serviceid', this.threadid);
                this.$http.post("/v1/csmail/cswrite-picture", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then((res) => {
                        this.showModel = false;
                        this.refreshMessage();
                    })
                    .catch((error) => {
                        this.$toast.fail('error')
                        this.showModel = true;
                        console.log(error.data);
                    });
                const data = {
                    emailscontent: this.messageReply,
                    threadid: this.threadid
                }
                this.$http.post("/v1/csmail/cswrite-message", data)
                    .then((res) => {
                        this.showModel = false;
                        this.refreshMessage();
                    })
                    .catch((error) => {
                        this.showModel = true;
                        console.log(error);
                    });
            }
        },
    },
    /* 生命周期结束时清除计时器 */
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}
/* ---------------------------------顶部：返回图标与标题------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: sticky;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------所有意见------------------------------- */
.mobile-email-input {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #fff;
}
.mobile-email-input-inn {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #dcdcdc;
}
</style>
