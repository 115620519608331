<template>
    <div class="mobile-email-home">
        <div class="flex flex-row items-center relative mobile-base-back">
            <img src="../mImage/login-back.png" class="justify-start" style="height:25px;width:25px;" @click="goBack">
            <div class="mobile-goback-title">{{$t('MobileServiceQ.title')}}</div>
            <div class="text-sm text-gray-400 flex items-center">{{$t('MobileMessage.clear')}}</div>
        </div>
        <div class="py-5 flex flex-row">
            <div class="w-1/3 flex justify-center items-center" @click="changeMode('来自用户')">
                <div class="relative flex flex-col justify-center items-center px-3">
                    <div class="message-unread" v-if="unread['seller-messages']"></div>
                    <img src="../mImage/message-user.png" style="height:44px;width:44px;">
                    <span class="system-icon-text" :style="value1 === '来自用户' ? 'color:#000000;font-weight:600;' : 'color:#5F6879'">{{$t('MobileMessage.seller')}}</span>
                </div>
            </div>
            <div class="w-1/3 flex justify-center items-center flex-col relative" @click="changeMode('来自客服')">
                <div class="relative flex flex-col justify-center items-center px-3">
                    <div class="message-unread" v-if="unread['customer-service-messages']"></div>
                    <img src="../mImage/message-service.png" style="height:44px;width:44px;">
                    <span class="system-icon-text" :style="value1 === '来自客服' ? 'color:#000000;font-weight:600;' : 'color:#5F6879'">{{$t('MobileMessage.service')}}</span>
                </div>
            </div>
            <div class="w-1/3 flex justify-center items-center flex-col relative" @click="changeMode('来自系统')">
                <div class="relative flex flex-col justify-center items-center px-3">
                    <div class="message-unread" v-if="unread['system-messages']"></div>
                    <img src="../mImage/message-system.png" style="height:44px;width:44px;">
                    <span class="system-icon-text" :style="value1 === '来自系统' ? 'color:#000000;font-weight:600;' : 'color:#5F6879'">{{$t('MobileMessage.system')}}</span>
                </div>
            </div>
        </div>
        <van-skeleton :loading="!loaded" v-for="i in 5" :key="i" class="mt-5" avatar  title :row="2" />
        <div v-if="loaded && Message.length === 0" class="flex-grow flex justify-center items-center flex-col">
            <img src="../images/message.png" style="height:150px">
            <span class="text-2xl mt-5">{{$t('MobileMessage.emptyMessage')}}</span>
        </div>
        <div v-if="loaded">
            <van-swipe-cell v-for="(message, index) in Message" :key="index" class="animate__animated animate__fast animate__lightSpeedInRight">
                <MobileMessage :Message="message" :value="value1" v-if="value1 !== '来自系统'" />
                <MobileSystem :Message="message" @fatherMethod="fatherMethod" v-else />
                <template #right>
                    <van-button square :text="$t('MobileMessage.delete')" type="danger" style="height: 100%" class="rounded-lg shadow-md" />
                </template>
            </van-swipe-cell>
        </div>
        <van-popup v-model="showPop" class="mobile-message-pop-back">
            <span class="mobile-system-message-title">{{$t('ChatMain.systemTitle')}}</span>
            <span class="mobile-system-message-date">{{ system.createdAt }}</span>
            <div class="mobile-system-message-content">{{ system.content }}</div>
        </van-popup>
    </div>
</template>

<script>
import MobileMessage from "./MobileMessageForm.vue";
import MobileSystem from "./MobileSystemForm.vue";

export default {
    components: {
        // 组件
        MobileMessage, // 对话信息
        MobileSystem, // 系统消息
    },
    created() {
        if (localStorage.getItem("user-message")) {
            this.value1 = localStorage.getItem("user-message");
        }
    },
    mounted() {
        this.$http
            .get('/v1/message/unread-all-messages').then(res => {
                this.unread = res.data.data;
            }).catch(error => {
                console.log(error)
            })
        if (this.value1 === '来自用户') {
            this.$http.get('/v1/mail/select-threadid', { // 获取普通消息列表
                withCredentials: true,
            }).then(res => {
                if (res.data.data) { // 没有消息时返回数据是null，此时系统报错，必须给一个判断语句
                    this.Message = res.data.data;
                } else {
                    this.Message = [];
                }
                this.loaded = true;
            }).catch(error => {
                console.log(error)
            })
        } else if (this.value1 === '来自客服') {
            this.$http.get('/v1/csmail/csselect-message', { // 获取客服消息列表
                withCredentials: true,
            }).then(res => {
                if (res.data.data) { // 没有消息时返回数据是null，此时系统报错，必须给一个判断语句
                    this.Message = res.data.data;
                } else {
                    this.Message = [];
                }
                this.loaded = true;
            }).catch(error => {
                console.log(error)
            })
        } else {
            this.$http.get('/v1/message/select-system-messages')
            .then(res => {
                if (res.data.data) { // 没有消息时返回数据是null，此时系统报错，必须给一个判断语句
                    this.Message = res.data.data;
                } else {
                    this.Message = [];
                }
                this.loaded = true;
            }).catch(error => {
                console.log(error)
            })
        }
    },
    data() {
        return {
            loaded: false,
            value1: '来自用户',
            unread: {
                "system-messages": false,
                "customer-service-messages": false,
                "seller-messages": false,
            }, // 三类消息的未读情况

            flag: true, // 防抖
            Message: [], // 后端传来的消息
            showPop: false, // 系统消息弹窗
            system: {
                id: 0,
                title: "",
                content: "",
                createdAt: "",
                recipientIds: "",
                read: true, // true是已读，按理说在聊天记录里的系统消息都是已读
            }, // 显示的单条系统消息
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        changeMode(mode) {
            this.value1 = mode;
            this.filterMessage()
        },
        filterMessage() {
            this.loaded = false;
            localStorage.setItem("user-message", this.value1);
            if (this.value1 === '来自用户') {
                this.$http.get('/v1/mail/select-threadid', { // 获取普通消息列表
                    withCredentials: true,
                }).then(res => {
                    if (res.data.data) { // 没有消息时返回数据是null，此时系统报错，必须给一个判断语句
                        this.Message = res.data.data;
                    } else {
                        this.Message = [];
                    }
                    this.loaded = true;
                }).catch(error => {
                    console.log(error)
                })
            } else if (this.value1 === '来自客服') {
                this.$http.get('/v1/csmail/csselect-message', { // 获取客服消息列表
                    withCredentials: true,
                }).then(res => {
                    if (res.data.data) { // 没有消息时返回数据是null，此时系统报错，必须给一个判断语句
                        this.Message = res.data.data;
                    } else {
                        this.Message = [];
                    }
                    this.loaded = true;
                }).catch(error => {
                    console.log(error)
                })
            } else if (this.value1 === '来自系统') { // 获取系统消息列表
                this.$http.get('/v1/message/select-system-messages', {
                    withCredentials: true,
                }).then(res => {
                    if (res.data.data) { // 没有消息时返回数据是null，此时系统报错，必须给一个判断语句
                        this.Message = res.data.data;
                    } else {
                        this.Message = [];
                    }
                    this.loaded = true;
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        fatherMethod(mail) {
            this.system = mail;
            this.showPop = true;
        },
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #65AFFF 0%, rgba(255, 255, 255, 0) 100%);
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
/* 三种类型聊天记录的选择图标文本样式 */
.system-icon-text {
    font-size: 14px;
    margin-top: 5px;
}
/* 弹窗背景图 */
.mobile-message-pop-back {
    height: 500px;
    width: 300px;
    max-height: 70%;
    max-width: 70%;
    background-image: url('../images/systemMessage.jpg');
    position: fixed;
}
.mobile-system-message-title {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 24px;
    font-weight: bold;
}
.mobile-system-message-date {
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translate(-50%, 0);
    white-space: nowrap;
}
.mobile-system-message-content {
    width: 100%;
    max-height: 400px;
    position: absolute;
    top: 70px;
    left: 0;
    padding: 30px;
    font-size: 20px;
    text-align: center;
}
.message-unread {
    height: 10px;
    width: 10px;
    background-color: red;
    position: absolute;
    top: 50px;
    right: 0;
    border-radius: 50%;
}
</style>
