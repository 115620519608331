<template>
    <div class="admin-detail-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>原始土地管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/land-manage');">原始土地凭证查看</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span><span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">原始土地上传审核</span></span>
        </div>
        <div class="admin-apply-title-change" v-if="landChild.landstatus==='已上线'">正在查看修改已上线的原始土地上传凭证</div>
        <div class="admin-apply-title-change" v-else-if="landChild.landstatus==='已驳回'">正在查看已驳回的原始土地上传凭证</div>
        <div class="admin-apply-title-change" v-else-if="landChild.landstatus==='待审核'">正在审核原始土地上传凭证</div>
        <div class="admin-detail-form-parent">
            <div class="admin-detail-form">
                <div class="admin-detail-form-topic">公司信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <img src="../adminImage/admin-company-cname.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">公司名称:</span>
                            <span>{{ landChild.corporationname }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <img src="../adminImage/admin-company-house.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">房屋所有权证编号:</span>
                            <span>{{ landChild.ownershipCertificate }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <img src="../adminImage/admin-company-name.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">企业联络人姓名:</span>
                            <span>{{ landChild.companyContact }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <img src="../adminImage/admin-company-phone.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">企业联络人手机号码:</span>
                            <span>{{ landChild.companyContactPhone }}</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">
                                <img src="../adminImage/admin-company-idcard.png" class="admin-apply-form-icon">
                                <span class="admin-apply-form-item-label">企业签约人身份证:</span>
                            </div>
                            <!-- 身份证正面照 -->
                            <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                                <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                    <img :src="landChild.frontIdImg" alt="身份证正面照" class="admin-apply-form-item-img-i" @click="show(1)">
                                </el-tooltip>
                                <!-- 弹框的显示和隐藏状态，根据 isfrontIdImg 变量决定 -->
                                <div class="admin-manage-image-modal" v-if="landChild.isfrontIdImg" @click="hide">
                                    <img class="full-image" :src="landChild.frontIdImg"/>
                                </div>
                            </div>
                            <!-- 身份证背面照 -->
                            <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                                <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                    <img :src="landChild.reverseIdImg" alt="身份证背面照" class="admin-apply-form-item-img-i" @click="show(2)">
                                </el-tooltip>
                                <!-- 弹框的显示和隐藏状态，根据 isreverseIdImg 变量决定 -->
                                <div class="admin-manage-image-modal" v-if="landChild.isreverseIdImg" @click="hide">
                                    <img class="full-image" :src="landChild.reverseIdImg"/>
                                </div>
                            </div>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">
                                <img src="../adminImage/admin-company-cprt.png" class="admin-apply-form-icon">
                                <span class="admin-apply-form-item-label">公司注册信息和注册证明:</span>
                            </div>
                            <!-- 公司注册信息 -->
                            <a :href="landChild.CPRTImg" target="_blank" class="admin-apply-form-item-link">点击下载查看</a>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">
                                <img src="../adminImage/admin-company-pfct.png" class="admin-apply-form-icon">
                                <span class="admin-apply-form-item-label">平台合同扫描件:</span>
                            </div>
                            <!-- 平台合同扫描件 -->
                            <a :href="landChild.PFCTImg" target="_blank" class="admin-apply-form-item-link">点击下载查看</a>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="admin-detail-form" style="margin-top: 30px;">
                <div class="admin-detail-form-topic">原始土地信息</div>
                <div class="admin-detail-form-full">
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">土地名称:</span>
                            <span>{{ landChild.landname }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">详细地址:</span>
                            <span>{{ landChild.landaddress }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">地理坐标:</span>
                            <span>{{ landChild.longilati }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">土地类型:</span>
                            <span>{{ landChild.landtype }}</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">土地长度:</span>
                            <span>{{ landChild.landlength }}英尺</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">土地宽度:</span>
                            <span>{{ landChild.landwidth }}英尺</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">海拔:</span>
                            <span>{{ landChild.landheight }}英尺</span>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <span class="admin-apply-form-item-label">土地面积:</span>
                            <span>{{ landChild.landarea }}平方英尺</span>
                        </el-row>
                    </div>
                    <div class="admin-detail-form-half">
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">土地无贷款证明(土地凭证):</div>
                            <!-- 土地无贷款证明 -->
                            <a :href="landChild.landcertificateImg" target="_blank" class="admin-apply-form-item-link">点击下载查看</a>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">土地担保地契:</div>
                            <!-- 土地无贷款证明 -->
                            <a :href="landChild.warrantydeed" target="_blank" class="admin-apply-form-item-link">点击下载查看</a>
                        </el-row>
                        <el-row class="admin-detail-form-row">
                            <div class="admin-apply-form-item-text">土地照片:</div>
                            <!-- 土地照片 -->
                            <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                                <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                    <img :src="landChild.landImage" alt="土地照片" class="admin-apply-form-item-img-i" @click="show(7)">
                                </el-tooltip>
                                <!-- 弹框的显示和隐藏状态，根据 isCPRT 变量决定 -->
                                <div class="admin-manage-image-modal" v-if="landChild.islandimg" @click="hide">
                                    <img class="full-image" :src="landChild.landImage"/>
                                </div>
                            </div>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
        <div class="admin-apply-button-parent">
            <el-button @click="goBack" class="admin-apply-button-1">返回</el-button>
            <el-button type="danger" plain @click="showModal(false)" class="admin-apply-button-1" style="margin-left:20px;" v-show="landChild.landstatus === '待审核' || landChild.landstatus === '已上线'">驳回申请</el-button>
            <el-button type="primary" class="admin-apply-button-1" @click="showModal(true)" style="margin-left:20px;" v-show="landChild.landstatus === '待审核'">通过申请</el-button>
        </div>
        <!-- 二次确认，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="admin-apply-modal" v-if="vmodal">
            <div class="admin-apply-modal-box">
                <span style="padding:30px;">{{ modalText }}</span>
                <el-input type="textarea" :rows="4" placeholder="请输入驳回原因" v-model="refuseReason" style="width: 300px;" v-if="!isAccess"></el-input>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="vmodal=false;refuseReason=''" class="admin-apply-button-1" style="margin-right: 20px;">取消</el-button>
                    <el-button type="primary" @click="apply" class="admin-apply-button-1" v-if="isAccess">通过</el-button>
                    <el-button type="danger" @click="refuse" class="admin-apply-button-1" v-else>驳回</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.originallandid = this.$route.query.id;
        this.mode = this.$route.query.mode;
    },
    mounted() { // 初始化
        this.$http.get('/v1/administrator/select-originallandmanage-byid?id=' + this.originallandid + '&landstatus=' + this.mode)
            .then(res => {
                const data = res.data.data;
                this.landChild.companyName = data.userBusiness.corporationname
                this.landChild.ownershipCertificate = data.userBusiness.housingownership
                this.landChild.companyContact = data.userBusiness.businessname
                this.landChild.companyContactPhone = data.userBusiness.businessphone
                this.landChild.frontIdImg = data.userBusiness.businesscard1
                this.landChild.reverseIdImg = data.userBusiness.businesscard2
                this.landChild.landcertificateImg = data.landcertificate
                this.landChild.CPRTImg = data.userBusiness.companyregistration
                this.landChild.PFCTImg = data.userBusiness.platformcontract
                this.landChild.warrantydeed = data.warrantydeed

                this.landChild.landid = data.originallandid
                this.landChild.landname = data.originallandname
                this.landChild.landaddress = data.detailedaddress
                this.landChild.longilati = data.geographicalcoordinates
                this.landChild.landtype = ''
                this.landChild.landlength = data.landlength
                this.landChild.landwidth = data.landwidth
                this.landChild.landarea = data.landarea
                this.landChild.landheight = data.height
                this.landChild.landdate = data.createdate
                this.landChild.landImage = data.landpicture
                // 处理返回值
                this.landChild.landstatus = data.landstatus
            })
            .catch(error => {
                // 处理错误
                this.$toast.fail("初始化失败，请刷新页面或重新登录");
            });
    },
    data() {
        return {
            originallandid: null,
            mode: null, // 查询的状态
            businessurl: 'http://192.168.1.145:8092/business/seeImages?', // 获取企业信息图片的后端接口，还需要value=73663ebd471246a38fac4aef32623037.png、businessid=c55468def68d414ea9cc4b74b573e52e
            landurl: 'http://192.168.1.145:8092/business/seeImages?', // 获取土地上传信息图片的后端接口,还需要value=fee424f5de534617b4a575f201d567b2.png&businessid=b64151dc874548e782ebec4fa8f701bf
            landChild: { // 父子组件传值
                whichtab: '', // 选定哪一个页面，这里有四个页面
                landId: '', // 向子组件传入土地编号，以获取土地上传信息
                isReview: true, // 土地上传资格审查详情页是否点入
                companyName: '',
                ownershipCertificate: '',
                companyContact: '',
                companyContactPhone: '',
                frontIdImg: require('../assets/no-image.png'), // 身份证正面照
                isfrontIdImg: false,
                reverseIdImg: require('../assets/no-image.png'), // 身份证反面照
                isreverseIdImg: false,
                landcertificateImg: '', // 土地无贷款证明
                CPRTImg: '', // 公司注册信息及注册证明
                PFCTImg: '', // 平台合同扫描件
                createdate: '', // 企业认证日期


                // 土地信息
                landid: '', // 土地编号
                landname: '', // 土地名称
                landaddress: '', // 详细地址
                longilati: '', // 经纬度
                landtype: '', // 土地类型
                landlength: '', // 土地长度
                landwidth: '', // 土地宽度
                landheight: '', // 海拔
                landarea: '', // 土地面积
                landdate: '', // 土地上传日期
                landImage: '', // 土地照片
                islandimg: false,
                landUrl: require('../assets/no-image.png'), // 土地照片

                landstatus: '', // 该条申请的状态
            },

            vmodal: false,
            modalText: '',
            isAccess: true, // true为通过申请，false为驳回申请
            refuseReason: '', // 驳回原因
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 点击图片查看原图事件 */
        show(n) {
            if (n===1) {
                this.landChild.isfrontIdImg = true
            } else if (n===2) {
                this.landChild.isreverseIdImg = true
            } else if (n===7) {
                this.landChild.islandimg = true
            }
        },
        hide() {
            this.landChild.isfrontIdImg = false; // 点击弹框外部时，隐藏弹框
            this.landChild.isreverseIdImg = false;
            this.landChild.islandimg = false
        },
        

        /* 提交按钮事件 */
        goBack() {
            this.$router.go(-1);
        },
        showModal(boolean) {
            if (boolean) {
                if (this.landChild.landstatus === '已驳回') {
                    this.modalText = '确定修改该条申请为已通过？';
                } else {
                    this.modalText = '确定通过该申请？';
                }
            } else {
                this.modalText = '请输入驳回原因';
            }
            this.isAccess = boolean;
            this.vmodal = true;
        },
        apply() {
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.get('/v1/administrator/originallandmanage-passthrough?originallandid=' + this.landChild.landid)
            .then(response => {
                this.$toast.success('审核通过，已录入系统！');
                this.$router.go(-1);
            })
            .catch(error => {
                // 处理错误
                alert('审核通过录入系统失败！请刷新页面或重新登录')
            });
        },
        refuse() {
            const data = {
                originallandid: this.landChild.landid,
                reasonrejection: this.refuseReason
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/administrator/originallandmanage-reject',data)
            .then(response => {
                this.$toast.success('审核驳回，已录入系统！');
                this.$router.go(-1);
            })
            .catch(error => {
                // 处理错误
                alert('审核驳回录入系统失败！请刷新页面或重新登录');
            });
        },
    },
}
</script>

<style>
/* body之下第一层，设置黑色背景色 */
.admin-apply-container {
    height: 100%;
    overflow-y: auto;
}
.admin-apply-title-change {
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
/* 表单层-更换颜色 */
.admin-apply-form {
    display: flex;
    flex-grow: 1;
    background-color: #646064;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
.admin-apply-form-full {
    vertical-align: top;
    padding: 10px 50px;
}
.admin-apply-form-left {
    vertical-align: top;
    width: 50%;
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
.admin-apply-form-right {
    vertical-align: top;
    flex-basis: 50%; /* 子 div 宽度为 30% */
    flex-wrap: wrap; /* 需要有换行，否则它们会溢出它们的容器 */
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
/* 表单元素 */
.admin-apply-form-item {
    padding: 20px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
    color: #000;
    display: flex;
    flex-direction: row;
    position: relative;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.admin-apply-form-item-label {
    min-width: 300px;
    display: inline-block;
    font-family: Alibaba PuHuiTi 2.0;
    font-size: 16px;
    font-weight: normal;
    color: #4E5969;
}
.admin-apply-form-icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 16px;
}
.admin-apply-form-item-select {
    position: absolute;
    left: 1000px;
    height: 24px;
    top: calc(50% - 10px);
    cursor: pointer;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.admin-apply-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    margin-right: 30px;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
}
/* 图片需要自适应到父块大小 */
.admin-apply-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* 描述文本 */
.admin-apply-form-item-text {
    font-size: 16px;
}
.admin-apply-form-item-link {
    border-bottom: 2px solid blue;
    color: blue;
    font-weight: normal;
    cursor: pointer;
}


/* ------------------------------上传提交开发商用户注册信息表单按钮------------------------------- */
.admin-apply-button-parent {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}
.admin-apply-button-1 {
    height: 36px;
    width: 100px;
    margin-right: 30px;
}
</style>