<template>
    <div class="admin-apply-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>企业管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="$router.push('/admin/company');">企业列表查看</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span><span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">查看详情</span></span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-apply-form-topic" v-if="whichtab === '已认证'"><span>正在修改已认证的开发商账户身份认证</span></div>
            <div class="admin-apply-form-topic" v-else-if="whichtab === '未认证'"><span>开发商账户身份认证</span></div>
            <div class="admin-apply-form-topic" v-else><span>加载中</span></div>
            <div class="admin-manage-tabs">
                <div class="admin-manage-topic">企业资质信息</div>
                <div class="admin-apply-form-full">
                    <!-- todo 加选中按钮 -->
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <img src="../adminImage/admin-company-id.png" class="admin-apply-form-icon">
                        <span class="admin-apply-form-item-label">公司编号:</span>
                        <span>{{ company.businessid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <img src="../adminImage/admin-company-cname.png" class="admin-apply-form-icon">
                        <span class="admin-apply-form-item-label">公司名称:</span>
                        <span>{{ company.corporationname }}</span>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.corporationname" @click="select.corporationname = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.corporationname = false;">
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <img src="../adminImage/admin-company-house.png" class="admin-apply-form-icon">
                        <span class="admin-apply-form-item-label">房屋所有权证编号:</span>
                        <span>{{ company.housingownership }}</span>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.housingownership" @click="select.housingownership = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.housingownership = false;">
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <img src="../adminImage/admin-company-name.png" class="admin-apply-form-icon">
                        <span class="admin-apply-form-item-label">企业联络人姓名:</span>
                        <span>{{ company.businessname }}</span>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.businessname" @click="select.businessname = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.businessname = false;">
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <img src="../adminImage/admin-company-phone.png" class="admin-apply-form-icon">
                        <span class="admin-apply-form-item-label">企业联络人手机号码:</span>
                        <span>{{ company.businessphone }}</span>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.businessphone" @click="select.businessphone = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.businessphone = false;">
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <div class="admin-apply-form-item-text">
                            <img src="../adminImage/admin-company-idcard.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">企业签约人身份证:</span>
                        </div>
                        <!-- 身份证正面照 -->
                        <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="company.businesscard1" alt="身份证正面照" class="admin-apply-form-item-img-i" @click="show(1)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isfrontIdImg 变量决定 -->
                            <div class="admin-manage-image-modal" v-if="isfrontIdImg" @click="hide">
                                <img class="full-image" :src="company.businesscard1"/>
                            </div>
                        </div>
                        <!-- 身份证背面照 -->
                        <div class="admin-apply-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="company.businesscard2" alt="身份证背面照" class="admin-apply-form-item-img-i" @click="show(2)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isreverseIdImg 变量决定 -->
                            <div class="admin-manage-image-modal" v-if="isreverseIdImg" @click="hide">
                                <img class="full-image" :src="company.businesscard2"/>
                            </div>
                        </div>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.IdCard" @click="select.IdCard = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.IdCard = false;">
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <div class="admin-apply-form-item-text">
                            <img src="../adminImage/admin-company-cprt.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">公司注册信息和注册证明:</span>
                        </div>
                        <!-- 公司注册信息与注册证明 -->
                        <a :href="company.companyregistration" target="_blank" class="admin-apply-form-item-link">点击下载查看</a>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.companyregistration" @click="select.companyregistration = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.companyregistration = false;">
                    </el-row>
                    <el-row :gutter="15" class="admin-apply-form-item">
                        <div class="admin-apply-form-item-text">
                            <img src="../adminImage/admin-company-pfct.png" class="admin-apply-form-icon">
                            <span class="admin-apply-form-item-label">平台合同扫描件:</span>
                        </div>
                        <!-- 平台合同扫描件 -->
                        <a :href="company.platformcontract" target="_blank" class="admin-apply-form-item-link">点击下载查看</a>
                        <img src="../adminImage/admin-company-select-off.png" class="admin-apply-form-item-select" v-if="!select.platformcontract" @click="select.platformcontract = true;">
                        <img src="../adminImage/admin-company-select-on.png" class="admin-apply-form-item-select" v-else @click="select.platformcontract = false;">
                    </el-row>
                </div>
                <div class="admin-apply-button-parent">
                    <el-button type="info" plain @click="goBack" class="admin-apply-button-1">返回</el-button>
                    <el-button type="primary" class="admin-apply-button-1" @click="showModal('s')" v-if="whichtab === '未认证' || whichtab === '已驳回'">通过申请</el-button>
                    <el-button type="danger" class="admin-apply-button-1" @click="showModal('v')" v-if="!(whichtab === '已驳回')">驳回申请</el-button>
                </div>
            </div>
        </div>
        <!-- 驳回申请，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="admin-apply-modal" v-if="vmodal">
            <div class="admin-apply-modal-box">
                <div class="flex flex-row">
                    <img src="../adminImage/admin-company-modal.png" class="admin-apply-modal-icon">
                    <span>驳回原因</span>
                </div>
                <el-input type="textarea" :rows="4" placeholder="请输入驳回原因" v-model="refundReason" style="width: 300px;margin-top: 20px;"></el-input>
                <span class="admin-apply-modal-error" v-show="showError">*驳回原因不可为空</span>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="refuse" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
        <!-- 通过申请，弹框的显示和隐藏状态，根据 smodal 变量决定 -->
        <div class="admin-apply-modal" v-if="smodal">
            <div class="admin-apply-modal-box">
                <span style="padding:30px;">确定要通过这条认证信息？</span>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="apply" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.businessid = this.$route.query.id;
        this.whichtab = this.$route.query.mode;
    },
    mounted() {
        this.$http.get('/v1/business/company-information-one?id=' + this.businessid + '&verificationstatus=' + this.whichtab)
            .then(res => {
                this.company = res.data.data;
            }).catch(error => {
                // 处理错误
                this.$toast.fail("企业认证数据申请失败,请检查网络或重新登录");
            });
    },
    data() {
        return {
            businessid: null, // 向后端申请数据时的参数
            vmodal: false, // 显示驳回申请弹窗
            smodal: false, // 显示通过申请二次认证弹窗
            refundReason: '', // 驳回原因
            showError: false, // 验证提示
            whichtab: '', // 三种状态,已认证、认证中、已驳回
            company: {
                businessid: '', // 企业编号
                corporationname: '', // 企业名称
                housingownership: '', // 房屋所有权证编号
                businessname: '', // 企业联络人姓名
                businessphone: '', // 企业联系电话
                businesscard1: require('../assets/no-image.png'), // 身份证正面照
                businesscard2: require('../assets/no-image.png'), // 身份证反面照
                companyregistration: '', // 公司注册信息与注册证明
                platformcontract: '', // 平台合同扫描件
            },
            isfrontIdImg: false, // 身份证正面照显示原图弹窗
            isreverseIdImg: false,

            select: {
                corporationname: false,
                housingownership: false,
                businessname: false,
                businessphone: false,
                IdCard: false,
                companyregistration: false,
                platformcontract: false,
            },
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 点击图片查看原图事件 */
        show(n) {
            if (n===1) {
                this.isfrontIdImg = true
            } else if (n===2) {
                this.isreverseIdImg = true
            }
        },
        hide() {
            this.isfrontIdImg = false; // 点击弹框外部时，隐藏弹框
            this.isreverseIdImg = false;
        },
        

        /* 提交按钮事件 */
        goBack() {
            this.$router.go(-1);
        },
        apply() {
            const data = {
                businessid: this.company.businessid
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/businesspassthrough
            this.$http.post('/v1/administrator/business-passthrough', data)
            .then(response => {
                this.$toast.success('审核通过，已录入系统！')
                this.$router.go(-1);
            })
            .catch(error => {
                // 处理错误
                alert('审核通过录入系统失败！请检查网络或重新登录')
            });
        },
        showModal(type) {
            if (type === 's') {
                this.smodal = true;
            } else {
                this.vmodal = true;
            }
        },
        closeModal() {
            this.vmodal = false;
            this.smodal = false;
        },
        refuse() {
            if(this.refundReason === '') {
                this.showError = true;
                return;
            }
            const refund = [];
            refund.push(this.select.corporationname.toString());
            refund.push(this.select.housingownership.toString());
            refund.push(this.select.businessname.toString());
            refund.push(this.select.businessphone.toString());
            refund.push(this.select.IdCard.toString());
            refund.push(this.select.companyregistration.toString());
            refund.push(this.select.platformcontract.toString());
            const data = {
                businessid: this.company.businessid,
                remark: this.refundReason,
                informationDetection: refund
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/businesspassthrough
            this.$http.post('/v1/administrator/business-reject', data)
            .then(response => {
                this.$toast.success('审核驳回，已录入系统！')
                this.$router.go(-1);
            })
            .catch(error => {
                // 处理错误
                alert('审核驳回录入系统失败！请检查网络或重新登录');
            });
        },
    }
}
</script>

<style>
.admin-apply-form-topic {
    font-size: 20px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #15ddff;
}
/* 弹窗 */
.admin-apply-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-apply-modal-box {
    padding: 30px 60px;
    max-width: 400px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.admin-apply-modal-icon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}
.admin-apply-modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
.admin-apply-modal-error {
    font-size: 12px;
    color: red;
}
</style>