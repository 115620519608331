<template>
    <keep-alive>
        <div class="mobile-shop-home" ref="landMarket">
            <van-skeleton v-for="i in 10" :key="i" class="mt-3" title avatar :row="3" :loading="loading" />
            <div class="h-full flex justify-center items-center flex-col" v-if="LandInfor.length === 0">
                <img src="../mImage/cart-empty.png" style="height:134px;width:120px;">
                <span class="cart-empty-text">{{$t('ShopCart.description')}}</span>
            </div>
            <van-pull-refresh v-model="refreshLoading"  @refresh="onRefresh" style="max-height:100%;overflow-y:auto;" v-else>
                <template #pulling>
                    <div class="text-black">{{$t('ShopCart.refresh')}} ↓</div>
                </template>
                <template #loosing>
                    <div class="text-black">{{$t('ShopCart.release')}} ↑</div>
                </template>
                <template #loading>
                    <div class="text-black">{{$t('ShopCart.refreshing')}}</div>
                </template>
                <van-swipe-cell  v-for="(land, index) in LandInfor" :key="index">
                    <ShopCartInfor
                        class="mb-1"
                        :LandInfor="land"
                        :show-delete="false"
                        :index="index"
                        ref="MyItems"
                        @selectThis="selectThis"
                        :showSelect="true"
                        @turnToDetail="turnToDetail"/>
                    <template #right>
                        <van-button square :text="$t('ShopCart.delete')" type="danger" @click="deleteThis(land.totalprice, land.isSelect,index)" style="height: 100%" class="rounded-lg shadow-md" />
                    </template>
                </van-swipe-cell>
            </van-pull-refresh>
            <div class="sticky bottom-0 left-0 right-0 flex items-center bg-gray-50 py-4 px-2">
                <div class="flex items-center">
                    <van-checkbox v-model="isSelectAll" class="mr-1 ml-1" @click="SelectAll"></van-checkbox>
                    <span class="text-sm">{{$t('ShopCart.all')}}</span>
                </div>
                <div class="mobile-detail-bottom-buttons">
                    <span class="text-lg">{{$t('ShopCart.total')}}: 
                        <span v-if="$setPayment === 'am'">${{ totalPrice }}</span>
                        <span v-else-if="$setPayment === 'zh'">￥{{ totalPrice * $exchangeRate }}</span>
                    </span>
                    <button class="bg-blue-500 hover:bg-blue-600 text-white px-4 ml-5 rounded w-30 h-10" @click="submit">{{$t('ShopCart.buy')}}</button>
                </div>
            </div>
        </div>
    </keep-alive>
</template>

<script>
import ShopCartInfor from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        ShopCartInfor, // 挂牌土地信息组件
    },
    mounted() {
        // 初始化土地市场列表
        this.$http
            .get("/v1/shoppingcart/get-landcart")
            .then((res) => {
                this.LandInfor = res.data.data;
                this.loading = false;
                this.addLandInforData();
                for (let i = 0; i < this.LandInfor.length; i++) {
                    this.totalPrice += Number(this.LandInfor[i].totalprice);
                    this.selectNum += 1;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    data() {
        return {
            loading: true,
            LandInfor: [],
            timer: null,
            firstloading: true, // mounted第一次加载，防止加载两次
            refreshLoading: false, // 下拉刷新加载
            hasMoreData: true, // 是否还有更多数据可加载
            pageNum: 1, // 页码

            totalPrice: 0.0, // 选中的商品总价格
            isSelectAll: true, // 选中所有商品，初始为true，全选
            selectNum: 0, // 选中的商品数目，如果选中的商品数目与购物车商品一致，则表示全选
        };
    },
    methods: {
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?land=" + this.LandInfor[index].originallandid + "&merch=" + this.LandInfor[index].onSale.id;
            this.$router.push({
                path: newpath,
            });
        },
        addLandInforData() {
            // 向LandInfor对象里添加选中数据
            for (let i = 0; i < this.LandInfor.length; i++) {
                this.$set(this.LandInfor[i], "isSelect", true);
            }
            this.loading = false;
        },
        loadInit(){
            this.$http
                .get("/v1/shoppingcart/get-landcart")
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.addLandInforData();
                    for (let i = 0; i < this.LandInfor.length; i++) {
                        this.totalPrice += Number(this.LandInfor[i].totalprice);
                        this.selectNum += 1;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onRefresh(){
            this.$http
                .get("/v1/shoppingcart/get-landcart")
                .then((res) => {
                    this.totalPrice = 0.0;
                    this.LandInfor = res.data.data;
                    this.addLandInforData();
                    for (let i = 0; i < this.LandInfor.length; i++) {
                        this.totalPrice += Number(this.LandInfor[i].totalprice);
                        this.selectNum += 1;
                    }
                    this.refreshLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectThis(price, boolean) {
            const priceInt = Number(price);
            if (boolean) {
                this.totalPrice += priceInt;
                this.selectNum += 1;
            } else {
                this.totalPrice -= priceInt;
                this.selectNum -= 1;
            }
            if (this.selectNum === this.LandInfor.length) {
                this.isSelectAll = true;
            } else {
                this.isSelectAll = false;
            }
        },
        deleteThis(price, boolean, index) {
            this.$http
                .delete("/v1/shoppingcart/delete-landcart?id=" + this.LandInfor[index].id)
                .then((res) => {
                    if (boolean) {
                        this.totalPrice -= Number(price);
                        this.selectNum -= 1;
                    }
                    this.$message({
                        message: this.$t('ShopCart.alertDeleteSuccess'),
                        type: "danger",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
            this.LandInfor.splice(index, 1);

        },
        SelectAll() {
            if (!this.isSelectAll) {
                this.notSelectAll();
                return;
            }
            // 全选选中
            let newprice = 0;
            for (let i = 0; i < this.LandInfor.length; i++) {
                this.LandInfor[i].isSelect = true; // 把所有购物车商品改为选中
                newprice += this.LandInfor[i].totalprice; // 重新计算所有商品总价
            }
            this.totalPrice = newprice; // 将算出的总价计入总价
            this.isSelectAll = true; // 全选
        },
        notSelectAll() {
            // 全不选中
            for (let i = 0; i < this.LandInfor.length; i++) {
                this.LandInfor[i].isSelect = false; // 把所有购物车商品改为未选中
            }
            this.totalPrice = 0;
            this.isSelectAll = false;
        },

        /*立即购买*/
        submit() {
            if (this.LandInfor.length === 0) {
                return;
            }
            let submitLandInfor = [];
            for (let i = 0; i < this.LandInfor.length; i++) {
                if (this.LandInfor[i].isSelect) {
                    submitLandInfor.push(this.LandInfor[i]);
                }
            }
            if (submitLandInfor.length === 0) {
                this.$message({
                    message: this.$t('ShopCart.alertNoProduct'),
                    type: "warning",
                });
                return;
            }
            this.$router.push({
                path: "/mobile/confirm-order",
                name: "MobileConfirmOrder",
                query: {
                    LandInfor: JSON.stringify(submitLandInfor),
                    totalPrice: JSON.stringify(this.totalPrice),
                },
            });
        },
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: calc(100vh - 200px);
    width: 100%;
    position: relative;
    background-color: #F4F5F9;
}
.mobile-shop-land {
    display: inline-block;
    width: 100%;
}
/* 空购物车的文本样式 */
.cart-empty-text {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 500;
}
/* ---------------------------------底部：快捷选中------------------------------- */
.mobile-detail-bottom {
    height: 50px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: absolute;
    bottom: 0;
}
.mobile-detail-bottom-selectall {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: row;
}
.market-select {
    /* 选中圆形栏 */
    font-size: 20px;
}
.mobile-detail-bottom-buttons {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
}
.mobile-detail-bottom-button {
    height: 35px;
    width: 80px;
    margin-left: 20px;
    background-color: #fda1ff;
    border-radius: 12px;
    color: #fff;
    font-size: 10px;
}

.select-icon {
    /* 未选中状态的样式 */
    color: #000;
}

.select-icon:hover {
    /* 鼠标悬停时的样式 */
    color: #ff0000;
}

.select-icon:active {
    /* 鼠标点击时的样式 */
    color: #00ff00;
}

.selected {
    /* 选中状态的样式 */
    color: #0000ff;
}
</style>
