<template>
    <div class="mobile-orderList-home mobile-base-background2">
        <div class="flex flex-row items-center">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileOrderList.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>

        <div class="orderList-view-infor">
            <div class="orderList-view-filter">
                <div class="orderList-view-filter-items" @click="filterMode='';productFilter();">
                    <span :style="filterMode===''?'color:#3662EC':''">{{$t('MobileOrderList.statu0')}}</span>
                    <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode===''">
                </div>
                <div class="orderList-view-filter-items" @click="filterMode='待付款';productFilter();">
                    <span :style="filterMode==='待付款'?'color:#3662EC':''">{{$t('MobileOrderList.statu1')}}</span>
                    <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode==='待付款'">
                </div>
                <div class="orderList-view-filter-items" @click="filterMode='已付款';productFilter();">
                    <span :style="filterMode==='已付款'?'color:#3662EC':''">{{$t('MobileOrderList.statu2')}}</span>
                    <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode==='已付款'">
                </div>
                <div class="orderList-view-filter-items" @click="filterMode='已退款';productFilter();">
                    <span :style="filterMode==='已退款'?'color:#3662EC':''">{{$t('MobileOrderList.statu3')}}</span>
                    <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode==='已退款'">
                </div>
                <div class="orderList-view-filter-items" @click="filterMode='售后中';productFilter();">
                    <span :style="filterMode==='售后中'?'color:#3662EC':''">{{$t('MobileOrderList.statu4')}}</span>
                    <img src="../mImage/order-list-icon.png" class="orderList-view-filter-icon" v-show="filterMode==='售后中'">
                </div>
            </div>
            <van-skeleton  v-for="i in 10" :key="i" class="mt-3" title avatar :row="3" :loading="loading" />
            <van-empty v-if="LandInfor.length === 0" :description="$t('MobileOrderList.noorder')" />
            <!-- 商品图鉴 -->
            <div class="order-list" ref="scrollContainer" @scroll="handleScroll">
                <div v-for="(order, index) in LandInfor" :key="index" class="order-bigorder-box animate__animated animate__fadeInUp">
                    <div class="order-background" v-for="(land, index2) in order.separateOrders" :key="index2"  @click.stop="turnToDetail(index,index2,land.separateOrderId)">
                        <van-checkbox icon-size="20px" class="order-select" v-model="select[index][index2].isSelect" v-show="mode !== 0" @change="checkBoxChange(index,index2)"></van-checkbox>
                        <div class="flex flex-col flex-grow ml-3">
                            <div class="order-col-first">
                                <img src="../mImage/order-list-seller.png" class="order-col-first-img">
                                <span class="order-col-first-seller">{{ land.seller + ' >' }}</span>
                                <div class="order-col-first-statu order-col-first-statu2" v-if="land.orderstatus==='待付款'">
                                    {{ getEnglish(land.orderstatus) }}
                                    {{ countdown?countdown[index].hour:0 }}h-
                                    {{ countdown?countdown[index].minute:0 }}m-
                                    {{ countdown?countdown[index].second:0 }}s
                                </div>
                                <div class="order-col-first-statu" v-else>
                                    {{ getEnglish(land.orderstatus) }}
                                </div>
                            </div>
                            <div class="order-col-second">
                                <img :src="land.landpicture?land.landpicture:require('../assets/no-image.png')" class="order-col-second-img"/>
                                <div class="order-col-second-name">
                                    <span class="order-col-second-name-merch">{{ land.landname }}</span>
                                    <span class="order-col-second-name-orig">{{ land.paymeans }}</span>
                                </div>
                                <div class="flex-grow flex justify-end">
                                    <div class="flex items-center">
                                        <span class="text-red-500 text-lg" v-if="$setPayment === 'am'">${{ land.totalprice }}</span>
                                        <span class="text-red-500 text-lg" v-else-if="$setPayment === 'zh'">￥{{ land.totalprice * $exchangeRate }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- 申请退款 --><div class="order-col-forth-button-red" @click="showRefund=true;refundPrice=order.totalAmount;orderInfor = land" v-if="refundJudge(land)">{{$t('MobileOrderList.refund')}}</div>
                        </div>
                    </div>
                    <div class="order-col-third" :class="getTip(order.separateOrders[0].orderstatus)">{{ getTip2(order.separateOrders[0].orderstatus,order.refundAmount) }}</div>
                    <div class="order-col-forth">
                        <!-- 继续支付 --><div class="order-col-forth-button-red" @click="showContinue = true;totalPrice = order.totalAmount;orderInfor = order" v-if="continuePayJudge(order.separateOrders)">{{$t('MobileOrderList.continue')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-container">
            <button class="footer-button bg-green-400" v-if="mode === 0" @click="mode = 1;totalPrice = 0;">{{$t('MobileOrderList.segPay')}}</button>
            <!-- <button class="footer-button bg-yellow-300 ml-3" v-if="mode === 0" @click="mode = 2;refundPrice = 0;">批量退款</button> -->
            <!-- 继续支付 -->
            <button class="footer-button bg-green-400" v-if="mode === 1" @click="segContinuePayment">{{$t('MobileOrderList.startPay')}}</button>
            <!-- 退款 -->
            <button class="footer-button bg-yellow-300" v-if="mode === 2" @click="segRefund">{{$t('MobileOrderList.refund')}}</button>
            <!-- 取消批量 -->
            <button class="footer-button bg-gray-400 ml-3" v-if="mode !== 0" @click="mode = 0;initializeSelect();">{{$t('MobileOrderList.cancelSeg')}}</button>
        </div>

        <!-- 退款弹窗 -->
        <van-popup v-model="showRefund" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.refundNum')}}</span>
                        <span class="text-gray-600 text-lg my-2 ">￥{{ refundPrice }}</span>
                    </div>
                    <div class="flex flex-col items-center justify-center p-2">
                        <span class="text-gray-600 text-md ">{{$t('MobilePurchaseDetails.reason')}}</span>
                        <van-field
                            v-model="refundReason"
                            :placeholder="$t('MobilePurchaseDetails.reasonInput')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                            class="refund-modal-input-box"
                        />
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showRefund = false;refundReason = '';">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="RefundConfirm">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>

        <!-- 继续支付弹窗 -->
        <van-popup v-model="showContinue" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center text-gray-600">
                        <span class="text-md">{{$t('MobileOrderList.continueTitle')}}</span>
                        <span class="text-lg my-2" v-if="$setPayment === 'am'">${{totalPrice.toFixed(2)}}</span>
                        <span class="text-lg my-2" v-if="$setPayment === 'zh'">￥{{(totalPrice * $exchangeRate).toFixed(2)}}</span>
                    </div>
                    <div class="grid grid-cols-1 gap-2 text-lg">
                        <!-- 支付方式 -->
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                            <div class="flex items-center">
                                <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.aliPay')}}
                            </div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="aliPay" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                            <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="wechat" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'am'">
                            <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="PayPal"></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10">
                            <div class="flex items-center"><img src="../images/balance.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.balance')}}
                            </div>
                            <van-radio class="flex justify-center items-center ml-5" v-model="payWay" name="balance"></van-radio>
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showContinue = false;">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="onSubmit">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
        <van-popup id="popup" v-model="showPopup" closeable close-icon-position="top-left" position="bottom" round class="pb-14" @close="onClosePop">
            <!-- 初次设置支付密码 -->
            <div class="flex flex-col justify-center items-center text-lg mt-12" v-if="firstSetPayPassword">
                {{$t('MobileConfirmOrder.alertNoPayPassword')}}
                <span class="text-3xl text-blue-500 mt-3" @click="$router.push('/mobile/change-password/pay')">{{$t('MobileConfirmOrder.alertNoPayPassword2')}}</span>
            </div>
            <!-- 请支付 -->
            <div class="flex flex-row justify-center items-center text-center text-3xl mt-12" >
                <span>{{$t('all.payPlease')}}:</span>
                <span v-if="$setPayment === 'am'">${{totalPrice.toFixed(2)}}</span>
                <span v-if="$setPayment === 'zh'">￥{{(totalPrice * $exchangeRate).toFixed(2)}}</span>
            </div>
            <div v-if="!firstSetPayPassword && !showPasswordInput" class="flex flex-col justify-center items-center text-center text-sm text-gray-400 mt-1" >
                <span>{{$t('MobileConfirmOrder.balance')}} <span class="text-red-500 ml-2">{{ balance[0] }}.<span class="text-sm">{{ balance[1] }}</span></span></span>
                <span class="ml-3 text-red-300">{{balance[2] < totalPrice?$t('MobileConfirmOrder.insufficientBalance'):''}}</span>
                <router-link to="/usermobile/recharge" class="text-blue-500"> &nbsp;&nbsp;{{$t('MobileConfirmOrder.topup')}}</router-link>
            </div>
            <div class="mx-3" v-if="!firstSetPayPassword && !showPasswordInput">
                <div class="mt-2">
                    <span class="text-blue-500 ">{{$t('MobileConfirmOrder.mixPay')}}</span>
                    <span v-if="$setPayment === 'am'">${{((totalPrice)/100-balance[2]).toFixed(2)}}:</span>
                    <span v-if="$setPayment === 'zh'">￥{{(((totalPrice)/100-balance[2]) * $exchangeRate).toFixed(2)}}：</span>
                </div>
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <!-- 支付方式 -->
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixAliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixWechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'am'">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixPaypal"></van-radio>
                    </div>
                </div>
            </div>
            <div v-if="!firstSetPayPassword && !showPasswordInput" @click="onPay" class="rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileConfirmOrder.pay')}}</span>
            </div>

            <!-- 密码输入框 -->
            <van-password-input
                v-if="showPasswordInput"
                :value="payPassword"
                :focused="showKeyboard"
                :info="$t('MobileConfirmOrder.enterNewPassword')"
                :error-info="errorInfo"
                @focus="onFocusPassword"
                class="mb-60"
            />

            <!-- 数字键盘 -->
            <van-number-keyboard
                v-model="payPassword"
                :show="showKeyboard"
                @blur="onBlurPassword"
            />
        </van-popup>

    </div>
</template>

<script>
export default {
    mounted() {
        // 获取当前时间
        this.dateTime = new Date().getTime();
        // 初始化我的订单列表
        this.$http.get('/v1/order/select-separateorder-list?pageSize=' + this.pageSize + '&pageNum=' + this.pageNum)
        .then(response => {
            this.LandInfor = response.data.data.rows;
            this.countdown = [];
            this.initializeSelect(); // 初始化订单列表系统
            this.initializeInterval(); // 初始化倒计时系统
            this.loading = false;
        }).catch(error => {})
        // 获取支付密码
        this.$http.get("/v1/register/getppd-status").then((res) => {
            this.firstSetPayPassword = res.data.data;
        }).catch(error => {
            this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'));
        });
        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                const value = Number(res.data.data.balance);
                this.balance = value.toFixed(2).toString().split(".");
                this.balance.push(value);
            })
            .catch((err) => {});
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {})
    },
    data() {
        return {
            messages: 0,
            firstSetPayPassword: true, // 是否为第一次设置支付密码，true为未设置，false为已设置
            dateTime: null, // 当前时间
            loading: true, // 骨架屏加载条
            pageSize: 10, // 页距
            pageNum: 1, // 页码
            flag: true, // 防抖
            mode: 0, // 模式，0为查看模式，1为批量支付模式，2为批量退款模式
            showRefund: false, // 退款弹窗
            LandInfor: [], // 订单数据
            timer: null, // 定时器
            countdown: null, // 定时器剩余时间数组
            example: [
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1925.0",
                    paymentStatus: "已付款",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黑土地",
                        totalAmount: "1000.0",
                        paymentStatus: "交易成功",
                        landpicture: null,
                        canceltime: '111',
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黄土地",
                        totalAmount: "900.0",
                        paymentStatus: "已退款",
                        landpicture: "/image/20230731145640.png",
                        canceltime: '111',
                    }],
                },
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1100.0",
                    paymentStatus: "售后中",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "红土地",
                        totalAmount: "1100.0",
                        paymentStatus: "退款中",
                        landpicture: null,
                        canceltime: '111',
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "泥土地",
                        totalAmount: "1200.0",
                        paymentStatus: "已驳回",
                        landpicture: null,
                        canceltime: '111',
                    }],
                },
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1300.0",
                    paymentStatus: "待付款",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "湿土地",
                        totalAmount: "1300.0",
                        paymentStatus: "待付款",
                        landpicture: null,
                        canceltime: '111',
                    }],
                },
            ], // 后端获取的订单信息
            select: [[{isSelect: false},{isSelect: false}],[{isSelect: false},{isSelect: false}],[{isSelect: false}]],
            selectIndex1: null, // 选中,reason: null的商品下标1
            selectIndex2: null, // 选中的商品下标2
            refundPrice: 0, // 退款金额
            refundReason: '', // 退款弹窗输入框临时存储区
            flag: true, // 防抖
            showSteps: true, // 是否隐藏进度条
            filterMode: '', // 筛选模式
            filterOption: [
                { text: '全部商品', value: '' },
                { text: '未付款', value: '待付款' },
                { text: '交易成功', value: '已付款' },
                { text: '已退款', value: '已退款' },
                { text: '退款中', value: '售后中' },
            ],
            showContinue: false, // 继续支付弹窗
            payWay: 'PayPal', // 继续支付支付方式，默认PayPal支付
            orderInfor: null, // 继续支付交易信息
            balance: ['0','00',0], // 余额，在mounted里获取
            totalPrice: 0, // 继续支付所需金额
            showPopup: false, // 继续支付余额支付余额不足选择支付方式弹窗
            showPasswordInput: false, // 余额支付密码弹窗
            payPassword: "", // 余额支付密码
            showKeyboard: false, // 键盘
            errorInfo: "", // 错误信息
            orders: [], // 批量继续支付选中的订单集合
        };
    },
    methods: {
        doNothing() {
            return;
        },
        /* 初始化复选框列表 */
        initializeSelect() {
            this.select = [];
            for (let i =0;i < this.LandInfor.length;i++) {
                let array = [];
                for (let j =0;j < this.LandInfor[i].separateOrders.length;j++) {
                    array.push({isSelect: false});
                }
                this.select.push(array);
            }
        },
        /* 订单状态在后端是中文，需要有双语 */
        getEnglish(statu) {
            if (statu === '待付款') {
                return this.$t('MobileOrderList.time');
            } else if (statu === '已付款') {
                return this.$t('MobileOrderList.statu2');
            } else if (statu === '已退款') {
                return this.$t('MobileOrderList.statu3');
            } else if (statu === '退款中') {
                return this.$t('MobileOrderList.statu4');
            } else if (statu === '已驳回') {
                return this.$t('MobileOrderList.statu5');
            } else if (statu === '已取消') {
                return this.$t('MobileOrderList.statu6');
            }
        },
        /* 根据状态不同会有提示文本，需要设置css样式 */
        getTip(statu) {
            if (statu === '已退款') {
                return 'order-col-third-yituikuan';
            } else if (statu === '退款中') {
                return 'order-col-third-shouhouzhong';
            } else if (statu === '已驳回') {
                return 'order-col-third-yibohui';
            } else {
                return 'hidden'
            }
        },
        /* 这部分是提示文本 */
        getTip2(statu,price) {
            if (statu === '已退款') {
                return this.$t('MobileOrderList.tip1') + this.$t('ShopCartInfor.price') + price;
            } else if (statu === '退款中') {
                return this.$t('MobileOrderList.tip2');
            } else if (statu === '已驳回') {
                return this.$t('MobileOrderList.tip3');
            } else {
                return ''
            }
        },
        /* 退款申请按钮是否显示判断 */
        refundJudge(order) {
            if (order.orderstatus === '已付款' || order.orderstatus === '已驳回') {} else { return false; }
            if (order.terminationtime > this.dateTime) {
                return true;
            } else {
                return false;
            }
        },
        /* 继续支付按钮是否显示判断 */
        continuePayJudge(order) {
            for (let i =0;i < order.length;i++) {
                if (order[i].orderstatus === '待付款') {} else { return false; }
            }
            return true;
        },
        /* turnToDetail(index,index2,unitlandid,orderid) { // 跳转到土地详情页
            const status = this.LandInfor[index].separateOrders[index2].orderstatus
            if (this.mode) { // 查看模式，跳转到详情页面
                if (status === '已付款' || status === '待付款') {
                    const newpath = "/mobile/my-order/detail?order=" + unitlandid
                    this.$router.push({
                        path: newpath,
                    });
                } else if(status === '已退款' || status === '退款中' || status === '已驳回') {
                    const newpath = "/mobile/my-order/refund?order=" + unitlandid
                    this.$router.push({
                        path: newpath,
                    });
                }
            } else {
                if (!this.select[index][index2].isSelect) { // 选中该商品
                    if (status === '已付款' || status === '已驳回') {
                        this.selectIndex1 = index;
                        this.selectIndex2 = index2;
                        this.refundPrice = this.LandInfor[index].separateOrders[index2].totalprice
                        this.showRefund = true;
                    } else {
                        this.$toast(this.$t('MobileOrderList.alertCanotRefund'))
                    }
                }
            }
        }, */
        turnToDetail(index,index2,unitlandid) {
            if (this.mode === 0) { // 进入详情
                const status = this.LandInfor[index].separateOrders[index2].orderstatus
                if (status === '已付款' || status === '待付款' || status === '已驳回' || status === '已取消') {
                    const newpath = "/mobile/my-order/detail?order=" + unitlandid
                    this.$router.push({
                        path: newpath,
                    });
                } else if (status === '已退款' || status === '退款中') {
                    const newpath = "/mobile/my-order/refund?order=" + unitlandid
                    this.$router.push({
                        path: newpath,
                    });
                }
            } else if (this.mode === 1) { // 批量支付模式
                if (this.LandInfor[index].separateOrders[index2].orderstatus !== '待付款') {
                    this.$toast.fail(this.$t('MobileOrderList.alertCanotSelect'));
                    this.select[index][index2].isSelect = false;
                    return;
                }
                this.select[index][index2].isSelect = !this.select[index][index2].isSelect;
                if (this.select[index][index2].isSelect) {
                    this.orders.push(this.LandInfor[index].separateOrders[index2].separateOrderId);
                    this.totalPrice += this.LandInfor[index].separateOrders[index2].totalprice;
                } else {
                    this.orders.filter(item => item.separateOrderId !== this.LandInfor[index].separateOrders[index2].separateOrderId);
                    this.totalPrice -= this.LandInfor[index].separateOrders[index2].totalprice;
                }
            } else if (this.mode === 2) { // 批量退款模式
                if (this.LandInfor[index].separateOrders[index2].orderstatus !== '已付款' && this.LandInfor[index].separateOrders[index2].orderstatus !== '已驳回') {
                    this.$toast.fail(this.$t('MobileOrderList.alertCanotSelect'));
                    this.select[index][index2].isSelect = false;
                    return;
                }
                this.select[index][index2].isSelect = !this.select[index][index2].isSelect;
                if (this.select[index][index2].isSelect) {
                    this.orders.push(this.LandInfor[index].separateOrders[index2]);
                    this.refundPrice += this.LandInfor[index].separateOrders[index2].totalprice;
                } else {
                    this.orders.filter(item => item.ids !== this.LandInfor[index].separateOrders[index2].ids);
                    this.refundPrice -= this.LandInfor[index].separateOrders[index2].totalprice;
                }
            }
        },
        checkBoxChange(index,index2) {
            if (this.mode === 1) { // 批量支付模式
                if (this.LandInfor[index].separateOrders[index2].orderstatus !== '待付款') {
                    this.$toast.fail(this.$t('MobileOrderList.alertCanotSelect'));
                    this.select[index][index2].isSelect = false;
                    return;
                }
            } else if (this.mode === 2) { // 批量退款模式
                if (this.LandInfor[index].separateOrders[index2].orderstatus !== '已付款' && this.LandInfor[index].separateOrders[index2].orderstatus !== '已驳回') {
                    this.$toast.fail(this.$t('MobileOrderList.alertCanotSelect'));
                    this.select[index][index2].isSelect = false;
                    return;
                }
            }
        },
        /* 申请退款 */
        RefundConfirm(){ // 发送退款申请
            if (!this.flag) {
                return;
            }
            this.flag = false;
            this.dateTime = new Date().getTime();
            if (this.orderInfor.terminationtime > this.dateTime) {
                // todo 开始退款
                const toastLoading = this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                const data = {
                    separateOrderId: this.orderInfor.separateOrderId,
                    refundreason: this.refundReason
                }
                this.$http.get('/v1/order/request-refund',data).then(response => {
                    toastLoading.clear();
                    this.$toast.success(this.$t('MobileOrderList.alertRefundSuccess'));
                    this.$router.push('/mobile/my-order/refund?order=' + this.orderInfor.separateOrderId);
                }).catch(error => {
                    console.log(error)
                })
            } else {
                this.$toast.fail(this.$t('MobileOrderList.alertRefund'));
            }
        },
        initializeInterval() { // 初始化待付款订单的剩余时间
            const NOW = new Date().getTime();
            for (let i = 0;i < this.LandInfor.length; i++) {
                if (this.LandInfor[i].separateOrders[0].orderstatus === '待付款') {
                    let totalSeconds = Math.floor((this.LandInfor[i].separateOrders[0].canceltime - NOW) / 1000);
                    let hour = Math.floor(totalSeconds / 3600);
                    let minute = Math.floor((totalSeconds % 3600) / 60);
                    let second = totalSeconds % 60;
                    this.countdown.push({
                        hour: hour,
                        minute: minute,
                        second: second,
                        totalSeconds: totalSeconds
                    });
                } else {
                    this.countdown.push(null);
                }
            }
            this.timer = setInterval(() => {
                this.countdown = this.countdown.map(item => {
                    if (item) {
                        if (--item.totalSeconds <= 0) {
                            item.totalSeconds = 0;
                        }
                        item.hour = Math.floor(item.totalSeconds / 3600);
                        item.minute = Math.floor((item.totalSeconds % 3600) / 60);
                        item.second = item.totalSeconds % 60;
                        return item;
                    }
                });
            }, 1000);
        },
        productFilter() { // 切换筛选订单状态时筛选商品的事件
            this.loading = true;
            this.mode = 0;
            this.pageNum = 1; // 把页码调回1
            if (this.filterMode === '') {
                this.$http.get('/v1/order/select-separateorder-list?pageSize=' + this.pageSize + '&pageNum=' + this.pageNum)
                .then(response => {
                    this.LandInfor = response.data.data.rows;
                    this.initializeSelect();
                    this.initializeInterval();
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                })
            } else {
                this.$http.get('/v1/order/select-separateorder-list?pageSize=' + this.pageSize + '&pageNum=' + this.pageNum + '&paymentstatus=' + this.filterMode)
                .then(response => {
                    this.LandInfor = response.data.data.rows;
                    this.initializeSelect();
                    this.initializeInterval();
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        // 滚动条事件加载更多订单
        handleScroll() {
            if (this.mode === 0 && this.flag) {
                const scrollContainer = this.$refs.scrollContainer;
                const scrollHeight = scrollContainer.scrollHeight;
                const scrollTop = scrollContainer.scrollTop;
                const clientHeight = scrollContainer.clientHeight;

                // 判断滚动条距离底部的距离是否达到一定范围
                if (scrollHeight - (scrollTop + clientHeight) < 100) {
                    this.flag = false;
                    this.pageNum += 1;
                    let url = '/v1/order/select-separateorder-list?pageSize=' + this.pageSize + '&pageNum=' + this.pageNum;
                    if (this.filterMode !== '') {
                        url += '&paymentstatus=' + this.filterMode;
                    }
                    this.$http.get(url)
                    .then(response => {
                        const Land = response.data.data.rows;
                        this.LandInfor.push(Land);
                        this.loadMoreSelect(Land);
                        this.loadMoreInterval(Land);
                    }).catch(error => {
                        this.$toast.fail('Error Contact');
                    })
                    // 延时一秒
                    setTimeout(()=>{
                        this.flag = true;
                    } ,1000)
                }
            }
        },
        // 滚动条事件加载更多多选框事件
        loadMoreSelect(land) {
            for (let i =0;i < land.length;i++) {
                let array = [];
                for (let j =0;j < land[i].separateOrders.length;j++) {
                    array.push({isSelect: false});
                }
                this.select.push(array);
            }
        },
        // 滚动条事件加载更多待付款订单剩余时间倒计时事件
        loadMoreInterval(land) {
            const NOW = new Date().getTime();
            for (let i = 0;i < land.length; i++) {
                if (land[i].separateOrders[0].orderstatus === '待付款') {
                    let totalSeconds = Math.floor((land[i].separateOrders[0].canceltime - NOW) / 1000);
                    let hour = Math.floor(totalSeconds / 3600);
                    let minute = Math.floor((totalSeconds % 3600) / 60);
                    let second = totalSeconds % 60;
                    this.countdown.push({
                        hour: hour,
                        minute: minute,
                        second: second,
                        totalSeconds: totalSeconds
                    });
                } else {
                    this.countdown.push(null);
                }
            }
        },
        onClosePop(){
            this.payWay='balance';
            // 等待500ms
            setTimeout(() => {
                this.showPasswordInput = false;
            }, 500);
        },
        onFocusPassword() {
            this.showKeyboard = true;
            // document.querySelector('#popup').style.height = "80%";
        },
        onBlurPassword() {
            this.showKeyboard = false;
            //删除height的设置
            document.querySelector('#popup').style.height = "";
        },
        /* 异步等待接口 */
        async fetchDataWithTimeout(url) {
            try {
                // 发送POST请求
                const response = await this.$http.post(url, { data });

                // 设置最长十分钟的超时时间
                const timeout = 10 * 60 * 1000; // 十分钟的毫秒数

                // 定义一个Promise，等待十分钟内收到状态为200的响应
                const response200 = new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(new Error('Timeout: Did not receive response within 10 minutes.'));
                    }, timeout);

                    if (response.status === 200) {
                        resolve(response);
                    }
                });

                // 等待Promise的返回结果
                const result = await Promise.race([response200]);

                // 处理状态为200的响应
                if (result.data) {
                    this.$toast.success(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.push('/mobile/pay-success');
                } else {
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    this.$router.push('/mobile/pay-fail');
                }
            } catch (error) {
                // 捕获异常并进行处理
                console.error('Error occurred:', error.message);
            }
        },
        /** 单个独立订单继续支付 */
        onSubmit() {
            if (this.payWay==='balance'){
                this.showContinue = false;
                this.showPopup = true;
                // 第一次设置支付密码
                if (firstSetPayPassword) {}
                /** 检查余额是否足够*/
                else if (this.balance[2] < this.orderInfor.totalAmount) {
                    this.$toast(this.$t('MobileConfirmOrder.alertNoMoney'));
                }
                /** 余额足够*/
                else {
                    this.showPasswordInput = true;
                }
            } else {
                let unitlandids = [];
                for (let i =0;i < this.orderInfor.separateOrders.length;i++) {
                    unitlandids.push(this.orderInfor.separateOrders[i].unitlandid);
                }
                const data = {
                    needpaper: this.orderInfor.separateOrders[0].needpaper,
                    unitlandid: unitlandids,
                    addressid: this.orderInfor.separateOrders[0].addressid,
                    ischeck: true,
                }
                if (this.payWay === "aliPay") {
                    this.$toast(this.$t('MobileConfirmOrder.alertalipay'));
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileConfirmOrder.alertLoading'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/alipay/pay", data).then((res) => {
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
                } else if (this.payWay === "wechat") {
                    toastLoading.clear();
                    this.$toast(this.$t('MobileConfirmOrder.alertWechat'));
                } else if (this.payWay==='PayPal') {
                    this.$toast(this.$t('MobileConfirmOrder.alertPaypal'));
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileConfirmOrder.alertLoading'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/paypal/pay", data).then((res) => {
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
                }
            }
        },
        /** 点击余额支付*/
        onPay(){
            /** 检查余额是否足够*/
            if (this.payWay==='balance'){
                if(this.balance[2]<this.totalPrice){
                    this.$toast(this.$t('MobileConfirmOrder.alertNoMoney'));
                    return;
                } else { // 余额足够
                    this.showPasswordInput = true;
                }
            } else { // 混合支付方式，先输密码
                this.showPasswordInput = true;
            }
        },
        /* 单个订单输入完支付密码后完成下一步支付 */
        goPay(){
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            let unitlandids = [];
            for (let i =0;i < this.orderInfor.separateOrders.length;i++) {
                unitlandids.push(this.orderInfor.separateOrders[i].unitlandid);
            }
            const data = {
                needpaper: this.orderInfor.needpaper,
                addressid: this.orderInfor.addressid,
                unitlandid: unitlandids,
                paymentpassword: this.payPassword,
                ischeck: true,
            };
            if(this.payWay==='balance'){
                this.$http.post("/v1/balance-payment/pay", data)
                    .then((res) => {
                    toastLoading.clear();
                    if (res.data.data==='密码错误') {
                        this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                        this.showKeyboard=false;
                        this.payPassword='';
                        return;
                    }
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    window.location.reload(); // 刷新页面
                });
            } else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data).then((res) => {
                    if (res.data.data==='密码错误') {
                        this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                        this.showKeyboard=false;
                        this.payPassword='';
                        return ;
                    }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            }
            else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
            }
            else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data).then((res) => {
                    if (res.data.data==='密码错误') {
                        this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                        this.showKeyboard=false;
                        this.payPassword='';
                        toastLoading.clear();
                        return ;
                    }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            }
        },
        /* 批量支付 */
        segContinuePayment() {
            if (this.orders.length < 1) {
                return;
            }
            this.$router.push({
                name: 'MobileOrderContinue',
                query: {
                    totalPrice: JSON.stringify(this.totalPrice),
                },
                params: {
                    orders: this.orders,
                }
            });
        },
        /* 批量退款 */
        segRefund() {
            if (this.refundPrice > 0) {
                this.showRefund = true;
            }
        },
    },
    watch:{
        // 密码输入完成
        payPassword(payPassword){
            if (payPassword.length >= 6) {
                this.payPassword = payPassword.substring(0, 6);  //  截取前六位
                this.goPay();
            }
        }
    },
    /* 生命周期结束时清除计时器 */
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
}
</script>

<style scoped>
.mobile-orderList-home {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
/* 退款信息 */
.orderList-view-infor {
    flex-grow: 1;
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
}
/* 筛选 */
.orderList-view-filter {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 8px 0;
}
.orderList-view-filter-items {
    width: 20%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px 0;
    position: relative;
}
.orderList-view-filter-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 7px;
    width: 7px;
}


/* ----------------------------------------商品图鉴----------------------------------------- */
.order-list {
    width: 100%;
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}
.order-bigorder-box {
    background-color: #ffffff;
    margin-top: 10px;
    border-radius: 6px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
    padding: 10px 16px 8px 10px;
}
.order-background {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
/* 第一行，包括小图标、卖家名称、订单状态 */
.order-col-first {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.order-col-first-img {
    height: 16px;
    width: 16px;
}
.order-col-first-seller {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 600;
}
.order-col-first-statu {
    flex-grow: 1;
    text-align: right;
    font-size: 13px;
    color: #8E8E8E;
}
.order-col-first-statu2 {
    font-size: 12px;
    color: #FA5151;
}

/* 第二行，包括商品图片、商品名称及支付方式、商品价格 */
.order-col-second {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.order-col-second-img {
    height: 72px;
    width: 72px;
}
.order-col-second-name {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
}
.order-col-second-name-merch {
    font-size: 14px;
    color: #230B34;
}
.order-col-second-name-orig {
    margin-top: 15px;
    padding: 4px 8px;
    background-color: rgba(11, 63, 227, 0.1);
    font-size: 10px;
    color: #0B3FE3;
}

/* 第三行，提示文本 */
.order-col-third {
    width: 100%;
    margin-top: 8px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 4px 12px;
    font-size: 14px;
}
.order-col-third-yituikuan {
    color: #3D3D3D;
}
.order-col-third-shouhouzhong {
    color: #ED763D;
}
.order-col-third-yibohui {
    color: #FA5151;
}

/* 第四行，按钮 */
.order-col-forth {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.order-col-forth-button-black {
    padding: 5px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1608);
    border-radius: 265px;
    font-size: 14px;
}
.order-col-forth-button-red {
    padding: 5px 16px;
    border: 1px solid #ED763D;
    border-radius: 265px;
    font-size: 14px;
    color: #ED763D;
}


.refund-modal-input-box {
    border-radius: 6px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}

/* ----------------------------------------底部按钮----------------------------------------- */
.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0px 2px 0 rgb(0, 0, 0);
    padding: 20px;
    text-align: right;
}
.footer-button {
    height: 40px;
    width: 100px;
    border-radius: 20px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}
</style>