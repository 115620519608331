<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>挂牌管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">挂牌列表查看</span>
        </div>
        <div class="admin-manage-main">
            <div class="admin-manage-tabs">
                <div class="admin-manage-tabs-head">
                    <div class="admin-manage-tabs-head-item" :class="isTab('已挂牌')" @click="tabsControl('已挂牌')">
                        <span @mouseover="changeCursor">挂牌中</span>
                    </div>
                    <div class="admin-manage-tabs-head-item" :class="isTab('已摘牌')" @click="tabsControl('已摘牌')">
                        <span @mouseover="changeCursor">已下架</span>
                    </div>
                </div>
                <div class="admin-manage-search-container">
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">挂牌编号</span>
                            <el-input style="width:256px;" v-model="search.id" placeholder="请输入挂牌编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">卖家昵称</span>
                            <el-input style="width:256px;" v-model="search.sellerid" placeholder="请输入卖家编号"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">原始土地编号</span>
                            <el-input style="width:256px;" v-model="search.originallandid" placeholder="请输入商品土地所属原始土地编号"></el-input>
                        </div>
                        <div class="admin-manage-search-input" style="margin-top: 20px;">
                            <span class="admin-manage-search-input-label">小区编号</span>
                            <el-input style="width:256px;" v-model="search.areaid" :placeholder="canInputAreaid?'请先输入原始土地编号':'格式:区(1,1)'" :disabled="canInputAreaid"></el-input>
                        </div>
                    </div>
                    <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <span class="admin-manage-search-input-label">挂牌时间</span>
                            <el-date-picker
                                style="width:256px;"
                                v-model="search.date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <!-- <div class="admin-manage-search-box">
                        <div class="admin-manage-search-input">
                            <el-checkbox v-model="selectThisPage" :indeterminate="isIndeterminate" @change="checkThisPageChange">全选当前页</el-checkbox>
                        </div>
                    </div> -->
                    <div class="admin-manage-search-box items-end" style="flex:1;">
                        <div class="admin-manage-search-buttonbox admin-manage-search-input" style="height:100%;width:auto;">
                            <el-button type="primary" @click="updatePage">
                                <img src="../adminImage/admin-search.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">查询</span>
                            </el-button>
                            <el-button type="info" plain style="margin-left: 20px;" @click="resetUser">
                                <img src="../adminImage/admin-refresh.png" class="admin-manage-search-icon">
                                <span style="vertical-align: middle;">重置</span>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="admin-manage-tabs-content">
                    <el-row :gutter="15" class="admin-manage-tabs-content-row admin-manage-tabs-content-row-topic">
                        <!-- <el-col :span="2"></el-col> -->
                        <el-col :span="4"><span>挂牌时间</span></el-col>
                        <el-col :span="2"><span>挂牌编号</span></el-col>
                        <el-col :span="4"><span>商品名称</span></el-col>
                        <el-col :span="2"><span>原始土地编号</span></el-col>
                        <el-col :span="2"><span>小区编号</span></el-col>
                        <el-col :span="4"><span>卖家昵称</span></el-col>
                        <el-col :span="2"><span>售价</span></el-col>
                        <el-col :span="4"><span>操作</span></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <div class="admin-manage-tabs-conten-row-flow" v-if="!loading">
                        <el-row :gutter="15" v-for="(apply, index) in LandInfor" :key="index" class="admin-manage-tabs-content-row">
                            <div class="admin-manage-tabs-content-col">
                                <!-- <el-col :span="2"><el-checkbox v-model="checked"></el-checkbox></el-col> -->
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.createtimee" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.createtime }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="toString(apply.id)" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.id }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.landname" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.landname }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.originallandid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.originallandid }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="apply.communitynumber" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.communitynumber }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <el-tooltip class="item" :content="apply.sellerid" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.seller }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip class="item" :content="toString(apply.pricenum)" placement="top">
                                        <span class="admin-manage-tabs-content-col-text">{{ apply.pricenum }}</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="4">
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="showDetail(apply.id)">查看详情</span>
                                    &nbsp;
                                    <span @mouseover="changeCursor" style="text-decoration: underline;" @click="showModal(apply.id)" v-show="apply.status === '已挂牌'">强制摘牌</span>
                                </el-col>
                            </div>
                        </el-row>
                        <div class="admin-manage-no-search" v-if="showLength===0">未查询到结果</div>
                    </div>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 70, 90, 100]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 这个是用来强制摘牌的弹窗 -->
        <div class="admin-apply-modal" v-show="modal.bmodal">
            <div class="admin-apply-modal-box">
                <div class="flex flex-row">
                    <img src="../adminImage/admin-company-modal.png" class="admin-apply-modal-icon">
                    <span>请输入摘牌原因</span>
                </div>
                <el-input type="textarea" :rows="4" class="admin-manage-modal-input" placeholder="请输入摘牌原因" v-model="banReason"></el-input>
                <div>
                    <el-button type="info" plain class="admin-apply-button-1" style="margin-right: 30px;" @click="closeModal">取消</el-button>
                    <el-button type="primary" class="admin-apply-button-1" @click="unload">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        if (localStorage.getItem("admin-sell")) {
            this.whichtab = localStorage.getItem("admin-sell")
        }
    },
    mounted() {
        this.$http.post("/v1/administrator/select-salemanage-list", {
            status: this.whichtab,
            pagesize: this.pageSize,
            pagenum: this.currentPage
        }).then((response) => {
            this.LandInfor = response.data.data.rows;
            this.showLength = response.data.data.total;
            this.loading = false;
        })
        .catch((error) => {
            this.$toast.fail("初始化失败，请刷新页面或重新登录");
        });
    },
    watch: {
        'search.originallandid': {
            handler(newVal, oldVal) {
                if (newVal !== '') {
                    this.canInputAreaid = false;
                } else {
                    this.search.areaid = '';
                    this.canInputAreaid = true;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            LandInfor: [{
                "id": 138,
                "originallandid": "S666",
                "unitlandid": "6004",
                "landname": "火焰山",
                "sellerid": "b4bcab5f1afa44e8bbba5449afa13d6d",
                "seller": "Mrs.wang",
                "salesmethods": "立即购买",
                "expirydate": "",
                "unitlandprice": null,
                "pricenum": 1301.0,
                "unitlandminprice": null,
                "minimumprice": null,
                "blocks": 2,
                "totalprice": 1301.0,
                "unitpicture": "/image/20230731145711.png",
                "createtime": "2023-06-20 13:41:41.131",
                "reasondelisting": null,
                "status": "已挂牌",
                "remark": "1",
                "starttime": null,
                "endtime": null,
                "pagesize": 0,
                "pagenum": 0,
                "start": 0,
                "originalLand": null,
                "unitLand": null
            }], // 测试数据，正常用户数据

        loading: true,
        showLength: 0, // 所有数据的长度
        whichtab: '已挂牌',
        pageSize: 50, // 每页条数
        currentPage: 1,// 页码

        selectAll: false, // 全选所有挂牌
        selectThisPage: false, // 全选当前页挂牌
        isIndeterminate: false, // 全选当前页是否未全选但选中部分
        checkList: [], // 全选当前页的数组
        selectId: '', // 选中摘牌的商品id
        banReason: '', // 摘牌原因
        canInputAreaid: true, // 是否可输入小区编号
        search: {
            id: '',
            originallandid: '',
            areaid: '',
            sellerid: '',
            date: '',
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        
        modal: {
            bmodal: false, // 封禁用户的弹窗
            vmodal: false, // 弹窗开关
            text: '', // 带按钮的弹窗内容
            showError: false, // true是带按钮的，false是仅提示
            errorText: '', // 仅提示时的文本
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n;
            this.currentPage = 1;
            this.loading = true;
            this.updatePage();
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'admin-manage-tabs-head-item-select'
            } else {
                return
            }
        },
        updatePage() { // 更新显示的数据
            let data = {
                status: this.whichtab,
                pagesize: this.pageSize,
                pagenum: this.currentPage
            }
            if (this.search.id !== '') {
                data["id"] = this.search.id;
            }
            if (this.search.originallandid !== '') {
                data["originallandid"] = this.search.originallandid;
            }
            if (this.search.areaid !== '') { // todo没有原始土地编号和小区编号的筛选
                data["communitynumber"] = this.search.areaid;
            }
            if (this.search.sellerid !== '') {
                data["seller"] = this.search.sellerid;
            }
            if (this.search.date !== '') {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }

            this.$http.post("/v1/administrator/select-salemanage-list", data)
                .then((response) => {
                    this.LandInfor = response.data.data.rows;
                    this.showLength = response.data.data.total;
                    this.loading = false;
                })
                .catch((error) => {
                    this.$toast.fail("加载失败,请重试或重新登录");
                });
        },
        resetUser() {
            this.search.id = '';
            this.search.originallandid = '';
            this.search.areaid = '';
            this.search.sellerid = '';
            this.search.date = '';
            this.currentPage = 1;
            this.loading = true;
            this.updatePage();
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val;
            // 更新显示的数据
            this.loading = true;
            this.updatePage();
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val;
            // 更新显示的数据
            this.loading = true;
            this.updatePage();
        },
        /* 全选当前页的多选框点击事件设置 */
        checkThisPageChange(val) {
            this.checkList = val ? this.LandInfor.map(item => item.unitlandid) : [];
            this.isIndeterminate = false;
        },
        handleCheckedListsChange(value) {
            let checkedCount = value.length;
            this.selectThisPage = checkedCount === this.LandInfor.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.LandInfor.length;
        },

        /* 强制摘牌弹窗及事件 */
        closeModal() {
            this.modal.vmodal = false;
            this.modal.bmodal = false;
        },
        showModal(val) {
            this.selectId = val;
            this.modal.bmodal = true;
        },
        unload() {
            if(this.banReason === '') {
                this.$toast("摘牌原因不可为空");
                return;
            }
            this.$http.put("/v1/administrator/select-salemanage-list", {
                id: this.selectId,
                reasondelisting: this.banReason
            }).then((response) => {
                this.$toast.success("摘牌成功,");
                this.modal.bmodal = false;
            })
            .catch((error) => {
                this.$toast.fail("摘牌失败,请重试或重新登录");
            });
        },
        showDetail(id) {
            localStorage.setItem("admin-sell", this.whichtab);
            this.$router.push({
                path: '/admin/sell-manage/detail?merch=' + id})
        },
    },
}
</script>

<style scoped>
.sellmanage-infor-row {
    display: flex;
    flex-direction: row;
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
.sellmanage-infor-checkbox {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    flex-direction: column;
    width: 8.33333%;
}


/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    padding: 50px;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-text {
    width: 300px; /* 设置文本容器的宽度 */
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    overflow-wrap: break-word; /* 标准的word-wrap属性的替代写法 */
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.modal-select {
    width: 200px;
    margin-top: 30px;
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>