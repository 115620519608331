<template>
    <div class="flex flex-col bg-gray-100 nmsger">
        <!-- 聊天页面的头部，包含菜单和清除聊天记录按钮 -->
        <!-- <div class="flex items-center justify-between p-2 bg-white shadow-md rounded-t">
            <div class="flex items-center space-x-2 flex-1">
                <h1 class="text-lg font-bold text-gray-700 flex-1 text-center">
                    客服
                </h1>
            </div>
        </div> -->

        <!-- 聊天记录区域 -->
        <div class="flex-1 p-4 overflow-y-auto relative" ref="messageList">
            <!-- 在这里循环渲染聊天记录 -->


            <!-- 对话框 -->
            <AppendMessage v-for="(message, index) in messages" :key="index" :name="message.isme? message.fromname : message.recipientname" :img="message.isme? myavatarUrl : message.userphoto"
                :side="message.isme? 'right' : 'left'" :text="message.emailscontent" :id="message.id" :time="message.createdate" :progress="message.progress" :isUrl="message.isUrl==='url'?true:false"
                :file="message.file" @forward="onForward(message.id)" />
        
        </div>

        <!-- 输入框和发送按钮 -->
        <form class="flex md:flex-row items-center p-2 bg-white shadow-md relative rounded-b justify-end">
            <div class="flex-none ml-2">
                <button @click.prevent="vmodal=true"
                    class="px-10 py-2 text-white bg-blue-500 rounded-md focus:outline-none focus:shadow-outline hover:bg-blue-600">
                    回复
                </button>
            </div>
        </form>

        <!-- 回复消息，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="admin-apply-modal" v-if="vmodal">
            <div class="admin-apply-modal-box">
                <div class="w-full">回复</div>
                <div class="relative mt-5" v-if="uploadMode">
                    <el-input type="textarea" :rows="4" placeholder="请输入回复信息" v-model="refundReason" style="width:300px;"></el-input>
                    <el-tooltip class="item" effect="dark" :content="tooltipText" placement="top-start">
                        <img src="../adminImage/admin-chat-image.png" class="admin-chat-modal-icon" @click="changeMode">
                    </el-tooltip>
                </div>
                <div class="flex flex-col p-3" v-else>
                    <div class="flex justify-center items-center shadow" style="width:300px;height:100px;" @mouseover="changeCursor" @click="handleClick">
                        <img :src="imageUrl" v-if="image" alt="图片已上传" class="max-h-full max-w-full">
                        <span class="text-6xl" v-else>+</span>
                        <input type="file" ref="image" @change="handleFileInputChange" hidden>
                    </div>
                    <div class="flex flex-row">
                        <el-tooltip class="item" effect="dark" :content="tooltipText" placement="top-start">
                            <img src="../adminImage/admin-chat-image.png" class="admin-chat-modal-icon-2" @click="changeMode">
                        </el-tooltip>
                        <span v-if="!uploadMode" style="color: red;font-size: 14px;">*请上传5MB以下的照片/截图</span>
                    </div>
                </div>
                <div class="w-full flex justify-end" style="margin-top: 20px;">
                    <el-button type="info" plain @click="closeModal" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="refuse" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>


        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import {mapState} from "vuex";
import AppendMessage from "../admin/AppendMessage.vue";
export default {
    computed: {
        ...mapState({
            myavatarUrl: (state) => state.admin.avatarUrl,
        }),
    },
    props: {
        avatarUrl: String,
        messages: Array,
        ThreadId: String,
    },
    components: {
        AppendMessage,
    },
    data() {
        return {
            example: [{
                id: 6175,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "你好，客服",
                createdate: "2023-08-23T16:00:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            },{
                id: 6176,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "我有问题想要问",
                createdate: "2023-08-23T16:01:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            }],
            dialogImageUrl: '', // 预览图片url
            dialogVisible: false, // 是否显示预览
            disabled: false,

            vmodal: false,
            text: '', // 文本，发送的消息
            image: null, // 图片，发送的消息
            imageUrl: null, // 图片url
            tooltipText: '上传图片', // 下方切换上传图片/文本提示语
            uploadMode: true, // true是上传文本，false是上传图片
        }
    },
    methods: {
        changeCursor(event) {
            event.target.style.cursor = "pointer";
        },
        changeMode() {
            if (this.uploadMode) {
                this.uploadMode = false;
                this.tooltipText = '上传文本'
            } else {
                this.uploadMode = true;
                this.tooltipText = '上传图片'
            }
        },
        handlePictureCardPreview(file) { // 图片预览
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        /* 上传图片功能样式 */
        handleClick() {
            // 触发文件选择器
            this.$refs.image.click();
        },
        handleFileInputChange() { // 获取用户选择的文件
            const size = 5 * 1024 * 1024; // 5MB
            const file = this.$refs.image.files[0];
            if (file.size < size) {
                this.image = this.$refs.image.files[0];
                // 将文件对象转换为 URL
                this.imageUrl = URL.createObjectURL(this.image);
            } else {
                this.$toast.fail('图片过大')
            }
        },
        // 发送文字函数
        async sendMessage() {
            if (this.ThreadId) {
                if (this.uploadMode && this.text !== '') {
                    this.$http.post('/v1/csmail/csreply', {
                        emailscontent: this.text,
                        serviceid: this.messages[0].serviceid
                    }).then(res => {
                        this.$parent.refreshMessage();
                        this.goBottom();
                        // 清空输入框中的内容
                        this.text = '';
                    }).catch(error => {
                        this.$toast.fail('发送失败,请重试或重新登录');
                    });
                } else if (!this.uploadMode && this.image) {
                    const formData = new FormData();
                    formData.append('picture', this.image);
                    formData.append('serviceid', this.messages[0].serviceid);
                    this.$http.post("/v1/csmail/csreply-picture", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((res) => {
                            this.$toast.success(this.$t('MobileServiceQ.alertSendSuccessfully'));
                            this.$parent.refreshMessage();
                        })
                        .catch((error) => {
                            this.$toast.fail('发送失败,请重试或重新登录')
                            console.log(error.data);
                        });
                }
            } else {
                this.$toast.fail('请选中主题消息再进行回复');
                this.text = '';
            }
        },
        goBottom() {
            // 滚动到最新消息
            this.$nextTick(() => {
                const messageList = this.$refs.messageList;
                messageList.scrollTop = messageList.scrollHeight;
            });
        },
        closeModal() {
            this.text = '';
            this.image = null;
            this.imageUrl = null;
            this.vmodal = false;
        },
    },
}
</script>
<style scoped>
.nmsger {
    height: 100%;
    width: 60%;
    margin: auto;
    background-color: #fff;
    border-radius: 12px;
}
.admin-chat-modal-icon {
    height: 28px;
    position: absolute;
    bottom: 3px;
    left: 5px;
    cursor: pointer;
}
.admin-chat-modal-icon-2 {
    height: 28px;
    cursor: pointer;
}
</style>