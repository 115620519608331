<template>
    <!-- container布局容器,包括头部和子页面区域 -->
    <div class="container-body">
        <div class="header-container">
            <img src="../icons/logo.png" alt="logo" class="header-logo-img" @click="goBack">
        </div>
        <div class="main-container">
            <!-- 路由占位符 -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>
.container-body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}
.header-container {
    height: 60px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding: 10px;
}
.header-logo-img {
    margin-left: 30px;
    height: 26px;
    cursor: pointer;
}
.main-container {
    flex: 1;
}
</style>