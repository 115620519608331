<template>
    <div class="common-modal" @click="$parent.closeIntlPhoneNumberModal">
        <div class="intl-box">
            <div class="intl-cells" v-for="(cell,index) in $intlNumber" :key="index" @click="getIntelPhoneNumber(cell.value)">{{ cell.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        getIntelPhoneNumber(val) {
            this.$parent.getIntelPhoneNumber(val);
        },
    },
}
</script>

<style>
.intl-box {
    width: 90%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #D8D8D8;
    background: #FFFFFF;
    overflow-y: auto;
    overflow-x: hidden;
}
.intl-cells {
    width: 100%;
    height: 36px;
    padding: 10px 8px;
    font-size: 16px;
    color: #3D3D3D;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #000000;
    white-space: nowrap;
}
</style>