<template>
  <div class="safe-full-container">
    <div class="safe-left-container">
      <div class="safe-left-options">
        <span
          class="safe-left-option"
          :class="index === 'infor' && !accountSafe ? 'safe-left-option-select' : ''"
          @click="changeIndex1('infor')"
          >{{ $t('AccountSafe.user') }}</span
        >
        <span
          class="safe-left-option"
          :class="index === 'address' && !accountSafe ? 'safe-left-option-select' : ''"
          @click="changeIndex1('address')"
          >{{ $t('PcAddress.title') }}</span
        >
        <span
          class="safe-left-option"
          :class="accountSafe || index === 'password' || index === 'account' || index === 'pay' ? 'safe-left-option-select' : ''"
          @click="accountSafe = !accountSafe"
          >{{ $t('MobileSafe.title') }}</span
        >
        <div class="w-full flex flex-col" v-if="accountSafe || index === 'password' || index === 'account' || index === 'pay'">
          <span
            class="safe-left-option2"
            :class="index === 'password' ? 'safe-left-option2-select' : ''"
            @click="changeIndex2('password')"
            >{{ $t('AccountSafe.password') }}</span
          >
          <span
            class="safe-left-option2"
            :class="index === 'account' ? 'safe-left-option2-select' : ''"
            @click="changeIndex2('account')"
            >{{ $t('AccountSafe.account') }}</span
          >
          <span
            class="safe-left-option2"
            :class="index === 'pay' ? 'safe-left-option2-select' : ''"
            @click="changeIndex2('pay')"
            >{{ $t('PcPay.title') }}</span
          >
        </div>
        <span
          class="safe-left-option"
          :class="index === 'service' && !accountSafe ? 'safe-left-option-select' : ''"
          @click="changeIndex1('service')"
          >{{ $t('AccountSafe.service') }}</span
        >
      </div>
    </div>
    <div class="safe-right-main">
      <MyInfor v-if="index === 'infor'"></MyInfor>
      <MyPassword v-else-if="index === 'password'"></MyPassword>
      <AssociatedAccount v-else-if="index === 'account'"></AssociatedAccount>
      <CustomerService v-else-if="index === 'service'"></CustomerService>
      <Address v-else-if="index === 'address'"></Address>
      <Pay v-else-if="index === 'pay'"></Pay>
    </div>
  </div>
</template>

<script>
import MyInfor from './MyInfor.vue'
import MyPassword from './AccountPassword.vue'
import AssociatedAccount from './AccountAsociated.vue'
import CustomerService from './ChatService.vue'
import Address from './AccountAddress.vue'
import Pay from './AccountPay.vue'
export default {
  components: {
    MyInfor,
    MyPassword,
    AssociatedAccount,
    CustomerService,
    Address,
    Pay
  },
  created() {
    if (localStorage.getItem('user-safe')) {
      if (localStorage.getItem('user-safe') === 'infor' || localStorage.getItem('user-safe') === 'address' || localStorage.getItem('user-safe') === 'service') {
        this.changeIndex1(localStorage.getItem('user-safe'));
      } else {
        this.changeIndex2(localStorage.getItem('user-safe'));
      }
    }
  },
  computed: {
    users() {
      return this.$store.state.developer.userInfo;
    },
  },
  data() {
    return {
      index: 'infor', // 菜单栏选中的模块
      accountSafe: false, // 是否展开账号安全的子菜单
    }
  },
  methods: {
    changeIndex1(t) {
      this.accountSafe = false;
      this.index = t;
      localStorage.setItem('user-safe',t);
    },
    changeIndex2(t) {
      this.accountSafe = true;
      this.index = t;
      localStorage.setItem('user-safe',t);
    }
  },
  beforeDestroy() {
    localStorage.removeItem('user-safe')
  }
}
</script>

<style scoped>
.safe-full-container {
  height: 100%;
  background-color: rgb(242, 248, 255);
  padding: 30px 100px 100px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.safe-left-container {
  height: 70%;
  border-radius: 30px;
  background-color: #ffffff;
  width: 200px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}
.safe-left-options {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
.safe-left-option {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #B4B4B4;
  cursor: pointer;
  text-align: center;
}
.safe-left-option2 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #B4B4B4;
  cursor: pointer;
  text-align: center;
}
.safe-left-option-select {
  border-left: 3px solid #0256FF;
  color: #0256FF;
}
.safe-left-option2-select {
  color: #0256FF;
}
.safe-right-main {
  margin-left: 30px;
  height: 100%;
  width: 1000px;
  overflow: hidden;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .safe-full-container {
      padding: 30px;
    }
    .safe-left-container {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .safe-left-container > div {
        transform: scale(0.8);
        transform-origin: top left; /* 确保从中心缩放 */
    }
}
</style>
