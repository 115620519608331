<template>
    <div class="flex flex-col h-full">
        <!-- 左上角返回 -->
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{ topicTitle }}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <!-- 设置邮箱账号 -->
        <div class="mobile-associated-box-container" v-if="setType === 'email'">
            <div class="mobile-associated-box-input-parent">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedSet.email')}}:</span></div>
                <input type="email" v-model="email" class="mobile-associated-box-input" :placeholder="$t('MobileAssociatedSet.emailInput')" />
            </div>
            <div class="items-center justify-end mobile-associated-box-input-parent">
                <!--图像验证码-->
                <div class="w-full flex flex-col">
                    <div class="w-full flex flex-row">
                        <span class="mobile-associated-box-input-labal">{{$t('mobileLogin.graphicCode')}}:</span>
                        <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="mobile-associated-box-input" style="width: 60%" />
                    </div>
                    <div class="w-full flex flex-row justify-center items-end mt-5">
                        <img @click="loadCodeImg" style="width:120px;" :src="imgCodeUrl" alt="验证码"/>
                        <span class="mobile-associated-box-imgcode-change" @click="loadCodeImg">{{$t('MobileAssociatedReplace.change')}}</span>
                    </div>
                </div>
            </div>
            <div class="mobile-associated-box-input-parent">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedSet.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedSet.codeInput')" class="mobile-associated-box-input" />
                <span @click="getEmailCode" v-if="isCode" class="mobile-associated-box-code-button">{{$t('MobileAssociatedSet.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-associated-box-code-button">{{$t('MobileAssociatedSet.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 90%; margin-bottom: 10px">
                <button class="mobile-associated-box-button" @click="AddEmailAssociated">{{$t('MobileAssociatedSet.confirm')}}</button>
            </div>
        </div>
        <!-- 设置手机号码 -->
        <div class="mobile-associated-box-container" v-else-if="setType === 'phone'">
            <div class="mobile-associated-box-input-parent">
                <span class="mobile-associated-box-input-labal">{{$t('MobileAssociatedSet.phone')}}:</span>
                <input type="tel" v-model="phone" class="mobile-associated-box-input" :placeholder="$t('MobileAssociatedSet.phoneInput')" />
            </div>
            <div class="items-center justify-end mobile-associated-box-input-parent">
                <!--图像验证码-->
                <div class="w-full flex flex-col">
                    <div class="w-full flex flex-row">
                        <span class="mobile-associated-box-input-labal">{{$t('mobileLogin.graphicCode')}}:</span>
                        <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="mobile-associated-box-input" style="width: 60%" />
                    </div>
                    <div class="w-full flex flex-row justify-center items-end mt-5">
                        <img @click="loadCodeImg" style="width:120px;" :src="imgCodeUrl" alt="验证码"/>
                        <span class="mobile-associated-box-imgcode-change" @click="loadCodeImg">{{$t('MobileAssociatedReplace.change')}}</span>
                    </div>
                </div>
            </div>
            <div class="mobile-associated-box-input-parent" v-show="imgCode.length === 4">
                <div class="mobile-associated-box-input-labal"><span>{{$t('MobileAssociatedSet.verify')}}:</span></div>
                <input type="text" v-model="code" placeholder="请输入验证码" class="mobile-associated-box-input" />
                <span @click="getPhoneCode" v-if="isCode" class="mobile-associated-box-code-button">{{$t('MobileAssociatedSet.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-associated-box-code-button">{{$t('MobileAssociatedSet.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 90%; margin-bottom: 10px">
                <button class="mobile-associated-box-button" @click="AddPhoneAssociated">{{$t('MobileAssociatedSet.confirm')}}</button>
            </div>
        </div>
        <!-- 应该不会出现的情景 -->
        <div class="mobile-associated-box-container" v-else>
            <div class="mobile-associated-box-topic">404 NOT FOUND</div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.setType = this.$route.query.type;
        if (this.setType === "email") {
            this.topicTitle = this.$t('MobileAssociatedSet.bundEmail');
        } else if (this.setType === "phone") {
            this.topicTitle = this.$t('MobileAssociatedSet.bundPhone');
        }
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            topicTitle: "添加关联", // 标题名称,根据是邮箱还是手机号修改名称
            setType: "", // 本次添加关联的类型，分为邮箱email和手机号phone
            email: "", // 邮箱
            phone: "", // 手机号
            code: "", // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            imgCode: "", // 手机端图像验证码
            imgCodeUrl: "", // 手机端图像验证码的url
            timer: null, // 计时器
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        checkPassword() {
            // 点击密码输入框后检查密码格式是否错误
            const re = /^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        /*加载图像验证码*/
        loadCodeImg(){
            ///register/captcha
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },
        setMyInterval() {
            // 设置计时器
            this.time = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false;
        },

        getEmailCode() { // 获取邮箱验证码
            if (!this.email.includes("@")) {
                this.$toast(this.$t('MobileAssociatedSet.alertEmailError'));
                return;
            } else if (this.imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return;
            }
            this.setMyInterval();
            this.$toast(this.$t('MobileAssociatedSet.alertLoading'));
            this.$http
                .post("/v1/index/get-verification-code?useremail=" + this.email + '&code=' + this.imgCode)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountEmail.alertCodeSendSuccessfully'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('MobileAssociatedReplace.alertCodeError'));
                    }
                })
                .catch((err) => {
                    this.$toast.fail(this.$t('MobileAssociatedSet.alertAxiosError'));
                });
        },
        AddEmailAssociated() { // 通过邮箱和验证码绑定邮箱账号
            if (!this.email.includes("@")) {
                this.$toast(this.$t('MobileAssociatedSet.alertEmailError'));
                return;
            }
            if (this.code === "") {
                this.$toast(this.$t('MobileAssociatedSet.alertNoCodeInput'));
                return;
            }
            this.$toast(this.$t('MobileAssociatedSet.alertLoading'));
            this.$http
                .post("/v1/register/association?contact=" + this.email + "&code=" + this.code)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountEmail.alertChangeSuccessfully'));
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                })
                .catch((err) => {
                    this.$toast.fail(this.$t('MobileAssociatedSet.alertAxiosError'));
                });
        },



        getPhoneCode() { // 获取手机验证码
            const re = /^[0-9]*$/;
            if (this.phone.length === 0 || !re.test(this.phone)) {
                this.$toast(this.$t('MobileAssociatedSet.alertPhoneError'));
                return;
            } else if (this.imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return
            }
            this.setMyInterval();
            this.$toast(this.$t('MobileAssociatedSet.alertLoading'));
            this.$http
                .post("/v1/register/phone-verificationcode?userphone=" + this.phone+"&code="+this.imgCode)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountPhone.alertChangeSuccessfully'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('AccountEmail.alertChangeSuccessfully'));
                    }
                })
                .catch((err) => {
                    this.$toast.fail(this.$t('MobileAssociatedSet.alertSendFail'));
                });
        },
        AddPhoneAssociated() {
            const re = /^[0-9]*$/;
            if (this.phone.length === 0 || !re.test(this.phone)) {
                this.$toast(this.$t('MobileAssociatedSet.alertPhoneError'));
                return;
            }
            if (this.code === "") {
                this.$toast(this.$t('MobileAssociatedSet.alertNoCodeInput'));
                return;
            }
            this.$toast(this.$t('MobileAssociatedSet.alertLoading'));
            this.$http
                .post("/v1/register/association?contact=" + this.phone + "&code=" + this.code)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('AccountPhone.alertChangeSuccessfully'));
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                })
                .catch((err) => {
                    this.$toast.fail(this.$t('MobileAssociatedSet.alertAxiosError'));
                });
        },
    },
};
</script>

<style>
</style>
