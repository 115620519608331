<template>
    <div class="developer-common-container">
        <!-- 左上角返回，返回主页 -->
        <div class="developer-goback-parent" @click="goBack">
            <div class="developer-goback-icon">
                <img src="../developer/developer-goback.png">
            </div>
            <span>{{$t('OriginalLandDetail.l1')}}</span>
        </div>
        <div class="developer-common-card-box originalland-detail-box1">
            <img :src="originalLand.landpicture" class="developer-detail-originalland-picture">
            <div class="originalland-detail-box1-text">
                <div class="developer-common-card-title">{{ originalLand.originallandname }}({{$t('OriginalLandDetail.l2')}})</div>
                <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l3')}}:</div>{{ originalLand.originallandid }}</div>
                <div class="originalland-detail-text">
                    <div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l4')}}:</div>
                    <div class="land-review-title-statu-yishangxian" v-if="originalLand.landstatus === '已上线'">{{ originalLand.landstatus }}</div>
                    <div class="land-review-title-statu-yixiaxian" v-else-if="originalLand.landstatus === '已下线'">{{ originalLand.landstatus }}</div>
                </div>
                <span class="original-detail-income" @click="gotoIncome">{{$t('OriginalLandDetail.l7')}} ></span>
                <div class="flex flex-row mt-10 items-center" v-show="originalLand.landstatus === '已上线'">
                    <button class="original-detail-button1" @click="showModal">{{$t('OriginalLandDetail.l6')}}</button>
                    <button class="original-detail-button2" style="margin-left:30px;" @click="gotoland">{{$t('OriginalLandDetail.l5')}}</button>
                </div>
                <button class="developer-detail-buy-button mt-10" @click="reloadland" @mouseover="changeCursor" v-show="originalLand.landstatus === '已下线'">{{$t('OriginalLandDetail.l8')}}</button>
            </div>
        </div>
        <div class="developer-common-card-box" style="margin-top:30px;padding-top:40px;padding-bottom:60px;">
            <div class="developer-common-card-title-parent">
                <div class="developer-common-card-title">{{$t('OriginalLandDetail.l1')}}</div>
                <span class="original-detail-checkland" @click="checkland">{{$t('OriginalLandDetail.l9')}} ></span>
            </div>
            <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l10')}}:</div>{{ originalLand.detailedaddress }}</div>
            <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l11')}}:</div>({{ originalLand.geographicalcoordinates }})</div>
            <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l12')}}:</div>{{ entranceLanguage(originalLand.entrance) }}</div>
            <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l13')}}:</div>{{ originalLand.height }}{{$t('LandReview.unit')}}</div>
            <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l14')}}:</div>{{ originalLand.landlength }}{{$t('LandReview.unit')}}</div>
            <div class="originalland-detail-text"><div class="originalland-detail-text-label">{{$t('OriginalLandDetail.l15')}}:</div>{{ originalLand.landwidth }}{{$t('LandReview.unit')}}</div>
        </div>
        <!-- 下线弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-if="vmodal">
            <div class="developer-common-modal-box" v-if="showError">
                <span>{{ errorText }}</span>
                <div class="developer-common-buttons">
                    <el-button type="primary" class="developer-common-button" @click="closeModal">{{$t('OriginalLandDetail.l16')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('OriginalLandDetail.l17')}}</span>
                <div class="developer-common-buttons">
                    <el-button type="info" plain class="developer-common-button mr-10" @click="closeModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                    <el-button type="primary" class="developer-common-button" @click="downloadland">{{$t('OriginalLandDetail.l16')}}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.originallandid = this.$route.query.id;
    },
    mounted() {
        // 获取土地详情信息
        this.$http.get("/v1/original-land/select-one-originalland?originallandid=" + this.originallandid)
            .then((res) => {
                this.originalLand = res.data.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    data() {
        return {
            originallandid: null,
            originalLand: {
                ownershipnumber: '', // 土地产权编号
                originallandname: '', // 土地名称
                createdate: '', // 创建时间
                detailedaddress: '', // 详细地址
                geographicalcoordinates: '', // 地理坐标
                entrance: '东', // 土地入口方位
                landtype: '不限', // 土地类型
                landlength: '', // 土地长度
                landwidth: '', // 土地宽度
                height: '', // 海拔
                landcertificate: '', // 土地无贷款证明
                warrantydeed: '', // 担保地契
                landpicture: '', // 土地照片
                landstatus: '', // 土地状态
            },


            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            errorText: '',
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1)
        },
        showModal() {
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        gotoland() {
            this.$router.push('/land-operate/big-map?land=' + this.originallandid);
        },
        reloadland() { // 重新上传土地
            this.$router.push({
                path: '/developer/land-detail/real-estate',
                name: 'developerLandView',
                query: {
                    land: this.originallandid
                },
                params: {
                    index: 0
                }
            })
        },
        checkland() { // 查看上传土地凭证
            this.$router.push({
                path: '/developer/land-detail/real-estate',
                name: 'developerLandView',
                query: {
                    land: this.originallandid
                },
                params: {
                    index: 4
                }
            })
        },
        gotoIncome() { // 收益分析
            this.$router.push('/developer/land-detail/income?land=' + this.originallandid)
        },
        /* 以下是下线原始土地的功能 */
        downloadland() {
            const toastLoading = this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('OriginalLandDetail.l19'),
                forbidClick: true,
            });
            this.$http.get("/v1/original-land/offline?originallandid=" + this.originallandid)
                .then((res) => {
                    if (res.data.status === 200) {
                        toastLoading.clear();
                        this.$toast.success(this.$t('OriginalLandDetail.l20'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.errorText = res.data.data;
                        this.vmodal = true;
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('OrderView.l16'));
                    this.vmodal = false;
                });
        },
        showModal() { // 弹窗提示是否下线，给用户一个二次选择的机会
            this.showError = false;
            this.vmodal = true;
        },
        entranceLanguage(data) { // 土地方位根据中英文不同需要设置不同的显示文本
            if (this.$i18n.locale === 'en') {
                if (data === '东') { return 'east' } else if (data === '西') { return 'west' } else if (data === '南') { return 'south' } else if (data === '北') { return 'north' }
            } else { return data }
        },
    },
}
</script>

<style scoped>
/* 第一栏卡片的样式，主要是有背景图片 */
.originalland-detail-box1 {
    margin-top: 30px;
    min-height: 345px;
    position: relative;
    background-image: url('../developer/original-detail.png');
    background-size: cover;
}
/* 第一栏卡片中左侧图片的样式 */
.developer-detail-originalland-picture {
    height: 285px;
    width: 285px;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
}
/* 第一栏卡片中右侧文本、按钮栏的样式，主要是位置 */
.originalland-detail-box1-text {
    position: absolute;
    top: 30px;
    left: 345px;
    display: flex;
    flex-direction: column;
}
.originalland-detail-text {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}
.originalland-detail-text-label {
    font-size: 16px;
    color: #4E5969;
    width: 200px;
    margin-right: 20px;
}
.developer-detail-box-label {
    font-weight: bold;
    width: 200px;
    display: inline-block;
}
.original-detail-income {
    font-size: 16px;
    color: #3662EC;
    margin-top: 40px;
    cursor: pointer;
}
.original-detail-button1 {
    height: 36px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    border-radius: 8px;
    background-color: #165DFF;
}
.original-detail-button2 {
    height: 36px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 8px;
    background-color: #FFF;
    color: #3662EC;
    border: 1px solid #3662EC;
}
.original-detail-checkland {
    margin-left: 30px;
    font-size: 16px;
    color: #3662EC;
    cursor: pointer;
}


/* 已上线标签 */
.land-review-title-statu-yishangxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 12px;
    background-color: rgba(0, 181, 120, 0.1);
    color: #00B578;
}
/* 已下线标签 */
.land-review-title-statu-yixiaxian {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 12px;
    background-color: rgba(54, 98, 236, 0.1);
    color: #3662EC;
}
</style>