<template>
  <div class="background" @click="handleClickOutside">
    <div class="box" @click="handleClickInside">
      <div class="left">
        <el-carousel height="623px">
          <el-carousel-item v-for="item in images" :key="item">
            <div class="small">
              <img :src="item" class="left-img" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 登录窗口 -->
      <div class="right">
        <!-- 关闭窗口的小图标 -->
        <img src="../pImage/close.png" class="close" @click="$parent.closeLoginModal()">
        <div class="title">
          <span>{{ getTitleText() }}</span>
          <img src="../icons/logo.png" class="title-logo" v-show="tab==='login'">
        </div>
        <div class="title-subhead" v-if="tab === 'login'">
          <span class="title-subhead-gray">{{$t('UserLoginModal.subhead1')}}</span>
          <span class="title-subhead-blue" @click.stop="changetab('register')">{{$t('UserLoginModal.subhead2')}}</span>
          <img src="../pImage/login-subhead.png" class="title-subhead-icon" @click.stop="changetab('register')">
        </div>
        <div class="title-subhead" v-else>
          <span class="title-subhead-blue" @click.stop="changetab('login')">{{$t('UserLoginModal.subhead3')}}</span>
          <img src="../pImage/login-subhead.png" class="title-subhead-icon" @click.stop="changetab('login')">
        </div>
        <div class="login-box">
          <!-- 登录模式选择框 -->
          <div class="tabs">
            <div class="tab" :class="mode?'tab-select':''" @click="changemode(true)">{{ LanguageText('phone') }}</div>
            <div class="tab" :class="mode?'':'tab-select'" @click="changemode(false)">{{ LanguageText('email') }}</div>
          </div>
          <!-- 第一栏-邮箱、账号、手机号 -->
          <div class="flex flex-row items-center" style="margin-top: 24px;">
            <!-- 账号 -->
            <div class="input-parent" v-if="tab==='login'&&!mode">
              <img src="../pImage/account.png" class="input-icon" />
              <input class="input" v-model="account" :placeholder="$t('UserLoginModal.enter1')" />
            </div>
            <!-- 邮箱 -->
            <div class="input-parent" v-else-if="tab!=='login'&&!mode">
              <img src="../pImage/email.png" class="input-icon" />
              <input class="input" v-model="email" :placeholder="$t('UserLoginModal.enter2')" @focus="loadCodeImg" @input="checkFormat('email')" />
            </div>
            <!-- 手机号 -->
            <div class="input-parent" v-else-if="mode">
              <img src="../pImage/phone.png" class="input-icon" />
              <input class="input" v-model="phone" :placeholder="$t('UserLoginModal.enter3')" @focus="loadCodeImg" @input="checkFormat('phone')" />
            </div>
            <!-- 判断格式正确错误的图标 -->
            <div v-if="email!==''||phone!==''">
              <img src="../pImage/check-error.png" class="check-icon" v-if="accountError">
              <img src="../pImage/check-right.png" class="check-icon" v-else>
            </div>
          </div>
          <!-- 第二栏-图像验证码 -->
          <div class="flex flex-row" style="margin-top: 24px;" v-show="imgCodeUrl">
            <div class="input2-parent">
              <img src="../pImage/code.png" class="input-icon" />
              <input class="input" style="width:156px;" v-model="imgCode" :placeholder="$t('UserLoginModal.imgcode')" />
            </div>
            <img :src="imgCodeUrl" class="img-code" @click="loadCodeImg">
          </div>
          <!-- 第三栏-获取并输入手机、邮箱验证码 -->
          <div class="flex flex-row" style="margin-top: 24px;" :style="(tab==='login'&&!mode)?'display:none;':''">
            <div class="input2-parent">
              <img src="../pImage/code.png" class="input-icon" />
              <input class="input" style="width:156px;" v-model="code" :placeholder="$t('UserLoginModal.code')" />
            </div>
            <div class="get-code" @click="getCode">
              <span v-if="isCode">{{$t('UserLoginModal.getcode')}}</span>
              <span v-else>{{$t('UserLoginModal.resendcode')}}{{ timeCount }}s</span>
            </div>
          </div>
          <!-- 第四栏-密码以及验证密码格式文本 -->
          <div class="flex flex-col" style="margin-top: 24px;" :style="(tab!=='forget'&&mode)?'display:none;':''">
            <div class="input-parent">
              <img src="../pImage/password-icon.png" class="input-icon" />
              <input class="input" @input="checkFormat('password')" v-model="password" :placeholder="$t('UserLoginModal.password')" :type="showPassword ? '' : 'password'"/>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('forgetPassword.showOrHidePassword')"
                placement="top-end"
              >
                <img src="../images/visible.png" class="password-show-icon" @click="togglePassword()" v-if="showPassword" />
                <img src="../images/not-visible.png" class="password-show-icon" @click="togglePassword()" v-else />
              </el-tooltip>
            </div>
            <!-- 第一行 -->
            <div class="flex flex-row items-center mt-3" v-show="tab!=='login'">
              <img src="../pImage/check-error.png" class="check-icon" v-if="showRules1" />
              <img src="../pImage/check-right.png" class="check-icon" v-else />
              <span class="password-tips" :style="showRules1 ? '' : 'color: rgba(0, 0, 0, 0.4);'" >{{ $t('AccountPassword.tip1') }}</span>
            </div>
            <!-- 第二行 -->
            <div class="flex flex-row items-center" v-show="tab!=='login'">
              <img src="../pImage/check-error.png" class="check-icon" v-if="showRules2" />
              <img src="../pImage/check-right.png" class="check-icon" v-else />
              <span class="password-tips" :style="showRules2 ? '' : 'color: rgba(0, 0, 0, 0.4);'" >{{ $t('AccountPassword.tip2') }}</span>
            </div>
            <!-- 第三行 -->
            <div class="flex flex-row items-center" v-show="tab!=='login'">
              <img src="../pImage/check-error.png" class="check-icon" v-if="showRules3" />
              <img src="../pImage/check-right.png" class="check-icon" v-else />
              <span class="password-tips" :style="showRules3 ? '' : 'color: rgba(0, 0, 0, 0.4);'" >{{ $t('AccountPassword.tip3') }}</span>
            </div>
          </div>
          <!-- 第五栏-忘记密码 -->
          <div class="forget-password" v-if="tab==='login'">
            <span class="cursor-pointer" @click.stop="changetab('forget')">{{$t('UserLoginModal.forgetpassword')}}</span>
          </div>
        </div>
        <!-- 登录/确定按钮 -->
        <div class="login-button-parent">
          <button class="login-button" @click="getButtonEvent">{{ getButtonText() }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (localStorage.getItem("loginMethod") === 'account') {
      this.mode = false;
    }
  },
  mounted() {
    this.updateModalScale();
    // 监听窗口大小变化以更新弹窗缩放
    window.addEventListener('resize', this.updateModalScale);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateModalScale);
  },
  data() {
    return {
      images: [
        require('@/pImage/loginP1.png'),
        require('@/pImage/loginP2.png'),
      ],
      tab: 'login', // 三种模式，登录'login'、注册'register'、忘记（找回）密码'forget'
      mode: true, // 无论是登录、注册、忘记密码都是两种方式，邮箱（密码）和手机号，true为手机号，false为密码（邮箱）
      account: '', // 三合一密码登录模式的账号
      email: '', // 邮箱模式的邮箱
      phone: '', // 手机号模式的手机号
      imgCodeUrl: null, // 图像验证码图片url
      imgCode: '', // 图像验证码
      code: '', // 从手机、邮箱获取的验证码
      password: '', // 密码
      showPassword: false, // 是否显示密码，fasle是隐藏，true是显示
      accountError: false, // 判断邮箱、手机号格式是否正确，true为错误，false为正确
      showRules1: true, // 检查密码格式是否正确-仅在注册和忘记密码中显示
      showRules2: true,
      showRules3: true,
      isCode: true, // 是否可获取验证码
      timer: null, // 计时器
      timeCount: 0, // 倒计时
    }
  },
  methods: {
    // 根据屏幕大小自适应弹窗大小
    updateModalScale() {
        const modal = document.querySelector('.box');
        if (window.innerWidth >= 946 && window.innerHeight >= 663) {
          modal.style.transform = `scale(1)`;
        } else {
          const scaleX = (window.innerWidth - 50) / 946;
          const scaleY = (window.innerHeight - 50) / 663;
          const scale = Math.min(scaleX, scaleY);
          modal.style.transform = `scale(${scale})`;
        }
    },
    // 点击弹窗外围背景关闭弹窗
    handleClickOutside(event) {
      if (!event.target.closest('.box')) {
        // 点击弹窗外部，关闭弹窗
        this.$parent.closeLoginModal();
      }
    },
    handleClickInside() {
      // 点击弹窗内部，不做任何操作
    },
    // 大标题文本
    getTitleText() {
      if (this.tab === 'login') {
        return this.$t('UserLoginModal.title1');
      } else if (this.tab === 'register') {
        return this.$t('UserLoginModal.title2');
      } else if (this.tab === 'forget') {
        return this.$t('UserLoginModal.title3');
      }
    },
    // 更改模式，更改操作模式为登录'login'、注册'register'还是忘记（找回）密码'forget'
    changetab(value) {
      this.account = '';
      this.email = '';
      this.phone = '';
      this.imgCodeUrl = null;
      this.imgCode = '';
      this.code = '';
      this.password = '';
      this.accountError = false;
      this.showRules1 = true;
      this.showRules2 = true;
      this.showRules3 = true;
      this.isCode = true;
      if(this.timer){clearInterval(this.timer);}
      this.timeCount = 0;
      this.tab = value;
    },
    // 更改模式，根据传入的boolean值确定更改为密码（邮箱）模式还是手机号模式
    changemode(boolean) {
      this.account = '';
      this.email = '';
      this.phone = '';
      this.imgCodeUrl = null;
      this.imgCode = '';
      this.code = '';
      this.password = '';
      this.accountError = false;
      this.showRules1 = true;
      this.showRules2 = true;
      this.showRules3 = true;
      this.isCode = true;
      if(this.timer){clearInterval(this.timer);}
      this.timeCount = 0;
      this.mode = boolean;
    },
    // 不同操作模式选择模式的文本是不同的，这里是手机号的文本
    LanguageText(value) {
      if (value === 'phone') {
        if (this.tab === 'login') {
          return this.$t('UserLoginModal.phonemode1')
        } else if (this.tab === 'register') {
          return this.$t('UserLoginModal.phonemode2')
        } else if (this.tab === 'forget') {
          return this.$t('UserLoginModal.phonemode3')
        }
      } else if (value === 'email') {
        if (this.tab === 'login') {
          return this.$t('UserLoginModal.emailmode1')
        } else if (this.tab === 'register') {
          return this.$t('UserLoginModal.emailmode2')
        } else if (this.tab === 'forget') {
          return this.$t('UserLoginModal.emailmode3')
        }
      }
    },
    // 显示/隐藏密码
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    // 检查邮箱、手机号、密码格式是否正确
    checkFormat(value) {
      if (value === 'email') {
        this.accountError = !this.email.includes('@');
      } else if (value === 'phone') {
        const re = /^[0-9]+$/;
        this.accountError = !re.test(this.phone);
      } else if (value === 'password') {
        const re1 = /^.{8,16}$/
        const re21 = /^(?=.*[A-Za-z])(?=.*\d).{2,}$/ // 包含字母和数字
        const re22 = /^(?=.*[A-Za-z])(?=.*[-+_!@#$%^&*.,?]).{2,}$/ // 包含字母和符号
        const re23 = /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/ // 包含数字和符号
        const re24 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{2,}$/ // 包含数字、字母和符号
        const re3 = /\s/ // 包含空格
        this.showRules1 = !re1.test(this.password);
        if (re21.test(this.password) || re22.test(this.password) || re23.test(this.password) || re24.test(this.password)) {
          this.showRules2 = false;
        } else {
          this.showRules2 = true;
        }
        this.showRules3 = re3.test(this.password);
      }
    },
    // 加载图像验证码
    loadCodeImg() {
      this.$http
        .get('/v1/register/captcha', {
          responseType: 'blob', // 设置响应类型为blob
        })
        .then((response) => {
          // 处理返回值
          const imageBlob = response.data
          this.imgCodeUrl = URL.createObjectURL(imageBlob)
        })
    },
    // 底部按钮的文本
    getButtonText() {
      if (this.tab === 'login') {
        return this.$t('UserLoginModal.confirmbutton1');
      } else if (this.tab === 'register') {
        return this.$t('UserLoginModal.confirmbutton2');
      } else if (this.tab === 'forget') {
        return this.$t('UserLoginModal.confirmbutton3');
      }
    },
    // 底部按钮的事件
    getButtonEvent() {
      if (this.tab === 'login') {
        if (this.mode) {
          this.mobilePhoneLogin();
        } else {
          this.mobileLogin();
        }
      } else if (this.tab === 'register') {
        if (this.mode) {
          this.PhoneRegister();
        } else {
          this.EmailRegister();
        }
      } else if (this.tab === 'forget') {
        if (this.mode) {
          this.PhoneForgetPassword();
        } else {
          this.EmailForgetPassword();
        }
      }
    },
    // 密码登录
    mobileLogin() {
      if (this.account.length === 0) {
        this.$toast(this.$t('mobileLogin.alertNoAccountInput'))
        return
      }
      const data = {
        loginname: this.account,
        loginpassword: this.password,
      }
      this.$http
        .post('/v1/index/login', data, {
          headers: {
            'Access-Control-Allow-Origin': 'http://test.datuland.com/',
            'Access-Control-Allow-Credentials': 'true',
          },
        })
        .then((response) => {
          // 处理返回值
          if (response.data.status === 200) {
            localStorage.setItem('loginMethod', 'account') // 存储登录方式到localStorage,key值为loginMethod
            this.$store.dispatch('user/fetchUserInfo');
            this.$parent.loginSuccess();
          } else {
            this.$toast.fail(this.$t('AccountEmail.alertBindFail'))
          }
        })
        .catch(() => {
          // 处理错误
          this.$toast.fail(this.$t('mobileLogin.alertAxiosError'))
        })
    },
    // 根据模式不同判断给邮箱发验证码还是手机
    getCode() {
      if (!this.isCode) {
        return;
      } else if (this.mode) {
        this.getPhoneLoginCode()
      } else if (!this.mode) {
        this.getEmailLoginCode()
      }
    },
    // 获取验证码后计时重新发送
    startCountdown() {
      // 设置倒计时60s
      this.timeCount = 60
      this.isCode = false
      // 计时器
      this.timer = setInterval(() => {
        if (this.timeCount > 0) {
          this.timeCount--
        } else {
          this.isCode = true
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    // 手机号验证码登陆页面点击获取验证码
    getPhoneLoginCode() {
      // 手机验证码登陆点击获取验证码
      if (this.phone.length === 0) { // 检测手机号是否为空
        this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'))
        return
      } else if (this.imgCode === '') { // 检测图像验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoGraphicCode'))
        return
      } else if (this.accountError) { // 检测手机号格式是否错误
        this.$toast(this.$t('computerlogin.alertPhoneError'))
        return
      }
      const loadingToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('mobileLogin.alertLoading'),
      })
      this.$http.post('/v1/register/verificationcode?loginname=' + this.phone + '&code=' + this.imgCode)
        .then(res => {
          loadingToast.clear();
          this.startCountdown();
          if (res.data.status === 200) {
            this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
          } else if (res.data.status === 201) {
            this.$toast.fail(this.$t('all.图形验证码错误'));
            this.imgCode = '';
            this.loadCodeImg();
          } else if (res.data.status === 202) {
            this.$toast.fail(this.$t('all.codeLimit'));
          } else {
            this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
            this.imgCode = '';
            this.loadCodeImg();
          }
      }).catch(err => {
        loadingToast.clear();
        this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
      })
    },
    // 邮箱注册、邮箱忘记密码页面点击获取验证码
    getEmailLoginCode() {
      // 邮箱模式点击获取验证码
      if (this.email.length === 0) { // 检测邮箱是否为空
        this.$toast(this.$t('computerlogin.emailRegistrationEmailInput'))
        return
      } else if (this.imgCode === '') { // 检测图像验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoGraphicCode'))
        return
      } else if (this.accountError) { // 检测邮箱格式是否错误
        this.$toast(this.$t('MobileAssociatedReplace.alertEmailError'))
        return
      }
      const loadingToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('mobileLogin.alertLoading'),
      })
      this.$http.post('/v1/register/verificationcode?loginname=' + this.email + '&code=' + this.imgCode)
        .then(res => {
          loadingToast.clear();
          this.startCountdown();
          if (res.data.status === 200) {
            this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
          } else if (res.data.status === 201) {
            this.$toast.fail(this.$t('all.图形验证码错误'));
            this.imgCode = '';
            this.loadCodeImg();
          } else if (res.data.status === 202) {
            this.$toast.fail(this.$t('all.codeLimit'));
          } else if (res.data.status === 201) {
            this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
            this.imgCode = '';
            this.loadCodeImg();
          }
      }).catch(err => {
        loadingToast.clear();
        this.$toast.fail(this.$t('mobileForgetPassword.alertAxiosError'));
      })
    },
    // 手机号验证码登陆
    mobilePhoneLogin() {
      if (this.phone.length === 0) { // 检测手机号是否为空
        this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'))
        return
      } else if (this.code === '') { // 检测验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoCode'))
        return
      } else if (this.accountError) { // 检测手机号格式是否错误
        this.$toast(this.$t('computerlogin.alertPhoneError'))
        return
      }
      const data = {
        userphone: this.phone,
        remark: this.code,
      }
      this.$http
        .post('/v1/index/loginyzm', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          // 处理返回值
          if (response.data.status === 200) {
            localStorage.setItem('loginMethod', 'phone') // 存储登录方式到localStorage,key值为loginMethod
            this.$store.dispatch('user/fetchUserInfo');
            this.$parent.loginSuccess();
          } else {
            this.$toast.fail(this.$t('all.loginFail'))
          }
        })
        .catch(() => {
          // 处理错误
          this.$toast(this.$t('mobileLogin.alertAxiosError'))
        })
    },
    // 手机号注册
    PhoneRegister() {
      if (this.phone.length === 0) { // 检测手机号是否为空
        this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'))
        return
      } else if (this.code === '') { // 检测验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoCode'))
        return
      } else if (this.accountError) { // 检测手机号格式是否错误
        this.$toast(this.$t('computerlogin.alertPhoneError'))
        return
      }
      const data = {
        userphone: this.phone,
        remark: this.code,
      }
      const loadingToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('mobileLogin.alertLoading'),
      })
      this.$http.post('/v1/register/userphone-register', data)
        .then((response) => {
          // 处理返回值
          loadingToast.clear()
          if (response.data.status === 200) {
            this.$store.dispatch('user/fetchUserInfo')
            this.$parent.closeLoginModal()
          } else {
            this.$toast.fail(this.$t('all.registeFail'));
          }
        })
        .catch(() => {
          // 处理错误
          this.$toast(this.$t('mobileLogin.alertAxiosError'))
        })
    },
    // 邮箱注册
    EmailRegister() {
      if (this.email.length === 0) { // 检测邮箱是否为空
        this.$toast(this.$t('computerlogin.emailRegistrationEmailInput'))
        return
      } else if (this.code === '') { // 检测验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoCode'))
        return
      } else if (this.accountError) { // 检测邮箱格式是否错误
        this.$toast(this.$t('MobileAssociatedReplace.alertEmailError'))
        return
      } else if (this.showRules1 || this.showRules2 || this.showRules3) { // 检测密码格式是否错误
        this.$toast(this.$t('AccountPassword.alertErrorPassword'))
        return
      }
      const data = {
        useremail: this.email,
        userpassword: this.password,
        remark: this.code,
      }
      const loadingToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('mobileLogin.alertLoading'),
      })
      this.$http.post('/v1/register/email-register', data)
        .then((response) => {
          // 处理返回值
          loadingToast.clear()
          if (response.data.status === 200) {
            localStorage.setItem('loginMethod', 'account') // 存储登录方式到localStorage,key值为loginMethod
            this.$store.dispatch('user/fetchUserInfo')
            this.$parent.closeLoginModal()
          } else {
            this.$toast.fail(this.$t('all.registeFail'));
          }
        })
        .catch(() => {
          // 处理错误
          this.$toast(this.$t('mobileLogin.alertAxiosError'))
        })
    },
    // 手机号忘记密码
    PhoneForgetPassword() {
      if (this.phone.length === 0) { // 检测手机号是否为空
        this.$toast(this.$t('computerlogin.alertNoCodeLogin'))
        return
      } else if (this.code === '') { // 检测验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoCode'))
        return
      } else if (this.accountError) { // 检测手机号格式是否错误
        this.$toast(this.$t('computerlogin.alertPhoneError'))
        return
      } else if (this.showRules1 || this.showRules2 || this.showRules3) { // 检测密码格式是否错误
        this.$toast(this.$t('AccountPassword.alertErrorPassword'))
        return
      }
      const data = {
        userphone: this.phone,
        userpassword: this.password,
        code: this.code,
      }
      const loadingToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('mobileLogin.alertLoading'),
      })
      this.$http.post('/v1/register/userphone-update-password', data)
        .then((response) => {
          // 处理返回值
          loadingToast.clear()
          if (response.data.status === 200) {
            this.$toast.success(this.$t('forgetPassword.alertResetSuccessfully'))
            this.changetab('login')
            this.changemode(false)
          } else {
            this.$toast.fail(this.$t('all.modifyFail'))
          }
        })
        .catch(() => {
          // 处理错误
          this.$toast(this.$t('mobileLogin.alertAxiosError'))
        })
    },
    // 邮箱忘记密码
    EmailForgetPassword() {
      if (this.email.length === 0) { // 检测邮箱是否为空
        this.$toast(this.$t('computerlogin.emailRegistrationEmailInput'))
        return
      } else if (this.code === '') { // 检测验证码是否为空
        this.$toast(this.$t('computerlogin.alertNoCode'))
        return
      } else if (this.accountError) { // 检测邮箱格式是否错误
        this.$toast(this.$t('MobileAssociatedReplace.alertEmailError'))
        return
      } else if (this.showRules1 || this.showRules2 || this.showRules3) { // 检测密码格式是否错误
        this.$toast(this.$t('AccountPassword.alertErrorPassword'))
        return
      }
      const data = {
        useremail: this.email,
        userpassword: this.password,
        code: this.code,
      }
      const loadingToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('mobileLogin.alertLoading'),
      })
      this.$http.post('/v1/register/useremail-update-password', data)
        .then((response) => {
          // 处理返回值
          loadingToast.clear()
          if (response.data.status === 200) {
            this.$toast.success(this.$t('forgetPassword.alertResetSuccessfully'))
            this.changetab('login')
            this.changemode(false)
          } else {
            this.$toast.fail(this.$t('all.modifyFail'))
          }
        })
        .catch(() => {
          // 处理错误
          this.$toast(this.$t('mobileLogin.alertAxiosError'))
        })
    },
    // 登录/确认按钮
    Login() {
      if (tab === 'login' && this.mode) { // 手机验证码登录
        this.mobilePhoneLogin();
      } else if (tab === 'login' && !this.mode) { // 密码登录
        this.mobileLogin();
      } else if (tab === 'register' && this.mode) { // 手机号注册
        this.PhoneRegister();
      } else if (tab === 'register' && !this.mode) { // 邮箱注册
        this.EmailRegister();
      } else if (tab === 'forget' && this.mode) { // 手机号忘记密码
        this.PhoneForgetPassword();
      } else if (tab === 'forget' && !this.mode) { // 邮箱忘记密码
        this.EmailForgetPassword();
      }
    }
  },
}
</script>

<style scoped>
.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 99; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.box {
    height: 663px;
    width: 946px;
    padding: 20px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
}
@media (max-width: 946px) or (max-height: 663px) {
    .box {
        transform: scale(1);
        transform-origin: center center; /* 确保从中心缩放 */
    }
}
/* 左侧宣传图 */
.left {
    height: 100%;
    width: 426px;
}
.left-img {
  object-fit: fill;
  width: 426px;
  height: 623px;
}
/* 右侧登录框 */
.right {
  margin-left: 69px;
  flex-grow: 1;
  position: relative;
}
/* 关闭窗口图标 */
.close {
  position: absolute;
  right: 0;
  top: 0;
  object-fit: contain;
  width: 24px;
  cursor: pointer;
}
/* 大标题 */
.title {
  position: absolute;
  top: 30px;
  left: 0;
  font-size: 30px;
  font-weight: bold;
  color: #3D3D3D;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.title-logo {
  height: 33px;
  object-fit: contain;
  margin-left: 9px;
}
/* 副标题 */
.title-subhead {
  position: absolute;
  top: 87px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title-subhead-gray {
  color: #3D3D3D;
}
.title-subhead-blue {
  color: #0256FF;
  cursor: pointer;
}
.title-subhead-icon {
  margin-left: 3px;
  object-fit: contain;
  height: 16px;
  cursor: pointer;
}
/* 登录框 */
.login-box {
  position: absolute;
  top: 130px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* 登录页面通用----模式选择框 */
.tabs {
  width: fit-content;
  border-radius: 8px;
  background-color: #F3F7FB;
  padding: 6px;
  display: flex;
  flex-direction: row;
}
.tab {
  border-radius: 8px;
  padding: 6px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #838A95;
  cursor: pointer;
}
.tab-select {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  color: #404B5A;
}
/* 登录页面通用----输入框 */
.input-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #165dff;
  height: 50px;
  width: 360px;
  padding: 13px 18px;
  position: relative;
}
.input-icon {
  object-fit: contain;
  height: 23px;
}
.input {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 14px;
  color: rgba(61, 61, 61, 0.4);
  border: none;
  background-color: #FFFFFF;
}
.check-icon {
  margin-left: 8px;
  object-fit: contain;
  height: 16px;
}
.input2-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #165dff;
  height: 50px;
  width: 227px;
  padding: 13px 18px;
}
.img-code {
  margin-left: 20px;
  object-fit: fill;
  height: 50px;
  width: 113px;
  cursor: pointer;
}
.get-code {
  margin-left: 20px;
  height: 50px;
  width: 113px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #165DFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #165DFF;
  cursor: pointer;
}
/* 登录/确认按钮 */
.login-button-parent {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.login-button {
  height: 50px;
  width: 411px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #165DFF;
  background-color: #165DFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #FFFFFF;
}
/* 忘记密码 */
.forget-password {
  margin-top: 24px;
  width: 360px;
  text-align: end;
  font-size: 16px;
  color: #0256FF;
}
</style>