<template>
    <div class="h-screen w-screen p-0 m-0" style="min-height:600px;">
        <!-- **********************************************这部分是登录************************************************* -->
        <div class="flex items-center justify-center h-screen flex-col relative mobile-background-base" style="padding:16px;" v-if="mobileMode === 1">
            <div class="home-page-header absolute top-0">
                <div style="padding:20px;">
                    <img src="../icons/logo.png" style="object-fit: cover;height: 40px;" @click="backToHomePage" />
                </div>
                <div style="font-size: 14px;flex: 1;text-align: right;margin-right: 20px;" @click="changeLanguage">
                    <span :style="$i18n.locale === 'en'? 'color: blue' : ''">EN</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span :style="$i18n.locale === 'zh'? 'color: blue' : ''">中文</span>
                </div>
            </div>
            <div class="mobile-login-title">{{$t('mobileLogin.loginTitle')}}</div>

            <!-- 两种模式登录的父层，需要relative来确定内部的相对位置 -->
            <div class="w-full relative flex-grow">
                <!-- 手机注册标签和邮箱注册标签 -->
                <div class="absolute" style="top:0;left:16px;">
                    <span :class="mobileRegister?'register-tab-select':'register-tabs'" @click="changeMode(true)">{{$t('mobileLogin.passwordLogin')}}</span>
                </div>
                <div class="absolute" style="top:0;left:112px;">
                    <span :class="mobileRegister?'register-tabs':'register-tab-select'" @click="changeMode(false)">{{$t('mobileLogin.codeLogin')}}</span>
                </div>
                <!-- 高端的页面只需要简单的代码 -->
                <div class="absolute" style="top:28px;" :style="mobileRegister?'left:21px;':'left:120px;'">
                    <img src="../mImage/login-icon.png" style="height:30px;width:60px;">
                </div>


                <!-- 三合一登录 -->
                <div class="my-tab" style="top:45px;padding:32px 16px 16px 16px;" v-if="mobileRegister" ref="contentBox">
                    <!-- 账号输入区域 -->
                    <div class="mobile-base-input-parent w-full">
                        <img src="../developer/login-loginname.png" class="mobile-base-input-icon">
                        <input class="mobile-base-input" v-model="mobile.account" :placeholder="$t('mobileLogin.passwordLoginAccountInput')">
                    </div>
                    <!-- 提示用户手机号格式 -->
                    <div class="w-full text-sm text-red-600">*{{ $t('all.请注意手机号格式') }}</div>
                    <!-- 密码输入区域 -->
                    <div class="mobile-base-input-parent relative w-full" style="margin-top: 10%;">
                        <img src="../developer/account-password.png" class="mobile-base-input-icon">
                        <input :type="showPassword ? '' : 'password'" class="mobile-base-input" v-model="mobile.password" :placeholder="$t('mobileLogin.passwordInput')">
                        <button class="absolute right-0 top-0 bottom-0 mr-5 focus:outline-none" @click="togglePassword">
                            <img src="../images/not-visible.png" class="h-5" v-if="!showPassword" alt="Show Password" />
                            <img src="../images/visible.png" class="h-5" v-if="showPassword" alt="Hide Password" />
                        </button>
                    </div>
                    <!-- 记住密码及忘记密码区域 -->
                    <div class="w-full flex flex-row" style="margin-top: 10%;">
                        <div>
                            <van-checkbox v-model="checked" icon-size="14px" style="font-size:14px;">{{$t('mobileLogin.remember')}}</van-checkbox>
                        </div>
                        <div class="flex-grow flex justify-end">
                            <span class="text-blue-500 hover:text-blue-700 text-sm" @click="mobileMode = 3">{{$t('mobileLogin.forgotPassword')}}</span>
                        </div>
                    </div>
                </div>


                <!-- 手机号验证码登录 -->
                <div class="my-tab" style="top:45px;" v-else-if="!mobileRegister" ref="contentBox">
                    <!-- 手机号输入区域 -->
                    <div class="mobile-base-input-parent w-full">
                        <img src="../developer/account-phone.png" class="mobile-base-input-icon">
                        <!-- 国际号选择区域 -->
                        <div class="flex flex-row items-center text-sm font-bold mx-1" @click="showDropDown=true">
                            {{ mobile.phoneCountry }}
                            <div class="flex-grow flex justify-end"><img src="../developer/developer-land-dropdown.png" style="height:16px;width:16px;"></div>
                        </div>
                        <input type="tel" class="mobile-base-input" v-model="mobile.phone" :placeholder="$t('mobileLogin.codeLoginPhoneInput')">
                    </div>
                    <!-- 验证码输入区域 -->
                    <div class="mobile-base-input-parent w-full" style="margin-top: 10%;">
                        <img src="../developer/account-password.png" class="mobile-base-input-icon">
                        <input type="text" v-model="mobile.code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                        <div class="mobile-base-input-code-parent">
                            <div class="mobile-base-input-code-button" @click="openModal" v-if="mobile.timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                            <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ mobile.timeCount }})</div>
                        </div>
                    </div>
                </div>

                <div class="absolute w-full" :style="{ top: overlayTop }">
                    <!-- 手机号验证码登录按钮 -->
                    <button class="mobile-button-full-blue" @click="mobilePhoneLogin" v-if="!mobileRegister">{{$t('mobileLogin.login')}}</button>
                    <!-- 三合一登录按钮 -->
                    <button class="mobile-button-full-blue" @click="mobileLogin" v-if="mobileRegister">{{$t('mobileLogin.login')}}</button>
                    <!-- 注册按钮 -->
                    <div class="mt-5 w-full flex justify-center">
                        <span class="text-gray-600">{{$t('mobileLogin.goToRegistration1')}}</span>
                        <span class="text-blue-500 cursor-pointer ml-1" @click="mobileMode = 2">{{$t('mobileLogin.goToRegistration2')}}</span>
                    </div>
                </div>
            </div>
            
            
            <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
            <div class="developer-common-modal" v-if="vmodal">
                <!-- 该弹窗仅用来获取手机验证码 -->
                <div class="developer-common-modal-box">
                    <!-- 标题 -->
                    <div class="w-full text-center mb-3">{{$t('mobileLogin.safeVerify')}}</div>
                    <!-- 图像验证码输入区，上为图片，下为输入框 -->
                    <div class="flex flex-col">
                        <div class="w-full flex justify-center">
                            <img @click="loadCodeImg" style="width:150px;" :src="imgCodeUrl" alt="验证码"/>
                        </div>
                        <div class="account-modal-input-parent" style="margin-top:20px;">
                            <!--            请输入图像验证码-->
                            <img src="../developer/account-password.png" class="account-modal-input-icon">
                            <input type="text" v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" class="account-modal-input" />
                        </div>
                    </div>
                    <!-- 按钮，关闭弹窗和获取验证码 -->
                    <div class="developer-common-buttons">
                        <button class="developer-common-button2" @click="vmodal = false;">{{$t('forgetPassword.no')}}</button>
                        <button class="developer-common-button2" style="margin-left:20px;" @click="getPhoneLoginCode">{{$t('mobileLogin.getCode')}}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- **********************************************这部分是注册************************************************* -->
        <mobile-register v-else-if="mobileMode === 2" ref="register"></mobile-register>

        <!-- **********************************************这部分是忘记密码************************************************* -->
        <forget-password v-else-if="mobileMode === 3" ref="forget"></forget-password>

        <!-- **********************************************这部分是忘记密码************************************************* -->
        <intel-phone v-if="showDropDown"></intel-phone>
    </div>
</template>

<script>
import MobileRegister from "./MobileRegister.vue";
import ForgetPassword from "./MobileForgetPassword.vue";
import IntelPhone from "../mobile/MobileIntlPhone.vue";
export default {
    components: {
        MobileRegister,  // 注册组件
        ForgetPassword, // 忘记密码组件
        IntelPhone, // 国际号弹窗组件
    },
    mounted() {
        this.lastLoginMethod = localStorage.getItem("loginMethod");
        if (localStorage.getItem("remember")) {
            this.checked = true;
            const data = localStorage.getItem("remember");
            const parsedData = JSON.parse(data);
            this.mobile.account = parsedData.loginname;
            this.mobile.password = parsedData.loginpassword;
        }
        if (this.lastLoginMethod === "phone") {
            this.mobileRegister = false;
        } else {
            this.mobileRegister = true;
        }
        this.updateOverlayPosition();
    },
    data() {
        return {
            showPassword: false, // 改变密码显示/隐藏状态的参数

            showRules: false, // 密码格式校验

            mobileMode: 1, // 手机端登录/注册/忘记密码模式，1为登录，2为注册，3为忘记密码

            mobileRegister: false, // 已经乱了，这个变成登录模式了,true为密码,false为验证码

            isCode: true, // 是否可以获取验证码,初始为true,表示可以获取验证码,false时变为重新发送倒计时
            imgCode: "", // 手机端图像验证码
            imgCodeUrl: "", // 手机端图像验证码的url
            mobile: {
                account: "", // 三合一版账号
                email: "", // 手机端邮箱登录/注册时输入的邮箱统一为这个
                phone: "", // 手机端手机号登录/注册时输入的手机号统一为这个
                phoneCountry: '+1', // 手机号国际号
                username: "", // 用户名，同上
                password: "", // 手机端邮箱以及手机号登录/注册时输入的密码统一为这个
                code: "", // 手机端注册输入的验证码统一为这个
                timeCount: 0, // 手机端获取验证码后的倒计时
                timer: null, // 手机端计时器
            },
            showDropDown: false, // 手机号国际号下拉菜单

            accept: false, // 选择是否同意协议
            checked: true, // 记住密码

            vmodal: false, // 手机端邮箱注册时获取验证码的弹窗，由于邮箱验证码获取太慢，给用户一个提示
            errorText: "", // 显示提示的文本

            lastLoginMethod: "", // 根据用户上一次登录方式自动选择登录方式

            overlayTop: '80px', // 下方按钮位置属性
        };
    },
    methods: {
        /* --------------------------------------------自适应样式-------------------------------------------------- */
        updateOverlayPosition() {
            // 获取当前显示的卡片高度
            this.$nextTick(() => {
                const cardElement = this.$refs.contentBox ? this.$refs.contentBox : null;
                if (cardElement) {
                    const cardHeight = cardElement.clientHeight; // 获取卡片高度
                    this.overlayTop = `${cardHeight + 80}px`;   // 更新按钮位置
                } else {
                    this.overlayTop = '80px'; // 如果没有卡片，保持初始位置
                }
            });
        },
        /* --------------------------------------------通用------------------------------------------------ */
        /* 给子组件一个调用父组件的函数，返回登录状态*/
        fatherMethod() {
            this.mobileMode = 1;
        },

        /* 点击眼睛图标显示/隐藏密码 */
        togglePassword() {
            this.showPassword = !this.showPassword;
        },

        /*加载图像验证码*/
        loadCodeImg(){
            ///register/captcha
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },

        changeLanguage() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'zh'
                localStorage.setItem('language', 'zh'); // 存储语言选择到localStorage
            } else {
                this.$i18n.locale = 'en'
                localStorage.setItem('language', 'en'); // 存储语言选择到localStorage
            }
            document.title = this.$t('pageTitle')
        },

        backToHomePage() {
            this.$router.push('/')
        },

        openModal() {
            this.imgCode = '';
            this.loadCodeImg();
            this.vmodal = true;
        },

        /* --------------------------------------------手机端------------------------------------------------ */
        // 切换三合一登录和手机号登录
        changeMode(boolean) {
            this.mobileRegister = boolean;
            this.updateOverlayPosition();
        },

        // 打开国际号弹窗
        openIntlPhoneNumberModal() {
            this.showDropDown = true;
        },
        // 从弹窗获取手机端国际号
        getIntelPhoneNumber(val) {
            if (this.mobileMode === 1 && !this.mobileRegister) { // 登录端手机号登录
                this.mobile.phoneCountry = val;
            } else if (this.mobileMode === 2 && this.$refs.register.mobileRegister) { // 注册端手机号注册
                this.$refs.register.mobile.phoneCountry = val;
            }
            this.closeIntlPhoneNumberModal();
        },
        // 关闭国际号弹窗
        closeIntlPhoneNumberModal() {
            this.showDropDown = false;
        },

        /* 登录方式三合一 */
        mobileLogin() {
            if (this.mobile.account.length === 0) {
                this.$toast(this.$t('mobileLogin.alertNoAccountInput'));
                return;
            }
            const data = {
                loginname: this.mobile.account,
                loginpassword: this.mobile.password,
            };
            this.$http
                .post("/v1/index/login", data, {
                    headers: {
                        'Access-Control-Allow-Origin': 'http://test.datuland.com/',
                        'Access-Control-Allow-Credentials': 'true'
                    }
                })
                .then((response) => {
                    // 处理返回值
                    if (response.data.status === 200) {
                        localStorage.setItem('loginMethod', 'account'); // 存储登录方式到localStorage,key值为loginMethod
                        if (this.checked) {
                            localStorage.setItem('remember', JSON.stringify(data));
                        } else {
                            localStorage.removeItem('remember');
                        }
                        this.$store.dispatch("user/fetchUserInfo");
                        this.$router.push("/mobile?index=1");
                    } else {
                        this.$toast.fail(this.$t('all.loginFail'));
                    }
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },


        /* 手机端的手机号验证码登陆页面点击获取验证码 */
        getPhoneLoginCode() { // 手机验证码登陆点击获取验证码
            if (this.mobile.phone.length === 0) {
                this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'));
                return;
            } else if (this.imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            this.startCountdown();
            this.$http
                .post("/v1/register/phone-verificationcode?userphone=" + this.mobile.phoneCountry + '-' + this.mobile.phone + "&code=" + this.imgCode)
                .then((response) => {
                    loadingToast.clear();
                    if(response.data.status===200){
                        this.vmodal = false;
                        this.$toast.success(this.$t('computerlogin.alertCodeSendSuccessfully'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (response.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                    }
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast.fail(this.$t('mobileLogin.alertAxiosError'));
                });
        },
        /* 手机号验证码登陆 */
        mobilePhoneLogin() {
            if (this.mobile.phone.length === 0 || this.mobile.code.length === 0) {
                this.$toast(this.$t('mobileLogin.alertInforError'));
                return;
            }
            const data = {
                userphone: this.mobile.phoneCountry + '-' + this.mobile.phone,
                remark: this.mobile.code,
            };
            this.$http
                .post("/v1/index/loginyzm", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    // 处理返回值
                    if (response.data.status === 200) {
                        const token = response.headers.authorization;
                        localStorage.setItem('loginMethod', 'phone'); // 存储登录方式到localStorage,key值为loginMethod
                        this.$store.commit("user/setToken", token);
                        this.$store.dispatch("user/fetchUserInfo");
                        this.$router.push("/mobile?index=1");
                    } else {
                        this.$toast.fail(this.$t('all.loginFail'));
                    }
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },


        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.mobile.timeCount = 60;
            this.isCode = false;
            // 计时器
            this.mobile.timer = setInterval(() => {
                if (this.mobile.timeCount > 0) {
                    this.mobile.timeCount--;
                } else {
                    this.isCode = true;
                    clearInterval(this.mobile.timer);
                    this.mobile.timer = null;
                }
            }, 1000);
        },

        closeModal() {
            this.vmodal = false;
        },


        /* 用户名登陆 */
        /* mobileNameLogin() {
            if (!this.mobile.username === '' || this.mobile.password === '' || this.showRules) {
                this.$toast('请检查用户名和密码')
                return
            }
            const data = {
                username: this.mobile.username,
                userpassword: this.mobile.password
            }
            this.$http
            .post('/index/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                // 处理返回值
                if (response.data.msg==="登陆成功") {
                    // 保存用户选择的登录方式
                    localStorage.setItem('loginMethod', 'username');
                    this.$router.push('/usermobile?index=1')
                } else {
                    this.loading = false
                    this.$toast('')
                }
            }).catch(error => {
                // 处理错误
                this.$toast(error.data.data)
                this.loading = false
            });
        }, */
        /* 邮箱登陆 */
        /* mobileEmailLogin() {
            if (!this.mobile.email.includes('@') || this.mobile.password === '' || this.showRules) {
                this.$toast('请检查邮箱和密码')
                return
            }
            const data = {
                useremail: this.mobile.email,
                userpassword: this.mobile.password
            }
            this.$http
            .post('/index/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                // 处理返回值
                if (response.data.msg==="登陆成功") {
                    // 保存用户选择的登录方式
                    localStorage.setItem('loginMethod', 'email');
                    this.$router.push('/usermobile?index=1')
                } else {
                    this.loading = false
                    this.$toast('response.data.data')
                }
            }).catch(error => {
                // 处理错误
                this.$toast(error.data.data)
                this.loading = false
            });
        }, */
    },
};
</script>

<style scoped>
/* ------------------------------------------------以下是手机端---------------------------------------------------- */
.home-page-header {
    width: 100%;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: row;
}
.mobile-login-title {
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    margin-top: 80px;
    margin-bottom: 16px;
}
</style>
