<template>
    <!-- 挂牌弹框-显示和隐藏状态根据 smodal 变量决定 -->
        <div class="land-sell-modal" v-if="vmodal === 1">
            <div class="land-sell-modal-box">
                <!-- 标题 -->
                <div class="land-sell-modal-box-title">
                    <span>{{$t('LandMap3.listtitle')}}</span>
                    <div class="flex-grow flex justify-end">
                        <img src="./developer/map-sell-cancel.png" @click="closeSaleModal" class="cursor-pointer">
                    </div>
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('useraccount.merch')}}</div>
                    <input type="text" v-model="salename" maxlength="20" show-word-limit class="land-sell-modal-box-items-input" :placeholder="$t('all.default') + ':' + LandInfor.mergelandname + LandInfor.unitlandid">
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.buytype')}}</div>
                    <el-select v-model="type" :placeholder="$t('LandMap3.pleasechoose')" style="width: 200px;margin-left:10px;">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.unitprice')}}</div>
                    <input type="number" v-model="saleUnitPrice" class="land-sell-modal-box-items-input" @input="changeSellPrice">
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.unitnumber')}}</div>
                    <div class="land-sell-modal-box-items-span">
                        <span class="text-red-500">{{ LandInfor.landblocks }}</span>
                        <span class="ml-2">{{$t('LandMap3.unitunit')}}</span>
                    </div>
                </div>
                <div class="land-sell-modal-box-items">
                    <div class="land-sell-modal-box-items-label">{{$t('LandMap3.totalpeice')}}</div>
                    <input type="number" v-model="sellprice" maxlength="15" show-word-limit class="land-sell-modal-box-items-input" @input="changeSaleUnitPrice">
                </div>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="./developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <span style="font-size: 10px;color: red;margin-top: 8px;">*{{$t('LandMap3.listtip1')}}</span>
                <span style="font-size: 10px;color: red;">*{{$t('LandMap3.listtip2')}}</span>
                <span style="font-size: 10px;color: red;">*{{$t('LandMap3.listtip3')}}</span>
                <button class="land-sell-button" @click="sellLand">{{$t('LandMap3.ok')}}</button>
            </div>
        </div>
        <!-- 修改价格的弹窗，根据 vmodal 变量决定 -->
        <div class="developer-common-modal" v-else-if="vmodal === 2">
            <div class="developer-common-modal-box" v-if="needCode">
                <span>{{$t('all.verifyId')}}</span>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="./developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-input v-model="newprice" class="mt-5" :placeholder="$t('LandSellDetail.pricenum')"></el-input>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="changePrice">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('LandSellDetail.changeprice')}}</span>
                <el-input v-model="newprice" class="mt-5" :placeholder="$t('LandSellDetail.pricenum')"></el-input>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="changePrice">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
        </div>
        <!-- 二次确认下架商品的弹窗 -->
        <div class="developer-common-modal" v-else-if="vmodal === 3">
            <div class="developer-common-modal-box" v-if="needCode">
                <span>{{$t('all.verifyId')}}</span>
                <!-- 验证码输入区域 -->
                <div class="flex flex-col" style="margin-top:16px;" v-if="needCode">
                    <div class="flex flex-row w-full items-center">
                        <div class="land-sell-code-button">
                            <img src="./developer/account-password.png" class="mobile-base-input-icon">
                            <input type="text" v-model="code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" class="mobile-base-input"/>
                            <div class="mobile-base-input-code-parent">
                                <div class="mobile-base-input-code-button" @click="getVerificationCode" v-if="timeCount===0">{{$t('mobileLogin.getCode')}}</div>
                                <div class="mobile-base-input-code-button" v-else>{{$t('forgetPassword.resendCode')}} ({{ timeCount }})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="unsale">{{$t('all.removeNow')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
            <div class="developer-common-modal-box" v-else>
                <span>{{$t('LandSellDetail.alertunsale')}}</span>
                <div class="developer-common-buttons">
                    <button class="developer-common-button2" style="width:100px;" @click="unsale">{{$t('OriginalLandDetail.l16')}}</button>
                    <el-button type="infor" class="developer-common-button" style="margin-left: 20px;" @click="closeSaleModal">{{$t('OriginalLandDetail.l18')}}</el-button>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        LandInfor: {
            type: Object,
            default: {
                unitlandid: '',
                landblocks: 0,
                mergelandname: '',
            },
        },
    },
    mounted() {
        this.updateModalScale();
        // 监听窗口大小变化以更新弹窗缩放
        window.addEventListener('resize', this.updateModalScale);
    },
    beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.updateModalScale);
        clearInterval(this.timer);
    },
    data() {
        return {
            vmodal: 0, // 打开哪个弹窗，1是挂牌，2是修改价格，3是下架
            newprice: '', // 价格
            type: '立即购买', // 挂牌-购买形式
            options: [{value: '立即购买',label: this.$t('LandMap3.buytype1')},], // 挂牌-购买形式的选择集
            saleUnitPrice: 0, // 单位土地售价
            sellprice: 0, // 售价
            salename: '', // 设置的商品名称

            needCode: true, // 是否需要验证码
            code: '', // 挂牌验证码
            timeCount: 0, // 倒计时
            timer: null, // 定时器
        }
    },
    methods: {
        // 根据屏幕大小自适应弹窗大小
        updateModalScale() {
            const modal = document.querySelector('.land-sell-modal-box');
            if (window.innerWidth >= 400 && window.innerHeight >= 600) {
                modal.style.transform = `scale(1)`;
            } else {
                const scaleX = window.innerWidth / 400;
                const scaleY = window.innerHeight / 600;
                const scale = Math.min(scaleX, scaleY);
                modal.style.transform = `scale(${scale})`;
            }
        },
        // 挂牌弹窗修改单位土地价格时总价格也会变
        changeSellPrice() {
            this.sellprice = this.saleUnitPrice * this.LandInfor.landblocks;
        },
        changeSaleUnitPrice() {
            if (this.selectunitland) {
                let quotient = this.sellprice / this.LandInfor.landblocks;
                this.saleUnitPrice = quotient.toFixed(2);
            }
        },

        // 获取验证码前打开弹窗需要检测是否需要验证码
        codeNeedOrNot(tab) {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/if-operation')
                .then(res => {
                    loadingToast.clear();
                    this.needCode = res.data.data;
                    if (tab === '挂牌') {
                        this.vmodal = 1;
                    } else if (tab === '下架') {
                        this.vmodal = 3;
                    } else if (tab === '修改价格') {
                        this.vmodal = 2;
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        // 获取验证码
        getVerificationCode() {
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('forgetPassword.alertLoading'),
            });
            this.$http.get('/v1/sale/verification-code')
                .then(res => {
                    loadingToast.clear();
                    if (res.data.status === 200) {
                        this.startCountdown();
                        if (res.data.data === '邮箱验证码发送成功') {
                            this.$toast.success(this.$t('LandSellList.sendemail'));
                        } else {
                            this.$toast.success(this.$t('LandSellList.sendphone'));
                        }
                    } else {
                        this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                    }
                }).catch(err => {
                    loadingToast.clear();
                    this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
                })
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.timeCount = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                    this.timeCount--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        // 挂牌
        sellLand() { // 挂牌售卖
            let data = {
                unitlandids: [this.unitlandid],
                salesmethods: this.type,
                pricenum: this.sellprice,
            }
            if (this.needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            if (this.salename !== '') {
                data["commodityname"] = this.salename;
            }
            this.$http.post('/v1/sale/add-sale-list',data)
            .then(res => {
                if (res.data.status === 200) {
                    this.$toast.success(this.$t('LandMap3.saleSuccess'));
                    window.location.reload(); // 刷新页面
                } else {
                    this.$toast.fail(this.$t('LandMap3.saleFail'));
                }
            }).catch(err => {
                // 处理错误
                this.$toast.fail(this.$t('LandMap3.neterror'));
            });
        },
        // 关闭挂牌弹窗
        closeSaleModal() {
            this.saleUnitPrice = '';
            this.sellprice = '';
            this.salename = '';
            this.newprice = '';
            this.code = '';
            clearInterval(this.timer);
            this.timeCount = 0;
            this.vmodal = 0;
        },
        // 下架
        unsale() {
            let data = {
                unitlandids: [this.unitlandid]
            }
            if (this.needCode && this.code === '') {
                this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                return;
            } else if (this.needCode) {
                data["code"] = this.code;
            }
            this.$http.DELETE('/v1/sale/delete-sale-list',data)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('all.removeSuccessful'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(this.$t('all.removeFail'));
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        // 修改价格
        changePrice() {
            const re = /^(?:(?:0|[1-9]\d*)(?:\.\d+)?|\d+(?:\.\d*)?)$/;
            if (this.newprice !== '' && re.test(this.newprice)) {
                let data = {
                    unitlandids: [this.unitlandid],
                    salesmethods: this.type,
                    pricenum: this.newprice
                }
                if (this.needCode && this.code === '') {
                    this.$toast.fail(this.$t('computerlogin.alertNoCode'));
                    return;
                } else if (this.needCode) {
                    data["code"] = this.code;
                }
                this.$http.put('/v1/sale/update-sale-list',data)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MyInfor.letter19'));
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(this.$t('all.modifyFail'));
                    }
                }).catch(error => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
            } else {
                this.$toast.fail(this.$t('LandSellDetail.tip4'));
            }
        },
    },
}
</script>

<style>
/* ----------------------------------------挂牌售卖弹窗---------------------------------------- */
.land-sell-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.land-sell-modal-box {
    height: auto;
    width: 450px;
    max-width: 90%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
    border-radius: 16px;
}
.land-sell-modal-box-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.land-sell-modal-box-title > span {
    font-size: 25px;
}
.land-sell-modal-box-items {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.land-sell-modal-box-items-label {
    width: 120px;
    max-width: 35%;
    text-align: end;
    font-size: 14px;
    white-space: nowrap;
}
.land-sell-modal-box-items-input {
    margin-left: 10px;
    width: 200px;
    max-width: 65%;
    height: 36px;
    background-color: #F1F4F8;
    border-radius: 6px;
    font-size: 14px;
    position: relative;
    padding: 0 8px;
}
.land-sell-modal-box-items-input > span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.land-sell-modal-box-items-span {
    width: 200px;
    max-width: 65%;
    margin-left: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
}
.land-sell-button {
    margin-top: 20px;
    height: 36px;
    width: auto;
    padding: 8px 22px;
    background-color: #165DFF;
    font-size: 16px;
    border-radius: 10px;
    color: #fff;
}
.land-sell-code-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F1F4F8;
    border-radius: 10px;
    height: 40px;
    width: 100%;
    padding: 10px 18px;
}
</style>