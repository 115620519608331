<template>
    <div class="admin-manage-container">
        <div class="admin-breadcrumb">
            <img src="../adminImage/home-page.png" class="admin-breadcrumb-icon">
            <span class="admin-breadcrumb-interval">/</span>
            <span>平台管理</span>
            <span class="admin-breadcrumb-interval">/</span>
            <span class="admin-breadcrumb-link cursor-pointer" @click="window.location.reload();">管理员新增</span>
        </div>
        <div class="admin-admin-add-box">
            <span class="admin-admin-add-title">新增管理员</span>
            <div class="admin-admin-add-infor">
                <div class="admin-admin-add-col">
                    <div class="admin-admin-add-row-label flex items-center"><span style="color:red;margin-right:5px;">*</span>管理员登录账号(昵称):</div>
                    <el-input v-model="loginname" placeholder="请输入登录账号，同时也是该管理员昵称"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <div class="admin-admin-add-row-label flex items-center"><span style="color:red;margin-right:5px;">*</span>管理员登录密码:</div>
                    <el-input v-model="loginpassword" placeholder="请输入登录密码"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <div class="admin-admin-add-row-label flex items-center"><span style="color:red;margin-right:5px;">*</span>姓名:</div>
                    <el-input v-model="administratorname" placeholder="请输入该账号使用人真实姓名"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <div class="admin-admin-add-row-label flex items-center"><span style="color:red;margin-right:5px;">*</span>手机号:</div>
                    <el-input v-model="administratorphone" placeholder="请输入该账号使用人手机号"></el-input>
                </div>
                <div class="admin-admin-add-col">
                    <div class="admin-admin-add-row-label flex items-center"><span style="color:red;margin-right:5px;">*</span>管理员职称:</div>
                    <el-radio v-model="role" label="普通管理员">普通管理员</el-radio>
                    <el-radio v-model="role" label="高级管理员">高级管理员</el-radio>
                    <el-radio v-model="role" label="超级管理员">超级管理员</el-radio>
                </div>
                <div class="admin-admin-add-col">
                    <div class="admin-admin-add-row-label flex items-center"><span style="color:red;margin-right:5px;">*</span>状态:</div>
                    <div class="admin-manage-status-normal" v-if="status==='可用'" @click="status='不可用'" style="cursor:pointer;">正常</div>
                    <div class="admin-manage-status-stop" v-else @click="status='可用'" style="cursor:pointer;">停用</div>
                </div>
                <div class="admin-admin-add-col flex justify-center align-center">
                    <button class="admin-add-button" @click="vmodal=true" @mouseover="changeCursor">
                        <img src="../adminImage/admin-add-add.png" class="admin-add-button-icon">
                        新增
                    </button>
                </div>
            </div>
        </div>
        <!-- 启用停用管理员账户二次确认弹窗，弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="admin-apply-modal" v-if="vmodal">
            <div class="admin-apply-modal-box">
                <span style="padding:30px;">确定新增该管理员账号？</span>
                <div style="margin-top: 20px;">
                    <el-button type="info" plain @click="vmodal=false" class="admin-apply-button-1">取消</el-button>
                    <el-button type="primary" @click="add" class="admin-apply-button-1" style="margin-left: 20px;">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            administratorname: '',
            loginname: '',
            loginpassword: '',
            administratorphone: '',
            role: '普通管理员',
            status: '可用',
            vmodal: false,
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        add() {
            if (this.loginname === '') {
                this.$toast.fail("登录账号（昵称）不可为空");
                this.vmodal = false;
                return;
            } else if (this.loginpassword === '') {
                this.$toast.fail("登录密码不可为空");
                this.vmodal = false;
                return;
            } else if (this.administratorname === '') {
                this.$toast.fail("真实姓名不可为空");
                this.vmodal = false;
                return;
            } else if (this.administratorphone === '') {
                this.$toast.fail("联系电话不可为空");
                this.vmodal = false;
                return;
            }
            let data = {
                administratorname: this.administratorname,
                loginname: this.loginname,
                loginpassword: this.loginpassword,
                administratorphone: this.administratorphone,
                role: this.role,
                status: this.status
            }
            this.$http.post("/v1/administrator/insert-platformmanage-one", data)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        this.$toast.success("新增成功！");
                        window.location.reload(); // 刷新页面
                    } else {
                        this.$toast.fail(data.data);
                    }
                })
                .catch((error) => {
                    this.$toast.fail("修改失败,请检查网络或重新登录")
                });
        },
    },
}
</script>

<style>
.admin-admin-add-box {
    min-height: 700px;
    width: 100%;
    padding: 30px;
    border-radius: 12px;
    background-color: #fff;
}
.admin-admin-add-infor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* 标题 */
.admin-admin-add-title {
    font-size: 24px;
    font-weight: bold;
}
/* 行块 */
.admin-admin-add-col {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    width: 600px;
}
.admin-admin-add-row-label {
    width: 300px;
    font-size: 16px;
}
.admin-admin-add-row-font {
    font-size: 20px;
    color: #8185a3;
}
.admin-add-button {
    margin-top: 20px;
    width: 130px;
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #165DFF;
    color: #fff;
    border-radius: 6px;
}
.admin-add-button-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
}
</style>