<template>
    <div class="normal-card-box overflow-y-auto" ref="cardContainer" style="width:1200px;">
        <div class="my-land-container" ref="card">
            <!-- 标题-返回文本 -->
            <div class="goback-parent" style="margin-bottom:24px" @click="$router.go(-1)">
                <span class="text-2xl">{{ '<' }}</span>
                <span class="ml-2">{{$t('PcMyLandDetail.title')}}</span>
            </div>

            <!-- 地图和信息 -->
            <div class="developer-detail-card" style="border: 1px solid #E5E5E5;min-width: 1140px;">
                <!-- 加载界面 -->
                <div class="absolute h-full w-full bg-white" style="top: 0;left: 0;z-index: 999;" v-if="loading">
                    <el-skeleton :loading="loading" animated>
                        <template slot="template">
                            <div class="developer-detail-card">
                                <el-skeleton-item variant="image" style="height: 300px;width: 300px;" />
                                <div class="flex flex-col" style="margin-left: 50px;">
                                    <el-skeleton-item variant="p" class="width:200px;" />
                                    <el-skeleton-item variant="p" style="width:200px;" />
                                    <el-skeleton-item variant="p" style="width:200px;margin-top: 30px;" />
                                    <el-skeleton-item variant="p" style="width:200px;margin-top: 30px;" />
                                    <el-skeleton-item variant="p" style="width:200px;margin-top: 30px;" />
                                    <el-skeleton-item variant="p" style="width:200px;margin-top: 30px;" />
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </div>
                <canvas ref="bigmap" :height="canvasSize" :width="canvasSize" class="developer-detail-box-canvas shadow-sm"></canvas>
                <div class="developer-detail-box absolute" style="width: 690px;">
                    <div class="developer-common-card-title-parent">
                        <div class="developer-common-card-title">{{ LandInfor.unitLand.mergelandname }}</div>
                        <div class="flex-grow flex justify-end items-center" v-show="LandInfor.id">
                            <button class="developer-common-card-button px-3" style="margin-left:200px;width: auto;" @click="gotoland">{{$t('LandSellDetail.goin')}}</button>
                        </div>
                    </div>
                    <div class="flex flex-row text-xl" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.blocks')}}:</div>{{ LandInfor.unitLand.landblocks }}</div>
                    <div class="flex flex-row text-xl" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.createdate')}}:</div>{{ LandInfor.unitLand.createdate }}</div>
                    <div class="flex flex-row text-xl" style="margin-top:30px;" v-if="LandInfor.unitLand.status === '已挂牌'">
                        <div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.price')}}:</div>
                        <span class="text-red-500" v-if="$setPayment === 'am'">${{ LandInfor.onSale.totalprice }}</span>
                        <span class="text-red-500" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.onSale.totalprice * $exchangeRate }}</span>
                    </div>
                    <div class="flex flex-row flex-grow items-end" v-show="LandInfor.unitLand.status === '已挂牌'">
                        <button class="developer-common-card-button" style="background-color:#165DFF;color: #FFFFFF;" @click="$refs.landsale.codeNeedOrNot('修改价格')">{{$t('LandSellDetail.changeprice')}}</button>
                        <button class="developer-common-card-button ml-10" @click="$refs.landsale.codeNeedOrNot('下架')">{{$t('LandSellDetail.unsale')}}</button>
                    </div>
                    <div class="flex flex-row flex-grow items-end" v-show="LandInfor.unitLand.status === '未挂牌'">
                        <button class="developer-common-card-button" style="background-color:#165DFF;color: #FFFFFF;" @click="$refs.landsale.codeNeedOrNot('挂牌')">{{$t('all.sale')}}</button>
                    </div>
                </div>
            </div>

            <div class="flex flex-row" style="margin-top:30px;min-width: 1140px;">
                <!-- 分块土地详情 -->
                <div class="developer-common-card-box relative" style="width:50%;border: 1px solid #E5E5E5;">
                    <!-- 加载界面 -->
                    <div class="absolute h-full w-full bg-white" style="top: 0;left: 0;z-index: 999;" v-if="loading">
                        <el-skeleton :loading="loading" animated>
                            <template slot="template">
                                <div class="bg-white" style="padding: 20px 50px;">
                                    <div class="flex flex-col">
                                        <el-skeleton-item variant="h1" class="width: 330px;" />
                                        <el-skeleton-item variant="h1" class="width: 330px;" />
                                        <div style="padding: 20px;">
                                            <div class="flex flex-row">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-skeleton>
                    </div>
                    <!-- 数据显示 -->
                    <div class="developer-common-card-title-parent">
                        <div class="developer-common-card-title">{{$t('LandSellDetail.unitdetail')}}</div>
                    </div>
                    <div class="flex flex-col" style="padding: 20px;">
                        <div class="flex flex-row"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.area')}}:</div>{{ LandInfor.unitLand.communitynumber }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandMap1.l6')}}:</div>{{ LandInfor.unitLand.landcoordinates }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.landpicture')}}:</div>{{ LandInfor.originalLand.height }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandMap3.statu')}}:</div>{{ LandInfor.unitLand.status }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandMap1.l3')}}:</div>{{ LandInfor.unitLand.createdate }}</div>
                    </div>
                </div>
                <!-- 原始土地详情 -->
                <div class="developer-common-card-box relative" style="width:50%;margin-left:30px;border: 1px solid #E5E5E5;">
                    <!-- 加载界面 -->
                    <div class="absolute h-full w-full bg-white" style="top: 0;left: 0;z-index: 999;" v-if="loading">
                        <el-skeleton :loading="loading" animated>
                            <template slot="template">
                                <div class="bg-white" style="padding: 20px 50px;">
                                    <div class="flex flex-col">
                                        <el-skeleton-item variant="h1" class="width: 330px;" />
                                        <el-skeleton-item variant="h1" class="width: 330px;" />
                                        <div style="padding: 20px;">
                                            <div class="flex flex-row">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                            <div class="flex flex-row" style="margin-top: 30px;">
                                                <el-skeleton-item variant="p" style="width:180px;" />
                                                <el-skeleton-item variant="p" style="width:200px;margin-left: 20px;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-skeleton>
                    </div>
                    <!-- 数据显示 -->
                    <div class="developer-common-card-title-parent">
                        <div class="developer-common-card-title">{{$t('LandSellDetail.origindetail')}}</div>
                    </div>
                    <div class="flex flex-col" style="padding: 20px;">
                        <div class="flex flex-row"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.originallandid')}}:</div>{{ LandInfor.originalLand.originallandid }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.originallandname')}}:</div>{{ LandInfor.originalLand.originallandname }}</div>
                        <!-- <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('MobilePurchaseDetails.developer')}}:</div>{{ LandInfor.userBusiness.corporationname?LandInfor.userBusiness.corporationname:'' }}</div> -->
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.detailedaddress')}}:</div>{{ LandInfor.originalLand.detailedaddress }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.geo')}}:</div>{{ LandInfor.originalLand.geographicalcoordinates }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.landlength')}}:</div>{{ LandInfor.originalLand.landlength }}</div>
                        <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandReview.landwidth')}}:</div>{{ LandInfor.originalLand.landwidth }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 挂牌/修改价格/摘牌弹窗 -->
        <LandSale ref="landsale" :LandInfor="saleLand"></LandSale>

  </div>
</template>

<script>
import LandSale from '../LandSaleModal.vue'
export default {
    components: {
        LandSale
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.unitlandid = this.$route.query.land;
    },
    mounted() {
        this.$parent.adjustBoxHeight('100%');
        // 监听窗口大小变化以更新弹窗缩放
        this.controlWindow();
        window.addEventListener('resize', this.controlWindow);
        // 获取土地详情信息
            this.$http.post("/v1/myland/show-myland-one?unitlandid=" + this.unitlandid)
                .then((res) => {
                    if (res.data.data) {
                        this.LandInfor = res.data.data;
                        this.saleLand.unitlandid = this.unitlandid;
                        this.saleLand.landblocks = this.LandInfor.unitLand.landblocks;
                        this.saleLand.mergelandname = this.LandInfor.unitLand.mergelandname;
                        let time1 = setTimeout(() => {
                            // 等待 100 毫秒后再绘制
                            const canvas = this.$refs.bigmap; // 获取 canvas 元素
                            canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                            this.drawGrid();
                            this.drawLand();
                            this.loading = false;
                            clearInterval(time1);
                        }, 100);
                    } else {
                        this.$toast.fail('404 NOT FOUND');
                    }
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('PcMyLandList.alertloadfail'));
                });
    },
    beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.controlWindow);
  },
    data() {
        return {
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            loading: true,
            saleLand: {
                unitlandid: '',
                landblocks: 0,
                mergelandname: '',
            },
            LandInfor: {
                id: null,
                originallandid: "",
                mergelandname: this.$t('LandSellDetail.name'),
                landnumber: "",
                communitynumber: "",
                landblocks: 0,
                landcoordinates: "",
                virtualownership: "",
                remark: null,
                status: "",
                createdate: "",
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                "unitLand": {
                    "id": 6004,
                    "originallandid": "S666",
                    "mergelandname": "工业土地",
                    "landnumber": "1",
                    "communitynumber": "区(1,1)",
                    "landblocks": 16,
                    "landcoordinates": "(12,0) ~ (15,3)",
                    "virtualownership": "10002",
                    "remark": "",
                    "status": "未挂牌",
                    "createdate": "2023-04-28 11:32:24",
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "unitlandid": null,
                    "originalLand": null,
                    "onSale": null
                },
                "onSale": {
                    "id": 88,
                    "originallandid": "",
                    "unitlandid": null,
                    "landname": null,
                    "sellerid": null,
                    "seller": null,
                    "salesmethods": null,
                    "expirydate": null,
                    "unitlandprice": null,
                    "pricenum": null,
                    "unitlandminprice": null,
                    "minimumprice": null,
                    "blocks": null,
                    "totalprice": '',
                    "unitpicture": null,
                    "createtime": null,
                    "reasondelisting": null,
                    "status": "未挂牌",
                    "remark": null,
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "originalLand": null,
                    "unitLand": null
                },
                userBusiness: {
                    cope: null,
                },
            },

            canvasSize: 300,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: "true", // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
            users: {
                avatarUrl: require("../assets/no-image.png"),
            },
        }
    },

    methods: {
        // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1400) {
                const scaleX = (window.innerWidth - 300) / 1200;
                this.$refs.card.style.transform = `scale(${scaleX})`;
                this.$refs.cardContainer.style.width = window.innerWidth - 250 + 'px';
            } else {
                this.$refs.card.style.transform = `scale(1)`;
                this.$refs.cardContainer.style.width = '1200px';
            }
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;// 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        // 进入土地地图
        gotoland() {
            this.$router.push({
                path: '/user/land-operate/area-map',
                name: 'UserPcLandMap3',
                query: {
                    land: this.LandInfor.originalLand.originallandid,
                    area: this.LandInfor.unitLand.communitynumber
                },
                params: {
                    id: this.unitlandid
                }
            });
        },
    },
}
</script>

<style scoped>
.my-land-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transform-origin: top left;
}
</style>