<template>
    <div class="mobile-associate-background">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileSafe.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="mobile-associate-box" @click="goChangePayPassword">
            <span class="flex-grow text-lg">{{hasSetPayPassword?$t('MobileSafe.modify'):$t('MobileSafe.set')}}{{$t('MobileSafe.payment')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
        <div class="mobile-associate-box" @click="goChangePassword">
            <span class="flex-grow text-lg">{{$t('MobileSafe.change')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
        <div class="mobile-associate-box" @click="goEmailSet">
            <div class="mobile-associate-box-text">
                <span :style="$i18n.locale === 'en'?'font-size: 16px':'font-size: 18px'">{{$t('MobileAssociated.email')}}</span>
                <span style="font-size: 14px; color: #bebebe">{{ users.email }}</span>
            </div>
            <div class="flex-grow flex flex-row items-center justify-end">
                <span class="mobile-associate-box-tip">{{ users.emailTip }}</span>
                <img src="../images/right.png" class="flex-none w-5 h-5" />
            </div>
        </div>
        <div class="mobile-associate-box" @click="goPhoneSet">
            <div class="mobile-associate-box-text">
                <span :style="$i18n.locale === 'en'?'font-size: 16px':'font-size: 18px'">{{$t('MobileAssociated.phone')}}</span>
                <span style="font-size: 14px; color: #bebebe">{{ users.phone }}</span>
            </div>
            <div class="flex-grow flex flex-row items-center justify-end">
                <span class="mobile-associate-box-tip">{{ users.phoneTip }}</span>
                <img src="../images/right.png" class="flex-none w-5 h-5" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            userInfor: (state) => state.user.userInfo,
        }),
    },
    mounted() {
        let userData = this.userInfor;
        if (userData.useremail) {
            this.users.email = userData.useremail;
            this.users.emailTip = this.$t('MobileAssociated.alertChange');
        } else {
            this.users.email = this.$t('MobileAssociated.alertNoEmail');
            this.users.emailTip = this.$t('MobileAssociated.alertAdd');
        }
        if (userData.userphone) {
            this.users.phone = userData.userphone;
            this.users.phoneTip = this.$t('MobileAssociated.alertChange');
        } else {
            this.users.phone = this.$t('MobileAssociated.alertNoMobile');
            this.users.phoneTip = this.$t('MobileAssociated.alertAdd');
        }
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            hasSetPayPassword: false,
            /* 几条文本 */
            users: {
                email: "",
                emailTip: "",
                phone: "",
                phoneTip: "",
            },
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        goChangePayPassword() {
            this.$router.push("/mobile/change-password/pay");
        },
        goChangePassword() {
            this.$router.push("/mobile/change-password");
        },
        goEmailSet() {
            if (this.users.emailTip === this.$t('MobileAssociated.alertAdd')) {
                this.$router.push("/mobile/associate/set?type=email");
            } else if (this.users.emailTip === this.$t('MobileAssociated.alertChange')) {
                this.$router.push({
                    name: "MobileAssociatedReplace",
                    params: {
                        type: "email",
                        useremail: this.users.email,
                        userphone: null,
                    },
                });
            }
        },
        goPhoneSet() {
            if (this.users.emailTip === this.$t('MobileAssociated.alertAdd')) {
                this.$router.push("/mobile/associate/set?type=phone");
            } else if (this.users.emailTip === this.$t('MobileAssociated.alertChange')) {
                this.$router.push({
                    name: "MobileAssociatedReplace",
                    params: {
                        type: "phone",
                        userphone: this.users.phone,
                        useremail: null,
                    },
                });
            }
        },
    },
};
</script>

<style scoped>
.mobile-associate-background {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}
.mobile-associate-box {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #b5b5b5;
    margin-top: 20px;
    position: relative;
    @apply hover:bg-gray-200 bg-white mx-6 px-8 pt-4 pb-3
}
.mobile-associate-box-text {
    display: flex;
    flex-direction: column;
}
.mobile-associate-box-tip {
    font-size: 12px;
    color: #bebebe;
}
</style>
