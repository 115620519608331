<template>
    <div class="developer-common-container" style="overflow-y:auto;overflow-x:hidden;">
        <div class="sell-list-tabs">
            <div class="sell-list-tab" :class="isTab1(1)" @click="tab1=1">{{$t('TotalIncome.l1')}}</div>
            <div class="sell-list-tab" style="margin-left:120px;" :class="isTab1(2)" @click="tab1=2">{{$t('TotalIncome.l2')}}</div>
        </div>
        <div class="w-full flex flex-row" style="margin-top:30px;" v-if="tab1===1">
            <div class="sell-list-card-parent" style="width:20%">
                <img src="../developer/sell-list-land.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.买家数量 }}</div>
                    <div class="sell-list-card-text2">{{$t('TotalIncome.l3')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="width:20%;margin-left:30px;">
                <img src="../developer/sell-list-unit.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.总收益 }}</div>
                    <div class="sell-list-card-text2">{{$t('TotalIncome.l4')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="width:20%;margin-left:30px;">
                <img src="../developer/sell-list-selling.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.昨日新增收益 }}</div>
                    <div class="sell-list-card-text2">{{$t('TotalIncome.l5')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="width:20%;margin-left:30px;">
                <img src="../developer/sell-list-sale.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.平均交易额 }}</div>
                    <div class="sell-list-card-text2">{{$t('TotalIncome.l6')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="width:20%;margin-left:30px;">
                <img src="../developer/sell-list-sale.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.昨日平均交易额 }}</div>
                    <div class="sell-list-card-text2">{{$t('TotalIncome.l7')}}</div>
                </div>
            </div>
        </div>
        <div class="w-full flex flex-row" style="margin-top:30px;" v-else-if="tab1===2">
            <div class="sell-list-card-parent" style="width:33%">
                <img src="../developer/sell-list-land.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.交易土地数据 }}</div>
                    <div class="sell-list-card-text2">{{$t('TotalIncome.l8')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="width:33%;margin-left:30px;">
                <img src="../developer/sell-list-unit.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.购买土地的单位土地数量 }}</div>
                    <div class="sell-list-card-text2">{{$t('all.purchasedLandNum')}}</div>
                </div>
            </div>
            <div class="sell-list-card-parent" style="width:33%;margin-left:30px;">
                <img src="../developer/sell-list-selling.png" style="height:80px;width:80px;">
                <div class="sell-list-card-text">
                    <div class="sell-list-card-text1">{{ user_data.卖出土地的单位土地数量 }}</div>
                    <div class="sell-list-card-text2">{{$t('all.soldLandNum')}}</div>
                </div>
            </div>
        </div>
        <div class="sell-list-tabs">
            <div class="sell-list-tab" :class="isTab2(1)" @click="tab2=1">{{$t('TotalIncome.l11')}}</div>
            <div class="sell-list-tab" style="margin-left:120px;" :class="isTab2(2)" @click="tab2=2">{{$t('TotalIncome.l12')}}</div>
        </div>
        <div class="w-full flex flex-col" ref="canvas" style="margin-top:30px;" v-show="tab2===1">
            <div class="developer-common-card-box">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">{{$t('TotalIncome.l13')}}</div>
                </div>
                <div class="echar" ref="charts" style="height:300px;width:0;"></div>
            </div>
            <div class="developer-common-card-box" style="margin-top:30px;">
                <div class="flex flex-row w-full h-full">
                    <div class="form-item-cells">
                        <div style="height:40px;width:100%;color: #788AA1;">
                            <el-col :span="4"><span>{{$t('OrderView.l8')}}</span></el-col>
                            <el-col :span="5"><span>{{$t('OrderView.l9')}}</span></el-col>
                            <el-col :span="2"><span>{{$t('OrderView.l10')}}</span></el-col>
                            <el-col :span="4"><span>{{$t('OrderView.l11')}}</span></el-col>
                            <el-col :span="4"><span>{{$t('OrderView.l12')}}</span></el-col>
                            <el-col :span="2"><span>{{$t('OrderView.l13')}}</span></el-col>
                            <el-col :span="3"><span>{{$t('OrderView.l14')}}</span></el-col>
                        </div>
                        <el-skeleton :rows="6" :loading="users_trade.length===0" animated></el-skeleton>
                        <div class="home-account-data">
                            <el-row v-for="(user, index) in users_trade" :key="index" style="width:100%;">
                                <div class="users-information-col" :style="tradeIndex === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="tradeIndex = index" @mouseleave="tradeIndex = -1">
                                    <el-col :span="4"><span>{{ user.separateOrderId }}</span></el-col>
                                    <el-col :span="5"><span>{{ user.landname }}</span></el-col>
                                    <el-col :span="2"><span>{{ user.ordertime }}</span></el-col>
                                    <el-col :span="4"><span>{{ user.paymeans }}</span></el-col>
                                    <el-col :span="4"><span>{{ user.totalprice }}</span></el-col>
                                    <el-col :span="2"><span>{{ user.landid }}</span></el-col>
                                    <el-col :span="3"><span>{{ user.landid }}</span></el-col>
                                </div>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full flex flex-col" ref="canvas2" style="margin-top:30px;" v-show="tab2===2">
            <div class="developer-common-card-box">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">{{$t('TotalIncome.l14')}}</div>
                </div>
                <div class="echar" ref="charts2" style="height:300px;width:0;"></div>
            </div>
            <div class="developer-common-card-box" style="margin-top:30px;">
                <div class="flex flex-row w-full h-full">
                    <div class="form-item-cells">
                        <div style="height:40px;width:100%;color: #788AA1;">
                            <el-col :span="3"><span>{{$t('OrderView.l8')}}</span></el-col>
                            <el-col :span="5"><span>{{$t('OrderView.l9')}}</span></el-col>
                            <el-col :span="3"><span>{{$t('OrderView.l10')}}</span></el-col>
                            <el-col :span="4"><span>{{$t('OrderView.l11')}}</span></el-col>
                            <el-col :span="4"><span>{{$t('OrderView.l12')}}</span></el-col>
                            <el-col :span="2"><span>{{$t('OrderView.l13')}}</span></el-col>
                            <el-col :span="3"><span>{{$t('OrderView.l14')}}</span></el-col>
                        </div>
                        <el-skeleton :rows="6" :loading="loading" animated></el-skeleton>
                        <div class="w-full h-full flex justify-center items-center text-lg" v-if="!loading && users_sell.length === 0">{{ $t('all.暂无交易数据') }}</div>
                        <div class="home-account-data">
                            <el-row v-for="(user, index) in users_sell" :key="index" style="width:100%;">
                                <div class="users-information-col" :style="tradeIndex2 === index ? 'background-color: rgba(22, 93, 255, 0.05);' : ''" @mouseover="tradeIndex2 = index" @mouseleave="tradeIndex2 = -1">
                                    <el-col :span="3"><span>{{ user.separateOrderId }}</span></el-col>
                                    <el-col :span="5"><span>{{ user.landname }}</span></el-col>
                                    <el-col :span="3"><span>{{ user.ordertime }}</span></el-col>
                                    <el-col :span="4"><span>{{ user.paymeans }}</span></el-col>
                                    <el-col :span="4"><span>{{ user.totalprice }}</span></el-col>
                                    <el-col :span="2"><span>{{ user.landid }}</span></el-col>
                                    <el-col :span="3"><span>{{ user.landid }}</span></el-col>
                                </div>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.$http.get('/v1/order/income-data') // 获取收益数据
            .then(res => {
                this.user_data = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        this.$http.get('/v1/business/select-totalincome') // 获取十天内收益趋势折线图数据
            .then(res => {
                this.tradeIncome = res.data.data;
            }).catch(error => {
                // 处理错误
            });
        // 开发商订单接口
        this.$http.get('/v1/business/select-transaction-list')
            .then(res => {
                this.users_sell = res.data.data;
                this.loading = false;
            }).catch(error => {
                // 处理错误
            });
    },
    mounted() {
        this.initChart();
        this.initChart2();
    },
    data() {
        return {
            tab1: 1, // 第一标签有两个，收入数据和价格数据
            user_data: {
                买家数量: 0,
                总收益: 0,
                昨日新增收益: 0,
                平均交易额: 0,
                昨日平均交易额: 0,
                交易土地数据: 0,
                单位土地均价: 0,
                当月单位土地均价: 0
            },
            tab2: 1, // 第e二标签有两个，收益和平均交易额
            tradeIncome: [{
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                date: "",
                income: 0.0
            },
            {
                wholeincome: 0.0,
                segmentation: 10,
                Listing: 2,
                Sold: 1
            }], // 收益数据
            chartInstance: null, // 折线图
            users_trade: [],
            tradeIndex: -1,
            users_sell: [], // 十条最近交易数据
            loading: true, // 骨架屏
            tradeIndex2: -1,
        }
    },
    methods: {
        isTab1(n) { // 确定第一标签的选中
            if(n === this.tab1) {
                return 'sell-list-tab-select'
            } else {
                return
            }
        },
        isTab2(n) { // 确定第二标签的选中
            if(n === this.tab2) {
                return 'sell-list-tab-select'
            } else {
                return
            }
        },
        initChart(){
            // 获取卡片宽度并设置echarts的画布宽度
            const myDiv = this.$refs.canvas;
            const width = myDiv.clientWidth;
            this.$refs.charts.style.width = width.toString() + 'px';
            // 绘制echarts
            this.chartInstance = this.$echarts.init(this.$refs.charts);
            let dates = []; // x轴
            let incomes = []; // y轴
            for (let i=6;i>=0;i--) {
                dates.push(this.tradeIncome[i].date);
                incomes.push(this.tradeIncome[i].income);
            }
            //配置echarts
            const option={
                tooltip: { //echarts提示框配置
                    trigger: 'item', // 触发类型，可选值有 'item'（数据项图形触发）和 'axis'（坐标轴触发）。默认为 'item'。
                    position: 'inside', // 提示框位置，可选值有 'auto'（自动计算位置）、'inside'（数据项内部）、'insideLeft'（数据项左侧）、'insideRight'（数据项右侧）、'insideTop'（数据项顶部）、'insideBottom'（数据项底部）。
                },
                xAxis: {
				    data: dates
			    },
                yAxis:{         //yAxis（Y轴）是配置图表的垂直轴。它用于定义数据在垂直方向上的刻度和标签。
                    type: 'value', // 设置y轴的类型为数值型
                    show: true // 设置y轴是否显示
                },
                series:{        //配置项
                    name: this.$t('TotalIncome.l15'),
				    type: 'line',
                    smooth: true,
				    data: incomes
                },
            }
            this.chartInstance.setOption(option);
        },
        initChart2(){
            // 获取卡片宽度并设置echarts的画布宽度
            const myDiv = this.$refs.canvas2;
            const width = myDiv.clientWidth;
            this.$refs.charts.style.width = width.toString() + 'px';
            // 绘制echarts
            this.chartInstance = this.$echarts.init(this.$refs.charts);
            let dates = []; // x轴
            let incomes = []; // y轴
            for (let i=6;i>=0;i--) {
                dates.push(this.tradeIncome[i].date);
                incomes.push(this.tradeIncome[i].income);
            }
            //配置echarts
            const option={
                tooltip: { //echarts提示框配置
                    trigger: 'item', // 触发类型，可选值有 'item'（数据项图形触发）和 'axis'（坐标轴触发）。默认为 'item'。
                    position: 'inside', // 提示框位置，可选值有 'auto'（自动计算位置）、'inside'（数据项内部）、'insideLeft'（数据项左侧）、'insideRight'（数据项右侧）、'insideTop'（数据项顶部）、'insideBottom'（数据项底部）。
                },
                xAxis: {
				    data: dates
			    },
                yAxis:{         //yAxis（Y轴）是配置图表的垂直轴。它用于定义数据在垂直方向上的刻度和标签。
                    type: 'value', // 设置y轴的类型为数值型
                    show: true // 设置y轴是否显示
                },
                series:{        //配置项
                    name: '收益(元)',
				    type: 'line',
                    smooth: true,
				    data: incomes
                },
            }
            this.chartInstance.setOption(option);
        },
    },
}
</script>

<style>
/* ------------------------------------------第三栏-交易列表------------------------------------------- */
.form-item-cells {
    max-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}
.home-account-data {
    max-height: 160px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.users-information-col {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
}
.users-information-col > div {
    margin: auto;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.users-information-col-span {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>