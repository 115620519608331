<template>
  <div class="developer-common-safe">
      <div class="developer-common-safe-title">
        {{ $t('AccountSafe.account') }}
      </div>
      <div class="flex flex-row">
        <div class="h-full w-1/2">
          <MyEmail></MyEmail>
        </div>
        <div class="h-full w-1/2">
          <MyPhone></MyPhone>
        </div>
      </div>
  </div>
</template>

<script>
import MyEmail from './AccountEmail.vue'
import MyPhone from './AccountPhone.vue'
export default {
  components: {
    MyEmail,
    MyPhone,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style>
.account-associate-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .account-associate-container {
        transform: scale(0.8);
        transform-origin: top left; /* 确保从中心缩放 */
    }
}
</style>
