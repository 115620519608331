<template>
  <div class="recommend-container">
    <div class="recommend-parent">
      <img src="../pImage/recommend.png" class="recommend-img">
    </div>
    <LandMarket></LandMarket>
  </div>
</template>

<script>
import LandMarket from "./LandRecommended";
export default {
  components: {
    LandMarket,
  },
  methods: {
    openLoginModal() {
      this.$parent.openLoginModal();
    },
  },
}
</script>

<style scoped>
.recommend-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 1024px) or (max-height: 980px) {
    .recommend-container {
        transform: scale(0.8);
        transform-origin: top center; /* 确保从中心缩放 */
    }
    .recommend-parent {
      margin-top: 0;
    }
}
@media (max-width: 980px) or (max-height: 720px) {
    .recommend-container {
        transform: scale(0.6);
        transform-origin: top center; /* 确保从中心缩放 */
    }
}
.recommend-parent {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.recommend-img {
  height: 250px;
  width: 1250px;
  object-fit: fill;
  border-radius: 8px;
}
</style>