<template>
    <!-- 页面背景-body之下第一层布置 -->
    <div class="main_container">
        <div class="header-container">
            <!-- logo -->
            <div class="logo_container">
                <img src="../icons/logo.png" class="logoImg_container">
            </div>
            <el-button class="button-login" @click="login">{{$t('MobileHomePage.button')}}</el-button>
            <div class="header-changeLanguage" @click="changeLanguage" @mouseover="changeCursor">
                <span :style="$i18n.locale === 'en'? 'color: blue' : ''">EN</span>
                <span>&nbsp;|&nbsp;</span>
                <span :style="$i18n.locale === 'zh'? 'color: blue' : ''">中文</span>
            </div>
        </div>
        <!-- 给登录页面设置一个块，里面包含logo、公司名、登录框，这样可以作为父块让它们水平垂直居中 -->
        <div class="form_container">
            <section class="slider">
                <div class="main-Image-text">
                    <span class="font-family-normal p-2 text-4xl font-bold animate__animated animate__fadeInUp">{{$t('computerlogin.introduce1')}}</span>
                    <span class="font-family-normal p-2 text-4xl font-bold mt-4 animate__animated animate__fadeInUp">{{$t('computerlogin.introduce2')}}</span>
                    <span class="font-family-normal text-2xl mt-16 animate__animated animate__fadeInUp">{{$t('computerlogin.alert')}}</span>
                </div>
            </section>
            <!-- 宣传图 -->
            <div class="main-propagate-box" v-for="(item,index) in images" :key="index" :class="index%2===0?'main-propagate-box-page-blue':''">
                <div class="main-propagate-introduce-box animate__animated" :class="index%2===0?'animate__fadeInLeft':'animate__fadeInRight'" v-if="$i18n.locale === 'zh'">
                    <span class="Image-propagate-text-blue">{{$t('MobileHomePage.view' + index + '1')}}</span>
                    {{$t('MobileHomePage.view' + index + '2')}}
                </div>
                <div class="main-propagate-introduce-box animate__animated" :class="index%2===0?'animate__fadeInLeft':'animate__fadeInRight'" v-if="$i18n.locale === 'en'">
                    {{$t('MobileHomePage.view' + index)}}
                </div>
                <div class="BootstraDiagram">
                    <img :src="item" style="object-fit: cover;">
                </div>
            </div>
        </div>
        <!-- 底部生产编号等位置 -->
        <div class="footer_container">
            <a href="mailto:info@datuland.com?subject=Hello" class="p-2 font-family-normal mt-6 footer-text-contact">{{$t('MobileHomePage.contact')}}</a>
            <span class="font-family-normal mt-6 footer-text-copyright">{{ $t('computerlogin.copyright') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            images: [ // 首页轮播图
                require('@/images/propagandizeImage1.jpg'),
                require('@/images/propagandizeImage2.jpg'),
                require('@/images/propagandizeImage3.jpg'),
                require('@/images/propagandizeImage4.jpg'),
            ],
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        changeLanguage() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'zh'
                localStorage.setItem('language', 'zh'); // 存储语言选择到localStorage
            } else {
                this.$i18n.locale = 'en'
                localStorage.setItem('language', 'en'); // 存储语言选择到localStorage
            }
            document.title = this.$t('pageTitle')
        },
        login() {
            this.$router.push({
                name: 'UserPcHomePage',
                params: {
                    login: true,
                }
            })
        }
    },
}
</script>

<style scoped>
/* 主页区域设置 */
.main_container {
    min-height: 100vh;
    /* 背景图片 */
    background-image: linear-gradient(to bottom, #0083e0 10%, #ffffff);
    background-size: cover;
    /* flex布局，子元素垂直水平居中 */
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.header-container {
    height: 100px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}
/* logo区域标识 */
.logo_container {
    display: inline-block;
    text-align: center;
    margin-top: 26px;
    margin-left: 10px;
    font-size: 20px;
}
/* logo图片设置 */
.logoImg_container {
    display: inline-block;
    margin: 0;
    height: 48px;
    object-fit: cover;
}
.header-changeLanguage {
    font-size: 24px;
    flex: 1;
    text-align: right;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* 登录按钮 */
.button-login {
    font-size: 24px;
    position: absolute;
    right: 200px;
    top: 50%;
    transform: translate(0, -50%);
}
/* 表单设置 */
.form_container {
  text-align: center;  /* 如果子元素是`inline`或`inline-block`类型的元素，则可以通过设置父元素的`text-align`属性为`center`来实现水平居中。 */
  margin-top: 100px;
  padding: 0;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  flex-direction: column;
}
.main-Image-text {
    position: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    color: #ffffff;
}
.slider {
  position: relative;
}

.slider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #1d192c;
  opacity: 0.8;
}

.slider {
    width: 100%;
    background: url("../images/slider-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 10% 0%;
    padding: 200px 0;
    position: relative;
}
@media (max-width: 992px) {
  .slider {
    padding: 150px 0;
  }
}
.slider .block {
  color: #E3E3E4;
  text-align: center;
}
.slider .block h1 {
  font-weight: 100;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 10px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .slider .block h1 {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 768px) {
  .slider .block h1 {
    font-size: 32px;
    line-height: 42px;
  }
}
.slider .block p {
  margin-bottom: 30px;
  color: #b9b9b9;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}
@media (max-width: 992px) {
  .slider .block p {
    font-size: 16px;
  }
}
/* 左半边引导图设置 */
.main-propagate-box {
    height: 1000px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
}
.main-propagate-box-page-blue {
    background-color: #f5f8fc;
}
.main-propagate-introduce-box {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    padding: 20px;
}
.Image-propagate-text-blue {
    color: #248aff;
}
.BootstraDiagram {
    width: 1000px;
    padding: 25px;
}
.el-carousel {
    height: 535px;
}
.el-carousel >>> .el-carousel__container {
    height: 535px;
}
/* 最底部更多信息 */
.footer_container {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.font-family-normal {
    font-family: "Roboto", "Droid Serif", Georgia, "Times New Roman", "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "WenQuanYi Micro Hei","Microsoft Yahei", serif
}
.footer-text-contact {
    font-size: 14px;
    color: #313131
}
.footer-text-copyright {
    font-size: 14px;
    color: #919191
}
</style>