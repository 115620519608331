<template>
    <div class="home-page">
        <div class="home-page-header" v-if="selectPage === '1'">
            <div class="flex flex-row w-full" style="margin-top:10px;padding:16px;">
                <img src="../icons/logo.png" style="object-fit: contain;height: 25px;cursor: pointer;"/>
                <div class="mobile-goback-title" style="color:#FFF">{{ showTitle() }}</div>
                <div class="flex-grow flex justify-end">
                    <div class="relative" @click="$router.push('/mobile/message')">
                        <div class="mobilehome-unread" v-if="messages !== 0"></div>
                        <img src="../mImage/login-ding.png" class="justify-end" style="height:25px;width:25px;">
                    </div>
                </div>
            </div>
        </div>
        <div class="home-page-header" style="background-color:#F4F5F9;" v-else>
            <div class="flex flex-row w-full" style="margin-top:10px;padding:16px;">
                <img src="../icons/logo.png" style="object-fit: contain;height: 25px;cursor: pointer;"/>
                <div class="mobile-goback-title">{{ showTitle() }}</div>
                <div class="flex-grow flex justify-end">
                    <div class="relative" @click="$router.push('/mobile/message')">
                        <div class="mobilehome-unread" v-if="messages !== 0"></div>
                        <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
                    </div>
                </div>
            </div>
        </div>
        <div class="home-page-main">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
        <div class="home-page-bottom">
            <div class="home-page-bottom-box">
                <!-- 主页图标 -->
                <div class="home-page-bottom-row">
                    <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToHome">
                        <img src="../mImage/house-g.png" class="home-page-bottom-icon" v-show="selectPage !== '1'" />
                        <img src="../mImage/house-p.png" class="home-page-bottom-icon" v-show="selectPage === '1'" />
                        <span :class="selectPage==='1'?'home-page-text-select':'home-page-text'">{{$t('MobileHome.page1')}}</span>
                    </div>
                </div>
                <!-- 土地市场图标 -->
                <div class="home-page-bottom-row">
                    <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToShop">
                        <img src="../mImage/market-g.png" class="home-page-bottom-icon" v-show="selectPage !== '2'" />
                        <img src="../mImage/market-p.png" class="home-page-bottom-icon" v-show="selectPage === '2'" />
                        <span :class="selectPage==='2'?'home-page-text-select':'home-page-text'">{{$t('MobileHome.page2')}}</span>
                    </div>
                </div>
                <!-- 购物车图标 -->
                <div class="home-page-bottom-row">
                    <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToCart">
                        <img src="../mImage/shopping-trolley-g.png" class="home-page-bottom-icon" v-show="selectPage !== '3'" />
                        <img src="../mImage/shopping-trolley-p.png" class="home-page-bottom-icon" v-show="selectPage === '3'" />
                        <span :class="selectPage==='3'?'home-page-text-select':'home-page-text'">{{$t('MobileHome.page3')}}</span>
                    </div>
                </div>
                <!-- 个人主页图标 -->
                <div class="home-page-bottom-row">
                    <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToInfor">
                        <img src="../mImage/user-g.png" class="home-page-bottom-icon" v-show="selectPage !== '4'" />
                        <img src="../mImage/user-p.png" class="home-page-bottom-icon" v-show="selectPage === '4'" />
                        <span :class="selectPage==='4'?'home-page-text-select':'home-page-text'">{{$t('MobileHome.page4')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.selectPage = this.$route.query.index;
    },
    watch: {
        "$route.query.index": function (newIndex) {
            this.selectPage = newIndex;
        },
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            selectPage: "1", // 选中的页面
            messages: 0, // 未读消息数量
        };
    },
    methods: {
        showTitle() {
            if (this.selectPage === '1') {
                return this.$t('MobileHome.page1');
            } else if (this.selectPage === '2') {
                return this.$t('MobileHome.page2');
            } else if (this.selectPage === '3') {
                return this.$t('MobileHome.page3');
            } else if (this.selectPage === '4') {
                return this.$t('MobileHome.page4');
            }
        },
        TurnToHome() {
            this.selectPage = "1";
            this.$router.push("/mobile/home-page?index=1").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
        TurnToShop() {
            this.selectPage = "2";
            this.$router.push("/mobile/land-market?index=2").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
        TurnToCart() {
            this.selectPage = "3";
            this.$router.push("/mobile/shopping-cart?index=3").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
        TurnToInfor() {
            this.selectPage = "4";
            this.$router.push("/mobile/my-infor?index=4").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
    },
};
</script>

<style scoped>
.home-page {
    /* 第一层 */
    height: 100vh;
    width: 100%;
    position: relative;
    background-image: linear-gradient(to bottom, #0083e0 10%, #f9fafb);
}
.home-page-header {
    height: 366px;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
}
.home-page-header-logo {
    /* logo区域外层 */
    margin-left: 20px;
    margin-top: 10px;
}
.home-page-bottom {
    /* 底部 */
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: 16px;
}
.home-page-bottom-box {
    height: 64px;
    width: 100%;
    padding: 11px 31px;
    background-color: #ffffff;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 10px 50px 0px rgba(207, 207, 207, 0.5);
}
.home-page-bottom-row {
    /* 底部分区 */
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
}
.home-page-text {
    text-align: center;
    font-size: 12px;
    color: #000000;
}
.home-page-text-select {
    color: #3662EC;
}
.home-page-bottom-icon {
    /* 图标 */
    height: 25px;
    width: 25px;
}
.home-page-main {
    /* 主页区域 */
    position: absolute;
    top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
}
</style>
