<template>
    <div class="mobile-detail-home bg-gray-50">
        <van-nav-bar class="bg-gray-50" :title="$t('MobilePurchaseDetails.title')" :left-text="$t('MobilePurchaseDetails.back')" left-arrow @click-left="goBack" />
        <div class="flex flex-col animate__animated animate__fadeInUp" style="padding: 20px">
            <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="mobile-detail-map mb-3 mx-1 shadow-lg rounded-lg  border-3 border-gray"></canvas>

            <!-- 挂牌信息 -->
            <div class="relative items-center rounded-lg shadow-md bg-white px-5 py-4 mt-0 mb-3">
                <div class="absolute top-2 left-3 text-sm text-gray-400">
                    {{$t('MobilePurchaseDetails.infor')}}
                </div>
                <div class="mt-3">
                    <div class="flex justify-between mt-5">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.orderno')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.orderInfo.separateOrderId }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.ordertime')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.orderInfo.ordertime }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.totalprice')}}</p>
                        <p class="text-sm text-right" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</p>
                        <p class="text-sm text-right" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.unitlandid')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.unitlandid }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.landname')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.landname }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.sellerid')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.orderInfo.sellerid }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.certificate')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.needpaper?$t('MobilePurchaseDetails.yes'):$t('MobilePurchaseDetails.no') }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.address')}}</p>
                        <p class="text-sm text-right">{{ LandInfor.originalLand.detailedaddress }}</p>
                    </div>
                </div>
            </div>
            <!-- 详细信息及原始土地信息 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-0 mb-3">
                <van-tabs v-model="tabControl">
                    <van-tab title="详情信息">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.area')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="原始土地信息">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.landId')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.ownerid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-1 mb-1 pt-1">
                <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.landRights')}}</span>
                <div class="flex flex-col mb-3">
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobilePurchaseDetails.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobilePurchaseDetails.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobilePurchaseDetails.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobilePurchaseDetails.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="sticky bottom-0 w-full py-3 flex items-center justify-end">
            <button :class="'px-4 mr-5 py-2  shadow-md  cursor-pointer text-sm rounded-3xl w-auto '
            +(orderStatus===''?'text-grey-400 hover:bg-yellow-500 bg-yellow-300 ':'bg-gray-200 text-black')"
                    @click="onRefund">{{orderStatus===''?$t('MobilePurchaseDetails.refund'):orderStatus}}</button>
            <button class="px-4 mr-5 py-2 shadow-md text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto"
                    @click="TalkSeller">{{$t('MobilePurchaseDetails.private')}}</button>
        </div> -->

        <!-- 退款弹窗 -->
        <van-popup v-model="showRefund" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.refundNum')}}</span>
                        <span class="text-gray-600 text-lg my-2" v-if="$setPayment === 'am'">${{ LandInfor.totalprice }}</span>
                        <span class="text-gray-600 text-lg my-2" v-else-if="$setPayment === 'zh'">￥{{ LandInfor.totalprice * $exchangeRate }}</span>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md ">{{$t('MobilePurchaseDetails.reason')}}</span>
                        <van-field
                            v-model="refundReason"
                            :placeholder="$t('MobilePurchaseDetails.reasonInput')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                        />
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showRefund = false">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="RefundConfirm">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: "MobilePurchaseDetails",
    created() {
        // 不要忘了获取传输过来的土地信息
        this.unitlandid = this.$route.query.unitlandid;
        this.orderid = this.$route.query.orderid;
    },
    mounted() {
        this.canvasSize = this.$el.clientWidth;
        // 获取土地详情信息
        if (this.flag) {
            this.flag = false;
            this.$http
                .post("/v1/myland/show-mylandOne",
                     {
                        unitlandid: this.unitlandid,
                        orderid: this.orderid,
                    },
                )
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.LandInfor.createtime = this.LandInfor.createtime.split("")[0];
                    const date = new Date(this.LandInfor.orderInfo.ordertime);// 格式化日期
                    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;// 格式化时间
                    const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                    this.LandInfor.orderInfo.ordertime = `${formattedDate} ${formattedTime}`;
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        // 获取订单状态
        this.$http
            .post("/v1/order/select-process-status",
                {
                    separateOrderId: this.orderid,
                },
            )
            .then((res) => {
                if(res.data.code === 200)
                {
                    this.orderStatus = res.data.data.流程状态;
                }

                if (this.orderStatus == 1) {
                    this.haveAddIn = false;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    data() {
        return {
            selectPage: 2, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            orderid: null, // 订单id
            flag: true, // 防抖
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                orderInfo:{
                    ordertime: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },
            showRefund: false, // 退款弹窗
            refundReason: "", // 退款原因
            orderStatus: '', // 订单状态
            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: 0, // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
        };
    },
    methods: {
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize =this.$el.clientWidth / 22;
            // 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);

            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        onRefund(){
            if (this.orderStatus !== '') {
                return;
            }
            this.showRefund = true;
        },
        RefundConfirm(){
            // 退款确认
            this.$http
                .post("/v1/order/request-refund", {
                    separateOrderId: this.orderid,
                    refundreason: this.refundReason,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MobilePurchaseDetails.alertSeccess'));
                        this.showRefund = false;
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(res.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.LandInfor.originallandid + "&merch=" + this.LandInfor.id + "&receive=" + this.LandInfor.orderInfo.sellerid,
            });
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
<style>
</style>