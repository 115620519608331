<template>
    <div class="developer-common-container">
        <div class="developer-detail-card">
            <canvas ref="bigmap" :height="canvasSize" :width="canvasSize" class="developer-detail-box-canvas"></canvas>
            <div class="developer-detail-box absolute">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">{{$t('LandSellDetail.name')}}:{{ LandInfor.mergelandname }}</div>
                    <button class="developer-common-card-button" style="margin-left:100px;" @click="gotoland" v-show="LandInfor.id">{{$t('LandSellDetail.goin')}}</button>
                </div>
                <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.blocks')}}:</div>{{ LandInfor.landblocks }}</div>
                <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.createdate')}}:</div>{{ LandInfor.createdate }}</div>
                <div class="flex flex-row" style="margin-top:30px;" v-if="LandInfor.onSale.totalprice">
                    <div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.price')}}:</div>
                    <span v-if="$setPayment === 'am'">${{ LandInfor.onSale.totalprice }}</span>
                    <span v-else-if="$setPayment === 'zh'">￥{{ LandInfor.onSale.totalprice * $exchangeRate }}</span>
                </div>
                <div class="flex flex-row flex-grow items-end" v-show="LandInfor.status === '未挂牌'">
                    <button class="developer-common-card-button" style="background-color:#165DFF;color: #FFFFFF;" @click="$refs.landsale.codeNeedOrNot('挂牌')">{{$t('all.sale')}}</button>
                </div>
                <div class="flex flex-row flex-grow items-end" v-show="LandInfor.status === '已挂牌'">
                    <button class="developer-common-card-button" style="background-color:#165DFF;color: #FFFFFF;" @click="$refs.landsale.codeNeedOrNot('修改价格')">{{$t('LandSellDetail.changeprice')}}</button>
                    <button class="developer-common-card-button ml-10" @click="$refs.landsale.codeNeedOrNot('下架')">{{$t('LandSellDetail.unsale')}}</button>
                </div>
            </div>
        </div>
        <!-- 分块土地详情和原始土地详情 -->
        <div class="flex flex-row" style="margin-top:30px;">
            <div class="developer-common-card-box" style="width:50%;">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">{{$t('LandSellDetail.unitdetail')}}</div>
                </div>
                <div class="flex flex-col" style="padding: 20px;">
                    <div class="flex flex-row"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.area')}}:</div>{{ LandInfor.communitynumber }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandMap1.l6')}}:</div>{{ LandInfor.landcoordinates }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.landpicture')}}:</div>{{ LandInfor.originalLand.height }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.landlength')}}:</div>{{ LandInfor.originalLand.landlength }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandMap1.l3')}}:</div>{{ LandInfor.createdate }}</div>
                </div>
            </div>
            <div class="developer-common-card-box" style="width:50%;margin-left:30px;">
                <div class="developer-common-card-title-parent">
                    <div class="developer-common-card-title">{{$t('LandSellDetail.origindetail')}}</div>
                </div>
                <div class="flex flex-col" style="padding: 20px;">
                    <div class="flex flex-row"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.originallandid')}}:</div>{{ LandInfor.originalLand.originallandid }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.originallandname')}}:</div>{{ LandInfor.originalLand.originallandname }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('MobilePurchaseDetails.developer')}}:</div><span v-show="LandInfor.id">{{ company }}</span></div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.detailedaddress')}}:</div>{{ LandInfor.originalLand.detailedaddress }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.geo')}}:</div>{{ LandInfor.originalLand.geographicalcoordinates }}</div>
                </div>
            </div>
        </div>
        <!-- 挂牌详情 -->
        <div class="developer-common-card-box" style="margin-top:30px;" v-if="LandInfor.status === '已挂牌'">
            <div class="developer-common-card-title-parent">
                <div class="developer-common-card-title">{{$t('LandSellDetail.saledetail')}}</div>
            </div>
            <div class="flex flex-col" style="padding: 20px;">
                <div class="flex flex-col" style="padding: 20px;">
                    <div class="flex flex-row"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.mergename')}}:</div>{{ LandInfor.onSale.landname }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.price')}}:</div>{{ LandInfor.onSale.pricenum }}</div>
                    <div class="flex flex-row" style="margin-top:30px;"><div style="color: #4E5969;width:200px;">{{$t('LandSellDetail.saletime')}}:</div>{{ LandInfor.onSale.createtime }}</div>
                </div>
            </div>
        </div>
        <!-- 地权解释 -->
        <div class="developer-common-card-box" style="margin-top:30px;">
            <div class="developer-common-card-title-parent">
                <div class="developer-common-card-title">{{$t('LandSellDetail.tip')}}</div>
            </div>
            <div class="flex flex-col" style="padding: 20px;">
                <span style="padding: 20px;">{{$t('LandSellDetail.tip1')}}</span>
                <span style="padding: 20px;">{{$t('LandSellDetail.tip2')}}</span>
                <span style="padding: 20px;">{{$t('LandSellDetail.tip3')}}</span>
            </div>
        </div>

        <!-- 挂牌/修改价格/摘牌弹窗 -->
        <LandSale ref="landsale" :LandInfor="saleLand"></LandSale>

    </div>
</template>

<script>
import LandSale from '../LandSaleModal.vue'
export default {
    components: {
        LandSale
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.unitlandid = this.$route.query.land;
    },
    computed: {
        company() {
            return this.$store.state.developer.userInfo.corporationname;
        },
    },
    mounted() {
        // 获取土地详情信息
            this.$http.get("/v1/original-land/select-unitland-details?unitlandid=" + this.unitlandid)
                .then((res) => {
                    if (res.data.data) {
                        this.LandInfor = res.data.data;
                        this.saleLand.unitlandid = this.unitlandid;
                        this.saleLand.landblocks = this.LandInfor.landblocks;
                        this.saleLand.mergelandname = this.LandInfor.mergelandname;
                        let time1 = setTimeout(() => {
                            // 等待 100 毫秒后再绘制
                            const canvas = this.$refs.bigmap; // 获取 canvas 元素
                            canvas.style.backgroundImage = "url(" + this.LandInfor.originalLand.landpicture + ")";
                            this.drawGrid();
                            this.drawLand();
                            clearInterval(time1);
                        }, 100);
                    } else {
                        this.$toast.fail('404 NOT FOUND');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
    },
    data() {
        return {
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            saleLand: {
                unitlandid: '',
                landblocks: 0,
                mergelandname: '',
            },
            LandInfor: {
                id: null,
                originallandid: "",
                mergelandname: "",
                landnumber: "",
                communitynumber: "",
                landblocks: 16,
                landcoordinates: "",
                virtualownership: "",
                remark: null,
                status: "",
                createdate: "",
                starttime: null,
                endtime: null,
                pagesize: 0,
                pagenum: 0,
                start: 0,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                "onSale": {
                    "id": 88,
                    "originallandid": "",
                    "unitlandid": null,
                    "landname": null,
                    "sellerid": null,
                    "seller": null,
                    "salesmethods": null,
                    "expirydate": null,
                    "unitlandprice": null,
                    "pricenum": null,
                    "unitlandminprice": null,
                    "minimumprice": null,
                    "blocks": null,
                    "totalprice": 0,
                    "unitpicture": null,
                    "createtime": null,
                    "reasondelisting": null,
                    "status": "未挂牌",
                    "remark": null,
                    "starttime": null,
                    "endtime": null,
                    "pagesize": 0,
                    "pagenum": 0,
                    "start": 0,
                    "originalLand": null,
                    "unitLand": null
                }
            },

            canvasSize: 300,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: "true", // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
            users: {
                avatarUrl: require("../assets/no-image.png"),
            },
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1)
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize = this.canvasSize / 22;// 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        // 进入地图
        gotoland() {
            this.$router.push({
                name: "Thirdland",
                query: {
                    land: this.LandInfor.originallandid,
                    area: this.LandInfor.communitynumber
                },
                params: {
                    id: this.LandInfor.onSale.unitlandid
                }
            });
        },
    },
}
</script>

<style>
.developer-detail-fill {
    min-height: 100%;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    background-color: #d5fdffba;
}
.developer-detail-card {
    width: 100%;
    padding: 20px 50px;
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(22, 93, 255, 0.05);
    border-radius: 30px;
    height: 350px;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    position: relative;
}
.developer-detail-box-in {
    height: 400px;
    width: 100%;
    position: relative;
    background-color: #eeeeee;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}
.developer-detail-box-canvas {
    height: 300px;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.developer-detail-box-row {
    padding: 20px;
    display: flex;
    flex-direction: row;
}
/* 文本在框内的位置 */
.developer-detail-box {
    left: 400px;
    top: 25px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
}
/* 地理位置坐标的小标签 */
.developer-detail-box-icon {
    position: absolute;
    top: 25px;
    height: 50px;
    width: 100px;
    border-radius: 12px;
    background-color: #eeeeee;
}
.developer-detail-buy-button {
    height: 40px;
    width: 150px;
    background-color: #ddd3fd;
    color: #fff;
    border-radius: 6px;
}
</style>