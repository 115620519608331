<template>
    <div class="developer-common-container">
        <div class="developer-common-card-box">
            <div class="developer-common-card-title-parent">
                <div class="developer-common-card-title">{{$t('myCompany.title')}}</div>
            </div>
            <div class="flex flex-col p-10 w-full">
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.companyName')}}:</div>
                    <span>{{ company.corporationname }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.certificateNumber')}}:</div>
                    <span>{{ company.housingownership }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.companyContactName')}}:</div>
                    <span>{{ company.businessname }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.companyPhoneNumber')}}:</div>
                    <span>{{ company.businessphone }}</span>
                </div>
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.idCard')}}:</div>
                    <div class="company-register-input-right">
                        <!-- 身份证正面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" :content="$t('myCompany.seeImage')" placement="top-end">
                                <img :src="company.businesscard1" alt="身份证正面照" class="upload-form-item-img-i" @click="show(1)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isfrontIdImg 变量决定 -->
                            <div class="lightbox" v-if="isfrontIdImg" @click="hide">
                                <img class="full-image" :src="company.businesscard1"/>
                            </div>
                        </div>
                        <!-- 身份证背面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" :content="$t('myCompany.seeImage')" placement="top-end">
                                <img :src="company.businesscard2" alt="身份证背面照" class="upload-form-item-img-i" @click="show(2)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isreverseIdImg 变量决定 -->
                            <div class="lightbox" v-if="isreverseIdImg" @click="hide">
                                <img class="full-image" :src="company.businesscard2"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 公司注册信息与注册证明 -->
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.companyRegistration')}}:</div>
                    <div class="company-register-input-right ml-3">
                        <a class="company-register-input-link" :href="company.companyregistration" target="_blank">{{$t('CompanyInfor.download')}}</a>
                    </div>
                </div>
                <!-- 平台合同扫描件 -->
                <div class="land-review-item">
                    <div class="land-review-item-label">{{$t('myCompany.platformContract')}}:</div>
                    <div class="company-register-input-right ml-3">
                        <a class="company-register-input-link" :href="company.platformcontract" target="_blank">{{$t('CompanyInfor.download')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$http.get('/v1/business/select-mycompany')
            .then(res => {
                this.company = res.data.data;
            }).catch(error => {
                // 处理错误
            });
    },
    data() {
        return {
            company: {
                businessid: '', // 企业编号
                corporationname: '', // 企业名称
                housingownership: '', // 房屋所有权证编号
                businessname: '', // 企业联络人姓名
                businessphone: '', // 企业联系电话
                businesscard1: require('../assets/no-image.png'), // 身份证正面照
                businesscard2: require('../assets/no-image.png'), // 身份证反面照
                companyregistration: null, // 公司注册信息与注册证明
                platformcontract: null, // 平台合同扫描件
            },
            isfrontIdImg: false,
            isreverseIdImg: false,
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 点击图片查看原图事件 */
        show(n) { // 点击图片显示弹窗
            if (n===1) {
                this.isfrontIdImg = true
            } else if (n===2) {
                this.isreverseIdImg = true
            }
        },
        hide() { // 点击弹框外部时，隐藏弹框
            this.isfrontIdImg = false;
            this.isreverseIdImg = false;
        },
    }
}
</script>

<style scoped>
.my-company {
    background-image: url('../developer/my-company.png');
    background-size: cover;
}
.land-review-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}
.land-review-item-label {
    font-size: 16px;
    color: #4E5969;
    width: 200px;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    padding: 10px;
    margin-right: 30px;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* ------------------------------图片弹窗------------------------------- */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  z-index: 99; /* 将弹框置于其他所有元素之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  max-width: 80%;
  max-height: 80%;
}
</style>