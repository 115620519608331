<template>
    <div class="main-container">
        <div class="left-chatmenu">
            <div class="admin-chat-search-parent">
                <div class="admin-chat-search">
                    <img src="../adminImage/admin-chat-search.png" style="height:30px;">
                    <input type="text" class="admin-chat-search-input" v-model="search" placeholder="搜索用户名">
                </div>
            </div>
            <el-skeleton :rows="10" :loading="loading" animated></el-skeleton>
            <div class="admin-service-no-search" v-if="!loading && chatThread.length === 0">
                <img src="../adminImage/admin-service-nosearch.png">
                <span>未搜索到结果~</span>
            </div>
            <div
                v-for="(apply, index) in chatThread"
                :key="index" class="left-chatmenu-chatthread animate__animated animate__fadeInUp"
                :style="selectServeId === apply.serviceid? 'background-color: #F3F7FF' : ''"
                @mouseover="changeCursor"
                @click="changeServeId(apply.serviceid)">
                <div class="left-chatmenu-chatthread-image">
                    <img :src="apply.avatarUrl" alt="头像" />
                </div>
                <div class="left-chatmenu-chatthread-name">{{ apply.fromname }}</div>
                <div class="left-chatmenu-chatthread-time">{{ apply.createdate }}</div>
                <div class="left-chatmenu-chatthread-message">
                    <span class="left-chatmenu-chatthread-message-unread" v-show="textIsUnRead(apply.fromid,apply.readstatus)">[未读]</span>
                    {{ apply.isUrl==='url'?'[图片]':apply.emailscontent }}
                </div>
                <div class="left-chatmenu-chatthread-icon circle-image" v-show="isUnRead(apply.fromid,apply.readstatus,apply.serviceid)">
                    <img style="background-color: red;" />
                </div>
            </div>
        </div>
        <chatMessage :messages="messages" :avatarUrl="avatarUrl" :ThreadId="selectServeId" v-show="selectServeId" ref="mychild"></chatMessage>
    </div>
</template>

<script>
import chatMessage from "../admin/ChatCustomer.vue";
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            userid: (state) => state.admin.adminInfo.administratorid,
        }),
    },
    components: {
        chatMessage,
    },
    mounted() {
        this.$http.get('/v1/csmail/csselect-admin-message')
            .then(res => {
                this.chatThread = res.data.data;
                this.loading = false;
            }).catch(error => {
                this.$toast.fail('主题消息获取失败，请刷新页面或重新登录');
                console.error('主题消息获取失败，请刷新页面或重新登录');
            });
        this.timer = setInterval(() => {  // 等待 100 毫秒后再绘制
            this.$http.get('/v1/csmail/csselect-admin-message')
            .then(res => {
                this.chatThread = res.data.data;
            }).catch(error => {
                this.$toast.fail('聊天信息更新失败，请刷新页面或重新登录');
                console.error('聊天信息更新失败，请刷新页面或重新登录')
                clearInterval(this.timer);
                this.timer = null;
            });
        }, 60000); // 每分钟进行一次
    },
    data() {
        return {
            loading: true, // 骨架屏
            selectServeId: null, // 选中的服务id
            timer: null, // 定时器
            example: [{
                id: 6175,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "你好，客服",
                createdate: "2023-08-23T16:00:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            }],
            chatThread: [], // 聊天主题列表，后端获取，客服需要获取多个客户发来的主题并进行回复，这里截取最新一条消息
            messages: [], // 单个主题的聊天信息，后端获取
            avatarUrl: null, // 头像url
            search: '', // 搜索内容
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        changeServeId(id) { // 选中该位置
            if (this.selectServeId === id) { return; }
            this.selectServeId = id;
            this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + id)
            .then(res => {
                this.messages = res.data.data;
                this.avatarUrl = res.data.msg;
                this.$refs.mychild.goBottom();
                // this.$store.dispatch("admin/refreshMessages");
            }).catch(error => {
                this.$toast.fail('聊天记录获取失败，请刷新页面或重新登录');
                console.error('聊天记录获取失败，请刷新页面或重新登录');
            });
        },
        refreshMessage() {
            this.$http.get('/v1/csmail/csselect-admin-message')
            .then(res => {
                this.chatThread = res.data.data;
            }).catch(error => {
                this.$toast.fail('主题消息更新失败');
                console.error('主题消息更新失败');
            });
            this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + this.selectServeId)
            .then(res => {
                this.messages = res.data.data;
                this.avatarUrl = res.data.msg;
                this.$refs.mychild.goBottom();
                // this.$store.dispatch("admin/refreshMessages");
            }).catch(error => {
                this.$toast.fail('聊天记录更新失败');
                console.error('聊天记录更新失败');
            });
        },
        textIsUnRead(fromid,unread) {
            // unread参数，true是已读，false是未读，换算到未读提醒，true是未读，false是已读
            if (this.userid === fromid) { // 我是发送方
                return false;
            } else {
                return !unread;
            }
        },
        isUnRead(fromid,unread,id) {
            // unread参数，true是已读，false是未读，换算到未读提醒，true是未读，false是已读
            if (this.userid === fromid) { // 我是发送方
                return false;
            } else if (this.selectServeId === id) {
                return false;
            } else {
                return !unread;
            }
        },
    },
    /* 生命周期结束时清除计时器 */
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
}
</script>

<style scoped>
.main-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}
/* 用户头像图片设为圆形显示 */
.left-chatmenu-chatthread-image {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 10px;
}
.left-chatmenu {
    width: 500px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
}
.left-chatmenu-chatthread {
    height: 100px;
    width: 95%;
    position: relative;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    border-bottom: 1px solid #eeeeee;
    border-radius: 6px;
}
.left-chatmenu-chatthread-name {
    position: absolute;
    left: 100px;
    top: 20px;
    font-size: 20px;
    max-width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.left-chatmenu-chatthread-time {
    position: absolute;
    right: 10px;
    top: 20px;
    font-size: 16px;
}
.left-chatmenu-chatthread-message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    position: absolute;
    left: 100px;
    top: 60px;
    max-width: 250px;
    font-size: 16px;
    color: #727272;
    word-wrap: break-word;
}
.left-chatmenu-chatthread-message-unread {
    color: blue;
}
.left-chatmenu-chatthread-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
.left-chatmenu-chatthread-icon-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

/* ----------------------------------左侧搜索栏样式--------------------------------- */
.admin-chat-search-parent {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px;
}
.admin-chat-search {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #000;
    padding: 5px;
    background-color: #fff;
}
.admin-chat-search-input {
    height: 30px;
    width: 200px;
    margin-left: 10px;
}
/* 为查询到任何主题 */
.admin-service-no-search {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.admin-service-no-search > img {
    height: 100px;
    width: 100px;
}
.admin-service-no-search > span {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
}
</style>