<template>
    <div class="h-full">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileChangePayPassword.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div v-if="showSet === null" class="flex justify-center items-center h-full">{{ $t('all.networkError') }}</div>
        <div v-else-if="showSet">
            <!--忘记/修改支付密码-->
            <div v-if="hasSetPassword">
                <div class="flex justify-center items-center">
                    <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.enterOldPassword')}}</div>
                </div>
                <van-password-input
                    :value="oldPassword"
                    :focused="showKeyboard"
                    :length="6"
                    class="mt-3 shadow-sm"
                    @focus="showKeyboard = true"
                />
                <div class="flex justify-end items-center">
                    <div class="text-blue-500 text-sm mr-5 mt-3" @click="onForgetPayPassword">{{$t('MobileChangePayPassword.forgetOldPassword')}}</div>
                </div>
                <van-number-keyboard
                    v-model="oldPassword"
                    :show="showKeyboard"
                    @blur="showKeyboard = false"
                />
            </div>
            <!-- 初次设置支付密码 -->
            <div v-else-if="firstInput && !hasSetPassword">
                <div  class="flex justify-center items-center animate__animated animate__flipInX">
                    <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.enterNewPassword')}}</div>
                </div>
                <van-password-input
                    :value="firstInputPassword"
                    :focused="showKeyboard"
                    :length="6"
                    class="mt-3 shadow-sm animate__animated animate__flipInX"
                    @focus="showKeyboard = true"
                />
                <van-number-keyboard
                    v-model="firstInputPassword"
                    :show="showKeyboard"
                    @blur="showKeyboard = false"
                />
            </div>
            <div v-else-if="!firstInput && !hasSetPassword">
                <!--重复输入-->
                <div class="flex justify-center items-center animate__animated animate__flipInX">
                    <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.resetPassword')}}</div>
                </div>
                <van-password-input
                    :value="secondInputPassword"
                    :focused="showKeyboard"
                    :length="6"
                    class="mt-3 shadow-sm animate__animated animate__flipInX"
                    @focus="showKeyboard = true"
                />
                <van-number-keyboard
                    v-model="secondInputPassword"
                    :show="showKeyboard"
                    @blur="showKeyboard = false"
                />
            </div>
        </div>
        
        
        <!-- 验证码修改支付密码 -->
        <div v-else-if="!showSet">
            <div class="flex justify-center items-center my-4">
                <div class="text-md text-center">{{$t('MobileChangePayPassword.verifyIdentity')}}</div>
            </div>
            <!-- 手机验证码验证 -->
            <div>
                <!-- 标题：验证码将发送到您的邮箱/手机号xxx -->
                <div class="flex items-center justify-center">
                    <span v-if="hideMobile===''" class="text-gray-500 text-sm mb-3 text-center">
                        {{$t('MobileChangePayPassword.tip2')}}{{hideMobile}}{{$i18n.locale === 'en'? $t('MobileChangePayPassword.tip3') : ''}}
                    </span>
                    <span v-else-if="hideEmail!==''" class="text-gray-500 text-sm mb-3 text-center">
                        {{$t('MobileChangePayPassword.tip1')}}{{ hideEmail }}{{$i18n.locale === 'en'? $t('MobileChangePayPassword.tip3') : ''}}
                    </span>
                </div>

                <!-- 新支付密码输入区 -->
                <div  class="flex justify-center items-center">
                    <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.newPassword')}}</div>
                </div>
                <van-password-input
                    :value="newPassword"
                    :focused="showKeyboard"
                    :length="6"
                    class="mt-3 shadow-sm animate__animated animate__flipInX"
                    @focus="showKeyboard = true"
                />

                <!-- 图像验证码输入区 -->
                <div class="flex items-center justify-center mt-6">
                    <span class="text-gray-500 text-sm">{{$t('MobileChangePayPassword.graphicCode')}}：</span>
                    <input v-model="imgCode" :placeholder="$t('MobileChangePayPassword.graphicCodeInput')" style="width: 110px" class="my-input " />
                    <img @click="loadCodeImg" class="h-8 w-20 " :src="imgCodeUrl" alt="验证码"/>
                </div>
                <!-- 手机/邮箱验证码输入区 -->
                <div v-if="imgCode.length>=4" class="flex items-center mt-2 justify-center animate__animated animate__flipInX">
                    <span class="text-gray-500 text-sm">{{$t('MobileChangePayPassword.verify')}}：</span>
                    <input v-model="code" :placeholder="$t('MobileChangePayPassword.verifyInput')" style="width: 110px" class="my-input " />
                    <div v-if="mobile.hasSent">
                        <span @click="getMobilePhoneCode" class="h-8 w-20 text-sm px-1 py-1 text-white bg-yellow-500 rounded cursor-pointer hover:bg-yellow-600">{{$t('MobileChangePayPassword.getCode')}}</span>
                    </div>
                    <div v-else>
                        <span disabled class="h-8 w-20 text-sm px-1 py-1 text-gray-500 bg-gray-200 rounded opacity-50">{{$t('MobileChangePayPassword.resendCode')}}{{ mobile.timeCount }}s</span>
                    </div>
                </div>

            </div>
            <!-- 修改支付密码按钮 -->
            <div class="mt-4 flex justify-center items-center">
                <div @click="onNext" class="text-sm  px-4 py-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600">{{$t('MobileChangePayPassword.change')}}</div>
            </div>

            <!-- 忘记密码新支付密码输入键盘 -->
            <van-number-keyboard
                v-model="newPassword"
                :show="showKeyboard"
                @blur="showKeyboard = false"/>
        </div>

    </div>
</template>

<script>
import {Notify} from "vant";
import {mapState} from "vuex";

export default {
    name: "MobileChangePayPassword",
    created() {
        this.$http.get("/v1/register/getppd-status").then((res) => {
            if (res.data.status === 200) {
                this.hasSetPassword = !res.data.data;
            }
            this.showSet = true;
        }).catch(error => {
            this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'));
        });
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    computed: {
        ...mapState({
            users: (state) => state.user.userInfo,
        }),
        hideEmail() {
            if(!this.users.useremail||this.users.useremail.length<3){
                return this.users.useremail;
            }
            const atIndex = this.users.useremail.indexOf('@');
            const username = this.users.useremail.slice(0, 2);
            const domain = this.users.useremail.slice(atIndex + 1);
            return `${username}****@${domain}`;
        },
        hideMobile() {
            if (!this.users.userphone||this.users.userphone.length !== 11) {
                return this.users.userphone;
            }
            return `${this.users.userphone.slice(0, 3)}****${this.users.userphone.slice(-4)}`;
        },
    },
    data(){
        return {
            messages: 0,
            forgetPassword:false,
            showSet: null,
            hasSetPassword: false,
            firstInput: true,
            oldPassword: "",
            firstInputPassword: "",
            secondInputPassword: "",
            newPassword: "",
            newPasswordAgain: "",
            showKeyboard: false,
            imgCode:"",
            imgCodeUrl:"",

            code:"",
            mobile:{
                hasSent: true,
                timeCount:60,
            },
        }
    },
    watch:{
        // 输入原密码进行认证
        oldPassword(oldPassword){
            if (oldPassword.length >= 6) {
                this.oldPassword = this.oldPassword.substring(0, 6);  //  截取前六位
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileChangePayPassword.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/register/originalppd-status",this.oldPassword,
                    {
                        headers: {
                        'Content-Type': 'application/octet-stream' // 设置请求头为二进制流
                    }})
                    .then(res=>{
                    toastLoading.clear();
                    if(res.data.data){
                        this.$toast.success(this.$t('MobileChangePayPassword.alertVerifySuccessfully'));
                        this.firstInput=true;
                        this.hasSetPassword=false;
                    }else{
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertVerifyFailed'));
                        this.oldPassword="";
                    }
                }).catch(err=>{
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileChangePayPassword.alertVerifyFailed'));
                    this.oldPassword="";
                })
            }
        },
        //  输入新密码-两次
        firstInputPassword(firstInputPassword){
            if (firstInputPassword.length >= 6) {
                this.firstInput=false;
                this.firstInputPassword = this.firstInputPassword.substring(0, 6);  //  截取前六位
            }
        },
        secondInputPassword(secondInputPassword){
            if (secondInputPassword.length >= 6) {
                this.secondInputPassword = this.secondInputPassword.substring(0, 6);  //  截取前六位
                if (this.secondInputPassword !== this.firstInputPassword)
                { // 两次密码输入不一致
                    this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordDifferent'));
                    this.firstInputPassword = "";
                    this.secondInputPassword = "";
                    this.firstInput = true;
                }
                else if(this.forgetPassword)
                //忘记密码
                {
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileChangePayPassword.alertSettingPassword'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/register/user-updateppd-authentication",
                        {
                            code:this.code,
                            paymentpassword: this.secondInputPassword,
                        })
                        .then(res=>{
                        toastLoading.clear();
                        if(res.data.data==="用户验证成功"){
                            this.$toast.success(this.$t('MobileChangePayPassword.alertPasswordSuccessfully'));
                            this.$router.go(-1);
                        }else{
                            this.$toast.fail(this.$t('AccountEmail.alertBindFail'));
                            this.firstInputPassword = "";
                            this.secondInputPassword = "";
                            this.code="";
                            this.firstInput = true;
                        }
                    }).catch(err=>{
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordFailed'));
                        this.firstInputPassword = "";
                        this.secondInputPassword = "";
                        this.code="";
                        this.firstInput = true;
                    })

                }
                else if(!this.hasSetPassword&&this.oldPassword==="")
                //初次设置密码(注意要多判断oldPassword是否为空)
                {
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileChangePayPassword.alertSettingPassword'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/register/insertppd", this.secondInputPassword,{
                        headers: {
                            'Content-Type': 'application/octet-stream' // 设置请求头为二进制流
                        }})
                        .then(res=>{
                        toastLoading.clear();
                        if(res.data.data==="支付密码设置成功"){
                            this.$toast.success(this.$t('MobileChangePayPassword.alertPasswordSuccessfully'));
                            this.$router.go(-1);
                        }else{
                            this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordFailed'));
                            this.firstInputPassword = "";
                            this.secondInputPassword = "";
                            this.firstInput = true;
                            this.hasSetPassword=false;
                        }
                    }).catch(err=>{
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordFailed'));
                        this.firstInputPassword = "";
                        this.secondInputPassword = "";
                        this.firstInput = true;
                        this.hasSetPassword=false;
                    })
                }
                else
                //根据原始密码修改密码
                {
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileChangePayPassword.alertChangingPassword'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/register/original-isok-updateppd",{
                        originalpaymentpassword: this.oldPassword,
                        paymentpassword: this.secondInputPassword
                    }).then(res=>{
                        toastLoading.clear();
                        if(res.data.data==="支付密码设置成功"){
                            this.$toast.success(this.$t('MobileChangePayPassword.alertChangePasswordSuccessfully'));
                            this.$router.go(-1);
                        }else{
                            this.$toast.fail(this.$t('MobileChangePayPassword.alertChangePasswordFailed'));
                            this.firstInputPassword = "";
                            this.secondInputPassword = "";
                            this.firstInput = true;
                            this.oldPassword=""
                            this.hasSetPassword=false;
                        }
                    }).catch(err=>{
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertChangePasswordFailed'));
                        this.secondInputPassword = "";
                    })
                }
                this.loadCodeImg();
            }
        }

    },
    methods:{
        onForgetPayPassword(){
            this.loadCodeImg();
            this.showSet=false;
            this.hasSetPassword=false;
            // 稍后需经过手机验证
            Notify({ type: 'primary', message: "稍后需经过手机验证" });
        },
        getMobilePhoneCode() {
            if (imgCode === '') {
                this.$toast.fail('computerlogin.alertNoGraphicCode');
                return;
            }
            // 手机端点击获取验证码
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileChangePayPassword.alertCodeLoading'),
                forbidClick: true,
            });

            this.$http.post("/v1/register/get-code-ofppd",this.imgCode,{
                    headers: {
                        'Content-Type': 'application/octet-stream' // 设置请求头为二进制流
                    }})
                .then((res) => {
                    toastLoading.clear();
                    this.startCountdown();
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('computerlogin.alertAccountSendSuccess'));
                    } else if (res.data.status === 201) {
                        this.$toast.fail(this.$t('all.图形验证码错误'));
                        this.imgCode = '';
                        this.loadCodeImg();
                    } else if (res.data.status === 202) {
                        this.$toast.fail(this.$t('all.codeLimit'));
                    } else {
                        this.$toast.fail(this.$t('computerlogin.alertAccountSendFail'));
                    }
                })
                .catch((error) => {
                    // 处理错误
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'));
                });
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.mobile.timeCount = 60;
            this.mobile.hasSent = false;
            // 计时器
            this.mobile.timer = setInterval(() => {
                if (this.mobile.timeCount > 0) {
                    this.mobile.timeCount--;
                } else {
                    this.mobile.hasSent = true;
                    clearInterval(this.mobile.timer);
                    this.mobile.timer = null;
                }
            }, 1000);
        },
        onNext(){
            if (this.newPassword.length !== 6) {
                this.$toast(this.$t('MobileChangePayPassword.alertNoPassword'))
                return;
            } else if (this.code === '') {
                this.$toast(this.$t('MobileChangePayPassword.alertNoCode'))
                return;
            }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileChangePayPassword.alertCodeLoading'),
                forbidClick: true,
            });
            this.$http.post("/v1/register/user-updateppd-authentication",{
                code: this.code,
                paymentpassword: this.newPassword
            }).then((response) => {
                    toastLoading.clear();
                    if (res.data.status === 200) { // 修改成功
                        this.$toast.success(this.$t('MobileChangePayPassword.alertChangePasswordSuccessfully'));
                        this.$router.go(-1);
                    } else { // 验证码错误
                        this.$toast.fail(this.$t('AccountEmail.alertBindFail'));
                    }
                })
                .catch((error) => {
                    // 处理错误
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'));
                });
        },
        loadCodeImg(){
            this.$http.get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },
    },
}

</script>

<style scoped>
.my-input {
    @apply text-sm w-48 py-1 mr-2 px-1 border-b border-gray-300 focus:outline-none;
}
</style>