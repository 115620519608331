<template>
    <div class="mobile-shop-home">
        <div class="users_profix_parent">
            <div @mouseover="changeCursor" class="circle-image shadow-md border-gray">
                <img :src="users.avatarUrl" alt="头像" style="padding: 0; margin: 0" @click="handleClick" />
                <input type="file" ref="fileInput" hidden @change="handleFileSelect" />
            </div>
            <div class="flex">
                <span class="flex flex-col text-lg mt-2 mb-3">{{$t('infor.name')}}: {{ users.username }}</span>
                <img class="self-center items-center w-5 h-5 hover:bg-gray-100" src="../images/edit.png" @click="onChangeInfo" />
            </div>
        </div>

        <div class="px-8 pt-3 pb-2 mx-3">
            <div class="flex justify-between">
                <div class="flex flex-col items-center" @click="goToOrder">
                    <img src="../mImage/order.png" style="height:34px;width:34px;"/>
                    <span class="mt-2">{{$t('infor.order')}}</span>
                </div>
                <div class="flex flex-col items-center" @click="goMyLand">
                    <img src="../mImage/land.png" style="height:34px;width:34px;"/>
                    <span class="mt-2">{{$t('infor.myLand')}}</span>
                </div>
                <div class="flex flex-col items-center" @click="goMyWallet">
                    <img src="../mImage/wallet.png" style="height:34px;width:34px;"/>
                    <span class="mt-2">{{$t('infor.balance')}}</span>
                </div>
            </div>
        </div>
        <div class="mobile-base-divider-parent">
            <div class="mobile-base-divider"></div>
        </div>

        <!-- 个人资料 -->
        <div class="card-container" @click="onChangeInfo">
            <img src="../mImage/infor-myinfor.png" style="margin-right:20px;">
            <span class="flex-grow text-lg">{{$t('infor.myinfor')}}</span>
            <img src="../images/right.png" class="right-icon" />
        </div>
        <!-- 收货地址 -->
        <div class="card-container" @click="goManageAddress">
            <img src="../mImage/infor-address.png" style="margin-right:20px;">
            <span class="flex-grow text-lg">{{$t('infor.address')}}</span>
            <img src="../images/right.png" class="right-icon" />
        </div>
        <!-- 账号与安全 -->
        <div class="card-container" @click="goSafe">
            <img src="../mImage/infor-safe.png" style="margin-right:20px;">
            <span class="flex-grow text-lg">{{$t('infor.accountSafe')}}</span>
            <img src="../images/right.png" class="right-icon" />
        </div>
        <!-- 客服服务及常见问题 -->
        <div class="card-container" @click="goServe">
            <img src="../mImage/infor-service.png" style="margin-right:20px;">
            <span class="flex-grow text-lg">{{$t('infor.service')}}</span>
            <img src="../images/right.png" class="right-icon" />
        </div>
        <!-- 设置 -->
        <div class="card-container" @click="goSetting">
            <img src="../developer/developer-header-set.png" style="margin-right:20px;">
            <span class="flex-grow text-lg">{{$t('infor.set')}}</span>
            <img src="../images/right.png" class="right-icon" />
        </div>
        <!-- 消息现在没用了 -->
        <!-- <div class="card-container" @click="goMyMessage">
            <span class="flex-grow text-lg">{{$t('infor.message')}}</span>
            <div class="bg-red-600 rounded-full h-8 w-8 flex items-center justify-center text-white" v-if="messages > 0">{{ messages }}</div>
            <img src="../images/right.png" class="right-icon" />
        </div> -->

        <div class="rounded-lg shadow-md hover:bg-red-600 bg-red-500 px-8 pt-2 pb-2 mx-3 mt-8 flex justify-center" @click="openModal">
            <span class="text-lg self-center text-white">{{$t('infor.downline')}}</span>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import { Dialog } from 'vant';

export default {
    computed: {
        ...mapState({
            users: (state) => state.user.userInfo,
        }),
    },
    created() {
        this.$store.dispatch("user/fetchUserInfo");
    },
    data() {
        return {
            messages: '', // 未读消息
        }
    },
    methods: {
        /* 此处为点击用户头像上传图片的点击事件 */
        handleFileSelect(event) {
            // 获取用户选择的文件对象
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("userphoto", file);
            this.$http
                .put("/v1/user/update-userphoto", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        console.log("success", response);
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            // 将文件对象转换为 URL
            const url = URL.createObjectURL(file);
            // 更新当前头像 URL
            this.users.avatarUrl = url;
            // 清空 input 内容
            event.target.value = "";
        },
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        handleClick() {
            // 点击时执行相应逻辑
            // 触发文件选择器
            this.$refs.fileInput.click();
        },
        onChangeInfo() {
            // 点击时执行相应逻辑
            // 触发文件选择器
            this.$router.push("/mobile/my-infor/detail");
        },
        goToOrder() {
            // 进入我的订单页面
            this.$router.push("/mobile/my-order");
        },
        goManageAddress() {
            this.$router.push("/mobile/address-manage");
        },
        goMyWallet() {
            this.$router.push("/mobile/my-wallet");
        },
        goMyLand() {
            this.$router.push("/mobile/my-land"); // 进入我的土地页面
        },
        goServe() {
            this.$router.push("/mobile/service"); // 进入客服服务页面
        },
        goSafe() {
            this.$router.push("/mobile/account-safe"); // 修改密码页面
        },
        goSetting() {
            this.$router.push("/mobile/settings"); // 设置页面
        },
        goMyMessage() {
            this.$router.push("/mobile/message"); // 消息页面
        },
        openModal() {
            Dialog.confirm({
                title: this.$t('infor.downline'),
                message: this.$t('infor.downlinetip'),
            })
            .then(() => {
                this.exit();
            })
            .catch(() => {
                // on cancel
            });
        },
        exit() { //退出登录 /index/exit
            localStorage.removeItem("userInfo");
            this.$http
                .get("/v1/index/exit")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        localStorage.removeItem("vuex");
                        this.$router.push("/mobile/login");
                    } else {
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: #F4F5F9;
    padding-bottom: 100px;
}

/* 用户头像的父块布局，里面包含了头像图片框和文字 */
.users_profix_parent {
    width: 200px;
    margin: 0 auto; /* 左右自动居中 */
    /* 使用了 flex 布局，并通过 `justify-content: center` 和 `align-items: center` 属性将上下两个块垂直对齐。 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 设置一点边角距离 */
    margin-top: 30px;
}

/* 用户头像布局 */
.users_profix {
    width: auto; /* 设置块的宽度 */
    height: auto; /* 设置块的高度 */
}

/* 用户头像图片设为圆形显示 */
.circle-image {
    width: 100px;
    height: 100px;
    /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
    border-radius: 50%;
    overflow: hidden;
}

/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.circle-image > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}
.card-container{
   @apply rounded-lg hover:bg-gray-200 bg-white pl-3 pr-4 pt-2 pb-2 mx-3 mt-3 flex items-center flex-row
}
.right-icon{
    @apply flex-none w-5 h-5 place-self-center
}
</style>
