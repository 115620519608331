<template>
    <div class="first-main-container">
        <!-- 左上角返回，返回登录页面 -->
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileChangePassword.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <!-- 手机端忘记密码 -->
        <div v-if="!loaded">
            <van-skeleton  title :row="2" />
            <van-skeleton  title :row="2" />
            <van-skeleton  title :row="2" />
        </div>
        <div v-if="loaded" class=" items-center justify-center h-screen ">
            <div class="my-password-tab" v-if="!first&&loaded">
                <div class="mobile-form-topic">{{$t('MobileChangePassword.title')}}</div>
                <div class="mobile-box-input-parent">
                    <div class="mobile-box-input-labal"><span>{{$t('MobileChangePassword.oldPassword')}}:</span></div>
                    <input :type="showPassword ? '' : 'password'" v-model="oldPassword" class="mobile-box-input" :placeholder="$t('MobileChangePassword.enterOldPassword')" @input="checkPassword" />
                    <span class="mobile-form-icon" @click="togglePassword">
                    <img src="../images/not-visible.png" class="mobile-form-icon-img" v-if="!showPassword" />
                    <img src="../images/visible.png" class="mobile-form-icon-img" v-if="showPassword" />
                </span>
                </div>
                <div class="mobile-box-input-parent">
                    <div class="mobile-box-input-labal"><span>{{$t('MobileChangePassword.newPassword')}}:</span></div>
                    <input :type="showPassword ? '' : 'password'" v-model="changePassword" class="mobile-box-input" :placeholder="$t('MobileChangePassword.enterNewPassword')" @input="checkPassword" />
                    <span class="mobile-form-icon" @click="togglePassword">
                    <img src="../images/not-visible.png" class="mobile-form-icon-img" v-if="!showPassword" />
                    <img src="../images/visible.png" class="mobile-form-icon-img" v-if="showPassword" />
                </span>
                </div>
                <div v-show="showRules" class="rules">{{$t('MobileChangePassword.rules1')}}</div>
                <button class="mobile-form-button" @click="emailChange">{{$t('MobileChangePassword.resetPassword')}}</button>
            </div>
            <div class="my-password-tab" v-else-if="first&&loaded">
                <div class="mobile-form-topic text-center">{{$t('MobileChangePassword.noPassword')}}</div>
                <div class="mobile-box-input-parent">
                    <div class="mobile-box-input-labal" style="width: 30%"><span>{{$t('MobileChangePassword.newPassword')}}:</span></div>
                    <input :type="showPassword ? '' : 'password'" v-model="changePassword" class="mobile-box-input" :placeholder="$t('MobileChangePassword.enterNewPassword')" @input="checkPassword" />
                    <span class="mobile-form-icon" @click="togglePassword">
                    <img src="../images/not-visible.png" class="mobile-form-icon-img" v-if="!showPassword" />
                    <img src="../images/visible.png" class="mobile-form-icon-img" v-if="showPassword" />
                </span>
                </div>
                <div v-show="showRules">{{$t('MobileChangePassword.rules1')}}</div>
                <div class="mobile-box-input-parent">
                    <div class="mobile-box-input-labal" style="width: 30%"><span>{{$t('MobileChangePassword.resurePassword')}}:</span></div>
                    <input :type="showPassword ? '' : 'password'" v-model="secondPassword" class="mobile-box-input" :placeholder="$t('MobileChangePassword.resurePasswordInput')" @input="checkPassword" />
                    <span class="mobile-form-icon" @click="togglePassword">
                    <img src="../images/not-visible.png" class="mobile-form-icon-img" v-if="!showPassword" />
                    <img src="../images/visible.png" class="mobile-form-icon-img" v-if="showPassword" />
                </span>
                </div>
                <button class="mobile-form-button" @click="firstChange">{{$t('MobileChangePassword.confirm')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        // // 获取用户信息,判断用户是否有密码，若没有，则第一次设置密码
        this.$http
            .get("/v1/user/personal-centerlist")
            .then((response) => {
                const data = response.data;
                if (data.status === 200) {
                    let userData = data.data;
                    if (userData.userpassword) {
                        this.first = false;
                    } else {
                        this.first = true;
                    }
                    this.loaded = true;
                } else {
                    // 处理错误情况
                    console.error(data.msg);
                }
            })
            .catch((error) => {
                // 处理请求失败的情况
                console.error(error);
            });
    },
    mounted() { // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            messages: 0,
            loaded: false,
            first: true, // 是否是第一次设置密码
            changePassword: "", // 新密码
            showPassword: false,
            oldPassword: "", // 旧密码
            secondPassword: "", // 第一次设置密码时确认密码
        };
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        checkPassword() {
            // 点击密码输入框后检查密码格式是否错误
            const re = /^(?=.*[A-Za-z])(?=.*\d|.*[-+_!@#$%^&*.,?])[^\s]{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        /* --------------------------------------------------手机端--------------------------------------------------- */
        emailChange() {
            if (this.changePassword.length === 0 || this.showRules) {
                this.$toast(this.$t('MobileChangePassword.alertNewPasswordError'));
                return;
            }
            const data = {
                oldpassword: this.oldPassword,
                newpassword: this.changePassword,
            };
            this.$http
                .post("/v1/register/user-update-password", data, {
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        // 成功时的处理
                        this.$toast.success(this.$t('MobileChangePassword.alertChangePasswordSuccessfully'));
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(this.$t('MobileChangePassword.alertChangePasswordFailed'));
                    }
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast.fail(error.data.data);
                });
        },

        firstChange() {
            if (this.changePassword.length === 0 || this.showRules) {
                this.$toast(this.$t('MobileChangePassword.alertNewPasswordError'));
                return;
            }
            if (this.changePassword !== this.secondPassword) {
                this.$toast(this.$t('MobileChangePassword.alertPasswordDifferent'));
                return;
            }
            const data = {
                userpassword: this.changePassword,
            };
            this.$http
                .post("/v1/register/setup-password", data, {
                    withCredentials: true,
                })
                .then((res) => {
                    // 成功时的处理
                    this.changePassword = "";
                    this.$toast(this.$t('MobileChangePassword.alertResetSuccessfully'));
                    let timer = setInterval(() => {
                        clearInterval(timer);
                        this.$router.go(-1);
                    }, 1000);
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast.fail(error.data.data);
                });
        },
    },
};
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    height: 100%; /* 容器高度为屏幕高度 */
}
.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}
/* --------------------------------------------手机端----------------------------------------------- */
.mobile-form-container {
    /* 手机端登陆页面 */
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #f9fafb;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-form-topic {
    /* 手机端登录注册标题 */
    font-size: 20px;
    padding: 10px;
}
.mobile-box-input-parent {
    /* 输入框样式 */
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.mobile-box-input-labal {
    /* 输入框前文字信息样式 */
    width: 70px;
    display: flex;
    align-items: center; /* 水平居中 */
}
.mobile-box-input {
    /* 输入框样式 */
    height: 50px;
    width: 70%;
    font-size: 18px;
    color: #dcdcdc;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
}
/* 密码规则文本样式 */
.rules {
    width: 80%;
    font-size: 14px;
    color: red;
}
.mobile-form-button {
    /* 按钮样式 */
    height: 45px;
    width: 80%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(to bottom right, #5a4de2, #274dba);
    border-radius: 30px;
    box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
}
.mobile-form-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.mobile-form-icon-img {
    max-height: 50px;
    max-width: 50px;
    height: 100%; /* 根据输入框的高度来设置图片大小 */
    width: auto;
}
.my-password-tab {
    @apply relative mx-3 p-6 rounded-lg bg-white flex flex-col items-center justify-center;
}
</style>
