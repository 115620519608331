<template>
    <div class="land-market-container h-full w-full flex flex-row justify-center mt-44" ref="mainContainer">
        <div class="flex flex-col">
            <div class="flex flex-row w-full items-center">
                <img src="../pImage/market-filter.png" class="icon">
                <span class="title">{{$t('all.quickFilter')}}</span>
            </div>
            <div class="left-box" style="height:calc(100% - 200px)">
                <!-- 快捷筛选1-单位土地个数 -->
                <div class="quick-filter">
                    <span class="quick-filter-title">{{$t('all.unitNum')}}</span>
                    <el-radio-group v-model="search.num">
                        <el-radio :label="0">{{ '<5' }}</el-radio>
                        <el-radio :label="5">{{ '5-15' }}</el-radio>
                        <el-radio :label="15">{{ '>15' }}</el-radio>
                    </el-radio-group>
                </div>
                <!-- 快捷筛选2-排序方式 -->
                <div class="quick-filter">
                    <span class="quick-filter-title">{{$t('all.sortMode')}}</span>
                    <div class="flex flex-row">
                        <el-select
                            v-model="search.sort"
                            :placeholder="$t('all.select')"
                            size="small"
                            style="width: 80px"
                        >
                            <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                        <el-select
                            v-model="search.seq"
                            :placeholder="$t('all.select')"
                            size="small"
                            style="width: 80px;margin-left:8px"
                        >
                            <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <!-- 快捷筛选3-价格区间 -->
                <div class="quick-filter">
                    <span class="quick-filter-title">{{$t('all.amount')}}</span>
                    <div class="flex flex-row">
                        <div class="flex flex-col">
                            <span class="quick-filter-subhead">{{$t('all.min')}}</span>
                            <input type="number" min="0" class="quick-filter-input" v-model="search.minprice">
                        </div>
                        <div class="flex flex-col" style="margin-left:8px">
                            <span class="quick-filter-subhead">{{$t('all.max')}}</span>
                            <input type="number" min="0" class="quick-filter-input" v-model="search.maxprice">
                        </div>
                    </div>
                </div>
                <!-- 快捷筛选4-时间区间 -->
                <div class="quick-filter">
                    <span class="quick-filter-title">{{$t('all.shelftime')}}</span>
                    <el-date-picker
                        v-model="search.date"
                        type="daterange"
                        unlink-panels
                        :range-separator="$t('all.to')"
                        :start-placeholder="$t('all.early')"
                        :end-placeholder="$t('all.last')"
                        :shortcuts="pickerOptions"
                        size="small"
                        style="width:168px"
                    />
                </div>
                <!-- 快捷筛选5-按钮 -->
                <div class="w-full flex flex-row mt-10">
                    <button class="infor1-button infor1-button-blueground" @click="initSearchProduct">{{$t('all.filter')}}</button>
                    <div class="flex-grow flex justify-end">
                        <button class="infor1-button infor1-button-whiteground" @click="resetProduct">{{$t('all.clear')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col pr-12 ml-5 relative">
            <div class="flex flex-row w-full">
                <div class="flex flex-row items-center">
                    <img src="../pImage/market-land.png" class="icon">
                    <span class="title">{{$t('all.landmarket')}}</span>
                </div>
                <div class="flex-grow flex justify-end">
                    <div class="search-box">
                        <img src="../pImage/search.png" class="search-box-icon">
                        <input type="text" v-model="search.key" class="search-box-input" :placeholder="$t('all.enterKeyword')">
                        <button class="search-box-button" @click="searchProduct">{{$t('all.search')}}</button>
                    </div>
                </div>
            </div>
            <div class="right-box" ref="scrollContainer" @scroll="handleScroll">
                <!-- 骨架屏 -->
                <div class="w-full flex flex-wrap" v-if="loading">
                    <el-skeleton class="private-skeleton" :loading="loading" animated :count="12" :throttle="500">
                        <template slot="template">
                            <div class="flex flex-col" style="margin-top: 16px;margin-left: 15px;">
                                <el-skeleton-item variant="image" style="width: 235px; height: 150px;" />
                                <div style="width: 235px;height: 165px;padding: 16px;">
                                    <el-skeleton-item variant="p" style="width: 60%" />
                                    <el-skeleton-item variant="p" style="width: 50%;margin-top: 40px;" />
                                    <div style="display: flex; align-items: center; justify-items: space-between;margin-top: 40px;">
                                        <el-skeleton-item variant="text" style="margin-right: 16px;" />
                                        <el-skeleton-item variant="text" style="width: 30%;" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </div>
                <!-- 商品图鉴 -->
                <LandMarketWindows v-for="(card, index) in apply" :key="index" class="card" :LandInfor="card"></LandMarketWindows>
            </div>
            <div class="back-top" v-show="showBackTop()" @click="backTop">
                <i class="el-backtop">
                    <img src="../pImage/backtop-icon.png" style="width:24px;height:20px;">
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import LandMarketWindows from "./LandShopList";
export default {
    components: {
        LandMarketWindows, // 土地市场组件
    },
    mounted() { // 初始化
        this.controlWindow();
        window.addEventListener('resize', this.controlWindow);
        this.$http.post('/v1/sale/get-saleselect-list',{
                pageNum: 1,
                pageSize: 10,
            })
            .then(res => {
                this.apply = res.data.data.rows;
                this.loading = false;
            })
            .catch(error => {
                this.$toast.fail(this.$t('forgetPassword.alertAxiosError'));
            });
    },
    beforeDestroy() {
        // 移除事件监听器
        window.removeEventListener('resize', this.controlWindow);
    },
    watch: {
        'search.sort': { // 监听排序筛选，若选择排序方式未选择升降序，则默认升序
            handler(newVal, oldVal) {
                if (this.search.seq === '') {
                    this.search.seq = 'ASC';
                }
            },
            deep: true
        },
        'search.maxprice': { // 监听排序筛选，若选择排序方式未选择升降序，则默认升序
            handler(newVal, oldVal) {
                if (this.search.minprice === '') {
                    this.search.minprice = 0;
                }
            },
            deep: true
        },
    },
    data() {
      return {
        loading: true,
        flag: true, // 防抖，滚动条加载数据需要有一段时间不触发，true代表处于防抖状态
        currentPage: 1, // 土地市场页码
        pageSize: 10, // 每页有多少条数据
        productNum: 0, // 商品总量，初始化时根据后端获取

        search: {
            key: '', // 关键词
            num: null, // 单位土地个数
            sort: '', // 排序方式
            seq: '', // 排序方式是从小到大还是从大到小
            minprice: '', // 价格区间-最小价格
            maxprice: '', // 价格区间-最大价格
            date: null, // 时间区间，结果是个数组
        },
        options1: [{
          value: 'blocks',
          label: this.$t('all.unitlandnum')
        }, {
          value: 'totalprice',
          label: this.$t('all.goodprice')
        }, {
          value: 'createtime',
          label: this.$t('all.shelftime')
        }],
        options2: [{
          value: 'DESC',
          label: this.$t('all.des')
        }, {
          value: 'ASC',
          label: this.$t('all.asc')
        }],
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: this.$t('LandSellList.tip2'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('LandSellList.tip3'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.$t('LandSellList.tip4'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        apply: [],
      };
    },
    methods: {
        // 根据屏幕宽度限制卡片宽度
        controlWindow() {
            if (window.innerWidth <= 1300 || window.innerHeight <= 952) {
                const scaleX = window.innerWidth / 1300;
                const scaleY = window.innerHeight / 952;
                const scale = Math.min(scaleX, scaleY);
                this.$refs.mainContainer.style.transform = `scale(${scale})`;
                this.$refs.mainContainer.style.height = 922 + 'px';
            } else {
                this.$refs.mainContainer.style.transform = `scale(1)`;
                this.$refs.mainContainer.style.height = `100%`;
            }
        },
        initProduct() { // 初始化显示的数据
            this.loading = true;
            this.$http
                .post("/v1/sale/get-saleselect-list",{
                    pageNum: this.currentPage,
                    pageSize: this.pageSize
                })
                .then((res) => {
                    this.apply = [];
                    this.loading = true;
                    this.apply = this.apply.concat(res.data.data.rows);
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        initSearchProduct() {
            this.apply = [];
            this.loading = true;
            this.searchProduct();
        },
        searchProduct() {
            let data = {
                pageNum: this.currentPage,
                pageSize: this.pageSize
            };
            if (this.search.key !== '') {
                data["keyword"] = this.search.key;
            }
            if (this.search.num !== null) {
                data["blocks"] = this.search.num;
            }
            if (this.search.sort !== '') {
                data["sortord"] = this.search.sort;
            }
            if (this.search.seq !== '') {
                data["sortorder"] = this.search.seq;
            }
            if (this.search.minprice) {
                if (this.search.maxprice === '') {
                    data["priceMax"] = 9999999;
                }
                data["priceMin"] = Number(this.search.minprice);
            }
            if (this.search.maxprice) {
                data["priceMax"] = Number(this.search.maxprice);
            }
            if (this.search.date) {
                data["starttime"] = this.search.date[0];
                data["endtime"] = this.search.date[1];
            }
            this.$http
                .post("/v1/sale/get-saleselect-list",data)
                .then((res) => {
                    this.loading = false;
                    this.apply = this.apply.concat(res.data.data.rows);
                })
                .catch((error) => {
                    this.$toast.fail(this.$t('computerlogin.alertAxiosError'));
                });
        },
        resetProduct() {
            this.currentPage = 1;
            this.search = {
                key: '', // 关键词
                num: null, // 单位土地个数
                sort: '', // 排序方式
                seq: '', // 排序方式是从小到大还是从大到小
                minprice: '', // 价格区间-最小价格
                maxprice: '', // 价格区间-最大价格
                date: null, // 时间区间，结果是个数组
            }
            this.initProduct();
        },
        // 当滚动条达到一定距离时加载新的商品数据
        handleScroll() {
            if (this.flag) { // 防抖
                return;
            }
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;

            // 判断滚动条距离底部的距离是否达到一定范围
            if (scrollHeight - (scrollTop + clientHeight) < 100) {
                this.flag = true;
                this.currentPage++;
                this.searchProduct();
                setTimeout(()=>{
                    // 延时一秒
                    this.flag = false;
                } ,1000)
            }
        },
        showBackTop() {
            const scrollContainer = this.$refs.scrollContainer;
            if (scrollContainer) {} else { return; }
            const scrollTop = scrollContainer.scrollTop;
            if (scrollTop >= 100) {
                return true;
            } else {
                return false;
            }
        },
        backTop() {
            let n = 0;
            const LENGTH = this.$refs.scrollContainer.scrollTop * 0.1;
            let timer = setInterval(() => {
                n++;
                this.$refs.scrollContainer.scrollTop -= LENGTH;
                if (n >= 10) {
                    clearInterval(timer);
                }
            }, 10);
        },
        turnToDetail(LandInfor) {
            this.$router.push({
                path: '/user/land-market/detail',
                query: {
                    land: LandInfor.originallandid,
                    merch: LandInfor.id
                }
            });
        },
    },
}
</script>

<style scoped>
@media (max-width: 1300px) or (max-height: 980px) {
    .land-market-container {
        transform-origin: top center; /* 确保从中心缩放 */
    }
}
.mt-44 {
    margin-top: 44px;
}
.icon {
    height: 25px;
    object-fit: contain;
}
.title {
    margin-left: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #3D3D3D;
}
.left-box {
    margin-top: 22px;
    width: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 100px 16px;
    background-color: #FFFFFF;
}
.quick-filter {
    width: 100%;
    border-bottom: 1px solid rgba(216, 216, 216, 0.3);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
}
.quick-filter-title {
    font-size: 14px;
    color: #999999;
    margin-bottom: 16px;
}
.quick-filter-subhead {
    margin-bottom: 8px;
    font-size: 12px;
    color: #E5E5E5;
}
.quick-filter-input {
    height: 32px;
    width: 80px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #0256FF;
    background-color: #FFFFFF;
}
.search-box {
    height: 40px;
    width: 500px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #0256FF;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
}
.search-box-icon {
    margin-left: 14px;
    height: 16px;
    object-fit: contain;
}
.search-box-input {
    flex-grow: 1;
    max-height: 40px;
    margin-left: 8px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.3);
}
.search-box-button {
    height: 40px;
    width: 100px;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: #0256FF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 14px;
}
.right-box {
    width: 1020px; /* 为了适应滚动条，加了20px */
    max-height: calc(100% - 200px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.card {
  width: 235px;
  height: 315px;
  margin: 16px 0 0 20px;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
}
.card:nth-child(4n+1) {
  margin-left: 0;
}
.card:nth-child(n+5) {
  margin-top: 16px;
}
.back-top {
  position: absolute;
  bottom: 30px;
  right: 0;
  border-radius: 9999px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, .12);;
  cursor: pointer;
}

.private-skeleton {
    width: 100%;
}
.private-skeleton >>> .el-skeleton {
    display: flex;
    flex-wrap: wrap;
}
</style>