<template>
    <div class="mobile-base-background2 order-confirm-background">
        <div class="flex flex-row items-center base-p-16px">
            <div style="z-index:2;"><img src="../mImage/login-back.png" style="height:25px;width:25px;" @click.stop="$router.go(-1)"></div>
            <div class="mobile-goback-title">{{$t('MobileConfirmOrder.title')}}</div>
            <div class="relative" @click="$router.push('/mobile/message')">
                <div class="mobilehome-unread" v-if="messages !== 0"></div>
                <img src="../mImage/login-ding-black.png" class="justify-end" style="height:25px;width:25px;">
            </div>
        </div>
        <div class="order-confirm-main" style="padding:0 16px;">
            <van-cell :title="$t('MobileConfirmOrder.cellTitle')" center />
            <div class="bg-white">
                <my-items
                    v-for="(land, index) in LandInfor"
                    :key="index"
                    :LandInfor="land"
                    :show-delete="false"
                    :index="index"
                    ref="MyItems"
                    :showSelect="false"
                    style="margin: 0;" />
            </div>
            <!-- 是否开具纸质土地证明-->
            <van-cell center class="mt-5" v-show="false">
                <template #icon>
                    {{$t('MobileConfirmOrder.paper')}}
                    <van-switch v-model="needpaper" @click="()=>{if(needpaper){totalPrice-=2500} else {totalPrice+=2500};}" size="15px" class="ml-2" />
                </template>
            </van-cell>
            <!--选择地址-->
            <van-cell center class="mt-5" @click="chooseAddress">
                <template v-if="chosenAddressId===null" #icon>
                    {{$t('MobileConfirmOrder.chooseAddress')}}
                    <van-icon name="arrow" class="ml-2" />
                </template>
                <template v-else #icon>
                    <div class="flex  items-center">
                        <div class="text-gray-500">
                            {{$t('MobileConfirmOrder.addressInput')}}：{{ chosenAddress.address }}<br/>
                            {{$t('MobileConfirmOrder.personInput')}}：{{ chosenAddress.name }}<br/>
                            {{$t('MobileConfirmOrder.phone')}}：{{ chosenAddress.tel }}
                        </div>
                        <span class="flex-grow"> </span>
                        <div class="round-md absolute right-3 text-xs text-blue-500 text-right" @click="chooseAddress">{{$t('MobileConfirmOrder.change')}} ></div>
                    </div>
                </template>
            </van-cell>

            <div>
                <div v-html="paymentForm"></div>
            </div>
            <div class="my-3 px-3 rounded-lg bg-white">
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'am'">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="paypal"></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="aliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="wechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/balance.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.balance')}}
                        </div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="balance"></van-radio>
                    </div>
                </div>
            </div>
            <van-submit-bar :loading="submitLoading" :price="totalPrice" :button-text="$t('MobileConfirmOrder.submitOrder')" @submit="onSubmit" v-if="haveData" />
            <van-dialog  :show-confirm-button="false" v-model="chooseAddressDialog" class="flex flex-col justify-end" style="height: calc(75vh);">
                <template #title>
                    <div class="absolute top-3 right-0 left-0 justify-center items-center px-2">
                        <span>{{$t('MobileConfirmOrder.chooseAddress')}}</span>
                        <span class="cursor-pointer absolute top-0 right-3 text-xl" @click="chooseAddressDialog = false">×</span>
                    </div>
                </template>
                <MobileAddressManagement class="absolute top-10 right-0 left-0" ref="addressManagement" :isChoosing="true"/>
                <div class="mb-16 py-2 mx-4 bg-blue-500 rounded-full text-white flex justify-center"  @click="onSubmitAddress">{{$t('MobileConfirmOrder.confirm')}}</div>
            </van-dialog>
        </div>
        <van-popup id="popup" v-model="showPopup" closeable close-icon-position="top-left" position="bottom" round class="pb-14" @close="onClosePop">
            <!-- 初次设置支付密码 -->
            <div class="flex flex-col justify-center items-center text-lg mt-12" v-if="firstSetPayPassword">
                {{$t('MobileConfirmOrder.alertNoPayPassword')}}
                <span class="text-3xl text-blue-500 mt-3" @click="$router.push('/mobile/change-password/pay')">{{$t('MobileConfirmOrder.alertNoPayPassword2')}}</span>
            </div>
            <!-- 请支付 -->
            <div class="flex flex-row justify-center items-center text-center text-3xl mt-12" >
                <span>{{$t('all.payPlease')}}:</span>
                <span v-if="$setPayment === 'am'">${{(totalPrice/100).toFixed(2)}}</span>
                <span v-if="$setPayment === 'zh'">￥{{((totalPrice/100) * $exchangeRate).toFixed(2)}}</span>
            </div>
            <div v-if="!firstSetPayPassword && !showPasswordInput" class="flex flex-col justify-center items-center text-center text-xl text-gray-400 mt-1">
                <span>{{$t('MobileConfirmOrder.balance')}} <span class="text-red-500 ml-2">{{ balance[0] }}.<span class="text-sm">{{ balance[1] }}</span></span></span>
                <span class="ml-3 text-red-300">{{balance[2] < totalPrice/100?$t('MobileConfirmOrder.insufficientBalance'):''}}</span>
                <router-link to="/usermobile/recharge" class="text-blue-500"> &nbsp;&nbsp;{{$t('MobileConfirmOrder.topup')}}</router-link>
            </div>
            <div class="mx-3" v-if="!firstSetPayPassword && !showPasswordInput">
                <div class="mt-2">
                    <span class="text-blue-500">{{$t('MobileConfirmOrder.mixPay')}}</span>
                    <span v-if="$setPayment === 'am'">${{((totalPrice)/100-balance[2]).toFixed(2)}}:</span>
                    <span v-if="$setPayment === 'zh'">￥{{(((totalPrice)/100-balance[2]) * $exchangeRate).toFixed(2)}}：</span>
                </div>
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixAliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'zh'">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixWechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2" v-if="$setPayment === 'am'">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixPaypal"></van-radio>
                    </div>
                </div>
            </div>
            <div v-if="!firstSetPayPassword && !showPasswordInput" @click="gotopay" class="rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileConfirmOrder.pay')}}</span>
            </div>


            <!-- 密码输入框 -->
            <van-password-input
                v-if="showPasswordInput"
                :value="payPassword"
                :focused="showKeyboard"
                :info="$t('MobileConfirmOrder.enterNewPassword')"
                :error-info="errorInfo"
                @focus="onFocusPassword"
                class="mb-60"
            />

            <!-- 数字键盘 -->
            <van-number-keyboard
                v-model="payPassword"
                :show="showKeyboard"
                @blur="onBlurPassword"
            />
        </van-popup>
    </div>
</template>

<script>
import MyItems from "@/mobile/ShopCartInfor";
import MobileAddressManagement from "@/mobile/MobileAddressManagement";
export default {
    components: {
        // 土地市场组件
        MyItems, // 挂牌土地信息组件
        MobileAddressManagement, // 地址管理组件
    },
    name: "MobileConfirmOrder",
    mounted() {
        // 获取支付密码
        this.$http.get("/v1/register/getppd-status").then((res) => {
            this.firstSetPayPassword = !res.data.data;
        }).catch(error => {
            this.$toast.fail(this.$t('MobileChangePayPassword.alertAxiosError'));
        });
        // 获取未读消息
        this.$http.get('/v1/mail/unread',{
            withCredentials: true,
        })
        .then(res => {
            this.messages = res.data.data;
        }).catch(error => {})
    },
    data() {
        return {
            haveData: false, // 后端是否传来数据，没有传来改为false
            firstSetPayPassword: true, // 是否为第一次设置支付密码，true为未设置，false为已设置
            messages: 0,
            chooseAddressDialog: false,
            chosenAddressId: null,
            chosenAddress: {},
            totalPrice: 0,
            LandInfor: [],
            payWay: "paypal",
            needpaper: false,
            paymentForm: "",
            submitLoading: false,
            orderid: "",
            balance: ['0','00',0],
            showPopup: false,
            popHeight: "60%",
            payPassword: "",
            showKeyboard: false,
            showPasswordInput: false,
            errorInfo: "",
        };
    },
    created() {
        if (this.$route.query.LandInfor) {
            this.LandInfor = JSON.parse(this.$route.query.LandInfor);
        }
        if (this.$route.query.totalPrice) {
            this.totalPrice = JSON.parse(this.$route.query.totalPrice) * 100;
            this.haveData = true;
        } else {
            this.$toast.fail(this.$t('MobileAssociatedReplaceloadFail'));
        }
        //查询默认地址
        this.$http.get("/v1/address/select-address?isdefault=true",)
            .then((res) => {
                //todo 没有默认地址
                if(res.data.data === '没有收货地址'){
                    return;
                }
                this.chosenAddressId = res.data.data.id;
                this.$set(this.chosenAddress, "address", res.data.data.province + res.data.data.city + res.data.data.area + res.data.data.detailedaddress);
                this.$set(this.chosenAddress, "name", res.data.data.consigneename);
                this.$set(this.chosenAddress, "tel", res.data.data.contactinformation);
            })
            .catch((err) => {
                console.log(err);
            });

        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                const value = Number(res.data.data.balance);
                this.balance = value.toFixed(2).toString().split(".");
                this.balance.push(value);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        // 商品图鉴子组件进入详情
        turnToDetail(index) {
            return;
        },
        chooseAddress() {
            this.chooseAddressDialog = true;
        },
        onSubmitAddress() {
            let addressList = this.$refs.addressManagement.addressList;
            this.chosenAddressId = this.$refs.addressManagement.chosenAddressId;
            for (let i = 0; i < addressList.length; i++) {
                if (addressList[i].id === this.chosenAddressId) {
                    this.chosenAddress = addressList[i];
                    break;
                }
            }
            if (this.chosenAddressId === -1) {
                this.$toast(this.$t('MobileConfirmOrder.alertNoAddress'));
                return;
            }
            this.chooseAddressDialog = false;
        },
        onClosePop(){
            this.payWay='balance';
            // 等待500ms
            setTimeout(() => {
                this.showPasswordInput = false;
            }, 500);
        },
        onFocusPassword() {
            this.showKeyboard = true;
            // document.querySelector('#popup').style.height = "80%";
        },
        onBlurPassword() {
            this.showKeyboard = false;
            //删除height的设置
            document.querySelector('#popup').style.height = "";
        },
        /* 异步等待接口 */
        async fetchDataWithTimeout(url) {
            try {
                // 发送POST请求
                const response = await this.$http.post(url);

                // 设置最长十分钟的超时时间
                const timeout = 10 * 60 * 1000; // 十分钟的毫秒数

                // 定义一个Promise，等待十分钟内收到状态为200的响应
                const response200 = new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(new Error('Timeout: Did not receive response within 10 minutes.'));
                    }, timeout);

                    if (response.status === 200) {
                        resolve(response);
                    }
                });

                // 等待Promise的返回结果
                const result = await Promise.race([response200]);

                // 处理状态为200的响应
                if (result.data) {
                    this.$toast.success(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.push('/mobile/pay-success');
                } else {
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    this.$router.push('/mobile/pay-fail');
                }
            } catch (error) {
                // 捕获异常并进行处理
                console.error('Error occurred:', error.message);
            }
        },

        /** 提交订单&&正常支付*/
        onSubmit() {
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
            };
            if(this.chosenAddressId===null){
                this.$toast.fail(this.$t('MobileConfirmOrder.alertNoAddress'));
                return ;
            }
            if (this.payWay === "aliPay") {
                this.$toast(this.$t('MobileConfirmOrder.alertalipay'));
                const toastLoading = this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/alipay/pay", data).then((res) => {
                    //订单获批准时的逻辑
                    if (res.data.status === 201) {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertGoPayFailed'));
                        this.$router.go(-1);
                    } else {
                        // window.location.href = res.data.html;
                        // window.open(res.data.html,'_blank'); // 打开一个新的PayPal标签页
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            } else if (this.payWay === "wechat") {
                toastLoading.clear();
                this.$toast(this.$t('MobileConfirmOrder.alertWechat'));
            }
            else if (this.payWay==='paypal'){
                this.$toast(this.$t('MobileConfirmOrder.alertPaypal'));
                const toastLoading = this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/paypal/pay", data).then((res) => {
                    //订单获批准时的逻辑
                    if (res.data.status === 201) {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertGoPayFailed'));
                        this.$router.go(-1);
                    } else {
                        // window.location.href = res.data.html;
                        // window.open(res.data.html,'_blank'); // 打开一个新的PayPal标签页
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            }
            else if (this.payWay==='balance'){
                this.submitLoading = false;
                this.showPopup = true;
                // 检查是否已设置支付密码
                if (this.firstSetPayPassword) {}
                /** 检查余额是否足够*/
                else if(this.balance[2] < this.totalPrice/100){
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNoMoney'));
                }
                /** 余额足够*/
                else {
                    this.showPasswordInput = true;
                }
            }
        },
        // 余额不足时需要选择混合支付方式然后再支付
        gotopay() {
            if (this.payWay === 'balance') {
                this.$toast.fail(this.$t('PcConfirmOrder.choosepayment'));
            } else {
                this.showPasswordInput = true;
            }
        },
        /** 点击余额支付*/
        onPay(){
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
                paymentpassword:this.payPassword
            };
            /** 检查是否选择地址*/
            if(this.chosenAddressId===null){
                this.$toast.fail(this.$t('MobileConfirmOrder.alertNoAddress'));
                return ;
            }
            /** 检查余额是否足够*/
            if (this.payWay === 'balance'){
                toastLoading.clear();
                this.goPay();
            }
            else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if (res.data.status === 201) {
                            this.$toast.fail(this.$t('MobileConfirmOrder.alertGoPayFailed'));
                            this.$router.go(-1);
                            return;
                        } else if (res.data==='密码错误') {
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return;
                        }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
            }
            else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
            }
            else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if (res.data.status === 201) {
                            this.$toast.fail(this.$t('MobileConfirmOrder.alertGoPayFailed'));
                            this.$router.go(-1);
                            return;
                        } else if (res.data.data==='密码错误') {
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return;
                        }
                        // 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.html;
                        let retries = 0;
                        const maxRetries = 10; // 设置最大重试次数
                        window.location.href = this.orderid;
                        // 等待1秒
                        const intervalId = setInterval(() => {
                            // 每隔一秒尝试跳转
                            if (window.location.href === this.orderid) {
                                toastLoading.clear();
                                clearInterval(intervalId); // 跳转成功后清除定时器
                            } else {
                                if (retries < maxRetries) {
                                    window.location.href = this.orderid; // 尝试跳转
                                    retries++;
                                } else {
                                    console.log('Jump fail');
                                    toastLoading.clear();
                                    clearInterval(intervalId); // 清除定时器
                                }
                            }
                        }, 1000); // 每隔一秒检查一次
                    }).catch((error) => {
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                    });
            }
        },
        /** 去支付*/
        goPay(){
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
                paymentpassword:this.payPassword
            };
            if(this.payWay==='balance'){
                this.$http.post("/v1/balance-payment/pay", data).then((res) => {
                    toastLoading.clear();
                    if (res.data.status === 201) {
                        this.$toast.fail(this.$t('MobileConfirmOrder.alertGoPayFailed'));
                        this.$router.go(-1);
                        return;
                    } else if (res.data.data==='密码错误') {
                        this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                        this.showKeyboard=false;
                        this.payPassword='';
                        return ;
                    }
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.push({
                        path: "/mobile/shopping-cart",
                    });
                }).catch((error) => {
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileConfirmOrder.alertNetFailed'));
                });
            }
        },
    },
    watch:{
    //    密码输入完成
        payPassword(payPassword){
            if (payPassword.length >= 6) {
                this.payPassword = payPassword.substring(0, 6);  //  截取前六位
                this.onPay();
            }
        }
    }
};
//
</script>

<style scoped>
.order-confirm-background {
    height: 100vh;
    overflow-y: hidden;
}
.order-confirm-main {
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
</style>
