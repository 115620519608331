<template>
    <div class="market-background" @click="turnToDetail">
        <img :src="LandInfor.originalLand.landpicture" class="market-image" />
        <div class="market-landname">{{ LandInfor.landname }}</div>
        <div class="market-landprice">{{ '￥' + LandInfor.pricenum }}</div>
        <div class="market-landaddress">
            <img src="../pImage/merch-icon.png" class="market-landaddress-icon">
            <span>{{ LandInfor.originalLand.geographicalcoordinates }}</span>
        </div>
        <div class="market-originallandname">{{ LandInfor.originalLand.originallandname }}</div>
        <div class="market-landblocks">{{$t('all.blocks')}}<span style="color: red;margin-left: 4px;">{{ LandInfor.unitLand.landblocks }}</span></div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        LandInfor: {
            // 包含图片链接image、landName、landPrice、landAddress
            type: Object,
            required: true,
            default: {
                originallandid: '',
                unitlandid: '',
                seller: '',
                pricenum: '',
                salesmethods: '',
                landname: "",
                originalLand: {
                    landpicture: '',
                    geographicalcoordinates: '',
                    originallandname: '',
                },
                unitLand: {
                    landblocks: 0,
                },
            }
        },
    },
    data() {
        return {
            landInfor: { // 测试数据
                originallandid: 'TD012023042096969',
                unitlandid: '60', // 用于确定该土地块的关键词
                seller: 'Mr.Wang',
                pricenum: '1000', // 单位土地售价
                salesmethods: '立即购买',
                landname: "合并测试土地1号",
                originalLand: null,
            }
        }
    },
    methods: {
        turnToDetail() {
            this.$parent.turnToDetail(this.LandInfor);
        },
    },
}
</script>

<style scoped>
.market-background {
    width: 235px;
    height: 315px;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 235px;
    object-fit: fill;
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 178px;
    left: 16px;
    font-size: 16px;
    color: #230B34;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 20px;
    left: 141px;
    font-size: 18px;
    color: #ED763D;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.market-landaddress {
    position: absolute;
    top: 264px;
    left: 16px;
    background-color: rgba(11, 63, 227, 0.1);
    border-radius: 4px;
    color: #3662EC;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: row;
    font-size: 14px;
    padding: 6px 8px;
}
.market-landaddress-icon {
    height: 10px;
    object-fit: contain;
    margin-right: 3px;
}
.market-originallandname {
    position: absolute;
    top: 221px;
    left: 16px;
    color: #B4B4B4;
    font-size: 14px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.market-landblocks {
    position: absolute;
    top: 221px;
    right: 16px;
    color: #B4B4B4;
    font-size: 14px;
}
</style>